import React, { Component } from "react";
import { Link } from "react-router-dom";
import MenuItem from "./../SidebarNew/MenuItem";
import NavItem from "./../SidebarNew/NavItem";

import { removeUserSession } from "./../../lib/Common";
import ValueContext from "./Context";
import { withTranslation } from "react-i18next";
import UserContext from "../../pages/Context";
import "./SidebarNew.css";
import TemplateApi from "../../api/TemplateApi";
import classes from "./../SidebarNew/SidebarNew.module.css";
import bz from "./assets/bz white.svg";
class SidebarNew extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const { t } = this.props;
    const transPage = "sidebar.";
    this.state = {
      contentDropdown: false,
      jobPosterDropdown: false,
      settingDropdown: false,
      settingBZDropdown: false,
      templateDropdown: false,
      adminDropdown: true,
      privilege: false,
      templates: [],
      siteSelected: {},
      tmp_id: 0,
      activeTmp: null,
      isClickTmp: false,
      imageSrc: "/img/bzpublish.png",
      // showSidebarValue: true,
      activeItems: "",
      thisRoute: "/",
      items: [
        {
          name: t(transPage + "content"),
          action: "contentDropdown",
          icon: "fa fa-palette",
          privilege: [1, 2, 6],
          child: [
            {
              index: 0,
              url: "/",
              icon: "fa fa-sitemap",
              privilege: [2, 6],
              name: t(transPage + "structure"),
            },
            {
              index: 1,
              url: "#",
              icon: "fa fa-book",
              privilege: [2, 6],
              name: t(transPage + "pages"),
            },
            {
              index: 2,
              url: "/menu-list",
              icon: "fa fa-location-arrow",
              privilege: [2, 6],
              name: t(transPage + "menu"),
            },
            {
              index: 3,
              url: "/article",
              icon: "fa fa-newspaper",
              privilege: [1, 2, 6],
              name: t(transPage + "articles"),
            },
            {
              index: 4,
              url: "/asset",
              icon: "fa fa-photo-video",
              privilege: [2, 6],
              name: t(transPage + "assets"),
            },
            {
              index: 20,
              url: "/location",
              icon: "fa fa-map-marker",
              privilege: [2, 6],
              name: t(transPage + "location"),
            },
          ],
        },
        {
          name: t(transPage + "job_poster"),
          action: "jobPosterDropdown",
          icon: "fa fa-briefcase",
          activeState: this.jobPosterDropdown,
          privilege: [3, 5],
          child: [
            {
              index: 5,
              url: "/job-posters",
              icon: "fa fa-circle",
              privilege: [3, 5],
              name: t(transPage + "jobs"),
            },
          ],
        },
        {
          name: t(transPage + "settings"),
          action: "settingDropdown",
          icon: "fa fa-briefcase",
          activeState: this.settingDropdown,
          privilege: [4],
          child: [
            {
              index: 7,
              url: "/category",
              icon: "fa fa-layer-group",
              privilege: [4],
              name: t(transPage + "category"),
            },
            {
              index: 8,
              url: "/about",
              icon: "fa fa-info-circle",
              privilege: [4],
              name: t(transPage + "company_information"),
            },
            {
              index: 9,
              url: "/email-settings",
              icon: "fa fa-envelope",
              privilege: [4],
              name: t(transPage + "email_settings"),
            },
            {
              index: 10,
              url: "/email-template",
              icon: "fa fa-envelope",
              privilege: [4],
              name: t(transPage + "email_template"),
            },
            {
              index: 11,
              url: "/s3-bucket-settings",
              icon: "fa fa-archive",
              privilege: [4],
              name: t(transPage + "s3_bucket_settings"),
            },
            {
              index: 12,
              url: "/ga-number-settings",
              icon: "far fa-chart-bar fa-fw",
              privilege: [4],
              name: t(transPage + "ga_number_code_settings"),
            },
          ],
        },
        {
          name: t(transPage + "settings_bz_admin"),
          action: "settingBZDropdown",
          icon: "fa fa-user-cog",
          activeState: this.settingBZDropdown,
          privilege: [99],
          child: [
            {
              index: 14,
              url: "/section/catalog",
              icon: "fa fa-align-left",
              privilege: [99],
              name: t(transPage + "sections"),
            },
            {
              index: 15,
              url: "/section-categories",
              icon: "fa fa-tag",
              privilege: [99],
              name: t(transPage + "section_categories"),
            },
            {
              index: 16,
              url: "/widgets",
              icon: "fa fa-puzzle-piece",
              privilege: [99],
              name: t(transPage + "widgets"),
            },
            {
              index: 17,
              url: "/template",
              icon: "fa fa-pager",
              privilege: [99],
              name: t(transPage + "templates"),
            },
            {
              index: 18,
              url: "/user",
              icon: "fa fa-user",
              privilege: [99],
              name: t(transPage + "user"),
            },
          ],
        },
      ],
    };

    this.setDropdown = this.setDropdown.bind(this);
    this.getTemplates = this.getTemplates.bind(this);
  }

  componentDidMount() {
    const contextValue = this.context;
    const url = window.location.href;
    const newUrl = new URL(url);
    const pathN = newUrl.pathname;
    const siteId = localStorage.getItem("siteId");
    this.getTemplates(siteId);

    if (pathN !== "/") {
      this.setDefaultActiveMenu(pathN);
    }
    this.setState({
      privilege: contextValue.user.is_superuser
        ? contextValue.user.is_superuser
        : contextValue.employee_privileges
          ? contextValue.employee_privileges
          : false,
    });
  }

  componentDidUpdate(prevState) {
    const thisRoute = this.state.thisRoute;
    const currentRoute = this.props.currentRoute;

    var isChange = true;
    const nextID = parseInt(localStorage.getItem("siteId"));
    const prevID = parseInt(prevState.activeSite);
    const prevTmp = parseInt(prevState.activeTemplate);
    const currentTmpID = parseInt(localStorage.getItem("templateId"));

    // console.log("site : ", nextID);
    // console.log("tmp :", currentTmpID);

    // console.log("prevTmp", prevTmp);

    if (prevID !== nextID) {
      if (currentTmpID) {
        isChange = true;
      }
    } else {
      isChange = false;
    }

    if (this.state.isChange !== isChange) {
      this.setState({
        isChange: isChange,
        tmp_id: currentTmpID,
      });

      this.getTemplates(nextID);
    }

    if (thisRoute !== currentRoute) {
      this.setState(
        {
          thisRoute: currentRoute,
        },
        () => {
          this.setDefaultActiveMenu(currentRoute);
        }
      );
    }
  }

  getTemplates(value) {
    const objSite = new TemplateApi();

    objSite.getSites(value).then((res) => {
      this.setState({
        templates: res.data.templates,
      });
    });
  }

  setDefaultActiveMenu = (currentRoute) => {
    let index, side;
    switch (currentRoute) {
      case "/":
        index = 0;
        side = "contentDropdown";
        break;
      case "/menu-list":
        index = 2;
        side = "contentDropdown";
        break;
      case "/article":
        index = 3;
        side = "contentDropdown";
        break;
      case "/asset":
        index = 4;
        side = "contentDropdown";
        break;
      case "/location":
        index = 20;
        side = "contentDropdown";
        break;
      case "/job-posters":
        index = 5;
        side = "jobPosterDropdown";
        break;
      case "/user":
        index = 6;
        side = "settingDropdown";
        break;
      case "/category":
        index = 7;
        side = "settingDropdown";
        break;
      case "/about":
        index = 8;
        side = "settingDropdown";
        break;
      case "/email-settings":
        index = 9;
        side = "settingDropdown";
        break;
      case "/email-template":
        index = 10;
        side = "settingDropdown";
        break;
      case "/s3-bucket-settings":
        index = 11;
        side = "settingDropdown";
        break;
      case "/ga-number-settings":
        index = 12;
        side = "settingDropdown";
        break;
      case "/language":
        index = 13;
        side = "settingDropdown";
        break;
      case "/section/catalog":
        index = 14;
        side = "settingBZDropdown";
        break;
      case "/section-categories":
        index = 15;
        side = "settingBZDropdown";
        break;
      case "/widgets":
        index = 16;
        side = "settingBZDropdown";
        break;
      case "/template":
        index = 17;
        side = "settingBZDropdown";
        break;
      case "/superuser":
        index = 18;
        side = "settingBZDropdown";
        break;
      case "language-json":
        index = 19;
        side = "settingBZDropdown";
        break;
      default:
        index = "";
        side = "";
        break;
    }
    this.setState(
      (prevState) => {
        return {
          activeItems: index,
          [side]: true,
        };
      },
      () => {
        window.scroll(0, 0);
      }
    );
  };

  setDropdown = (myState) => {
    this.setState((prevState) => {
      return {
        [myState]: !prevState[myState],
      };
    });
  };

  handleLogout = () => {
    const contextValue = this.context;

    removeUserSession();
    contextValue.removeDataUser();
    setTimeout(() => {
      // window.location.href = "/login";
      this.props.history.push("/login");
    }, 500);
  };

  setActiveItems = (index) => {
    this.setState({
      activeItems: index,
    });
  };

  setActiveTmp = (val) => {
    const contextValue = this.context;
    localStorage.setItem("templateId", val);
    contextValue.changeActiveTemplate(val);
    this.setState({
      activeTmp: val,
      tmp_id: val,
    });
  };

  render() {
    let Title = "Template";
    let action = "templateDropdown";
    let openMenuTemplate = this.state.templateDropdown;
    let iconTemplate = "fa fa-layer-group";

    let TemplateAll = this.state.templates.map((el) => {
      var isActived = false;

      if (el.id === this.state.tmp_id) {
        isActived = true;
      } else {
        isActived = false;
      }

      let activeStyle = !this.props.showSidebarValue
        ? classes.padActive
        : classes.padInActive;

      return (
        <li
          className={`nav-item`}
          key={el.id}
          defaultValue={this.state.tmp_id}
          onClick={() => {
            this.setState(
              {
                tmp_id: el.id,
              },
              () => this.setActiveTmp(el.id)
            );
          }}
        >
          <Link
            className={["nav-link", activeStyle].join(" ")}
            data-toggle={this.props.showSidebarValue ? "tooltip" : ""}
            data-placement="right"
          >
            <i className="fa fa-pager"></i>
            <p className={isActived ? "sd1-active-items" : ""}>{el.name}</p>
          </Link>
        </li>
      );
    });

    let TemplateNav = (
      <NavItem
        menuOpen={openMenuTemplate}
        onClick={() => this.setDropdown(action)}
        icon={iconTemplate}
        title={Title}
        navClass={`nav-item-c`}
        showSidebarValue={this.props.showSidebarValue}
      >
        {TemplateAll}
      </NavItem>
    );

    const { user, privilege } = this.state;
    let allItems = this.state.items.map((itm, idx) => {
      let children;
      if (itm.child && itm.child.length > 0) {
        children = itm.child.map((x, i) => {
          let active = this.state.activeItems === x.index ? true : false;
          let menuItems = (
            <MenuItem
              showSidebarValue={this.props.showSidebarValue}
              icon={x.icon}
              url={x.url}
              onClick={() => this.setActiveItems(x.index)}
              isActive={active}
              onlyPages={x.index === 1 ? true : false}
            >
              {x.name}
            </MenuItem>
          );
          if (privilege && !privilege.length) {
            return <>{menuItems}</>;
          } else if (privilege.length > 0) {
            let items = [];
            let idxItems = [];
            privilege.forEach((p) => {
              if (x.privilege.includes(p.id) && !idxItems.includes(x.name)) {
                idxItems.push(x.name);
                items.push(menuItems);
              }
            });
            return <>{items}</>;
          }
        });
      }
      let openMenu;
      switch (idx) {
        case 0:
          openMenu = this.state.contentDropdown;
          break;
        case 1:
          openMenu = this.state.jobPosterDropdown;
          break;
        case 2:
          openMenu = this.state.settingDropdown;
          break;
        case 3:
          openMenu = this.state.settingBZDropdown;
          break;
        default:
          break;
      }

      let navItems = (
        <NavItem
          menuOpen={openMenu}
          onClick={() => this.setDropdown(itm.action)}
          icon={itm.icon}
          title={itm.name}
          navClass={`nav-item-c${idx}`}
          showSidebarValue={this.props.showSidebarValue}
        >
          {children}
        </NavItem>
      );
      if (privilege && privilege.length === undefined) {
        return <>{navItems}</>;
      } else if (privilege.length > 0) {
        let items = [];
        let idxItems = [];
        privilege.forEach((p) => {
          if (itm.privilege.includes(p.id) && !idxItems.includes(itm.name)) {
            idxItems.push(itm.name);
            items.push(navItems);
          }
        });
        return <>{items}</>;
      }
    });
    return (
      <div style={{ overflow: "hidden" }}>
        <aside className="main-sidebar main-sidebar-top sidebar-dark-primary elevation-4">
          <div
            className="sidebar"
            style={{ height: "100%", overflow: "hidden" }}
          >
            <div className="user-panel mt-3 mb-3 d-flex dashboardCustom">
              <div className="image img-avatar">
                <i className="fa fa-user fa-2x"></i>
              </div>
              <div className="info">
                <Link to={"/"}>
                  <div className="d-block text-white"> Dashboard </div>
                </Link>
              </div>
            </div>
            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                {TemplateNav}
              </ul>
            </nav>
            <div className={"borderLine"}>
              <hr />
            </div>

            {/* picture */}
            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                {allItems}
                <MenuItem
                  showSidebarValue={this.props.showSidebarValue}
                  icon="fa fa-power-off"
                  onClick={this.handleLogout}
                >
                  Logout
                </MenuItem>
              </ul>
            </nav>
          </div>
          <div className="logo-phenikaa">
            <div className="footer-phenikaa">
              <a href="https://baezeni.com/" target="_blank" rel="noreferrer">
                <img src={bz} alt="" />
              </a>
            </div>
          </div>
        </aside>
      </div>
    );
  }
}

export default withTranslation()(SidebarNew);
