import { gapi } from 'gapi-script'
import axios from "axios"
import config from "./../config/config"

gapi.load("client:auth2", function () {
    gapi.auth2.init({ client_id: "974721150096-94umf044gi0qicst64992ln34aj3abhd.apps.googleusercontent.com" })
    .then(res=>{
        console.log(res)
    })
});

class SitemapApi {
    base_api = config.base_api
    token = config.token
    
    isSignedIn() {
        return gapi.auth2.getAuthInstance().isSignedIn.get()
    }

    async logIn() {
        // if (!this.isSignedIn()) {
        return await this.authenticate()
            .then(this.loadClient)
        // }
    }

    async authenticate() {
        return await gapi.auth2.getAuthInstance()
            .signIn({ scope: "https://www.googleapis.com/auth/webmasters https://www.googleapis.com/auth/webmasters.readonly" })
            .then(function () { console.log("Sign-in successful"); },
                function (err) { console.error("Error signing in", err); });
    }

    async loadClient() {
        // gapi.client.setApiKey("YOUR_API_KEY");
        return await gapi.client.load("https://content.googleapis.com/discovery/v1/apis/searchconsole/v1/rest")
            .then(function () { console.log("GAPI client loaded for API"); },
                function (err) { console.error("Error loading GAPI client for API", err); });
    }

    submitSitemap(siteUrl, feedpath, customParams) {
        return gapi.client.webmasters.sitemaps.submit({
            "siteUrl": siteUrl,
            "feedpath": feedpath,
            ...customParams
        })
    }

    listSitemap(siteUrl, customParams) {
        return gapi.client.webmasters.sitemaps.list({
            "siteUrl": siteUrl,
            ...customParams
        })
    }

    getSitemap(siteUrl, feedpath, customParams) {
        return gapi.client.webmasters.sitemaps.get({
            "siteUrl": siteUrl,
            "feedpath": feedpath,
            ...customParams
        })
            .then(function (response) {
                // Handle the results here (response.result has the parsed body).
                console.log("Response", response);
            },
                function (err) { console.error("Execute error", err); });
    }

    deleteSitemap(siteUrl, feedpath, customParams) {
        return gapi.client.webmasters.sitemaps.delete({
            "siteUrl": siteUrl,
            "feedpath": feedpath,
            ...customParams
        })
            .then(function (response) {
                // Handle the results here (response.result has the parsed body).
                // console.log("Response", response);
            },
                function (err) { console.error("Execute error", err); });
    }

    queryAnalytics(siteUrl, startDate, endDate, options) {
        return gapi.client.webmasters.searchanalytics.query({
            "siteUrl": siteUrl,
            "resource": {
                ...options,
                "startDate": startDate,
                "endDate": endDate
            }
        })
            .then(function (response) {
                // Handle the results here (response.result has the parsed body).
                console.log("Response", response);
            },
                function (err) { console.error("Execute error", err); });
    }

    generateSitemap(templateId, body) {
        return axios.post(this.base_api + "templates/" + templateId + "/generate_sitemap/", body, {
            headers:{
                'Authorization': `Token ${this.token}`,
                "Content-Type":"application/json"
            }
        })
    }
}

export default SitemapApi;