import React, { Component, styled } from 'react';
import { Card , Dropdown }  from "react-bootstrap"
import { Link} from "react-router-dom"
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';


// import Card,{ CardHeader, CardTitle,CardBody, CardFooter  } from "../components/Card";
// import { Content, ContentHeader, ContentBody } from "./../../components/Content"

import ArticleApi from "../../api/ArticleApi"
import WidgetApi from "../../api/WidgetApi"

import { shortSentence } from "../../lib/StringHelper"

import "./ArticleElement.css"
// import { CardBody, CardTitle } from '../../components/Card';

class ArticlesElement extends Component {

    constructor(props) {
        super(props)

        this.state = {
            articles:[],
            selectedArticleId:{}
        }

        this.getWidgetDetail = this.getWidgetDetail.bind(this)
        this.onSortEnd = this.onSortEnd.bind(this)
        this.shouldCancelStart = this.shouldCancelStart.bind(this)
        this.checkSelectedArticle = this.checkSelectedArticle.bind(this)
        this.handleCheckedArticle = this.handleCheckedArticle.bind(this)
    }

    componentDidMount(){

        this.getWidgetDetail(this.props.widgetId)

         
        
    }

    componentWillReceiveProps(newProps){
        // JANGAN DI UNCOMMENT INI 
        // this.getWidgetDetail(newProps.widgetId)


        // FOR SELECTED CHECKBOX
        // this.state.selectedArticleId
        /*
            {
                {55:true},
                {65:true}
                ....
            }
        */
        var res = {}
        for(var i = 0; i < newProps.selectedArticles.length; i++) {
            res[newProps.selectedArticles[i].id] = true
        }

        this.setState({
            selectedArticleId:res
        },() => {
            // console.log("state selectedId => ", this.state.selectedArticleId)
        })
        // =============================================================
    }

    getWidgetDetail(neoWidgetId) {

        const objWidget = new WidgetApi()
        const widgetId = neoWidgetId

        const params = {
            section:this.props.section
        }

        objWidget.getDetailWidget(widgetId, params)
        .then(res => {
            
            // console.log(" ==> ", res.data)

           this.setState({
               articles:res.data.articles
           })
           
        })
        .catch(err => {
            console.log(err.message)
        })
    }

    

    onSortEnd({oldIndex, newIndex}) {

        const objArticle = new ArticleApi()

        const widgetId = this.props.widgetId

        this.setState(({articles}) => ({
          articles: arrayMove(articles, oldIndex, newIndex),
        }),() => {


            const ids = this.state.articles.map(item => {
                return item.id
            })
            //console.log(" ===> ",this.state.articles)
            // alert("do something")
            objArticle.widgetArticleIndexUpdate(widgetId, ids)
            .then(res => {
                
                this.getWidgetDetail(widgetId)
               
            })
            .catch(err => {
                console.log(err)
            })
    
        });
    };

    shouldCancelStart(e) {
        //alert(JSON.stringify(e))
        // Cancel sorting if the event target is an anchor tag (`a`)
        if (e.target.tagName.toLowerCase() === 'a') {
            return true; // Return true to cancel sorting
        }
    }

    checkSelectedArticle(value) {
        

        return this.state.selectedArticles.findIndex(item => item.id === value.id) > 0
    }

    handleCheckedArticle(value ) {


        // console.log(" value : ", this.state.selectedArticleId)
        var checked = this.props.selectedArticles.find(element => { 
            // console.log(element.id," 8D ",value.id)    
            return element.id === value.id
        })

        // because checked from checkbox doesnt work 
        if(typeof(checked) === "undefined") {
            checked = true
        } else{
            checked = false
        }

        // console.log("checked ==> ", checked)

        this.props.setSelectedArticle("article",value, checked)
    }
    

    render() {

        // alert("render..")

        const SortableItem = sortableElement(item => {

            const value = item.value

            const findArticle = this.props.articles.find(row => row.id === value.id)
            
            var scontent = findArticle?.article_languages[1] ?  findArticle.article_languages[1]?.content : ""
            var stitle = findArticle?.article_languages[1] ? findArticle.article_languages[1]?.title : ""

            var shortContent = shortSentence(scontent, 10)
            var shortTitle = shortSentence(stitle, 5)

            var headBackGround = /*this.state.selectedArticleId[value.id] ? "bg-danger" :*/ "bg-primary"

            return(
                <div className="col-md-2">
                    <Card >
                        {/* <Card.Img variant="top" src={"/img/sample-asset.png"} style={{padding:"2%"}}></Card.Img> */}
                        <Card.Header style={{color:"black"}} className={headBackGround} onClick={() => { }}>   
                           
                            <div className="form-check form-check-inline">
                               
                                <input onClick={(event) => this.handleCheckedArticle(value)} 
                                        type="checkbox" 
                                        value="" 
                                        className="form-check-input" 
                                        id={"label"+value.id} 
                                        // selectedArticleId[value.id]
                                        defaultChecked={this.state.selectedArticleId[value.id]}
                                    ></input>&nbsp;
                                <label 
                                    title={shortTitle} 
                                    className="form-check-label" 
                                    htmlFor={"label"+value.id}
                                ><b >{value.index}. <span>{ shortTitle }</span></b> 
                                </label>
                            </div>
                            {/* <div className="card-tools">
                                <Dropdown >
                                    <Dropdown.Toggle  id="dropdown-basic" as={"span"} >  
                                        <a href="#" style={{color:"black"}}><i className="fa fa-ellipsis-v"></i></a>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href={`/article/detail/${value.id}`}>Update</Dropdown.Item>
                                        
                                    </Dropdown.Menu>
                                </Dropdown>
                                
                            </div> */}
                        </Card.Header>
                        <a  href={`/article/detail/${value.id}/${this.props.widgetId}`}>
                            <Card.Body style={{color:"black", overflow:"hidden"}}>
                                <Card.Title>
                                
                                    <p> { shortTitle } ...</p>
                                    <hr></hr>
                                </Card.Title>
                                <Card.Text style={{overflow:"hidden", height:160}}>
                                    <span style={{fontSize:12}} dangerouslySetInnerHTML={{__html:shortContent}}></span>
                                
                                </Card.Text>
                                <Card.Text style={{fontSize:12}}>
                                        <b>{value.status}</b>
                                </Card.Text>
                            </Card.Body>
                        </a>
                        
                    </Card>
                </div>
            )
        });

        const SortableContainer = sortableContainer(({children}) => {
            return <ul className="row" >{children}</ul>;
        });

        const {articles} = this.state;

        return (
            <div style={{marginLeft:"-2%"}}>
               <SortableContainer axis="xy" onSortEnd={this.onSortEnd} distance={1} shouldCancelStart={(e) => this.shouldCancelStart(e)}>
                    {articles.map((value, index) => (
                        <SortableItem key={`item-${value.name}`} index={index} value={value} />
                    ))}
                  
               </SortableContainer>
            </div>
        );
    }
}

export default ArticlesElement;