import React, { Component } from 'react'

import Context from "./Context";

import MainSidebar from './MainSidebar/MainSidebar'
import ContentPages from './Content/Content'
import classes from './NewSideBar.module.css'

import bzLogo from './assets/bzpublish.png'
import bzSetting from './assets/settings.svg'

export default class NewSidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeSidebar: true,
            toogleActive: () => {
                this.toogleActive();
            }
        }
        this.toogleActive = this.toogleActive.bind(this)
    }

    componentDidMount() {
        // console.log('test')
    }

    toogleActive() {
        this.setState((prevState) => {
            return {
                activeSidebar: !prevState.activeSidebar,
            };
        });
    }

    render() {
        return (
            <Context.Provider value={this.state}>
                <div className={classes.topNav}>
                    <div className={classes.navLogo}>
                        <img src={bzLogo} alt="logo" />
                    </div>
                    <div className={classes.navSettings}>
                        <div className={classes.navIcon}>
                            <img src={bzSetting} alt="icon"/>
                        </div>
                    </div>
                </div>
                <div className={classes.customContainer}>
                    <div className="d-flex">
                        <MainSidebar />
                        <ContentPages />
                    </div>
                </div>
            </Context.Provider>
        )
    }
}
