import React, { Component } from 'react';
import { Modal, Button, Tab, Tabs, ListGroup } from "react-bootstrap"
import { Link } from "react-router-dom"
import Pagination from "react-js-pagination";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import swal from "sweetalert"
import AlertHelper from "./../../lib/AlertHelper"

import Card, { CardHeader, CardTitle, CardBody, } from "../Card";
// import { Content, ContentHeader, ContentBody } from "../components/Content"
import SectionApi from "../../api/SectionApi"
import SectionCategoryApi from "./../../api/SectionCategoryApi"
import GeneralApi from "./../../api/GeneralApi"
import { withTranslation } from 'react-i18next';
import Context from "../../pages/Context";

class SectionPair extends Component {
  static contextType = Context;
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      names: [],
      sections: [],
      selectedSections: [],
      selectedSection: {},
      categories: [],
      category: "",
      searchSection: "",

      languageList: [],
      tabKey: 1,
      selectedLanguage: 1,
      renderTabLanguage: "",
      idElement: 0,

      activePage: 1,
      limitPageData: 9,
      category: "",
      totalCount: 0,

      transPage: "modals.section_pair.",
      transAlert: "alert."
    }

    this.timeouts = {}

    this.getSectionList = this.getSectionList.bind(this)
    this.getAllSectionCategories = this.getAllSectionCategories.bind(this)
    this.setSelectedItem = this.setSelectedItem.bind(this)
    this.onOpenModal = this.onOpenModal.bind(this)
    this.onCloseModal = this.onCloseModal.bind(this)

    this.pairSection = this.pairSection.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleCategoryChange = this.handleCategoryChange.bind(this)

    this.renderTabLanguage = this.renderTabLanguage.bind(this)
  }

  onOpenModal() {
    const { languagesList } = this.context
    this.setState({
      languageList: languagesList
    }, () => {
      this.getSectionList()
      this.getAllSectionCategories()
      this.renderTabLanguage()
    })
  }

  onCloseModal() {
    this.setState({
      sections: [],
      names: [],
      searchSection: "",
      category: ""
    }, () => {
      this.props.onHide()
    })
  }

  getSectionList() {
    const objSection = new SectionApi()

    objSection.sectionCatalogList({
      page_size: this.state.limitPageData,
      page: this.state.activePage,
      category: this.state.category,
      name: this.state.searchSection
    })
      .then(res => {

        this.setState({
          sections: res.data.results,
          totalCount: res.data.count,
        })
      })
  }

  getAllSectionCategories() {
    this.setState({
      categories: [],
    });

    const sectionObj = new SectionCategoryApi();

    sectionObj
      .getAllSectionCategories()
      .then((res) => {
        this.setState({
          categories: res.data.results,
        });
      });
  }

  setSelectedItem(section) {
    this.setState({
      selectedSection: section
    })
  }

  pairSection() {
    const { t } = this.props;
    const objSection = new SectionApi()

    const sectionLanguages = this.state.names.map(item => {
      return {
        language: item.language,
        name: item.name
      }
    })

    const dt = {
      name: this.state.name,
      page: this.props.pageId,
      // folder_name:this.state.selectedSection.folder_name,
      menu: this.state.selectedSection.menu,
      parent: this.state.selectedSection.id,
      section_languages: sectionLanguages
    }

    objSection.sectionAdd(dt)
      .then(res => {
        this.props.getPageDetail()
        //console.log(res.data)

        // this.setState({
        //     sections:res.data
        // })

        swal("Success !", t(this.state.transAlert + "section_pair_add_success"), "success")
        this.props.onHide()
        window.location.reload()
      })
      .catch(err => {
        const objAlert = new AlertHelper()
        const resErr = err?.response?.data ? err?.response?.data : err.message
        //console.log(" Error => ",err?.response?.data)
        objAlert.errorHandlerAlert(resErr)
      })
  }

  handleNameChange(id, value) {

    let names = [...this.state.names]
    let name = { ...names[id] }
    name.name = value
    names[id] = name

    this.setState({
      name: names[0].name,
      names: names
    })

  }

  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,

    }, () => {
      this.getSectionList()
    });
  }

  handleChangeTab(value) {
    this.setState(
      {
        selectedLanguage: value,
        tabKey: value,
      },
      () => {
        this.renderTabLanguage()
      }
    )
  }

  handleCategoryChange(value) {
    const newValue = value !== this.state.category ? value : '';
    this.setState({
      category: newValue,
      activePage: 1
    }, () => {
      this.getSectionList()
    })
  }

  handleSearchSectionChange(value) {
    clearTimeout(this.timeouts.search)
    this.setState({
      searchSection: value,
      activePage: 1
    }, () => {
      this.timeouts.search = setTimeout(() => {
        this.getSectionList()
      }, 333)
    })
  }

  renderTabLanguage() {

    const renderTabLanguage = this.state.languageList.map((item, index) => {

      var findName = this.state.names.find((textLang, idx) => item.id === textLang.language)
      return {
        id: findName?.id,
        language: item.id,
        //   name: findName ? findName.name : "",
        languageName: item.name,
        //   page: this.state.selectedPage,
        //   section: this.state.selectedSection,
        name: findName?.name,
      }

    })

    this.setState({
      names: renderTabLanguage,
    },
      () => {

      })
  }

  render() {
    const { t } = this.props;
    const TabLanguage = this.state.names.map((item, index) => {
      // console.log("item" ,item )

      return (
        <Tab key={index} eventKey={item.language} title={item.languageName}>
          <br></br>

          <div className='form-group'>
            <input
              className="form-control col-md-4 mb-2"
              type="text"
              value={this.state.searchSection}
              placeholder={t(this.state.transPage + 'search_section')}
              onChange={(e) => { this.handleSearchSectionChange(e.target.value) }}
            />

            <label> {t('name')} </label>
            <textarea className='form-control' onChange={(event) => this.handleNameChange(index, event.target.value)} value={this.state.names[index].name}></textarea>
            {/* { JSON.stringify( this.state.text_languages )  } */}
          </div>
        </Tab>
      )
    })

    const SectionList = this.state.sections.map((item, index) => {
      const langId = localStorage.getItem("lang") === "en" ? 0 : 1;
      return (
        <div className='col-md-4' kye={index}>
          <Card className='card card-outline card-primary'>
            <CardHeader className=''>
              <CardTitle>
                <input id={item.name + "-" + item.id} name="selectedSection" className='' type='radio' onClick={(event) => this.setSelectedItem(item)}></input>
                        &nbsp;
                        <label htmlFor={item.name + "-" + item.id}>
                  {" "}
                  {item.section_languages[langId]?.name}
                </label>
              </CardTitle>
              <div className='card-tools'>
                <span>&nbsp;</span>
                <Link to={`/section/detail/${item.id}`}>
                  <Button className='btn-sm' variant='default'>
                    <i className='fa fa-arrow-right'></i>
                  </Button>
                </Link>
                <span>&nbsp;</span>

              </div>
            </CardHeader>
            <CardBody style={{ backgroundImage: `url("${item.screenshot}")`, backgroundColor: "blue" }}>
              <img width="100%" height={100} src={item.screenshot ? `${item.screenshot}` : "/img/phenikaa-default-image.svg"} alt={item.name}></img>
            </CardBody>
          </Card>
        </div>

      )
    })

    const SectionCategoriesItems = this.state.categories.map(item => {
      const langId = localStorage.getItem("lang") === "en" ? 0 : 1;
      return (
        <ListGroup.Item
          key={item.id + item.name}
          onClick={() => this.handleCategoryChange(item.id)}
          active={item.id === this.state.category}
          action
        >
          {item.category_languages[langId].name}
        </ListGroup.Item>
      )
    })

    return (
      <Modal show={this.props.show} onHide={() => this.onCloseModal()} onShow={() => this.onOpenModal()} size="xl">
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title>{t(this.state.transPage + 'add_section')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-3">
              <h5 className="mb-4">{t(this.state.transPage + 'section_category')}</h5>
              <ListGroup>
                {SectionCategoriesItems}
              </ListGroup>
            </div>
            <div className="col-9">
              {/* <div className="form-group">
                          <label> Name </label>
                          <input type="text" className="form-control" value={this.state.name} onChange={(event) => this.handleNameChange(event.target.value)}></input>
                      </div> */}
              {/* { JSON.stringify(this.state.names)} */}
              <Tabs>
                {TabLanguage}
              </Tabs>

              {/* <Tabs className="mb-2" defaultActiveKey="all" id="uncontrolled-tab-example">
                <Tab eventKey="home" title="Used Sections">
                </Tab>
                <Tab eventKey="profile" title="Favorite Sections">
                </Tab>
                <Tab eventKey="contact" title="My Private Sections">
                </Tab>
                <Tab eventKey="all" title="All Sections">
                </Tab>
              </Tabs> */}

              <div className="row">
                {SectionList}
              </div>
              <div className="d-flex align-items-center">
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.limitPageData}
                  totalItemsCount={this.state.totalCount}
                  pageRangeDisplayed={4}
                  onChange={this.handlePageChange.bind(this)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
                <p className="ml-3">{t('total')} {this.state.totalCount}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={() => this.pairSection()}>
            <i className="fa fa-plus">&nbsp;</i>
            {t(this.state.transPage + 'pair_section')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(SectionPair);