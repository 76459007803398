import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import Flag from "react-flags";
import { withTranslation } from "react-i18next";
import { removeUserSession } from "../../lib/Common";
import { withCookies, Cookies } from "react-cookie";

import UserContext from "../../pages/Context";
import SiteAddModal from "../Modal/SiteAddModal";

import SiteApi from "../../api/SiteApi";
import bzLogo from "./assets/bzpublish.png";
import bzSetting from "./assets/settings.svg";
import pheEn from "./assets/logo-en.png";
import pheVn from "./assets/logo-vn.png";

import "./Header.css";
class HeaderNew extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const { t } = this.props;
    const transPage = "pages.header.";
    this.state = {
      sites: [],
      activeSites: false,
      activeSiteData: false,
      transPage: "pages.header.",
      navItems: [
        {
          index: 1,
          name: t(transPage + "create_new_site"),
        },
        {
          index: 2,
          name: t(transPage + "upload_site"),
        },
        {
          index: 3,
          name: t(transPage + "manage_sitemap"),
        },
        {
          index: 4,
          name: t(transPage + "logout"),
        },
      ],
      setShowSiteAdd: false,
      setShowSiteUpdate: false,
    };
    this.getSites = this.getSites.bind(this);
    this.handleChangeSites = this.handleChangeSites.bind(this);
    this.setShowSiteAdd = this.setShowSiteAdd.bind(this);
    this.setShowSiteUpdate = this.setShowSiteUpdate.bind(this);
  }

  componentDidMount() {
    this.getSites();
  }

  getSites() {
    const objSite = new SiteApi();
    const contextValue = this.context;

    objSite.getAllSites().then((res) => {
      res.data.results.forEach((a, i) => {
        // let activeTemplate = false
        // a.templates.forEach(aT => {
        //     if (aT.active) {
        //         activeTemplate = aT.id
        //     }
        // })

        let activeTemplate = localStorage.getItem("templateId");
        if (activeTemplate) {
          if (contextValue.activeSite) {
            if (a.id === parseInt(contextValue.activeSite)) {
              this.handleChangeSites(
                parseInt(contextValue.activeSite),
                a,
                activeTemplate
              );
            }
          } else {
            if (a.id === 5) {
              this.handleChangeSites(a.id, a, activeTemplate);
            }
          }
        }
      });
      this.setState({
        sites: res.data.results,
      });
    });
  }

  handleChangeSites(value, param, template) {
    const contextValue = this.context;

    let activeSiteData, activeTemplate;
    activeTemplate = template;
    this.state.sites.forEach((a, i) => {
      if (a.id === parseInt(value)) {
        activeSiteData = a;
        a.templates.forEach((aT) => {
          if (aT.active) {
            activeTemplate = aT.id;
          }
        });
      }
    });

    this.setState(
      {
        activeSiteData: param !== "" ? param : activeSiteData,
        activeSites: value,
      },
      () => {
        contextValue.changeActiveSite(parseInt(value));
        setTimeout(() => {
          contextValue.changeActiveTemplate(parseInt(activeTemplate));
        }, 100);
      }
    );
  }

  handleLogout = () => {
    const contextValue = this.context;

    removeUserSession();
    contextValue.removeDataUser();
    setTimeout(() => {
      window.location.href = "/login";
    }, 500);
  };

  setShowSiteAdd() {
    this.setState({
      setShowSiteAdd: !this.state.setShowSiteAdd,
    });
  }

  setShowSiteUpdate() {
    this.setState({
      setShowSiteUpdate: !this.state.setShowSiteUpdate,
    });
  }

  render() {
    const { t } = this.props;
    const { navItems, activeSites } = this.state;
    const contextValue = this.context;

    let optionItem = navItems.map((s, i) => {
      let items;
      if (s.index === 1) {
        items = (
          <li onClick={this.setShowSiteAdd}>
            <span>{s.name}</span>
          </li>
        );
      } else if (s.index === 2) {
        items = activeSites ? (
          <li onClick={this.setShowSiteUpdate}>
            <span>{s.name}</span>
          </li>
        ) : (
          <li style={{ cursor: "not-allowed" }}>
            <span style={{ opacity: ".6" }}>{s.name}</span>
          </li>
        );
      } else if (s.index === 3) {
        items = (
          <li>
            <Link to="/sitemap-settings">{s.name}</Link>
          </li>
        );
      } else if (s.index === 4) {
        items = (
          <li onClick={this.handleLogout}>
            <span>{s.name}</span>
          </li>
        );
      }
      return items;
    });

    return (
      <div className="topNav">
        <div className="navLogo">
          <Link to={"/"}>
            <img
              src={localStorage.getItem("lang") === "vi" ? pheVn : pheEn}
              alt="logo"
            />
          </Link>
        </div>
        <Nav variant="pills" className="navbar-nav ml-auto navLanguage">
          {contextValue.user.is_superuser ? (
            <div>
              <select
                className="form-control nav-L-sel-c"
                value={this.state.activeSites}
                onChange={(event) => {
                  this.handleChangeSites(event.target.value, "", "");
                }}
              >
                <option disabled value="">
                  {" "}
                  -- {t(this.state.transPage + "select_file")} --{" "}
                </option>
                {this.state.sites.map((item) => {
                  return <option value={item.id}> {item.name} </option>;
                })}
              </select>
            </div>
          ) : (
            <></>
          )}

          <Nav.Item className="nav-item mt-auto mb-auto">
            <Nav.Link
              className="nav-link"
              active={localStorage.getItem("lang") === "vi" ? 1 : ""}
              data-widget="control-sidebar"
              data-slide="true"
              href="#"
              onClick={() => {
                this.props.changeLanguage("vi");
              }}
              role="button"
            >
              <Flag
                name="VN"
                format="png"
                pngSize={24}
                shiny={false}
                alt="Vietnam Flag"
                basePath="/img/flags"
                title="Vietnam"
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item mt-auto mb-auto">
            <Nav.Link
              className="nav-link"
              style={{ padding: -5 }}
              active={localStorage.getItem("lang") === "en" ? 1 : ""}
              data-widget="control-sidebar"
              data-slide="true"
              href="#"
              onClick={() => {
                this.props.changeLanguage("en");
              }}
              role="button"
            >
              <Flag
                name="GB"
                format="png"
                pngSize={24}
                shiny={false}
                alt="English Flag"
                basePath="/img/flags"
                title="United States"
              />
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {contextValue.user.is_superuser ? (
          <div className="navSettings">
            <div className={"slNav"}>
              <div className={"contentDropdown"}>
                <ul>
                  <li>
                    <div className="navIcon">
                      <img src={bzSetting} alt="icon" />
                    </div>
                    <div className={"block"}></div>
                    <div className={"triangle"}></div>
                    <ul>{optionItem}</ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <SiteAddModal
          show={this.state.setShowSiteAdd}
          onHide={this.setShowSiteAdd}
          getSites={this.getSites}
        ></SiteAddModal>
        <SiteAddModal
          show={this.state.setShowSiteUpdate}
          activeSites={this.state.activeSites}
          activeSiteData={this.state.activeSiteData}
          getSites={this.getSites}
          onHide={this.setShowSiteUpdate}
        ></SiteAddModal>
      </div>
    );
  }
}

export default withTranslation()(HeaderNew);
