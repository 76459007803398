import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"
import swal from "sweetalert"
import AlertHelper from "../../lib/AlertHelper"
import { withTranslation } from 'react-i18next';
import SectionApi from "../../api/SectionApi"

class JobCollectionDeleteModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            transPage: "modals.job_collection_delete."
        }

        this.deleteJobCollection = this.deleteJobCollection.bind(this)
        this.onOpenModal = this.onOpenModal.bind(this)
    }

    deleteJobCollection() {
        const sectionApi = new SectionApi()

        sectionApi.widgetDelete(this.props.widgetId)
            .then(res => {
                this.props.onHide()
                swal("Success !", "You Sucessfully deleted Job Collection", "success")
                if(this.props.callbackSuccess) {
                    this.props.callbackSuccess()
                }
            })
            .catch(err => {
                const objAlert = new AlertHelper()
                // const resErr = err?.response?.data ? err?.response?.data : err.message
                const resErr = err.message
                //console.log(" Error => ",err?.response?.data)
                objAlert.errorHandlerAlert(resErr)
            })
    }

    onOpenModal() {
        //console.log("modal open")
    }

    onCloseModal() {
        this.props.onHide()
    }


    render() {
        const { t } = this.props;
        const listJobCollection = this.props.selectedJobCollection.map(item => {
            return (<li key={item.id}>{item.name}</li>)
        })

        return (
            <Modal show={this.props.show} onHide={() => this.onCloseModal()} onShow={() => this.onOpenModal()}>
                {this.props.loadingModal}
                <Modal.Header closeButton className="bg-danger">
                    <Modal.Title>{t(this.state.transPage + "title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t(this.state.transPage + "confirmation_text")}</p>
                    <ul>
                        {listJobCollection}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.props.onHide()}>
                        {t('cancel')}
                    </Button>
                    <Button variant="danger" onClick={() => this.deleteJobCollection()}>
                        <i className="fa fa-trash">&nbsp;</i>
                        {t(this.state.transPage + "delete_job_collection")}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(JobCollectionDeleteModal);