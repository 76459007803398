import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ListGroup, Badge } from "react-bootstrap";
import Pagination from "react-js-pagination";

import ApplicantArchiveConfirm from "./ApplicantArchiveConfirm";
import AppicantUnArchieveConfirm from "./ApplicantUnarchiveConfirm";

import JobApi from "./../../api/JobApi";
import GeneralApi from "./../../api/GeneralApi";
import swal from "sweetalert";
import { withTranslation } from 'react-i18next';
import UserContext from '../Context'

/*
    Elementary School
    Junior High School
    Senior High School
    Bachelor's degree
    Master degree
    Doctoral degree
*/

const labelConfirmation = [
  {
    id: 1,
    label: "secondary"
  },
  {
    id: 2,
    label: "primary"
  },
  {
    id: 3,
    label: "info"
  },
  {
    id: 4,
    label: "success"
  }, {
    id: 5,
    label: "danger"
  }
]

const labelPriority = [
  {
    id: 1,
    label: "success"
  },
  {
    id: 2,
    label: "info"
  },
  {
    id: 3,
    label: "warning"
  },
  {
    id: 4,
    label: "danger"
  }
]

class ApplicantList extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      applicantId: undefined,
      applicants: [],

      totalCount: 0,
      limitPageData: 18,
      activePage: 1,

      countryFilter: "",
      genderFilter: "",
      search: "",
      height: "",
      countries: [],
      selectedApplicant: [],

      activeApplicant: true,
      applicantArchiveConfirm: false,
      applicantUnarchiveConfirm: false,

      isTable: false,
      transPage: "pages.applicant.",
      transAlert: "alert."
    };

    this.getApplicantList = this.getApplicantList.bind(this);
    this.getCountries = this.getCountries.bind(this);
    this.applicantArchive = this.applicantArchive.bind(this);

    this.setShowArchiveConfirm = this.setShowArchiveConfirm.bind(this);
    this.setShowUnarchiveConfirm = this.setShowUnarchiveConfirm.bind(this);
    this.archiveSelectedApplicant = this.archiveSelectedApplicant.bind(this);
    this.unarchiveSelectedApplicant = this.unarchiveSelectedApplicant.bind(
      this
    );

    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    var bb = "False";
    if (this.props.activeApplicant) {
      bb = "True";
    }

    if (window.screen.width >= 1441 && window.screen.height >= 900) {
      /*HD+ */
      this.setState({
        height: "170px",
      });
    } else if (window.screen.width >= 1024 && window.screen.height >= 768) {
      this.setState({
        height: "50%",
      });
    }

    this.setState(
      {
        activeApplicant: bb,
      },
      () => {
        this.getApplicantList();
        this.getCountries();
      }
    );
  }

  getCountries() {
    const generalObj = new GeneralApi();

    generalObj
      .getCountries()
      .then((res) => {
        this.setState({
          countries: res.data.results,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handlePageChange(pageNumber) {
    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.getApplicantList();
      }
    );
  }

  getApplicantList() {
    const obJob = new JobApi();

    obJob
      .getApplicant({
        page_size: this.state.limitPageData,
        page: this.state.activePage,

        gender: this.state.genderFilter,
        country: this.state.countryFilter,
        search: this.state.search,

        active: this.state.activeApplicant,
      })
      .then((res) => {
        this.setState({
          applicants: res.data.results,
          totalCount: res.data.count,
        });
      });
  }

  handleFilterChange(state, value) {
    this.setState(
      {
        [state]: value,
      },
      () => {
        this.getApplicantList();
      }
    );
  }

  setShowArchiveConfirm() {
    this.setState({
      applicantArchiveConfirm: !this.state.applicantArchiveConfirm,
    });
  }

  setShowUnarchiveConfirm() {
    this.setState({
      applicantUnarchiveConfirm: !this.state.applicantUnarchiveConfirm,
    });
  }

  applicantArchive(applicantId) {
    const { t } = this.props;
    const obj = new JobApi();

    obj
      .AppicantArchieve(applicantId, false)
      .then((res) => {
        swal("Success", t(this.state.transAlert + "archive_applicant_success"), "success");
        this.getApplicantList();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  applicantUnarchive(applicantId) {
    const { t } = this.props;
    const obj = new JobApi();

    obj
      .AppicantArchieve(applicantId, true)
      .then((res) => {
        swal(
          "Success",
          t(this.state.transAlert + "unarchive_applicant_success"),
          "success"
        );
        this.getApplicantList();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSelectedApplicant(applicant, value) {
    var lastSelected = [...this.state.selectedApplicant];
    var indexDelete = -1;
    applicant = {
      id: applicant.id,
      first_name: applicant.user.first_name,
      last_name: applicant.user.last_name,
    };

    // var stateSelected = ""

    if (value === true) {
      lastSelected.push(applicant);
    } else {
      //console.log("jalan donk harusnya ", article , this.state.selectedArticles)
      this.state.selectedApplicant.forEach((item, index) => {
        if (item.id === applicant.id) {
          indexDelete = index;
        }
      });

      lastSelected.splice(indexDelete, 1);
      //console.log("lastArticles => ",lastArticles)
    }

    this.setState(
      {
        selectedApplicant: lastSelected,
      },
      () => {
        if (this.state.selectedApplicant.length > 0) {
          this.setState({
            deleteDisable: false,
          });
        } else {
          this.setState({
            deleteDisable: true,
          });
        }
      }
    );
  }

  archiveSelectedApplicant(selectedApplicant) {
    const { t } = this.props;
    const obj = new JobApi();

    selectedApplicant = selectedApplicant.map((item) => {
      return item.id;
    });

    obj
      .AppicantArchieveMulti(selectedApplicant, false)
      .then((res) => {
        swal("Success", t(this.state.transAlert + "archive_applicant_success"), "success");

        setTimeout(function () {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => { });
  }

  unarchiveSelectedApplicant(selectedApplicant) {
    const { t } = this.props;
    const obj = new JobApi();

    selectedApplicant = selectedApplicant.map((item) => {
      return item.id;
    });

    obj
      .AppicantUnarchieveMulti(selectedApplicant, false)
      .then((res) => {
        swal(
          "Success",
          t(this.state.transAlert + "unarchive_applicant_success"),
          "success"
        );

        setTimeout(function () {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => { });
  }

  render() {
    const { t } = this.props;
    const { employee_privileges } = this.context;
    const isOnlyMonitor = employee_privileges.filter(e => e.id === 5).length > 0;

    const CardList = this.state.applicants.map((item, index) => {
      var image = item.avatar
        ? item.avatar
        : `${process.env.PUBLIC_URL}/img/phenikaa-default-image.svg`;

      const selectedApplicantIds = new Set(
        this.state.selectedApplicant.map(applicant => {
          return applicant.id
        })
      )

      return (
        <ListGroup
          key={index}
          as="div"
          className="col-md-2"
          style={{ marginBottom: 20 }}
        >
          <ListGroup.Item className={"bg bg-primary"}>
            <input
              id={index + item.user.username}
              type="checkbox"
              onClick={(event) => {
                this.handleSelectedApplicant(item, event.target.checked);
              }}
              checked={selectedApplicantIds.has(item.id)}
            ></input>{" "}
            &nbsp;
            <b>
              <label htmlFor={index + item.user.username}>
                {item.id}. {item.user?.first_name} {item.user?.last_name}{" "}
              </label>
            </b>
            <span style={{ float: "right" }}></span>
          </ListGroup.Item>
          <Link
            key={index}
            to={`/job-poster/applicant/detail/${item.id}`}
          >
            <ListGroup.Item>
              <img
                style={{ margin: "0 0", objectFit: "cover" }}
                src={image}
                width={"100%"}
                height={this.state.height}
                alt="profile"
              ></img>
            </ListGroup.Item>
            <ListGroup.Item style={{ color: "black" }}>
              <div> {t(this.state.transPage + "work_experience")} : {item.working_experience} </div>
              <div> {t(this.state.transPage + "last_ed")} : {item.last_education}</div>
            </ListGroup.Item>
          </Link>
          {/* <ListGroup.Item>
                  <div style={{}}><button className="btn btn-sm btn-secondary" title="archive this applicant" 
                      onClick={() => { 
                          
                          this.setState({
                              applicantId:item.id
                          },this.setShowArchiveConfirm())
                      }}
                  > Archive </button></div>
              </ListGroup.Item> */}
        </ListGroup>
      );
    })

    const TableFormat = <table className="table responsive-table table-border">
      <thead>
        <th></th>
        <th> {t('no')} </th>
        <th> {t('photo')} </th>
        <th> {t('name')} </th>
        <th> {t(this.state.transPage + "work_experience")} </th>
        <th> {t('education')} </th>
      </thead>
      <tbody>
        {this.state.applicants.map((item, index) => {

          var image = item.avatar ? item.avatar : `${process.env.PUBLIC_URL}/img/phenikaa-default-image.svg`

          const selectedApplicantIds = new Set(
            this.state.selectedApplicant.map(applicant => {
              return applicant.id
            })
          )

          return (<tr>
            <td>
              <input
                id={index + item.user.username}
                type="checkbox"
                onClick={(event) => {
                  this.handleSelectedApplicant(item, event.target.checked);
                }}
                checked={selectedApplicantIds.has(item.id)}
              ></input>
            </td>
            <td> {index + 1} </td>
            <td>
              <a href={`/job-poster/applicant/detail/${item.applicant}`}>
                <img alt="applicants" style={{ margin: "0 0" }} src={image} width={50} height={50}></img>
              </a>
            </td>
            <td> <b> {item.user.first_name} {item.user.last_name}</b> </td>
            <td> {item.experiences.map(item => {
              return <li>{item.title}</li>
            })}  </td>
            <td> {item.educations.map(item => {
              return <li>{item.title}</li>
            })}   </td>

          </tr>)
        })}
      </tbody>
    </table>

    var Content = CardList
    if (this.state.isTable) {
      Content = TableFormat
    }

    var disableArchive = true;
    if (this.state.selectedApplicant.length > 0) {
      disableArchive = false;
    }

    return (
      <>
        <div className="clearfix"></div>
        <b>{t('filter')}</b>
        <button
          disabled={disableArchive || isOnlyMonitor}
          className="btn btn-secondary float-right"
          onClick={() => {
            if (this.props.activeApplicant) {
              this.setShowArchiveConfirm()
            } else {
              this.setShowUnarchiveConfirm()
            }
          }}
        >
          {this.props.activeApplicant ? t('archive') : t('unarchive')}
        </button>
        <hr></hr>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label>{t('country')} </label>
              <select
                className="form-control"
                value={this.state.countryFilter}
                onChange={(event) =>
                  this.handleFilterChange("countryFilter", event.target.value)
                }
              >
                <option value=""> -- {t(this.state.transPage + "all_countries")} -- </option>
                {this.state.countries.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className=""></div>
            <div className="form-group">
              <label> {t('last_education')} </label>
              <select className="form-control">
                <option value=""> -- {t(this.state.transPage + "select_last_education")} -- </option>
                <option value="elementary highschool">Elementary School</option>
                <option value="junior highschool">Junior High School</option>
                <option value="senior highschool">Senior High School</option>
                <option value="elementary degree">Bachelor's degree</option>
                <option value="master degree">Master degree</option>
                <option value="doctoral degree">Doctoral degree</option>
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label> {t('search')} </label>
              <input
                type="text"
                value={this.state.search}
                className="form-control"
                onChange={(event) =>
                  this.handleFilterChange("search", event.target.value)
                }
              ></input>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10">

            <div className="float-left">
              <button className="btn btn-primary btn-sm float-right" onClick={() => this.setState({ isTable: true })}>
                <i className="fa fa-list"></i>
              </button>
              &nbsp;
              <button className="btn btn-primary btn-sm float-right" onClick={() => this.setState({ isTable: false })}>
                <i className="fa fa-square"></i>
              </button>
            </div>

          </div>
          <div className="col-md-2">
            <div className="float-right">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.limitPageData}
                totalItemsCount={this.state.totalCount}
                pageRangeDisplayed={4}
                onChange={this.handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </div>
        </div>
        <div className="row">
          {Content}
        </div>
        <div className="row">
          <div className="col-md-10"></div>
          <div className="col-md-2">
            <div className="float-right">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.limitPageData}
                totalItemsCount={this.state.totalCount}
                pageRangeDisplayed={4}
                onChange={this.handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </div>
          <ApplicantArchiveConfirm
            selectedApplicant={this.state.selectedApplicant}
            archiveSelectedApplicant={this.archiveSelectedApplicant}
            getApplicantList={this.getApplicantList}
            show={this.state.applicantArchiveConfirm}
            onHide={this.setShowArchiveConfirm}
          ></ApplicantArchiveConfirm>
          <AppicantUnArchieveConfirm
            selectedApplicant={this.state.selectedApplicant}
            unarchiveSelectedApplicant={this.unarchiveSelectedApplicant}
            getApplicantList={this.getApplicantList}
            show={this.state.applicantUnarchiveConfirm}
            onHide={this.setShowUnarchiveConfirm}
          ></AppicantUnArchieveConfirm>
        </div>
      </>
    );
  }
}

export default withTranslation()(ApplicantList);
