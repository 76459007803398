import React, { Component } from 'react';

import ContentHeader from "./ContentHeader"
import ContentBody from "./ContentBody"

import classes from './ContentNew.module.css'
import './ContentNew.css'

class Content extends Component {

    render() {
        return (
            // <div className="content-wrapper" style={{minHeight:"1136px"}}>
            <div className={["content-wrapper", classes.contentWrapper].join(' ')} style={{minHeight:"1136px"}}>
                {this.props.children}
            </div>
        );
    }
}

export {Content , ContentHeader, ContentBody}
export default Content;