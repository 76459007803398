import React, { Component } from 'react';

class ContentBody extends Component {
    
    constructor(props){
        super(props)
    }

    render() {
        return (
            <section className="content">
                {this.props.children}
            </section>
        );
    }
}

export default ContentBody;