import axios from "axios";
import config from "./../config/config";
class UserApi {
    base_api = config.base_api;
    token = config.token;

    getUser(params) {
        return axios.get(this.base_api + "users/", {
            params: params,
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getDetailUser(userId) {
        return axios.get(this.base_api + `users/${userId}`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getAllPrivileges(params){
        return axios.get(this.base_api + "privileges/", {
            params: params,
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    addUser(data) {
        return axios.post(this.base_api + `users/`, data, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    updateUser(userId, data) {
        return axios.patch(this.base_api + `users/${userId}/`, data, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    deleteSelectedUser(user) {
        return axios.delete(this.base_api + `users/${user.id}/`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    // ===================== Employees ================================

    getAllEmployees(params) {
        return axios.get(this.base_api + `employees/`, {
            params: params,
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getDetailEmployee(employeeId) {
        return axios.get(this.base_api + `employees/${employeeId}/`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    addEmployee(data) {
        // must be form data
        return axios.post(this.base_api + `employees/`, data, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "multipart/form-data",
            },
        });
    }

    updateEmployee(userId, data) {
        // must be form data
        return axios.patch(this.base_api + `employees/${userId}/`, data, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "multipart/form-data",
            },
        });
    }

    deleteSelectedEmployee(employee) {
        return axios.delete(this.base_api + `employees/${employee.id}/`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    // ======================= Author =============================

    getAllAuthor(params) {
        return axios.get(this.base_api + `authors/`, {
            params: params,
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getDetailAuthor(authorId) {
        return axios.get(this.base_api + `authors/${authorId}/`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    addAuthor(data) {
        const bodyForm = new FormData();

        bodyForm.append("site", data.site);
        bodyForm.append("name", data.name);
        bodyForm.append("avatar", data.avatar);
        bodyForm.append("author_languages", data.author_languages);

        // must be form data
        return axios.post(this.base_api + `authors/`, bodyForm, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "multipart/form-data",
            },
        });
    }

    addAuthorNew(data) {
        return axios.post(this.base_api + `authors/`, data, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    updateAuthorNew(authorId, data) {
        return axios.patch(this.base_api + `authors/${authorId}/`, data, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    updateAuthor(authorId, data) {
        // must be form data
        return axios.patch(this.base_api + `authors/${authorId}/`, data, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "multipart/form-data",
            },
        });
    }

    deleteSelectedAuthor(authors) {
        const authorsId = authors.map((item) => {
            return item.id;
        });

        return axios.delete(this.base_api + `authors/delete_authors/`, {
            data: {
                authors: authorsId,
            },
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    // ======================= end Author ========================

    getCurrentUser() {
        return axios.get(this.base_api + `employees/get_current_user`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }
}

export default UserApi;
