import React, { Component } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import { Button, Tabs, Tab } from 'react-bootstrap';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
// import ClassicEditor from './../plugins/ckeditor-build-complete-font/build/ckeditor';
// import ClassicEditor from './ckeditor';

// import DatePicker from "react-bootstrap-date-picker"
import DatePicker from 'react-datepicker';
import swal from 'sweetalert';
import Select from 'react-dropdown-select';
// import Dashboard from "./../components/section/Dashboard"

import ArticleAssetListUpdate from './../../components/widgets/AssetList/ArticleAssetListUpdate';
import ArticleAssetListAdd from './../../components/widgets/AssetList/ArticleAssetListAdd';
import ArticleDeleteConfirm from './../../components/Modal/ArticleDeleteConfirm';
import ModalLocation from './../../components/Modal/LocationModal';
import WidgetArticleAddLocationModal from './../../components/Modal/WidgetArticleAddLocationModal';

import GeneralApi from './../../api/GeneralApi';
import ArticleApi from './../../api/ArticleApi';
import WidgetApi from './../../api/WidgetApi';
import PageApi from './../../api/PageApi';
import SectionApi from './../../api/SectionApi';
import UserApi from '../../api/UserApi';
import LocationApi from '../../api/LocationApi';

import { myGetYear, getMonth, myRange } from './../../lib/DateHelper';

import 'react-datepicker/dist/react-datepicker.css';
import AlertHelper from './../../lib/AlertHelper';
import Context from '../Context';

import './ArticleForm.css';
// import SectionFormHeader from "./SectionFormHeader"

class ArticleForm extends Component {
	static contextType = Context;
	constructor(props) {
		super(props);

		this.state = {
			// gMapApiKey: 'AIzaSyA6aQOcCYM5yXimMPDdTCopJD9wT0324L8',
            gMapApiKey: "AIzaSyCigRL-AQS62AdkaUj42rp9OX_5Mbze8PM",
			mapsLib: ['drawing', 'places'],

			articleId: 0,
			article_type: 0,
			name: '',
			author: [],
			widget: {},
			category: [],
			date: '',
			dateToApi: '',
			status: 'Drafted',
			is_internal: false,
			is_nofollow: false,

			selectedLanguage: 1,
			widgetDisable: false,

			article_assets: [],
			widgetList: [],
			categoryList: [],
			languageList: [],
			employeeList: [],

			selectedAssetAdd: [],
			selectedAsset: [],
			selectedWidget: [],

			AssetSection: '',
			HeaderTextPage: '',
			ButtonForm: '',
			ButtonDelete: '',

			tabKey: 0,
			articleLanguages: [],
			articleKeywords: [],
			loadedKeywords: [],
			activeKeyword: '',

			pages: [],
			sections: [],
			selectedPageId: null,
			selectedSectionId: null,

			urlType: 'internal',
			deleteDisable: true,

			openCollapse: true,
			showModalConfirm: false,

			setShowLocationModal: false,
			setShowArticleAddLocationModal: false,
			pairLocation: false,
		};

		this.getAllEmployees = this.getAllEmployees.bind(this);
		this.getDetailArticle = this.getDetailArticle.bind(this);
		this.getDetailWidget = this.getDetailWidget.bind(this);
		this.getAllWidget = this.getAllWidget.bind(this);
		this.getArticleCategories = this.getArticleCategories.bind(this);
		this.getLanguages = this.getLanguages.bind(this);
		this.getPages = this.getPages.bind(this);
		this.getSectionByPage = this.getSectionByPage.bind(this);
		this.getCurrentUser = this.getCurrentUser.bind(this);

		this.handleChangeName = this.handleChangeName.bind(this);
		this.handleChangeStatus = this.handleChangeStatus.bind(this);
		this.handleChangeWidget = this.handleChangeWidget.bind(this);
		this.handleChangeCategory = this.handleChangeCategory.bind(this);
		this.handleChangeStatus = this.handleChangeStatus.bind(this);
		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.handleChangeTab = this.handleChangeTab.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleSectionChange = this.handleSectionChange.bind(this);
		this.handleUrlTypeChange = this.handleUrlTypeChange.bind(this);
		this.handleUrlExternalChange = this.handleUrlExternalChange.bind(this);
		this.handleAuthorChange = this.handleAuthorChange.bind(this);
		this.handleChangeNoFollow = this.handleChangeNoFollow.bind(this);

		this.handleChangeTitle = this.handleChangeTitle.bind(this);
		this.handleChangeContent = this.handleChangeContent.bind(this);
		this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
		this.handleChangeDescription = this.handleChangeDescription.bind(this);

		this.setSelectedAsset = this.setSelectedAsset.bind(this);
		this.setSelectedAssetAdd = this.setSelectedAssetAdd.bind(this);
		this.setShowModalConfirm = this.setShowModalConfirm.bind(this);

		this.deleteSelectedAssetAdd = this.deleteSelectedAssetAdd.bind(this);
		this.deleteSelectedArticle = this.deleteSelectedArticle.bind(this);

		this.addArticle = this.addArticle.bind(this);
		this.updateArticle = this.updateArticle.bind(this);
		this.addKeyword = this.addKeyword.bind(this);
		this.updateKeyword = this.updateKeyword.bind(this);
		this.handleKeyword = this.handleKeyword.bind(this);
		this.updateLoadedKeyword = this.updateLoadedKeyword.bind(this);
		this.deleteLoadedKeyword = this.deleteLoadedKeyword.bind(this);
		this.deleteAddedKeyword = this.deleteAddedKeyword.bind(this);

		this.clearInternal = this.clearInternal.bind(this);
		this.clearExternal = this.clearExternal.bind(this);

		this.setShowLocationModal = this.setShowLocationModal.bind(this);
		this.setShowArticleAddLocationModal = this.setShowArticleAddLocationModal.bind(this);
		this.addNewLocation = this.addNewLocation.bind(this);
		this.pairLocationWithArticle = this.pairLocationWithArticle.bind(this);
	}

	componentDidMount() {
		if (this.props.formType === 'Add') {
			this.setState(
				{
					ButtonForm: (
						<Button className='float-right' onClick={this.addArticle}>
							{' '}
							<i className='fa fa-edit'>&nbsp;</i> Add{' '}
						</Button>
					),
					widgetDisable: true,
					selectedWidget: [this.props.widget].map((item) => {
						//console.log(" HITEM ====> ", item)

						return {
							id: item.id,
							is_internal: item.is_internal,
							page: item.page,
							name: item.name,
							namePage: item.name,
						};
					}),
				},
				() => {}
			);
			this.getLanguages();
			this.getAllWidget();
		} else {
			this.getDetailArticle();

			this.setState(
				{
					HeaderTextPage: 'Article Detail Page',
					ButtonForm: (
						<Button className='float-right mr-3' onClick={this.updateArticle}>
							{' '}
							<i className='fa fa-edit'>&nbsp;</i> Update{' '}
						</Button>
					),
					ButtonDelete: (
						<Button variant='danger' style={{ marginRight: 10 }} className='float-right' onClick={() => this.setShowModalConfirm()}>
							{' '}
							<i className='fa fa-trash'>&nbsp;</i> Delete{' '}
						</Button>
					),
				},
				() => {
					this.getAllWidget();
				}
			);
		}

		this.getAllEmployees();
		this.getArticleCategories();
		// this.getPages()
	}

	componentDidUpdate() {
		const { sectionDetail } = this.context;

		if (sectionDetail.categoryList.length > 0 && sectionDetail.categoryList !== this.state.categoryList) {
			this.setState({ categoryList: sectionDetail.categoryList });
		}

		if (sectionDetail.widgetList.length > 0 && sectionDetail.widgetList !== this.state.widgetList) {
			this.setState({ widgetList: sectionDetail.widgetList });
		}

		if (sectionDetail.pagesList.length > 0 && sectionDetail.pagesList !== this.state.pages) {
			this.setState({ pages: sectionDetail.pagesList });
		}
	}

	setShowModalConfirm() {
		this.setState({
			showModalConfirm: !this.state.showModalConfirm,
		});
	}

	handleChangeTitle(id, value) {
		let articleLanguages = [...this.state.articleLanguages];
		let articleLanguage = { ...articleLanguages[id] };

		articleLanguage.title = value;
		articleLanguages[id] = articleLanguage;

		this.setState({
			articleLanguages: articleLanguages,
		});
	}

	handleChangeKeyword(id, value) {
		let articleLanguages = [...this.state.articleLanguages];
		let articleLanguage = { ...articleLanguages[id] };

		articleLanguage.keyword = value;
		articleLanguages[id] = articleLanguage;

		this.setState({
			articleLanguages: articleLanguages,
		});
	}

	handleChangeDescription(id, value) {
		let articleLanguages = [...this.state.articleLanguages];
		let articleLanguage = { ...articleLanguages[id] };

		articleLanguage.description = value;
		articleLanguages[id] = articleLanguage;

		this.setState({
			articleLanguages: articleLanguages,
		});
	}

	handleChangeSummary(id, value) {
		let articleLanguages = [...this.state.articleLanguages];
		let articleLanguage = { ...articleLanguages[id] };

		articleLanguage.summary = value;
		articleLanguages[id] = articleLanguage;

		this.setState({
			articleLanguages: articleLanguages,
		});
	}

	handleChangeName(value) {
		this.setState({
			name: value,
		});
	}

	handleAuthorChange(value) {
		// console.log("value => ",value[0].val)

		this.setState({
			author: value,
		});
	}

	handlePageChange(value) {
		if (!value) {
			this.setState(
				{
					selectedPageId: null,
					selectedSectionId: null,
					sections: [],
				},
				() => {
					//this.getSectionByPage()
				}
			);
		} else {
			this.setState(
				{
					selectedPageId: value,
				},
				() => {
					this.getSectionByPage();
				}
			);
		}
	}

	handleSectionChange(value) {
		this.setState({
			selectedSectionId: value,
		});
	}

	handleChangeContent(id, value) {
		let articleLanguages = [...this.state.articleLanguages];
		let articleLanguage = { ...articleLanguages[id] };

		articleLanguage.content = value;
		articleLanguages[id] = articleLanguage;

		this.setState({
			articleLanguages: articleLanguages,
		});
	}

	handleChangeWidget(value) {
		this.setState({
			widget: value,
		});
	}

	handleChangeCategory(value) {
		this.setState({
			category: value,
		});
	}

	handleChangeStatus(value) {
		this.setState({
			status: value,
		});
	}

	handleUrlExternalChange(id, value) {
		let urlLanguages = [...this.state.articleLanguages];
		let urlLanguage = { ...urlLanguages[id] };
		urlLanguage.path = value;
		urlLanguages[id] = urlLanguage;

		this.setState({
			articleLanguages: urlLanguages,
		});
	}

	handleChangeTab(value) {
		// console.log("lang ==> ", value)

		// get title and content base on the language
		// console.log("state AL ==> ", this.state.articleLanguages)
		//console.log(" findArticlelanguage ===> ",findArticleLanguage, this.state.articleLanguages)

		this.setState({
			tabKey: value,
			selectedLanguage: value,
			// title:findArticleLanguage?.title,
			// content:findArticleLanguage?.content
		});
	}

	handleChangeDate(value) {
		const result = JSON.stringify(value).slice(1, -1).split('T');

		value = value ? value : new Date();

		this.setState({
			date: value,
			dateToApi: result[0],
		});
		// console.log(result)
	}

	handleUrlTypeChange(value) {
		this.setState(
			{
				urlType: value,
			},
			() => {
				if (this.state.urlType === 'internal') {
					this.clearExternal();
				} else {
					this.clearInternal();
				}
			}
		);
	}

	handleChangeNoFollow(value) {
		this.setState({
			is_nofollow: value,
		});
	}

	setSelectedAssetAdd(asset) {
		var lastSelected = [];
		lastSelected = [...this.state.selectedAssetAdd];

		//console.log(asset);

		lastSelected = lastSelected.concat(asset);

		//console.log(" lastSelected ==> ",lastSelected)
		// console.log("last selected ==> ",lastSelected)

		this.setState(
			{
				selectedAssetAdd: lastSelected,
			},
			() => {
				var deleteDisable = true;
				if (this.state.selectedAssetAdd.length > 0) {
					deleteDisable = false;
				}

				this.setState({
					deleteDisable: false,
				});
			}
		);
	}

	deleteSelectedAssetAdd(assets) {
		// console.log(" delete assets => ",assets)

		var result = [];
		var lastSelected = [];
		lastSelected = [...this.state.selectedAssetAdd];

		lastSelected.forEach((ite) => {
			var exist = false;

			assets.forEach((item) => {
				if (ite.id === item.id) {
					exist = true;
				}
			});

			if (exist === false) {
				result.push(ite);
			}
		});

		this.setState(
			{
				selectedAssetAdd: result,
			},
			() => {
				swal('Success!', 'You sucessfully deleted asset on this article', 'success');
			}
		);
	}

	setSelectedAsset(asset, value) {
		var lastSelected = [];
		lastSelected = [...this.state.selectedAsset];
		var indexDelete = -1;
		var stateSelected = 'selectedAsset';

		if (value === true) {
			lastSelected.push(asset);
		} else {
			//console.log("jalan donk harusnya ", article , this.state.selectedArticles)
			this.state[stateSelected].forEach((item, index) => {
				if (item.id === asset.id) {
					indexDelete = index;
				}
			});

			lastSelected.splice(indexDelete, 1);
			//console.log("lastArticles => ",lastArticles)
		}

		this.setState(
			{
				[stateSelected]: lastSelected,
			},
			() => {
				if (this.state[stateSelected].length > 0) {
					this.setState({
						deleteDisable: false,
					});
				} else {
					this.setState({
						deleteDisable: true,
					});
				}
			}
		);
	}

	getAllEmployees() {
		const { sectionDetail } = this.context;

		this.setState({ employeeList: sectionDetail.employeeList });
	}

	getDetailArticle() {
		const objArticle = new ArticleApi();

		const articleId = this.props.article.id;
		// const widgetId = this.props.match.params.widgetId

		return objArticle
			.getDetailArticles(articleId)
			.then((res) => {
				var widgetDisable = false; // able
				var urlType = '';

				console.log('res => ', res.data);

				if (res.data.widgets[0]?.is_internal === true) {
					//console.log("haloo worrld ")
					widgetDisable = true;
				}

				if (res.data.article_languages[0].page) {
					urlType = 'internal';
				} else {
					urlType = 'external';
				}

				const selectedWidget = res.data.widgets.map((item) => {
					var page = '';
					if (page) {
						page = ' - ' + item.page;
					}

					return {
						...item,
						namePage: item.name + page,
					};
				});

				var image = '';
				if (res.data.author?.avatar) {
					image = (
						<div>
							<img width='30' height='30' src={res.data.author.avatar} alt=''></img>
							{' ' + res.data.author_name}
						</div>
					);
				} else {
					image = res.data.author_name;
				}

				const author = [
					{
						val: res.data.author,
						label: image,
					},
				];

				this.setState(
					{
						articleId: articleId,
						article_type: res.data.article_type?.id,
						category: res.data?.categories ? res.data?.categories : [],
						name: res.data.name,

						selectedWidget: selectedWidget,
						author: author,
						status: res.data.status,
						date: res.data.date ? new Date(res.data.date) : new Date(),
						dateToApi: res.data.date,
						article_assets: res.data.article_assets,
						widgetDisable: widgetDisable,
						is_internal: res.data.widgets[0]?.is_internal,
						urlType: urlType,
						selectedLanguage: 1,
						// title:res.data.title,
						// content:res.data.content,
						articleLanguages: res.data.article_languages,
						loadedKeywords: res.data.article_keywords,
						selectedPageId: res.data.article_languages[0]?.page,
						selectedSectionId: res.data.article_languages[0]?.section,
						is_nofollow: res.data.article_languages[0]?.is_nofollow,

						pairLocation: res.data.location ? res.data.location.id : false,
						location: res.data.location,
					},
					() => {
						this.getLanguages();
						// this.handleAuthorChange()
						if (this.state.author.length === 0) {
							this.getCurrentUser();
						}
						this.getSectionByPage();
					}
				);
			})
			.catch((err) => {
				// const resErr = err?.response?.data ? err?.response?.data : err.message

				const objAlert = new AlertHelper();
				objAlert.errorHandlerAlert(err.message);
			});
	}

	clearInternal() {
		this.setState({
			selectedPageId: null,
			selectedSectionId: null,
		});
	}

	clearExternal() {
		let urlLanguages = [...this.state.articleLanguages];
		urlLanguages.map((item, id) => {
			// let urlLanguage = { ...urlLanguages[ id ]}
			item.path = null;
			urlLanguages[id] = item;

			return item;
		});

		this.setState({
			articleLanguages: urlLanguages,
		});
	}

	addArticle(param) {
		const objArticle = new ArticleApi();

		const article_languages = this.state.articleLanguages.map((item) => {
			return {
				language: item.language ? item.language : 1,
				title: item.title ? item.title : '',
				content: item.content ? item.content : '',
				summary: item.summary ? item.summary : '',
				description: item.description ? item.description : '',
				page: this.state.selectedPageId ? this.state.selectedPageId : null,
				section: this.state.selectedSectionId ? this.state.selectedSectionId : null,
				path: item.path,
			};
		});

		const author = this.state.author.length > 0 ? this.state.author[0].val : null;
		const categories = this.state.category.map((item) => {
			return item.id;
		});

		var dt = {
			article_type: 1,
			title: this.state.title,
			content: this.state.content,
			name: this.state.name,
			author: author,
			categories: categories,
			location: this.state.pairLocation ? this.state.pairLocation : this.state.removeLocation ? null : null,

			status: this.state.status,
			article_languages: article_languages,
			//date:this.state.date,
			//assets:this.state.assets
		};

		if (this.state.date) {
			dt['date'] = this.state.dateToApi;
		}

		if (this.state.selectedAssetAdd.length > 0) {
			const ids = this.state.selectedAssetAdd.map((item) => {
				return {
					asset: item.id,
				};
			});

			dt['article_assets'] = ids;
		}

		if (this.state.selectedWidget) {
			dt['widgets'] = this.state.selectedWidget.map((item) => item.id);
		}

		// console.log(dt)

		objArticle
			.addArticle(dt)
			.then((res) => {
				const keywords_languages = this.state.articleKeywords.map((item) => {
					return {
						article: res.data.id,
						language: parseInt(item.language),
						keyword: item.keyword,
					};
				});

				objArticle.addKeywords(keywords_languages).then((res) => {
					if (param) {
						// this.getDetailWidget(param)
						this.props.getWidgetDetail();
					}
					swal('Success !', 'You Successfully add new article', 'success');
				});
			})
			.catch((err) => {
				const objAlert = new AlertHelper();
				var res = err?.response?.data;
				if (!res) {
					res = err.message;
					swal('Error !', res, 'error');
				} else {
					objAlert.errorHandlerAlert(res);
				}
			});
	}

	updateArticle() {
		const objArticle = new ArticleApi();
		const articleId = this.state.articleId;

		const article_languages = this.state.articleLanguages.map((item) => {
			return {
				id: item.id,
				language: item.language ? item.language : 1,
				title: item.title ? item.title : '',
				summary: item.summary ? item.summary : '',
				content: item.content ? item.content : '',
				description: item.description ? item.description : '',
				page: this.state.selectedPageId ? this.state.selectedPageId : null,
				section: this.state.selectedSectionId ? this.state.selectedSectionId : null,
				path: item.path,
			};
		});

		const author = this.state.author.length > 0 ? this.state.author[0].val : null;

		const categories = this.state.category.map((item) => {
			return item.id;
		});

		var dt = {
			// title:this.state.title,
			// content:this.state.content,
			name: this.state.name,
			article_type: this.state.article_type,
			// language:this.state.language,
			categories: categories,
			author: author,
			location: this.state.pairLocation ? this.state.pairLocation : this.state.removeLocation ? null : null,
			//widget:this.state.widget,
			status: this.state.status,
			article_languages: article_languages,
			//date:this.state.date,
			//assets:this.state.assets
		};

		if (this.state.date) {
			dt['date'] = this.state.dateToApi;
		}

		// if(this.state.assets.length > 0) {
		//     dt["assets"] = this.state.assets
		// }

		if (this.state.selectedWidget) {
			dt['widgets'] = this.state.selectedWidget.map((item) => item.id);
		}

		// console.log("update ===> ",dt)

		this.state.loadedKeywords.forEach((k, i) => {
			const keywords_languages = {
				language: k.language,
				keyword: k.keyword,
			};

			objArticle.updateKeywords(k.id, keywords_languages).then((res) => {});
		});

		objArticle
			.updateArticle(articleId, dt)
			.then((res) => {
				const keywords_languages = this.state.articleKeywords.map((item) => {
					return {
						article: articleId,
						language: parseInt(item.language),
						keyword: item.keyword,
					};
				});
				objArticle.addKeywords(keywords_languages).then((res) => {});

				swal('Success !', 'You Successfully updated this article', 'success');
			})
			.catch((err) => {
				const objAlert = new AlertHelper();
				var res = err?.response?.data;
				if (!res) {
					res = err.message;
					swal('Error !', res, 'error');
				} else {
					objAlert.errorHandlerAlert(res);
				}
			});
	}

	getArticleCategories() {
		const { sectionDetail } = this.context;

		this.setState({ categoryList: sectionDetail.categoryList });
	}

	getAllWidget() {
		const { sectionDetail } = this.context;

		this.setState({ widgetList: sectionDetail.widgetList });
	}

	getDetailWidget() {
		const objWidget = new WidgetApi();
		const widgetId = this.props.match.params.widgetId;

		return objWidget.getDetailWidget(widgetId);
	}

	getLanguages() {
		const { languagesList } = this.context;

		this.setState(
			{
				languageList: languagesList,
			},
			() => {
				// ===================================================================================================================================
				var index = this.state.languageList.findIndex((item) => item.abbreviation === localStorage.getItem('lang'));

				this.setState(
					{
						tabKey: index,
					},
					() => {
						this.renderTabLanguage();
						// this.handleChangeTab(this.state.tabKey)
					}
				);
			}
		);
	}

	getPages() {
		const { sectionDetail } = this.context;
		this.setState(
			{
				pages: sectionDetail.pagesList,
			},
			() => {
				if (this.state.selectedPageId) {
					this.getSectionByPage();
				}
			}
		);
	}

	getSectionByPage() {
		console.log(this.state.articleId);
		const objSection = new SectionApi();

		const params = {
			language: localStorage.getItem('lang'),
			page_id: this.state.selectedPageId,
			max_size: true,
		};

		objSection.sectionLanguage(params).then((res) => {
			// console.log(" heheh ",res.data)

			this.setState({
				sections: res.data.results,
			});
		});
	}

	getCurrentUser() {
		const objUser = new UserApi();

		objUser
			.getCurrentUser()
			.then((res) => {
				const author = [
					{
						val: res.data.id,
						label: res.data.user.first_name + ' ' + res.data.user.last_name,
					},
				];

				// alert(JSON.stringify(author))

				this.setState({
					author: author,
				});
			})
			.catch((err) => {
				console.log('err getCurrent user => ', err.message);
			});
	}

	// deleteArticle(articleId) {
	//     const objArticle = new ArticleApi();

	//     objArticle
	//     .getDetailArticles(articleId)
	//     .then((res) => {
	//         this.setState({
	//             selectedArticles: [res.data],
	//             showModalConfirm: !this.state.showModalConfirm,
	//         });
	//     })
	//     .catch((err) => {
	//         swal("Error !", err.message, "error");
	//     });
	// }

	deleteSelectedArticle() {
		const objArticle = new ArticleApi();

		this.setState({
			loadingModal: (
				<div className='overlay d-flex justify-content-center align-items-center'>
					<i className='fas fa-2x fa-sync fa-spin'></i>
				</div>
			),
		});

		const ids = [{ id: this.state.articleId, name: this.state.name }].map((item) => {
			return item.id;
		});

		objArticle
			.deleteMultiArticle(ids)
			.then((res) => {
				this.setState(
					{
						selectedArticles: [],
						showModalConfirm: !this.state.showModalConfirm,
						loadingModal: '',
					},
					() => {
						this.props.getWidgetDetail();
						swal('Success !', 'You successfully delete an article', 'success');
					}
				);
			})
			.catch((err) => {
				swal('Error !', err.message, 'error');
			});
	}

	renderTabLanguage() {
		const articleLanguages = this.state.languageList.map((item) => {
			var result = this.state.articleLanguages.find((articleLang) => item.id === articleLang.language);
			if (!result) {
				result = {
					article: this.state.articleId ? this.state.articleId : 0,
					language: item.id,
					title: '',
					content: '',
					page: null,
					section: null,
					path: '',
				};
			}

			result.languageName = item.name;
			result.page = !this.state.selectedPageId ? null : this.state.selectedPageId;
			result.section = !this.state.selectedSectionId ? null : this.state.selectedSectionId;
			return result;
		});

		this.setState(
			{
				articleLanguages: articleLanguages,
			},
			() => {
				this.handleChangeTab(this.state.tabKey); // english default
				//console.log(" render tab ==> ",this.state.articleLanguages )
			}
		);
	}

	handleKeyword(value) {
		this.setState({
			activeKeyword: value,
		});
	}

	addKeyword(index) {
		if (this.state.activeKeyword !== '') {
			const articleKeyword = {
				id: `${this.state.articleKeywords.length + 1}`,
				language: index + 1,
				keyword: this.state.activeKeyword,
			};
			this.setState(
				{
					articleKeywords: this.state.articleKeywords.concat(articleKeyword),
					activeKeyword: '',
				},
				() => {
					document.getElementById('fk-contentA-keywords0').value = '';
					document.getElementById('fk-contentA-keywords1').value = '';
					document.getElementById('fk-contentU-keywords0').value = '';
					document.getElementById('fk-contentU-keywords1').value = '';
				}
			);
		}
	}

	updateKeyword(id, value) {
		let articleKeywords = [...this.state.articleKeywords];
		let articleKeyword = { ...articleKeywords[id - 1] };

		articleKeyword.keyword = value;
		articleKeywords[id - 1] = articleKeyword;

		this.setState({
			articleKeywords: articleKeywords,
		});
	}

	updateLoadedKeyword(index, value) {
		let loadedKeywords = [...this.state.loadedKeywords];
		let loadedKeyword = { ...loadedKeywords[index] };

		loadedKeyword.keyword = value;
		loadedKeywords[index] = loadedKeyword;

		this.setState({
			loadedKeywords: loadedKeywords,
		});
	}

	deleteAddedKeyword(index) {
		this.setState({
			articleKeywords: this.state.articleKeywords.filter(function (ele, i) {
				return i !== index;
			}),
		});
	}

	deleteLoadedKeyword(id, index) {
		const objArticle = new ArticleApi();

		this.setState({
			loadedKeywords: this.state.loadedKeywords.filter(function (ele, i) {
				return i !== index;
			}),
		});

		objArticle.deleteKeywords(id).then((res) => {
			// this.getDetailArticle()
		});
	}

	setShowLocationModal() {
		this.setState({
			setShowLocationModal: !this.state.setShowLocationModal,
		});
	}

	setShowArticleAddLocationModal() {
		this.setState({
			setShowArticleAddLocationModal: !this.state.setShowArticleAddLocationModal,
		});
	}

	addNewLocation(newLocation) {
		const locationObj = new LocationApi();

		locationObj
			.addLocation(newLocation)
			.then((res) => {
				if (res.data) {
					this.setState({
						pairLocation: res.data.id,
						location: res.data,
					});
					// swal("Success !", t(this.state.transAlert + "newlocation_add_success"), "success")
					this.setShowLocationModal();
				}
			})
			.catch((err) => {
				console.log('err => ', err);
			});
	}

	pairLocationWithArticle(location) {
		this.setState({
			pairLocation: location.id,
			location: location,
		});
		this.setShowArticleAddLocationModal();
	}

	removeLocation(locationId) {
		if (locationId) {
			this.setState({
				pairLocation: false,
				location: false,
				removeLocation: true,
			});
		}
	}

	render() {
		const Categories = this.state.categoryList.map((item) => {
			return {
				id: item.id,
				name: item.category,
			};
		});

		var categorySelected = [];
		// SELECTED CATEGORY
		if (this.state.categoryList.length > 0) {
			categorySelected = this.state.category.map((item, index) => {
				for (var ii = 0; ii < this.state.categoryList.length; ii++) {
					if (this.state.categoryList[ii]?.id === item || this.state.categoryList[ii]?.id === item?.id) {
						return {
							id: this.state.categoryList[ii]?.id,
							name: this.state.categoryList[ii]?.category,
						};
					}
				}

				return { id: 0, name: '' };
			});
		}

		const PageSelect = this.state.pages.map((item) => {
			return (
				<option key={item.id} value={item.id}>
					{item.name}
				</option>
			);
		});

		const SectionSelect = this.state.sections.map((item) => {
			// console.log(item)
			return (
				<option key={item.id} value={item.section}>
					{item.name}
				</option>
			);
		});

		// const employeeSelect = this.state.employeeList.map(item => {
		//     return (<option key={item.id} value={item.id}>{ item.user.username }</option>)
		// })

		const widgets = this.state.widgetList.map((item) => {
			//  // console.log(" item => ", item )
			/*
                {
                    "id":1355,
                    "page":null,
                    "name":"Governement Awards",
                    "is_internal":false,
                    "category":3,
                    "category_name":"Article",
                    "status":"Public",
                    "namePage":"Governement Awards"
                }

            */
			return {
				id: item.id,
				name: item.name,
				namePage: item.namePage,
			};
		});

		// ClassicEditor
		// .create( document.querySelector( '#editor' ), {

		//     toolbar: [ 'bold', 'italic' ]
		// } )
		// .then( editor => {
		//     console.log( 'Editor was initialized', editor );
		// } )
		// .catch( error => {
		//     console.error( error.stack );
		// } );

		const TabLanguage = this.state.articleLanguages.map((item, index) => {
			let ckeditorConfig = {
				toolbar: {
					items: [
						'heading',
						'|',
						'bold',
						'italic',
						'|',
						'superscript',
						'subscript',
						'|',
						'link',
						'|',
						'bulletedList',
						'numberedList',
						'|',
						'insertTable',

						'|',
						'undo',
						'redo',
					],
				},
				table: {
					contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
				},
				language: '',
			};

			if (item.language === 2) {
				ckeditorConfig.language = 'vi';
			} else {
				ckeditorConfig.language = 'en';
			}

			return (
				<Tab key={index} eventKey={index} title={item.languageName}>
					<br></br>
					<div className='form-group'>
						<label> Title </label>
						<input
							className='form-control'
							placeholder='put your title in here'
							value={this.state.articleLanguages[index].title}
							onChange={(event) => this.handleChangeTitle(index, event.target.value)}
						></input>
						{/* {this.state.title} */}
					</div>

					<div className='form-group'>
						<label> Summary </label>
						<textarea
							className='form-control'
							placeholder='put your summary in here'
							value={this.state.articleLanguages[index].summary}
							onChange={(event) => this.handleChangeSummary(index, event.target.value)}
						></textarea>
						{/* {this.state.title} */}
					</div>

					<div className='form-group pb-2'>
						<label> Content</label>
						{/* <textarea
                            id="editor"
                            className="form-control" style={{width:"100%", height:500, padding:"1%"}}
                            value={this.state.articleLanguages[index].content}
                            onChange={(event) => this.handleChangeContent(event.target.value)}></textarea> */}
						<CKEditor
							id='editor'
							config={ckeditorConfig}
							editor={ClassicEditor}
							data={this.state.articleLanguages[index].content}
							onInit={(editor) => {
								// You can store the "editor" and use when it is needed.
								//console.log( 'Editor is ready to use! '+index, editor );
							}}
							onChange={(event, editor) => {
								const data = editor.getData();

								this.handleChangeContent(index, data);
							}}
						/>
					</div>
					<div className='seo-section'>
						<label> SEO </label>
						<div className='form-group'>
							<label> Description </label>
							<textarea
								className='form-control'
								value={this.state.articleLanguages[index].description}
								onChange={(event) => this.handleChangeDescription(index, event.target.value)}
							></textarea>
						</div>

						<div id='form-keywords' className='form-group'>
							<label> Keywords </label>
							<div className='fk-content'>
								{this.state?.loadedKeywords.map((a, i) => {
									return parseInt(a.language - 1) === index ? (
										<div className='fk-c-in'>
											<i className='fa fa-times fa-sm' onClick={(event) => this.deleteLoadedKeyword(a.id, i)}></i>
											<input
												type='text'
												className='form-control'
												value={a.keyword}
												onChange={(event) => this.updateLoadedKeyword(i, event.target.value)}
											/>
										</div>
									) : (
										<></>
									);
								})}
								{this.state?.articleKeywords.map((a, i) => {
									return parseInt(a.language - 1) === index ? (
										<div className='fk-c-in'>
											<i className='fa fa-times fa-sm' onClick={(event) => this.deleteAddedKeyword(i)}></i>
											<input
												type='text'
												className='form-control'
												defaultValue={a.keyword}
												onChange={(event) => this.updateKeyword(a.id, event.target.value)}
											/>
										</div>
									) : (
										<></>
									);
								})}
								<input
									id={this.props.formType === 'Add' ? `fk-contentA-keywords${index}` : `fk-contentU-keywords${index}`}
									type='text'
									className='form-control fk-contentW-keywords'
									defaultValue={''}
									onChange={(event) => this.handleKeyword(event.target.value)}
								/>

								<div className='fk-icn-add' onClick={() => this.addKeyword(index)}>
									<i className='fa fa-plus'></i>
								</div>
							</div>
						</div>
					</div>
				</Tab>
			);
		});

		var AssetSection = ''; // UNTUK ASSET

		if (this.props.formType === 'Add') {
			AssetSection = (
				<ArticleAssetListAdd
					selectedAssetAdd={this.state.selectedAssetAdd}
					setSelectedAssetAdd={this.setSelectedAssetAdd}
					//assets={this.state.assets}
					deleteSelectedAssetAdd={this.deleteSelectedAssetAdd}
					getDetailArticle={this.getDetailArticle}
				></ArticleAssetListAdd>
			);
		} else if (this.props.formType === 'Add') {
			AssetSection = (
				<ArticleAssetListAdd
					selectedAssetAdd={this.state.selectedAssetAdd}
					setSelectedAssetAdd={this.setSelectedAssetAdd}
					//assets={this.state.assets}
					deleteSelectedAssetAdd={this.deleteSelectedAssetAdd}
					getDetailArticle={this.getDetailArticle}
				></ArticleAssetListAdd>
			);
		} else {
			AssetSection = (
				<ArticleAssetListUpdate
					articleId={this.props.article.id} // PROOPS
					article_assets={this.state.article_assets}
					getDetailArticle={this.getDetailArticle}
				></ArticleAssetListUpdate>
			);
		}

		const years = myRange(1990, myGetYear(new Date()) + 1, 1);
		const months = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];

		var URLInput = this.state.articleLanguages.map((item, index) => {
			return (
				<div className='col-md-6'>
					<div className='form-group' key={index}>
						<label> Url {item.languageName}</label>
						<input
							value={item.path}
							type='text'
							className='form-control'
							onChange={(event) => this.handleUrlExternalChange(index, event.target.value)}
						></input>
					</div>
				</div>
			);
		});

		if (this.state.urlType === 'internal') {
			URLInput = (
				<>
					<div className='col-md-6'>
						<div className='form-group'>
							<label> Page </label>
							<select
								className='form-control'
								value={this.state.selectedPageId}
								onChange={(event) => this.handlePageChange(event.target.value)}
							>
								<option value='0'> -- Please Select Your Page -- </option>
								{PageSelect}
							</select>
						</div>
					</div>

					<div className='col-md-6'>
						<div className='form-group'>
							<label> Section </label>
							<select
								className='form-control'
								value={this.state.selectedSectionId}
								onChange={(event) => this.handleSectionChange(event.target.value)}
							>
								<option value='0'> -- Please Select Your Section -- </option>
								{SectionSelect}
							</select>
						</div>
					</div>
				</>
			);
		}

		// const selectedAsset = this.state.selectedAssetAdd.map((item,index) => {
		//     return (<li key={index}>{item.id} - {item.name}</li>)
		// })

		let renderLocation;

		if (this.state.location) {
			renderLocation = (
				<div className='d-flex'>
					<button className='btn btn-primary mb-3' onClick={this.setShowArticleAddLocationModal}>
						<i className='fa fa-edit mr-1'></i>Change Location
					</button>
					<div className='d-flex file-input-custom ml-3' onClick={this.setShowLocationModal}>
						<label for=''>
							<i className='fa fa-plus mr-1'></i>Add new Location
						</label>
					</div>
				</div>
			);
		} else {
			renderLocation = (
				<div className='d-flex'>
					<button className='btn btn-primary mb-3' onClick={this.setShowArticleAddLocationModal}>
						<i className='fa fa-plus mr-1'></i>Add Location
					</button>
					<div className='d-flex file-input-custom ml-3' onClick={this.setShowLocationModal}>
						<label for=''>
							<i className='fa fa-plus mr-1'></i>Add new Location
						</label>
					</div>
				</div>
			);
		}

		let defaulLocation;

		if (this.state.pairLocation && this.state.location) {
			defaulLocation = {
				lat: parseFloat(this.state.location.latitude),
				lng: parseFloat(this.state.location.longitude),
			};
		} else {
			defaulLocation = {
				lat: 0,
				lng: 0,
			};
		}

		return (
			<div id='art-s-form'>
				<div className='row'>
					<div className='col-md-6'>
						<div className='form-group'>
							<label>Name</label>
							<input
								type='text'
								name=''
								className='form-control'
								value={this.state.name}
								onChange={(event) => this.handleChangeName(event.target.value)}
							></input>
							{/* {this.state.name} */}
						</div>

						<div className='row'>
							<div className='col-md-12'>
								<Tabs id='controlled-tab-example' activeKey={this.state.tabKey} onSelect={(k) => this.handleChangeTab(k)}>
									{TabLanguage}
								</Tabs>
							</div>
						</div>
					</div>
					<div className='article-form-right'>
						<div className='row'>
							<div className='d-flex flex-column w-100'>
								<div className='col-md-12'>
									<div className='form-group'>
										<label>Collection</label>

										<Select
											multi
											disabled={this.state.widgetDisable}
											searchable={true}
											searchBy='namePage'
											options={widgets}
											values={[...this.state.selectedWidget]}
											valueField='id'
											labelField='namePage'
											onDropdownClose={(value) => {
												// console.log("dropdownClose" , value)
											}}
											onChange={(selectedWidget) => {
												this.setState({
													selectedWidget,
												});
											}}
										/>
									</div>
									<div className='col-md-12 p-0'>
										<div className='form-group'>
											<label>Category</label>
											{/* { JSON.stringify(this.state.selectedWidget)} */}
											<Select
												multi
												// disabled={this.state.widgetDisable}
												searchable={true}
												searchBy='name'
												options={Categories}
												values={categorySelected}
												valueField='id'
												labelField='name'
												onDropdownClose={(value) => {
													// console.log("dropdownClose" , value)
												}}
												onChange={(selectedcategory) => {
													this.handleChangeCategory(selectedcategory);
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* <div className="col-md-12">
                            <div className="form-group">
                                <label>Category</label>
                                <Select
                                    multi
                                    // disabled={this.state.widgetDisable}
                                    searchable={true}
                                    searchBy="name"
                                    options={Categories}
                                    values={categorySelected}
                                    valueField="id"
                                    labelField="name"
                                    onDropdownClose={(value) => {
                                        // console.log("dropdownClose" , value)
                                    }}
                                    onChange={(selectedcategory) => {
                                        this.handleChangeCategory(selectedcategory);
                                    }}
                                />
                            </div>
                        </div> */}
						<div className='row'>
							<div className='col-md-6'>
								<div className='form-group'>
									<label>Date</label>
									<div>
										<DatePicker
											className='form-control'
											wrapperClassName='form-control'
											onChange={(date) => this.handleChangeDate(date)}
											selected={this.state.date}
											renderCustomHeader={({
												date,
												changeYear,
												changeMonth,
												decreaseMonth,
												increaseMonth,
												prevMonthButtonDisabled,
												nextMonthButtonDisabled,
											}) => (
												<div style={{ margin: 10, display: 'flex', justifyContent: 'center' }}>
													<button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
														{'<'}
													</button>
													<select value={myGetYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
														{years.map((option) => (
															<option key={option} value={option}>
																{option}
															</option>
														))}
													</select>

													<select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
														{months.map((option) => (
															<option key={option} value={option}>
																{option}
															</option>
														))}
													</select>

													<button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
														{'>'}
													</button>
												</div>
											)}
										></DatePicker>
									</div>
								</div>
							</div>
							<div className='col-md-6'>
								<div className='form-group'>
									<label> Status </label>
									<select
										className='form-control'
										value={this.state.status}
										onChange={(event) => this.handleChangeStatus(event.target.value)}
									>
										<option value='Drafted'>Drafted</option>
										<option value='Published'> Published </option>
									</select>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-md-6'>
								<div className='form-group'>
									<label> Author </label>
									<Select
										searchable={true}
										searchBy='label'
										name='form-field-name'
										values={this.state.author}
										options={this.state.employeeList}
										valueField='val'
										labelField='label'
										onChange={(author) => {
											this.setState({
												author: author,
											});
										}}
									/>
								</div>
							</div>
							<div className='col-md-6'>
								<div className='form-group'>
									<input
										type='checkbox'
										id={'is_nofollow-' + this.state.articleId}
										onClick={() => this.handleChangeNoFollow()}
										checked={this.state.is_nofollow}
									/>
									&nbsp;
									<label htmlFor={'is_nofollow-' + this.state.articleId}> No Follow </label>
								</div>
							</div>
						</div>
						<div className='form-group'>
							<label> Url Type</label>
							<br></br>
							<label htmlFor={'url-internal-' + this.state.articleId}>
								<input
									type='radio'
									className=''
									id={'url-internal-' + this.state.articleId}
									value={'internal'}
									checked={this.state.urlType === 'internal'}
									onChange={() => this.handleUrlTypeChange('internal')}
								></input>
								&nbsp; Internal
							</label>
							&nbsp; &nbsp;
							<label htmlFor={'url-external-' + this.state.articleId}>
								<input
									type='radio'
									className=''
									id={'url-external-' + this.state.articleId}
									value='external'
									checked={this.state.urlType === 'external'}
									onChange={() => this.handleUrlTypeChange('external')}
								></input>
								&nbsp; External{' '}
							</label>
						</div>
						<div className='row'>{URLInput}</div>

						<div className='form-group'>
							<label>Location</label>
							{this.state.pairLocation && this.state.location ? (
								<div className='form-group position-relative'>
									<div
										style={{
											position: 'absolute',
											right: '0',
											zIndex: '2',
										}}
									>
										<div className='fk-icn-remove' onClick={() => this.removeLocation(this.state.location.id)}>
											<i className='fa fa-times'></i>
										</div>
									</div>
									<p>
										{this.state.location.company_name ||
											this.state.location.location_languages[0].location_type ||
											this.state.location.location_languages[1].location_type}
									</p>
									<GoogleMap
										mapContainerStyle={{
											width: '100%',
											height: '30vh',
										}}
										center={defaulLocation}
										zoom={10}
									>
										<Marker position={defaulLocation} />
									</GoogleMap>
									<p>
										{this.state.location.address ||
											this.state.location.location_languages[0].address ||
											this.state.location.location_languages[1].address}
									</p>
								</div>
							) : (
								<></>
							)}
							{renderLocation}
						</div>
					</div>

					{AssetSection}

					<div className='row w-100 h-100 d-flex justify-content-end mt-3'>
						<div className='d-flex w-100 justify-content-end'>
							{this.state.ButtonForm}
							{this.state.ButtonDelete}
						</div>
					</div>
				</div>
				<ArticleDeleteConfirm
					selectedArticles={[{ id: this.state.articleId, name: this.state.name }]}
					deleteSelectedArticle={this.deleteSelectedArticle}
					loadingModal={this.state.loadingModal}
					show={this.state.showModalConfirm}
					onHide={this.setShowModalConfirm}
				></ArticleDeleteConfirm>
				<ModalLocation
					show={this.state.setShowLocationModal}
					onHide={this.setShowLocationModal}
					addNewLocation={this.addNewLocation}
				></ModalLocation>
				<WidgetArticleAddLocationModal
					show={this.state.setShowArticleAddLocationModal}
					onHide={this.setShowArticleAddLocationModal}
					locationId={this.state.pairLocation}
					// widgetId={this.props.data.widget}
					// getWidgetDetail={this.getDetailWidget}
					// selectedAssets={this.state.selectedAssets}
					// type={""}
					// selectedAsset={this.handleSelectedAssetThumbnail}
					pairLocationWithArticle={this.pairLocationWithArticle}
				></WidgetArticleAddLocationModal>
			</div>
		);
	}
}

export default ArticleForm;
