import axios from "axios"
import config from "./../config/config"

export default class CategoryApi {

    // base_api = "http://192.168.13.177:7000/"
    base_api = config.base_api
    token = config.token
    // token = "7e142a22850ebf92be390c473ce16cb4593651f7"

    getAllCategories(params){
        return axios.get(this.base_api+`article_categories/`,{
            params:params,
            headers:{
                "Authorization":`Token ${this.token}`,
                "Content-Type":"application/json"
            }
        }) 
    }

    getDetailCategory(categoryId) {
        return axios.get(this.base_api+`article_categories/${categoryId}/`,{
            headers:{
                "Authorization":`Token ${this.token}`,
                "Content-Type":"application/json"
            }
        })
    }

    categoryAdd(data){

        return axios.post(this.base_api+`article_categories/`,data,{
            headers:{
                "Authorization":`Token ${this.token}`,
                "Content-Type":"application/json"
            }
        })
    }

    categoryUpdate(categoryId,data) {
        return axios.patch(this.base_api+`article_categories/${categoryId}/`,data,{
            headers:{
                "Authorization":`Token ${this.token}`,
                "Content-Type":"application/json"
            }
        })
    }

    categoryDelete(categoryId) {
        return axios.delete(this.base_api+`article_categories/${categoryId}/`,{
            headers:{
                "Authorization":`Token ${this.token}`,
                "Content-Type":"application/json"
            }
        })
    }



}