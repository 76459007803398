import axios from "axios";
import config from "./../config/config";
class JobApi {
  base_api = config.base_api;
  token = config.token;

  getJobList(params) {
    let lang = localStorage.getItem("lang");
    let param;
    if (lang === "en") {
      param = `?language=en`;
    } else {
      param = `?language=vi`;
    }
    return axios.get(this.base_api + `job_posters/${param}`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getJobDetail(jobId) {
    return axios.get(this.base_api + `job_posters/${jobId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getPositionList(params) {
    return axios.get(this.base_api + `positions/`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getDetailPosition(positionId) {
    return axios.get(this.base_api + `positions/${positionId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getLocationList(params) {
    return axios.get(this.base_api + `locations/`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getCompanies(params) {
    return axios.get(this.base_api + `companies/`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getKeywords(params) {
    return axios.get(this.base_api + `keywords/`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getCurrencies(params) {
    return axios.get(this.base_api + `currencies/`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  updateJobPoster(jobId, data) {
    return axios.patch(this.base_api + `job_posters/${jobId}/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  addJobPoster(data) {
    return axios.post(this.base_api + `job_posters/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  updateJobPosterStatus(data) {
    return axios.patch(this.base_api + `job_posters/update_status/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  deleteSelectedJob(selectedJobs) {
    const jobId = selectedJobs[0].id;

    return axios.delete(this.base_api + `job_posters/${jobId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getApplicationList(params) {
    return axios.get(this.base_api + `applications/`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getApplicant(params) {
    return axios.get(this.base_api + `applicants/`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getPriorityList(params) {
    return axios.get(this.base_api + `priorities/`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getConfirmationList(params) {
    return axios.get(this.base_api + `confirmations/`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getApplicationDetail(applicationId) {
    return axios.get(this.base_api + `applications/${applicationId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  updateApplication(applicationId, request) {
    return axios.put(
      this.base_api + `applications/${applicationId}/`,
      request,
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  addCompany(data) {
    return axios.post(this.base_api + `companies/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  updateCompany(companyId, data) {
    return axios.patch(this.base_api + `companies/${companyId}/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  deleteCompany(companyId) {
    return axios.delete(this.base_api + `companies/${companyId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getDetailCompany(companyId) {
    return axios.get(this.base_api + `companies/${companyId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  addPosition(data) {
    return axios.post(this.base_api + `positions/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  updatePosition(positionId, data) {
    return axios.patch(this.base_api + `positions/${positionId}/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  deletePosition(positionId) {
    return axios.delete(this.base_api + `positions/${positionId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  addLocation(data) {
    return axios.post(this.base_api + `locations/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  updateLocation(locationId, data) {
    return axios.patch(this.base_api + `locations/${locationId}/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  deleteLocation(locationId) {
    return axios.delete(this.base_api + `locations/${locationId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getDetailLocation(locationId) {
    return axios.get(this.base_api + `locations/${locationId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  addKeyword(data) {
    return axios.post(this.base_api + `keywords/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getApplicantDetail(applicantId) {
    return axios.get(this.base_api + `applicants/${applicantId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getJobTypes(params = undefined) {
    return axios.get(this.base_api + `job_types/`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getJobTypeDetail(jobTypeId) {
    return axios.get(this.base_api + `job_types/${jobTypeId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  updateJobType(jobTypeId, data) {
    return axios.patch(this.base_api + `job_types/${jobTypeId}/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  addJobType(data) {
    return axios.post(this.base_api + `job_types/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  deleteJobType(jobTypeId) {
    return axios.delete(this.base_api + `job_types/${jobTypeId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  addSalaryOffer(data) {
    return axios.post(this.base_api + `application_offers/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  approveSalaryOffer(data, salaryOfferId) {
    return axios.patch(
      this.base_api + `application_offers/${salaryOfferId}/`,
      data,
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  // =================================== resume , portofolio , =================================================

  // =================================== currencies ============================================================
  getDetailCurrency(currencyId) {
    return axios.get(this.base_api + `currencies/${currencyId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  addCurrency(params) {
    return axios.post(this.base_api + `currencies/`, params, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  updateCurrency(currencyId, data) {
    return axios.patch(this.base_api + `currencies/${currencyId}/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  // =================================== end currencies ============================================================

  AppicantArchieve(applicantId, active) {
    return axios.patch(
      this.base_api + `applicants/${applicantId}/`,
      {
        active: active,
      },
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  AppicantArchieveMulti(appIds, active) {
    return axios.patch(
      this.base_api + `applicants/update_active/`,
      {
        applicants: appIds,
      },
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  AppicantUnarchieveMulti(appIds, active) {
    return axios.patch(
      this.base_api + `applicants/update_active/`,
      {
        applicants: appIds,
      },
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  sendEmailToApplicant(data, id) {
    let url = this.base_api + `applications/${id}/send_status_update/`;
    return axios.post(url, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }
}

export default JobApi;
