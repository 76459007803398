import React, { Component } from 'react';
import { Modal, Tab, Tabs, Button } from "react-bootstrap"
import swal from "sweetalert"
import AlertHelper from "./../../lib/AlertHelper"

import SectionCategoryApi from "./../../api/SectionCategoryApi"
import GeneralApi from "../../api/GeneralApi"
import { withTranslation } from 'react-i18next';
import Context from "../../pages/Context"


class SectionCategoryAddModal extends Component {
    static contextType = Context;
    constructor(props) {
        super(props)

        this.state = {
            categoryId: 0,
            category: "",
            categoryLanguages: [],
            languageList: [],

            tabKey: 1,
            selectedLanguage: 1,
            idElement: 0,
            renderTabLanguage: "",

            loadingModal: "",
            transPage: "modals.section_category_add.",
            transAlert: "alert."
        }

        this.categoryUpdate = this.categoryUpdate.bind(this)
        this.categoryAdd = this.categoryAdd.bind(this)

        this.renderTabLanguage = this.renderTabLanguage.bind(this)
        this.onOpenModal = this.onOpenModal.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)

    }

    onOpenModal() {
        const { languagesList } = this.context
        this.setState({
            categoryId: this.props.categoryId | 0,
            tabKey: 1,
            selectedLanguage: 1,
            languageList: languagesList
        }, () => {
            if (this.state.categoryId) {
                this.getDetailCategory()
            } else {
                this.renderTabLanguage()
            }
        })
    }

    onCloseModal() {
        this.setState({
            categoryId: 0,
            category: "",
            // languageList:[],
            tabKey: 1,
            selectedLanguage: 1,
            categoryLanguages: [],
            renderTabLanguage: ""
        }, () => {
            this.props.onHide()
        })
        // this.props.onHide()
    }

    getDetailCategory() {
        const objCategory = new SectionCategoryApi()

        const categoryId = this.state.categoryId

        objCategory.getDetailSectionCategory(categoryId)
            .then(res => {
                this.setState({
                    category: res.data.category,
                    categoryLanguages: res.data.category_languages
                }, () => {
                    this.renderTabLanguage()
                })
            })
    }

    handleContentChange(id, value) {
        let category_languages = [...this.state.categoryLanguages]
        let category_language = { ...category_languages[id] }
        category_language.name = value
        category_languages[id] = category_language

        this.setState({
            categoryLanguages: category_languages,
            category: category_languages[0].name
        })
    }

    handleChangeTab(value) {

        this.setState({

            selectedLanguage: value,
            tabKey: value,
        }, () => {
            this.renderTabLanguage()
        })
    }

    categoryAdd() {
        const { t } = this.props;
        const objCategory = new SectionCategoryApi()

        this.setState({
            loadingModal: <div className="overlay d-flex justify-content-center align-items-center">
                <i className="fas fa-2x fa-sync fa-spin"></i>
            </div>
        })

        const data = {
            name: this.state.category,
            category_languages: this.state.categoryLanguages
        }

        objCategory.addSectionCategory(data)
            .then(res => {
                //this.props.setSelectedAssetAdd(this.state.selectedAssets)
                this.props.getCategories()
                this.setState({
                    loadingModal: ""
                })
                this.props.onHide()
                swal("Success !", t(this.state.transAlert + "section_category_add_success"), "success");
            })
            .catch(err => {
                const objAlert = new AlertHelper()
                const resErr = err?.response?.data ? err?.response?.data : err.message
                //console.log(" Error => ",err?.response?.data)
                objAlert.errorHandlerAlert(resErr)
                this.setState({
                    loadingModal: ""
                })
            })
    }

    categoryUpdate() {
        const { t } = this.props;
        const objCategory = new SectionCategoryApi()

        this.setState({
            loadingModal: <div className="overlay d-flex justify-content-center align-items-center">
                <i className="fas fa-2x fa-sync fa-spin"></i>
            </div>
        })

        const data = {
            name: this.state.category,
            category_languages: this.state.categoryLanguages
        }

        objCategory.updateSectionCategory(this.state.categoryId, data)
            .then(res => {
                //this.props.setSelectedAssetAdd(this.state.selectedAssets)
                this.props.getCategories()
                this.setState({
                    loadingModal: ""
                })
                this.props.onHide()
                swal("Success !", t(this.state.transAlert + "section_category_update_success"), "success");
            })
            .catch(err => {
                const objAlert = new AlertHelper()
                const resErr = err?.response?.data ? err?.response?.data : err.message
                //console.log(" Error => ",err?.response?.data)
                objAlert.errorHandlerAlert(resErr)
                this.setState({
                    loadingModal: ""
                })
            })
    }

    categoryDelete() {

    }

    renderTabLanguage() {
        const renderTabLanguage = this.state.languageList.map(item => {
            var result = this.state.categoryLanguages.find(textLang => item.id === textLang.language)

            if (!result) {
                result = {
                    language: item.id,
                    name: "",
                }
            }

            result.languageName = item.name

            return result
        })

        this.setState({
            categoryLanguages: renderTabLanguage
        })

    }

    render() {
        const { t } = this.props;
        var HeaderExecute = t(this.state.transPage + "add_section_category")
        var ButtonExecute = <Button variant="primary" onClick={() => this.categoryAdd()}>
            <i className="fa fa-plus">&nbsp;</i>
            {t(this.state.transPage + "add_section_category")}
        </Button>

        if (this.state.categoryId) {
            HeaderExecute = t(this.state.transPage + "update_section_category")
            ButtonExecute = <Button variant="primary" onClick={() => this.categoryUpdate()}>
                <i className="fa fa-edit">&nbsp;</i>
                {t(this.state.transPage + "update_section_category")}
            </Button>
        }

        const TabLanguage = this.state.categoryLanguages.map((item, index) => {

            return (

                <Tab key={index} eventKey={item.language} title={item.languageName}>
                    <br></br>

                    <div className="form-group">
                        <label> {t('section_category')} </label>
                        <textarea
                            className="form-control"
                            onChange={(event) => this.handleContentChange(index, event.target.value)}
                            value={this.state.categoryLanguages[index].name}
                        >
                        </textarea>
                        {/* { JSON.stringify( this.state.text_languages )  } */}
                    </div>

                </Tab>
            )
        })

        return (
            <Modal show={this.props.show} onShow={this.onOpenModal} onHide={() => this.onCloseModal()}>
                {this.state.loadingModal}
                <Modal.Header closeButton className="bg-primary">
                    <Modal.Title>{HeaderExecute}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        id="controlled-tab-example-3"
                        activeKey={this.state.tabKey}
                        onSelect={(k) => this.handleChangeTab(k)}
                    >

                        {TabLanguage}
                    </Tabs>

                </Modal.Body>
                <Modal.Footer>

                    {ButtonExecute}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(SectionCategoryAddModal);