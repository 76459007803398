import React from "react";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default class AlertHelper {
	errorHandlerAlert(response) {
		var message = "";

		if (typeof response === "string") {
			message = <div>{response}</div>;
		} else if (response.length > 0) {
			message = response.map((item) => {
				return <li key={item}>{item}</li>;
			});

			message = <ul style={{ listStyleType: "none" }}>{message}</ul>;
		} else if (typeof response === "object") {
			message = Object.keys(response).map((item) => {
				if (response[item] && response[item].length > 0) {
					const result = response[item].map((itemB, index) => {
						if (typeof response[item][0] === "object") {
							return <li key={index}>{JSON.stringify(itemB)} :</li>;
						} else {
							return <li key={itemB}>{itemB}</li>;
						}
					});

					return (
						<div>
							{item} : <br /> <ul style={{ listStyleType: "none" }}>{result}</ul>{" "}
						</div>
					);
				}
			});

			message = <div>{message}</div>;
		} else {
			message = <div> {JSON.stringify(response)} </div>;
		}

		const MySwal = withReactContent(swal);

		MySwal.fire({
			icon: "error",
			title: <p> Error !</p>,

			html: message,
		});
	}
}
