import axios from "axios";
import config from "./../config/config";

export default class PageApi {
  base_api = config.base_api;
  token = config.token;

  async getDescription(param) {
    return await axios.get(this.base_api + `pages/${param}/`, {
      params: { max_size: true },
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  updateDescriptionPage(id, data) {
    return axios.patch(
      this.base_api + `pages/${id}/`,
      {
        page_languages: data,
      },
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  updateDescriptionUsed(id, data) {
    return axios.patch(
      this.base_api + `pages/${id}/`,
      {
        use_page_description: data,
      },
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  updatePageSectionsSetting(id, data) {
    return axios.patch(
      this.base_api + `pages/${id}/`,
      {
        is_draft: data.is_draft,
        page_type: data.page_type,
        params_qty: data.param_qty,
        has_params: data.has_param,
      },
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  getPageStyling() {
    return axios.get(this.base_api + `styles/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  updatePageSectionsStyling(id, data) {
    let ids = parseInt(id);
    console.log(id, data)
    return axios.patch(
      this.base_api + `page_style_pair/`,
      {
        page_id: ids,
        page_styles: data,
      },
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  addKeywordPage(param) {
    return axios.post(this.base_api + `page_keywords/`, param, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  deleteKeywordPage(id) {
    return axios.delete(this.base_api + `page_keywords/${id}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getAllPages(params) {
    return axios.get(this.base_api + `pages/`, {
      params: params,
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getPageDetail(pageId) {
    return axios.get(this.base_api + `pages/${pageId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  pageSectionDelete(sections) {
    return axios.delete(this.base_api + `section_delete/`, {
      data: {
        ids: sections,
      },
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  pageAdd(data) {
    return axios.post(
      this.base_api + `pages/`,
      {
        name: data.name,
        parent: data.parent,
        template: data.template,
        has_params: data.has_params,
        widgets: data.widgets,
        page_languages: data.page_languages,
        page_style: data.page_styles,
      },
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  pageUpdate(pageId, data) {
    return axios.put(
      this.base_api + `pages/${pageId}/`,
      {
        name: data.name,
        parent: data.parent,
        template: data.template,
        has_params: data.has_params,
        widgets: data.widgets,
        page_languages: data.page_languages,
        page_style: data.page_styles,
        is_draft: data.is_draft,
      },
      {
        headers: {
          Authorization: `Token ${this.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  pageDelete(pages) {
    return axios.delete(this.base_api + `page_delete/`, {
      data: {
        ids: pages,
      },
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  pageStylesCatalogSetting() {
    let url = this.base_api + `page_styles/catalog/`;

    return axios.get(url, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  pageDescAdd(descId, data) {
    return axios.post(
      this.base_api + `pages/${descId}/`,
      {
        page_languages: {
          name: data.name,
          language_name: data.lang,
          description: data.description,
        },
      },
      {
        headers: {
          Authorization: "Token " + this.token,
          "Content-Type": "application/json",
        },
      }
    );
  }

  pageLanguages() {
    // job poster
    let url = this.base_api + `languages/`;
    // console.log(url);
    return axios.get(url, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  getLanguages() {
    return axios.get(this.base_api + `languages/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  widgetTextDetail(textId) {
    return axios.get(this.base_api + `text_elements/${textId}/`, {
      headers: {
        Authorization: "Token " + this.token,
        "Content-Type": "application/json",
      },
    });
  }

  pageLanguageListSettings(params) {
    let url =
      this.base_api +
      `pages/get_page_names/?template=${params[0].template}&language=${params[0].language}&max_size=${params[0].max_size}`;

    return axios.get(url, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  pageLanguageList(params) {
    let url =
      this.base_api +
      `pages/get_page_names/?template=${params.template}&language=${params.language}&max_size=${params.max_size}`;

    return axios.get(url, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }
}
