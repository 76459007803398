import React, { Component } from 'react';
import { Tab, Col, Row, Collapse, Card, Nav, Carousel } from "react-bootstrap"
import { CardBody } from '../../components/Card';

import SectionFormHeader from "./SectionFormHeader"
import ArticleForm from "./ArticleForm"
import ArticleFormCollection from "./ArticleFormCollection"
import ArticleFormExternal from "./ArticleFormExternal"
import ArticleOrderModal from '../../components/Modal/ArticleOrderModal'
import WidgetApi from "./../../api/WidgetApi"
import UserApi from "./../../api/UserApi"
import Context from '../Context'


import "./FormCard.css"

class ArticleCard extends Component {
    static contextType = Context;
    constructor(props) {
        super(props)

        this.state = {
            openCollapse: false, // DEFAULT FALSE
            widget: {
                id: 0,
                articles: [],
            },
            collectionsList: [],
            index: 0,
            showOrderArticle: false
        }

        this.setOpenCollapse = this.setOpenCollapse.bind(this)
        this.getWidgetDetail = this.getWidgetDetail.bind(this)
        this.getAllCollections = this.getAllCollections.bind(this)

        this.setIndex = this.setIndex.bind(this)
        this.handleSelect = this.handleSelect.bind(this)

        this.renderTabContentAdd = this.renderTabContentAdd.bind(this)

        this.setShowOrderArticle = this.setShowOrderArticle.bind(this)
        this.confirmArticleOrder = this.confirmArticleOrder.bind(this)
    }

    componentDidMount() {
        this.getWidgetDetail()

        if (!this.props.data?.is_internal) {
            this.getAllCollections();
        }
    }

    componentDidUpdate() {
        // if(!this.state.widget.id) {
        //     this.getWidgetDetail()
        // }
        // this.getWidgetDetail()
    }

    setOpenCollapse() {
        this.setState({
            openCollapse: !this.state.openCollapse
        })
    }

    getAllCollections() {
        const objWidget = new WidgetApi()

        const params = {
            template: Number(localStorage.getItem("templateId")),
            // template: 41,
            max_size: true,
            category: 3,
            internal: this.props.data.is_internal
        }

        objWidget.getAllWidgets(params)
            .then(res => {
                const collectionsList = res.data.results.map(item => {

                    var page = ""
                    if (item.page) {
                        page = " - " + item.page
                    }

                    return {
                        ...item,
                        namePage: item.name + page
                    }
                })

                this.setState({
                    collectionsList: collectionsList
                })
            })
    }

    getWidgetDetail() {

        const objWidget = new WidgetApi()
        const widgetId = this.props.data.widget
        const sectionWidgetId = this.props.data.section_widget_languages[0]?.section_widget
        const isInternal = this.props.data.is_internal

        const params = {
            section: sectionWidgetId
        }

        if (isInternal) {
            objWidget.getDetailWidget(widgetId, params)
                .then(res => {
                    this.setState({
                        widget: res.data,

                    }, () => {
                        this.renderTabContentAdd()
                    })

                })
                .catch(err => {
                    console.log(err.message)
                })
        } else {
            this.setState({
                widget: {
                    ...this.state.widget,
                    id: widgetId
                }
            }, () => {
                this.renderTabContentAdd()
            })
        }

    }

    getWidgetDetailWithId(widgetId) {

        const objWidget = new WidgetApi()
        const sectionWidgetId = this.props.data.section_widget_languages[0]?.section_widget

        const params = {
            section: sectionWidgetId
        }

        objWidget.getDetailWidget(widgetId, params)
            .then(res => {
                // console.log(res.data)
                this.setState({
                    widget: res.data,

                }, () => {
                    this.renderTabContentAdd()
                })

            })
            .catch(err => {
                console.log(err.message)
            })
    }

    setIndex(selectedIndex) {
        this.setState({
            index: selectedIndex
        })
    }

    handleSelect(selectedIndex, e) {
        this.setIndex(selectedIndex);
    }

    renderTabContentAdd() {

        const selectedWidget = {
            id: this.state.widget?.id ? this.state.widget?.id : 0,
            name: this.state.widget?.name ? this.state.widget?.name : "",
            namePage: this.state.widget?.name,
            is_internal: this.state.widget?.is_internal ? this.state.widget?.is_internal : false
        }

        this.setState({
            TabContentAdd: <Tab.Pane eventKey="add-article">
                <br></br>
                {/* {JSON.stringify(this.state.widget)} */}
                <ArticleForm
                    widgetName={this.props.widgetName}
                    widgetElementType={this.props.widgetElementType}
                    widgetElementStatic={this.props.widgetElementStatic}
                    widget={selectedWidget}
                    getWidgetDetail={this.getWidgetDetail}
                    formType="Add"
                ></ArticleForm>
            </Tab.Pane>
        })


    }

    setShowOrderArticle() {
        this.setState({
            showOrderArticle: !this.state.showOrderArticle
        })
    }

    confirmArticleOrder(widgetId, dataOrder) {
        const objWidget = new WidgetApi()

        const arrId = []
        if (dataOrder) {
            dataOrder.forEach(or => {
                arrId.push(or.id)
            });
        }

        if (arrId.length > 0) {
            const data = {
                // widget_asset_ids: arrId
                article_ids: arrId
            }

            objWidget.widgetArticleOrder(widgetId, data)
                .then(res => {
                    // this.getWidgetDetail()
                    this.setState({ is_internal: true })
                    this.getWidgetDetailWithId(widgetId)
                    window.location.reload()
                    // this.props.getSectionDetail()
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }

    render() {
        var TabContent = <>

            {
                this.state.widget.articles.map((item, index) => {

                    return (
                        <Tab.Pane eventKey={index}>
                            <br></br>
                            <ArticleForm
                                widgetName={this.props.widgetName}
                                widgetElementType={this.props.widgetElementType}
                                widgetElementStatic={this.props.widgetElementStatic}
                                article={item}
                                formType="Update"
                            ></ArticleForm>
                        </Tab.Pane>
                    )
                })
            }
        </>

        var TabContent, NavContent, NavAdd, TabAdd;

        if (this.state.widget.is_internal) {
            TabContent = this.state.widget.articles.map((item, index) => {
                return (
                    <Tab.Pane eventKey={index}>
                        <br></br>
                        <ArticleForm
                            widgetName={this.props.widgetName}
                            widgetElementType={this.props.widgetElementType}
                            widgetElementStatic={this.props.widgetElementStatic}
                            article={item}
                            formType="Update"
                            getWidgetDetail={this.getWidgetDetail}
                        ></ArticleForm>
                    </Tab.Pane>
                )
            })

            NavContent = this.state.widget.articles.map((item, index) => {

                return (
                    <Nav.Item>
                        <Nav.Link eventKey={index} style={{ borderRadius: 60, backgroundColor: "" }}>
                            {item.index}. {item.name}
                        </Nav.Link>
                    </Nav.Item>

                )
            })

            NavAdd = (
                <Nav.Item>
                    <Nav.Link eventKey="add-article" style={{ borderRadius: 60 }}>
                        Add New +
                    </Nav.Link>
                </Nav.Item>
            )

            TabAdd = (
                <Tab.Pane eventKey="add-article">
                    <br></br>
                    <ArticleForm
                        widgetName={this.props.widgetName}
                        widgetElementType={this.props.widgetElementType}
                        widgetElementStatic={this.props.widgetElementStatic}
                        widget={this.state.widget}
                        formType="Add"
                        getWidgetDetail={this.getWidgetDetail}
                    ></ArticleForm>
                </Tab.Pane>
            )
        } else if (!this.state.widget.is_internal && this.state.widget.id) {
            TabContent = (
                <Tab.Pane eventKey={0}>
                    <br></br>
                    <ArticleFormExternal
                        data={this.props.data}
                        collectionsList={this.state.collectionsList}
                    />
                </Tab.Pane>
            )
        }


        return (
            <Card>
                <SectionFormHeader

                    widget={this.state.widget}
                    section={this.props.section}

                    widgetName={this.props.widgetName}
                    widgetElementType={this.props.widgetElementType}
                    widgetElementStatic={this.props.widgetElementStatic}
                    setOpenCollapse={this.setOpenCollapse}
                    openCollapse={this.state.openCollapse}
                    icon={"fa-newspaper"}

                ></SectionFormHeader>
                <Collapse in={this.state.openCollapse} style={{ padding: "1.25rem" }}>
                    <CardBody id={`article-form-${this.props.widgetName}`}>

                        <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
                            <Row>
                                <Col>
                                    <div className="d-flex">
                                        <Nav variant="pills" className="scrollmenu" >
                                            {NavAdd}
                                            {NavContent}
                                        </Nav>
                                        {
                                            this.props.data.is_internal && this.state.widget.articles.length > 1 ?
                                                <div className='btn-w-ass-or'
                                                    onClick={this.setShowOrderArticle}
                                                >
                                                    <span>Order Article</span>
                                                </div>
                                                :
                                                <></>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Tab.Content>
                                        {this.state.TabContentAdd}
                                        {TabContent}
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </CardBody>
                </Collapse>
                <ArticleOrderModal
                    show={this.state.showOrderArticle}
                    onHide={this.setShowOrderArticle}
                    data={this.state.widget.articles}
                    widgetId={this.state.widget.id}
                    confirmArticleOrder={this.confirmArticleOrder}
                >
                </ArticleOrderModal>
            </Card>
        );
    }
}

export default ArticleCard;