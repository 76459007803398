import React, { Component } from 'react';
import { Modal, Button, Tabs, Tab } from "react-bootstrap"
import swal from "sweetalert"

import JobApi from "./../../api/JobApi"
import GeneralApi from "../../api/GeneralApi"
import { withTranslation } from 'react-i18next';
import Context from "../Context";

class JobTypeAddModal extends Component {
    static contextType = Context;
    constructor(props) {
        super(props)
        this.state = {
            JobType: "",
            jobTypeLanguages: [],
            languageList: [],

            tabKey: 1,
            selectedLanguage: 1,
            idElement: 0,
            renderTabLanguage: "",
            transPage: "modals.job_type_add.",
            transAlert: "alert."
        }

        this.onOpenModal = this.onOpenModal.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)
        this.handleChangeJobType = this.handleChangeJobType.bind(this)
        this.handleChangeTab = this.handleChangeTab.bind(this)


        this.addJobType = this.addJobType.bind(this)
        this.updateJobType = this.updateJobType.bind(this)

        this.renderTabLanguage = this.renderTabLanguage.bind(this)
    }

    onOpenModal() {
        const { languagesList } = this.context

        this.setState({
            id: this.props.jobTypeId | 0,
            tabKey: 1,
            selectedLanguage: 1,
            languageList: languagesList
        }, () => {

            if (this.props.jobTypeId) {
                this.getDetailJobType()
            } else {
                this.renderTabLanguage()
            }
        })
    }

    onCloseModal() {

        this.props.callback()
        this.props.onHide()

    }

    // handleChangeJobType(value) {
    //     this.setState({
    //         JobType:value
    //     })
    // }

    getDetailJobType() {
        const obJob = new JobApi()

        obJob.getJobTypeDetail(this.props.jobTypeId)
            .then(res => {

                // alert(JSON.stringify(res.data))

                this.setState({
                    id: res.data.id,
                    name: res.data.name,
                    jobTypeLanguages: res.data.job_type_languages,
                }, () => {

                    this.renderTabLanguage()
                })

            })
            .catch(err => {
                console.log(err.message)
            })
    }


    handleChangeJobType(id, value) {


        let job_type_languages = [...this.state.jobTypeLanguages]
        let job_type_language = { ...job_type_languages[id] }
        job_type_language.name = value
        job_type_languages[id] = job_type_language

        this.setState({
            jobType: job_type_languages[0].name,
            jobTypeLanguages: job_type_languages
        }, () => {
            // console.log(this.state.jobTypeLanguages)
        })
    }

    handleChangeTab(value) {

        this.setState({

            selectedLanguage: value,
            tabKey: value,
        }, () => {
            this.renderTabLanguage()
        })
    }

    addJobType() {
        const { t } = this.props;
        const obJob = new JobApi()

        const request = {
            name: this.state.jobType,
            job_type_languages: this.state.jobTypeLanguages
        }

        obJob.addJobType(request)
            .then(res => {
                swal("Success!", t(this.state.transAlert + "job_type_add_success"), "success")
                this.onCloseModal()
            })
            .catch(err => {
                swal("Error!", err.message, "error")
            })

    }

    updateJobType() {
        const { t } = this.props;
        const obJob = new JobApi()

        const request = {
            name: this.state.jobType,
            job_type_languages: this.state.jobTypeLanguages
        }

        obJob.updateJobType(this.props.jobTypeId, request)
            .then(res => {
                swal("Success!", t(this.state.transAlert + "job_type_update_success"), "success")
                this.onCloseModal()
            })
            .catch(err => {
                swal("Error!", err.message, "error")
            })
    }

    renderTabLanguage() {

        const renderTabLanguage = this.state.languageList.map(item => {
            var result = this.state.jobTypeLanguages.find(jobTypeLang => item.id === jobTypeLang.language)

            if (!result) {
                result = {
                    language: item.id,
                    name: "",
                    job_type: undefined,
                    languageName: item.name
                }
            }

            result.languageName = item.name
            return result
        })

        this.setState({
            jobTypeLanguages: renderTabLanguage
        }, () => {
            //console.log("jobTypeLanguages : ",this.state.jobTypeLanguages)
        })


    }


    render() {
        const { t } = this.props;
        var HeaderText = <Modal.Title> {t(this.state.transPage + "add_job_type")} </Modal.Title>

        var SubmitButton = <Button variant="primary" onClick={() => this.addJobType()}>
            <i className="fa fa-plus">&nbsp;</i>
            {t(this.state.transPage + "add_job_type")}
        </Button>

        if (this.props.jobTypeId) {
            HeaderText = <Modal.Title> {t(this.state.transPage + "update_job_type")} </Modal.Title>
            SubmitButton = <Button variant="primary" onClick={() => this.updateJobType()}>
                <i className="fa fa-plus">&nbsp;</i>
                {t(this.state.transPage + "update_job_type")}
            </Button>
        }

        const TabLanguage = this.state.jobTypeLanguages.map((item, index) => {


            return (

                <Tab key={index} eventKey={item.language} title={item.languageName}>
                    <br></br>

                    <div className="form-group">
                        <label> {t('job_type')} </label>
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.jobTypeLanguages[index].name}
                            onChange={(event) => this.handleChangeJobType(index, event.target.value)}
                        ></input>
                        {/* { JSON.stringify( this.state.jobTypeLanguages )  } */}
                    </div>

                </Tab>
            )



        })

        return (
            <Modal show={this.props.show} onHide={this.props.onHide} onShow={this.onOpenModal}>
                <Modal.Header closeButton className="bg-primary">
                    {HeaderText}
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <Tabs
                                id="controlled-tab-example-3"
                                activeKey={this.state.tabKey}
                                onSelect={(k) => this.handleChangeTab(k)}
                            >

                                {TabLanguage}


                            </Tabs>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>

                    {SubmitButton}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(JobTypeAddModal);