import React, { Component } from "react";
import { Tab, Col, Row, Collapse, Card, Nav, Carousel } from "react-bootstrap";

import swal from "sweetalert";

import TitlePages from "./Elements/TitlePages";
import { CardHeader } from "../components/Card";

import { Content, ContentHeader, ContentBody } from "./../components/Content";
import { CardBody } from "./../components/Card";

import EmailApi from "../api/EmailApi";
import { withTranslation } from "react-i18next";
import UserContext from "./Context";
import config from "../config/config";
import ReactGA from "react-ga";

class EmailSetting extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            activeTemplate: 0,
            isEmailLoaded: false,
            emailSettings: [],
            status: [
                {
                    status: "False",
                },
                {
                    status: "True",
                },
            ],
            emailID: 1,
            selectedTls: false,
            selectedSsl: false,
            openCollapse: false,
            transAlert: "alert.",
            address_all: [],
        };

        this.getEmailSettings = this.getEmailSettings.bind(this);
        this.tlsChange = this.tlsChange.bind(this);
        this.sslChange = this.sslChange.bind(this);
        this.updateEmailSetting = this.updateEmailSetting.bind(this);
        this.updateEmailRecipients = this.updateEmailRecipients.bind(this);
    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        const contextProps = this.context;
        this.setState(
            { activeTemplate: contextProps.activeTemplate },
            this.getEmailSettings
        );
    }

    componentDidUpdate() {
        const contextProps = this.context;
        if (contextProps.activeTemplate !== this.state.activeTemplate) {
            this.setState(
                { activeTemplate: contextProps.activeTemplate },
                this.getEmailSettings
            );
        }
    }

    handleChange(e) {
        const temp = [];
        const target = e.target.value;
        const removeSpace = target.replace(/ /g, "");
        const dataAdd = removeSpace.split(",");

        dataAdd.forEach((el) => {
            temp.push({
                id: null,
                address: el,
            });
        });

        this.setState({
            address_all: temp,
        });
    }

    getEmailSettings() {
        const Em = new EmailApi();

        Em.getEmailSettings(this.state.activeTemplate)
            .then((res) => {
                const email = [];
                let id, ssl, tls;

                res.data.results.forEach((item) => {
                    ssl = item.use_ssl;
                    tls = item.use_tls;
                    id = item.id;
                    email.push({
                        id: item.id,
                        template: item.template,
                        email_type: item.email_type,
                        host: item.host,
                        port: item.port,
                        host_user: item.host_user,
                        ssl: item.use_ssl,
                        tls: item.use_tls,
                        email_recipients: item.email_recipients,
                    });
                    item.email_recipients.forEach((re) => {
                        this.setState({
                            [`recepient${re.id}`]: false,
                        });
                    });
                });
                this.setState({
                    emailSettings: email,
                    emailID: id,
                    selectedTls: tls,
                    selectedSsl: ssl,
                    isEmailLoaded: true,
                });
            })
            .catch((err) => {});
    }

    tlsChange(e) {
        if (e.target.value) {
            this.setState({
                selectedTls: e.target.value,
            });
        }
    }

    sslChange(e) {
        if (e.target.value) {
            this.setState({
                selectedSsl: e.target.value,
            });
        }
    }

    updateEmailSetting() {
        const { t } = this.props;
        const Em = new EmailApi();
        const capitalize = (s) => {
            if (typeof s !== "string") return "";
            return s.charAt(0).toUpperCase() + s.slice(1);
        };
        const emailData = {
            template: this.state.activeTemplate,
            email_type: "Email Backend",
            host: document.getElementById("host").value,
            port: document.getElementById("port").value,
            host_user: document.getElementById("host_user").value,
            host_password: document.getElementById("host_password").value,
            use_tls: this.state.selectedTls
                ? capitalize(String(this.state.selectedTls))
                : "False",
            use_ssl: this.state.selectedSsl
                ? capitalize(String(this.state.selectedSsl))
                : "False",
        };
        Em.updateEmailSettings(this.state.emailID, emailData)
            .then((res) => {
                if (res) {
                    swal(
                        "Success !",
                        t(
                            this.state.transAlert +
                                "email_settings_update_success"
                        ),
                        "success"
                    );
                    this.getEmailSettings();
                }
            })
            .catch((err) => {
                swal("Error!", err.message, "error");
            });
    }

    updateEmailRecipients(param) {
        const { t } = this.props;
        const Em = new EmailApi();

        const emailRecipients = {
            id: param,
            // name: document.getElementById(`nameR${param}`).value,
            from_name: document.getElementById(`fromN${param}`).value,
            from_address: document.getElementById(`fromA${param}`).value,
            to_address: document.getElementById(`toR${param}`).value,
            to_addresses: this.state.address_all,
        };

        console.log(emailRecipients);
        Em.updateEmailRecipients(param, emailRecipients)
            .then((res) => {
                if (res) {
                    swal(
                        "Success !",
                        t(
                            this.state.transAlert +
                                "email_recipient_update_success"
                        ),
                        "success"
                    );
                    this.getEmailSettings();
                }
            })
            .catch((err) => {
                swal("Error!", err.message, "error");
            });
    }

    render() {
        let span;
        const { t } = this.props;
        const { emailSettings } = this.state;
        let host, port, hostEmail, tls, ssl, emailRecipients;

        if (this.state.isEmailLoaded && emailSettings.length) {
            const email = emailSettings[0];
            host = email.host;
            port = email.port;
            hostEmail = email.host_user;
            tls = email.tls ? email.tls : false;
            ssl = email.ssl ? email.ssl : false;

            emailRecipients = email.email_recipients.map((em, index) => {
                if (index === 0) {
                    span = (
                        <span style={{ fontSize: "11px" }}>
                            Example : support@phenikaa.com, user@phenikaa.com,
                            admin@phenikaa.com
                        </span>
                    );
                } else {
                    span = <></>;
                }
                var arrow = (
                    <button className="btn btn-xs">
                        <i className="fas fa-chevron-up"></i>
                    </button>
                );
                var bgColor = "";
                if (!this.state.openCollapse) {
                    arrow = (
                        <button className="btn btn-xs">
                            <i className="fas fa-chevron-down text-white"></i>
                        </button>
                    );
                    bgColor = "bg-primary";
                }

                return (
                    <Card>
                        <CardHeader title={"test"} className={`${bgColor}`}>
                            <div style={{ float: "left" }}>
                                <div
                                    style={{
                                        width: "100%",
                                        float: "left",
                                        marginLeft: 0,
                                        fontWeight: "bold",
                                    }}
                                >
                                    {em.name}{" "}
                                    <i className="fas fa-pencil"> &nbsp;</i>
                                </div>
                            </div>

                            <div
                                style={{ float: "right", marginLeft: "2%" }}
                                className="arrow-toggle"
                                onClick={() => {
                                    this.setState((prevState) => ({
                                        [`recepient${em.id}`]:
                                            !prevState[`recepient${em.id}`],
                                    }));
                                }}
                            >
                                {arrow}
                            </div>
                        </CardHeader>
                        <Collapse
                            in={this.state[`recepient${em.id}`]}
                            style={{ padding: "1.25rem" }}
                        >
                            <CardBody>
                                <div className="col-md-12">
                                    {/* <div className="form-group">
                    <label>{t("name")}</label>
                    <input
                      id={`nameR${em.id}`}
                      type="text"
                      defaultValue={em.name}
                      className="form-control"
                    ></input>
                  </div> */}
                                    <div className="form-group">
                                        <label>
                                            {t(
                                                "pages.email_settings.from_name"
                                            )}
                                        </label>
                                        <input
                                            id={`fromN${em.id}`}
                                            type="text"
                                            defaultValue={em.from_name}
                                            className="form-control"
                                        ></input>
                                    </div>
                                    <div className="form-group">
                                        <label>
                                            {t(
                                                "pages.email_settings.from_address"
                                            )}
                                        </label>
                                        <input
                                            id={`fromA${em.id}`}
                                            type="text"
                                            defaultValue={em.from_address}
                                            className="form-control"
                                        ></input>
                                    </div>
                                    <div
                                        className="form-group"
                                        style={{ marginBottom: "0" }}
                                    >
                                        <label>{t("to")}</label>
                                        <input
                                            id={`toR${em.id}`}
                                            type="text"
                                            defaultValue={em.to_address}
                                            className="form-control"
                                            onChange={(e) =>
                                                this.handleChange(e)
                                            }
                                        ></input>
                                    </div>
                                    {span}
                                    <div
                                        className="float-right"
                                        style={{ marginTop: "1rem" }}
                                    >
                                        <div
                                            className="btn btn-primary"
                                            onClick={() =>
                                                this.updateEmailRecipients(
                                                    em.id
                                                )
                                            }
                                        >
                                            <i className="fa fa-edit">&nbsp;</i>
                                            Update
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Collapse>
                    </Card>
                );
            });
        }

        return (
            <Content>
                <ContentHeader>
                    <TitlePages title={t("pages.email_settings.title")} />
                </ContentHeader>
                <ContentBody>
                    <div className="pl-4 pr-4 pb-4">
                        <div className="clearfix"></div>
                        <div>
                            <b>
                                {t(
                                    "pages.email_settings.email_server_settings"
                                )}
                            </b>
                            <hr></hr>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t("host")}</label>
                                        <input
                                            id="host"
                                            type="text"
                                            defaultValue={host}
                                            className="form-control"
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t("port")}</label>
                                        <input
                                            id="port"
                                            type="text"
                                            defaultValue={port}
                                            className="form-control"
                                        ></input>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t("email")}</label>
                                        <input
                                            id="host_user"
                                            type="text"
                                            defaultValue={hostEmail}
                                            className="form-control"
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t("password")}</label>
                                        <input
                                            id="host_password"
                                            type="password"
                                            className="form-control"
                                        ></input>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t("pages.email_settings.use_tls")}
                                        </label>
                                        <select
                                            className="form-control"
                                            onChange={this.tlsChange}
                                        >
                                            {this.state.status.map((item) => {
                                                return (
                                                    <option
                                                        value={item.status}
                                                        selected={
                                                            Boolean(
                                                                item.status.toLowerCase()
                                                            ) === Boolean(tls)
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        {item.status}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t("pages.email_settings.use_ssl")}
                                        </label>
                                        <select
                                            className="form-control"
                                            onChange={this.sslChange}
                                        >
                                            {this.state.status.map((item) => {
                                                // const capitalize = (s) => {
                                                //     if (typeof s !== 'string') return ''
                                                //     return s.charAt(0).toUpperCase() + s.slice(1)
                                                // }
                                                // let selected = item.status === ssl ? true : false
                                                // let val = capitalize(String(item.status))
                                                return (
                                                    <option
                                                        value={item.status}
                                                        selected={
                                                            Boolean(
                                                                item.status.toLowerCase()
                                                            ) === Boolean(ssl)
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        {item.status}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="float-right">
                                <div
                                    className="btn btn-primary"
                                    onClick={this.updateEmailSetting}
                                >
                                    <i className="fa fa-edit">&nbsp;</i>
                                    {t("update")}
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <b>{t("pages.email_settings.email_details")}</b>
                            <hr></hr>
                            <div className="">{emailRecipients}</div>
                        </div>
                    </div>
                </ContentBody>
            </Content>
        );
    }
}

export default withTranslation()(EmailSetting);
