import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import dateFormat from "dateformat";
import JobApi from "./../../api/JobApi";
import swal from "sweetalert";
import "./Logs.css";

class LogsListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      app_logs: [],
      id: 0,
      is_sent: false,
      isLoading: false,
    };

    this.onOpenModal = this.onOpenModal.bind(this);
    this.sendEmailToApplicant = this.sendEmailToApplicant.bind(this);
  }

  onOpenModal() {
    if (this.props.app_logs) {
      this.setState({
        app_logs: this.props.app_logs,
        id: this.props.id,
      });
    }
  }

  componentDidUpdate() {
    console.log("componentdidUpdate", this.state.is_sent);
    var isSent = false;
    if (this.state.is_sent !== isSent) {
      this.setState({
        is_sent: isSent,
      });
    }
  }

  setShowLoading() {
    console.log("masuk");
    this.setState({
      isLoading: !this.state.isLoading,
    });
  }

  sendEmailToApplicant(param) {
    let lang = localStorage.getItem("language");
    let tmp = parseInt(localStorage.getItem("templateId"));
    let current_language;
    if (lang === "English") {
      current_language = "en";
    } else {
      current_language = "vi";
    }
    const data = {
      template_id: tmp,
      log_id: param,
      language: current_language,
    };

    const api = new JobApi();

    this.setShowLoading();

    this.setState({
      isLoading: true,
    });

    api
      .sendEmailToApplicant(data, this.state.id)
      .then((res) => {
        this.setState({
          is_sent: true,
          isLoading: false,
        });
        swal("Success!", "Success updated!", "success");
        this.props.onHide();
        this.props.get();
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  render() {
    const { t } = this.props;

    // let loading = (
    //   <div class="spinner-border m-5" role="status">
    //     <span class="sr-only">Loading...</span>
    //   </div>
    // );

    // let isLoad = this.state.isLoading ? loading : <></>;

    const Logs = this.state.app_logs.map((item, index) => {
      const date = item.updated_at;
      const format = dateFormat(date, "dd/mm/yyyy");
      let current_status, btn_send;

      if (item.email_sent) {
        current_status = <span>Sent</span>;
        btn_send = (
          <button
            className="badge badge-success"
            onClick={() => this.sendEmailToApplicant(item.id)}
          >
            {t("sent")}
          </button>
        );
      } else {
        current_status = <span>Unsent</span>;
        btn_send = (
          <button
            className="badge badge-primary"
            onClick={() => this.sendEmailToApplicant(item.id)}
          >
            {t("button_send_email")}
          </button>
        );
      }

      return (
        <tr id="tr_on_logs">
          <td>{index + 1}</td>
          <td>{format}</td>
          <td>{item.status}</td>
          <td>{current_status}</td>
          <td>{btn_send}</td>
        </tr>
      );
    });

    return (
      <Modal
        size="lg"
        show={this.props.show}
        onHide={this.props.onHide}
        onShow={this.onOpenModal}
      >
        <Modal.Header closeButton className="bg-primary">
          {t("logs")}
        </Modal.Header>
        <Modal.Body>
          <div className="row" id="table_on_logs">
            <table className="table table-bordered">
              <thead>
                <th> {t("ID")} </th>
                <th> {t("updated_at")} </th>
                <th> {t("status_confirmation")} </th>
                <th> {t("email_sent")} </th>
                <th> {t("action")} </th>
              </thead>
              <tbody>{Logs}</tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={() => {
              this.props.onHide();
            }}
          >
            {" "}
            {t("ok")}{" "}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(LogsListModal);
