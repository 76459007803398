import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"
import { withTranslation } from 'react-i18next';

class JobPosterModalDelete extends Component {
    constructor(props) {
        super(props)
        this.state = {
            transPage: "modals.job_poster_delete."
        }

        this.deleteSelectedJob = this.deleteSelectedJob.bind(this)
        // this.onHideModal = this.onHideModal.bind(this)
    }

    deleteSelectedJob() {
        // dari props method nya
        this.props.deleteSelectedJob()
    }

    render() {
        const { t } = this.props;

        const listPages = this.props.selectedJobs?.map(item => {

            return (<li key={item.id}>{item.article.article_languages.title}</li>)
        })

        return (
            <Modal show={this.props.show} onHide={() => this.props.onHide()}>
                <Modal.Header closeButton className="bg-danger">
                    <Modal.Title> {t(this.state.transPage + "title")} </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p> {t(this.state.transPage + "confirmation_text")} </p>
                    <ul>
                        {listPages}
                    </ul>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.props.onHide()}>
                        {t('cancel')}
                    </Button>
                    <Button variant="danger" onClick={() => { this.deleteSelectedJob() }}>
                        <i className="fa fa-trash">&nbsp;</i>
                        {t(this.state.transPage + "delete_job")}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(JobPosterModalDelete);