import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

// import Dashboard from "./components/section/Dashboard";
import NewDashboard from "./components/section/DashboardNew";

import ArticleListPage from "./pages/ArticleListPage";
import AboutPage from "./pages/AboutPage";

// import CompanyDetailPage from './pages/CompanyDetailPage'
import SectionCatalog from "./pages/SectionCatalog";
import SectionDetailPage from "./pages/SectionDetail";
// import PageDetail from "./pages/PageDetail";
import NewPageDetail from "./pages/NewPageDetail/NewPageDetail";
import WidgetList from "./pages/WidgetListPage";
import WidgetDetailPage from "./pages/WidgetDetailPage";
// import WidgetDetailSamplePage from "./pages/WidgetDetailPage"

import Page404 from "./pages/Page404";
import ArticleDetailPage from "./pages/ArticleDetailPage";
import AssetListPage from "./pages/AssetListPage";
import AssetAdd from "./pages/AssetAdd";
import AssetDetailPage from "./pages/AssetDetailPage";
import { Helmet } from "react-helmet";
import CategoryPage from "./pages/CategoryPage";
import UserDetail from "./pages/UserDetailPage";
// import TestMenu from "./samples/containers/TestMenuPage";
import WidgetsPage from "./pages/WidgetsPage";
import JobPosterList from "./pages/Job/JobPosterList";
import JobPosterAdd from "./pages/Job/JobPosterAdd";
import TestPage from "./pages/TestPage";
import MenuListPage from "./pages/MenuListPage";
import MenuDetailPage from "./pages/MenuDetailPage";
import SiteStructure from "./pages/SiteStructure";
import User from "./pages/User";
import Login from "./pages/Login";
import EmailSetting from "./pages/EmailSetting";
import EmailTemplate from "./pages/EmailTemplate";
import PrivateRoute from "./components/Route/PrivateRoute";
// import PublicRoute from './components/Route/PublicRoute';
// import { getToken } from "./lib/Common";
import TemplateList from "./pages/TemplateList";
import TemplateDetail from "./pages/TemplateDetail";
import ApplicantDetail from "./pages/Job/ApplicantDetail";
import TestSidebarPage from "./pages/TestSidebarPage";
import NewSideBar from "./samples/NewSidebar/NewSideBar";
import GASettingPage from "./pages/GASettingPage";
import BucketSettingPage from "./pages/BucketSettingPage";
import SectionCategoriesPage from "./pages/SectionCategoriesPage";
import LocationPage from "./pages/LocationPage";
import LocationDetailPage from "./pages/LocationDetailPage";

import Sample from './pages/Sample'

// import LangList from "./pages/LanguageList"
import LanguageJSON from "./pages/LanguageJSON";
import LanguagePage from "./pages/LanguagePage";

import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import Context from "./pages/Context";
import SitemapSettingsPage from "./pages/SitemapSettingsPage";

import AuthApi from './api/AuthApi'
import LanguageApi from "./api/LanguageApi";
import WidgetApi from "./api/WidgetApi"
import PageApi from "./api/PageApi"
import ArticleApi from "./api/ArticleApi"
import SectionApi from "./api/SectionApi"
import UserApi from "./api/UserApi"

import { setMobileCookie, removeMobileCookie } from "./lib/Common";

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);

    const { cookies } = props;
    this.state = {
      lang: "en",
      titleName: "Phenikaa",
      userKey: cookies.get("key"),
      user: cookies.get("user"),
      employee_privileges: cookies.get("employee_privileges"),
      removeDataUser: () => {
        this.removeDataUser();
      },

      activeSite: cookies.get("active-site") ? cookies.get("active-site") : 5,
      changeActiveSite: (value) => {
        this.changeActiveSite(value);
      },

      activeTemplate: cookies.get("active-template")
        ? cookies.get("active-template")
        : 41,
      changeActiveTemplate: (value) => {
        this.changeActiveTemplate(value);
      },
      languagesList: [],
      sectionDetail: {
        methods: {
          getAllWidget: (callback) => { this.getAllWidget(callback) },
          getPages: (callback) => { this.getPages(callback) },
          getArticleCategories: (callback) => { this.getArticleCategories(callback) },
          getAllSections: (callback) => { this.getAllSections(callback) },
          getAllEmployees: (callback) => { this.getAllEmployees(callback) },
        },
        isLoaded: {},
        widgetList: [],
        pagesList: [],
        categoryList: [],
        employeeList: []
      },
      route: [
        // Last id = 35
        // article
        {
          privelege: [1, 2, 6],
          id: 1,
          path: "/article/widgets/detail/:widgetId",
          component: ArticleDetailPage,
        },
        {
          privelege: [1, 2, 6],
          id: 2,
          path: "/article/detail/:articleId/:widgetId",
          component: ArticleDetailPage,
        },
        {
          privelege: [1, 2, 6],
          id: 3,
          path: "/article/detail/:articleId",
          component: ArticleDetailPage,
        },
        {
          privelege: [1, 2, 6],
          id: 4,
          path: "/article/add",
          component: ArticleDetailPage,
        },
        {
          privelege: [1, 2, 6],
          id: 5,
          path: "/article",
          component: ArticleListPage,
        },
        {
          privelege: [1, 2, 6],
          id: 39,
          path: "/location/detail/:locationId",
          component: LocationDetailPage,
        },
        {
          privelege: [1, 2, 6],
          id: 40,
          path: "/location/add",
          component: LocationDetailPage,
        },
        {
          privelege: [1, 2, 6],
          id: 6,
          path: "/location",
          component: LocationPage,
        },
        // page
        {
          privelege: [2, 6],
          id: 7,
          path: "/page/detail/:pageId",
          component: NewPageDetail,
        },
        // menu
        {
          privelege: [2, 6],
          id: 8,
          path: "/menu-list/:menuId",
          component: MenuDetailPage,
        },
        { privelege: [2, 6], id: 9, path: "/menu-list", component: MenuListPage },
        // asset
        {
          privelege: [2, 6],
          id: 10,
          path: "/asset/detail/:assetId",
          component: AssetDetailPage,
        },
        { privelege: [2, 6], id: 11, path: "/asset/add", component: AssetAdd },
        { privelege: [2, 6], id: 12, path: "/asset", component: AssetListPage },
        // job poster
        {
          privelege: [3, 5],
          id: 13,
          path: "/job-poster/applicant/detail/:applicantId",
          component: ApplicantDetail,
        },
        {
          privelege: [3, 5],
          id: 14,
          path: "/job-poster/detail/:jobPosterId",
          component: JobPosterAdd,
        },
        {
          privelege: [3],
          id: 15,
          path: "/job-posters/add",
          component: JobPosterAdd,
        },
        {
          privelege: [3, 5],
          id: 16,
          path: "/job-posters",
          component: JobPosterList,
        },
        // admin setting
        {
          privelege: [4],
          id: 17,
          path: "/employee/detail/:employeeId",
          component: UserDetail,
        },
        {
          privelege: [4],
          id: 18,
          path: "/employee/add",
          component: UserDetail,
        },
        { privelege: [4], id: 19, path: "/user", component: User },
        { privelege: [4], id: 20, path: "/user", component: User },
        { privelege: [4], id: 21, path: "/category", component: CategoryPage },
        { privelege: [4], id: 22, path: "/about", component: AboutPage },
        {
          privelege: [4],
          id: 23,
          path: "/email-settings",
          component: EmailSetting,
        },
        {
          privelege: [4],
          id: 24,
          path: "/email-template",
          component: EmailTemplate,
        },
        {
          privelege: [4],
          id: 25,
          path: "/s3-bucket-settings",
          component: BucketSettingPage,
        },
        {
          privelege: [4],
          id: 26,
          path: "/ga-number-settings",
          component: GASettingPage,
        },
        {
          privelege: [4],
          id: 27,
          path: "/sitemap-settings",
          component: SitemapSettingsPage,
        },
        {
          privelege: [4],
          id: 28,
          path: "/language",
          component: LanguagePage,
        },
        // superuser
        {
          privelege: [6, 99],
          id: 29,
          path: "/section/detail/:sectionId",
          component: SectionDetailPage,
        },
        {
          privelege: [99],
          id: 30,
          path: "/section/developer/:sectionId",
          component: WidgetList,
        },
        {
          privelege: [6, 99],
          id: 31,
          path: "/section/newdetail/:sectionId",
          component: SectionDetailPage,
        },
        {
          privelege: [6, 99],
          id: 32,
          path: "/widget/detail/:widgetId",
          component: WidgetDetailPage,
        },
        {
          privelege: [99],
          id: 33,
          path: "/section/catalog",
          component: SectionCatalog,
        },
        {
          privelege: [99],
          id: 34,
          path: "/section-categories",
          component: SectionCategoriesPage,
        },
        { privelege: [99], id: 35, path: "/widgets", component: WidgetsPage },
        {
          privelege: [99],
          id: 36,
          path: "/template/detail/:templateId",
          component: TemplateDetail,
        },
        { privelege: [99], id: 37, path: "/template", component: TemplateList },
        {
          privelege: [4],
          id: 38,
          path: "/language-json",
          component: LanguageJSON,
        },
      ],
    };

    this.changeLanguage = this.changeLanguage.bind(this);
    this.getDataUser = this.getDataUser.bind(this);
    this.removeDataUser = this.removeDataUser.bind(this);
    this.changeActiveSite = this.changeActiveSite.bind(this);
    this.changeActiveTemplate = this.changeActiveTemplate.bind(this);
    this.getAllWidget = this.getAllWidget.bind(this)
    this.getArticleCategories = this.getArticleCategories.bind(this)
    this.getPages = this.getPages.bind(this)
    this.getAllSections = this.getAllSections.bind(this)
  }

  componentDidMount() {
    const languageApi = new LanguageApi();

    languageApi.getAllLanguage()
      .then(res => {
        this.setState({
          languagesList: res.data.results
        })
      })
  }

  changeLanguage(lang) {
    localStorage.setItem("lang", lang);
    this.setState(
      {
        lang: lang,
      },
      () => {
        window.location.reload();
      }
    );
  }

  getDataUser(key, dataUser, privilege) {
    const { cookies } = this.props;
    if (window.screen.width > 1024) {
      cookies.set("key", key, { path: "*" });
      cookies.set("user", dataUser, { path: "*" });
      cookies.set("employee_privileges", privilege, { path: "*" });
    } else {
      setMobileCookie(key, dataUser, privilege)
    }

  }

  changeActiveSite(site) {
    const { cookies } = this.props;
    const aS = localStorage.getItem("siteId");
    let activeSite;
    if (aS) {
      if (this.state.activeSite !== site) {
        activeSite = site;
        cookies.set("active-site", site, { path: "*" });
        localStorage.setItem("siteId", site);
      } else {
        activeSite = aS;
      }
    } else {
      activeSite = site;
      cookies.set("active-site", site ? site : 5, { path: "*" });
      localStorage.setItem("siteId", site);
    }

    this.setState({
      activeSite: activeSite,
    });
  }

  changeActiveTemplate(template) {
    const { cookies } = this.props;
    const aT = localStorage.getItem("templateId");

    let activeTemplate;
    if (aT) {
      if (this.state.activeTemplate !== template) {
        activeTemplate = template;
        cookies.set("active-template", template, { path: "*" });
        localStorage.setItem("templateId", template);
      } else {
        activeTemplate = aT;
      }
    } else {
      activeTemplate = template;
      cookies.set("active-template", template ? template : 41, { path: "*" });
      localStorage.setItem("templateId", template);
    }

    this.setState(
      {
        activeTemplate: activeTemplate,
      },
      () => { }
    );
  }

  removeDataUser() {
    const { cookies } = this.props;
    const objAuth = new AuthApi()

    // objAuth.logout()
    //   .then(res => {
    //     if (res) {
    this.setState(
      {
        userKey: false,
        user: false,
        employee_privileges: false,
      },
      () => {
        if (window.screen.width > 1024) {
          cookies.remove("key", { path: "*" });
          cookies.remove("user", { path: "*" });
          cookies.remove("employee_privileges", { path: "*" });
        } else {
          removeMobileCookie()
        }

      }
    );
    //   }
    // })
    // .catch(err => {

    // })
  }

  // SECTION DETAIL

  getAllWidget(callback) {
    const objWidget = new WidgetApi()

    const params = {
      template: Number(localStorage.getItem("templateId")),
      max_size: true,
      category: 3,
      internal: this.state.is_internal
    }

    objWidget.getAllWidgets(params)
      .then(res => {
        // namePage setting 
        const widgetList = res.data.results.map(item => {

          var page = ""
          if (item.page) {
            page = " - " + item.page
          }

          return {
            ...item,
            namePage: item.name + page
          }
        })

        let sectionDetail = {
          ...this.state.sectionDetail,
          widgetList: widgetList,
          isLoaded: {
            ...this.state.sectionDetail.isLoaded,
            widgetList: true
          }
        }

        this.setState({
          sectionDetail: sectionDetail
        }, () => {
          if (typeof callback === 'function') {
            callback()
          }
        })
      })
  }

  getPages(callback) {
    const objPage = new PageApi()

    const params = {
      language: localStorage.getItem("lang"),
      template: localStorage.getItem("templateId"),
      max_size: true
    }

    objPage.pageLanguageList(params)
      .then(res => {
        let sectionDetail = {
          ...this.state.sectionDetail,
          pagesList: res.data.results,
          isLoaded: {
            ...this.state.sectionDetail.isLoaded,
            pagesList: true
          }
        }

        this.setState({
          sectionDetail: sectionDetail
        }, () => {
          if (typeof callback === 'function') {
            callback()
          }
        })
      })
  }

  getArticleCategories(callback) {
    const objArticle = new ArticleApi()

    objArticle.getArticleCategories()
      .then(res => {
        let sectionDetail = {
          ...this.state.sectionDetail,
          categoryList: res.data.results ? res.data.results : [],
          isLoaded: {
            ...this.state.sectionDetail.isLoaded,
            categoryList: true
          }
        }

        this.setState({
          sectionDetail: sectionDetail
        }, () => {
          if (typeof callback === 'function') {
            callback()
          }
        })
      })
  }

  getAllSections(callback) {
    const obj = new SectionApi()

    obj.getAllSections()
      .then(res => {
        let sectionDetail = {
          ...this.state.sectionDetail,
          sectionList: res.data.results || [],
          isLoaded: {
            ...this.state.sectionDetail.isLoaded,
            sectionList: true
          }
        }

        this.setState({
          sectionDetail: sectionDetail
        }, () => {
          if (typeof callback === 'function') {
            callback()
          }
        })
      })
  }

  getAllEmployees(callback) {
    const userApi = new UserApi()

    userApi.getAllEmployees({
      max_size: true
    })
      .then(res => {
        const resEmployee = res.data.results.map(item => {
          var image = ""
          if (item.avatar) {
            image = <div><img width="30" height="30" src={item.avatar} alt=""></img>{" " + item.user.first_name + " " + item.user.last_name}</div>
          } else {
            image = item.user.first_name + " " + item.user.last_name
          }

          return ({
            val: item.id,
            label: image
          })
        })

        let sectionDetail = {
          ...this.state.sectionDetail,
          employeeList: resEmployee,
          isLoaded: {
            ...this.state.sectionDetail.isLoaded,
            employeeList: true
          }
        }

        this.setState({
          sectionDetail: sectionDetail
        }, () => {
          if (typeof callback === 'function') {
            callback()
          }
        })
      })
  }

  render() {
    const { user } = this.state;
    const specialRoute = [
      "/email-settings",
      "/s3-bucket-settings",
      "/ga-number-settings",
      "/widget/detail/:widgetId",
      "/template",
    ];
    let allRoute, prev;
    if (user) {
      if (user.is_superuser) {
        allRoute = this.state.route.map((route) => {
          let routes;
          if (specialRoute.includes(route.path)) {
            switch (route.path) {
              case "/s3-bucket-settings":
                routes = (
                  <PrivateRoute
                    path={route.path}
                    render={(props) => (
                      <BucketSettingPage
                        {...props}
                        activeTemplate={this.state.activeTemplate}
                      />
                    )}
                  />
                );
                break;
              case "/email-settings":
                routes = (
                  <PrivateRoute
                    path={route.path}
                    render={(props) => (
                      <EmailSetting
                        {...props}
                        activeTemplate={this.state.activeTemplate}
                      />
                    )}
                  />
                );
                break;
              case "/ga-number-settings":
                routes = (
                  <PrivateRoute
                    path={route.path}
                    render={(props) => (
                      <GASettingPage
                        {...props}
                        activeTemplate={this.state.activeTemplate}
                      />
                    )}
                  />
                );
                break;
              case "/widget/detail/:widgetId":
                routes = (
                  <PrivateRoute
                    path={route.path}
                    render={(props) => (
                      <WidgetDetailPage
                        {...props}
                        activeTemplate={this.state.activeTemplate}
                      />
                    )}
                  />
                );
                break;
              case "/template":
                routes = (
                  <PrivateRoute
                    path={route.path}
                    render={(props) => (
                      <TemplateList
                        {...props}
                        activeTemplate={this.state.activeTemplate}
                        activeSite={this.state.activeSite}
                      />
                    )}
                  />
                );
                break;
              default:
                break;
            }
          } else {
            routes = (
              <PrivateRoute path={route.path} component={route.component} />
            );
          }
          return routes;
        });
      } else {
        prev = this.state.employee_privileges;
        allRoute = this.state.route.map((route) => {
          let routes = [];
          let idxItems = [];
          prev.forEach((p) => {
            if (
              route.privelege.includes(p.id) &&
              !idxItems.includes(route.path)
            ) {
              idxItems.push(route.path);
              routes.push(
                <PrivateRoute path={route.path} component={route.component} />
              );
            }
          });
          return routes;
        });
      }
    }

    const { languagesList } = this.state

    // if (getToken()) {
    if (this.state.userKey) {
      return (
        <Context.Provider value={this.state}>
          <Helmet>
            <title>{this.state.titleName}</title>
          </Helmet>
          <Router>
            <NewDashboard
              activeSite={this.state.activeSite}
              activeTemplate={this.state.activeTemplate}
              changeLanguage={this.changeLanguage}
              languageList={languagesList}
            >
              <Switch>
                {allRoute}
                {/* { OLD ROUTE } */}
                {/* <PrivateRoute path="/section/detail/HIỆN THỰC HÓA MỌI CAM KẾT" component={TestPage}></PrivateRoute> */}
                {/* <PrivateRoute path="/sites/detail/:siteId" component={(props) => DashboardRender(CompanyDetailPage,props)}></PrivateRoute> */}
                {/* <PrivateRoute path='/page/detail/:pageId' component={PageDetail} /> */}
                {/* <PrivateRoute path="/widget/detailsample/:widgetId" component={WidgetDetailSamplePage} /> */}

                {/* <PrivateRoute path="/sites/detail/:siteId" component={SiteStructure}></PrivateRoute>
                                    <PrivateRoute path="/page/detail/:pageId" component={NewPageDetail} />
                                    <PrivateRoute path="/section/developer/:sectionId" component={WidgetList} />

                                    <PrivateRoute path="/section/detail/:sectionId" component={SectionDetailPage} />
                                    <PrivateRoute path="/section/newdetail/:sectionId" component={SectionDetailPage} />

                                    <PrivateRoute path="/section/catalog" component={SectionCatalog} />
                                    <PrivateRoute path="/widget/detail/:widgetId" component={WidgetDetailPage} />

                                    <PrivateRoute path="/job-poster/detail/:jobPosterId" component={JobPosterAdd}></PrivateRoute>
                                    <PrivateRoute path="/job-poster/applicant/detail/:applicantId" component={ApplicantDetail}></PrivateRoute>

                                    <PrivateRoute path="/article/widgets/detail/:widgetId" component={ArticleDetailPage}></PrivateRoute>
                                    <PrivateRoute path="/article/detail/:articleId/:widgetId" component={ArticleDetailPage}></PrivateRoute>
                                    <PrivateRoute path="/article/detail/:articleId" component={ArticleDetailPage} ></PrivateRoute>
                                    <PrivateRoute path="/employee/detail/:employeeId" component={UserDetail} ></PrivateRoute>
                                    <PrivateRoute path="/asset/detail/:assetId" component={AssetDetailPage} ></PrivateRoute>
                                    <PrivateRoute path="/template/detail/:templateId" component={TemplateDetail} ></PrivateRoute>
                                    <PrivateRoute path="/menu-list/:menuId" component={MenuDetailPage} ></PrivateRoute>
                                    <PrivateRoute path="/job-posters/add" component={JobPosterAdd} ></PrivateRoute>

                                    <PrivateRoute path="/article/add" component={ArticleDetailPage} ></PrivateRoute>
                                    <PrivateRoute path="/asset/add" component={AssetAdd} ></PrivateRoute>
                                    <PrivateRoute path="/employee/add" component={UserDetail} ></PrivateRoute>
                                    <PrivateRoute path="/sites/widgets" component={WidgetList} ></PrivateRoute>
                                    <PrivateRoute path="/s3-bucket-settings" component={BucketSettingPage} />
                                    <PrivateRoute path="/ga-number-settings" component={GASettingPage} ></PrivateRoute>
                                    <PrivateRoute path="/email-settings" component={EmailSetting} ></PrivateRoute>
                                    <PrivateRoute path="/email-template" component={EmailTemplate} ></PrivateRoute>
                                    <PrivateRoute path="/category" component={CategoryPage} ></PrivateRoute>
                                    <PrivateRoute path="/template" component={TemplateList} ></PrivateRoute>
                                    <PrivateRoute path="/site-structure" component={SiteStructure} ></PrivateRoute>
                                    <PrivateRoute path="/asset" component={AssetListPage} ></PrivateRoute>
                                    <PrivateRoute path="/article" component={ArticleListPage} ></PrivateRoute>
                                    <PrivateRoute path="/widgets" component={WidgetsPage} ></PrivateRoute>
                                    <PrivateRoute path="/job-posters" component={JobPosterList} ></PrivateRoute>
                                    <PrivateRoute path="/section-categories" component={SectionCategoriesPage} ></PrivateRoute>
                                    <PrivateRoute path="/user" component={User}></PrivateRoute>

                                    <PrivateRoute path="/about" component={AboutPage}></PrivateRoute>
                                    <PrivateRoute path="/menu-list" component={MenuListPage} ></PrivateRoute>
                                    <PrivateRoute path="/test-menu" component={TestMenu} ></PrivateRoute>
                                    <PrivateRoute path="/test" component={TestPage}></PrivateRoute>

                                    <PrivateRoute path="/new-sidebar" component={NewSideBar} ></PrivateRoute> */}

                {/* <PrivateRoute exact={true} path="/dashboard" component={SiteStructure} ></PrivateRoute> */}
                <PrivateRoute path="/sample" component={Sample} />
                <Route
                  exact
                  path="/login"
                  render={() => {
                    return <Redirect to="/" />;
                  }}
                />
                <PrivateRoute
                  path="/"
                  exact={true}
                  component={SiteStructure}
                ></PrivateRoute>

                <PrivateRoute path="*" component={Page404}></PrivateRoute>
              </Switch>
            </NewDashboard>
          </Router>
        </Context.Provider>
      );
    } else {
      return (
        <Router>
          <Switch>
            {/* <Route path="/" exact={true} component={Login}></Route> */}
            {/* <Route path="/login" exact={true} component={Login}></Route> */}
            <Route
              path="/login"
              exact={true}
              render={(props) => (
                <Login {...props} getDataUser={this.getDataUser} propState={this.state} />
              )}
            />
            <Route path="/test-sidebar" component={TestSidebarPage}></Route>
            <Route path="/test-route" component={TestPage}></Route>
            <Route path="/new-sidebar" component={NewSideBar}></Route>

            {/* <Route path="*" component={Page404}></Route> */}
            <Route
              path="*"
              render={(props) => (
                <Login {...props} getDataUser={this.getDataUser} />
              )}
            />
          </Switch>
        </Router>
      );
    }
  }
}

export default withCookies(App);
