import React, { Component } from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faListUl, faEdit } from "@fortawesome/free-solid-svg-icons";

import icon from './assets/icon.svg'

import classes from './Box.module.css'
export default class Box extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <div className={classes.contentBox}>
                <div className={classes.wrappContent}>
                    <div className={classes.box}>
                        <div className={classes.boxTop}>
                            <div className={classes.boxLeft}>
                                <div className={classes.topIcon}></div>
                                <span>1. Header</span>
                            </div>
                            <div className={classes.boxRight}>
                                <FontAwesomeIcon color="#00599D" className={classes.iconBox} icon={faCog} size="lg" />
                                <FontAwesomeIcon color="#00599D" className={classes.iconBox} icon={faListUl} size="lg" />
                                <FontAwesomeIcon color="#00599D" className={classes.iconBox} icon={faEdit} size="lg" />
                            </div>
                        </div>
                        <hr />
                        <div className={classes.boxBottom}>
                            <div className={classes.boxDefault}>
                                <img src={icon} alt="" />
                                <span>No preview available</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
