import React, { Component } from "react";
import { Table, Button, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";

import Card, { CardBody } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content";
import swal from "sweetalert";
import AlertHelper from "./../lib/AlertHelper";

import Pagination from "react-js-pagination";

import TitlePages from "./Elements/TitlePages";
// import UserAddModal from "../components/Modal/UserAddModal"

import UserApi from "./../api/UserApi";

import UserDeleteConfirm from "../components/Modal/UserDeleteConfirm";
import AuthorAddModal from "../components/Modal/AuthorAddModal";
import AuthorDeleteConfirm from "../components/Modal/AuthorDeleteConfirm";

import ApplicantList from "../pages/Job/ApplicantList";
import { withTranslation } from "react-i18next";
import config from "../config/config";
import ReactGA from "react-ga";

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: 0,
            authorId: 0,

            users: [],
            employees: [],
            applicants: [],
            authors: [],

            selectedEmployee: [],
            selectedApplicant: [],
            selectedAuthor: [],

            search: "",
            searchAuthor: "",

            showModal: false,
            showConfirm: false,

            showModalAuthor: false,
            showConfirmAuthor: false,

            tabkey: 1,

            limitPageData: 10,
            activePage: 1,
            totalCount: 0,

            limitPageAuthor: 10,
            activePageAuthor: 1,
            totalCountAuthor: 0,

            deleteDisable: true,
            transAlert: "alert.",
        };

        this.setUserId = this.setUserId.bind(this);
        this.getEmployee = this.getEmployee.bind(this);
        this.getAuthor = this.getAuthor.bind(this);

        this.deleteSelectedUser = this.deleteSelectedUser.bind(this);
        this.deleteSelectedAuthor = this.deleteSelectedAuthor.bind(this);

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);

        this.setShowModal = this.setShowModal.bind(this);
        this.setShowConfirm = this.setShowConfirm.bind(this);

        this.setShowModalAuthor = this.setShowModalAuthor.bind(this);
        this.setShowConfirmAuthor = this.setShowConfirmAuthor.bind(this);
        this.setSelectedAuthor = this.setSelectedAuthor.bind(this);
    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        this.getEmployee();
        this.getAuthor();
    }

    getEmployee() {
        const userObj = new UserApi();

        userObj
            .getAllEmployees({
                page_size: this.state.limitPageData,
                page: this.state.activePage,
                search: this.state.search,
            })
            .then((res) => {
                this.setState({
                    employees: res.data.results,
                    totalCount: res.data.count,
                });
            });
    }

    // ===== author =========

    getAuthor() {
        const userObj = new UserApi();

        userObj
            .getAllAuthor({
                page_size: this.state.limitPageAuthor,
                page: this.state.activePageAuthor,
                search: this.state.searchAuthor,
            })
            .then((res) => {
                this.setState({
                    authors: res.data.results,
                    totalCountAuthor: res.data.count,
                });
            });
    }

    getDetailAuthor() {
        const userObj = new UserApi();

        userObj.getDetailAuthor(this.state.authorId).then((res) => {
            this.setState({
                selectedAuthor: res.data,
            });
        });
    }

    getUserDetail() {
        const userObj = new UserApi();

        userObj.getDetailUser(this.state.userId).then((res) => {
            this.setState({
                selectedUser: res.data,
            });
        });
    }

    setUserId(value) {
        this.setState({
            userId: value,
        });
    }

    // ===== employee ======

    setShowModal() {
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    setShowConfirm() {
        this.setState({
            showConfirm: !this.state.showConfirm,
        });
    }

    // ========= Author ============

    setShowModalAuthor() {
        this.setState({
            showModalAuthor: !this.state.showModalAuthor,
        });
    }

    setShowConfirmAuthor() {
        this.setState({
            showConfirmAuthor: !this.state.showConfirmAuthor,
        });
    }

    // ===========================

    handleSearchChange(value) {
        this.setState(
            {
                search: value,
            },
            () => {
                this.getEmployee();
            }
        );
    }

    handlePageChange(pageNumber) {
        this.setState(
            {
                activePage: pageNumber,
            },
            () => {
                this.getEmployee();
            }
        );
    }

    deleteSelectedUser() {
        const { t } = this.props;
        const userObj = new UserApi();

        const user = this.state.selectedEmployee[0];

        // console.log(" selectedUser => ",this.state.selectedUser)

        userObj
            .deleteSelectedEmployee(user)
            .then((res) => {
                swal(
                    "Success !",
                    t(this.state.transAlert + "user_delete_success"),
                    "success"
                );
                this.setShowConfirm();
                this.getEmployee();
            })
            .catch((err) => {
                console.log(" err => ", err);
                // const objAlert = new AlertHelper()
                // objAlert.errorHandlerAlert(err.response.data)
            });
    }

    // author handle

    handleSearchAuthorChange(value) {
        this.setState(
            {
                searchAuthor: value,
            },
            () => {
                this.getAuthor();
            }
        );
    }

    handlePageAuthorChange(pageNumber) {
        this.setState(
            {
                activePageAuthor: pageNumber,
            },
            () => {
                this.getAuthor();
            }
        );
    }

    setSelectedAuthor(author, value) {
        // alert("sadfasdas")

        var lastSelected = [...this.state.selectedAuthor];
        var indexDelete = -1;

        if (value === true) {
            lastSelected.push(author);
            console.log(lastSelected);
        } else {
            //console.log("jalan donk harusnya ", article , this.state.selectedArticles)
            this.state.selectedAuthor.forEach((item, index) => {
                if (item.id === author.id) {
                    indexDelete = index;
                }
            });

            lastSelected.splice(indexDelete, 1);
            //console.log("lastArticles => ",lastArticles)
        }

        this.setState(
            {
                selectedAuthor: lastSelected,
            },
            () => {
                if (this.state.selectedAuthor.length > 0) {
                    this.setState({
                        deleteDisable: false,
                    });
                } else {
                    this.setState({
                        deleteDisable: true,
                    });
                }
            }
        );
    }

    deleteSelectedAuthor() {
        const { t } = this.props;
        const userObj = new UserApi();

        const author = this.state.selectedAuthor;

        // console.log(" selectedUser => ",author)

        userObj
            .deleteSelectedAuthor(author)
            .then((res) => {
                swal(
                    "Success !",
                    t(this.state.transAlert + "author_delete_success"),
                    "success"
                );
                this.setShowConfirmAuthor();
                this.getAuthor();
                this.setState({
                    selectedAuthor: [],
                });
            })
            .catch((err) => {
                console.log(" err => ", err);
            });
    }

    // ==========

    render() {
        const { t } = this.props;
        const UserList = this.state.employees.map((item) => {
            return (
                <tr key={item.id}>
                    <td> {item.id}</td>
                    <td> {item.user.username}</td>
                    <td> {item.user.first_name}</td>
                    <td> {item.user.last_name}</td>
                    <td> {item.user.email}</td>
                    <td>
                        <div style={{ float: "right" }}>
                            <Link
                                to={`/employee/detail/${item.id}`}
                                className="btn btn-primary btn-xs"
                            >
                                <i className="fa fa-edit"></i>
                            </Link>
                            &nbsp;
                            <Button
                                variant="danger"
                                className="btn btn-xs"
                                onClick={() => {
                                    this.setState(
                                        {
                                            selectedEmployee: [item],
                                        },
                                        () => this.setShowConfirm()
                                    );
                                }}
                            >
                                <i className="fa fa-trash"></i>
                            </Button>
                        </div>
                    </td>
                </tr>
            );
        });

        const AuthorList = this.state.authors.map((item) => {
            let fil = localStorage.getItem("lang") === "en" ? 1 : 2;
            const filter = [fil];
            const data = item.author_languages;
            let filtered = data.filter(function (item) {
                return filter.indexOf(item.language) !== -1;
            });

            let name = filtered ? (filtered[0] ? filtered[0].name : "") : "";
            return (
                <tr>
                    <td>
                        <input
                            type="checkbox"
                            onClick={(event) => {
                                this.setSelectedAuthor(
                                    item,
                                    event.target.checked
                                );
                            }}
                            id={item.id}
                        ></input>
                    </td>
                    <td>
                        <img
                            src={item.avatar}
                            width={150}
                            height={150}
                            alt={item.name}
                        />
                    </td>
                    <td>{name}</td>
                    <td>
                        <div style={{ float: "right" }}>
                            <Button
                                className="btn btn-primary btn-xs"
                                onClick={() => {
                                    this.setState(
                                        {
                                            authorId: item.id,
                                        },
                                        () => {
                                            this.setShowModalAuthor();
                                        }
                                    );
                                }}
                            >
                                <i className="fa fa-edit"></i>
                            </Button>
                            &nbsp;
                            <Button
                                variant="danger"
                                className="btn btn-xs"
                                onClick={() => {
                                    this.setState(
                                        {
                                            selectedAuthor: [item],
                                        },
                                        () => this.setShowConfirmAuthor()
                                    );
                                }}
                            >
                                <i className="fa fa-trash"></i>
                            </Button>
                        </div>
                    </td>
                </tr>
            );
        });

        return (
            <Content>
                <ContentHeader>
                    {/* <h1> Users </h1> */}
                    <TitlePages title={t("pages.user_page.title")} />
                </ContentHeader>

                <ContentBody>
                    <Card>
                        <CardBody>
                            <Tabs>
                                <Tab eventKey={1} title={t("employee")}>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-md-10"></div>
                                        <div className="col-md-2">
                                            <Link
                                                className="btn btn-primary"
                                                to={"/employee/add"}
                                                style={{ float: "right" }}
                                            >
                                                <i className="fa fa-plus">
                                                    &nbsp;
                                                </i>{" "}
                                                {t(
                                                    "pages.user_page.add_employee"
                                                )}
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div
                                            className={[
                                                "col-md-10",
                                                "p-0",
                                            ].join(" ")}
                                        >
                                            <div
                                                className={[
                                                    "form-group col-md-4",
                                                    "p-0",
                                                ].join(" ")}
                                            >
                                                <label> {t("search")} </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="search"
                                                    placeholder={t(
                                                        "pages.user_page.search_employee"
                                                    )}
                                                    value={this.state.search}
                                                    onChange={(event) =>
                                                        this.handleSearchChange(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="col-md-2"
                                            style={{ position: "relative" }}
                                        >
                                            <div
                                                style={{
                                                    float: "right",
                                                    position: "absolute",
                                                    bottom: 0,
                                                    right: 6,
                                                }}
                                            >
                                                <Pagination
                                                    activePage={
                                                        this.state.activePage
                                                    }
                                                    itemsCountPerPage={
                                                        this.state.limitPageData
                                                    }
                                                    totalItemsCount={
                                                        this.state.totalCount
                                                    }
                                                    pageRangeDisplayed={4}
                                                    onChange={
                                                        this.handlePageChange
                                                    }
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <Table striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th> ID </th>
                                                    <th> {t("username")} </th>
                                                    <th> {t("first_name")} </th>
                                                    <th> {t("last_name")} </th>
                                                    <th> {t("email")} </th>
                                                    <th> {t("action")} </th>
                                                </tr>
                                            </thead>
                                            <tbody>{UserList}</tbody>
                                        </Table>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="row">
                                        <div className="col-md-10"></div>
                                        <div className="col-md-2">
                                            <div style={{ float: "right" }}>
                                                <Pagination
                                                    activePage={
                                                        this.state.activePage
                                                    }
                                                    itemsCountPerPage={
                                                        this.state.limitPageData
                                                    }
                                                    totalItemsCount={
                                                        this.state.totalCount
                                                    }
                                                    pageRangeDisplayed={4}
                                                    onChange={
                                                        this.handlePageChange
                                                    }
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="clearfix"></div>
                                </Tab>
                                <Tab eventKey={2} title={t("applicant")}>
                                    <br></br>
                                    <ApplicantList
                                        activeApplicant={true}
                                    ></ApplicantList>
                                </Tab>
                                <Tab eventKey={3} title={t("author")}>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-md-7"></div>
                                        <div className="col-md-5 d-flex justify-content-end">
                                            <Button
                                                variant="danger"
                                                className="mr-2"
                                                disabled={
                                                    this.state.deleteDisable
                                                }
                                                onClick={() => {
                                                    this.setShowConfirmAuthor();
                                                }}
                                            >
                                                {t(
                                                    "pages.user_page.delete_selected_author"
                                                )}
                                            </Button>
                                            <Button
                                                variant="primary"
                                                onClick={() => {
                                                    this.setState(
                                                        {
                                                            authorId: 0,
                                                        },
                                                        () => {
                                                            this.setShowModalAuthor();
                                                        }
                                                    );
                                                }}
                                                style={{ float: "right" }}
                                            >
                                                <i className="fa fa-plus">
                                                    &nbsp;
                                                </i>{" "}
                                                {t(
                                                    "pages.user_page.add_author"
                                                )}
                                            </Button>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div
                                            className={[
                                                "col-md-10",
                                                "p-0",
                                            ].join(" ")}
                                        >
                                            <div
                                                className={[
                                                    "form-group col-md-4",
                                                    "p-0",
                                                ].join(" ")}
                                            >
                                                <label> {t("search")} </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="search"
                                                    placeholder={t(
                                                        "pages.user_page.search_author"
                                                    )}
                                                    value={
                                                        this.state.searchAuthor
                                                    }
                                                    onChange={(event) =>
                                                        this.handleSearchAuthorChange(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="col-md-2"
                                            style={{ position: "relative" }}
                                        >
                                            <div
                                                style={{
                                                    float: "right",
                                                    position: "absolute",
                                                    bottom: 0,
                                                    right: 6,
                                                }}
                                            >
                                                <Pagination
                                                    activePage={
                                                        this.state
                                                            .activePageAuthor
                                                    }
                                                    itemsCountPerPage={
                                                        this.state
                                                            .limitPageAuthor
                                                    }
                                                    totalItemsCount={
                                                        this.state
                                                            .totalCountAuthor
                                                    }
                                                    pageRangeDisplayed={4}
                                                    onChange={
                                                        this
                                                            .handlePageAuthorChange
                                                    }
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <Table striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th> {t("avatar")} </th>
                                                    <th> {t("author")} </th>
                                                    <th> {t("action")} </th>
                                                </tr>
                                            </thead>
                                            <tbody>{AuthorList}</tbody>
                                        </Table>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="row">
                                        <div className="col-md-10"></div>
                                        <div className="col-md-2">
                                            <div style={{ float: "right" }}>
                                                <Pagination
                                                    activePage={
                                                        this.state
                                                            .activePageAuthor
                                                    }
                                                    itemsCountPerPage={
                                                        this.state
                                                            .limitPageAuthor
                                                    }
                                                    totalItemsCount={
                                                        this.state
                                                            .totalCountAuthor
                                                    }
                                                    pageRangeDisplayed={4}
                                                    onChange={
                                                        this
                                                            .handlePageAuthorChange
                                                    }
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="clearfix"></div>
                                </Tab>
                            </Tabs>
                        </CardBody>
                    </Card>
                </ContentBody>
                <AuthorAddModal
                    show={this.state.showModalAuthor}
                    onHide={this.setShowModalAuthor}
                    authorId={this.state.authorId}
                    getAuthor={this.getAuthor}
                ></AuthorAddModal>
                <UserDeleteConfirm
                    show={this.state.showConfirm}
                    onHide={this.setShowConfirm}
                    getUser={this.getEmployee}
                    selectedUser={this.state.selectedEmployee}
                    deleteSelectedUser={this.deleteSelectedUser}
                ></UserDeleteConfirm>
                <AuthorDeleteConfirm
                    show={this.state.showConfirmAuthor}
                    onHide={this.setShowConfirmAuthor}
                    getAuthor={this.getAuthor}
                    selectedAuthor={this.state.selectedAuthor}
                    deleteSelectedAuthor={this.deleteSelectedAuthor}
                ></AuthorDeleteConfirm>
            </Content>
        );
    }
}

export default withTranslation()(User);
