import axios from "axios";
import config from "./../config/config";
export default class GeneralApi {
  // base_api = "http://192.168.13.177:7000/"
  site_api = config.base_api;
  token = config.token;

  getAllLocation() {
    return axios.get(this.site_api + `locations/`, {
      headers: {
        Authorization: "Token " + this.token,
        "Content-Type": "application/json",
      },
    });
  }

  getAllLocations(params) {
    return axios.get(this.site_api + `locations/`, {
      params: params,
      headers: {
        Authorization: "Token " + this.token,
        "Content-Type": "application/json",
      },
    });
  }

  addLocation(data) {
    return axios.post(this.site_api + `locations/`, data, {
      headers: {
        Authorization: "Token " + this.token,
        "Content-Type": "application/json",
      },
    });
  }

  updateLocation(data, locationId) {
    return axios.patch(this.site_api + `locations/${locationId}/`, data, {
      headers: {
        Authorization: "Token " + this.token,
        "Content-Type": "application/json",
      },
    });
  }

  deleteLocation(locationId) {
    return axios.delete(this.site_api + `locations/${locationId}/`, {
      headers: {
        Authorization: "Token " + this.token,
        "Content-Type": "application/json",
      },
    });
  }

  getDetailLocation(locationId) {
    return axios.get(this.site_api + `locations/${locationId}/`, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }

}
