import React, { Component } from "react";
// import { Link } from "react-router-dom"
import { Modal, Card, Button } from "react-bootstrap";
import swal from "sweetalert";
import AlertHelper from "./../../lib/AlertHelper";
import Pagination from "react-js-pagination";

// import MyCard,{ CardHeader, CardTitle, CardBody, CardFooter  } from "./../Card";

import AssetApi from "../../api/AssetApi";
import WidgetApi from "../../api/WidgetApi";

import { shortSentence } from "../../lib/StringHelper";
import docImage from "../Assets/Docx.svg";
import ekselImage from "../Assets/Xlsx.svg";
import pdfImage from "../Assets/Pdf.svg";
import defaultImage from "../Assets/default-image.svg";
import videoImage from "../Assets/Video.svg";
import svgImage from "../Assets/Svg.svg";
// import ArticleApi from '../../api/ArticleApi';

class WidgetAssetAddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articleId: 0,
      assets: [],
      selectedAssets: [],
      asset_types: [],
      pairDisable: true,
      loadingModal: "",
      search: "",

      asset_type: 0,
      inputType: "FILE",
      activePage: 1,
      limitPageData: 30,
      totalCount: 0,
      co_md: "",
    };
    this.getAllAssets = this.getAllAssets.bind(this);
    this.handleSelectedAsset = this.handleSelectedAsset.bind(this);
    this.onShowModal = this.onShowModal.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    // this.addAssetArticle = this.addAssetArticle.bind(this)
    this.getAssetTypeLanguage = this.getAssetTypeLanguage.bind(this);
    this.handleChangeFileType = this.handleChangeFileType.bind(this);
    this.getAllAssetsAfterFilter = this.getAllAssetsAfterFilter.bind(this);
  }

  onShowModal() {
    // this.getAllAssets();
    const w = window.screen.width;

    if (w <= 1440) {
      this.setState(
        {
          limitPageData: 20,
          co_md: "col-md-3",
        },
        () => {
          this.getAllAssets();
        }
      );
    } else if (w > 1441) {
      this.setState(
        {
          limitPageData: 30,
          co_md: "col-md-2",
        },
        () => {
          this.getAllAssets();
        }
      );
    }
    this.setState({
      articleId: this.props.articleId,
    });
    this.getAssetTypeLanguage();
    //alert("props => "+this.props.articleId)
  }

  getAllAssets() {
    const objAsset = new AssetApi();

    return objAsset
      .getAllAssets({
        page_size: this.state.limitPageData,
        page: this.state.activePage,
        search: this.state.search,
        order: '-id'
      })
      .then((res) => {
        //console.log(res.data.results)

        this.setState({
          totalCount: res.data.count,
          assets: res.data.results,
        });
      });
  }

  getAssetTypeLanguage() {
    const obj = new AssetApi();
    const lang = localStorage.getItem('lang')

    obj.assetTypeLanguage({
      language: lang ? lang : 'en'
    }).then((res) => {
      this.setState({
        asset_types: res.data.results,
      });
    });
  }

  handlePageChange(pageNumber) {
    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.getAllAssets();
      }
    );
  }

  handleSelectedAsset(asset, value) {
    var lastSelected = [...this.state.selectedAssets];
    var indexDelete = -1;
    // var stateSelected = ""

    if (value === true) {
      lastSelected.push(asset);
    } else {
      //console.log("jalan donk harusnya ", article , this.state.selectedArticles)
      this.state.selectedAssets.forEach((item, index) => {
        if (item.id === asset.id) {
          indexDelete = index;
        }
      });

      lastSelected.splice(indexDelete, 1);
      //console.log("lastArticles => ",lastArticles)
    }

    this.setState(
      {
        selectedAssets: lastSelected,
      },
      () => {
        if (this.state.selectedAssets.length > 0) {
          this.setState({
            pairDisable: false,
          });
        } else {
          this.setState({
            pairDisable: true,
          });
        }
      }
    );
  }

  addAssetWidget() {
    const objWidget = new WidgetApi();

    this.setState({
      loadingModal: (
        <div className="overlay d-flex justify-content-center align-items-center">
          <i className="fas fa-2x fa-sync fa-spin"></i>
        </div>
      ),
    });

    const assets = this.state.selectedAssets.map((item) => {
      return item.id;
    });

    objWidget
      .widgetAssetPair(this.props.widgetId, assets)
      .then((res) => {
        //this.props.setSelectedAssetAdd(this.state.selectedAssets)
        this.props.getWidgetDetail(this.props.widgetId);
        this.setState({
          loadingModal: "",
        });
        this.props.onHide();
        swal(
          "Success !",
          "You sucessfully added asset into this widget !",
          "success"
        );
      })
      .catch((err) => {
        const objAlert = new AlertHelper();
        const resErr = err?.response?.data ? err?.response?.data : err.message;
        //console.log(" Error => ",err?.response?.data)
        objAlert.errorHandlerAlert(resErr);
      });
  }

  handleSearchChange(value) {
    this.setState(
      {
        activePage: 1,
        search: value,
      },
      () => {
        this.getAllAssets();
      }
    );
  }

  getAllAssetsAfterFilter(input) {
    const objAsset = new AssetApi();
    return objAsset
      .getAllAssetsAfterFilter({
        page_size: 30,
        search: this.state.search,
        type: input,
      })
      .then((res) => {
        this.setState({
          assets: res.data.results,
          totalCount: res.data.count,
        });
      });
  }

  handleChangeFileType(value) {
    var inputType = "FILE";
    // if (parseInt(value) === 1) {
    //   inputType = "Image";
    // } else if (parseInt(value) === 2) {
    //   inputType = "Others";
    // } else {
    //   inputType = "External Link";
    // }
    this.setState(
      {
        asset_type: value,
        inputType: inputType,
        activePage: 1,
        search: "",
      },
      () => {
        this.getAllAssetsAfterFilter(value);
      }
    );
  }

  // addAssetArticle() {
  //     const objArticle = new ArticleApi()

  //     this.setState({
  //         loadingModal:<div className="overlay d-flex justify-content-center align-items-center">
  //             <i className="fas fa-2x fa-sync fa-spin"></i>
  //         </div>
  //     })

  //     if(this.props.type === "add") {

  //         this.props.setSelectedAssetAdd(this.state.selectedAssets)
  //         // this.props.getDetailArticle()
  //         this.setState({
  //             loadingModal:""
  //         })
  //         this.props.onHide()
  //         swal("Success !", "You sucessfully added asset into this article !", "success");

  //     } else if(this.props.type === "update") {

  //         const ids = this.state.selectedAssets.map(item => {
  //             return(item.id)
  //         })

  //         const dt = {
  //             articleId:this.state.articleId,
  //             ids:ids
  //         }

  //         // console.log(" dt => ",dt)

  //         objArticle.articleAssetPair(dt.articleId, dt.ids)
  //         .then(res => {
  //             this.props.getDetailArticle()
  //             this.setState({
  //                 loadingModal:""
  //             })
  //             this.props.onHide()
  //             swal("Success !", "You sucessfully added asset into this article !", "success");
  //         })
  //         .catch(err => {
  //             console.log(err.message)
  //         })
  //     }

  // }

  render() {
    var media, url;
    const AssetList =
      this.state.assets.length > 0 ? (
        this.state.assets.map((item, index) => {
          if (item.asset_file) {
            url = item.asset_file;
          } else {
            url = "";
          }

          //   let url = item.asset_file;
          let typeDoc = [".docx", ".doc"];
          let typePdf = ".pdf";
          let typeXlx = [".xls", ".xlxs", ".xlxm", ".xlxb", ".xltx", ".xltm"];
          //   let typeVideo = [
          //     ".mp3",
          //     ".mp4",
          //     ".webm",
          //     ".mkv",
          //     ".m4p",
          //     ".3gp",
          //     ".flv",
          //     ".f4v",
          //     ".f4p",
          //     ".f4a",
          //     ".mpeg",
          //   ];
          let typeSvg = ".svg";
          //   let myArr = url.split();
          let resultDoc = url.split().some((i) => typeDoc.includes(i));
          let resultPdf = url.includes(typePdf);
          //   let resultVideo = myArr.some((i) => typeVideo.includes(i));
          let resultEksel = url.split().some((i) => typeXlx.includes(i));
          let resultSvg = url.includes(typeSvg);

          if (item.asset_type_name === "Image") {
            media = (
              <Card.Img
                title={`asset-media-image-${index}`}
                style={{
                  height: "126px",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "6px",
                }}
                src={item.file_small ? item.file_small : item.asset_file}
              ></Card.Img>
            );
          } else if (item.asset_type_name === "Others") {
            media = (
              <>
                {resultPdf ? (
                  <img
                    style={{
                      height: "126px",
                      width: "100%",
                      objectFit: "contain",
                      borderRadius: "6px",
                    }}
                    src={item.thumbnail_file ? item.thumbnail_file : pdfImage}
                    alt=""
                  />
                ) : resultDoc ? (
                  <img
                    style={{
                      height: "126px",
                      width: "100%",
                      objectFit: "contain",
                      borderRadius: "6px",
                    }}
                    src={item.thumbnail_file ? item.thumbnail_file : docImage}
                    alt=""
                  />
                ) : resultEksel ? (
                  <img
                    style={{
                      height: "126px",
                      width: "100%",
                      objectFit: "contain",
                      borderRadius: "6px",
                    }}
                    src={item.thumbnail_file ? item.thumbnail_file : ekselImage}
                    alt=""
                  />
                ) : resultSvg ? (
                  <img
                    style={{
                      height: "126px",
                      width: "100%",
                      objectFit: "contain",
                      borderRadius: "6px",
                    }}
                    src={item.thumbnail_file ? item.thumbnail_file : svgImage}
                    alt=""
                  />
                ) : (
                  <img
                    style={{
                      height: "126px",
                      width: "100%",
                      objectFit: "contain",
                      borderRadius: "6px",
                    }}
                    src={
                      item.thumbnail_file ? item.thumbnail_file : defaultImage
                    }
                    alt=""
                  />
                )}
              </>
            );
          } else {
            if (item.asset_link) {
              media = (
                <>
                  {item.thumbnail_file ? (
                    <Card.Img
                      title={`media-${item.asset_type_name}-${index}`}
                      style={{
                        height: "126px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "6px",
                      }}
                      src={item.thumbnail_file}
                    ></Card.Img>
                  ) : (
                    <img
                      style={{
                        height: "126px",
                        width: "100%",
                        objectFit: "contain",
                        borderRadius: "6px",
                      }}
                      src={videoImage}
                      alt=""
                    />
                  )}
                </>
              );
            }
          }

          return (
            <div key={item.id} className={this.state.co_md}>
              <Card>
                <Card.Header className="" style={{ padding: "1rem 0.5rem 0" }}>
                  <div
                    className="form-check form-check-inline"
                    style={{
                      position: "relative",
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="radio"
                      className="form-check-input"
                      id={"label-modal-" + item.id}
                      onClick={(event) =>
                        this.handleSelectedAsset(item, event.target.checked)
                      }
                    ></input>
                    <label
                      className="form-check-label"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }}
                      htmlFor={"label-modal-" + item.id}
                    >
                      {item.name}
                    </label>
                  </div>
                </Card.Header>

                <Card.Body style={{ padding: "1rem .5rem" }}>
                  {media}
                  {/* <div className="container-fluid" align="center">
                    <Card.Img
                      src={
                        item.thumbnail_file
                          ? item.thumbnail_file
                          : item.file_small
                      }
                      style={{ height: "100px" }}
                    ></Card.Img>
                  </div> */}
                </Card.Body>

                <Card.Footer
                  style={{
                    fontSize: 16,
                    margin: "auto auto 1rem .5rem",
                    padding: "0",
                    textAlign: "left",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <div align="center" style={{ fontSize: 12 }}>
                    {shortSentence(item.description, 6)} ...
                  </div>
                </Card.Footer>
              </Card>
            </div>
          );
        })
      ) : (
        <div className="col-md-12 center-block">
          <h4 className="text-center">There is no Assets</h4>
        </div>
      );

    return (
      <Modal
        show={this.props.show}
        onHide={() => this.props.onHide()}
        size="xl"
        onShow={() => this.onShowModal()}
      >
        {this.state.loadingModal}
        <Modal.Header closeButton className="bg-primary">
          Select Widget Asset's
        </Modal.Header>

        <Modal.Body>
          {/* <div style={{ float: "left" }}>
                        <div className="form-group">

                            <input type="text" placeholder="search..." className="form-control" name="search" value={this.state.search} onChange={(event) => this.handleSearchChange(event.target.value)} />
                        </div>
                    </div>
                    <div style={{ float: "right" }}>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.limitPageData}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={4}
                            onChange={this.handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"

                        />
                    </div> */}
          <div
            className="d-flex flex-row"
            width="100%"
            style={{ padding: "0 2rem" }}
          >
            <div
              className="form-group"
              style={{ width: "30%", marginRight: "1rem" }}
            >
              <label>File Type</label>
              <select
                className="form-control"
                defaultValue={this.state.inputType}
                onChange={(event) => {
                  this.handleChangeFileType(event.target.value);
                }}
              >
                <option value=""> -- Select File Type -- </option>
                {this.state.asset_types.map((item) => {
                  return <option value={item.name}> {item.name} </option>;
                })}
              </select>
            </div>

            <div className="form-group" style={{ width: "30%" }}>
              <label>Search</label>
              <input
                type="text"
                placeholder="Search..."
                className="form-control"
                name="search"
                value={this.state.search}
                onChange={(event) =>
                  this.handleSearchChange(event.target.value)
                }
              />
            </div>
          </div>
          <div style={{ padding: "0 2rem" }}>
            {" "}
            <hr />
          </div>
          <div className="clearfix"></div>
          <div className="row" style={{ padding: "0 2rem" }}>
            {AssetList}
          </div>
          <div
            id="pagination_custom"
            style={{ float: "left", padding: "0 2rem", margin: "2rem 0" }}
          >
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.limitPageData}
              totalItemsCount={this.state.totalCount}
              pageRangeDisplayed={4}
              onChange={this.handlePageChange.bind(this)}
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled={true}
              prevPageText="Prev"
              nextPageText="Next"
            />
          </div>
          <div className="clearfix"></div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={this.state.pairDisable}
            onClick={() => this.addAssetWidget()}
          >
            {" "}
            <i className="fa fa-paperclip">&nbsp;</i> Pair These Assets{" "}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default WidgetAssetAddModal;
