import axios from "axios";
import config from "./../config/config";
class LanguageApi {
  base_api = config.base_api;
  token = config.token;

  getAllLanguage(params) {
    return axios.get(this.base_api + "languages/", {
      params: params,
      headers: {
        Authorization: "Token " + this.token,
        "Content-Type": "application/json",
      },
    });
  }

  getDetailLanguage(langId) {
    return axios.get(this.base_api + `languages/${langId}/`, {
      headers: {
        Authorization: "Token " + this.token,
        "Content-Type": "application/json",
      },
    });
  }

  languageAdd(menu) {
    return axios.post(
      this.base_api + `languages/`,
      {
        name: menu.name,
      },
      {
        headers: {
          Authorization: "Token " + this.token,
          "Content-Type": "application/json",
        },
      }
    );
  }

  languageUpdate(id, lang) {
    return axios.patch(
      this.base_api + `languages/${id}/`,
      {
        name: lang.name,
        abbreviation:lang.abbreviation,
        flag:lang.flag,
        json:lang.json

      },
      {
        headers: {
          Authorization: "Token " + this.token,
          "Content-Type": "application/json",
        },
      }
    );
  }

  getJsonLanguages(params) { 
    return axios.get(this.base_api,{
        params:params,
        headers: {
          Authorization: "Token " + this.token,
          "Content-Type": "application/json",
        },
    }) 

  }

  getJsonLanguageItem(params) { 
     return axios.get(this.base_api,{
        params:params,
        headers: {
          Authorization: "Token " + this.token,
          "Content-Type": "application/json",
        },
    }) 
  }

  addJsonLanguageItem(data) { 
    return axios.post(this.base_api,data,{
      headers: {
          Authorization: "Token " + this.token,
          "Content-Type": "application/json",
        },
    })
  }

  updateJsonLanguageItem(item, data) { 
    return axios.patch(this.base_api+'/'+item+"/", data, { 

      headers: {
          Authorization: "Token " + this.token,
          "Content-Type": "application/json",
      },
    })
  }
}

export default LanguageApi;
