import React, { Component } from "react";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
// import swal from "sweetalert";
// import AlertHelper from "./../../lib/AlertHelper";

import { withTranslation } from "react-i18next";
import LanguageApi from "../../api/LanguageApi";

import Context from "../../pages/Context"

class LanguageItemAddModal extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      keyName:"",
      transPage: "modals.language.",
      transAlert: "alert.",
      languages:[],
      activeKey:"en"
      
    };

    this.onOpenModal = this.onOpenModal.bind(this);
    this.getLanguages = this.getLanguages.bind(this)

    this.handleChangeKeyName = this.handleChangeKeyName.bind(this)
    this.handleChangeTab     = this.handleChangeTab.bind(this)

    // this.renderTabLanguage = this.renderTabLanguage.bind(this)

  }

  componentDidMount() {
     this.setState(
      {
        id: this.props.id,
        activeKey:"en"
      },
      () => {
        this.getLanguages()
      }
    );
  }

  onOpenModal() {
    this.setState(
      {
        id: this.props.id,
        activeKey:"en"
      },
      () => {
        this.getLanguages()
      }
    );
  }

  getLanguages() {
    const { languagesList } = this.context

    this.setState({
      languages: languagesList
    })
  }

  handleChangeKeyName(value) {
    this.setState({
      keyName:value
    })
  }

  handleChangeTab(value) {
    this.setState({
      activeKey:value
    })
  }

  // renderTabLanguage() {
  //   const renderTabLanguage = this.state.languages.map((item, index) => {
  //     var findName = this.state.names.find((textLang, idx) => item.id === textLang.language)

  //     //console.log("item " + index)

  //     if (this.state.update) {
  //       //console.log("first", this.state.names[index])
  //       return {
  //         id: findName?.id,
  //         language: item.id,
  //         name: findName ? findName.name : "",
  //         languageName: item.name,
  //         page: this.state.selectedPage,
  //         section: this.state.selectedSection,
  //         path: this.state.names[index].path,
  //       }
  //     } else {
  //       //console.log("after", this.state.names[index])
  //       return {
  //         //   id: null,
  //         language: item.id,
  //         name: findName ? findName.name : "",
  //         languageName: item.name,
  //         page: this.state.selectedPage,
  //         section: this.state.selectedSection,
  //         path: null,
  //       }
  //     }
  //   })
  // }

  render() {
    const { t } = this.props;
    //var HeaderTitle = t(this.state.transPage + "title_add");
    var KeyName = <div className="form-group">
      <label> Language Item </label>
      <input type="text" name="keyName" value={this.state.keyName} className="form-control" ></input>
    </div>

    var HeaderTitle = t("Add Item ")
    var BtnSubmit = (
      <Button variant="primary" onClick={() => {}}>
        <i className="fa fa-plus">&nbsp;</i>
        {t("Add Language Item")}
      </Button>
    );

    if (this.props.methodModal === "Update") {
      KeyName = ""
      //HeaderTitle = t(this.state.transPage + "title_update");
      HeaderTitle = t("Update ")
      BtnSubmit = (
        <Button variant="primary" onClick={() => {}}>
          <i className="fa fa-edit">&nbsp;</i>
          {t("Update Language Item")}
        </Button>
      );
    }

    const TabLanguage = this.state.languages.map((item, index) => {
      // console.log("item" ,item )

      return (
        <Tab key={index} eventKey={item.abbreviation} title={item.name}>
          <br></br>

          <div className='form-group'>
            <label> {t(' Translation ')} </label>
            <textarea 
              className='form-control' 
              onChange={(event) => this.handleContentChange(index, event.target.value)} 
              // value={this.state.names[index].name}
            ></textarea>
            {/* { JSON.stringify( this.state.text_languages )  } */}
          </div>
        </Tab>
      )
    })

    return (
      <Modal
        size="lg"
        show={this.props.show}
        onHide={() => this.props.onHide()}
        onShow={() => this.onOpenModal()}
      >
        <Modal.Header closeButton className="bg-primary" >
          <Modal.Title> {HeaderTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2 style={{marginBottom:10}}>{"key : "+this.props.selectedKey}</h2>
          <hr></hr>
          { KeyName }
          {/* { JSON.stringify(this.state.languages)} */}
          <Tabs id='controlled-tab-example-3' activeKey={this.state.tabKey} onSelect={(k) => this.handleChangeTab(k)}>
            {TabLanguage}
          </Tabs>
        
        </Modal.Body>
        <Modal.Footer>{BtnSubmit}</Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(LanguageItemAddModal);
