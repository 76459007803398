import axios from "axios";
import config from "./../config/config";
class GANumberApi {
  base_api = config.base_api;
  token = config.token;

  getGANumber(params) {
    return axios.get(this.base_api + `templates/${params}/`, {
      headers: {
        Authorization: "Token " + this.token,
        "Content-Type": "application/json",
      },
    });
  }

  updateGANumber(Id, data) {
    return axios.patch(this.base_api + `templates/${Id}/`, data, {
      headers: {
        Authorization: `Token ${this.token}`,
        "Content-Type": "application/json",
      },
    });
  }
}

export default GANumberApi;
