import axios from "axios"
import config from "./../config/config"
class CompanyApi {

    base_api = config.base_api
    token = config.token

    getEmailSettings(params) {
        return axios.get(this.base_api+`template_emails/?template=${params}`,{
            params:params,
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    getAllTemplateEmail(customParams) {
        return axios.get(this.base_api + `email_contents/`, {
            params: customParams,
            headers: {
                'Authorization': `Token ${this.token}`,
                "Content-Type": "application/json"
            }
        })
    }

    getTemplateEmailById(id, customParams) {
        return axios.get(this.base_api + `email_contents/${id}/`, {
            params: customParams,
            headers: {
                'Authorization': `Token ${this.token}`,
                "Content-Type": "application/json"
            }
        })
    }

    updateEmailSettings(Id, data) {
        return axios.patch(this.base_api + `template_emails/${Id}/`, data, {
            headers: {
                'Authorization': `Token ${this.token}`,
                "Content-Type": "application/json"
            }
        })
    }

    
    updateEmailRecipients(Id, data) {
        return axios.patch(this.base_api + `email_recipients/${Id}/`, data, {
            headers: {
                'Authorization': `Token ${this.token}`,
                "Content-Type": "application/json"
            }
        })
    }

    updateTemplateEmail(Id, data) {
        return axios.patch(this.base_api + `email_contents/${Id}/`, data, {
            headers: {
                'Authorization': `Token ${this.token}`,
                "Content-Type": "application/json"
            }
        })
    }

    postTemplateEmail(data) {
        return axios.post(this.base_api + `email_contents/`, data, {
            headers: {
                'Authorization': `Token ${this.token}`,
                "Content-Type": "application/json"
            }
        })
    }
}

export default CompanyApi