import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"

import MenuApi from "../../api/MenuApi"
import { withTranslation } from 'react-i18next';

class MenuItemConfirm extends Component {

    constructor(props) {
        super(props)

        this.state = { 
            menuItemId:0,
            itemName:"",
            transPage: "modals.menu_item_delete."
        }

        this.deleteMenuItem = this.deleteMenuItem.bind(this)
        this.onOpenModal = this.onOpenModal.bind(this)
        this.getDetailMenuItem = this.getDetailMenuItem.bind(this)
        // this.onHideModal = this.onHideModal.bind(this)
    }

    deleteMenuItem(){
        // dari props method nya
        this.props.deleteMenuItem() 
    }

    onOpenModal(){

        const _this = this
        
        console.log(" ==> ", this.props.id )

        this.setState({
            menuItemId:this.props.id
        },() => {


            console.log(" menuItemId ==> ", this.state.menuItemId )

            _this.getDetailMenuItem()
           
        })

       
    }

    getDetailMenuItem() {
        const menuObj = new MenuApi()

        const menuItemId = this.state.menuItemId
        // console.log("getDetailmenuItem ==> ")
        menuObj.getDetailMenuItem(menuItemId)
        .then(res => {

            console.log(" res data => ",res.data )


            // const newFormat = res.data.results.map(item => {
            //     return 
            // })

            this.setState({
              
                itemName:res.data.item_languages[1].name
            })
        })
        .catch(err => {
            console.log(err)
        })
    }

    render() {

        const {t} = this.props;

        return (
            <Modal show={this.props.show} onHide={() => this.props.onHide()} onShow={() => this.onOpenModal()}>
                <Modal.Header closeButton className="bg-danger">
                    <Modal.Title>{t(this.state.transPage + "title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t(this.state.transPage + "confirmation_text")}</p>
                    <ul>
                        <li> {this.state.itemName}</li>
                    </ul>
                  
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.props.onHide()}>
                        {t('cancel')}
                    </Button>
                    <Button variant="danger" onClick={() => { this.deleteMenuItem()}}>
                        <i className="fa fa-trash">&nbsp;</i>
                        {t(this.state.transPage + "delete_menu_item")}
                    </Button>   
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(MenuItemConfirm);