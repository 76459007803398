import React, { Component } from 'react';
// import { Link } from "react-router-dom"
import { Modal, Card, Button } from "react-bootstrap"
import swal from 'sweetalert'
import AlertHelper from "./../../lib/AlertHelper"
import Pagination from "react-js-pagination";

// import MyCard,{ CardHeader, CardTitle, CardBody, CardFooter  } from "./../Card";

import AssetApi from "../../api/AssetApi"
import WidgetApi from "../../api/WidgetApi"

import { shortSentence } from "../../lib/StringHelper"
// import ArticleApi from '../../api/ArticleApi';

class WidgetAssetAddModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            articleId: 0,
            assets: [],
            selectedAssets: [],
            activeAsset: null,
            pairDisable: true,
            loadingModal: "",
            search: "",

            activePage: 1,
            limitPageData: 30,
            totalCount: 0
        }
        this.getAllAssets = this.getAllAssets.bind(this)
        this.handleSelectedAsset = this.handleSelectedAsset.bind(this)
        this.onShowModal = this.onShowModal.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
    }

    onShowModal() {
        this.getAllAssets()
        this.setState({
            articleId: this.props.articleId,
            search: ""
        })
    }

    getAllAssets() {
        const objAsset = new AssetApi()

        return objAsset.getAllAssets({
            type: 'Image',
            page_size: this.state.limitPageData,
            page: this.state.activePage,
            search: this.state.search,
            order: '-id'
        })
            .then(res => {
                this.setState({
                    totalCount: res.data.count,
                    assets: res.data.results,
                })
            })
    }

    handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,

        }, () => {
            this.getAllAssets()
        });
    }

    handleSelectedAsset(asset, value, id) {
        this.setState((prevState) => {
            return {
                selectedAssets: prevState.activeAsset !== id ? asset : false,
                activeAsset: prevState.activeAsset !== id ? id : false
            }
        }, () => {
            this.props.selectedAsset(this.props.thumbnailType, this.state.selectedAssets.asset_file, value)
            if (this.state.selectedAssets) {
                this.setState({
                    pairDisable: false,
                })
            } else {
                this.setState({
                    pairDisable: true,
                })
            }
        })
    }

    addAssetWidget() {
        const objWidget = new WidgetApi()

        this.setState({
            loadingModal: <div className="overlay d-flex justify-content-center align-items-center">
                <i className="fas fa-2x fa-sync fa-spin"></i>
            </div>
        })

        const assets = this.state.selectedAssets.map(item => {
            return (item.id)
        })

        objWidget.widgetAssetPair(this.props.widgetId, assets)
            .then(res => {
                //this.props.setSelectedAssetAdd(this.state.selectedAssets)
                this.props.getWidgetDetail(this.props.widgetId)
                this.setState({
                    loadingModal: ""
                })
                this.props.onHide()
                swal("Success !", "You sucessfully added asset into this widget !", "success");
            })
            .catch(err => {
                const objAlert = new AlertHelper()
                const resErr = err?.response?.data ? err?.response?.data : err.message
                objAlert.errorHandlerAlert(resErr)
            })
    }

    handleSearchChange(value) {
        this.setState({
            activePage: 1,
            search: value
        }, () => {
            this.getAllAssets()
        })
    }

    render() {

        const AssetList = this.state.assets.length > 0 ? this.state.assets.map((item, i) => {
            return (
                <div key={item.id} className="col-md-2">
                    <Card>
                        <Card.Header className="" style={{ padding: "1rem 0.5rem 0" }}>
                            <div className="form-check form-check-inline" style={{
                                position: "relative",
                                width: "100%",
                                overflow: "hidden"
                            }}>
                                <input type="radio" name="myCheckbox" checked={this.state.activeAsset === ("label-modal-" + item.id) ? true : false} style={{ position: "relative", margin: "auto 0.5rem auto 0" }} className="form-check-input" id={"label-modal-" + item.id} onClick={(event) => this.handleSelectedAsset(item, item.id, event.target.id)} ></input>
                                <label className="form-check-label" style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "100%",
                                }} htmlFor={"label-modal-" + item.id}>{item.name}</label>
                            </div>

                        </Card.Header>

                        <Card.Body style={{ padding: "1rem .5rem" }}>
                            <div className="container-fluid" align="center">
                                <Card.Img src={item.asset_file} style={{ height: "100px" }}></Card.Img>
                            </div>
                        </Card.Body>

                        <Card.Footer style={{
                            fontSize: 16,
                            margin: "auto auto 1rem .5rem",
                            padding: "0",
                            textAlign: "left",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100%",
                            backgroundColor: "white"
                        }}>
                            <div align="center" style={{ fontSize: 12 }}>{shortSentence(item.description, 6)} ...</div>
                        </Card.Footer>
                    </Card>
                </div>
            )
        }) : <div className="col-md-12 center-block"><h4 className="text-center" >There is no Assets</h4></div>

        return (
            <Modal show={this.props.show} onHide={() => this.props.onHide()} size="xl" onShow={() => this.onShowModal()}>
                {this.state.loadingModal}
                <Modal.Header closeButton className="bg-primary">
                    Select Widget Asset's
                </Modal.Header>

                <Modal.Body>
                    {/* <div style={{ float: "left" }}>
                        <div className="form-group">
                            <input type="text" placeholder="search..." className="form-control" name="search" value={this.state.search} onChange={(event) => this.handleSearchChange(event.target.value)} />
                        </div>
                    </div> */}
                    {/* <div style={{ float: "right" }}>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.limitPageData}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={4}
                            onChange={this.handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div> */}
                    <div className="d-flex flex-row" width="100%" style={{ padding: "0 2rem" }}>
                        <div className="form-group" style={{ width: "30%" }}>
                            <label>Search</label>
                            <input
                                type="text"
                                placeholder="Search..."
                                className="form-control"
                                name="search"
                                value={this.state.search}
                                onChange={(event) =>
                                    this.handleSearchChange(event.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div style={{ padding: "0 2rem" }}>
                        {" "}
                        <hr />
                    </div>
                    <div className="clearfix"></div>
                    <div className="row" style={{ padding: "0 2rem" }}>
                        {AssetList}
                    </div>
                    <div id="pagination_custom" style={{ float: "left", padding: "0 2rem", margin: "2rem 0" }}>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.limitPageData}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={4}
                            onChange={this.handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                            prevPageText="Prev"
                            nextPageText="Next"
                        />
                    </div>
                    <div className="clearfix"></div>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={this.state.pairDisable} onClick={this.props.type !== "update asset" ? this.props.onHide : this.props.updatePairAsset}> <i className="fa fa-paperclip">&nbsp;</i> Pair These Assets </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default WidgetAssetAddModal;