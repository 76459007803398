import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"
import swal from "sweetalert"
import AlertHelper from "../../lib/AlertHelper"

import MenuApi from "./../../api/MenuApi"
import { withTranslation } from 'react-i18next';

class MenuAddModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            id: 0,
            name: "",
            menu: {},
            transPage: "modals.menu_add.",
            transAlert: "alert."
        }

        this.handleNameChange = this.handleNameChange.bind(this)

        this.addMenu = this.addMenu.bind(this)
        this.updateMenu = this.updateMenu.bind(this)

        this.onShowModal = this.onShowModal.bind(this)
        this.onHideModal = this.onHideModal.bind(this)
    }

    handleNameChange(value) {
        this.setState({
            name: value
        })
    }

    getDetailMenu() {

        const menuObj = new MenuApi()

        menuObj.getDetailMenu(this.state.id)
            .then(res => {
                this.setState({
                    menu: res.data,
                    name: res.data.name
                })
            })
            .catch(err => {
                const objAlert = new AlertHelper()
                const resErr = err?.response?.data ? err?.response?.data : err.message
                objAlert.errorHandlerAlert(resErr)
            })

    }

    addMenu() {
        const {t} = this.props;
        const menuObj = new MenuApi()

        const newMenu = {
            name: this.state.name,
            template: parseInt(localStorage.getItem('templateId'))
        }

        menuObj.menuAdd(newMenu)
            .then(res => {

                //console.log("success => ", res.data)
                swal("Success !", t(this.state.transAlert + "menu_add_success"), "success")
                this.props.getAllMenu()
                this.props.onHide()
                //this.props.history.push('/asset')
            })
            .catch(err => {
                const objAlert = new AlertHelper()
                const resErr = err?.response?.data ? err?.response?.data : err.message
                objAlert.errorHandlerAlert(resErr)
            })
    }

    updateMenu() {
        const {t} = this.props;
        const menuObj = new MenuApi()

        const newMenu = {
            name: this.state.name,
        }

        menuObj.menuUpdate(this.state.id, newMenu)
            .then(res => {

                //console.log("success => ", res.data)
                swal("Success !", t(this.state.transAlert + "menu_update_success"), "success")
                this.props.getAllMenu()
                this.props.onHide()
                //this.props.history.push('/asset')
            })
            .catch(err => {
                const objAlert = new AlertHelper()
                const resErr = err?.response?.data ? err?.response?.data : err.message
                objAlert.errorHandlerAlert(resErr)

            })
    }

    onShowModal() {
        if (this.props.id) {
            this.setState({
                id: this.props.id
            }, () => {
                this.getDetailMenu(this.state.id)
            })
        }
    }

    onHideModal() {
        this.setState({
            id: 0,
            menu: {},
            name: ""
        }, () => {
            this.props.onHide()
        })
    }

    render() {
        const { t } = this.props;
        var HeaderText = t(this.state.transPage + 'add_menu')
        var BtnSubmit = <Button variant="primary" onClick={() => this.addMenu()}>
            <i className="fa fa-plus"> &nbsp; </i>
            {t('add')}
        </Button>



        if (this.state.id) {
            HeaderText = t(this.state.transPage + 'update_menu')
            BtnSubmit = <Button variant="primary" onClick={() => this.updateMenu()}>
                <i className="fa fa-edit"> &nbsp; </i>
                {t('update')}
            </Button>
        }

        return (
            <Modal show={this.props.show} onHide={() => this.onHideModal()} onShow={() => this.onShowModal()}>
                <Modal.Header closeButton className="bg-primary">
                    <Modal.Title> {HeaderText} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label> {t('name')} </label>
                            <input value={this.state.name} onChange={(event) => this.handleNameChange(event.target.value)} type="text" className="form-control"></input>
                        </div>
                    </form>

                </Modal.Body>
                <Modal.Footer>

                    {BtnSubmit}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(MenuAddModal);