import React, { Component } from 'react'

import Context from '../Context'

import SidebarPrimary from './SidebarPrimary/SidebarPrimary'
import SidebarSecondary from './SidebarSecondary/SidebarSecondary'

import classes from './MainSidebar.module.css'

export default class MainSidebar extends Component {
    static contextType = Context
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {
        const contextValue = this.context

        return (
            <div className={classes.mainSidebar}>
                <div className={classes.wrappedSidebar}>
                    <SidebarPrimary />
                    <SidebarSecondary />
                </div>
            </div>
        )
    }
}
