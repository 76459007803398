import React, { Component } from 'react';

class index extends Component {
    render() {

        var image = this.props.image ? <img src={this.props.image } className="card-img" alt="..." /> : <svg className="bd-placeholder-img" 
            width="100%" height="150" xmlns="http://www.w3.org/2000/svg" 
            preserveAspectRatio="xMidYMid slice" 
            focusable="false" role="img" aria-label="Placeholder: Image">
                <title>Placeholder</title>
                <rect width="100%" height="100%" fill="#868e96"></rect>
                <icon x="45%" y="50%" fill="#dee2e6" dy=".5em">
                   
                </icon>
            </svg>

        return (
            <a href={ this.props.title} className="col-md-3">
                <div className="card mb-3" style={{"maxWidth": 540}}>
                    <div className="row no-gutters">
                        <div className="col-md-5">
                            { image }
                        </div>
                        <div className="col-md-7">
                            <div className="card-body">
                                <h7 className="card-title" style={{fontSize:12, color:"black"}}>{ this.props.title.substring(0,20)+" ..."}</h7>
                                <p className="card-text">{ this.props.description}</p>
                                <p className="card-text" style={{fontSize:10}}><small className="text-muted">{this.props.info}</small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        );
    }
}

export default index;