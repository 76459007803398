import React, { Component } from "react";
import { Button, Tab, Tabs, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataTable, { defaultThemes } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import swal from "sweetalert";
import Pagination from "react-js-pagination";

import Card, { CardHeader, CardBody } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "../components/Content";
import ArticleDeleteConfirm from "./../components/Modal/ArticleDeleteConfirm";
import MultipleDropdownButton from "../components/Dropdown/MultipleDropdownButton"
import TitlePages from './Elements/TitlePages'
import ArticleApi from "./../api/ArticleApi";
import TemplateApi from "./../api/TemplateApi";
import UserApi from "./../api/UserApi";
import "./ArticleListPage.css";
// import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
import { withTranslation } from 'react-i18next';
import UserContext from './Context'
import config from '../config/config'
import ReactGA from 'react-ga';

import AuthorAddModal from "../components/Modal/AuthorAddModal";
import AuthorDeleteConfirm from "../components/Modal/AuthorDeleteConfirm";

class ArticleListPage extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      filteredArticles: [],
      selectedArticles: [],
      filters: {
        name: "",
        author: "",
        category: [],
        collection: [],
        templateId: ""
      },
      inputField: {
        category: [],
        collection: [],
        templates: []
      },
      authors: [],
      authorTabKey: 1,
      showModalAuthor: false,
      showConfirmAuthor: false,
      authorId: 0,
      selectedAuthor: [],
      deleteDisable: true,

      activeTemplate: 0,
      showModalConfirm: false,
      loadingModal: "",
      widthName: null,
      widthColl: null,
      maxWidthAction: null,
      transAlert: "alert."
    };

    this.getAllArticles = this.getAllArticles.bind(this);
    this.getAllTemplates = this.getAllTemplates.bind(this)
    this.getAuthor = this.getAuthor.bind(this)
    this.showCategory = this.showCategory.bind(this);
    this.deleteArticle = this.deleteArticle.bind(this);
    this.deleteSelectedArticle = this.deleteSelectedArticle.bind(this);
    this.deleteSelectedAuthor = this.deleteSelectedAuthor.bind(this)
    this.filterArticles = this.filterArticles.bind(this);
    this.handleFilterTextBox = this.handleFilterTextBox.bind(this)
    this.handleFilterDropdown = this.handleFilterDropdown.bind(this)
    this.handleChangeTemplate = this.handleChangeTemplate.bind(this)
    this.handleSearchAuthorChange = this.handleSearchAuthorChange.bind(this)
    this.handlePageAuthorChange = this.handlePageAuthorChange.bind(this)
    this.setShowModalConfirm = this.setShowModalConfirm.bind(this);
    this.setShowModalAuthor = this.setShowModalAuthor.bind(this)
    this.setShowConfirmAuthor = this.setShowConfirmAuthor.bind(this)
    this.setSelectedAuthor = this.setSelectedAuthor.bind(this)
  }

  componentDidMount() {
    ReactGA.initialize(config.GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    const { activeTemplate } = this.context;

    this.getAllTemplates();
    this.getAuthor();

    this.setState({
      activeTemplate: activeTemplate,
      filters: {
        ...this.state.filters,
        templateId: activeTemplate
      }
    }, () => {
      this.getAllArticles()
    })

    if (window.screen.width <= 1440) {
      this.setState({
        widthName: "290px",
        widthColl: "170px",
        maxWidthAction: "120px",
      });
    } else if (window.screen.width <= 1920) {
      this.setState({
        widthName: "650px",
      });
    } else if (window.screen.width === 2560) {
      this.setState({
        widthName: "40vw",
      });
    }
  }

  componentDidUpdate() {
    const { activeTemplate } = this.context;

    if (activeTemplate !== this.state.activeTemplate) {
      this.setState({
        activeTemplate: activeTemplate,
        filters: {
          author: "",
          category: [],
          collection: [],
          name: "",
          templateId: activeTemplate
        }
      }, () => {
        this.getAllArticles();
        this.getAllTemplates();
      })
    }
  }

  setShowModalConfirm() {
    this.setState({
      showModalConfirm: !this.state.showModalConfirm,
    });
  }

  setShowModalAuthor() {
    this.setState({
      showModalAuthor: !this.state.showModalAuthor,
    });
  }

  setShowConfirmAuthor() {
    this.setState({
      showConfirmAuthor: !this.state.showConfirmAuthor,
    });
  }

  deleteSelectedAuthor() {
    const { t } = this.props;
    const userObj = new UserApi();

    const author = this.state.selectedAuthor;

    // console.log(" selectedUser => ",author)

    userObj
      .deleteSelectedAuthor(author)
      .then((res) => {
        swal(
          "Success !",
          t(this.state.transAlert + "author_delete_success"),
          "success"
        );
        this.setShowConfirmAuthor();
        this.getAuthor();
        this.setState({
          selectedAuthor: [],
        });
      })
      .catch((err) => {
        console.log(" err => ", err);
      });
  }

  setSelectedAuthor(author, value) {
    // alert("sadfasdas")

    var lastSelected = [...this.state.selectedAuthor];
    var indexDelete = -1;

    if (value === true) {
      lastSelected.push(author);
      console.log(lastSelected);
    } else {
      //console.log("jalan donk harusnya ", article , this.state.selectedArticles)
      this.state.selectedAuthor.forEach((item, index) => {
        if (item.id === author.id) {
          indexDelete = index;
        }
      });

      lastSelected.splice(indexDelete, 1);
      //console.log("lastArticles => ",lastArticles)
    }

    this.setState(
      {
        selectedAuthor: lastSelected,
      },
      () => {
        if (this.state.selectedAuthor.length > 0) {
          this.setState({
            deleteDisable: false,
          });
        } else {
          this.setState({
            deleteDisable: true,
          });
        }
      }
    );
  }

  handleSearchAuthorChange(value) {
    this.setState(
      {
        searchAuthor: value,
      },
      () => {
        this.getAuthor();
      }
    );
  }

  handlePageAuthorChange(pageNumber) {
    this.setState(
      {
        activePageAuthor: pageNumber,
      },
      () => {
        this.getAuthor();
      }
    );
  }

  showCategory(categoryId) {
    var result = this.getArticleDetail(categoryId);
    return result;
  }

  getArticleDetail(categoryId) {
    const objArticle = new ArticleApi();

    return objArticle.getDetailArticleCategory(categoryId);
  }

  getAllArticles(params) {
    const objArticle = new ArticleApi();
    const language = localStorage.getItem('lang');
    let inputField = {};
    inputField.category = new Set()
    inputField.collection = new Set()

    objArticle
      .getAllArticles({
        max_size: true,
        internal: false,
        language: language,
        template: this.state.filters.templateId,
        order: '-id',
        ...params
      })
      .then((res) => {
        const articles = res.data.results.map((item, index, array) => {
          item.category_names.forEach(cat => {
            inputField.category.add(cat)
          })
          item.widgets.forEach(widget => {
            inputField.collection.add(widget.name)
          })

          return {
            id: item.id,
            title: item.title,
            name: item.name,
            view_counter: item.view_counter,
            category: item.category,
            category_names: item.category_names,
            widgets: item.widgets,
            author_name: item.author_name
          }
        });

        inputField.category = [...inputField.category]
        inputField.collection = [...inputField.collection]

        this.setState({
          articles: articles,
          filteredArticles: articles,
          inputField: {
            ...this.state.inputField,
            ...inputField
          }
        })

      });
  }

  getAllTemplates() {
    const templateApi = new TemplateApi();
    const activeSite = localStorage.getItem('siteId')

    templateApi
      .getSites(activeSite)
      .then(res => {
        const templates = res.data.templates;
        this.setState({
          inputField: {
            ...this.state.inputField,
            templates: templates
          }
        })
      })
  }

  getAuthor() {
    const userObj = new UserApi();

    userObj
      .getAllAuthor({
        page_size: this.state.limitPageAuthor,
        page: this.state.activePageAuthor,
        search: this.state.searchAuthor,
      })
      .then((res) => {
        this.setState({
          authors: res.data.results,
          totalCountAuthor: res.data.count,
        });
      });
  }

  deleteArticle(articleId) {
    const objArticle = new ArticleApi();

    objArticle
      .getDetailArticles(articleId)
      .then((res) => {
        this.setState({
          selectedArticles: [res.data],
          showModalConfirm: !this.state.showModalConfirm,
        });
      })
      .catch((err) => {
        swal("Error !", err.message, "error");
      });
  }

  deleteSelectedArticle() {
    const { t } = this.props;
    const objArticle = new ArticleApi();

    this.setState({
      loadingModal: (
        <div className="overlay d-flex justify-content-center align-items-center">
          <i className="fas fa-2x fa-sync fa-spin"></i>
        </div>
      ),
    });

    const ids = this.state.selectedArticles.map((item) => {
      return item.id;
    });

    objArticle
      .deleteMultiArticle(ids)
      .then((res) => {
        this.setState(
          {
            selectedArticles: [],
            showModalConfirm: !this.state.showModalConfirm,
            loadingModal: "",
          },
          () => {
            this.getAllArticles();
            swal("Success !", t(this.state.transAlert + "delete_multi_article"), "success");
          }
        );
      })
      .catch((err) => {
        swal("Error !", err.message, "error");
      });
  }

  handleFilterDropdown(e, filterName) {
    const value = e.target.dataset.value
    let state = new Set([...this.state.filters[filterName]])
    if (state.has(value)) {
      state.delete(value)
    } else {
      state.add(value)
    }

    let newFilters = { ...this.state.filters }
    newFilters[filterName] = [...state];
    this.setState({ filters: newFilters }, this.filterArticles)
  }

  handleFilterTextBox(e, filterName) {
    let newFilters = { ...this.state.filters }
    newFilters[filterName] = e.target.value;
    this.setState({ filters: newFilters }, this.filterArticles)
  }

  handleChangeTemplate(e) {
    this.setState({
      filters: {
        ...this.state.filters,
        templateId: e.target.value
      }
    }, () => {
      const params = { template: this.state.filters.templateId }
      this.getAllArticles(params)
    })
  }

  filterArticles() {
    const filters = { ...this.state.filters }
    const filteredArticles = [...this.state.articles].filter(article => {
      let categorySet = new Set()
      let collectionSet = new Set()
      article.category_names.forEach(cat => {
        categorySet.add(cat)
      })
      article.widgets.forEach(widget => {
        collectionSet.add(widget.name)
      })
      const articleName = article.name ? article.name : ""
      const author = article.author_name ? article.author_name : ""
      const categoryIntersection = new Set([...filters.category].filter(x => categorySet.has(x)))
      const collectionIntersection = new Set([...filters.collection].filter(x => collectionSet.has(x)))

      return (
        (articleName).toLowerCase().includes(filters.name?.toLowerCase())
        && (author).toLowerCase().includes(filters.author?.toLowerCase())
        && (filters.category.length === 0 || categoryIntersection.size > 0)
        && (filters.collection.length === 0 || collectionIntersection.size > 0)
      )
    })

    this.setState({
      filteredArticles: filteredArticles
    })
  }

  render() {
    const { t } = this.props;
    const columnsTable = [
      {
        name: "Id",
        selector: "id",
        sortable: true,
        width: "70px"
      },
      {
        name: t('name'),
        selector: "name",
        sortable: true,
        width: this.state.widthName,
        // maxWidth:""
        style: {
          width: 15,
        },
        cell: (row) => (
          <Link
            to={`/article/detail/${row.id}?template=${this.state.filters.templateId}`}
          >
            {row.name}
          </Link>
        ),
      },
      {
        name: t('author'),
        selector: "author_name",
        sortable: true,
        style: {
          color: "black",
        },
        cell: (row) => row.author_name,
      },
      {
        name: t('category'),
        selector: "category_names",
        sortable: true,
        style: {
          color: "black",
        },
        cell: (row) => {
          return (
            <ul style={{ padding: "0 0 0 10px" }}>
              {row.category_names.map((item) => {
                return <li>{item}</li>;
              })}
            </ul>
          )
        },
      },
      {
        name: t('collections'),
        selector: "widget_name",
        sortable: true,
        width: this.state.widthColl,
        style: {
          color: "black",
        },
        cell: (row) => {
          return (
            <ul>
              {row.widgets.map((item) => {
                return <li>{item.name}</li>;
              })}
            </ul>
          );
        },
      },
      {
        name: t('counter'),
        selector: "view_counter",
        sortable: false,
        width: '70px',
        style: {
          color: "black",
        },
        cell: (row) => <span className='m-auto'>{row.view_counter}</span>
      },
      {
        name: t('action'),
        selector: "action",
        maxWidth: this.state.maxWidthAction,
        // width: "200px",
        cell: (row) => (
          <div id={row.id}>
            <Button
              variant="danger"
              className="btn-sm"
              onClick={() => this.deleteArticle(row.id)}
            >
              <i className="fa fa-trash"></i>
            </Button>
          </div>
        ),
      },
    ];

    const AuthorList = this.state.authors.map((item) => {
      let fil = localStorage.getItem("lang") === "en" ? 1 : 2;
      const filter = [fil];
      const data = item.author_languages;
      let filtered = data.filter(function (item) {
        return filter.indexOf(item.language) !== -1;
      });

      // console.log(filtered);

      let name = filtered ? (filtered[0] ? filtered[0].name : "") : "";
      return (
        <tr>
          <td>
            <input
              type="checkbox"
              onClick={(event) => {
                this.setSelectedAuthor(
                  item,
                  event.target.checked
                );
              }}
              id={item.id}
            ></input>
          </td>
          <td>
            <img
              src={item.avatar}
              width={150}
              height={150}
              alt={item.name}
            />
          </td>
          <td>{name}</td>
          <td>
            <div style={{ float: "right" }}>
              <Button
                className="btn btn-primary btn-xs"
                onClick={() => {
                  this.setState(
                    {
                      authorId: item.id,
                    },
                    () => {
                      this.setShowModalAuthor();
                    }
                  );
                }}
              >
                <i className="fa fa-edit"></i>
              </Button>
                      &nbsp;
                      <Button
                variant="danger"
                className="btn btn-xs"
                onClick={() => {
                  this.setState(
                    {
                      selectedAuthor: [item],
                    },
                    () => this.setShowConfirmAuthor()
                  );
                }}
              >
                <i className="fa fa-trash"></i>
              </Button>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <Content>
        <ContentHeader>
          {/* <h1> Articles </h1> */}
          <TitlePages title={t('pages.articles.title')} />
        </ContentHeader>
        <ContentBody>
          <Card>
            <CardHeader>
              <Tabs
                activeKey={this.state.authorTabKey}
                onSelect={k => {
                  this.setState({ authorTabKey: k })
                }}>
                <Tab eventKey={1} title={t('pages.articles.title')}>
                  <br></br>
                  <div className="d-flex justify-content-between">
                    <div></div>
                    <div>
                      <Button className="btn btn-primary" href={`/article/add?template=${this.state.filters.templateId}`}>
                        <i className="fa fa-plus">&nbsp;</i>
                        {t('pages.articles.add_article')}
                      </Button>
                    </div>
                  </div>
                  <div id="body_article">

                    {/* <DataTableExtensions
                columns={columnsTable}
                data={this.state.articles}
              > */}
                    <div
                      id="filter-articles"
                      className="form-inline row"
                    >
                      <input
                        className="form-control d-inline-block col-2"
                        // style={{ maxWidth: "400px", fontSize: "13px" }}
                        type="text"
                        placeholder={t('pages.articles.search_name')}
                        onChange={(e) => this.handleFilterTextBox(e, "name")}
                      />

                      <input
                        className="form-control d-inline-block ml-2 col-2"
                        // style={{ width: "100px", fontSize: "13px" }}
                        type="text"
                        placeholder={t('pages.articles.search_author')}
                        onChange={(e) => this.handleFilterTextBox(e, "author")}
                      />

                      <MultipleDropdownButton
                        className="d-inline-block ml-2 p-0 col-3 col-xl-2"
                        // style={{ width: "124px" }}
                        placeholder={t('pages.articles.category_filters')}
                        onChange={(e) => { this.handleFilterDropdown(e, "category") }}
                        values={this.state.inputField.category}
                        selectedValue={this.state.filters.category}
                      />

                      <MultipleDropdownButton
                        className="d-inline-block ml-2 p-0 col-3 col-xl-2"
                        // style={{ width: "145px" }}
                        placeholder={t('pages.articles.collection_filters')}
                        onChange={(e) => { this.handleFilterDropdown(e, "collection") }}
                        values={this.state.inputField.collection}
                        selectedValue={this.state.filters.collection}
                      />

                      <div className="form-inline p-0 ml-4 col">
                        <label htmlFor="filter-template">Template: &nbsp;</label>
                        <select
                          className="form-control d-inline-block"
                          id="filter-template"
                          onChange={this.handleChangeTemplate}
                          value={this.state.filters.templateId}
                        >
                          {
                            this.state.inputField.templates.map(template => {
                              return (
                                <option
                                  key={"" + template.id + template.key}
                                  value={template.id}
                                >
                                  {template.name}
                                </option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </div>

                    <DataTable
                      columns={columnsTable}
                      data={this.state.filteredArticles}
                      className="table table-bordered dataTable"
                      striped
                      role="grid"
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      pagination
                      selectableRows={true}
                      selectableRowsHighlight={true}
                      highlightOnHover={true}
                      customStyles={{
                        headCells: {
                          style: {
                            borderRightStyle: "solid",
                            borderRightWidth: "1px",
                            borderRightColor: defaultThemes.default.divider.default,
                          },
                        },
                        cells: {
                          style: {
                            borderRightStyle: "solid",
                            borderRightWidth: "1px",
                            borderRightColor: defaultThemes.default.divider.default,
                          },
                        },
                      }}
                    // progressPending
                    />
                    {/* </DataTableExtensions> */}
                    <div className="clearfix"></div>
                  </div>
                </Tab>
                <Tab eventKey={2} title={t("author")}>
                  <br></br>
                  <div className="d-flex justify-content-between">
                    <div></div>
                    <div>
                      <Button
                        className="btn btn-danger mr-2"
                        disabled={
                          this.state.deleteDisable
                        }
                        onClick={() => {
                          this.setShowConfirmAuthor();
                        }}
                      >
                        {t(
                          "pages.user_page.delete_selected_author"
                        )}
                      </Button>
                      <Button
                        className="btn btn-primary"
                        onClick={() => {
                          this.setState(
                            {
                              authorId: 0,
                            },
                            () => {
                              this.setShowModalAuthor();
                            }
                          );
                        }}
                        style={{ float: "right" }}
                      >
                        <i className="fa fa-plus">
                          &nbsp;
                                                </i>{" "}
                        {t(
                          "pages.user_page.add_author"
                        )}
                      </Button>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className={[
                        "col-md-10",
                        "p-0",
                      ].join(" ")}
                    >
                      <div
                        className={[
                          "form-group col-md-4",
                          "p-0",
                        ].join(" ")}
                      >
                        <label> {t("search")} </label>
                        <input
                          type="text"
                          className="form-control"
                          name="search"
                          placeholder={t(
                            "pages.user_page.search_author"
                          )}
                          value={
                            this.state.searchAuthor
                          }
                          onChange={(event) =>
                            this.handleSearchAuthorChange(
                              event.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="col-md-2"
                      style={{ position: "relative" }}
                    >
                      <div
                        style={{
                          float: "right",
                          position: "absolute",
                          bottom: 0,
                          right: 6,
                        }}
                      >
                        <Pagination
                          activePage={
                            this.state
                              .activePageAuthor
                          }
                          itemsCountPerPage={
                            this.state
                              .limitPageAuthor
                          }
                          totalItemsCount={
                            this.state
                              .totalCountAuthor
                          }
                          pageRangeDisplayed={4}
                          onChange={
                            this
                              .handlePageAuthorChange
                          }
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th></th>
                          <th> {t("avatar")} </th>
                          <th> {t("author")} </th>
                          <th> {t("action")} </th>
                        </tr>
                      </thead>
                      <tbody>{AuthorList}</tbody>
                    </Table>
                  </div>
                  <div className="clearfix"></div>
                  <div className="row">
                    <div className="col-md-10"></div>
                    <div className="col-md-2">
                      <div style={{ float: "right" }}>
                        <Pagination
                          activePage={
                            this.state
                              .activePageAuthor
                          }
                          itemsCountPerPage={
                            this.state
                              .limitPageAuthor
                          }
                          totalItemsCount={
                            this.state
                              .totalCountAuthor
                          }
                          pageRangeDisplayed={4}
                          onChange={
                            this
                              .handlePageAuthorChange
                          }
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="clearfix"></div>
                </Tab>
              </Tabs>

              <div className="card-tools">
              </div>
            </CardHeader>

          </Card>
        </ContentBody>
        <ArticleDeleteConfirm
          selectedArticles={this.state.selectedArticles}
          deleteSelectedArticle={this.deleteSelectedArticle}
          loadingModal={this.state.loadingModal}
          show={this.state.showModalConfirm}
          onHide={this.setShowModalConfirm}
        ></ArticleDeleteConfirm>
        <AuthorAddModal
          show={this.state.showModalAuthor}
          onHide={this.setShowModalAuthor}
          authorId={this.state.authorId}
          getAuthor={this.getAuthor}
        />
        <AuthorDeleteConfirm
          show={this.state.showConfirmAuthor}
          onHide={this.setShowConfirmAuthor}
          getAuthor={this.getAuthor}
          selectedAuthor={this.state.selectedAuthor}
          deleteSelectedAuthor={this.deleteSelectedAuthor}
        />
      </Content>
    );
  }
}

export default withTranslation()(ArticleListPage);
