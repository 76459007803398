import React, { Component } from "react";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import swal from "sweetalert";

import PageApi from "./../../api/PageApi";
import MenuApi from "./../../api/MenuApi";
import SectionApi from "./../../api/SectionApi";
// import GeneralApi from "./../../api/GeneralApi";
import AlertHelper from "./../../lib/AlertHelper";
import { withTranslation } from "react-i18next";
import Context from "../../pages/Context";

class PageSectionAdd extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            pageId: 0,
            name: "",
            title: "",
            pages: [],
            menu: "",
            menus: [],

            names: [],
            urlLanguages: [],
            languageList: [],
            tabKey: 1,
            selectedLanguage: 1,
            renderTabLanguage: "",
            idElement: 0,

            isDraft: true,
            isDark: true,
            hasShortcut: false,

            transPage: "modals.page_section_add.",
            transAlert: "alert.",

            // index:0,
        };

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleMenuChange = this.handleMenuChange.bind(this);
        this.handleDarkModeChange = this.handleDarkModeChange.bind(this);
        this.handleChangeShortcut = this.handleChangeShortcut.bind(this);
        // this.handlePageChange = this.handlePageChange.bind(this)
        // this.handleIndexChange = this.handleIndexChange.bind(this)

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);

        this.getPages = this.getPages.bind(this);
        this.getMenus = this.getMenus.bind(this);
        this.detailSection = this.detailSection.bind(this);

        this.renderTabLanguage = this.renderTabLanguage.bind(this);
    }

    handleMenuChange(value) {
        this.setState({
            menu: value,
        });
    }

    // handleNameChange(value) {
    //     this.setState({
    //         name:value
    //     })
    // }

    // handlePageChange(value){
    //     this.setState({
    //         pageId:value
    //     })
    // }

    // handleIndexChange(value) {
    //     this.setState({
    //         index:value
    //     })
    // }

    onOpenModal() {
        console.log(this.props.id);
        const _this = this;
        const { languagesList } = this.context;

        const templateId = localStorage.getItem("templateId");

        this.getMenus();

        this.getPages({
            template: templateId,
        });

        this.setState(
            {
                id: this.props.id || 0,
                pageId: this.props.pageId,
                tabKey: 1,
                selectedLanguage: 1,
                languageList: languagesList,
                isDraft: false,
                isDark: false,
            },
            () => {
                // console.log("languageList ===> ", this.state.languageList)

                if (this.state.id) {
                    _this.detailSection(this.state.id).then((res) => {
                        console.log(res.data);
                        const sectionLanguages = res.data.section_languages.map(
                            (item) => {
                                return {
                                    id: item.id,
                                    name: item.name ? item.name : "",
                                    title: item.title ? item.title : "",
                                    languageName: item.language_name,
                                    language: item.language,
                                };
                            }
                        );

                        this.setState({
                            name: res.data.name,
                            menu: res.data.menu,
                            pageId: res.data.page,
                            index: res.data.index,
                            names: sectionLanguages,
                            isDraft: res.data.is_draft,
                            isDark: res.data.is_dark,
                            hasShortcut: res.data.has_shortcut,
                        });
                    });
                }

                this.renderTabLanguage();
            }
        );
    }

    onCloseModal() {
        this.setState(
            {
                id: 0,
                pageId: 0,
                name: "",
                title: "",
                pages: [],
                names: [],
            },
            () => {
                this.props.onHide();
            }
        );
    }

    getMenus() {
        const objMenu = new MenuApi();

        objMenu
            .getAllMenu()
            .then((res) => {
                // console.log(res.data)

                this.setState({
                    menus: res.data.results,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getPages(params) {
        const objPage = new PageApi();

        objPage.getAllPages(params).then((res) => {
            const result = res.data.results.map((item) => {
                return {
                    id: item.id,
                    name: item.name,
                };
            });

            this.setState({
                pages: result,
            });
        });
    }

    detailSection(sectionId) {
        const objSection = new SectionApi();

        return objSection.getDetailSection(sectionId);
    }

    handleDarkModeChange(value) {
        this.setState({
            isDark: value,
        });
    }

    handleIsDraftChange(value) {
        console.log("isDraft =>", value);

        this.setState({
            isDraft: value,
        });
    }

    handleSubmit() {
        const { t } = this.props;
        const objSection = new SectionApi();

        const sectionLanguages = this.state.names.map((item) => {
            return {
                language: item.language,
                name: item.name,
                title: item.title,
            };
        });

        const data = {
            name: this.state.name,
            page: this.state.pageId,
            menu: this.state.menu,
            is_dark: this.state.isDark,
            is_draft: this.state.isDraft,
            has_shortcut: this.state.hasShortcut,
            section_languages: sectionLanguages,
        };

        objSection
            .sectionAdd(data)
            .then((res) => {
                this.props.onHide();
                swal(
                    "Success !",
                    t(this.state.transAlert + "section_add_success"),
                    "success"
                );
                // this.props.getPageDetail(this.props.pageId);
                // this.setState({
                //   id: 0,
                //   pageId: 0,
                //   name: "",
                //   title: "",
                //   pages: [],
                //   names: [],
                // });
            })
            .catch((err) => {
                const objAlert = new AlertHelper();
                const resErr = err?.response?.data
                    ? err?.response?.data
                    : err.message;
                //console.log(" Error => ",err?.response?.data)
                objAlert.errorHandlerAlert(resErr);
            });
    }

    handleNameChange(id, value) {
        let names = [...this.state.names];
        let name = { ...names[id] };
        name.name = value;
        names[id] = name;

        this.setState({
            name: names[0].name,
            names: names,
        });
    }

    handleTitleChange(id, value) {
        let names = [...this.state.names];
        let title = { ...names[id] };
        title.title = value;
        names[id] = title;

        this.setState({
            name: names[0].title,
            names: names,
        });
    }

    handleSectionUpdate() {
        const { t } = this.props;
        const objSection = new SectionApi();

        const sectionId = this.state.id;

        const sectionLanguages = this.state.names.map((item) => {
            return {
                language: item.language,
                name: item.name,
                title: item.title,
            };
        });

        const data = {
            name: this.state.name,
            page: this.state.pageId,
            menu: this.state.menu,
            section_languages: sectionLanguages,
            is_dark: this.state.isDark,
            is_draft: this.state.isDraft,
            has_shortcut: this.state.hasShortcut,
            // index:this.state.index
        };

        console.log("data update >", data);

        objSection
            .sectionUpdate(sectionId, data)
            .then((res) => {
                this.props.onHide();
                swal(
                    "Success !",
                    t(this.state.transAlert + "section_update_success"),
                    "success"
                );
                //this.props.getPageDetail(this.props.pageId)
                // setTimeout(() => {
                //   window.location.reload()
                // }, 0)
            })
            .catch((err) => {
                const objAlert = new AlertHelper();
                const resErr = err?.response?.data
                    ? err?.response?.data
                    : err.message;
                //console.log(" Error => ",err?.response?.data)
                objAlert.errorHandlerAlert(resErr);
            });
    }

    handleChangeTab(value) {
        this.setState(
            {
                selectedLanguage: value,
                tabKey: value,
            },
            () => {
                this.renderTabLanguage();
            }
        );
    }

    handleChangeShortcut() {
        this.setState({
            hasShortcut: !this.state.hasShortcut,
        });
    }

    renderTabLanguage() {
        const renderTabLanguage = this.state.languageList.map((item, index) => {
            var findName = this.state.names.find(
                (textLang, idx) => item.id === textLang.language
            );
            console.log("item ", findName);
            return {
                id: findName?.id,
                language: item.id,
                //   name: findName ? findName.name : "",
                languageName: item.name,
                //   page: this.state.selectedPage,
                //   section: this.state.selectedSection,
                name: findName?.name,
            };
        });

        this.setState(
            {
                names: renderTabLanguage,
            },
            () => {}
        );
    }

    render() {
        const { t } = this.props;
        // const PageList = this.state.pages.map(item => {
        //     return (<option key={item.id} value={item.id}>{item.name}</option>)
        // })

        const MenuList = this.state.menus.map((item) => {
            return (
                <option key={item.id} value={item.id}>
                    {item.name}
                </option>
            );
        });

        var HeaderText = t("add_section");
        var BtnSubmit = (
            <Button variant="primary" onClick={() => this.handleSubmit()}>
                <i className="fa fa-plus">&nbsp;</i>
                {t("add_section")}
            </Button>
        );

        if (this.state.id) {
            HeaderText = t("update_section");
            BtnSubmit = (
                <Button
                    variant="primary"
                    onClick={() => this.handleSectionUpdate()}
                >
                    <i className="fa fa-edit">&nbsp;</i>
                    {t(this.state.transPage + "edit_section")}
                </Button>
            );
        }

        const TabLanguage = this.state.names.map((item, index) => {
            // console.log("item" ,item )

            return (
                <Tab
                    key={index}
                    eventKey={item.language}
                    title={item.languageName}
                >
                    <br></br>

                    <div className="form-group">
                        <label> {t("title")} </label>
                        <textarea
                            className="form-control"
                            onChange={(event) =>
                                this.handleTitleChange(
                                    index,
                                    event.target.value
                                )
                            }
                            value={this.state.names[index].title}
                        ></textarea>
                        <label> {t("name")} </label>
                        <textarea
                            className="form-control"
                            onChange={(event) =>
                                this.handleNameChange(index, event.target.value)
                            }
                            value={this.state.names[index].name}
                        ></textarea>
                        {/* {JSON.stringify(this.state.names)} */}
                    </div>
                </Tab>
            );
        });

        return (
            <Modal
                show={this.props.show}
                onHide={() => this.onCloseModal()}
                onShow={this.onOpenModal}
            >
                <Modal.Header closeButton className="bg-primary">
                    <Modal.Title> {HeaderText} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        {/* { JSON.stringify(this.state.names)} */}
                        <Tabs>{TabLanguage}</Tabs>

                        <div className="form-group">
                            <label> {t("menu")} </label>
                            <select
                                className="form-control"
                                value={this.state.menu}
                                onChange={(event) =>
                                    this.handleMenuChange(event.target.value)
                                }
                            >
                                <option value="">
                                    {" "}
                                    -- {t(
                                        this.state.transPage + "select_menu"
                                    )}{" "}
                                    --{" "}
                                </option>
                                {MenuList}
                            </select>
                        </div>

                        <div className="form-group">
                            <label>
                                {" "}
                                {t(this.state.transPage + "dark_logo")}{" "}
                            </label>
                            <br></br>
                            <input
                                id="dark"
                                type="radio"
                                name="darkLogo"
                                value={true}
                                defaultChecked={this.state.isDark === true}
                                onChange={() => this.handleDarkModeChange(true)}
                            ></input>{" "}
                            <label htmlFor="dark">{t("dark")}</label>
                            &nbsp;&nbsp;&nbsp;
                            <input
                                id="light"
                                type="radio"
                                name="darkLogo"
                                value={false}
                                defaultChecked={this.state.isDark === false}
                                onChange={() =>
                                    this.handleDarkModeChange(false)
                                }
                            ></input>{" "}
                            <label htmlFor="light">{t("light")}</label>
                        </div>

                        <div className="form-group">
                            <label> {t("publicity")} </label>
                            <select
                                className="form-control"
                                value={this.state.isDraft}
                                onChange={(event) =>
                                    this.handleIsDraftChange(event.target.value)
                                }
                            >
                                <option value="">
                                    -- {t(this.state.transPage + "select")} --
                                </option>
                                <option value={false}> {t("published")}</option>
                                <option value={true}> {t("draft")}</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <input
                                id="has-shortcut"
                                type="checkbox"
                                value={this.state.hasShortcut}
                                checked={this.state.hasShortcut}
                                onChange={(event) =>
                                    this.handleChangeShortcut()
                                }
                            ></input>
                            &nbsp;
                            <label for="has-shortcut">
                                {t(this.state.transPage + "has_shortcut")}
                            </label>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>{BtnSubmit}</Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(PageSectionAdd);
