import React, { Component } from 'react';
import Card,{ CardHeader, CardBody  } from "./../../components/Card";
import { Dropdown } from "react-bootstrap"

class AssetElementItem extends Component {
    render() {

        const { item } = this.props

        return(
            <div className="col-md-2" key={item.asset}>
                <Card>
                    <CardHeader className="bg-primary">
                        <div className="form-check form-check-inline">
                            <input type="checkbox" value="" onClick={(event) => { this.props.setSelectedGeneral("general",item, event.currentTarget.checked) }} className="form-check-input" id={"label"+item.asset}></input>&nbsp;
        <label className="form-check-label" htmlFor={"label"+item.asset}><b >{item.index}.{item.asset_name}</b> </label>
                        </div>
                        <div className="card-tools">
                                <Dropdown drop="down">
                                    <Dropdown.Toggle  id="dropdown-basic" as={"a"} >  
                                        <label href="#" style={{"color":"black"}}><i className="fa fa-ellipsis-v"></i></label>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href={`/asset/detail/${item.asset}`}>Update</Dropdown.Item>
                                       
                                    </Dropdown.Menu>
                                </Dropdown>
                              
                            </div>
                    </CardHeader>
                    <CardBody >
                        <div className="container-fluid" align="center">
                            <img alt="asset content" className="card-img" src={item.asset_file || "/img/phenikaa-default-image.svg"} height={150}></img> 
                        </div>
                        
                    </CardBody>
                    
                   
                </Card> 
            </div>
        )
    }
}

export default AssetElementItem;