import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Card, { CardHeader, CardTitle, CardBody } from "./../../components/Card";
import PageAddInformation from "../Modal/PageAddInformation";
import PageUpdateInformation from "../Modal/PageUpdateInformation";
import PageApi from "./../../api/PageApi";
import css from "./PageSettings.module.css";
import PageDeleteKeywords from "../Modal/PageDeleteKeywords";
import "./PageSetting.css";
import swal from "sweetalert";
import { withTranslation } from "react-i18next";

class PageSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageCatalogSetting: [],
            pageSetting: {},
            hasParams: false,
            isPublished: null,
            paramQty: 0,
            pageType: "",
            isDesc: null,
            isKeyword: [],
            ShowAddTextModal: false,
            ShowUpdateTextModal: false,
            ShowDeleteTextModal: false,
            modalName: null,
            isEnglish: false,
            isDataDesc: [],
            currentDescription: null,
            idSelected: null,
            keySelected: null,
            idDesc: null,
            isExpandle: true,
            isExpandle2: true,
            isExpandle3: true,
            isGeneralType: true,
            isErrorType: false,
            isChecked: false,
            isCheckedPublish: false,
            isCheckedSectionShortcut: false,
            value: 0,
            value2: 0,
            isScroll: false,
            sectionShortcut: [],
            idBehavior: 0,
            idBlock: 0,
            IDSectionShortcut: 0,
            IDScroll_Top: 0,
            id_pages: null,
            use_page_desc: false,
            isCheckedPageDesc: false,
            transPage: "pages.new_page_detail.page_settings.",
            transAlert: "alert.",
        };

        this.getPageDetail = this.getPageDetail.bind(this);
        this.getPageStyling = this.getPageStyling.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.setShowAddTextModal = this.setShowAddTextModal.bind(this);
        this.setShowUpdateTextModal = this.setShowUpdateTextModal.bind(this);
        this.setShowDeleteTextModal = this.setShowDeleteTextModal.bind(this);
        this.changeStatusExpand = this.changeStatusExpand.bind(this);
        this.changeStatusExpand2 = this.changeStatusExpand2.bind(this);
        this.changeStatusExpand3 = this.changeStatusExpand3.bind(this);
        this.onChangeCheckboxType = this.onChangeCheckboxType.bind(this);
        this.updatePageSectionsSetting = this.updatePageSectionsSetting.bind(
            this
        );
        this.updatePageSectionsStyling = this.updatePageSectionsStyling.bind(
            this
        );
    }

    componentDidMount() {
        const id = this.props.id;
        const idLanguages = localStorage.getItem("lang");
        this.setState(
            {
                isEnglish: idLanguages === "en",
                id_pages: id,
            },
            () => {
                this.getPageDetail(id);
                this.getPageStyling();
            }
        );
    }

    componentDidUpdate() {
        const idLanguages = localStorage.getItem("lang");
        const isEnglish = idLanguages === "en";

        if (this.state.isEnglish !== isEnglish) {
            this.setState(
                {
                    isEnglish: isEnglish,
                },
                () => {
                    this.getPageDetail(this.props.id);
                    this.getPageStyling();
                }
            );
        }

        if (this.props.id !== this.state.id_pages) {
            this.setState(
                {
                    id_pages: this.props.id,
                },
                () => {
                    this.getPageDetail(this.props.id);
                }
            );
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.hasParams !== nextProps.hasParams) {
            this.setState({
                hasParams: nextProps.hasParams,
                isChecked: nextProps.hasParams,
            });
        }

        if (this.state.isPublished !== nextProps.isPublished) {
            this.setState({
                isPublished: nextProps.isPublished,
                isCheckedPublish: nextProps.isPublished,
            });
        }

        if (this.state.paramQty !== nextProps.paramQty) {
        }

        if (nextProps.pageType === "General") {
            this.setState({
                pageType: "General",
            });
        }

        if (nextProps.isChecked) {
            this.setState({
                isScroll: nextProps.isChecked,
            });
        }

        if (nextProps.valueBehavior) {
            this.setState({
                value: nextProps.valueBehavior,
            });
        }

        if (nextProps.valueBlock) {
            this.setState({
                value2: nextProps.valueBlock,
            });
        }

        if (nextProps.idBehavior) {
            this.setState({
                idBehavior: nextProps.idBehavior,
            });
        }

        if (nextProps.idBlock) {
            this.setState({
                idBlock: nextProps.idBlock,
            });
        }

        if (nextProps.use_page) {
            this.setState({
                use_page_desc: nextProps.use_page,
                isCheckedPageDesc: nextProps.use_page,
            });
        }
    }

    getPageStyling() {
        const objPage = new PageApi();

        objPage
            .getPageStyling()
            .then((res) => {
                let secShortcut, ids, idb;

                res.data.results.forEach((data, i) => {
                    switch (i) {
                        case 0:
                            ids = data.id;
                            secShortcut = data.options;
                            break;
                        case 1:
                            idb = data.id;
                            break;
                        default:
                        // code block
                    }
                });

                this.setState({
                    IDSectionShortcut: ids,
                    sectionShortcut: secShortcut,
                    IDScroll_Top: idb,
                });
            })

            .catch((err) => {
                console.log(" err page style => ", err.message);
            });
    }

    getPageDetail(id) {
        const objPage = new PageApi();
        objPage
            .getPageDetail(id)

            .then((res) => {
                let dataLanguage = res.data.page_languages;
                let data = res.data.page_languages;
                let key = res.data.page_keywords;
                const qty = res.data.params_qty;
                const keyEn = [];
                const keyVn = [];
                key.forEach((ele) => {
                    if (ele.language === 1) {
                        keyEn.push({ id: ele.id, key: ele.keyword });
                    } else {
                        keyVn.push({ id: ele.id, key: ele.keyword });
                    }
                });

                if (this.state.isEnglish) {
                    this.setState({
                        isKeyword: keyEn,
                        idDesc: data[0].id,
                        isDesc: data[0].description,
                    });
                } else {
                    this.setState({
                        isKeyword: keyVn,
                        idDesc: data[1].description,
                        isDesc: data[1].description,
                    });
                }

                this.setState({
                    isCheckedSectionShortcut: res.data.styles[0].is_checked,
                    paramQty: qty,
                    currentDescription: dataLanguage,
                });

                // console.log(this.state.currentDescription);
            })

            .catch((err) => {
                console.log(" err page style => ", err.message);
            });
    }

    updateDescriptionUsed() {
        const objPage = new PageApi();
        objPage
            .updateDescriptionUsed(this.props.id, !this.state.isCheckedPageDesc)

            .then((res) => {
                if (res.data) {
                    setTimeout(window.location.reload(), 0);
                }
            })

            .catch((err) => {
                console.log(" err page style => ", err.message);
            });
    }

    updatePageSectionsSetting() {
        const { t } = this.props;
        const data = {
            page_type: this.state.pageType,
            param_qty: this.state.paramQty,
            has_param: this.state.hasParams,
            is_draft: this.state.isPublished,
        };

        const sectionObj = new PageApi();
        console.log(data);

        sectionObj
            .updatePageSectionsSetting(this.props.id, data)
            .then((res) => {
                this.props.getPageDetail(this.props.id);
                swal(
                    "Success !",
                    t(this.state.transAlert + "settings_update_success"),
                    "success"
                );
            })
            .catch((err) => {
                console.log(err.response.data);
            });
    }

    updatePageSectionsStyling() {
        const { t } = this.props;
        const data = {
            option: this.state.idBehavior,
            value: this.state.value,
        };

        const datas = {
            option: this.state.idBlock,
            value: this.state.value2,
        };

        const options = [];
        options.push(data, datas);

        const data_style_1 = {
            style: this.state.IDSectionShortcut,
            is_checked: this.state.isCheckedSectionShortcut,
            options: this.state.isCheckedSectionShortcut ? options : null,
        };
        const data_style_2 = {
            style: this.state.IDScroll_Top,
            is_checked: this.state.isScroll,
        };

        const dataAll = [];
        dataAll.push(data_style_1, data_style_2);

        const sectionObj = new PageApi();

        sectionObj
            .updatePageSectionsStyling(this.props.id, dataAll)
            .then((res) => {
                this.props.getPageDetail(this.props.id);
                swal(
                    "Success !",
                    t(this.state.transAlert + "page_styling_update_success"),
                    "success"
                );
            })
            .catch((err) => {
                console.log(err.response.data);
            });
    }

    onChangeCheckbox(key, itemType, value) {
        if (key === "publish") {
            this.setState({
                isPublished: value,
                isCheckedPublish: false,
            });
            this.setState((prevState) => ({
                pageSetting: {
                    ...prevState.pageSetting,
                    [key]: value,
                },
            }));

            //   if (value) {
            //     this.setState({
            //       isCheckedPublish: true,
            //     });
            //     this.setState((prevState) => ({
            //       pageSetting: {
            //         ...prevState.pageSetting,
            //         [key]: value,
            //       },
            //     }));
            //   } else {
            //     this.setState({
            //       isCheckedPublish: false,
            //     });
            //     this.setState((prevState) => ({
            //       pageSetting: {
            //         ...prevState.pageSetting,
            //         [key]: value,
            //       },
            //     }));
            //   }
        } else if (key === "draft") {
            this.setState({
                isPublished: value,
                isCheckedPublish: true,
            });
        } else if (key === "hasParams") {
            if (value) {
                this.setState((prevState) => ({
                    pageSetting: {
                        ...prevState.pageSetting,
                        [key]: value,
                        paramQty: prevState.paramQty,
                    },
                    hasParams: value,
                    isChecked: !prevState.isChecked,
                }));
            } else {
                this.setState((prevState) => ({
                    pageSetting: {
                        ...prevState.pageSetting,
                        paramQty: 0,
                        hasParams: false,
                    },
                    hasParams: false,
                    paramQty: 0,
                    isChecked: !prevState.isChecked,
                }));
            }
        } else if (key === "page_desc") {
            this.setState((prevState) => ({
                use_page_desc: value,
                isCheckedPageDesc: !prevState.isCheckedPageDesc,
            }));

            this.updateDescriptionUsed();
        } else if (key === "scroll_to_top") {
            this.setState((prevState) => ({
                isScroll: !prevState.isScroll,
            }));
        } else if (key === "section_shortcut") {
            this.setState({
                isCheckedSectionShortcut: !this.state.isCheckedSectionShortcut,
            });
        }
    }
    onChangeCheckboxType(key, itemType, value) {
        if (key === "pageType") {
            this.setState((prevState) => ({
                pageSetting: {
                    ...prevState.pageSetting,
                    [key]: itemType,
                },
            }));
            if (itemType === "Error") {
                this.setState({
                    pageType: "Error",
                });
                this.setState((prevState) => ({
                    pageSetting: {
                        ...prevState.pageSetting,
                        [key]: itemType,
                    },
                }));
            } else {
                this.setState({
                    pageType: "General",
                });
                this.setState((prevState) => ({
                    pageSetting: {
                        ...prevState.pageSetting,
                        [key]: itemType,
                    },
                }));
            }
        } else if (key === "behavior") {
            this.setState({
                value: itemType,
            });
        } else if (key === "block") {
            this.setState({
                value2: itemType,
            });
        } else {
            const data = itemType;
            this.setState((prevState) => ({
                pageSetting: {
                    ...prevState.pageSetting,
                    [key]: itemType,
                },
                paramQty: data,
            }));
        }
    }

    changeStatusExpand() {
        this.setState((prevState) => {
            return {
                isExpandle: !prevState.isExpandle,
            };
        });
    }

    changeStatusExpand2() {
        this.setState((prevState) => {
            return {
                isExpandle2: !prevState.isExpandle2,
            };
        });
    }

    changeStatusExpand3() {
        this.setState((prevState) => {
            return {
                isExpandle3: !prevState.isExpandle3,
            };
        });
    }

    setShowAddTextModal() {
        this.setState({
            ShowAddTextModal: !this.state.ShowAddTextModal,
        });
    }

    setShowDeleteTextModal() {
        this.setState({
            ShowDeleteTextModal: !this.state.ShowDeleteTextModal,
        });
    }

    setShowUpdateTextModal() {
        this.setState((prevState) => {
            return {
                ShowUpdateTextModal: !prevState.ShowUpdateTextModal,
            };
        });
    }

    render() {
        const { t } = this.props;
        let curDes = this.state.currentDescription;
        let name;
        if (this.state.modalName === "SEO Keywords") {
            name = t(this.state.transPage + "seo_keywords");
        } else if (this.state.modalName === "Desc") {
            name = t("description");
        }

        const { isExpandle, isExpandle2 } = this.state;
        let isExpandStatus, isExpandFooter, isExpandStatus2, isFalse;
        if (isExpandle) {
            isExpandStatus = css.isExpand;
            isExpandFooter = css.isExpandFooter;
            isFalse = css.offMb;
        } else {
            isExpandStatus = "";
            isFalse = "";
            isExpandFooter = css.isNotExpand;
        }

        if (isExpandle2) {
            isExpandStatus2 = css.isExpand2;
        } else {
            isExpandStatus2 = "";
        }

        let titleSettingCards = (
            <CardTitle>
                {" "}
                <i className="fa fa-gear" onClick={this.changeStatusExpand}>
                    &nbsp;
                </i>{" "}
                {t(this.state.transPage + "page_settings")} &nbsp;
                <i
                    class="fa fa-caret-down"
                    onClick={this.changeStatusExpand}
                ></i>
            </CardTitle>
        );

        let param_quantity = (
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    name="paramQty"
                    id="paramQty"
                    value={this.state.hasParams}
                    onChange={(event) =>
                        this.onChangeCheckboxType(
                            "paramQty",
                            1,
                            event.target.checked
                        )
                    }
                    disabled={this.state.isChecked ? false : true}
                    checked={this.state.paramQty === 1 ? true : false}
                />
                <label class="form-check-label" for="paramQty">
                    1
                </label>
            </div>
        );

        let param_quantity2 = (
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    name="paramQty"
                    id="param_qty2"
                    value={this.state.hasParams}
                    onChange={(event) =>
                        this.onChangeCheckboxType(
                            "paramQty",
                            2,
                            event.target.checked
                        )
                    }
                    disabled={this.state.isChecked ? false : true}
                    checked={this.state.paramQty === 2 ? true : false}
                />
                <label class="form-check-label" for="param_qty2">
                    2
                </label>
            </div>
        );

        let page_type = (
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    id="general_page"
                    value={this.state.pageType}
                    onChange={(event) =>
                        this.onChangeCheckboxType(
                            "pageType",
                            "General",
                            event.target.checked
                        )
                    }
                    checked={this.state.pageType === "General" ? true : false}
                />
                <label class="form-check-label" for="general_page">
                    General Page
                </label>
            </div>
        );
        let page_type2 = (
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    id="error_page"
                    value={this.state.pageType}
                    onChange={(event) =>
                        this.onChangeCheckboxType(
                            "pageType",
                            "Error",
                            event.target.checked
                        )
                    }
                    checked={this.state.pageType === "Error" ? true : false}
                />
                <label class="form-check-label" for="error_page">
                    Error Page
                </label>
            </div>
        );

        let settingCards = (
            <>
                <div className="form-group">
                    <label>
                        {" "}
                        {t(this.state.transPage + "has_parameters")}{" "}
                    </label>
                    <br />
                    <label
                        className="font-weight-normal"
                        htmlFor="enable-has-params"
                    >
                        {t("enable")}: &nbsp;
                    </label>
                    <input
                        id="enable-has-params"
                        type="checkbox"
                        value={this.state.hasParams}
                        onChange={(event) =>
                            this.onChangeCheckbox(
                                "hasParams",
                                "checkbox",
                                event.target.checked
                            )
                        }
                        checked={this.state.isChecked ? true : false}
                    />
                </div>
                <div className="form-group">
                    <label>
                        {" "}
                        {t(this.state.transPage + "parameters_quantity")}{" "}
                    </label>
                    <br />
                    {param_quantity}
                    {param_quantity2}
                </div>
                <div class="form-group">
                    <label>
                        {" "}
                        {t(this.state.transPage + "published_draft")}{" "}
                    </label>
                    <br />
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id={t("draft")}
                            value={this.state.isPublished}
                            onChange={(event) =>
                                this.onChangeCheckbox(
                                    "draft",
                                    "checkbox",
                                    event.target.checked
                                )
                            }
                            checked={this.state.isPublished}
                        />
                        <label class="form-check-label" for={t("draft")}>
                            {t("draft")}
                        </label>
                    </div>
                    {/* <div class="form-check">
                        <input
                            class="form-check-input"
                            type="radio"
                            id={t("publish")}
                            value={this.state.isPublished}
                            onChange={(event) =>
                                this.onChangeCheckbox(
                                    "publish",
                                    "checkbox",
                                    event.target.checked
                                )
                            }
                            checked={
                                this.state.isCheckedPublish === false
                                    ? true
                                    : false
                            }
                        />
                        <label class="form-check-label" for={t("publish")}>
                            {t("publish")}
                        </label>
                    </div> */}
                </div>

                {/* <div className="form-group">
          <label> {t(this.state.transPage + "is_publish")} </label>
          <br />
          {t("publish")}:{" "}
          <input
            type="checkbox"
            value={this.state.isPublished}
            onChange={(event) =>
              this.onChangeCheckbox("publish", "checkbox", event.target.checked)
            }
            checked={this.state.isCheckedPublish ? true : false}
          />
        </div> */}
                {/* <div className="form-group">
          <label> {t(this.state.transPage + "is_draft")} </label>
          <br />
          {t("draft")}:{" "}
          <input
            type="checkbox"
            value={this.state.isPublished}
            onChange={(event) =>
              this.onChangeCheckbox("publish", "checkbox", event.target.checked)
            }
            checked={this.state.isCheckedPublish ? true : false}
          />
        </div> */}

                <div className="form-group">
                    <label> {t(this.state.transPage + "type_of_page")} </label>
                    <br />
                    {page_type}
                    {page_type2}
                </div>
            </>
        );

        let footerCards = (
            <div className={[css.stylesFooter, isFalse].join(" ")}>
                <Button
                    className={["float-right", isExpandFooter].join(" ")}
                    onClick={this.updatePageSectionsSetting}
                >
                    {" "}
                    {t(this.state.transPage + "save_settings")}{" "}
                </Button>
            </div>
        );

        let titleSettingCardsInfo = (
            <CardTitle>
                {" "}
                <i className="fa fa-gear">&nbsp;</i> {t("seo")} &nbsp;
                <i
                    class="fa fa-caret-down"
                    onClick={this.changeStatusExpand2}
                ></i>
            </CardTitle>
        );

        let isKeywords;

        isKeywords = this.state.isKeyword.map((ele) => {
            return (
                <div
                    style={{
                        width: "auto",
                        height: "auto",
                        border: "1px solid #e9e9e9",
                        borderRadius: "5px",
                        marginBottom: "1rem",
                    }}
                    id={ele.id}
                >
                    <div className="d-flex flex-row">
                        <p
                            style={{
                                width: "80%",
                                justifyContent: "center",
                                margin: "auto",
                                padding: "3px 10px",
                            }}
                        >
                            {ele.key}
                        </p>

                        <i
                            style={{
                                width: "10%",
                                justifyContent: "center",
                                margin: "auto",
                                color: "rgb(204 4 4)",
                                cursor: "pointer",
                            }}
                            className="fa fa-times-circle"
                            onClick={() => {
                                this.setState({
                                    idSelected: ele.id,
                                    keySelected: ele.key,
                                });
                                this.setShowDeleteTextModal();
                            }}
                        ></i>
                    </div>
                </div>
            );
        });

        let isDisableAdd;
        if (this.state.isDesc === null) {
            isDisableAdd = css.isNone;
        } else if (this.state.isDesc) {
            isDisableAdd = css.isNone;
        } else {
            isDisableAdd = "";
        }

        let informationCards = (
            <>
                <div className="form-group">
                    <div
                        className="d-flex flex-row"
                        style={{
                            width: "100%",
                            borderRadius: "5px",

                            marginBottom: "1rem",
                        }}
                    >
                        <p
                            style={{
                                width: "70%",
                                margin: "auto",
                                paddingLeft: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {t(this.state.transPage + "seo_keywords")}
                        </p>
                        <button
                            type="button"
                            class="btn-sm btn btn-default"
                            onClick={() => {
                                this.setState({ modalName: "SEO Keywords" });
                                this.setShowAddTextModal();
                            }}
                        >
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </button>
                    </div>

                    {isKeywords}
                </div>

                <div style={{ marginTop: "2rem" }}>
                    <div
                        className="d-flex flex-row"
                        style={{
                            width: "100%",
                            borderRadius: "5px",
                            marginBottom: "1rem",
                        }}
                    >
                        <p
                            style={{
                                width: "43%",
                                margin: "auto",
                                paddingLeft: "1rem",
                                fontWeight: "bold",
                                cursor: "pointer",
                            }}
                        >
                            {t("description")}
                        </p>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "left",
                            }}
                        >
                            <input
                                style={{ margin: "auto .5rem" }}
                                type="checkbox"
                                value={this.state.use_page_desc}
                                onChange={(event) =>
                                    this.onChangeCheckbox(
                                        "page_desc",
                                        "checkbox",
                                        event.target.checked
                                    )
                                }
                                checked={
                                    this.state.isCheckedPageDesc ? true : false
                                }
                            />{" "}
                            Use Page Description
                        </div>
                        <button
                            type="button"
                            class={[
                                "btn-sm btn btn-default",
                                isDisableAdd,
                            ].join(" ")}
                            onClick={() => {
                                this.setState({ modalName: "Desc" });
                                this.setShowAddTextModal();
                            }}
                        >
                            <i className="fa fa-edit"></i>
                        </button>
                    </div>

                    {/* <br /> */}
                </div>
                <div
                    className="d-flex flex-row"
                    style={{
                        width: "auto",
                        height: "auto",
                        border: "1px solid #e9e9e9",
                        borderRadius: "5px",
                    }}
                >
                    <p
                        style={{
                            width: "80%",
                            justifyContent: "center",
                            margin: "auto",
                            padding: "10px",
                        }}
                    >
                        {this.state.isDesc}
                    </p>

                    <i
                        style={{
                            width: "10%",
                            justifyContent: "center",
                            margin: "auto",
                            color: "#007bff",
                            cursor: "pointer",
                        }}
                        onClick={this.setShowUpdateTextModal}
                        className="fa fa-info-circle"
                    ></i>
                </div>
            </>
        );

        /* styling settingss*/
        const { isExpandle3 } = this.state;
        let isExpandFooter2, isFalse2, isExpandStatus3;
        if (isExpandle3) {
            isExpandStatus3 = css.isExpand2;
            isExpandFooter2 = css.isExpandFooter;
            isFalse2 = css.offMb;
        } else {
            isExpandStatus3 = "";
            isFalse2 = "";
            isExpandFooter2 = css.isNotExpand;
        }

        let titleStylingCards = (
            <CardTitle>
                {" "}
                <i className="fa fa-gear">&nbsp;</i>{" "}
                {t(this.state.transPage + "page_styling")} &nbsp;
                <i
                    class="fa fa-caret-down"
                    onClick={this.changeStatusExpand3}
                ></i>
            </CardTitle>
        );

        let behavior, block, behaviors, blocks;

        this.state.sectionShortcut.forEach((ele, i) => {
            if (i === 0) {
                behaviors = ele.values;
            } else {
                blocks = ele.values;
            }
        });

        if (behaviors) {
            behavior = behaviors.map((ele, i) => {
                let activeIndex;
                if (this.state.value === ele.id) {
                    activeIndex = ele.id;
                }
                return (
                    <div class="form-check" id={ele.id}>
                        <input
                            disabled={!this.state.isCheckedSectionShortcut}
                            class="form-check-input"
                            type="radio"
                            name="behavior"
                            id={ele.name}
                            value={this.state.value}
                            onChange={(event) =>
                                this.onChangeCheckboxType(
                                    "behavior",
                                    ele.id,
                                    event.target.checked
                                )
                            }
                            checked={ele.id === activeIndex ? true : false}
                        />
                        <label class="form-check-label" htmlFor={ele.name}>
                            {ele.name}
                        </label>
                    </div>
                );
            });
        }

        if (blocks) {
            block = blocks.map((s, i) => {
                let activeIndexBlock;
                if (this.state.value2 === s.id) {
                    activeIndexBlock = s.id;
                }

                return (
                    <div class="form-check" id={s.id}>
                        <input
                            disabled={!this.state.isCheckedSectionShortcut}
                            class="form-check-input"
                            type="radio"
                            name="block"
                            id={s.name}
                            value={this.state.value2}
                            onChange={(event) =>
                                this.onChangeCheckboxType(
                                    "block",
                                    s.id,
                                    event.target.checked
                                )
                            }
                            checked={s.id === activeIndexBlock ? true : false}
                        />
                        <label class="form-check-label" htmlFor={s.name}>
                            {s.name}
                        </label>
                    </div>
                );
            });
        }

        let stylingCards = (
            <>
                <div className="form-group">
                    <label>
                        {" "}
                        {t(this.state.transPage + "section_shortcut")}{" "}
                    </label>
                    <br />
                    <label
                        className="font-weight-normal"
                        htmlFor="enable-section-shortcut"
                    >
                        {t("enable")}: &nbsp;
                    </label>
                    <input
                        id="enable-section-shortcut"
                        type="checkbox"
                        onChange={(event) =>
                            this.onChangeCheckbox(
                                "section_shortcut",
                                "checkbox",
                                event.target.checked
                            )
                        }
                        checked={this.state.isCheckedSectionShortcut}
                    />
                    <div style={{ paddingLeft: "1rem" }}>
                        <b>{t("behavior")}</b>
                        {behavior}
                    </div>

                    <div style={{ paddingLeft: "1rem", marginTop: "1rem" }}>
                        <b>{t("block")}</b>
                        {block}
                    </div>
                </div>
                <div className="form-group">
                    <label> {t(this.state.transPage + "scroll_to_top")} </label>
                    <br />
                    <div>
                        <label
                            className="font-weight-normal"
                            htmlFor="enable-scroll-to-top"
                        >
                            {t("enable")}: &nbsp;
                        </label>
                        <input
                            id="enable-scroll-to-top"
                            type="checkbox"
                            name="scroll_to_top"
                            value={this.state.isScroll}
                            onChange={(event) =>
                                this.onChangeCheckbox(
                                    "scroll_to_top",
                                    "checkbox",
                                    event.target.checked
                                )
                            }
                            checked={this.state.isScroll ? true : false}
                        />
                    </div>
                </div>
            </>
        );

        let footerStyling = (
            <div className={[css.stylesFooter, isFalse2].join(" ")}>
                <Button
                    className={["float-right", isExpandFooter2].join(" ")}
                    onClick={this.updatePageSectionsStyling}
                >
                    {" "}
                    {t(this.state.transPage + "save_styling")}{" "}
                </Button>
            </div>
        );

        return (
            <>
                <Card>
                    <PageAddInformation
                        id={this.props.id}
                        widgetName={name}
                        show={this.state.ShowAddTextModal}
                        onHide={this.setShowAddTextModal}
                        getDataAll={this.getPageDetail}
                    ></PageAddInformation>

                    <PageUpdateInformation
                        widgetName={name}
                        textId={this.state.idDesc}
                        show={this.state.ShowUpdateTextModal}
                        onHide={this.setShowUpdateTextModal}
                        id={this.props.id}
                        data={this.state.isDesc}
                        getDataAll={this.getPageDetail}
                        currentDesc={curDes}
                    ></PageUpdateInformation>
                    <PageDeleteKeywords
                        widgetName={name}
                        show={this.state.ShowDeleteTextModal}
                        onHide={this.setShowDeleteTextModal}
                        id={this.props.id}
                        data={this.state.isDesc}
                        idKey={this.state.idSelected}
                        keySelected={this.state.keySelected}
                        getDataAll={this.getPageDetail}
                        currentDesc={this.state.currentDescription}
                    ></PageDeleteKeywords>
                    <CardHeader
                        className={["bg-primary", css.styleNew].join(" ")}
                    >
                        {titleSettingCardsInfo}
                        <div className="card-tools"></div>
                    </CardHeader>
                    <CardBody
                        className={[css.card_setting2, isExpandStatus2].join(
                            " "
                        )}
                    >
                        {informationCards}
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader
                        id="card_header_set"
                        className={["bg-primary", css.styleNew].join(" ")}
                    >
                        {titleSettingCards}
                        <div className="card-tools"></div>
                    </CardHeader>
                    <CardBody
                        className={[css.card_setting, isExpandStatus].join(" ")}
                    >
                        {settingCards}
                    </CardBody>
                    {footerCards}
                </Card>
                <Card>
                    <CardHeader
                        className={["bg-primary", css.styleNew].join(" ")}
                    >
                        {titleStylingCards}
                        <div className="card-tools"></div>
                    </CardHeader>
                    <CardBody
                        className={[css.card_setting2, isExpandStatus3].join(
                            " "
                        )}
                    >
                        {stylingCards}
                    </CardBody>
                    {footerStyling}
                </Card>
            </>
        );
    }
}

export default withTranslation()(PageSettings);
