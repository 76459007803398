import React, { Component } from 'react';
import swal from "sweetalert";
import { Tab, Nav, Button } from "react-bootstrap"
import Card, { CardHeader, CardBody } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content"
import Context from "./Context";
import Flag from "react-flags"

// import writeJsonFile from "write-json-file"
import SortableTree from "react-sortable-tree";
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

import LanguageApi from "./../api/LanguageApi"

// import { shortSentence } from "./../lib/StringHelper";
import { withTranslation } from "react-i18next";

import LanguageItemAddModal from "./../components/Modal/LanguageItemAddModal"
import LanguageItemDeleteConfirm from "./../components/Modal/LanguageItemDeleteConfirm"

class LanguageJSON extends Component {
    static contextType = Context;
    constructor(props) {
        super(props)

        this.state = {

            langaugeList: [],
            selectLang: {},
            selectedLanguage: "en",
            selectedKey: "",
            jsonLanguage: {},
            treeData: [],
            showModal: false,
            showConfirm: false,
            methodModal: "Add"
        }

        this.getLanguages = this.getLanguages.bind(this)
        this.fetchJSON = this.fetchJSON.bind(this)
        this.downloadJsonFile = this.downloadJsonFile.bind(this)
        this.setTreeData = this.setTreeData.bind(this)

        this.setShowModal = this.setShowModal.bind(this)
        this.jsonUpdate = this.jsonUpdate.bind(this)
        this.getJsonLang = this.getJsonLang.bind(this)
    }

    componentDidMount() {
        this.getLanguages()
        this.getJsonLang()
    }

    setShowModal() {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    setShowConfirm() {
        this.setState({
            showConfirm: !this.state.showConfirm
        })
    }

    setSelelectedLanguage(selectedLanguage) {
        this.setState({
            selectedLanguage: selectedLanguage.abbreviation,
            selectLang: selectedLanguage
        }, () => {
            this.getJsonLang()
        })
    }

    getLanguages() {
        const { languagesList } = this.context

        this.setState({
            langaugeList: languagesList,

        }, () => {
            this.setSelelectedLanguage(languagesList[0])
        })

    }

    fetchJSON() {

        fetch(`/locales/${this.state.selectedLanguage}/translation.json`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(res => {

                return res.json()
            })
            .then(res => {
                // console.log("FETCH ", res)
                this.setState({
                    jsonLanguage: res
                }, () => {
                    this.setTreeData()
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    getJsonLang() {

        const objLang = new LanguageApi()
        const { t } = this.props;

        objLang.getDetailLanguage(this.state.selectLang.id)
            .then(res => {
                this.setState({
                    jsonLanguage: JSON.parse(res.data.json)
                })
            })
            .catch(err => {
                console.log("err => ", err)
            })
    }

    jsonUpdate() {
        // console.log(" run ")
        const objLang = new LanguageApi()
        const { t } = this.props;

        objLang.languageUpdate(this.state.selectLang.id, {
            ...this.state.selectLang,
            json: JSON.stringify(this.state.jsonLanguage)
        })
            .then(res => {
                swal(
                    "Success !",
                    t(this.state.transAlert + "language"),
                    "success"
                );
            })
            .catch(err => {
                console.log("err => ", err)
            })
    }

    setTreeData() {
        var treeData = []


        for (const [key, value] of Object.entries(this.state.jsonLanguage)) {

            var c = []

            if (key === "pages") {
                var c2 = []
                Object.entries(this.state.jsonLanguage[key]).forEach((item) => {



                    if (Object.entries(this.state.jsonLanguage[key][item[0]]).length > 1) {
                        Object.entries(this.state.jsonLanguage[key][item[0]]).forEach(item1 => {

                            if (typeof item1 === "string") {
                                c2.push({
                                    title: item1
                                })
                            }



                        })
                    }

                    c.push({
                        title: item[0],
                        children: c2
                    })
                    // console.log("item => ", item)

                })
                //console.log("key ===> ",key)
            }
            treeData.push({
                title: key,
                value: value,
                children: c
            })
        }

        this.setState({
            treeData: treeData
        })

    }

    downloadJsonFile() {
        const fileData = JSON.stringify(this.state.jsonLanguage);
        const blob = new Blob([fileData], { type: "text/json" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = "translation-" + this.state.selectedLanguage + '.json';
        link.href = url;
        link.click();
    }

    render() {

        const TabLanguages = this.state.langaugeList.map(item => {
            return (<Tab.Pane eventKey={item.abbreviation}>

                <button className="btn btn-sm btn-outline-primary" style={{ margin: "20px 0", float: "right", }}>
                    <i className="fa fa-upload"> &nbsp; </i> Upload JSON
                    </button>
                <div className="clearfix" style={{ margin: "0 1%" }}></div>
                <JSONInput
                    id={'a_unique_' + item.id}
                    locale={locale}
                    height='550px'
                    width="100%"
                    placeholder={this.state.jsonLanguage}
                    onChange={(res) => {
                        this.setState({
                            jsonLanguage: res.jsObject
                        })
                    }}
                />

                <button onClick={() => this.jsonUpdate()} className="btn btn-sm btn-outline-primary" style={{ margin: "20px 0", float: "right", }}>
                    <i className="fa fa-edit">&nbsp;</i>Update JSON
                    </button>


            </Tab.Pane>)
        })

        /*
            <Nav.Item  className="" style={{ marginRight:10 }} > 
                <Nav.Link eventKey="GB" className="btn btn-xs bg-secondary" style={{padding:"2px 10px"}}>
                    <Flag name="GB" format="png" pngSize={24} shiny={false} alt="Great Britain Flag" basePath="/img/flags" title="Vietnam" /> English 
                </Nav.Link>
            </Nav.Item>
        */

        const NavLanguages = this.state.langaugeList.map(item => {
            return (<Nav.Item className="" style={{ marginRight: 10 }} onClick={() => this.setSelelectedLanguage(item)}>
                <Nav.Link eventKey={item.abbreviation} className="btn btn-xs bg-primary" style={{ padding: "2px 10px" }} >
                    <Flag name={item.flag} format="png" pngSize={24} shiny={false} alt="Vietnam Flag" basePath="/img/flags" title="English" /> {item.name}
                </Nav.Link>
            </Nav.Item>
            )
        })

        return (
                <Content>
                    <ContentHeader>
                        <h1> Language </h1>
                    </ContentHeader>
                    <ContentBody>
                        <Card>

                            <Tab.Container id="left-tabs-example" defaultActiveKey={this.state.selectedLanguage}>
                                <CardHeader>
                                    <div className="row">
                                        <div className="col-md-9">
                                            <div className="float-left" style={{ marginRight: 10 }}>Preview</div>
                                            <Nav variant="pills">
                                                {NavLanguages}
                                                <Nav.Item style={{ marginRight: 10 }} title="add new language" onClick={() => {
                                                    this.setState({
                                                        jsonLanguage: {}
                                                    })
                                                }}>
                                                    <Nav.Link eventKey="New" className="btn btn-xs bg-primary" style={{ padding: "5px 10px" }}>
                                                        <i className="fas fa-plus"></i>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                        <div className="col-md-3">
                                            <button className="float-right btn btn-sm btn-primary" onClick={() => this.downloadJsonFile()}>
                                                <i className="fa fa-download"></i> Download Json
                                            </button>
                                        </div>

                                    </div>

                                </CardHeader>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <SortableTree
                                                treeData={this.state.treeData}
                                                onChange={(treeData) => this.setState({ treeData })}
                                                onMoveNode={() => {

                                                }}
                                                generateNodeProps={({ node }) => ({
                                                    title: (
                                                        <div>
                                                            <span className="float-left">
                                                                Key : {node.title ? node.title : "undefined"}
                                                            </span>
                                                            <div className='float-right'>
                                                                <button
                                                                    title={"add_language_item"}
                                                                    className='btn btn-success btn-xs'
                                                                    style={{ margin: "0 5px" }}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            methodModal: "Add Item ",
                                                                            selectedKey: node.title,
                                                                        }, () => {
                                                                            this.setShowModal()
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className='fa fa-plus'></i>
                                                                </button>
                                                                <button
                                                                    title={"update_this_item"}
                                                                    className='btn btn-primary btn-xs'
                                                                    style={{ margin: "0 5px" }}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            methodModal: "Update",
                                                                            selectedKey: node.title,
                                                                        }, () => {
                                                                            this.setShowModal()
                                                                        })
                                                                    }}
                                                                >
                                                                    <i className='fa fa-edit'></i>
                                                                </button>
                                                                <Button
                                                                    title={"delete_this_item"}
                                                                    variant='danger'
                                                                    className='btn-xs'
                                                                    style={{ margin: "0 5px" }}
                                                                    onClick={() => {
                                                                        //this.jsonUpdate()
                                                                    }}
                                                                >
                                                                    <i className='fa fa-trash'></i>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ),
                                                })}
                                                style={{}}
                                            />
                                        </div>
                                        <div className="col-md-6" style={{ backgroundColor: "#ddd" }}>
                                            <Tab.Content >

                                                {TabLanguages}
                                                <Tab.Pane eventKey="New">
                                                    <button className="btn btn-sm btn-outline-primary" style={{ margin: "20px 0", float: "right", }}>
                                                        <i className="fa fa-upload"> &nbsp; </i> Upload JSON
                                                    </button>
                                                    <div className="clearfix" style={{ margin: "0 1%" }}></div>
                                                    <JSONInput
                                                        id={'a_unique_add'}
                                                        locale={locale}
                                                        height='550px'
                                                        width="100%"
                                                        placeholder={this.state.jsonLanguage}
                                                        onChange={(res) => {
                                                            this.setState({
                                                                jsonLanguage: res.jsObject
                                                            })
                                                        }}
                                                    />

                                                    <Button
                                                        onClick={() => console.log("hello world")}
                                                        className="btn btn-sm btn-outline-primary"
                                                        style={{ margin: "20px 0", float: "right", }}
                                                    >
                                                        <i className="fa fa-edit">&nbsp;</i>Update JSON
                                                    </Button>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                        <div >

                                        </div>

                                    </div>

                                </CardBody>

                            </Tab.Container>
                        </Card>

                        <LanguageItemAddModal
                            show={this.state.showModal}
                            methodModal={this.state.methodModal}
                            onHide={this.setShowModal}
                            selectedKey={this.state.selectedKey}
                        >

                        </LanguageItemAddModal>
                        <LanguageItemDeleteConfirm
                            show={this.state.showConfirm}
                            onHide={this.setShowConfirm}
                            selectedKey={this.state.selectedKey}
                        >

                        </LanguageItemDeleteConfirm>
                    </ContentBody>
                </Content>
        );
    }
}

export default withTranslation()(LanguageJSON);