import axios from "axios"
import config from "./../config/config"
export default class AuthApi {

    // base_api = "http://192.168.13.177:7000/"
    base_api = config.base_api
    token = config.token
    // token = "7e142a22850ebf92be390c473ce16cb4593651f7"

    login(username, password) {
        return axios.post(this.base_api+`employee-login/`,{
            username:username, 
            password:password
        },{
            params:{
               
            }
        })
    }

    logout() {
        return axios.post(this.base_api+`api-auth/logout/`)
    }

    getToken(){
        return axios.post(this.base_api+`api-auth-token/`)
    }

}