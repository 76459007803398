import React, { Component } from "react";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import PageApi from "./../../api/PageApi";
import GeneralApi from "../../api/GeneralApi";
import swal from "sweetalert";
import Context from "../../pages/Context";

class WidgetTextAddModal extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      name: "",
      content: "",
      input_type: "Small",
      text_languages: [],
      languageList: [],
      tabKey: 1,
      selectedLanguage: 1,
      idElement: 0,
      renderTabLanguage: "",
      selectedPageId: 0,
      selectedSectionId: 0,
    };
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleContentChange = this.handleContentChange.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleInputTypeChange = this.handleInputTypeChange.bind(this);
    this.renderTabLanguage = this.renderTabLanguage.bind(this);
    this.addKeywordPage = this.addKeywordPage.bind(this);
  }

  onOpenModal() {
    const { languagesList } = this.context;

    this.setState(
      {
        id: this.props.textId | 0,
        tabKey: 1,
        selectedLanguage: 1,
        languageList: languagesList,
      },
      () => {
        // console.log("languages kelar ==> ", this.state.languageList)

        if (this.state.id) {
          //alert(this.state.id)
          this.getDetailTextElement();
        } else {
          this.renderTabLanguage();
        }
      }
    );
  }

  onCloseModal() {
    this.setState(
      {
        id: 0,
        name: "",
        // languageList:[],
        tabKey: 1,
        selectedLanguage: 1,
        text_languages: [],
        renderTabLanguage: "",
      },
      () => {
        this.props.onHide();
      }
    );
  }

  handleInputTypeChange(value) {
    this.setState({
      input_type: value,
    });
  }

  handleNameChange(value) {
    this.setState({
      name: value,
    });
  }

  handleContentChange(id, value) {
    let text_languages = [...this.state.text_languages];
    let text_language = { ...text_languages[id] };
    text_language.content = value;
    text_languages[id] = text_language;

    this.setState({
      text_languages: text_languages,
    });
  }

  handleChangeTab(value) {
    this.setState(
      {
        selectedLanguage: value,
        tabKey: value,
      },
      () => {
        this.renderTabLanguage();
      }
    );
  }

  pageDescAdd() {
    const objPage = new PageApi();
    const data = this.state.text_languages.map((ele, index) => {
      return {
        languange: ele.language,
        description: ele.content,
      };
    });

    objPage
      .pageLanguages(data)
      .then((res) => {
        this.props.onHide();
        swal(
          "Success !",
          "Description has been successfully added !",
          "success"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  addKeywordPage() {
    const objPage = new PageApi();
    const data = this.state.text_languages.map((ele, index) => {
      return {
        page: parseInt(this.props.id),
        language: ele.language,
        keyword: ele.content,
      };
    });

    objPage
      .addKeywordPage(data)
      .then((res) => {
        this.props.onHide();
        this.props.getDataAll(this.props.id);
        swal(
          "Success !",
          "Description has been successfully added !",
          "success"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  renderTabLanguage() {
    const renderTabLanguage = this.state.languageList.map((item) => {
      var result = this.state.text_languages.find(
        (textLang) => item.id === textLang.language
      );

      if (!result) {
        result = {
          language: item.id,
          text_element: 4,
          content: "",
          languageName: item.name,
        };
      }

      result.languageName = item.name;
      return result;
    });

    this.setState(
      {
        text_languages: renderTabLanguage,
      },
      () => {
        console.log("text_languages : ", this.state.text_languages);
      }
    );
  }

  render() {
    var HeaderExecute = "Add";
    var ButtonExecute = (
      <Button variant="primary" onClick={() => this.addKeywordPage()}>
        <i className="fa fa-plus">&nbsp;</i>
        Add
      </Button>
    );

    if (this.state.id) {
      HeaderExecute = "Update";
      ButtonExecute = (
        <Button variant="primary" onClick={() => this.updateWidgetText()}>
          <i className="fa fa-edit">&nbsp;</i>
          Update
        </Button>
      );
    }

    let ckeditorConfig = {
      toolbar: {
        items: [
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "superscript",
          "subscript",
          "|",
          "link",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",

          "|",
          "undo",
          "redo",
        ],
      },
      table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
      },
      language: "",
    };

    const TabLanguage = this.state.text_languages.map((item, index) => {
      var TextEditor = (
        <textarea
          className="form-control"
          onChange={(event) =>
            this.handleContentChange(index, event.target.value)
          }
          value={this.state.text_languages[index].content}
        ></textarea>
      );

      if (this.state.input_type === "Big") {
        TextEditor = TextEditor = (
          <CKEditor
            id="editor"
            config={ckeditorConfig}
            editor={ClassicEditor}
            data={this.state.text_languages[index].content}
            onInit={(editor) => {
              // You can store the "editor" and use when it is needed.
              //console.log( 'Editor is ready to use! '+index, editor );
            }}
            onChange={(event, editor) => {
              const data = editor.getData();

              this.handleContentChange(index, data);
            }}
          />
        );
      }

      return (
        <Tab key={index} eventKey={item.language} title={item.languageName}>
          <br></br>

          <div className="form-group">
            <label> Content </label>
            {TextEditor}
            {/* { JSON.stringify( this.state.text_languages )  } */}
          </div>
        </Tab>
      );
    });

    return (
      <Modal
        show={this.props.show}
        onHide={() => this.onCloseModal()}
        onShow={this.onOpenModal}
        size="lg"
      >
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title>
            {HeaderExecute} {this.props.widgetName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {/* <div className="form-group">
              <label> Name </label>
              <input
                type="text"
                className="form-control"
                onChange={(event) => this.handleNameChange(event.target.value)}
                value={this.state.name}
              ></input>
            </div> */}

            <Tabs
              id="controlled-tab-example-3"
              activeKey={this.state.tabKey}
              onSelect={(k) => this.handleChangeTab(k)}
            >
              {TabLanguage}
            </Tabs>
          </form>
        </Modal.Body>
        <Modal.Footer>{ButtonExecute}</Modal.Footer>
      </Modal>
    );
  }
}

export default WidgetTextAddModal;
