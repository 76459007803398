import React, { Component } from 'react'

import Card, { CardBody, CardFooter } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content"
import TitlePages from './Elements/TitlePages'

import { GoogleMap, useJsApiLoader, LoadScript } from '@react-google-maps/api';

export default class Sample extends Component {

    constructor(props) {
        super(props)

        this.state = {

        }

        // this.handleUsernameChange = this.handleUsernameChange.bind(this)
    }

    componentDidMount() {

    }

    render() {
        const containerStyle = {
            width: '100%',
            height: '50vw'
        };

        const center = {
            lat: -3.745,
            lng: -38.523
        };
        return (
            <Content>
                <ContentHeader>
                    <TitlePages title='test' />
                </ContentHeader>
                <ContentBody>
                    <Card>
                        <LoadScript
                            googleMapsApiKey="AIzaSyA6aQOcCYM5yXimMPDdTCopJD9wT0324L8"
                        >
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={10}
                            >
                                { /* Child components, such as markers, info windows, etc. */}
                                <></>
                            </GoogleMap>
                        </LoadScript>
                    </Card>
                </ContentBody>
            </Content>
        )
    }
}
