import axios from "axios";
import config from "./../config/config";

class TemplateApi {
    // base_api = "http://192.168.13.177:7000/"
    base_api = config.base_api;
    token = config.token;

    getAllTemplate() {
        return axios.get(this.base_api + "templates/", {
            //   params: params,
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getSites(param) {
        return axios.get(this.base_api + `sites/${param}/`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getTemplateDetail(templateId) {
        return axios.get(this.base_api + `templates/${templateId}/`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getTemplateInformation(templateId) {
        return axios.get(this.base_api + `templates/${templateId}/`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    createTemplateNew(data) {
        return axios.post(this.base_api + `templates/`, data, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "multipart/form-data",
            },
        });
    }

    updateTemplateNew(id, dt) {
        return axios.put(this.base_api + `templates/${id}/`, dt, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "multipart/form-data",
            },
        });
    }

    deleteTemplateNew(data) {
        return axios.patch(
            this.base_api + `sites/unpair_template/`,
            {
                site_id: data.site_id,
                template_ids: data.template_ids,
            },
            {
                headers: {
                    Authorization: `Token ${this.token}`,
                    "Content-Type": "application/json",
                },
            }
        );
    }
    updateActiveTemplate(data) {
        return axios.patch(
            this.base_api + `sites/activate_template/`,
            {
                site_id: data.site_id,
                template_id: data.template_ids,
            },
            {
                headers: {
                    Authorization: `Token ${this.token}`,
                    "Content-Type": "application/json",
                },
            }
        );
    }
    templateAdd(dt) {
        return axios.post(this.base_api + `templates/`, dt, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "multipart/form-data",
            },
        });
    }

    templateUpdate(dt, id) {
        return axios.put(this.base_api + `templates/${id}/`, dt, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "multipart/form-data",
            },
        });
    }

    deleteTemplate(id) {
        return axios.delete(this.base_api + `templates/${id}/`, {
            data: {},
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "multipart/form-data",
            },
        });
    }

    buildTemplate(id, data) {
        return axios.post(this.base_api + `templates/${id}/run_build/`, data, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }
}

export default TemplateApi;
