import React, { Component } from 'react';
import { Button } from "react-bootstrap"
import swal from "sweetalert"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import Card, { CardHeader, CardTitle, CardBody } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content"
import ListCard from "./../components/ListCard"
import TitlePages from './Elements/TitlePages'

import SectionApi from "./../api/SectionApi"
import WidgetApi from "./../api/WidgetApi"

import WidgetAddModal from "./../components/Modal/WidgetAddModal"
import SectionWidgetDeleteConfirm from "./../components/Modal/SectionWidgetDeleteConfirm"
// import SectionWidgetPair from "../components/Modal/SectionWidgetPair"
import SectionWidgetDetailPair from "../components/Modal/SectionDetailWidgetPair"
import config from '../config/config'
import ReactGA from 'react-ga';

class WidgetListPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            section: {
                id: 0,
                name: "",
                section_widgets: [],

            },
            columns: {
                "droppable-1": {
                    id: "droppable-1",
                    title: "widgets",
                    widgetIds: []
                }
            },
            showModal: false,
            showModalDelete: false,
            showModalPair: false,
            disableDelete: true,
            selectedWidget: [],
            widgetId: 0,
            sectionWidgetId: 0

        }

        this.setWidgetId = this.setWidgetId.bind(this)
        this.setSectionWidgetId = this.setSectionWidgetId.bind(this)
        this.getSectionDetail = this.getSectionDetail.bind(this)
        this.setSelectedWidget = this.setSelectedWidget.bind(this)

        // this.updateSectionWidgetPair = this.updateSectionWidgetPair.bind(this)
        this.deleteSelectedWidget = this.deleteSelectedWidget.bind(this)

        this.setShowModal = this.setShowModal.bind(this)
        this.setShowModalDelete = this.setShowModalDelete.bind(this)
        this.setShowModalPair = this.setShowModalPair.bind(this)
    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        const sectionId = this.props.match.params.sectionId
        this.getSectionDetail(sectionId)
    }

    componentWillReceiveProps(newProps) {
        const sectionId = newProps.match.params.sectionId
        this.getSectionDetail(sectionId)
    }

    componentDidUpdate() {
        // console.log("component did render ")
    }

    setSectionWidgetId(sectionWidgetId) {
        this.setState({
            sectionWidgetId: sectionWidgetId
        }, () => {
            this.setShowModalPair()
        })
    }

    setWidgetId(widgetId) {
        this.setState({
            widgetId: widgetId
        }, () => {
            this.setShowModal()
        })
    }

    setShowModal() {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    setShowModalDelete() {
        this.setState({
            showModalDelete: !this.state.showModalDelete
        })
    }

    setShowModalPair() {

        this.setState({
            showModalPair: !this.state.showModalPair
        })
    }

    getSectionDetail(sectionId) {



        // kosongin nilai nya 
        this.setState(prevState => ({
            section: {
                ...prevState.section,
                section_widgets: []
            }

        }))

        const objSection = new SectionApi()
        // const pageId = this.props.match.params.pageId


        objSection.getDetailSection(sectionId)
            .then(res => {

                var widgets = []
                var widgetIds = []

                // console.log(res.data)
                // console.log("getSectionDetail", res.data)

                // buat drop & drag
                res.data.section_widgets.forEach((item, index) => {

                    widgets.push({
                        id: item.id,
                        index: item.index,
                        section: item.sectionId,
                        name: item.name,

                    })

                    widgetIds.push(item)
                })
                // =========================================


                this.setState(prevState => ({
                    section: {
                        ...prevState.section,
                        id: res.data.id,
                        name: res.data.name,
                        section_widgets: res.data.section_widgets
                    }
                }), () => {
                    // console.log(" hello mf => ",this.state.section)
                })

                // this.setState({
                //     section:{
                //         ...this.state.section,
                //         id:res.data.id,
                //         name:res.data.name,
                //         section_widgets:res.data.section_widgets
                //     }
                // })

                this.setState({
                    columns: {
                        ...this.state.columns,
                        ["droppable-1"]: {
                            ...this.state.columns["droppable-1"],
                            widgetIds: widgetIds
                        }
                    }
                }, () => {

                })


            })
    }

    deleteSelectedWidget() {
        const objSection = new SectionApi()
        const sectionId = this.props.match.params.sectionId

        const widgets = this.state.selectedWidget.map(item => {

            return (item.id)
        })


        objSection.sectionWidgetDelete(sectionId, widgets)
            .then(res => {

                this.setState({
                    showModalDelete: false,
                    disableDelete: true,
                    selectedWidget: [],
                })
                this.getSectionDetail(sectionId)

                swal("Success !", "You successfully delete widget on this section ", "success")

                setTimeout(() => {
                    window.location.reload()
                }, 0)

            })
            .catch(err => {
                swal("Error!", err.message, "error")
            })
    }

    setSelectedWidget(itemData, value) {
        var lastSelected = [...this.state.selectedWidget]
        var indexDelete = -1

        if (value === true) {

            lastSelected.push(itemData)
        } else {
            //console.log("jalan donk harusnya ", article , this.state.selectedArticles)
            this.state["selectedWidget"].forEach((item, index) => {
                if (item.id === itemData.id) {
                    indexDelete = index
                }
            })

            lastSelected.splice(indexDelete, 1)
            //console.log("lastArticles => ",lastArticles)
        }

        this.setState({
            "selectedWidget": lastSelected,

        }, () => {
            if (this.state["selectedWidget"].length > 0) {
                this.setState({
                    disableDelete: false,

                })
            } else {
                this.setState({
                    disableDelete: true,

                })
            }
        })
    }

    // updateSectionWidgetPair(sectionId, widgetId){
    //     const objSection = new SectionApi()

    //     const thisWidgetId = [widgetId]
    //     // const widgets = selectedWidget.map(item => {
    //     //     return (item.id)
    //     // })

    //     return objSection.sectionWidgetPair(sectionId, thisWidgetId)

    // }

    onDragEnd(result) {


        //console.log("result ==> ",result)
        const { destination, source } = result
        const sectionId = this.props.match.params.sectionId

        if (!destination) {
            return
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return
        }

        const column = this.state.columns[source.droppableId]
        // console.log(" column ==>",column)

        // // process.exit()
        const newWidgetIds = Array.from(column.widgetIds)
        //console.log( "newSectionIds 1 ==> ",newSectionIds )
        newWidgetIds.splice(source.index, 1)
        newWidgetIds.splice(destination.index, 0, column.widgetIds[source.index])
        //console.log( "newSectionIds 2 ==> ",newSectionIds )



        const objWidget = new WidgetApi()

        // const ids = this.state.columns[source.droppableId].sectionIds.map(item => {
        //     return item.id
        // })
        const ids = newWidgetIds.map(item => {
            return item.id
        })

        //console.log (" input => ",ids)


        objWidget.widgetIndexUpdate(sectionId, ids)
            .then(res => {

                //console.log(" result ==> ",res.data, newWidgetIds)

                const result = res.data.map(item => {
                    const findSec = newWidgetIds.find(row => row.id === item.id)
                    return ({
                        ...findSec,
                        index: item.index
                    })
                })

                // console.log(result)


                // ===== pengubah state ====

                const newColumn = {
                    ...column,
                    widgetIds: result
                }

                const newColumnState = {
                    ...this.state.columns,
                    [source.droppableId]: newColumn
                }

                this.setState({
                    columns: newColumnState
                })

                // ======= pengubah state =============


                //this.getPageDetail(pageId)
            })
            .catch(err => {
                console.log(err)
            })



    }

    render() {


        //const WidgetList = this.state.section.widgets.map((item,index) => {
        const WidgetList = this.state.columns["droppable-1"].widgetIds.map((item, index) => {

            // console.log("contradiction => ", item)

            var widgetFormatData = []

            var icon = "fa fa-puzzle-piece"


            if (item.category_name === "Article") {
                widgetFormatData.push(item.category_name)

            }
            if (item.category_name === "Media") {
                widgetFormatData.push(item.category_name)

            }
            if (item.category_name === "Text") {
                widgetFormatData.push(item.category_name)

            }

            let linkTo = (item.category_name === "Article" && item.is_internal === false) ?
                `/widget/detail/${item.widget}/?section_widget=${item.id}&section=${item.section}&name=${item.name}`
                :
                `/widget/detail/${item.widget}/?section_widget=${item.id}`

            return (
                <Draggable draggableId={"draggable-" + index} index={index} key={index}>
                    {(provided, snapshot) => (
                        <div
                            key={item.id}
                            className="col-md-2 float-left"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        // disableinteractiveelementblocking
                        >

                            <ListCard

                                data={{
                                    name: item.name, id: item.id,
                                    category_name: item.category_name,
                                    index: item.index,
                                    is_internal: item.is_internal
                                }}
                                tools={true}
                                type={"element"}
                                items={widgetFormatData}
                                linkTo={linkTo}
                                setSelectedItem={this.setSelectedWidget}
                                setItemId={this.setSectionWidgetId}
                            ></ListCard>
                        </div>
                    )}
                </Draggable>

            )
        })

        return (

            <Content>
                <ContentHeader></ContentHeader>
                <ContentBody>
                    <Card>
                        <CardHeader>
                            <CardTitle>

                                <div> Section Name : <b>{this.state.section.name}</b> </div>
                                <div> Id : {this.state.section.id}</div>
                                <div> Index : {this.state.section.index}</div>
                                <div> Menu : {this.state.section.menu}</div>
                            </CardTitle>
                            <div className="card-tools">
                                <Button className="btn btn-danger" disabled={this.state.disableDelete} onClick={this.setShowModalDelete} style={{ marginRight: 5 }}>
                                    <i className="fa fa-trash">&nbsp;</i>
                                        Delete Selected Widget
                                    </Button>
                                <Button className="btn btn-primary" onClick={() => {
                                    this.setState({
                                        sectionWidgetId: 0,
                                        widgetId: 0,
                                    }); this.setShowModalPair()
                                }} style={{ marginRight: 5 }}>
                                    <i className="fa fa-plus">&nbsp;</i>
                                        Pair Widget
                                    </Button>
                                <Button className="btn btn-primary" onClick={() => {
                                    this.setState({
                                        sectionWidgetId: 0,
                                        widgetId: 0,
                                    }, () => this.setShowModal());
                                }}>
                                    <i className="fa fa-plus">&nbsp;</i>
                                        Add Widget
                                    </Button>

                            </div>
                        </CardHeader>
                        <CardBody>
                            <p>Widget List :</p>
                            <DragDropContext
                                className="row"
                                // onBeforeCapture={this.onBeforeCapture}
                                // onBeforeDragStart={this.onBeforeDragStart}
                                // onDragStart={this.onDragStart}
                                // onDragUpdate={() => console.log("drag update ")}
                                onDragEnd={(result) => this.onDragEnd(result)}
                            >
                                <Droppable droppableId="droppable-1" direction="horizontal" type="WIDGET">
                                    {(provided, snapshot) => (
                                        <div

                                            ref={provided.innerRef}
                                            style={{ backgroundColor: snapshot.isDraggingOver ? 'white' : 'white', height: "100%" }}
                                            {...provided.droppableProps}
                                        >
                                            {WidgetList}
                                            {provided.placeholder}
                                        </div>

                                    )}
                                </Droppable>
                            </DragDropContext>

                            <div className="clearfix"></div>
                        </CardBody>

                    </Card>
                </ContentBody>
                <WidgetAddModal
                    widgetId={this.state.widgetId}
                    section={this.state.section}
                    show={this.state.showModal}
                    onHide={this.setShowModal}

                    selectedWidget={this.state.selectedWidget}
                    getSectionDetail={this.getSectionDetail}

                ></WidgetAddModal>
                <SectionWidgetDeleteConfirm
                    section={this.state.section}
                    show={this.state.showModalDelete}
                    onHide={this.setShowModalDelete}
                    getSectionDetail={this.getSectionDetail}
                    selectedWidget={this.state.selectedWidget}
                    deleteSelectedWidget={this.deleteSelectedWidget}
                ></SectionWidgetDeleteConfirm>
                {/* <SectionWidgetPair
                        show={this.state.showModalPair}
                        onHide={this.setShowModalPair}
                        selectedWidget={this.state.selectedWidget}
                        setSelectedWidget={this.setSelectedWidget}
                        getSectionDetail={this.getSectionDetail}
                        section={this.state.section}
                    >
                    </SectionWidgetPair> */}
                <SectionWidgetDetailPair
                    id={this.state.sectionWidgetId}
                    show={this.state.showModalPair}
                    onHide={this.setShowModalPair}
                    selectedWidget={this.state.selectedWidget}
                    setSelectedWidget={this.setSelectedWidget}
                    getSectionDetail={this.getSectionDetail}
                    section={this.state.section}
                >
                </SectionWidgetDetailPair>

            </Content>

        );
    }
}

export default WidgetListPage;