import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"

class ArticleStatusConfirm extends Component {
    constructor(props){
        super(props)

        this.handleChangeStatus = this.handleChangeStatus.bind(this)
    }

    handleChangeStatus(){
        this.props.changeStatus()
    }

    render() {

        const listArticleName = this.props.selectedArticles.map(item => {
            return (<li key={item.id}>{ item.name }</li>)
        })

        return (
            <Modal show={this.props.show} onHide={() => this.props.onHide()}>
                <Modal.Header closeButton className="bg-primary">
                    <Modal.Title> Confirm Change Status </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure want to change these article status ?</p> 
                    <ul>
                        {listArticleName}
                    </ul>          
                </Modal.Body>
                <Modal.Footer>
                  
                    <Button variant="primary" onClick={() => this.handleChangeStatus()}>
                        <i className="fa fa-check">&nbsp;</i>
                         Change Status
                    </Button>   
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ArticleStatusConfirm;