import React, { Component } from 'react'
import { Modal, Card, Button } from "react-bootstrap"
import swal from 'sweetalert'
import AlertHelper from "./../../lib/AlertHelper"
import { withTranslation } from 'react-i18next';


import SiteApi from '../../api/SiteApi'
import SitemapApi from '../../api/SitemapApi';


class SiteAddModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            transPage: "modals.site_add.",
            isLoadingModal: false,
            activeSiteData: false,
            isSuccess: false,
        }
        this.addSite = this.addSite.bind(this)
        this.updateSite = this.updateSite.bind(this)
        this.submitSitemap = this.submitSitemap.bind(this)
    }

    componentDidMount() {
        this.sitemapApi = new SitemapApi()
    }

    onShowModal() {

    }

    onCloseModal() {

    }

    addSite() {
        const siteApi = new SiteApi()

        this.setState({
            loadingModal: <div className="overlay d-flex justify-content-center align-items-center">
                <i className="fas fa-2x fa-sync fa-spin"></i>
            </div>
        })

        const newAsset = {
            name: document.getElementById("site-name").value,
            url: document.getElementById("site-url").value,
            api_url: document.getElementById("site-api-url").value,
        }

        siteApi.addSite(newAsset)
            .then(res => {
                this.props.getSites()
                swal("Success !", "You successfully added new Site", "success")
                    .then(() => {
                        this.setState({ isLoadingModal: true })
                        this.sitemapApi.logIn()
                            .then(() => {
                                this.submitSitemap(newAsset.url, newAsset.url + "sitemap.xml")
                                    .then(this.props.onHide)
                            })
                    })
            })
            .catch(err => {
                console.log("err => ", err)
                swal("Error !", err.message, "error")
                this.setState({ isLoadingModal: false }, this.props.onHide)
            })
    }

    updateSite() {
        const siteApi = new SiteApi()
        const activeSiteData = this.props.activeSiteData

        this.setState({
            isLoadingModal: true
        })

        const updateAsset = {
            name: document.getElementById("site-name").value,
            url: document.getElementById("site-url").value,
            api_url: document.getElementById("site-api-url").value,
        }

        if (activeSiteData) {
            siteApi.updateSite(activeSiteData.id, updateAsset)
                .then(res => {
                    this.props.getSites()
                    swal("Success !", "You successfully update Site", "success")
                        .then(() => {
                            if (activeSiteData.url !== updateAsset.url) {
                                this.setState({ isLoadingModal: true })
                                this.sitemapApi.logIn()
                                    .then(() => {
                                        this.submitSitemap(updateAsset.url, updateAsset.url + "sitemap.xml")
                                            .then(() => {
                                                this.setState({ isLoadingModal: false }, this.props.onHide)
                                            })
                                    })
                            }
                        })
                    if (activeSiteData.url === updateAsset.url) {
                        this.setState({ isLoadingModal: false })
                        this.props.onHide()
                    }
                })
                .catch(err => {
                    console.log("err => ", err)
                    swal("Error !", err.message, "error")
                    this.setState({ isLoadingModal: false }, this.props.onHide)
                })
        }
    }

    submitSitemap(siteUrl, feedPath) {
        const nonWwwUrl = this.removeWww(siteUrl);
        const nonWwwfeedPath = this.removeWww(feedPath)
        if (siteUrl.indexOf("://www.") !== -1) {
            this.sitemapApi.submitSitemap(nonWwwUrl, nonWwwfeedPath)
                .then(response => { })
        }

        return this.sitemapApi.submitSitemap(siteUrl, feedPath)
            .then(response => {
                swal("Success !", "You successfully submitted a sitemap to Google Search Console ", "success")
            },
                function (err) {
                    const errorMessage = err.result.error.message
                    swal("Error !", errorMessage, "error")
                })
            .then(() => {
                this.setState({ isLoadingModal: false })
            })
    }

    removeWww(url) {
        return url.replace('://www.', '://');
    }

    render() {
        const { t } = this.props;
        const activeSiteData = this.props.activeSiteData

        return (
            <Modal show={this.props.show} onHide={() => this.props.onHide()} size="md" onShow={() => this.onShowModal()}>
                {this.state.isLoadingModal ?
                    <div className="overlay d-flex justify-content-center align-items-center">
                        <i className="fas fa-2x fa-sync fa-spin"></i>
                    </div>
                    :
                    null
                }
                <Modal.Header closeButton className="bg-primary">
                    {t(this.state.transPage + "create_new_site")}
                </Modal.Header>

                <Modal.Body>
                    <div className="form-group">
                        <label> {t(this.state.transPage + "site_name")} </label>
                        <input id="site-name" className="form-control" type="text" defaultValue={activeSiteData ? activeSiteData.name : ""}></input>
                    </div>
                    <div className="form-group">
                        <label> {t(this.state.transPage + "site_url")} </label>
                        <input id="site-url" className="form-control" type="text" defaultValue={activeSiteData ? activeSiteData.url : ""}></input>
                    </div>
                    <div className="form-group">
                        <label> {t(this.state.transPage + "api_url")} </label>
                        <input id="site-api-url" className="form-control" type="text" defaultValue={activeSiteData ? activeSiteData.api_url : ""}></input>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={this.props.onHide}> <i className="fa fa-times">&nbsp;</i> {t(this.state.transPage + "cancel")}  </Button>
                    <Button onClick={activeSiteData ? this.updateSite : this.addSite}> <i className={activeSiteData ? "fa fa-edit" : "fa fa-plus"}>&nbsp;</i> {activeSiteData ? t(this.state.transPage + "update") : t(this.state.transPage + "create")}  </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default withTranslation()(SiteAddModal);