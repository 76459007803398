import React, { Component } from "react";
import swal from "sweetalert";
import { Button } from "react-bootstrap";
import SortableTree from "react-sortable-tree";

// import ValueContext from '../components/section/Context'

import TitlePages from "./Elements/TitlePages";
import Card, { CardHeader, CardBody } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content";
import PageAddModal from "../components/Modal/PageAddModal";
import PageDeleteConfirm from "../components/Modal/PageDeleteConfirm";

import SiteApi from "./../api/SiteApi";
import PageApi from "./../api/PageApi";
import TemplateApi from "./../api/TemplateApi";

import { shortSentence } from "./../lib/StringHelper";
import { withTranslation } from "react-i18next";
import UserContext from "./Context";

class SiteStructure extends Component {
    // static contextType = ValueContext
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            site: {},
            template: {},
            pageId: 0,
            selectedLang: localStorage.getItem("lang"),
            //pages:[],
            selectedPages: [],
            templateInformation: [],
            disableDelete: true,
            showModal: false,
            showModalConfirm: false,
            buildType: "",

            treeData: [],
            treeDataTable: [],
            parent: null,

            overlay: "",
            isLoading: false,
            transAlert: "alert.",
            activeTemplate: 0,
        };

        // this.getCompanyDetail = this.getCompanyDetail.bind(this)
        // this.getAllTemplates = this.getAllTemplates.bind(this)
        // this.selectedTemplate = this.selectedTemplate.bind(this)
        this.getSiteStructure = this.getSiteStructure.bind(this);
        this.getSiteDetail = this.getSiteDetail.bind(this);
        this.getTemplatePage = this.getTemplatePage.bind(this);
        // this.getSitePages = this.getSitePages.bind(this)
        //this.getTemplatePage = this.getTemplatePage.bind(this)
        this.deleteSelectedPage = this.deleteSelectedPage.bind(this);
        this.siteStructureUpdate = this.siteStructureUpdate.bind(this);

        this.getTemplateInformation = this.getTemplateInformation.bind(this);

        this.setLoading = this.setLoading.bind(this);
        this.setOverlay = this.setOverlay.bind(this);
        this.setSelectedPage = this.setSelectedPage.bind(this);
        this.setShowModal = this.setShowModal.bind(this);
        this.setShowModalConfirm = this.setShowModalConfirm.bind(this);

        this.buildTemplate = this.buildTemplate.bind(this);

        this.setPageId = this.setPageId.bind(this);
    }

    componentDidMount() {
        this.setState(
            {
                selectedLang: localStorage.getItem("lang"),
                activeTemplate: localStorage.getItem("templateId"),
            },
            () => {
                this.getTemplatePage();
                this.getSiteStructure();
                this.getSiteDetail();
                this.getTemplateInformation();
            }
        );
    }

    componentDidUpdate() {
        const { activeTemplate } = this.context;

        if (this.state.selectedLang !== localStorage.getItem("lang")) {
            this.getSiteStructure();
        } else if (activeTemplate !== this.state.activeTemplate) {
            this.setState(
                {
                    activeTemplate: activeTemplate,
                },
                this.getSiteStructure
            );
            this.getTemplateInformation();
        }

        // if(localStorage.getItem("siteId") !== this.state.site.id && localStorage.getItem("template") !== this.state.template.id) {
        //     this.getSitePages()
        //     this.getSiteDetail()
        // }
    }

    setShowModal() {
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    setShowModalConfirm() {
        this.setState({
            showModalConfirm: !this.state.showModalConfirm,
        });
    }

    setLoading() {
        this.setState(
            {
                isLoading: !this.state.isLoading,
            },
            () => {
                this.setOverlay();
            }
        );
    }

    setOverlay() {
        var overlay = "";
        if (this.state.isLoading) {
            overlay = (
                <div className="overlay d-flex justify-content-center align-items-center">
                    <i className="fa fa-2x fa-sync fa-spin"></i>
                </div>
            );
        }

        this.setState({
            overlay: overlay,
        });
    }

    getSiteDetail() {
        const objSite = new SiteApi();

        const siteId = localStorage.getItem("siteId");

        objSite.getSiteDetail(siteId ? siteId : 5).then((res) => {
            this.setState({
                site: res.data,
            });
        });
    }

    getTemplatePage(templateName) {
        const objTemplate = new TemplateApi();

        const templateId = localStorage.getItem("templateId");

        objTemplate.getTemplateDetail(templateId).then((res) => {
            this.setState({
                template: res.data,
            });
        });
    }

    getTemplateInformation() {
        const objTemplate = new TemplateApi();

        const templateId = localStorage.getItem("templateId");

        objTemplate.getTemplateInformation(templateId).then((res) => {
            this.setState({
                templateInformation: res.data.template_information,
            });
        });
    }

    getSiteStructure() {
        const objSite = new SiteApi();

        const templateId =
            this.props.match.params.templateId |
            localStorage.getItem("templateId");

        const params = {
            language: localStorage.getItem("lang"),
        };

        // alert(JSON.stringify(params))

        objSite.getSiteStructure(templateId, params).then((res) => {
            res.data.expanded = true;
            const newFormat = res.data.pages.map((item) => {
                return {
                    ...item,
                    expanded: true,
                };
            });

            this.setState(
                {
                    treeData: newFormat,
                    selectedLang: localStorage.getItem("lang"),
                },
                () => {}
            );
        });
    }

    handleBuildTypeChange(value) {
        this.setState({
            buildType: value,
        });
    }

    buildTemplate() {
        this.setLoading();
        const objTemplate = new TemplateApi();
        const siteId = localStorage.getItem("siteId");
        const templateId = localStorage.getItem("templateId");

        const data = {
            state: this.state.buildType,
            site_id: siteId,
        };

        objTemplate
            .buildTemplate(templateId, data)
            .then((res) => {
                swal("Success !", res.data.message, "success");
                this.setLoading();
                this.getTemplateInformation();
            })
            .catch((err) => {
                swal("Error !", err.message, "error");
                this.setLoading();
            });
    }

    setSelectedPage(itemData, value) {
        var lastSelected = [...this.state.selectedPages];
        var indexDelete = -1;

        if (value === true) {
            lastSelected.push(itemData);
        } else {
            //console.log("jalan donk harusnya ", article , this.state.selectedArticles)
            this.state.selectedPages.forEach((item, index) => {
                if (item.id === itemData.id) {
                    indexDelete = index;
                }
            });

            lastSelected.splice(indexDelete, 1);
            //console.log("lastArticles => ",lastArticles)
        }

        this.setState(
            {
                selectedPages: lastSelected,
            },
            () => {
                console.log(this.state.selectedPages);

                if (this.state.selectedPages.length > 0) {
                    this.setState({
                        disableDelete: false,
                    });
                } else {
                    this.setState({
                        disableDelete: true,
                    });
                }
            }
        );
    }

    setPageId(value) {
        this.setState({
            pageId: value,
        });

        this.setShowModal();
    }

    deleteSelectedPage() {
        const { t } = this.props;
        const objPage = new PageApi();

        const pages = this.state.selectedPages.map((item) => {
            return item.id;
        });

        objPage
            .pageDelete(pages)
            .then((res) => {
                this.setState({
                    showModalConfirm: false,
                    disableDelete: true,
                    selectedPages: [],
                });
                // this.getSitePages()
                this.getSiteStructure();

                swal(
                    "Success !",
                    t(
                        this.state.transAlert +
                            "delete_page_in_template_success"
                    ),
                    "success"
                );

                setTimeout(() => {
                    window.location.reload();
                }, 0);
            })
            .catch((err) => {
                swal("Error!", err.message, "error");
            });
    }

    siteStructureUpdate() {
        //console.log(" input => ",this.state.treeData)

        const siteObj = new SiteApi();
        const temp = localStorage.getItem("templateId");
        const data = {
            page_update: this.state.treeData,
        };

        siteObj
            .updateSiteStructureNew(temp, data)
            .then((res) => {
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        // const contextValue = this.context
        // console.log(contextValue)
        const { t } = this.props;
        const { templateInformation } = this.state;

        let templateInfo;
        if (templateInformation.length > 0) {
            templateInfo = templateInformation.map((t) => {
                return (
                    <div className="form-group">
                        <label>{t.name} : </label>
                        <a href={t.domain} target="_blank" rel="noreferrer">
                            {" "}
                            {t.domain}
                        </a>
                    </div>
                );
            });
        }

        return (
            <Content>
                <ContentHeader>
                    <TitlePages title={t("pages.site_structure.title")} />
                </ContentHeader>

                <ContentBody>
                    <Card>
                        {this.state.overlay}
                        <CardHeader>
                            <h3 className="card-title">
                                {t("site")} : {this.state.site.name}
                                <br></br>
                                {t("template")} : {this.state.template.name}
                                <br></br>
                            </h3>

                            <div className="card-tools">
                                {/* <Button variant="danger" disabled={this.state.disableDelete} onClick={() =>{ this.setShowModalConfirm()}} style={{marginRight:5}}>
                                    <i className="fa fa-trash">&nbsp;</i>
                                    Delete Selected
                                </Button> */}
                                <div className="row">
                                    <select
                                        className="form-control col-md-5"
                                        onChange={(event) =>
                                            this.handleBuildTypeChange(
                                                event.target.value
                                            )
                                        }
                                    >
                                        <option value="">
                                            {" "}
                                            --{" "}
                                            {t(
                                                "pages.site_structure.select_build"
                                            )}{" "}
                                            --
                                        </option>
                                        <option value="preview">
                                            {" "}
                                            {t(
                                                "pages.site_structure.preview"
                                            )}{" "}
                                        </option>
                                        <option value="publish">
                                            {" "}
                                            {t(
                                                "pages.site_structure.publish"
                                            )}{" "}
                                        </option>
                                    </select>
                                    &nbsp;
                                    <Button
                                        className="float-right"
                                        onClick={() => this.buildTemplate()}
                                    >
                                        <i className="fa fa-industry">&nbsp;</i>
                                        {t("build")}
                                    </Button>
                                    &nbsp;
                                    <Button
                                        disabled={
                                            this.state.treeData.length > 0
                                        }
                                        className="float-right"
                                        onClick={() => {
                                            this.setState({ pageId: 0 }, () =>
                                                this.setShowModal()
                                            );
                                        }}
                                    >
                                        <i className="fa fa-plus">&nbsp;</i>
                                        {t("pages.site_structure.add_page")}
                                    </Button>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody style={{ height: "100vh" }}>
                            <div
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "relative",
                                }}
                            >
                                <SortableTree
                                    treeData={this.state.treeData}
                                    onChange={(treeData) =>
                                        this.setState({ treeData })
                                    }
                                    onMoveNode={() => {
                                        this.siteStructureUpdate();
                                    }}
                                    generateNodeProps={({ node }) => ({
                                        title: (
                                            <div title={node.name}>
                                                <span className="float-left">
                                                    {shortSentence(
                                                        node.name,
                                                        15
                                                    ) + " ... "}
                                                </span>
                                                <div className="float-right">
                                                    <button
                                                        title="add Child"
                                                        className="btn btn-success btn-xs"
                                                        style={{
                                                            margin: "0 5px",
                                                        }}
                                                        onClick={() => {
                                                            //console.log("parent page ==> ", node.id)

                                                            this.setState(
                                                                {
                                                                    pageId: 0,
                                                                    parent: node.id,
                                                                },
                                                                () => {
                                                                    this.setShowModal();
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <i className="fa fa-plus"></i>
                                                    </button>
                                                    <button
                                                        title="Update this item"
                                                        className="btn btn-primary btn-xs"
                                                        style={{
                                                            margin: "0 5px",
                                                        }}
                                                        onClick={() => {
                                                            console.log(
                                                                "pageId",
                                                                node.id
                                                            );
                                                            this.setState(
                                                                {
                                                                    pageId: node.id,

                                                                },
                                                                () =>
                                                                    this.setShowModal()
                                                            );
                                                        }}
                                                    >
                                                        <i className="fa fa-edit"></i>
                                                    </button>
                                                    <Button
                                                        disabled={
                                                            node.parent ===
                                                            undefined
                                                        }
                                                        title="Delete this item"
                                                        variant="danger"
                                                        className="btn-xs"
                                                        style={{
                                                            margin: "0 5px",
                                                        }}
                                                        onClick={() => {
                                                            this.setState(
                                                                {
                                                                    selectedPages:
                                                                        [node],
                                                                },
                                                                () =>
                                                                    this.setShowModalConfirm()
                                                            );
                                                        }}
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Button>
                                                </div>
                                            </div>
                                        ),
                                    })}
                                />
                                <div
                                    style={{
                                        width: "35%",
                                        position: "absolute",
                                        top: "0",
                                        right: "2rem",
                                        zIndex: "2",
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "relative",
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: "1.25rem",
                                            border: "1px solid black",
                                        }}
                                    >
                                        <label>Template Information</label>
                                        {templateInfo}
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </CardBody>
                    </Card>
                </ContentBody>

                <PageAddModal
                    pageId={this.state.pageId}
                    parent={this.state.parent}
                    show={this.state.showModal}
                    onHide={this.setShowModal}
                ></PageAddModal>
                <PageDeleteConfirm
                    selectedPages={this.state.selectedPages}
                    show={this.state.showModalConfirm}
                    onHide={this.setShowModalConfirm}
                    deleteSelectedPage={this.deleteSelectedPage}
                ></PageDeleteConfirm>
            </Content>
        );
    }
}

export default withTranslation()(SiteStructure);
