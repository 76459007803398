import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"
import swal from "sweetalert"
import AlertHelper from "./../../lib/AlertHelper"

import WidgetApi from "../../api/WidgetApi"
import PageApi from "../../api/PageApi"
import SectionApi from "../../api/SectionApi"
import GeneralApi from "../../api/GeneralApi"
import { withTranslation } from 'react-i18next';
import Context from "../../pages/Context"

class WidgetAddNew extends Component {
    static contextType = Context;
    constructor(props) {
        super(props)
        this.state = {
            widgetId: this.props.widgetId,
            name: "",
            category: "",
            widgetCategories: [],
            sectionId: "",
            categoryDisable: false,

            urlType: "internal",
            widgetType: "internal",
            pages: [],
            sections: [], // based on pages
            languageList: [],
            urlLanguages: [],
            urlId: 0,

            selectedPageId: null,
            selectedSectionId: null,
            transPage: "modals.widget_add.",
            transAlert: "alert."
        }

        this.getDetailWidget = this.getDetailWidget.bind(this)
        this.getWidgetCategories = this.getWidgetCategories.bind(this)
        this.getPages = this.getPages.bind(this)
        this.getSectionByPage = this.getSectionByPage.bind(this)

        this.handleChangeName = this.handleChangeName.bind(this)
        this.handleChangeCategory = this.handleChangeCategory.bind(this)
        this.handleWidgetAdd = this.handleWidgetAdd.bind(this)
        this.handleWidgetUpdate = this.handleWidgetUpdate.bind(this)
        this.handleWidgetType = this.handleWidgetType.bind(this)
        this.handleUrlTypeChange = this.handleUrlTypeChange.bind(this)
        this.handleUrlExternalChange = this.handleUrlExternalChange.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.handleSectionChange = this.handleSectionChange.bind(this)

        this.onOpenModal = this.onOpenModal.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)

        this.clearInternal = this.clearInternal.bind(this)
        this.clearExternal = this.clearExternal.bind(this)

    }

    handleChangeName(value) {
        this.setState({
            name: value
        })
    }

    handleChangeCategory(value) {
        this.setState({
            category: value
        })
    }

    handleWidgetType(value) {
        this.setState({
            widgetType: value
        })
    }

    onOpenModal() {
        const { languagesList } = this.context;

        this.setState({
            widgetId: this.props.widgetId,

        }, () => {

            if (this.state.widgetId) {

                this.setState({
                    categoryDisable: true,
                    languageList: languagesList
                }, () => {
                    this.getDetailWidget()
                })

            } else {
                this.getPages()
                this.setState({
                    languageList: languagesList
                }, () => {
                    var urlLangList = this.state.languageList.map(item => {

                        var result = {
                            id: null,
                            language: item.id,
                            languageName: item.name,
                            path: ""
                        }

                        return result
                    })

                    this.setState({
                        urlLanguages: urlLangList
                    })
                })

            }
        })

        this.getWidgetCategories()

    }

    onCloseModal() {
        this.setState({
            name: "",
            category: "",
            urlType: "internal",
            selectedPageId: null,
            selectedSectionId: null,
            languageList: [],
            urlLanguages: [],
        }, () => {
            this.props.onHide()
        })
    }

    getPages() {
        const objPage = new PageApi()

        const params = {
            language: localStorage.getItem("lang"),
            template: localStorage.getItem("templateId"),
            max_size: true
        }

        objPage.pageLanguageList(params)
            .then(res => {

                // console.log(res.data)

                this.setState({
                    pages: res.data.results
                }, () => {
                    if (this.state.selectedPageId) {
                        this.getSectionByPage()
                    }
                })
            })
    }

    getSectionByPage() {
        const objSection = new SectionApi()

        const params = {
            language: localStorage.getItem("lang"),
            page_id: this.state.selectedPageId,
            max_size: true
        }

        objSection.sectionLanguage(params)
            .then(res => {

                // console.log(" heheh ",res.data)

                this.setState({
                    sections: res.data.results
                })
            })
    }

    handlePageChange(value) {
        if (!value) {
            this.setState({
                selectedSectionId: null,
                sections: []
            }, () => {
                //this.getSectionByPage()
            })
        } else {
            this.setState({
                selectedPageId: value
            }, () => {
                this.getSectionByPage()
            })

        }
    }

    handleSectionChange(value) {
        this.setState({
            selectedSectionId: value
        })
    }

    handleWidgetAdd() {
        const { t } = this.props;
        const objSection = new SectionApi()

        const urls = this.state.urlLanguages.map(item => {
            return {
                id: null,
                language: item.language,
                page: !this.state.selectedPageId ? null : this.state.selectedPageId,
                section: !this.state.selectedSectionId ? null : this.state.selectedSectionId,
                path: item.path
            }
        })

        let data
        if (this.state.WidgetType === "internal") {
            data = {
                name: this.state.name,
                is_internal: this.state.WidgetType === "internal" ? "True" : "False",
                category: this.state.category,
                urls: urls,
                template: parseInt(localStorage.getItem('templateId'))
            }
        } else if (this.state.WidgetType === "external") {
            data = {
                name: this.state.name,
                is_internal: this.state.WidgetType === "internal" ? "True" : "False",
                category: this.state.category,
                urls: urls
            }
        }

        // console.log(" add widget => ",data)

        objSection.sectionWidgetAdd(data)
            .then(res => {

                if (!this.props.sectionWidgetPair) {
                    this.props.getAllWidgets()
                }

                this.props.onHide()
                swal("Success !", t(this.state.transAlert + "widget_add_success"), "success")

            })
            .catch(err => {
                const objAlert = new AlertHelper()
                const resErr = err?.response?.data ? err?.response?.data : err.message
                //console.log(" Error => ",err?.response?.data)
                objAlert.errorHandlerAlert(resErr)
            })
    }

    handleWidgetUpdate() {
        const { t } = this.props;
        const objSection = new SectionApi()

        const urls = this.state.urlLanguages.map(item => {

            // console.log(" item => ",item)

            return {
                id: item.id,
                language: item.language,
                page: !this.state.selectedPageId ? null : this.state.selectedPageId,
                section: !this.state.selectedSectionId ? null : this.state.selectedSectionId,
                path: item.path
            }
        })

        const data = {
            id: this.state.widgetId,
            name: this.state.name,
            category: this.state.category,
            // is_internal:this.state.urlType === "internal" ? "True" : "False",
            urls: urls
        }

        objSection.sectionWidgetUpdate(data)
            .then(res => {
                if (!this.props.sectionWidgetPair) {
                    this.props.getAllWidgets()
                }

                this.props.onHide()
                swal("Success !", t(this.state.transAlert + "widget_update_success"), "success")

            })
            .catch(err => {
                const objAlert = new AlertHelper()
                const resErr = err?.response?.data ? err?.response?.data : err.message
                //console.log(" Error => ",err?.response?.data)
                objAlert.errorHandlerAlert(resErr)
            })
    }

    handleUrlTypeChange(value) {

        const _this = this

        this.setState({
            urlType: value
        }, () => {
            // _this.getDetailWidget()
            if (this.state.urlType === "internal") {
                _this.clearExternal()
            }
            else {
                _this.clearInternal()
            }

        })
    }

    handleUrlExternalChange(id, value) {
        let urlLanguages = [...this.state.urlLanguages]
        let urlLanguage = { ...urlLanguages[id] }
        urlLanguage.path = value
        urlLanguages[id] = urlLanguage

        this.setState({
            urlLanguages: urlLanguages
        })
    }

    getWidgetCategories() {
        const obj = new WidgetApi()

        obj.getWidgetCategories()
            .then(res => {
                this.setState({
                    widgetCategories: res.data.results || []
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getDetailWidget() {
        const objWidget = new WidgetApi()

        objWidget.getDetailWidget(this.state.widgetId)
            .then(res => {


                var urlType = "internal"
                var urlLangList = this.state.languageList.map(item => {

                    var result = {
                        id: null,
                        language: item.id,
                        languageName: item.name,
                        path: null
                    }


                    var resUrls = res.data.urls.find(urlLang => item.id === urlLang.language)

                    if (resUrls) {
                        result.id = resUrls.id

                        if (resUrls.page) {
                            result.page = resUrls.page
                            result.section = resUrls.section
                            result.path = null
                        } else {
                            result.page = null
                            result.section = null
                            result.path = resUrls.path
                        }


                    }
                    // console.log("result ==> ", result)
                    return result
                })

                // console.log("urlLangList ==> ", urlLangList)
                if (urlLangList[0].page) {
                    urlType = "internal"
                } else {
                    urlType = "external"
                }

                this.setState({

                    name: res.data.name,
                    category: res.data.category,
                    urlLanguages: urlLangList,
                    urlType: urlType,
                    widgetType: res.data.is_internal ? "internal" : "external",
                    selectedPageId: res.data.urls[0] ? res.data.urls[0].page : null,
                    selectedSectionId: res.data.urls[0] ? res.data.urls[0].section : null

                    // sectionId:res.data.sectionId,
                }, () => {
                    this.getPages()
                })
            })
            .catch(err => {

            })
    }

    clearInternal() {
        this.setState({
            selectedPageId: null,
            selectedSectionId: null
        })
    }

    clearExternal() {

        let urlLanguages = [...this.state.urlLanguages]
        urlLanguages.map((item, id) => {

            // let urlLanguage = { ...urlLanguages[ id ]}
            item.path = null
            urlLanguages[id] = item

            return item
        })

        this.setState({
            urlLanguages: urlLanguages
        })
    }

    render() {
        const { t } = this.props;
        var titleHeader = t(this.state.transPage + "title_add")

        const PageSelect = this.state.pages.map(item => {
            return (<option key={item.id} value={item.id}>{item.name}</option>)
        })

        const SectionSelect = this.state.sections.map(item => {
            // console.log(item)
            return (<option key={item.id} value={item.section}>{item.name}</option>)
        })

        var ButtonSubmit = <Button variant="primary" onClick={() => this.handleWidgetAdd()}>
            <i className="fa fa-plus">&nbsp;</i>
            {t(this.state.transPage + "add_widget")}
        </Button>

        if (this.state.widgetId) {
            titleHeader = t(this.state.transPage + "title_update")
            ButtonSubmit = <Button variant="primary" onClick={() => this.handleWidgetUpdate()}>
                <i className="fa fa-edit">&nbsp;</i>
                {t(this.state.transPage + "update_widget")}
            </Button>
        }

        const WidgetCategoriesOption = this.state.widgetCategories.map(item => {
            return (
                <option key={item.id} value={item.id}> {item.category} </option>
            )
        })

        // GANTI JIKA SUDAH ADA4
        var URLInput = this.state.urlLanguages.map((item, index) => {
            return (
                <div className="form-group" key={index}>

                    <label> Url {item.languageName}</label>
                    <input value={this.state.urlLanguages[index].path} type="text" className="form-control"
                        onChange={(event) => this.handleUrlExternalChange(index, event.target.value)}></input>
                </div>
            )
        })

        if (this.state.urlType === "internal") {
            URLInput = <div>
                <div className="form-group">
                    <label> {t('page')} </label>
                    <select className="form-control" value={this.state.selectedPageId} onChange={(event) => this.handlePageChange(event.target.value)}>
                        <option value={null}> -- {t(this.state.transPage + "select_page")} -- </option>
                        {PageSelect}
                    </select>
                </div>
                <div className="form-group">
                    <label> {t('section')} </label>
                    <select className="form-control" value={this.state.selectedSectionId} onChange={(event) => this.handleSectionChange(event.target.value)}>
                        <option value={null}> -- {t(this.state.transPage + "select_page")} -- </option>
                        {SectionSelect}
                    </select>
                </div>

            </div>
        }

        return (
            <Modal show={this.props.show} onHide={() => this.onCloseModal()} onShow={() => this.onOpenModal()}>
                <Modal.Header closeButton className="bg-primary">
                    <Modal.Title> {titleHeader} </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="form-group">
                        <label> {t('name')} </label>
                        <input className="form-control" type="text" value={this.state.name} onChange={(event) => this.handleChangeName(event.target.value)} ></input>
                    </div>
                    <div className="form-group">
                        <label>{t('category')}</label>
                        <select disabled={this.state.categoryDisable} className="form-control" value={this.state.category} onChange={(event) => this.handleChangeCategory(event.target.value)} >
                            <option value=""> -- {t(this.state.transPage + "select_category")} -- </option>
                            {WidgetCategoriesOption}
                        </select>
                    </div>
                    <div className="form-group">
                        <label> {t('widget_type')}</label><br></br>

                        <label htmlFor="widget-internal">
                            <input
                                disabled={!this.state.widgetId ? false : true}
                                type="radio"
                                className=""
                                id="widget-internal"
                                name="widget_type"
                                value={"internal"}
                                checked={this.state.widgetType === "internal"}
                                onChange={() => this.handleWidgetType("internal")}
                            ></input>
                            &nbsp; {t('internal')}
                        </label>
                        &nbsp; &nbsp;
                        <label htmlFor="widget-external">
                            <input
                                disabled={!this.state.widgetId ? false : true}
                                type="radio"
                                className=""
                                id="widget-external"
                                name="widget_type"
                                value="external"
                                checked={this.state.widgetType === "external"}
                                onChange={() => this.handleWidgetType("external")}
                            ></input>
                             &nbsp; {t('external')} </label>
                    </div>
                    <div className="form-group">
                        <label> {t('url_type')}</label><br></br>

                        <label htmlFor="internal">
                            <input
                                type="radio"
                                className=""
                                id="internal"
                                name="url_type"
                                value={"internal"}
                                checked={this.state.urlType === "internal"}
                                onChange={() => this.handleUrlTypeChange("internal")}
                            ></input>
                                &nbsp; {t('internal')}
                        </label>
                        &nbsp; &nbsp;
                        <label htmlFor="external">
                            <input
                                type="radio"
                                className=""
                                id="external"
                                name="url_type"
                                value="external"
                                checked={this.state.urlType === "external"}
                                onChange={() => this.handleUrlTypeChange("external")}
                            ></input>
                             &nbsp; {t('external')} </label>
                    </div>
                    {URLInput}

                </Modal.Body>
                <Modal.Footer>
                    {ButtonSubmit}

                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(WidgetAddNew);