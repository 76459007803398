export function shortSentence(sentence,len=10) {
    
    if(sentence != null ) {
        var splitString = sentence.split(" ")
        splitString.length = len
        var shortContent = splitString.join(" ")
        return shortContent
    } else {
        return sentence
    }
   
}

export function shortChar(string, len=10) {
    if(string != null ) {
        var splitString = string.split("")
        splitString.length = len
        var shortContent = splitString.join("")
        return shortContent
    } else {
        return string
    }
}