import React, { Component } from 'react'
import Collapse from 'react-bootstrap/Collapse'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faChevronUp, faChevronDown, faCog, faListUl, faEdit } from "@fortawesome/free-solid-svg-icons";

import Context from '../Context'
import Box from './Box/Box'

import classes from './Content.module.css'
export default class Content extends Component {
    static contextType = Context
    constructor(props) {
        super(props)
        this.state = {
            open: true
        }
    }

    toggleActive = () => {
        this.setState((prevState) => {
            return {
                open: !prevState.open,
            };
        })
    }
    render() {
        const contextValue = this.context
        let icon = contextValue.activeSidebar ?
            <FontAwesomeIcon onClick={contextValue.toogleActive} color="#fff" className={classes.iconList} icon={faEllipsisV} size="lg" />
            :
            <FontAwesomeIcon onClick={contextValue.toogleActive} color="#fff" className={classes.iconList} icon={faListUl} size="lg" />

        // let collapse = this.state.open ? classes.isCollapsed : classes.customCollapse 
        return (
            <div className={classes.contentPages}>
                <div className={classes.topContent}>
                    {icon}
                    <span>Pages</span>
                </div>
                <div className={classes.mainContent}>
                    <div className={classes.boxToogle} onClick={this.toggleActive} aria-expanded={this.state.open}>
                        <span>HOME</span>
                        <FontAwesomeIcon color="#F1F1F1" className={classes.iconUp} icon={this.state.open ? faChevronUp : faChevronDown} size="lg" />
                        <FontAwesomeIcon color="#00599D" className={[classes.iconList, classes.iconListW].join(' ')} icon={faEllipsisV} size="lg" />
                    </div>
                    <Collapse in={this.state.open} className={classes.customCollapse}>
                        <div id="content-pages">
                            <Box open={this.state.open} />
                            <Box open={this.state.open} />
                        </div>
                    </Collapse>
                </div>
            </div>
        )
    }
}
