import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Context from "./Context";

import HeaderNew from "./HeaderNew";
import SideBarNew from "./SidebarNew";
import SidebarPageNew from "./SidebarPageNew";
import LoadingPage from "../../pages/LoadingPage";

import Footer from "./Footer";
class DashboardNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRoute: "/",
      showSidebar: false,
      showingSidebar: () => {
        this.showSidebar();
      },
    };
  }

  // componentDidMount(){
  //     this.setState({
  //         showSidebar: localStorage.getItem("sidebar") !== "" ? localStorage.getItem("sidebar") : true
  //     })
  // }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        currentRoute: this.props.location.pathname,
      });
    }
  }

  showSidebar = () => {
    this.setState((prevState) => {
      return { showSidebar: !prevState.showSidebar };
    });
    localStorage.setItem("sidebar", !this.state.showSidebar);
  };

  render() {
    let children;

    if (this.props.languageList.length > 0) {
      children = (
        <>
          {this.props.children}
          {/* <Footer /> */}
        </>
      );
    } else {
      children = <LoadingPage />;
    }
    return (
      <Context.Provider value={this.state}>
        <div id="DashboardNew" className="wrapper">
          <HeaderNew
            showSidebar={this.showSidebar}
            changeLanguage={this.props.changeLanguage}
          />

          <SideBarNew
            showSidebar={this.showSidebar}
            {...this.props}
            showSidebarValue={this.state.showSidebar}
            currentRoute={this.state.currentRoute}
          />

          <SidebarPageNew
            activeSite={this.props.activeSite}
            activeTemplate={this.props.activeTemplate}
            currentRoute={this.state.currentRoute}
          ></SidebarPageNew>

          {children}
        </div>
      </Context.Provider>
    );
  }
}

export default withRouter((props) => <DashboardNew {...props} />);
