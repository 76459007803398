import axios from "axios"
import config from "./../config/config"
class CompanyApi {

    base_api = config.base_api
    token = config.token

    getAllCompanies(params) {
        return axios.get(this.base_api+`companies/`,{
            params:params,
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    getCompanyDetail(companyId){
        return axios.get(this.base_api+`companies/${companyId}/`,{
            headers:{
                'Content-Type':"application/json"
            }
        })
    }

    getAllUsers(){
        return axios.get(this.example_api+`users`,{
            headers:{
                "Content-Type":"application/json"
            }
        })
    }

    getApi(endpoint){
        return axios.get(this.site_api+`${endpoint}`,{
            headers:{
                'Authorization': `Token ${this.token}`,
                "Content-Type":"application/json"
            }
        })
    }
    
}

export default CompanyApi