import React, { Component } from 'react';
import { Button, Tabs, Tab } from "react-bootstrap"
import swal from "sweetalert"
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Card, { CardBody, CardFooter } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content"
import TitlePages from './Elements/TitlePages'
import WidgetAssetAddThumbnailModal from "../components/Modal/WidgetAssetAddThumbnailModal"

import AssetApi from "./../api/AssetApi"
import GeneralApi from "./../api/GeneralApi"
import { withTranslation } from 'react-i18next';
import config from '../config/config'
import ReactGA from 'react-ga';
import Context from "./Context"


class AssetAdd extends Component {
    static contextType = Context;
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            description: "",
            file: "",
            asset_link: "",
            inputType: "FILE",
            file_type: "",
            hasLanguage: false,
            hasDarkLogo: false,
            hasSlogan: false,
            darkLight: false,

            assets_file: [],
            assets_link: [],
            asset_types: [],

            descriptions: [],
            languageList: [],
            tabKey: 1,
            selectedLanguage: 1,
            renderTabLanguage: "",

            fileNewThumbnail: false,
            setShowAssetThumbnailModal: false,
            thumbnailUrl: false,
            thumbnailUrl1: false,
            thumbnailUrl2: false,
            thumbnailID: false,

            transPage: "pages.asset_add.",
            transAlert: "alert.",

            assets_file_size: [],
            src: null,
            srcEn: null,
            srcVn: null,
            crop: {
                unit: '%',
                x: (100 - 40) / 2,
                y: 0,
                width: 40,
                height: 100,
                aspect: 9 / 16,
            },
            assets_crop: [],
            mobile_variable: {
                mobile_aspect: 0,
                mobile_unit: 0,
                mobile_height: 0,
                mobile_width: 0,
                mobile_x: 0,
                mobile_y: 0
            }

        }

        this.getAssetTypeLanguage = this.getAssetTypeLanguage.bind(this)
        this.setShowAssetThumbnailModal = this.setShowAssetThumbnailModal.bind(this)

        this.handleChangeName = this.handleChangeName.bind(this)
        this.handleChangeDescription = this.handleChangeDescription.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
        this.handleChangeTab = this.handleChangeTab.bind(this)
        this.handleChangeLink = this.handleChangeLink.bind(this)
        this.handleChangeFileType = this.handleChangeFileType.bind(this)
        this.handleHasLanguage = this.handleHasLanguage.bind(this)
        this.handleHasSlogan = this.handleHasSlogan.bind(this)

        this.handleDarkLogo = this.handleDarkLogo.bind(this)
        this.handleDarkLight = this.handleDarkLight.bind(this)
        this.handleSelectedAssetThumbnail = this.handleSelectedAssetThumbnail.bind(this)

        this.uploadNewAsset = this.uploadNewAsset.bind(this)
        this.renderTabLanguage = this.renderTabLanguage.bind(this)

        this.onCropChange = this.onCropChange.bind(this)
        this.makeClientCrop = this.makeClientCrop.bind(this)

    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        const { languagesList } = this.context;

        this.getAssetTypeLanguage()

        const descriptions = languagesList.map(item => {
            return {
                language: item.id,
                languageName: item.name,
                description: "",
            }
        })

        this.setState({
            languageList: languagesList,
            descriptions: descriptions
        })
    }

    getAssetTypeLanguage() {
        const obj = new AssetApi()
        const lang = localStorage.getItem('lang')

        obj.assetTypeLanguage({
            language: lang ? lang : 'en'
        })
            .then(res => {
                this.setState({
                    asset_types: res.data.results
                })
            })
    }

    setShowAssetThumbnailModal(value) {
        this.setState({
            thumbnailType: value,
            setShowAssetThumbnailModal: !this.state.setShowAssetThumbnailModal
        })
    }

    handleChangeName(value) {
        this.setState({
            name: value
        })
    }

    handleChangeLink(value) {
        //console.log("Sub: " + value.substring(8,23))
        var newValue = "";
        if (value.substring(8, 23) === "www.youtube.com") {
            newValue = value.replace('/watch?v=', '/embed/')
        }
        else {
            newValue = value;
        }

        this.setState({
            asset_link: newValue
        })
    }

    handleChangeLinkLanguage(value, index) {
        const { assets_link } = this.state;

        if (value.substring(8, 23) === "www.youtube.com") {
            assets_link[index] = value.replace('/watch?v=', '/embed/')
        } else {
            assets_link[index] = value;
        }

        this.setState({
            asset_link: assets_link
        })
    }

    handleChangeDescription(id, value) {

        let descriptions = [...this.state.descriptions]
        let description = { ...descriptions[id] }
        description.description = value
        descriptions[id] = description

        this.setState({
            description: descriptions[0].description,
            descriptions: descriptions
        })

    }

    handleChangeFile(event) {
        //console.log(event.target.files[0])
        this.setState({
            file: event.target.files[0]
        })

        const reader = new FileReader();
        reader.addEventListener('load', () =>
            this.setState({ src: reader.result })
        );
        reader.readAsDataURL(event.target.files[0]);
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onImageLoaded1 = (image) => {
        this.imageRef1 = image;
    };

    onCropComplete = (crop, param, index) => {
        this.makeClientCrop(crop, param, index);
    };

    onCropChange = (crop, param, index) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });

        if (param === 'nolang') {
            this.setState({ crop });
        } else {
            let assets_crop = [...this.state.assets_crop]
            assets_crop[index] = crop
            this.setState({ assets_crop });
        }
    };

    async makeClientCrop(crop, param, index) {
        if (this.imageRef && crop.width && crop.height) {
            if (param === 'nolang') {
                const croppedImage = await this.getCroppedImg(this.imageRef, crop, this.state.file.name, param, index)
                const croppedImageUrl = this.blobToFile(croppedImage, `mobile-${this.state.file.name}`)
                this.setState({
                    croppedImageUrl
                });
            } else {
                if (index === 0) {
                    const croppedImage = await this.getCroppedImg(this.imageRef, crop, this.state.assets_file[index].name, param, index);
                    const croppedImageUrlEn = this.blobToFile(croppedImage, `mobile-${this.state.assets_file[index].name}`)
                    this.setState({ croppedImageUrlEn });
                } else {
                    const croppedImage = await this.getCroppedImg(this.imageRef1, crop, this.state.assets_file[index].name, param, index);
                    const croppedImageUrlVi = this.blobToFile(croppedImage, `mobile-${this.state.assets_file[index].name}`)
                    this.setState({ croppedImageUrlVi });
                }
            }
        }
    }

    getCroppedImg(image, crop, fileName, param, index) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        // const base64Image = canvas.toDataURL('image/jpeg');
        // return base64Image;
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                // resolve(this.fileUrl); use this if you want to display result of cropped
                resolve(blob); // this return only blob file which will be saved to db
            }, 'image/jpeg');
        });
    }

    handleChangeNewThumbnail(event) {
        this.setState({
            fileNewThumbnail: event.target.files[0],
            thumbnailUrl: URL.createObjectURL(event.target.files[0]),
            thumbnailID: false
        })
    }

    handleChangeFileLang(event, index) {
        //console.log(event.target.files[0])
        let assets_file = [...this.state.assets_file]
        assets_file[index] = event.target.files[0];

        let size = event.target.files[0].size
        let imgSize = parseInt(Math.ceil(size / 1000))
        let assets_file_size = [...this.state.assets_file_size]
        assets_file_size[index] = imgSize;

        this.setState({
            assets_file: assets_file,
            file: event.target.files[0],
            assets_file_size: assets_file_size
        })

        const reader = new FileReader();
        reader.addEventListener('load', () =>
            parseInt(index) === 0 ?
                this.setState({ srcEn: reader.result }) :
                this.setState({ srcVn: reader.result })

        );
        reader.readAsDataURL(event.target.files[0]);
    }

    handleChangeFileType(value) {
        var inputType = "FILE"
        if (parseInt(value) === 3) {
            inputType = "URL"
        }

        this.setState({
            // asset_type:value,
            asset_type: value,
            inputType: inputType
        })
    }

    handleHasLanguage() {
        this.setState({
            hasLanguage: !this.state.hasLanguage
        })
    }

    handleHasSlogan() {
        this.setState({
            hasSlogan: !this.state.hasSlogan
        })
    }


    handleDarkLogo() {
        this.setState({
            hasDarkLogo: !this.state.hasDarkLogo
        })
    }

    handleDarkLight(value) {
        this.setState({
            darkLight: value
        })
    }

    handleSelectedAssetThumbnail(thumbnailType, asset, id) {
        switch (thumbnailType) {
            case 'noLang':
                this.setState({
                    thumbnailUrl: asset
                })
                break;
            case 'enLang':
                this.setState({
                    thumbnailUrl1: asset
                })
                break;
            case 'vnLang':
                this.setState({
                    thumbnailUrl2: asset
                })
                break;
            default:
                break;
        }
        this.setState({
            thumbnailID: id,
            fileNewThumbnail: false
        })
    }

    blobToFile(theBlob, fileName) {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;

        const file = new File([theBlob], fileName);
        // return theBlob;
        return file;
    }

    convertSelectedCrop(crop, index) {
        let limiter
        let edge = parseInt(this.state.assets_file_size[index])
        if (edge < 1000) {
            limiter = 80
        } else if (edge > 1000 && edge < 2000) {
            limiter = 70
        } else if (edge > 2000) {
            limiter = 50
        } else {
            limiter = 100
        }

        let newCrop = {
            mobile_aspect: crop.aspect,
            mobile_height: Math.ceil((crop.height * limiter) / 100),
            mobile_unit: crop.unit,
            mobile_width: Math.ceil((crop.width * limiter) / 100),
            mobile_x: Math.ceil((crop.x * (limiter + 10)) / 100),
            mobile_y: Math.ceil((crop.y * limiter) / 100),
        }

        return newCrop
    }

    uploadNewAsset() {

        const assetObj = new AssetApi()

        const asset_mobiles = [this.state.croppedImageUrlEn, this.state.croppedImageUrlVi]
        const asset_languages = this.state.descriptions.map((item, index) => {
            return {
                language: item.language,
                description: item.description,
                asset_file: this.state.assets_file[index],
                asset_link: this.state.assets_link[index],
                asset_mobile: asset_mobiles[index],

                // mobile_variable: this.state.assets_crop[index] ? this.convertSelectedCrop(this.state.assets_crop[index], index) : this.state.mobile_variable

                mobile_variable: this.state.assets_crop[index] ? {
                    mobile_aspect: this.state.assets_crop[index]?.aspect,
                    mobile_unit: this.state.assets_crop[index]?.unit,
                    mobile_height: Math.ceil(this.state.assets_crop[index]?.height),
                    mobile_width: Math.ceil(this.state.assets_crop[index]?.width),
                    mobile_x: Math.ceil(this.state.assets_crop[index]?.x),
                    mobile_y: Math.ceil(this.state.assets_crop[index]?.y)
                } : this.state.mobile_variable
            }
        })

        const newAsset = {
            name: this.state.name,
            has_languages: this.state.hasLanguage,
            thumbnail: parseInt(this.state.asset_type) !== 1 ? (this.state.thumbnailID ? this.state.thumbnailID : null) : null,
            description: this.state.description,
            asset_type: this.state.asset_type,
            asset_link: this.state.hasLanguage ? false : this.state.asset_link, // cuma asset_link
            asset_file: this.state.file,
            asset_mobile: this.state.croppedImageUrl,
            set_logo_opacity: this.state.hasDarkLogo,
            logo_opacity: this.state.darkLight ? "Dark" : "Light",
            has_slogan: this.state.hasSlogan,
            asset_languages: asset_languages,

            mobile_variable: this.state.crop ? {
                mobile_aspect: this.state.crop.aspect,
                mobile_unit: this.state.crop.unit,
                mobile_height: Math.ceil(this.state.crop.height),
                mobile_width: Math.ceil(this.state.crop.width),
                mobile_x: Math.ceil(this.state.crop.x),
                mobile_y: Math.ceil(this.state.crop.y)
            } : this.state.mobile_variable
        }

        // console.log(newAsset)

        if (this.state.fileNewThumbnail) {
            const newThumbnail = {
                name: this.state.name,
                description: "",
                has_languages: "",
                has_slogan: false,
                thumbnail: null,
                file_type: "FILE",
                asset_type: 1,
                set_logo_opacity: false,
                logo_opacity: false,
                asset_mobile: null,
                asset_file: this.state.fileNewThumbnail,
                asset_languages: asset_languages
            }

            assetObj.assetAdd(newThumbnail)
                .then(res => {
                    let newArray = { ...newAsset, thumbnail: res.data.id }
                    this.addNewAsset(newArray)
                })
                .catch(err => {
                    console.log("err => ", err)
                })
        } else {
            this.addNewAsset(newAsset)
        }
    }

    addNewAsset(newAsset) {
        const { t } = this.props;
        const assetObj = new AssetApi()

        // console.log('newAsset', newAsset)

        assetObj.assetAdd(newAsset)
            .then(res => {
                // swal("Success !", JSON.stringify(res.data), "success")
                // console.log("success => ", res.data)
                swal("Success !", t(this.state.transAlert + "asset_add_success"), "success")
                setTimeout(() => {
                    this.props.history.push('/asset')
                }, 0)
            })
            .catch(err => {
                console.log("err => ", err)
            })
    }

    renderTabLanguage() {
        const renderTabLanguage = this.state.languageList.map((item) => {

            // var findName = this.state.descriptions.find((textLang, idx) => item.id === textLang.language)
            // console.log("item ", findName)
            return {

                language: item.id,
                //   name: findName ? findName.name : "",
                languageName: item.name,
                //   page: this.state.selectedPage,
                //   section: this.state.selectedSection,
                description: ""
            }

        })

        this.setState({
            descriptions: renderTabLanguage,
        })
    }

    handleChangeTab(value) {
        this.setState(
            {
                selectedLanguage: value,
                tabKey: value,
            },
            () => {
                this.renderTabLanguage()
            }
        )
    }

    removeThumbnail = () => {
        this.setState({
            thumbnailUrl: false,
            thumbnailID: false
        })
    }

    render() {
        const { t } = this.props;

        const { crop, croppedImageUrl, src, srcEn, srcVn, assets_crop } = this.state;

        const TabLanguage = this.state.descriptions.map((item, index) => {
            if (this.state.hasLanguage) {
                FileInputLanguage = <div className="form-group">
                    <label>{t(this.state.transPage + "asset_file")}</label>
                    <input type="file" name="asset_file" className="form-control" onChange={(event) => this.handleChangeFileLang(event, index)}></input>
                    {index === 0 ? this.state.asset_type === "1" && srcEn &&
                        (
                            <div className="mt-3">
                                <label style={{ position: "relative", display: 'block' }}>Assets for mobile</label>
                                <div className="c_crop--fixed">
                                    <ReactCrop
                                        src={srcEn}
                                        crop={assets_crop[0] ? assets_crop[0] : crop}
                                        ruleOfThirds
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={(crop) => this.onCropComplete(crop, 'lang', index)}
                                        onChange={(crop) => this.onCropChange(crop, 'lang', index)}
                                    />
                                </div>
                            </div>
                        ) :
                        this.state.asset_type === "1" && srcVn &&
                        (
                            <div className="mt-3">
                                <label style={{ position: "relative", display: 'block' }}>Assets for mobile</label>
                                <div className="c_crop--fixed">
                                    <ReactCrop
                                        src={srcVn}
                                        crop={assets_crop[1] ? assets_crop[1] : crop}
                                        ruleOfThirds
                                        onImageLoaded={this.onImageLoaded1}
                                        onComplete={(crop) => this.onCropComplete(crop, 'lang', index)}
                                        onChange={(crop) => this.onCropChange(crop, 'lang', index)}
                                    />
                                </div>
                            </div>
                        )
                    }
                </div>

                // LINK IS NOT EXITS IN DATABASE
                if (this.state.inputType === "URL") {
                    FileInputLanguage = <div className="form-group">
                        <label>Asset Url</label>
                        <input type="url" name="asset_url" className="form-control" value={this.state.assets_link[index] ? this.state.assets_link[index] : ''} onChange={(event) => this.handleChangeLinkLanguage(event.target.value, index)}></input>
                    </div>
                }

            }

            return (
                <Tab key={index} eventKey={item.language} title={item.languageName}>
                    <br></br>
                    { FileInputLanguage}
                    <div className='form-group'>
                        <label> {t('description')} </label>
                        <textarea className='form-control'
                            onChange={(event) => this.handleChangeDescription(index, event.target.value)}
                            value={this.state.descriptions[index].description}></textarea>
                        {/* { JSON.stringify( this.state.text_languages )  } */}
                    </div>
                </Tab>
            )
        })

        var FileInputLanguage = ""
        var FileInput = ""
        var input = <div className="form-group">
            <label>{t(this.state.transPage + "asset_file")}</label>
            <input type="file" name="asset_file" className="form-control" onChange={(event) => this.handleChangeFile(event)}></input>
            {this.state.asset_type === "1" && src &&
                (
                    <div className="mt-3">
                        <label style={{ position: "relative", display: 'block' }}>Assets for mobile</label>
                        <div className="c_crop--fixed">
                            <ReactCrop
                                src={src}
                                crop={crop}
                                ruleOfThirds
                                onImageLoaded={this.onImageLoaded}
                                onComplete={(crop) => this.onCropComplete(crop, 'nolang', '')}
                                onChange={(crop) => this.onCropChange(crop, 'nolang', '')}
                            />
                        </div>
                    </div>
                )}
            {/*
            {croppedImageUrl && (
                <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
            )} */}
        </div>

        if (this.state.inputType === "URL") {
            input = <div className="form-group">
                <label>Asset Urls</label>
                <input type="url" name="asset_url" className="form-control" value={this.state.asset_link} onChange={(event) => this.handleChangeLink(event.target.value)}></input>
            </div>
        }

        let SetThumbnail, FileThumbnail
        if (this.state.asset_type && parseInt(this.state.asset_type) !== 1) {
            SetThumbnail =
                <div className="d-flex flex-column">
                    <label>Asset Thumbnail</label>
                    {/* enable if you want to show thumbnail */}
                    {
                        this.state.thumbnailUrl ?
                            <div className='position-relative' style={{width: '50%', cursor: 'pointer'}} onClick={this.removeThumbnail}>
                                <i className='fa fa-times fa-1x position-absolute' style={{top: '1rem', right: '1rem', zIndex: '2', border: '1px solid black', padding: '.5rem', color: 'white'}}></i>
                                <img src={this.state.thumbnailUrl} style={{ height: '300px', width: '100%', marginBottom: '1.5rem' }} alt="" />
                            </div>
                            : <></>
                    }
                    <div className="d-flex">
                        <div className="mt-auto mb-auto" style={{ cursor: "pointer", border: "1px solid black", borderRadius: "10px", padding: ".25rem" }} onClick={() => { this.setShowAssetThumbnailModal('noLang') }}>
                            <i className={this.state.thumbnailUrl ? "fa fa-edit" : "fa fa-plus"}></i> {this.state.thumbnailUrl ? 'Update Thumbnail' : 'Add Thumbnail'}
                        </div>
                        <div className="d-flex file-input-custom-t" style={{ border: "1px solid black", borderRadius: "10px" }}>
                            <input type="file" name="asset_file" className="form-control custom-file-input" onChange={(event) => this.handleChangeNewThumbnail(event)}></input>
                            <label for="asset_file" >
                                <i className="fa fa-plus mr-1"></i>Add New Thumbnail
                                <p class="file-name-custom"></p>
                            </label>
                        </div>
                    </div>

                </div>
        } else {
            <></>
        }

        if (!this.state.hasLanguage) {
            FileInputLanguage = ""
            FileInput = input
            FileThumbnail = SetThumbnail
        } else {
            FileInput = ""
            FileInputLanguage = input
            FileThumbnail = SetThumbnail
        }

        var DarkLogoInput = ""

        if (this.state.hasDarkLogo) {
            DarkLogoInput = <div>
                <input type="radio" value="dark" id="dark-radio" name="has-dark-logo" checked={this.state.darkLight === "Dark"} onClick={() => this.handleDarkLight("Dark")}></input>
                &nbsp;
                <label for="dark-radio"> Dark </label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <input type="radio" value="light" id="light-radio" name="has-dark-logo" checked={this.state.darkLight === "Light"} onClick={() => this.handleDarkLight("Light")} ></input>
                &nbsp;
                <label for="light-radio"> Light </label>
                {/* { JSON.stringify(this.state.darkLight) } */}
            </div>
        }

        return (

            <Content>
                <ContentHeader>
                    {/* <h1> Add Asset </h1> */}
                    <TitlePages title={t(this.state.transPage + "title")} />
                </ContentHeader>
                <ContentBody>
                    <Card>

                        <CardBody>
                            {/* { JSON.stringify(this.state.assets_file)} */}
                            {/* { JSON.stringify(this.state.descriptions)} */}
                            <div className="form-group ">
                                <label>{t(this.state.transPage + "asset_name")}</label>
                                <input type="text" name="asset_name" className="form-control" value={this.state.name} onChange={(event) => this.handleChangeName(event.target.value)}></input>

                            </div>
                            <div className="form-group">
                                <input type="checkbox" value="dark-logo" id="check-dark-logo" checked={this.state.hasDarkLogo} onClick={() => this.handleDarkLogo()} ></input> &nbsp;
                                <label for="check-dark-logo"> {t(this.state.transPage + "logo_override_color")} </label>
                                {DarkLogoInput}
                            </div>
                            <div className="form-group">
                                <input type="checkbox" id="check-has-language" checked={this.state.hasLanguage} onClick={() => this.handleHasLanguage()}></input> &nbsp;
                                <label for="check-has-language"> {t(this.state.transPage + "has_language")} </label>
                            </div>
                            <div className="form-group">
                                <input type="checkbox" id="check-has-slogan" checked={this.state.hasSlogan} onClick={() => this.handleHasSlogan()}></input> &nbsp;
                                <label for="check-has-slogan"> {t(this.state.transPage + "has_slogan")} </label>
                            </div>
                            <div className="form-group">

                                <label>{t('file_type')}</label>
                                <select className="form-control" defaultValue={this.state.inputType} onChange={(event) => { this.handleChangeFileType(event.target.value) }}>
                                    <option value=""> -- {t(this.state.transPage + "select_file")} -- </option>
                                    {/* <option value="Image"> Image </option>
                                    <option value="Video"> Video </option>
                                    <option value="PDF"> PDF </option>
                                    <option value="CSV"> CSV </option>
                                    <option value="URL"> URL </option> */}
                                    {
                                        this.state.asset_types.map(item => {
                                            return (<option value={item.id}> {item.name} </option>)
                                        })
                                    }
                                </select>

                            </div>
                            {/* {this.state.asset_link} */}
                            {FileInput}

                            <Tabs>
                                {TabLanguage}
                            </Tabs>

                            {FileThumbnail}


                        </CardBody>
                        <CardFooter>
                            <div className="row">
                                <div className="col-md-9">

                                </div>
                                <div className="col-md-3">
                                    <Button className="float-right" onClick={() => this.uploadNewAsset()}> <i className="fa fa-plus">&nbsp;</i>{t(this.state.transPage + "upload_new_asset")} </Button>
                                </div>

                            </div>
                        </CardFooter>
                    </Card>
                </ContentBody>
                <WidgetAssetAddThumbnailModal
                    show={this.state.setShowAssetThumbnailModal}
                    onHide={this.setShowAssetThumbnailModal}
                    getWidgetDetail={this.getDetailWidget}
                    selectedAssets={this.state.selectedAssets}
                    thumbnailType={this.state.thumbnailType}
                    type={""}
                    selectedAsset={this.handleSelectedAssetThumbnail}
                ></WidgetAssetAddThumbnailModal>
            </Content>

        );
    }
}

export default withTranslation()(AssetAdd);