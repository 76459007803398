import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"

class WidgetAssetDeleteConfirm extends Component {
    constructor(props) {
        super(props)

        this.deleteSelectedAsset = this.deleteSelectedAsset.bind(this)
        this.onOpenModal = this.onOpenModal.bind(this)
        // this.onHideModal = this.onHideModal.bind(this)
    }

    deleteSelectedAsset() {
        // dari props method nya
        this.props.deleteSelectedAsset() 
    }

    onOpenModal() {
        //console.log("modal open")
    }

    
    render() {

        const listFileName = this.props.selectedAssets.map(item => {
            return (<li key={item.asset}>{ item.asset_name ? item.asset_name : item.name }</li>)
        })

        return (
            <Modal show={this.props.show} onHide={() => this.props.onHide()} onShow={() => this.onOpenModal()}>
                {this.props.loadingModal}
                <Modal.Header closeButton className="bg-danger">
                    <Modal.Title>Confirm Delete Assets </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p> Are you sure want to delete these / this files/file ? </p>
                    <ul>
                        {listFileName}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.props.onHide()}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => this.deleteSelectedAsset()}>
                        <i className="fa fa-trash">&nbsp;</i>
                        Delete Selected Assets
                    </Button>   
                </Modal.Footer>
            </Modal>
        );
    }
}

export default WidgetAssetDeleteConfirm;