import React, { Component } from 'react';
import { Dropdown } from "react-bootstrap"

import Card,{ CardHeader, CardBody  } from "./../../components/Card";
// import { Content, ContentHeader, ContentBody } from "./../../components/Content"

// import WidgetAssetAddModal from "./../../components/Modal/WidgetAssetAddModal"

class AssetsElement extends Component {

    constructor(props) {
        super(props)
        this.state = {
            
        }

       
    }

    render() {

        // console.log(this.props.assets)

        const Assets = this.props.widget_assets.map(item => {
            return(
                <div className="col-md-3" key={item.id+"-"+item.asset}>
                    <Card>
                        <CardHeader className="bg-primary">
                            <div className="form-check form-check-inline">
                                <input type="checkbox" value="" 
                                    onClick={(event) => this.props.setSelectedAsset("asset",item, event.currentTarget.checked)} 
                                    className="form-check-input" id={"label-"+item.id+"-"+item.asset}></input>&nbsp;
                                <label className="form-check-label" htmlFor={"label-"+item.id+"-"+item.asset}><b >{item.asset_name}</b> </label>
                            </div>
                            <div className="card-tools">
                                    <Dropdown drop="down">
                                        <Dropdown.Toggle  id="dropdown-basic" as={"a"} >  
                                            <label href="#" style={{"color":"black"}}><i className="fa fa-ellipsis-v"></i></label>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href={`/asset/detail/${item.asset}`}>Update</Dropdown.Item>
                                           
                                        </Dropdown.Menu>
                                    </Dropdown>
                                  
                                </div>
                        </CardHeader>
                        <CardBody>
                            <div className="container-fluid" align="center">
                                <img className="card-img" src={item.asset_file}></img> 
                            </div>
                            
                        </CardBody>
                        
                       
                    </Card> 
                </div>
            )
        })

        return (
            <div className="row">
                
                {Assets}
              
            </div>
               
        );
    }
}

export default AssetsElement;