import React, { Component } from "react";
import { css } from "@emotion/core";
import { Collapse } from "react-bootstrap"
import ClipLoader from "react-spinners/ClipLoader";
// import MenuItem from "./../SidebarNew/MenuItem"
// import NavItem from "./../SidebarNew/NavItem"
import NewMenuItem from "./../SidebarNew/NewMenuItem/NewMenuItem";
import ExtendMenuItem from "./../SidebarNew/NewMenuItem/ExtendMenuItem";
import GeneralApi from "./../../api/GeneralApi";
import TemplateApi from "./../../api/TemplateApi";
import SiteApi from "./../../api/SiteApi";
import UserContext from '../../pages/Context'

import "./DashboardNew.css";

class SidebarPageNew extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            contentDropdown: true,
            pages: false,
            activePages: "",
            activeSite: 5,
            activeTemplate: 41,

            siteStructure: false,
            activeRoute: false
        };
        this.handleTemplateChange = this.handleTemplateChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            activeTemplate: this.props.activeTemplate,
            activeSite: this.props.activeSite
        }, () => {
            this.handleTemplateChange(this.state.activeSite, this.state.activeTemplate);
        })

        const currentRoute = this.props.currentRoute
        if (currentRoute !== '/' && currentRoute.includes('page/detail/')) {
            let activeRoute = currentRoute.split('/').pop();
            if (this.state.activePages !== parseInt(activeRoute)) {
                this.setState({
                    activePages: parseInt(activeRoute)
                })
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.activeSite !== state.activeSite || props.activeTemplate !== state.activeTemplate) {
            return {
                activeTemplate: props.activeTemplate !== state.activeTemplate ? props.activeTemplate : state.activeTemplate,
                activeSite: props.activeSite !== state.activeSite ? props.activeSite : state.activeSite,
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        // if (this.props.activeSite !== prevProps.activeSite || this.props.activeTemplate !== prevProps.activeTemplate) {
        if (this.props.activeTemplate !== prevProps.activeTemplate) {
            let site, template
            site = this.props.activeSite !== prevProps.activeSite ? this.props.activeSite : prevProps.activeSite
            template = this.props.activeTemplate !== prevProps.activeTemplate ? this.props.activeTemplate : prevProps.activeTemplate
            setTimeout(() => {
                this.handleTemplateChange(site, template)
            }, 300);
        }

        const currentRoute = this.props.currentRoute
        if (currentRoute !== '/' && currentRoute.includes('page/detail/')) {
            let activeRoute = currentRoute.split('/').pop();
            if (this.state.activePages !== parseInt(activeRoute)) {
                this.setState({
                    activePages: parseInt(activeRoute)
                })
            }
        } else {
            if (this.state.activePages) {
                this.setState({
                    activePages: false
                })
            }

        }
    }

    handleTemplateChange(site, template) {
        const objTemplate = new TemplateApi();
        const objSite = new SiteApi();

        localStorage.setItem("templateId", template);

        objTemplate.getTemplateDetail(template).then((res) => {
            const languageCode = localStorage.getItem("lang");
            this.setState(
                {
                    pages: false,
                    isLoading: true,
                    templateSelected: res.data,
                },
                () => {
                    const obj = new GeneralApi();

                    const params = {
                        template: template,
                        language: languageCode,
                    };

                    obj.getDetailSidebar(site, params).then((res) => {
                        // console.log(" pages ==> ", res.data.template.pages)
                        this.setState({
                            sidebar: res.data,
                            pages: res.data.template.pages,
                        });
                    });

                    const param = {
                        language: languageCode,
                    };

                    objSite.getSiteStructure(template, param).then((res) => {
                        this.setState({
                            siteStructure: res.data.pages
                        })
                        // res.data.expanded = true;
                        // const newFormat = res.data.pages.map((item) => {
                        //     return {
                        //         ...item,
                        //         expanded: true,
                        //     };
                        // });

                        // this.setState({
                        //     treeData: newFormat
                        // }, () => {
                        //     console.log(this.state.siteStructure)
                        // })

                    });

                }
            );
        });
    }

    getSiteStructure() {
        const objSite = new SiteApi();

        const templateId =
            this.props.match.params.templateId | localStorage.getItem("templateId");

        const params = {
            language: localStorage.getItem("lang"),
        };

        // alert(JSON.stringify(params))

        objSite.getSiteStructure(templateId, params).then((res) => {
            res.data.expanded = true;
            const newFormat = res.data.pages.map((item) => {
                return {
                    ...item,
                    expanded: true,
                };
            });

            this.setState(
                {
                    treeData: newFormat,
                    selectedLang: localStorage.getItem("lang"),
                },
                () => { }
            );
        });
    }

    activePages = (page) => {
        this.setState({
            activePages: page,
        });
    };

    getSidebarPageListRunning = (site, idx) => {
        let items = site?.map((s, i) => {
            let isActive = this.state.activePages === i ? true : false;

            let childs = s.children ? this.getSidebarPageList(s.children, idx + 1) : <></>
            return (
                <>
                    <ExtendMenuItem
                        idx={idx}
                        data={s}
                        active={isActive}
                        activePage={() => this.activePages(i)}
                    />
                    {childs}
                </>
            )
        })

        return items
    }

    expandPages = (name) => {
        if (name) {
            this.setState({
                [name]: !this.state[name]
            })
        }
    }

    getSidebarPageList = (site, idx) => {
        let items = site?.map((s, i) => {
            let isActive = this.state.activePages === s.id ? true : false;

            let ico, child
            if (s.children) {
                let styles = {
                    marginLeft: parseInt(idx) === 0 ? `1rem` : `calc((2.5rem * ${idx}))`,
                    color: '#C2C7D0'
                }
                ico = <i className={!this.state[s.name] ? 'fa fa-plus-circle' : 'fa fa-minus-circle'} style={styles} onClick={() => { this.expandPages(s.name) }}></i>
                let childs = this.getSidebarPageList(s.children, idx + 1)
                child =
                    <Collapse in={this.state[s.name]} >
                        <div>
                            {childs}
                        </div>
                    </Collapse >
            } else {
                let styles = {
                    height: '1rem',
                    width: '1rem',
                    marginLeft: parseInt(idx) === 0 ? `1rem` : (parseInt(idx) > 1 ? `calc((2rem * ${idx}))` : `calc((2.5rem * ${idx}))`),
                    opacity: '0'
                }
                ico = <i className='fa fa-accessible-icon' style={styles} ></i>
            }
            return (
                <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center'>
                        {ico}
                        <ExtendMenuItem
                            idx={idx}
                            data={s}
                            active={isActive}
                            activePage={() => this.activePages(s.id)}
                        />
                    </div>
                    {child}
                </div>
            )
        })

        return items
    }

    render() {
        const { pages, siteStructure, treeData } = this.state;
        let listPages;
        const override = css`
            display: block;
            margin: 0 auto;
        `;
        if (pages) {
            listPages = pages.map((p, i) => {
                let isActive = this.state.activePages === i ? true : false;
                return (
                    <NewMenuItem
                        data={p}
                        active={isActive}
                        activePage={() => this.activePages(i)}
                    />
                );
            });
        } else {
            listPages = (
                <ClipLoader
                    css={override}
                    size={30}
                    margin={2}
                    color={"#000"}
                    loading={this.state.isLoading}
                />
            );
        }

        let pageHome, allPages, listPage, testTree
        if (siteStructure) {
            let home = [{
                id: siteStructure[0]?.id,
                name: siteStructure[0]?.name,
                index: siteStructure[0]?.index,
                children: false
            }]
            pageHome = this.getSidebarPageList(home, 0)
            allPages = this.getSidebarPageList(siteStructure[0]?.children, 0)
            listPage =
                <div>
                    {pageHome}
                    {allPages}
                </div>
            // testTree = <SortableTree
            //     treeData={treeData}
            //     onChange={treeData => this.setState({ treeData })}
            //     generateNodeProps={({ node, i }) => ({
            //         title: (
            //             <ExtendMenuItem
            //                 idx={i}
            //                 data={node}
            //                 active={false}
            //                 activePage={() => this.activePages(i)}
            //             />
            //         ),
            //     })}
            // />
        } else {
            listPage = (
                <ClipLoader
                    css={override}
                    size={30}
                    margin={2}
                    color={"#000"}
                    loading={this.state.isLoading}
                />
            );
        }

        return (
            <aside className="main-sidebar-page sidebar-dark-primary elevation-4">
                <div
                    className="sidebar"
                    style={{
                        height: "100%",
                        marginLeft: "4.5rem",
                        paddingTop: "6.4rem",
                        paddingLeft: "0",
                        paddingRight: "0",
                    }}
                >
                    {/* <div>
                        {listPages}
                    </div> */}

                    {listPage}
                    {/* <div id="cstm-sortable-tree" style={{ height: "100%", width: "100%", position: "relative" }}>
                        <SortableTree
                            treeData={treeData}
                            onChange={treeData => this.setState({ treeData })}
                            canDrag={false}
                            canDrop={false}
                            generateNodeProps={({ node, i }) => ({
                                title: (
                                    <ExtendMenuItem
                                        idx={i}
                                        data={node}
                                        active={false}
                                        activePage={() => this.activePages(i)}
                                    />
                                ),
                            })}
                        />
                    </div> */}
                </div>
            </aside>
        );
    }
}

export default SidebarPageNew;
