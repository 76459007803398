import React, { Component } from "react";
import { Button, Alert } from "react-bootstrap";

import AuthApi from "./../api/AuthApi";
import { setUserSession } from "./../lib/Common";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      alert: "",
      error: {},
    };

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);

    this.setError = this.setError.bind(this);
    this.setAlert = this.setAlert.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  componentDidMount() {
    console.log(this.props.propState)
  }

  setError(err) {
    this.setState({
      error: err,
    });
  }

  setAlert(message) {
    this.setState({
      alert: message,
    });
  }

  handleUsernameChange(value) {
    this.setState({
      username: value,
    });
  }

  handlePasswordChange(value) {
    this.setState({
      password: value,
    });
  }

  handleLogin() {
    const objAuth = new AuthApi();

    const username = this.state.username;
    const password = this.state.password;

    objAuth
      .login(username, password)
      .then((res) => {
        if (
          res.data.user.is_superuser ||
          res.data.employee_privileges.length > 0
        ) {
          this.setAlert(
            <Alert variant="success"> You Successfully Login </Alert>
          );
          setUserSession(res.data.key, res.data.user);
          localStorage.setItem("lang", "en");
          this.props.getDataUser(
            res.data.key,
            res.data.user,
            res.data.employee_privileges
          );
          window.location.href = "/";
        } else {
          this.setAlert(
            <Alert variant="danger">
              {" "}
              You don't have a privilege to access this website{" "}
            </Alert>
          );
        }

        // setTimeout(() => {
        //     window.location.href = "/dashboard";
        // }, 500);
      })
      .catch((err) => {
        var msg = "";

        if (err?.response?.data?.non_field_errors) {
          msg = err?.response?.data?.non_field_errors.map((item) => {
            return <li>{item}</li>;
          });
        }

        this.setAlert(
          <Alert variant="danger" onClose={() => this.setAlert("")} dismissible>
            {msg}
          </Alert>
        );
      });
  }

  handleSubmit(e) {
    this.handleLogin();
    e.preventDefault();
  }

  render() {
    return (
      <div className="login-page" style={{ minHeight: 400 }}>
        <div className="login-box" style={{ height: "50vh" }}>
          <div className="login-logo">
            <a href="../../index2.html">
              <b>Phenikaa</b> Admin
            </a>
          </div>
          <div className="card login-card-body">
            <p className="login-box-msg">
              {this.state.alert || "Sign in to start your"}{" "}
              <a
                href="/test-route"
                style={{ textDecoration: "none", color: "black" }}
              >
                {" "}
                session
              </a>
            </p>

            <form onSubmit={(e) => this.handleSubmit(e)}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  value={this.state.username}
                  className="form-control"
                  placeholder="Username"
                  onChange={(event) =>
                    this.handleUsernameChange(event.target.value)
                  }
                ></input>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="fas fa-user"></i>
                  </span>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  value={this.state.password}
                  className="form-control"
                  placeholder="Password"
                  onChange={(event) =>
                    this.handlePasswordChange(event.target.value)
                  }
                ></input>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="fas fa-lock"></i>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="icheck-primary">
                    <input type="checkbox" id="remember" />
                    &nbsp;
                    <label htmlFor="remember">Remember Me</label>
                  </div>
                </div>

                <div className="col-4">
                  <Button type="submit" className="btn btn-primary btn-block">
                    Sign In
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
