import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"

class SitemapDeleteConfirm extends Component {
    constructor(props) {
        super(props)

        this.deleteSelectedSitemap = this.deleteSelectedSitemap.bind(this)
        this.onOpenModal = this.onOpenModal.bind(this)
        // this.onHideModal = this.onHideModal.bind(this)
    }

    deleteSelectedSitemap() {
        this.props.deleteSitemap()
            .then(this.props.onHide)
    }

    onOpenModal() {
        //console.log("modal open")
    }

    render() {

        const generalList = this.props.selectedSitemap.map((item, index) => {
            return (<li key={index}>{item.path}</li>)
        })

        return (
            <Modal show={this.props.show} onHide={() => this.props.onHide()} onShow={() => this.onOpenModal()}>
                {this.props.loadingModal}
                <Modal.Header closeButton className="bg-danger">
                    <Modal.Title>Confirm Delete Elements </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p> Are you sure want to delete this Sitemap ? </p>
                    <ul>
                        {generalList}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.props.onHide()}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={this.deleteSelectedSitemap}>
                        <i className="fa fa-trash">&nbsp;</i>
                        Delete Selected Elements
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default SitemapDeleteConfirm;