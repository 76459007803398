import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
import AlertHelper from "./../../lib/AlertHelper";
import TemplateApi from "./../../api/TemplateApi";
import { withTranslation } from "react-i18next";

class TemplateAddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      name: "",
      screenshot: "",
      transPage: "modals.template_add.",
      transAlert: "alert.",
      isActive: false,
      status: false,
      currentStatus: false,
      active: false,
      check: null,
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleActiveTemplate = this.handleActiveTemplate.bind(this);
    this.handleScreenshotFile = this.handleScreenshotFile.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.getTemplateDetail = this.getTemplateDetail.bind(this);
  }

  onOpenModal() {
    // console.log("props active", this.props.status_active);
    this.setState(
      {
        id: this.props.id,
        status: this.props.status_active,
        id_site: parseInt(this.props.id_site),
      },
      () => {
        if (this.state.id) {
          this.getTemplateDetail();
        }
      }
    );
  }

  handleNameChange(value) {
    this.setState({
      name: value,
    });
  }

  handleActiveTemplate(value) {
    if (value === "check") {
      this.setState({
        currentStatus: true,
      });
    }
  }

  handleScreenshotFile(e) {
    this.setState({
      site: e.target.files[0],
    });
  }

  getTemplateDetail() {
    const objTemplate = new TemplateApi();

    objTemplate.getTemplateDetail(this.state.id).then((res) => {
      this.setState({
        name: res.data.name,
      });
    });
  }

  updateTemplateNew() {
    const objTemplate = new TemplateApi();
    const id = this.props.id;
    const formData = new FormData();

    formData.append("name", this.state.name);
    formData.append("screenshot", this.state.screenshot);
    // console.log(this.state.status);

    objTemplate
      .updateTemplateNew(id, formData)
      .then((res) => {
        swal("Success !", "You successfully updated this template", "success");

        //   this.props.getSites();
        setTimeout(() => {
          window.location.reload();
        }, 0);
      })
      .catch((err) => {
        const objAlert = new AlertHelper();
        const resErr = err?.response?.data ? err?.response?.data : err.message;
        objAlert.errorHandlerAlert(resErr);
      });

    // if (this.state.currentStatus === true) {
    //   objTemplate
    //     .updateTemplateNew(id, formData)
    //     .then((res) => {
    //       this.updateActiveTemplate();
    //       swal(
    //         "Success !",
    //         "You successfully added page in this template",
    //         "success"
    //       );
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 0);
    //     })
    //     .catch((err) => {
    //       const objAlert = new AlertHelper();
    //       const resErr = err?.response?.data
    //         ? err?.response?.data
    //         : err.message;
    //       objAlert.errorHandlerAlert(resErr);
    //     });
    // } else {
    //   objTemplate
    //     .updateTemplateNew(id, formData)
    //     .then((res) => {
    //       swal(
    //         "Success !",
    //         "You successfully updated this template",
    //         "success"
    //       );

    //       //   this.props.getSites();
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 0);
    //     })
    //     .catch((err) => {
    //       const objAlert = new AlertHelper();
    //       const resErr = err?.response?.data
    //         ? err?.response?.data
    //         : err.message;
    //       objAlert.errorHandlerAlert(resErr);
    //     });
    // }
  }

  updateActiveTemplate() {
    const objTemplate = new TemplateApi();

    const data = {
      site_id: this.state.id_site,
      template_ids: this.state.id,
    };

    objTemplate
      .updateActiveTemplate(data)
      .then((res) => {
        // console.log("res data", res.data);
      })
      .catch((err) => {
        const objAlert = new AlertHelper();
        const resErr = err?.response?.data ? err?.response?.data : err.message;
        objAlert.errorHandlerAlert(resErr);
      });
  }

  render() {
    const { t } = this.props;
    var HeaderTitle = t(this.state.transPage + "title_add");
    var BtnSubmit = (
      <Button variant="primary" onClick={() => this.createTemplateNew()}>
        <i className="fa fa-plus">&nbsp;</i>
        {t(this.state.transPage + "add_template")}
      </Button>
    );

    if (this.state.id) {
      HeaderTitle = t(this.state.transPage + "title_update");
      BtnSubmit = (
        <Button variant="primary" onClick={() => this.updateTemplateNew()}>
          <i className="fa fa-edit">&nbsp;</i>
          {t(this.state.transPage + "update_template")}
        </Button>
      );
    }

    return (
      <Modal
        show={this.props.show}
        onHide={() => this.props.onHide()}
        onShow={() => this.onOpenModal()}
      >
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title> {HeaderTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label> {t("name")} </label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={this.state.name}
              onChange={(event) => this.handleNameChange(event.target.value)}
            ></input>
          </div>

          <div className="form-group">
            <label> {t("screenshot")} </label>
            <input
              type="file"
              className="form-control"
              name=""
              value=""
              onChange={(e) => this.handleScreenshotFile(e)}
            />
          </div>
          {/* <Form.Group controlId="checkTemplateOnModal">
            <Form.Check
              type="radio"
              label="Active"
              onChange={(e) => this.handleActiveTemplate(e.target.value)}
              checked={
                this.state.currentStatus === this.state.status ? false : true
              }
              defaultValue={"check"}
            />
          </Form.Group> */}
        </Modal.Body>
        <Modal.Footer>{BtnSubmit}</Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(TemplateAddModal);
