import React, { Component } from 'react';
import { Modal, Button, Tabs, Tab } from "react-bootstrap"
import swal from "sweetalert"

import JobApi from "./../../api/JobApi"
import GeneralApi from "../../api/GeneralApi"
import { withTranslation } from 'react-i18next';
import Context from "../Context";

class CurrencyAddModal extends Component {
    static contextType = Context;
    constructor(props) {
        super(props)
        this.state = {
            currency: "",
            currencyLanguages: [],
            languageList: [],

            tabKey: 1,
            selectedLanguage: 1,
            idElement: 0,
            renderTabLanguage: "",
            transPage: "modals.currency_add.",
            transAlert: "alert."
        }

        this.onOpenModal = this.onOpenModal.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)
        this.handleChangeCurrency = this.handleChangeCurrency.bind(this)

        this.handleChangeTab = this.handleChangeTab.bind(this)

        this.addCurrency = this.addCurrency.bind(this)
        this.updateCurrency = this.updateCurrency.bind(this)

        this.renderTabLanguage = this.renderTabLanguage.bind(this)
    }

    onOpenModal() {
        const { languagesList } = this.context;

        this.setState({
            id: this.props.currencyId | 0,
            tabKey: 1,
            selectedLanguage: 1,
            languageList: languagesList
        }, () => {
            if (this.props.currencyId) {
                this.getDetailCurrency()
            } else {
                this.renderTabLanguage()
            }
        })
    }

    onCloseModal() {
        this.setState({
            currency: "",
            currencyLanguages: [],
            languageList: [],

            tabKey: 1,
            selectedLanguage: 1,
            idElement: 0,
            renderTabLanguage: ""
        }, () => {
            this.props.callback()
            this.props.onHide()
        })


    }

    handleChangeCurrency(id, value) {
        let currency_languages = [...this.state.currencyLanguages]
        let currency_language = { ...currency_languages[id] }
        currency_language.name = value
        currency_languages[id] = currency_language

        this.setState({
            currency: currency_languages[0].name,
            currencyLanguages: currency_languages
        }, () => {
            // console.log(this.state.jobTypeLanguages)
        })
    }

    handleChangeTab(value) {

        this.setState({

            selectedLanguage: value,
            tabKey: value,
        }, () => {
            this.renderTabLanguage()
        })
    }

    getDetailCurrency() {
        const obJob = new JobApi()

        obJob.getDetailCurrency(this.props.currencyId)
            .then(res => {

                // alert(JSON.stringify(res.data))

                this.setState({
                    id: res.data.id,
                    name: res.data.name,
                    currencyLanguages: res.data.currency_languages,
                }, () => {

                    this.renderTabLanguage()
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }


    addCurrency() {
        const { t } = this.props;
        const obJob = new JobApi()

        const request = {
            name: this.state.currency,
            currency_languages: this.state.currencyLanguages
        }

        obJob.addCurrency(request)
            .then(res => {
                swal("Success!", t(this.state.transAlert + "currency_add_success"), "success")
                this.onCloseModal()
            })
            .catch(err => {
                swal("Error!", err.message, "error")
            })

    }

    updateCurrency() {
        const { t } = this.props;
        const obJob = new JobApi()

        const request = {
            name: this.state.currency,
            currency_languages: this.state.currencyLanguages
        }

        obJob.updateCurrency(this.props.currencyId, request)
            .then(res => {
                swal("Success!", t(this.state.transAlert + "currency_update_success"), "success")
                this.onCloseModal()
            })
            .catch(err => {
                swal("Error!", err.message, "error")
            })
    }

    renderTabLanguage() {

        const renderTabLanguage = this.state.languageList.map(item => {
            var result = this.state.currencyLanguages.find(currencyLang => item.id === currencyLang.language)

            if (!result) {
                result = {
                    language: item.id,
                    name: "",
                    currency: null,
                    languageName: item.name
                }
            }

            result.languageName = item.name
            return result
        })

        this.setState({
            currencyLanguages: renderTabLanguage
        }, () => {
            //console.log("jobTypeLanguages : ",this.state.jobTypeLanguages)
        })


    }

    render() {
        const { t } = this.props;
        var HeaderText = <Modal.Title> {t(this.state.transPage + 'add_currency')} </Modal.Title>

        var SubmitButton = <Button variant="primary" onClick={() => this.addCurrency()}>
            <i className="fa fa-plus">&nbsp;</i>
            {t(this.state.transPage + 'add_currency')}
        </Button>

        if (this.props.currencyId) {
            HeaderText = <Modal.Title> {t(this.state.transPage + 'update_currency')} </Modal.Title>
            SubmitButton = <Button variant="primary" onClick={() => this.updateCurrency()}>
                <i className="fa fa-plus">&nbsp;</i>
                {t(this.state.transPage + 'update_currency')}
            </Button>
        }

        const TabLanguage = this.state.currencyLanguages.map((item, index) => {


            return (

                <Tab key={index} eventKey={item.language} title={item.languageName}>
                    <br></br>

                    <div className="form-group">
                        <label> {t('currency')} </label>
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.currencyLanguages[index].name}
                            onChange={(event) => this.handleChangeCurrency(index, event.target.value)}
                        ></input>
                        {/* { JSON.stringify( this.state.jobTypeLanguages )  } */}
                    </div>

                </Tab>
            )



        })

        return (
            <Modal show={this.props.show} onHide={this.props.onHide} onShow={this.onOpenModal}>
                <Modal.Header closeButton className="bg-primary">
                    {HeaderText}
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <Tabs
                                id="controlled-tab-example-3"
                                activeKey={this.state.tabKey}
                                onSelect={(k) => this.handleChangeTab(k)}
                            >

                                {TabLanguage}


                            </Tabs>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>

                    {SubmitButton}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(CurrencyAddModal);