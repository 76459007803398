import React, { Component } from "react";
import { Modal, Button, Tab, Tabs } from "react-bootstrap";
import swal from "sweetalert";
// import withReactContent from "sweetalert2-react-content";

import PageApi from "../../api/PageApi";
// import MenuApi from "../../api/MenuApi"
import SectionApi from "../../api/SectionApi";
import SectionCategoryApi from "../../api/SectionCategoryApi";
import GeneralApi from "../../api/GeneralApi";

import AlertHelper from "./../../lib/AlertHelper";
import { withTranslation } from "react-i18next";

import Context from "../../pages/Context"

class PageSectionCatalogAdd extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      pageId: 0,
      name: "",
      pages: [],

      index: 0,
      file: {
        name: "",
      },

      selectedSections: [],

      names: [],
      languageList: [],
      tabKey: 1,
      selectedLanguage: 1,
      renderTabLanguage: "",

      categories: [],
      category: "",
      transPage: "modals.section_catalog_add.",
      transAlert: "alert.",
      file_ss: {},
      file_metadata: {},
      isAvailableValue: null,
      isAvailableMetadata: null,
      isAvailableImages: null,
      isDisplayMetadata: null,
      isImages: false,
      errMsg: "",
      isDone: false,
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    // this.handleMenuChange = this.handleMenuChange.bind(this)
    // this.handlePageChange = this.handlePageChange.bind(this)
    // this.handleIndexChange = this.handleIndexChange.bind(this)

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);

    this.getPages = this.getPages.bind(this);
    this.getAllSectionCategories = this.getAllSectionCategories.bind(this);
    // this.setSelectedItem = this.setSelectedItem.bind(this)
    this.sectionCatalogDetail = this.sectionCatalogDetail.bind(this);
  }

  componentDidUpdate() {
    var isDone = false;
    if (this.state.isDone !== isDone) {
      this.setState({
        isDone: isDone,
      });
    }
  }

  handleNameChange(id, value) {
    let names = [...this.state.names];
    let name = { ...names[id] };
    name.name = value;
    names[id] = name;

    this.setState(
      {
        name: names[0].name,
        names: names,
      },
      () => {
        // console.log(names)
      }
    );
  }

  handleFileChange(e, key) {
    if (key === "zip") {
      this.setState({
        file: e.target.files[0],
        isAvailableValue: e.target.value,
      });
    } else if (key === "metadata") {
      this.setState({
        file_metadata: e.target.files[0],
        isAvailableMetadata: e.target.value,
      });
    } else if (key === "images") {
      if (e.target.files) {
        if (
          e.target.files[0].name === "screenshot.jpg" ||
          e.target.files[0].name === "screenshot.png" ||
          e.target.files[0].name === "screenshot.jpeg"
        ) {
          this.setState({
            errMsg: "",
          });
        } else {
          this.setState({
            errMsg: "Please input file with name `screenshot.png/.jpg/.jpeg`",
          });
        }
        this.setState({
          file_ss: e.target.files[0],
          isAvailableImages: e.target.value,
        });
      }
    }
  }

  onOpenModal() {
    const { languagesList } = this.context;
    this.getAllSectionCategories(() => {
      this.setState(
        {
          languageList: languagesList,
        },
        () => {
          const _this = this;
          const templateId = localStorage.getItem("templateId");

          this.getPages({
            template: templateId,
          });

          this.setState(
            {
              id: this.props.id,
              isAvailableValue: null,
              isDisplayMetadata: null,
              isAvailableImages: null,
            },
            () => {
              if (this.state.id) {
                _this
                  .sectionCatalogDetail(this.state.id)
                  .then((res) => {
                    this.setState(
                      {
                        name: res.data.name,
                        // pageId:res.data.page,
                        index: res.data.index,
                        names: res.data.section_languages,
                        category: res.data.category ? res.data.category : "",
                      },
                      () => {
                        this.renderTabLanguage();
                      }
                    );
                  })
                  .catch((err) => {
                    const objAlert = new AlertHelper();
                    const resErr = err?.response?.data
                      ? err?.response?.data
                      : err.message;
                    //console.log(" Error => ",err?.response?.data)
                    objAlert.errorHandlerAlert(resErr);
                  });
              } else {
              }
            }
          );
        }
      );
    });
  }

  onCloseModal() {
    this.setState(
      {
        id: 0,
        pageId: 0,
        name: "",
        pages: [],
      },
      () => {
        this.props.onHide();
      }
    );
  }

  getPages(params) {
    const objPage = new PageApi();

    objPage.getAllPages(params).then((res) => {
      const result = res.data.results.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });

      this.setState({
        pages: result,
      });
    });
  }

  getAllSectionCategories(callback) {
    const sectionObj = new SectionCategoryApi();

    sectionObj.getAllSectionCategories().then((res) => {
      this.setState(
        {
          categories: res.data.results,
        },
        callback
      );
    });
  }

  sectionCatalogDetail(sectionId) {
    const objSection = new SectionApi();

    return objSection.sectionCatalogDetail(sectionId);
  }

  handleSubmit() {
    const objSection = new SectionApi();
    const { t } = this.props;
    const formData = new FormData();

    if (this.state.file.name) {
      formData.append("zip_file", this.state.file);
    }

    formData.append("category", this.state.category);
    formData.append("page", 72);
    formData.append("name", this.state.name);
    this.state.names.forEach((item, index) => {
      formData.append(`section_languages[${index}]language`, item.language);
      formData.append(`section_languages[${index}]name`, item.name);
    });

    objSection
      .sectionAddCatalog(formData)
      .then((res) => {
        this.props.onHide();
        swal(
          "Success !",
          t(this.state.transAlert + "section_add_success"),
          "success"
        );
        this.props.getSectionCatalog();

        // setTimeout(()=>{
        //     window.location.reload()
        // },0)
      })
      .catch((err) => {
        const objAlert = new AlertHelper();
        const resErr = err?.response?.data ? err?.response?.data : err.message;
        //console.log(" Error => ",err?.response?.data)
        objAlert.errorHandlerAlert(resErr);
      });
  }

  handleSectionUpdate() {
    const { t } = this.props;
    const objSection = new SectionApi();
    const sectionId = this.state.id;
    const formData = new FormData();

    if (this.state.file.name) {
      formData.append("zip_file", this.state.file);
      formData.append("category", this.state.category);
      formData.append("page", 72);
      formData.append("name", this.state.name);
      this.state.names.forEach((item, index) => {
        formData.append(`section_languages[${index}]language`, item.language);
        formData.append(`section_languages[${index}]name`, item.name);
      });
    } else {
      if (this.state.file_ss && !this.state.isAvailableMetadata) {
        formData.append("screenshot", this.state.file_ss);
        formData.append("category", this.state.category);
        formData.append("page", 72);
        formData.append("name", this.state.name);
        this.state.names.forEach((item, index) => {
          formData.append(`section_languages[${index}]language`, item.language);
          formData.append(`section_languages[${index}]name`, item.name);
        });
      } else if (this.state.file_metadata && !this.state.isAvailableImages) {
        console.log("metadata available--");
        formData.append("metadata", this.state.file_metadata);
        formData.append("category", this.state.category);
        formData.append("page", 72);
        formData.append("name", this.state.name);
        this.state.names.forEach((item, index) => {
          formData.append(`section_languages[${index}]language`, item.language);
          formData.append(`section_languages[${index}]name`, item.name);
        });
      } else if (this.state.file_metadata && this.state.file_ss) {
        formData.append("metadata", this.state.file_metadata);
        formData.append("category", this.state.category);
        formData.append("page", 72);
        formData.append("name", this.state.name);
        this.state.names.forEach((item, index) => {
          formData.append(`section_languages[${index}]language`, item.language);
          formData.append(`section_languages[${index}]name`, item.name);
        });
        formData.append("screenshot", this.state.file_ss);
        formData.append("metadata", this.state.file_metadata);
      }
    }

    objSection
      .sectionCatalogUpdate(sectionId, formData)
      .then((res) => {
        console.log(res.data);
        this.props.onHide();
        swal(
          "Success !",
          t(this.state.transAlert + "section_update_success"),
          "success"
        );
        this.setState({
          isDone: true,
        });
      })
      .catch((err) => {
        const objAlert = new AlertHelper();
        const resErr = err?.response?.data ? err?.response?.data : err.message;
        objAlert.errorHandlerAlert(resErr);
      });
  }

  renderTabLanguage() {
    const renderTabLanguage = this.state.languageList.map((item, index) => {
      var findName = this.state.names.find(
        (textLang, idx) => item.id === textLang.language
      );
      //console.log("item ", findName)
      return {
        language: item.id,
        languageName: item.name,
        name: findName?.name,
      };
    });

    this.setState({
      names: renderTabLanguage,
    });
  }

  handleChangeTab(value) {
    this.setState(
      {
        selectedLanguage: value,
        tabKey: value,
      },
      () => {
        this.renderTabLanguage();
      }
    );
  }

  handleCategoryChange(event) {
    this.setState({ category: event.target.value });
  }

  render() {
    var isImage = this.state.isAvailableValue !== null ? true : false;
    var isMeta = this.state.isAvailableValue !== null ? true : false;
    const { t } = this.props;

    let FormTwo, SectionName, HeaderText, BtnSubmit;
    // let isNone = this.state.isAvailableValue !== null ? "none" : "inherit";
    let isNoneZIP =
      this.state.isAvailableImages !== null &&
        this.state.isAvailableMetadata !== null
        ? "none"
        : "inherit";

    const TabLanguage = this.state.names.map((item, index) => {
      return (
        <Tab key={index} eventKey={item.language} title={item.languageName}>
          <br></br>

          <div className="form-group">
            <label> {t("name")} </label>
            <textarea
              className="form-control"
              onChange={(event) =>
                this.handleNameChange(index, event.target.value)
              }
              value={this.state.names[index].name}
            ></textarea>
            {/* { JSON.stringify( this.state.text_languages )  } */}
          </div>
        </Tab>
      );
    });

    if (this.state.id) {
      SectionName = (
        <div className="form-group">
          <Tabs>{TabLanguage}</Tabs>
        </div>
      );

      HeaderText = t(this.state.transPage + "update_section");
      BtnSubmit = (
        <Button variant="primary" onClick={() => this.handleSectionUpdate()}>
          <i className="fa fa-edit">&nbsp;</i>
          {t(this.state.transPage + "update_section")}
        </Button>
      );

      FormTwo = (
        <>
          {" "}
          <div className="form-group">
            <label> {t("screenshot")} </label>
            <input
              type="file"
              className="form-control"
              onChange={(event) => this.handleFileChange(event, "images")}
              accept=".svg,.png,.jpg,.jpeg"
              disabled={isImage}
            ></input>
            <p style={{ color: "#b60a0a" }}>{this.state.errMsg}</p>
          </div>
          <div className="form-group">
            <label> {t("metadata")} </label>
            <input
              type="file"
              className="form-control"
              onChange={(event) => this.handleFileChange(event, "metadata")}
              accept=".json"
              disabled={isMeta}
            ></input>
          </div>
        </>
      );
    } else {
      HeaderText = t(this.state.transPage + "add_section");
      BtnSubmit = (
        <Button variant="primary" onClick={() => this.handleSubmit()}>
          <i className="fa fa-plus">&nbsp;</i>
          {t(this.state.transPage + "add_section")}
        </Button>
      );
      SectionName = "";
      FormTwo = <></>;
    }

    const CategoryOptions = this.state.categories.map((item) => {
      const langId = localStorage.getItem("lang") === "en" ? 0 : 1;
      return (
        <option key={item.id + item.name} value={item.id}>
          {item.category_languages[langId].name}
        </option>
      );
    });

    return (
      <Modal
        show={this.props.show}
        onHide={() => this.onCloseModal()}
        onShow={() => this.onOpenModal()}
      >
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title> {HeaderText} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {SectionName}

            <div className="form-group" style={{ display: isNoneZIP }}>
              <label> {t("zip_file")} </label>
              <input
                type="file"
                className="form-control"
                onChange={(event) => this.handleFileChange(event, "zip")}
                accept=".zip,.rar,.7zip"
              ></input>
            </div>

            <div className="form-group">
              <label htmlFor="select-category">{t("category")}</label>
              <select
                className="form-control"
                id="select-category"
                onChange={(event) => this.handleCategoryChange(event)}
                value={this.state.category}
              >
                <option value="">{t("none")}</option>
                {CategoryOptions}
              </select>
            </div>
            {FormTwo}
          </form>
        </Modal.Body>
        <Modal.Footer>{BtnSubmit}</Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(PageSectionCatalogAdd);
