import React, { Component } from 'react';
import GeneralApi from "./../../api/GeneralApi"

import AssetElementItem from "./AssetElementItem"
import TextElementItem from "./TextElementItem"
import ArticleElementItem from "./ArticleElementItem"

import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import WidgetApi from '../../api/WidgetApi';
import Context from "../Context";

class GeneralElement extends Component {
    static contextType = Context;
    constructor(props) {
        super(props)

        this.state = {
            generals: [],
            // selectedGeneralId:0,
            languages: [],
            selectedItem: [],
            selectedGeneralId: {}

        }

        // this.getGeneralData = this.getGeneralData.bind(this)
        this.getLanguages = this.getLanguages.bind(this)
        this.onSortEnd = this.onSortEnd.bind(this)
        this.handleCheckedGeneral = this.handleCheckedGeneral.bind(this)


    }

    componentDidMount() {
        this.getLanguages()
        // this.getGeneralData()
    }

    componentWillReceiveProps(newProps) {
        // JANGAN DI UNCOMMENT INI 
        // this.getWidgetDetail(newProps.widgetId)


        // FOR SELECTED CHECKBOX
        // this.state.selectedArticleId
        /*
            {
                {55:true},
                {65:true}
                ....
            }
        */
        var res = {}
        for (var i = 0; i < newProps.selectedGeneral.length; i++) {
            res[newProps.selectedGeneral[i].id] = true
        }

        this.setState({
            selectedGeneralId: res
        }, () => {
            // console.log("state selectedId => ", this.state.selectedArticleId)
        })
        // =============================================================
    }

    getLanguages() {
        const { languagesList } = this.context

        this.setState({
            languages: languagesList,
            generals: this.props.generals
        })
    }

    handleCheckItem() {

    }

    // getWidgetDetail(neoWidgetId) {

    //     const objWidget = new WidgetApi()
    //     const widgetId = neoWidgetId

    //     const params = {
    //         section:this.props.section
    //     }

    //     objWidget.getDetailWidget(widgetId, params)
    //     .then(res => {

    //         // console.log(" ==> ", res.data)

    //        this.setState({
    //            generals:res.data.articles
    //        })

    //     })
    //     .catch(err => {
    //         console.log(err.message)
    //     })
    // }

    onSortEnd({ oldIndex, newIndex }) {

        const objWidget = new WidgetApi()
        const widgetId = this.props.widgetId
        // const generals = this.props.generals

        this.setState(({ generals }) => ({
            generals: arrayMove(generals, oldIndex, newIndex),
        }), () => {


            const ids = this.state.generals.map(item => {

                var id = item.id
                if (item.type === "Media") {
                    id = item.asset
                }

                return ({
                    id: id,
                    category: item.type
                })
            })

            const data = {
                widget_id: widgetId,
                elements: ids
            }

            console.log(" ===> ", this.state.generals)
            // alert("do something")
            objWidget.widgetElementIndexUpdate(data)
                .then(res => {

                    const newGeneral = [].concat(res.data.text_elements.map(item => {
                        return ({
                            ...item,
                            type: "Text"
                        })
                    })).concat(res.data.widget_assets.map(item => {
                        return ({
                            ...item,
                            type: "Media"
                        })
                    })).concat(res.data.articles.map(item => {
                        return ({
                            ...item,
                            type: "Article"
                        })
                    })).sort((a, b) => a.index - b.index)

                    this.setState({
                        generals: newGeneral
                    })



                })
                .catch(err => {
                    console.log(err)
                })

        });
    };

    shouldCancelStart(e) {
        //alert(JSON.stringify(e))
        // Cancel sorting if the event target is an anchor tag (`a`)
        if (e.target.tagName.toLowerCase() === 'a') {
            return true; // Return true to cancel sorting
        }
    }

    checkSelectedArticle(value) {


        return this.state.selectedArticles.findIndex(item => item.id === value.id) > 0
    }

    handleCheckedGeneral(value) {


        // console.log(" value : ", this.state.selectedArticleId)
        var checked = this.props.selectedGeneral.find(element => {
            // console.log(element.id," 8D ",value.id)    
            return element.id === value.id
        })

        // because checked from checkbox doesnt work 
        if (typeof (checked) === "undefined") {
            checked = true
        } else {
            checked = false
        }

        // console.log("checked ==> ", checked)

        this.props.setSelectedArticle("article", value, checked)
    }

    render() {

        const SortableItem = sortableElement((value, index) => {

            const item = value.item

            if (item.type === "Text") {
                return <TextElementItem
                    key={index}
                    item={item}
                    languages={this.state.languages}
                    setTextId={this.props.setTextId}
                    setSelectedGeneral={this.props.setSelectedGeneral}
                    handleCheckedGeneral={this.handleCheckedGeneral}
                ></TextElementItem>
            } else if (item.type === "Media") {
                return <AssetElementItem
                    key={index}
                    item={item}
                    setSelectedGeneral={this.props.setSelectedGeneral}
                    handleCheckedGeneral={this.handleCheckedGeneral}
                ></AssetElementItem>
            } else if (item.type === "Article") {
                return <ArticleElementItem
                    key={index}
                    widgetId={this.props.widgetId}
                    item={item}
                    setSelectedGeneral={this.props.setSelectedGeneral}
                    languages={this.state.languages}
                    handleCheckedGeneral={this.handleCheckedGeneral}
                ></ArticleElementItem>
            } else {
                return (<div key={index} className="">{item.type} </div>)
            }
            //return (<div className="">{ item.type } </div>)
        })

        // const GeneralElementContent = this.props.generals.map((item,index) => {

        //     // console.log("item type", item.type)

        //     if(item.type === "text") {
        //         return <TextElementItem 
        //             key={index}
        //             item={item}
        //             languages={this.state.languages}
        //             setTextId={this.props.setTextId}
        //             setSelectedGeneral={this.props.setSelectedGeneral}
        //         ></TextElementItem>
        //     } else if(item.type === "asset") {
        //         return <AssetElementItem 
        //             key={index}
        //             item={item}
        //             setSelectedGeneral={this.props.setSelectedGeneral}

        //         ></AssetElementItem>
        //     } else if(item.type === "article") {
        //         return <ArticleElementItem 
        //             key={index}
        //             widgetId={this.props.widgetId}
        //             item={item}
        //             setSelectedGeneral={this.props.setSelectedGeneral}
        //             languages={this.state.languages}

        //         ></ArticleElementItem>
        //     } else {
        //         return (<div key={index} className="">{ item.type } </div>) 
        //     }
        //     //return (<div className="">{ item.type } </div>)
        // })

        // return (
        //     <div className="row">

        //         {GeneralElementContent}
        //     </div>
        // );

        const SortableContainer = sortableContainer(({ children }) => {
            return <ul className="row" > {children}</ul>;
        });

        //const {generals} = this.props;

        return (
            <div style={{ marginLeft: "-2%" }}>
                {/* { JSON.stringify(this.state.generals)} */}
                <SortableContainer axis="xy" onSortEnd={this.onSortEnd} distance={1} shouldCancelStart={(e) => this.shouldCancelStart(e)}>
                    {this.state.generals.map((item, index) => (
                        <SortableItem key={`item-${index}`} index={index} item={item} />
                    ))}

                </SortableContainer>
            </div>
        );
    }
}

export default GeneralElement;