import React, { Component } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Modal, Card, Button } from "react-bootstrap"
import Pagination from "react-js-pagination";

import LocationApi from "../../api/LocationApi"

import { shortSentence } from "../../lib/StringHelper"

class WidgetArticleAddLocationModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            // gMapApiKey: 'AIzaSyA6aQOcCYM5yXimMPDdTCopJD9wT0324L8',
            gMapApiKey: "AIzaSyCigRL-AQS62AdkaUj42rp9OX_5Mbze8PM",
            mapsLib: ["drawing", "places"],

            articleId: 0,
            locations: [],
            selectedLocations: [],
            activeLocation: null,
            pairDisable: true,
            loadingModal: "",
            search: "",

            activePage: 1,
            limitPageData: 10,
            totalCount: 0
        }
        this.showCurrentLocation = this.showCurrentLocation.bind(this)
        this.getAllLocation = this.getAllLocation.bind(this)
        this.handleSelectedLocation = this.handleSelectedLocation.bind(this)
        this.onShowModal = this.onShowModal.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
    }

    onShowModal() {
        this.showCurrentLocation()
        this.getAllLocation()
        this.setState({
            articleId: this.props.articleId,
            locationId: this.props.locationId,
            activeLocation: `label-modal-${this.props.locationId}`,
            search: ""
        })
    }

    showCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    this.setState(prevState => ({
                        currentLatLng: {
                            ...prevState.currentLatLng,
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        }
                    }))
                }
            )
        } else {
            console.log('error')
        }
    }

    getAllLocation() {
        const objLocation = new LocationApi()

        return objLocation.getAllLocations({
            // type: 'Image',
            page_size: this.state.limitPageData,
            page: this.state.activePage,
            search: this.state.search
        })
            .then(res => {
                this.setState({
                    totalCount: res.data.count,
                    locations: res.data.results,
                })
            })
    }

    handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,

        }, () => {
            this.getAllLocation()
        });
    }

    handleSelectedLocation(location, value, id) {
        this.setState((prevState) => {
            return {
                selectedLocations: prevState.activeLocation !== id ? location : false,
                activeLocation: prevState.activeLocation !== id ? id : false
            }
        }, () => {
            // this.props.selectedAsset(this.props.thumbnailType, this.state.selectedLocations.asset_file, value)
            if (this.state.selectedLocations) {
                this.setState({
                    pairDisable: false,
                })
            } else {
                this.setState({
                    pairDisable: true,
                })
            }
        })
    }

    handleSearchChange(value) {
        console.log(value)
        this.setState({
            activePage: 1,
            search: value
        }, () => {
            this.getAllLocation()
        })
    }

    pairLocation = () => {
        this.props.pairLocationWithArticle(this.state.selectedLocations)
    }

    render() {
        const containerStyle = {
            width: '100%',
            height: '20vh'
        };

        const LocationList = this.state.locations.length > 0 ? this.state.locations.map((item, i) => {
            let defaultCenter
            if ((!item.latitude && !item.longitude) || (parseFloat(item.latitude) === 0 && parseFloat(item.longitude) === 0)) {
                defaultCenter = this.state.currentLatLng
            } else {
                defaultCenter = {
                    lat: parseFloat(item.latitude),
                    lng: parseFloat(item.longitude)
                }
            }

            return (
                <div key={item.id} className="col-md-6">
                    <Card style={{ backgroundColor: this.state.activeLocation === ("label-modal-" + item.id) ? '#e9e9e9' : '#fff' }}>
                        <Card.Header className="" style={{ padding: "1rem 0.5rem 0" }}>
                            <div className="form-check form-check-inline" style={{
                                position: "relative",
                                width: "100%",
                                overflow: "hidden"
                            }}>
                                <input type="radio" name="myCheckbox" checked={this.state.activeLocation === ("label-modal-" + item.id) ? true : false} style={{ position: "relative", margin: "auto 0.5rem auto 0" }} className="form-check-input" id={"label-modal-" + item.id} onClick={(event) => this.handleSelectedLocation(item, item.id, event.target.id)} ></input>
                                <label className="form-check-label" style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "100%",
                                }} htmlFor={"label-modal-" + item.id}>{item.company_name}</label>
                            </div>

                        </Card.Header>

                        <Card.Body style={{ padding: "1rem .5rem" }}>
                            <GoogleMap
                                ref={this.myMap}
                                mapContainerStyle={containerStyle}
                                center={defaultCenter}
                                zoom={10}
                            >
                                <Marker
                                    position={defaultCenter}
                                />
                            </GoogleMap>
                        </Card.Body>

                        <Card.Footer style={{
                            fontSize: 16,
                            margin: "auto auto 1rem .5rem",
                            padding: "0",
                            textAlign: "left",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100%",
                            backgroundColor: "white"
                        }}>
                            <div align="center" style={{ fontSize: 12 }}>{shortSentence((item.address || item.location_languages[i].address), 6)} ...</div>
                        </Card.Footer>
                    </Card>
                </div>
            )
        }) : <div className="col-md-12 center-block"><h4 className="text-center" >There is no Location</h4></div>

        return (
            <Modal show={this.props.show} onHide={() => this.props.onHide()} size="xl" onShow={() => this.onShowModal()}>
                {this.state.loadingModal}
                <Modal.Header closeButton className="bg-primary">
                    Select Widget Location's
                </Modal.Header>

                <Modal.Body>
                    <div className="d-flex flex-row" width="100%" style={{ padding: "0 2rem" }}>
                        <div className="form-group" style={{ width: "30%" }}>
                            <label>Search</label>
                            <input
                                type="text"
                                placeholder="Search..."
                                className="form-control"
                                name="search"
                                value={this.state.search}
                                onChange={(event) =>
                                    this.handleSearchChange(event.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div style={{ padding: "0 2rem" }}>
                        {" "}
                        <hr />
                    </div>
                    <div className="clearfix"></div>
                    <div className="row" style={{ padding: "0 2rem" }}>
                        {LocationList}
                    </div>
                    <div id="pagination_custom" style={{ float: "left", padding: "0 2rem", margin: "2rem 0" }}>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.limitPageData}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={4}
                            onChange={this.handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                            prevPageText="Prev"
                            nextPageText="Next"
                        />
                    </div>
                    <div className="clearfix"></div>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={this.state.pairDisable} onClick={this.pairLocation}> <i className="fa fa-paperclip">&nbsp;</i> Pair These Location </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default WidgetArticleAddLocationModal;