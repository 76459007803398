import React, { Component } from 'react';
import { Card } from "react-bootstrap"
// import { Link } from "react-router-dom"
// import Card,{ CardHeader, CardBody  } from "./../../components/Card";
// import { Dropdown } from "react-bootstrap"

import { shortSentence } from "./../../lib/StringHelper"


class ArticleElementItem extends Component {

    render() {
            const value = this.props.item

            var index = this.props.languages.findIndex( item => item.abbreviation === localStorage.getItem("lang") )
            //const findArticle = this.props.articles.find(row => row.id === value.id)
            const findArticle = value
            
            var scontent = findArticle?.article_languages[index] ?  findArticle.article_languages[index]?.content : ""
            var stitle = findArticle?.article_languages[index] ? findArticle.article_languages[index]?.title : ""

            var shortContent = shortSentence(scontent, 10)
            var shortTitle = shortSentence(stitle, 5)

            var headBackGround = /*this.state.selectedArticleId[value.id] ? "bg-danger" :*/ "bg-primary"

            return(
                <div className="col-md-2">
                    <Card >
                        {/* <Card.Img variant="top" src={"/img/sample-asset.png"} style={{padding:"2%"}}></Card.Img> */}
                        <Card.Header style={{color:"black"}} className={headBackGround} 
                            onClick={() => { }}>   
                           
                            <div className="form-check form-check-inline">
                               
                                <input onClick={(event) => { this.props.setSelectedGeneral("general",value, event.currentTarget.checked) }} 
                                        type="checkbox" 
                                        value="" 
                                        className="form-check-input" 
                                        id={"label"+value.id} 
                                        // selectedArticleId[value.id]
                                        // defaultChecked={this.state.selectedArticleId[value.id]}
                                    ></input>&nbsp;
                                <label 
                                    title={shortTitle} 
                                    className="form-check-label" 
                                    htmlFor={"label"+value.id}
                                ><b >{value.index}. <span>{ shortTitle }</span></b> 
                                </label>
                            </div>
                            {/* <div className="card-tools">
                                <Dropdown >
                                    <Dropdown.Toggle  id="dropdown-basic" as={"span"} >  
                                        <a href="#" style={{color:"black"}}><i className="fa fa-ellipsis-v"></i></a>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href={`/article/detail/${value.id}`}>Update</Dropdown.Item>
                                        
                                    </Dropdown.Menu>
                                </Dropdown>
                                
                            </div> */}
                        </Card.Header>
                        <a  href={`/article/detail/${value.id}/${this.props.widgetId}`}>
                            <Card.Body style={{color:"black", overflow:"hidden"}}>
                                <Card.Title>
                                
                                    <p> { shortTitle } ...</p>
                                    <hr></hr>
                                </Card.Title>
                                <Card.Text style={{overflow:"hidden", height:70}}>
                                    <span style={{fontSize:12}} dangerouslySetInnerHTML={{__html:shortContent}}></span>
                                
                                </Card.Text>
                                <Card.Text style={{fontSize:12}}>
                                        <b>{value.status}</b>
                                </Card.Text>
                            </Card.Body>
                        </a>
                        
                    </Card>
                </div>
            )
    }
}

export default ArticleElementItem;