import React, { Component } from 'react';
import { Tab, Col, Row, Collapse, Nav } from 'react-bootstrap';
import Card, { CardBody } from "../../components/Card";
import SectionFormHeader from "./SectionFormHeader"

import WidgetApi from "./../../api/WidgetApi"

import TextForm from "./TextForm"

class TextCard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            openCollapse: false,
            widget: {
                text_elements: []
            }
        }

        this.maxElements = {
            739: 8,
            2386: 11
        }

        this.setOpenCollapse = this.setOpenCollapse.bind(this)
        this.getWidgetDetail = this.getWidgetDetail.bind(this)
    }

    componentDidMount() {
        this.getWidgetDetail()
    }

    setOpenCollapse() {
        this.setState({
            openCollapse: !this.state.openCollapse
        })
    }

    getWidgetDetail() {
        const objWidget = new WidgetApi()

        const widgetId = this.props.data.widget
        const sectionWidgetId = this.props.data.section_widget_languages[0]?.section_widget

        const params = {
            section: sectionWidgetId
        }

        objWidget.getDetailWidget(widgetId, params)
            .then(res => {

                //console.log(' widgets TEXT  ==> ',res.data)
                this.setState({
                    widget: res.data
                }, () => {
                    //this.getGeneralData()
                })



            })
            .catch(err => {
                console.log(err.message)
            })
    }

    render() {
        let NavAdd = (
            <Nav.Item>
                <Nav.Link eventKey="add-text" style={{ borderRadius: 60 }}>
                    Add Text +
                </Nav.Link>
            </Nav.Item>
        )

        if (Object.keys(this.maxElements).includes(this.props.widget.toString()) && this.state.widget.text_elements.length >= this.maxElements[this.props.widget]) {
            NavAdd = ""
        }

        const TabContent = this.state.widget.text_elements.map((item, index) => {


            return (
                <Tab.Pane eventKey={index} >
                    <br></br>
                    <TextForm
                        widgetName={this.props.widgetName}
                        widgetElementType={this.props.widgetElementType}
                        widgetElementStatic={this.props.widgetElementStatic}
                        text_element={item}
                        widget={this.state.widget}
                        getWidgetDetail={this.getWidgetDetail}
                    ></TextForm>
                </Tab.Pane>
            )
        })

        const NavContent = this.state.widget.text_elements.map((item, index) => {

            return (
                <Nav.Item>
                    <Nav.Link eventKey={index} style={{ borderRadius: 60, backgroundColor: "" }}>
                        {item.index}. {item.name}
                    </Nav.Link>
                </Nav.Item>
            )
        })


        return (
            <Card>
                <SectionFormHeader
                    widget={this.state.widget}
                    section={this.props.section}

                    widgetName={this.props.widgetName}
                    widgetElementType={this.props.widgetElementType}
                    widgetElementStatic={this.props.widgetElementStatic}
                    setOpenCollapse={this.setOpenCollapse}
                    openCollapse={this.state.openCollapse}
                    icon={"fa-font"}

                ></SectionFormHeader>
                <Collapse in={this.state.openCollapse} style={{ padding: "1.25rem" }}>
                    <CardBody id={`text-form-${this.props.widgetName}`}>
                        <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
                            <Row>
                                <Col>
                                    <Nav variant="pills" className="scrollmenu" >

                                        {NavAdd}
                                        {NavContent}

                                    </Nav>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="add-text">
                                            <br></br>
                                            <TextForm
                                                widgetName={this.props.widgetName}
                                                widgetElementType={this.props.widgetElementType}
                                                widgetElementStatic={this.props.widgetElementStatic}
                                                widget={this.state.widget}
                                                getWidgetDetail={this.getWidgetDetail}
                                            ></TextForm>
                                        </Tab.Pane>
                                        {TabContent}



                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </CardBody>
                </Collapse>
            </Card>
        );
    }
}

export default TextCard;