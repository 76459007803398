import React, { Component } from "react";
import swal from "sweetalert";
import TitlePages from "./Elements/TitlePages";
import { Content, ContentHeader, ContentBody } from "./../components/Content";
import GA_Api from "../api/GANumberApi";
import { withTranslation } from 'react-i18next';
import UserContext from './Context';
import config from '../config/config'
import ReactGA from 'react-ga';

class GASettingPage extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);

    this.state = {
      isConnected: false,
      idTemplate: 0,
      ga_code: [],
      transAlert: "alert."
    };
    this.getGANumber = this.getGANumber.bind(this);
    this.updateGANumber = this.updateGANumber.bind(this);
  }

  componentDidMount() {
    ReactGA.initialize(config.GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    const contextProps = this.context
    this.setState({ idTemplate: contextProps.activeTemplate }, this.getGANumber)
  }

  componentDidUpdate() {
    const contextProps = this.context
    if (contextProps.activeTemplate !== this.state.idTemplate) {
      this.setState({ idTemplate: contextProps.activeTemplate }, this.getGANumber)
    }
  }

  getGANumber() {
    const GA = new GA_Api();

    GA.getGANumber(this.state.idTemplate)
      .then((res) => {
        const data = res.data;
        this.setState({
          isConnected: true,
          ga_code: data.ga_code,
        });
      })
      .catch((err) => {
        console.log("error => ", err.response);
      });
  }

  updateGANumber() {
    const { t } = this.props;
    const GA = new GA_Api();
    const data = {
      ga_code: document.getElementById("ga_codes").value,
    };
    GA.updateGANumber(this.state.idTemplate, data)
      .then((res) => {
        const data = res.data;
        if (data) {
          swal(
            "Success !",
            t(this.state.transAlert + "ga_numbers_update_success"),
            "success"
          );
        }
      })
      .catch((err) => {
        console.log("error => ", err.response);
      });
  }

  render() {
    const { t } = this.props;
    let ga_codes;
    if (this.state.isConnected) {
      ga_codes = this.state.ga_code;
    }

    return (
      <Content>
        <ContentHeader>
          <TitlePages title={t('pages.ga_number_settings.title')} />
        </ContentHeader>
        <ContentBody>
          <div className="pl-4 pr-4 pb-4">
            <div className="clearfix"></div>
            <div>
              <hr></hr>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      id="ga_codes"
                      type="text"
                      defaultValue={ga_codes}
                      className="form-control"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="float-right">
                <div className="btn btn-primary" onClick={this.updateGANumber}>
                  <i className="fa fa-edit">&nbsp;</i>
                  {t('update')}
                </div>
              </div>
            </div>
          </div>
        </ContentBody>
      </Content>
    );
  }
}

export default withTranslation()(GASettingPage);