import React, { Component } from "react";
import {
    GoogleMap,
    LoadScript,
    Autocomplete,
    Marker,
} from "@react-google-maps/api";
// import Geocode from "react-geocode";
import { Modal, Button, InputGroup, Tabs, Tab } from "react-bootstrap";
import swal from "sweetalert";
import AlertHelper from "../lib/AlertHelper";
import GeneralApi from "../api/GeneralApi";
import LocationApi from "../api/LocationApi";
import JobApi from "../api/JobApi";
import { withTranslation } from "react-i18next";

import Card, { CardBody, CardFooter } from "../components/Card";
import CompanyAddModal from "../pages/Job/CompanyAddModal";
import DeleteCompanyConfirm from "../pages/Job/DeleteCompanyConfirm";
import { Content, ContentHeader, ContentBody } from "./../components/Content"
import TitlePages from './Elements/TitlePages'
import Context from "./Context";

import "./LocationPage.css";

class LocationDetailPage extends Component {
    static contextType = Context
    constructor(props) {
        super(props);

        this.state = {
            // gMapApiKey: "AIzaSyA6aQOcCYM5yXimMPDdTCopJD9wT0324L8",
            gMapApiKey: "AIzaSyCigRL-AQS62AdkaUj42rp9OX_5Mbze8PM",
            // gMapApiKey: 'AIzaSyCo2mDUObZFhtBXJg_QTEGbcMAyvn4zKkY',
            queryLocation: "",
            mapsLib: ["drawing", "places"],
            currentLatLng: {
                lat: 0,
                lng: 0,
            },
            defaultCenter: {
                lat: -6.1741,
                lng: 106.8296,
            },
            bounds: null,
            isMarkerShown: false,
            languageList: [],
            locationLanguage: [],
            companies: [],
            selectedCompanies: [],
            names: [],
            nameNoLang: "",
            address: [],
            addressNoLang: "",
            website: [],
            websiteNoLang: "",

            hasLanguage: false,
            tabKey: 1,
            selectedLanguage: 1,

            // update Loc
            locationLoaded: false,

            transPage: "modals.location_add_new.",
            transAlert: "alert.",
        };
        this.myMap = React.createRef();

        this.autocomplete = null;

        this.onLoad = this.onLoad.bind(this);
        this.onPlaceChanged = this.onPlaceChanged.bind(this);

        this.renderTabLanguage = this.renderTabLanguage.bind(this);
        this.handleChangeAddress = this.handleChangeAddress.bind(this);
        this.handleChangeWebsite = this.handleChangeWebsite.bind(this);
        this.handleHasLanguage = this.handleHasLanguage.bind(this);
        // this.handleLocationChange = this.handleLocationChange.bind(this)
        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.handleChangeCompany = this.handleChangeCompany.bind(this);

        this.addNewLocation = this.addNewLocation.bind(this);
        this.getDetailLocation = this.getDetailLocation.bind(this);
        this.getCompanies = this.getCompanies.bind(this);
        this.setShowCompanyDelete = this.setShowCompanyDelete.bind(this);
        this.setShowCompany = this.setShowCompany.bind(this);
        this.deleteSelectedCompany = this.deleteSelectedCompany.bind(this);
    }

    componentDidMount() {
        const { languagesList } = this.context

        this.showCurrentLocation();
        if (this.props.match.params.locationId) {
            this.getDetailLocation(this.props.match.params.locationId);
            this.getCompanies();
            this.setState(
                {
                    locationId: this.props.match.params.locationId,
                    tabKey: 1,
                    selectedLanguage: 1,
                    languageList: languagesList,
                });
        } else {
            this.getCompanies();
            this.setState(
                {
                    id: this.props.positionId | 0,
                    tabKey: 1,
                    selectedLanguage: 1,
                    languageList: languagesList,
                },
                () => {
                    // this.setState({
                    //     address:""
                    // })
                    // if(this.props.locationId) {
                    //     this.getDetailLocation()
                    // } else {
                    //     this.renderTabLanguage()
                    // }
                }
            );
        }
    }

    showCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState((prevState) => ({
                    currentLatLng: {
                        ...prevState.currentLatLng,
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    },
                    isMarkerShown: true,
                }));
            });
        } else {
            console.log("error");
        }
    };

    onLoad(autocomplete) {
        // console.log("autocomplete: ", autocomplete);

        this.autocomplete = autocomplete;
    }

    showCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState((prevState) => ({
                    currentLatLng: {
                        ...prevState.currentLatLng,
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    },
                    isMarkerShown: true,
                }));
            });
        } else {
            console.log("error");
        }
    };

    onPlaceChanged() {
        if (this.autocomplete !== null) {
            let location = this.autocomplete.getPlace();
            this.setState((prevState) => ({
                currentLatLng: {
                    ...prevState.currentLatLng,
                    lat: location.geometry.location.lat(),
                    lng: location.geometry.location.lng(),
                },
                addressNoLang: location.formatted_address,
                isMarkerShown: true,
                address: [
                    { address: location.formatted_address },
                    { address: location.formatted_address },
                ],
            }));
            console.log(this.state.currentLatLng);
        } else {
            console.log("Autocomplete is not loaded yet!");
        }
    }

    addNewLocation() {
        const { t } = this.props;
        const assetObj = new LocationApi();

        const location_languages = this.state.languageList.map((item, index) => {
            return {
                language: item.id,
                location_type: this.state.names[index]
                    ? this.state.names[index].name
                    : "-",
                address: this.state.address[index]
                    ? this.state.address[index].address
                    : "",
                website: this.state.website[index]
                    ? this.state.website[index].website
                    : "",
            };
        });

        const newLocation = {
            has_languages: this.state.hasLanguage,
            company: this.state.selectedCompany,
            location_type: this.state.nameNoLang ? this.state.nameNoLang : "-", //location_type is NAME        
            latitude: this.state.currentLatLng?.lat,
            longitude: this.state.currentLatLng?.lng,
            description: document.getElementById("m-loc-description").value,
            email: document.getElementById("m-loc-email").value,
            phone: document.getElementById("m-loc-phone").value,
            address: this.state.addressNoLang,
            website: this.state.websiteNoLang,
            location_languages: location_languages,
        };

        assetObj
            .addLocation(newLocation)
            .then((res) => {
                if (res.data) {
                    swal(
                        "Success !",
                        t(this.state.transAlert + "newlocation_add_success"),
                        "success"
                    );
                    this.props.history.push("/location");
                }
            })
            .catch((err) => {
                console.log("err => ", err);
            });
    }

    updateLocation() {
        const { t } = this.props;
        const assetObj = new LocationApi();

        const location_languages = this.state.languageList.map((item, index) => {
            return {
                language: item.id,
                location_type: this.state.names[index]
                    ? this.state.names[index].name
                    : "-",
                address: this.state.address[index]
                    ? this.state.address[index].address
                    : "-",
                website: this.state.website[index]
                    ? this.state.website[index].website
                    : "-",
            };
        });

        const newLocation = {
            has_languages: this.state.hasLanguage,
            company: this.state.selectedCompany,
            location_type: this.state.nameNoLang ? this.state.nameNoLang : "-", //location_type is NAME
            latitude: this.state.currentLatLng?.lat,
            longitude: this.state.currentLatLng?.lng,
            description: document.getElementById("m-loc-description").value,
            email: document.getElementById("m-loc-email").value,
            phone: document.getElementById("m-loc-phone").value,
            address: this.state.addressNoLang ? this.state.addressNoLang : "-",
            website: this.state.websiteNoLang ? this.state.websiteNoLang : "-",
            location_languages: location_languages,
        };

        assetObj
            .updateLocation(newLocation, this.state.locationId)
            .then((res) => {
                if (res.data) {
                    swal(
                        "Success !",
                        t(this.state.transAlert + "newlocation_update_success"),
                        "success"
                    );
                    this.props.history.push("/location");
                }
            })
            .catch((err) => {
                console.log("err => ", err);
            });
    }

    getDetailLocation(locationId) {
        const obj = new LocationApi();

        obj
            .getDetailLocation(locationId)
            .then((res) => {
                console.log(" getDetailLocation ===> ", res.data);
                let names = []
                let address = [];
                let website = [];
                res.data.location_languages.forEach((loc, i) => {
                    names.push({
                        name: loc.location_type,
                    });
                    address.push({
                        address: loc.address,
                    });
                    website.push({
                        website: loc.website,
                    });
                });

                this.setState(
                    {
                        currentLatLng: {
                            lat: parseFloat(res.data.latitude),
                            lng: parseFloat(res.data.longitude),
                        },
                        selectedCompany: res.data.company,
                        names: names,
                        nameNoLang: res.data.location_type,
                        description: res.data.description,
                        email: res.data.email,
                        phone: res.data.phone,
                        hasLanguage: res.data.has_languages,
                        address: address,
                        addressNoLang: res.data.address,
                        website: website,
                        websiteNoLang: res.data.website,
                        locationLoaded: true,
                    },
                    () => {
                        this.renderTabLanguage();
                    }
                );
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    getCompanies() {
        const obJob = new JobApi();

        obJob
            .getCompanies({
                max_size: true,
            })
            .then((res) => {
                this.setState({
                    companies: res.data.results,
                });
            })
            .catch((err) => { });
    }

    handleChangeNames(index, value) {
        let names = [...this.state.names];
        let new_names = { ...names[index] };
        new_names.name = value;
        names[index] = new_names;

        this.setState(
            {
                names: names,
            },
            () => { }
        );
    }

    handleChangeNameNoLang(value) {
        this.setState({
            nameNoLang: value,
        });
    }

    handleChangeAddress(index, value) {
        let location_address = [...this.state.address];
        let newlocation_address = { ...location_address[index] };
        newlocation_address.address = value;
        location_address[index] = newlocation_address;

        this.setState(
            {
                // oldaddress: newlocation_address[0].address,
                address: location_address,
            },
            () => { }
        );
    }

    handleChangeAddressNoLang(value) {
        this.setState({
            addressNoLang: value,
        });
    }

    handleChangeWebsite(index, value) {
        let location_website = [...this.state.website];
        let newlocation_website = { ...location_website[index] };
        newlocation_website.website = value;
        location_website[index] = newlocation_website;

        this.setState(
            {
                website: location_website,
            },
            () => { }
        );
    }

    handleChangeWebsiteNoLang(value) {
        this.setState({
            websiteNoLang: value,
        });
    }

    handleChangeTab(value) {
        this.setState(
            {
                selectedLanguage: value,
                tabKey: value,
            },
            () => {
                this.renderTabLanguage();
            }
        );
    }

    handleChangeCompany(value) {
        this.setState({
            selectedCompany: value,
        });
    }

    handleHasLanguage() {
        this.setState({
            hasLanguage: !this.state.hasLanguage,
        });
    }

    renderTabLanguage() {
        if (this.state.languageList) {
            const renderTabLanguage = this.state.languageList.map((item) => {
                return {
                    language: item.id,
                    languageName: item.name,
                    description: "",
                };
            });

            this.setState({
                locationLanguage: renderTabLanguage,
            });
        }
    }

    setShowCompanyDelete(companyId) {
        if (companyId) {
            const obj = new JobApi();

            obj
                .getDetailCompany(companyId)
                .then((res) => {
                    this.setState({
                        selectedCompanies: [res.data],
                        showCompanyDelete: !this.state.showCompanyDelete,
                    });
                })
                .catch((err) => {
                    alert(err.message);

                    console.log(err.messasge);
                });
        } else {
            this.setState({
                showCompanyDelete: !this.state.showCompanyDelete,
            });
        }
    }

    setShowCompany(companyId) {
        if (companyId) {
            this.setState({
                companyId: companyId,
                showCompany: !this.state.showCompany,
            });
        } else {
            this.setState({
                companyId: 0,
                showCompany: !this.state.showCompany,
            });
        }
    }

    deleteSelectedCompany() {
        const { t } = this.props;
        const obj = new JobApi();

        const [{ id }] = this.state.selectedCompanies;

        obj
            .deleteCompany(id)
            .then((res) => {
                swal(
                    "Success!",
                    t(this.state.transAlert + "company_delete_success"),
                    "success"
                );
                this.getCompanies();
                this.setShowCompanyDelete();
            })
            .catch((err) => {
                swal("Error!", err.message, "error");
            });
    }

    render() {
        const { t } = this.props;
        const containerStyle = {
            width: "100%",
            height: "30vh",
        };

        let renderTabLanguage;
        if (this.state.hasLanguage) {
            if (this.state.languageList) {
                let tabLanguage;
                tabLanguage = this.state.languageList.map((item, index) => {
                    return (
                        <Tab key={index} eventKey={index + 1} title={item.name}>
                            <br></br>

                            <div className="form-group">
                                <label>
                                    {t(this.state.transPage + "name")}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={this.state.names[index]?.name}
                                    onChange={(event) =>
                                        this.handleChangeNames(index, event.target.value)
                                    }
                                ></input>
                            </div>
                            <div className="form-group">
                                <label> {t("address")} </label>
                                {/* <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.address[index]?.address}
                                    onChange={(event) =>
                                        this.handleChangeAddress(index, event.target.value)
                                    }
                                ></input> */}
                                <textarea
                                    className="form-control"
                                    value={this.state.address[index]?.address}
                                    onChange={(event) =>
                                        this.handleChangeAddress(index, event.target.value)
                                    }
                                    rows='3'
                                >
                                </textarea>
                            </div>
                            <div className="form-group mt-2">
                                <label>{t(this.state.transPage + "website")}</label>
                                <input
                                    type="text"
                                    name="asset_name"
                                    className="form-control"
                                    value={this.state.website[index]?.website}
                                    onChange={(event) =>
                                        this.handleChangeWebsite(index, event.target.value)
                                    }
                                ></input>
                            </div>
                        </Tab>
                    );
                });

                renderTabLanguage = (
                    <div className="form-group d-block">
                        <Tabs
                            id="controlled-tab-example-3"
                            activeKey={this.state.tabKey}
                            onSelect={(k) => this.handleChangeTab(k)}
                        >
                            {tabLanguage}
                        </Tabs>
                    </div>
                );
            }
        } else {
            renderTabLanguage = <>
                <div className="form-group">
                    <label> {t(this.state.transPage + "name")} </label>
                    <input
                        type="text"
                        className="form-control"
                        value={this.state.nameNoLang}
                        onChange={(event) => this.handleChangeNameNoLang(event.target.value)}
                    ></input>
                </div>
                <div className="form-group">
                    <label> {t('address')} </label>
                    {/* <input
                        type="text"
                        className="form-control"
                        value={this.state.addressNoLang}
                        onChange={(event) => this.handleChangeAddressNoLang(event.target.value)}
                    ></input> */}
                    <textarea
                        className="form-control"
                        value={this.state.addressNoLang}
                        onChange={(event) =>
                            this.handleChangeAddressNoLang(event.target.value)
                        }
                        rows='3'
                    >
                    </textarea>
                </div>
                <div className="form-group mt-2">
                    <label>{t(this.state.transPage + "website")}</label>
                    <input type="text" name="asset_name" className="form-control"
                        value={this.state.websiteNoLang}
                        onChange={(event) => this.handleChangeWebsiteNoLang(event.target.value)}
                    ></input>
                </div>
            </>
        }

        return (
            <Content>
                <ContentHeader>
                    <TitlePages title="Add Location" />
                </ContentHeader>
                <ContentBody>
                    <Card>
                        <div className="loc-header">
                            <div className="row">
                                <label>Location Detail</label>
                                <div className="d-flex flex-row btn-loc-header ml-auto">
                                    <div onClick={() => this.props.history.push("/location")}>
                                        {t(this.state.transPage + "cancel")}
                                    </div>
                                    {this.state.locationLoaded ? (
                                        <div onClick={() => this.updateLocation()}>
                                            {t(this.state.transPage + "update_location")}
                                        </div>
                                    ) : (
                                        <div onClick={() => this.addNewLocation()}>
                                            {t(this.state.transPage + "add_new_location")}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="loc-body">
                            <div>
                                <div className="form-group position-relative">
                                    <label for="m-loc-company">
                                        {t(this.state.transPage + "company_name")}
                                    </label>
                                    <button
                                        className="btn btn-info btn-xs float-right btn-loc-add-company"
                                        onClick={() => this.setShowCompany()}
                                    >
                                        + {t("add_company")}{" "}
                                    </button>
                                    <div className="clearfix"></div>
                                    <InputGroup>
                                        <select
                                            id="m-loc-company"
                                            value={this.state.selectedCompany}
                                            className="form-control"
                                            onChange={(event) =>
                                                this.handleChangeCompany(event.target.value)
                                            }
                                        >
                                            <option> -- Please Select Company -- </option>
                                            {this.state.companies.map((item) => {
                                                return (
                                                    <option value={item.id} key={item.id}>
                                                        {" "}
                                                        {item.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <InputGroup.Prepend
                                            onClick={() => {
                                                this.setShowCompany(this.state.selectedCompany);
                                            }}
                                        >
                                            <InputGroup.Text>
                                                {" "}
                                                <i className="fa fa-edit"></i>
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <InputGroup.Prepend
                                            onClick={() => {
                                                this.setShowCompanyDelete(this.state.selectedCompany);
                                            }}
                                        >
                                            <InputGroup.Text>
                                                {" "}
                                                <i className="fa fa-trash"></i>
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                    </InputGroup>
                                </div>

                                <div className="form-group ">
                                    <label for="m-loc-description">
                                        {t(this.state.transPage + "description")}
                                    </label>
                                    <input
                                        id="m-loc-description"
                                        type="text"
                                        name="asset_name"
                                        className="form-control"
                                        defaultValue={this.state.description}
                                    ></input>
                                </div>
                                <div className="form-group ">
                                    <label for="m-loc-email">
                                        {t(this.state.transPage + "email")}
                                    </label>
                                    <input
                                        id="m-loc-email"
                                        type="email"
                                        name="asset_name"
                                        className="form-control"
                                        defaultValue={this.state.email}
                                    ></input>
                                </div>
                                <div className="form-group ">
                                    <label for="m-loc-phone">
                                        {t(this.state.transPage + "phone")}
                                    </label>
                                    <input
                                        id="m-loc-phone"
                                        type="text"
                                        name="asset_name"
                                        className="form-control"
                                        defaultValue={this.state.phone}
                                    ></input>
                                </div>
                                <div className="form-group mt-3">
                                    <label for="check-has-language">
                                        {" "}
                                        {t(this.state.transPage + "has_language")}{" "}
                                    </label>
                                    <div className="w-100">
                                        <input
                                            type="checkbox"
                                            id="check-has-language"
                                            checked={this.state.hasLanguage}
                                            onClick={() => this.handleHasLanguage()}
                                        ></input>{" "}
                    &nbsp;
                  </div>
                                </div>

                                {renderTabLanguage}
                            </div>
                            <div>
                                <LoadScript
                                    googleMapsApiKey={this.state.gMapApiKey}
                                    libraries={this.state.mapsLib}
                                >
                                    <GoogleMap
                                        ref={this.myMap}
                                        id="g-maps"
                                        mapContainerStyle={containerStyle}
                                        center={
                                            this.state.locationLoaded
                                                ? this.state.currentLatLng
                                                : this.state.isMarkerShown
                                                    ? this.state.currentLatLng
                                                    : this.state.defaultCenter
                                        }
                                        zoom={17}
                                        clickableIcons={true}
                                        onDblClick={this.onDblClick}
                                        mapTypeId="roadmap"
                                    >
                                        <Autocomplete
                                            onLoad={this.onLoad}
                                            onPlaceChanged={this.onPlaceChanged}
                                        // types={['(regions)']} only region showing
                                        >
                                            {/* <input type="text" id="m-tx-autocomplete" defaultValue={''} className="form-control" placeholder="Location" onChange={(event) => this.handleLocationChange(event.target.value)}></input> */}
                                            <input
                                                id="loc-search-place"
                                                type="text"
                                                placeholder="search here"
                                            />
                                        </Autocomplete>
                                        <Marker
                                            // onLoad={onLoad}
                                            position={
                                                this.state.isMarkerShown
                                                    ? this.state.currentLatLng
                                                    : this.state.defaultCenter
                                            }
                                        />
                                    </GoogleMap>
                                </LoadScript>
                            </div>
                        </div>
                    </Card>
                </ContentBody>
                <CompanyAddModal
                    companyId={this.state.companyId}
                    show={this.state.showCompany}
                    onHide={this.setShowCompany}
                    callback={this.getCompanies}
                ></CompanyAddModal>
                <DeleteCompanyConfirm
                    companyId={this.state.companyId}
                    selectedCompanies={this.state.selectedCompanies}
                    onHide={this.setShowCompanyDelete}
                    show={this.state.showCompanyDelete}
                    deleteSelectedCompany={this.deleteSelectedCompany}
                ></DeleteCompanyConfirm>
            </Content>
        );
    }
}

export default withTranslation()(LocationDetailPage);
