import axios from "axios";
import config from "./../config/config";

export default class SiteApi {
    // base_api = "http://192.168.13.177:7000/"
    base_api = config.base_api;
    token = config.token;

    getAllSites() {
        return axios.get(this.base_api + "sites/", {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getSiteDetail(siteId) {
        return axios.get(this.base_api + `sites/${siteId}/`, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    addSite(site) {
        return axios.post(this.base_api + "sites/", site, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    updateSite(siteID, site) {
        return axios.patch(this.base_api + `sites/${siteID}/`, site, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    getSiteStructure(templateId, params) {
        return axios.get(this.base_api + `site_structure/${templateId}/`, {
            params: params,
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "application/json",
            },
        });
    }

    updateSiteStructure(siteId, data) {
        return axios.patch(
            this.base_api + `site_structure/${siteId}/`,
            {
                page_update: data.page_update,
            },
            {
                headers: {
                    Authorization: `Token ${this.token}`,
                    "Content-Type": "application/json",
                },
            }
        );
    }

    updateSiteStructureNew(templateId, data) {
        return axios.patch(
            this.base_api + `site_structure/${templateId}/`,
            {
                page_update: data.page_update,
            },
            {
                headers: {
                    Authorization: `Token ${this.token}`,
                    "Content-Type": "application/json",
                },
            }
        );
    }
}
