import React, { Component } from 'react';
import Select from "react-dropdown-select"

import WidgetApi from "./../../api/WidgetApi"

class ArticleFormCollection extends Component {

    constructor(props) {
        super(props)

        this.state = {
            widgetDisable:false,
            widgetList:[],
            selectedWidget:[],

        }
    }

    getAllWidget(){
        //console.log("Get All Widgets");
        const objWidget = new WidgetApi()
        

        const params = {
            template:Number(localStorage.getItem("templateId")),
            max_size:true,
            category:3,
            internal:this.state.is_internal
        }

       // console.log("params ==> ",params)

        objWidget.getAllWidgets(params)
        .then(res => {
        
          
            // namePage setting 
          const widgetList = res.data.results.map(item => {
              
              var page = ""
              if(item.page) {
                 page = " - "+item.page
              }

              return {
                  ...item,
                  namePage:item.name+page
              }
          })

            this.setState({
                widgetList:widgetList
            })
        })
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Collection</label>
                        {/* { JSON.stringify(this.state.selectedWidget)} */}
                        <Select
                            multi
                            disabled={this.state.widgetDisable}
                            searchable={true}
                            searchBy="namePage"
                            options={this.state.widgetList}
                            values={[...this.state.selectedWidget]}
                        
                            valueField="id"
                            labelField="namePage"
                            onDropdownClose={(value) => {
                                // console.log("dropdownClose" , value)   
                            }}
                            onChange={(selectedWidget) => {
                                this.setState({
                                    selectedWidget
                                });
                                // const newValue = this.state.selectedWidget.map((val) => ({ value: val.id, label: val.name }));

                                // return this.setState({
                                //   selectedWidget: [...this.state.selectedWidget, ...newValue]
                                // });
                            }}
                        
                        />
                        {/* { JSON.stringify(this.state.widgetList)} */}
                    </div>
                </div>
            </div>
        );
    }
}

export default ArticleFormCollection;