import React, { Component } from "react";
import { Button, Tabs, Tab } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
// import ClassicEditor from './../plugins/ckeditor-build-complete-font/build/ckeditor';
// import ClassicEditor from './ckeditor';

// import DatePicker from "react-bootstrap-date-picker"
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import Select from "react-dropdown-select";
// import Dashboard from "./../components/section/Dashboard"

import Card, { CardHeader, CardBody, CardFooter } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content";

import ArticleAssetListUpdate from "./../components/widgets/AssetList/ArticleAssetListUpdate";
import ArticleAssetListAdd from "./../components/widgets/AssetList/ArticleAssetListAdd";
import TitlePages from "./Elements/TitlePages";
import ModalLocation from "../components/Modal/LocationModal";
import WidgetArticleAddLocationModal from "../components/Modal/WidgetArticleAddLocationModal";
import AdvancedArticle from "../components/AdvancedArticle/AdvancedArticle";

import ArticleApi from "./../api/ArticleApi";
import WidgetApi from "./../api/WidgetApi";
import PageApi from "./../api/PageApi";
import SectionApi from "./../api/SectionApi";
import UserApi from "../api/UserApi";
import LocationApi from "../api/LocationApi";

import { myGetYear, getMonth, myRange } from "./../lib/DateHelper";

import "react-datepicker/dist/react-datepicker.css";
import AlertHelper from "./../lib/AlertHelper";

import "./ArticleDetailPage.css";

import { withTranslation } from "react-i18next";
import UserContext from "./Context";
import config from "../config/config";
import ReactGA from "react-ga";

class ArticleDetailPage extends Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			// gMapApiKey: "AIzaSyA6aQOcCYM5yXimMPDdTCopJD9wT0324L8",
            gMapApiKey: "AIzaSyCigRL-AQS62AdkaUj42rp9OX_5Mbze8PM",
			mapsLib: ["drawing", "places"],

			articleId: this.props.match.params.articleId,

			name: "",
			author: [],
			widget: {},
			// category:"", // tadinya ini, untuk jaga2
			category: [],
			date: "",
			dateToApi: "",
			status: "Drafted",
			is_internal: false,
			is_nofollow: false,

			selectedLanguage: 1,
			widgetDisable: false,

			article_assets: [],
			widgetList: [],
			categoryList: [],
			languageList: [],
			employeeList: [],

			selectedAssetAdd: [],
			selectedAsset: [],
			selectedWidget: [],

			AssetSection: "",
			HeaderTextPage: "",
			ButtonForm: "",

			tabKey: 0,
			articleLanguages: [],
			articleKeywords: [],
			loadedKeywords: [],
			activeKeyword: "",

			pages: [],
			sections: [],
			selectedPageId: null,
			selectedSectionId: null,

			urlType: "internal",
			deleteDisable: true,

			selectedArticleType: 1,
			content_assets_file: [],
			loaded_content_assets_file: false,
			update_assets_file: [false, false],

			transPage: "pages.article_detail.",
			transAlert: "alert.",

			activeTemplate: 0,

			location: false,
			setShowLocationModal: false,
			setShowArticleAddLocationModal: false,
			pairLocation: false,

			overlay: "",
			isLoading: false,
			pageActive: 'add',
		};

		this.getAllEmployees = this.getAllEmployees.bind(this);
		this.getDetailArticle = this.getDetailArticle.bind(this);
		this.getDetailWidget = this.getDetailWidget.bind(this);
		this.getAllWidget = this.getAllWidget.bind(this);
		this.getArticleCategories = this.getArticleCategories.bind(this);
		this.getLanguages = this.getLanguages.bind(this);
		this.getPages = this.getPages.bind(this);
		this.getSectionByPage = this.getSectionByPage.bind(this);
		this.getCurrentUser = this.getCurrentUser.bind(this);

		this.handleChangeName = this.handleChangeName.bind(this);
		this.handleChangeStatus = this.handleChangeStatus.bind(this);
		this.handleChangeWidget = this.handleChangeWidget.bind(this);
		this.handleChangeCategory = this.handleChangeCategory.bind(this);
		this.handleChangeStatus = this.handleChangeStatus.bind(this);
		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.handleChangeTab = this.handleChangeTab.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleSectionChange = this.handleSectionChange.bind(this);
		this.handleUrlTypeChange = this.handleUrlTypeChange.bind(this);
		this.handleUrlExternalChange = this.handleUrlExternalChange.bind(this);
		this.handleAuthorChange = this.handleAuthorChange.bind(this);
		this.handleChangeNoFollow = this.handleChangeNoFollow.bind(this);

		this.handleChangeTitle = this.handleChangeTitle.bind(this);
		this.handleChangeSummary = this.handleChangeSummary.bind(this);
		this.handleChangeContent = this.handleChangeContent.bind(this);
		this.handleChangeContentSection = this.handleChangeContentSection.bind(this);
		this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
		this.handleChangeDescription = this.handleChangeDescription.bind(this);

		this.setSelectedAsset = this.setSelectedAsset.bind(this);
		this.setSelectedAssetAdd = this.setSelectedAssetAdd.bind(this);

		this.deleteSelectedAssetAdd = this.deleteSelectedAssetAdd.bind(this);

		this.addArticle = this.addArticle.bind(this);
		this.updateArticle = this.updateArticle.bind(this);
		this.addKeyword = this.addKeyword.bind(this);
		this.updateKeyword = this.updateKeyword.bind(this);
		this.handleKeyword = this.handleKeyword.bind(this);
		this.updateLoadedKeyword = this.updateLoadedKeyword.bind(this);
		this.deleteLoadedKeyword = this.deleteLoadedKeyword.bind(this);
		this.deleteAddedKeyword = this.deleteAddedKeyword.bind(this);

		this.clearInternal = this.clearInternal.bind(this);
		this.clearExternal = this.clearExternal.bind(this);

		this.setShowLocationModal = this.setShowLocationModal.bind(this);
		this.setShowArticleAddLocationModal = this.setShowArticleAddLocationModal.bind(this);
		this.addNewLocation = this.addNewLocation.bind(this);
		this.pairLocationWithArticle = this.pairLocationWithArticle.bind(this);
	}

	handleChangeTitle(id, value) {
		let articleLanguages = [...this.state.articleLanguages];
		let articleLanguage = { ...articleLanguages[id] };

		articleLanguage.title = value;
		articleLanguages[id] = articleLanguage;

		this.setState({
			articleLanguages: articleLanguages,
		});
	}

	handleChangeKeyword(id, value) {
		let articleLanguages = [...this.state.articleLanguages];
		let articleLanguage = { ...articleLanguages[id] };

		articleLanguage.keyword = value;
		articleLanguages[id] = articleLanguage;

		this.setState({
			articleLanguages: articleLanguages,
		});
	}

	handleChangeDescription(id, value) {
		let articleLanguages = [...this.state.articleLanguages];
		let articleLanguage = { ...articleLanguages[id] };

		articleLanguage.description = value;
		articleLanguages[id] = articleLanguage;

		this.setState({
			articleLanguages: articleLanguages,
		});
	}

	handleChangeName(value) {
		this.setState({
			name: value,
		});
	}

	handleAuthorChange(value) {
		this.setState({
			author: value,
		});
	}

	handlePageChange(value) {
		if (!value) {
			this.setState(
				{
					selectedPageId: null,
					selectedSectionId: null,
					sections: [],
				},
				() => {
					//this.getSectionByPage()
				}
			);
		} else {
			this.setState(
				{
					selectedPageId: value,
				},
				() => {
					this.getSectionByPage();
				}
			);
		}
	}

	handleSectionChange(value) {
		this.setState({
			selectedSectionId: value,
		});
	}

	handleChangeSummary(id, value) {
		let articleLanguages = [...this.state.articleLanguages];
		let articleLanguage = { ...articleLanguages[id] };

		articleLanguage.summary = value;
		articleLanguages[id] = articleLanguage;

		this.setState({
			articleLanguages: articleLanguages,
		});
	}

	handleChangeContent(id, value, callback) {
		let articleLanguages = [...this.state.articleLanguages];
		let articleLanguage = { ...articleLanguages[id] };

		articleLanguage.content = value;
		articleLanguages[id] = articleLanguage;

		this.setState(
			{
				articleLanguages: articleLanguages,
			},
			callback
		);
	}

	handleChangeContentSection(id, value) {
		let articleLanguages = [...this.state.articleLanguages];
		let articleLanguage = { ...articleLanguages[id] };

		articleLanguage.content_section = value;
		articleLanguages[id] = articleLanguage;

		this.setState({
			articleLanguages: articleLanguages,
		});
	}

	handleChangeWidget(value) {
		this.setState({
			widget: value,
		});
	}

	handleChangeCategory(value) {
		this.setState({
			category: value,
		});
	}

	handleChangeStatus(value) {
		this.setState({
			status: value,
		});
	}

	handleUrlExternalChange(id, value) {
		let urlLanguages = [...this.state.articleLanguages];
		let urlLanguage = { ...urlLanguages[id] };
		urlLanguage.path = value;
		urlLanguages[id] = urlLanguage;

		this.setState({
			articleLanguages: urlLanguages,
		});
	}

	handleChangeTab(value) {
		this.setState({
			tabKey: value,
			selectedLanguage: value,
			// title:findArticleLanguage?.title,
			// content:findArticleLanguage?.content
		});
	}

	handleChangeDate(value) {
		var newDate = value ? new Date(value) : new Date();
		newDate.setDate(newDate.getDate() + 1);
		const result = JSON.stringify(newDate).slice(1, -1).split("T");

		this.setState({
			date: value,
			dateToApi: result[0],
		});
	}

	handleUrlTypeChange(value) {
		this.setState(
			{
				urlType: value,
			},
			() => {
				if (this.state.urlType === "internal") {
					this.clearExternal();
				} else {
					this.clearInternal();
				}
			}
		);
	}

	handleArticleTypeChange = (value) => {
		this.setState({
			selectedArticleType: value,
		}, () => {
			let articleLanguages = [...this.state.articleLanguages];
			let articleLanguage = { ...articleLanguages[0] };
			let articleLanguage1 = { ...articleLanguages[1] };

			articleLanguage.content = "";
			articleLanguage1.content = "";

			const data = [articleLanguage, articleLanguage1]

			this.setState({
				articleLanguages: data
			})
		});
	};

	handleChangeNoFollow(value) {
		this.setState({
			is_no_follow: value,
		});
	}

	setSelectedAssetAdd(asset) {
		var lastSelected = [];
		lastSelected = [...this.state.selectedAssetAdd];

		lastSelected = lastSelected.concat(asset);

		this.setState(
			{
				selectedAssetAdd: lastSelected,
			},
			() => {
				var deleteDisable = true;
				if (this.state.selectedAssetAdd.length > 0) {
					deleteDisable = false;
				}

				this.setState({
					deleteDisable: false,
				});
			}
		);
	}

	deleteSelectedAssetAdd(assets) {
		const { t } = this.props;

		var result = [];
		var lastSelected = [];
		lastSelected = [...this.state.selectedAssetAdd];

		lastSelected.forEach((ite) => {
			var exist = false;

			assets.forEach((item) => {
				if (ite.id === item.id) {
					exist = true;
				}
			});

			if (exist === false) {
				result.push(ite);
			}
		});

		this.setState(
			{
				selectedAssetAdd: result,
			},
			() => {
				swal("Success!", t(this.state.transAlert + "delete_selected_asset_in_article_success"), "success");
			}
		);
	}

	setSelectedAsset(asset, value) {
		var lastSelected = [];
		lastSelected = [...this.state.selectedAsset];
		var indexDelete = -1;
		var stateSelected = "selectedAsset";

		if (value === true) {
			lastSelected.push(asset);
		} else {
			this.state[stateSelected].forEach((item, index) => {
				if (item.id === asset.id) {
					indexDelete = index;
				}
			});

			lastSelected.splice(indexDelete, 1);
		}

		this.setState(
			{
				[stateSelected]: lastSelected,
			},
			() => {
				if (this.state[stateSelected].length > 0) {
					this.setState({
						deleteDisable: false,
					});
				} else {
					this.setState({
						deleteDisable: true,
					});
				}
			}
		);
	}

	componentDidMount() {
		ReactGA.initialize(config.GA_ID);
		ReactGA.pageview(window.location.pathname + window.location.search);

		const { t } = this.props;
		const { activeTemplate } = this.context;

		this.setState({ activeTemplate: activeTemplate });

		if (this.props.match.path === "/article/add") {
			this.setState(
				{
					pageActive: 'add',
					HeaderTextPage: "Article Add",
					ButtonForm: (
						<Button className="float-right" onClick={this.addArticle}>
							{" "}
							<i className="fa fa-plus">&nbsp;</i> {t(this.state.transPage + "add_article")}
						</Button>
					),
				},
				() => {
					this.getLanguages();
					this.getAllWidget();
				}
			);
		} else if (this.props.match.path === "/article/widgets/detail/:widgetId") {
			const widgetId = this.props.match.params.widgetId;
			this.getDetailWidget().then((res) => {
				this.setState(
					{
						pageActive: 'update',
						widget: {
							id: res.data.id,
							name: res.data.name,
							is_internal: res.data.is_internal,
						},
					},
					() => {
						var widgetDisable = false; // disabled

						if (widgetId) {
							widgetDisable = true;
						}

						this.setState(
							{
								is_internal: true,
								selectedWidget: [this.state.widget],
								widgetDisable: widgetDisable,
								HeaderTextPage: "Article Add",
								ButtonForm: (
									<Button className="float-right" onClick={this.addArticle}>
										{" "}
										<i className="fa fa-plus">&nbsp;</i> {t(this.state.transPage + "add_article")}
									</Button>
								),
							},
							() => {
								this.getLanguages();
								this.getAllWidget();
							}
						);
					}
				);
			});
		} else {
			this.getDetailArticle();

			this.setState(
				{
					pageActive: 'update',
					HeaderTextPage: "Article Detail Page",
					ButtonForm: (
						<Button className="float-right" onClick={this.updateArticle}>
							{" "}
							<i className="fa fa-edit">&nbsp;</i> {t("update")}{" "}
						</Button>
					),
				},
				() => {
					this.getAllWidget();
				}
			);
		}

		this.getAllEmployees();
		this.getArticleCategories();
		this.getPages();
	}

	componentDidUpdate() {
		const { activeTemplate } = this.context;

		if (this.props.match.path === "/article/add") {
			if (activeTemplate !== this.state.activeTemplate) {
				this.setState(
					{
						selectedWidget: [],
						activeTemplate: activeTemplate,
					},
					this.getAllWidget
				);
			}
		}
	}

	getAllEmployees() {
		const objUser = new UserApi();

		objUser
			.getAllAuthor({
				max_size: true,
			})
			.then((res) => {
				const resEmployee = res.data.results.map((item) => {
					// var name = item.user.first_name+" "+item.user.last_name
					// if(!item.user.first_name) {
					//     name = item.user.username
					// }

					var image = "";
					if (item.avatar) {
						image = (
							<div>
								<img width="30" height="30" src={item.avatar} alt=""></img>
								{" " + item.name}
							</div>
						);
					} else {
						image = item.name;
					}

					return {
						val: item.id,
						label: image,
					};
				});

				this.setState({
					employeeList: resEmployee,
				});
			});
	}

	getDetailArticle() {
		const objArticle = new ArticleApi();

		const articleId = this.props.match.params.articleId;
		// const widgetId = this.props.match.params.widgetId

		return objArticle
			.getDetailArticles(articleId)
			.then((res) => {
				var widgetDisable = false; // able
				var urlType = "";

				if (res.data.widgets[0]?.is_internal === true) {
					widgetDisable = true;
				}

				if (res.data.article_languages[0].page) {
					urlType = "internal";
				} else {
					urlType = "external";
				}

				const selectedWidget = res.data.widgets.map((item) => {
					var page = "";
					if (page) {
						page = " - " + item.page;
					}

					return {
						...item,
						namePage: item.name + page,
					};
				});

				var image = "";
				if (res.data.author?.avatar) {
					image = (
						<div>
							<img width="30" height="30" src={res.data.author.avatar} alt=""></img>
							{" " + res.data.author_name}
						</div>
					);
				} else {
					image = res.data.author_name;
				}

				const author = [
					{
						val: res.data.author,
						label: image,
					},
				];

				let loaded_content_assets_file = [];
				if (res.data.article_type.id === 2) {
					res.data.article_languages.forEach((d) => {
						loaded_content_assets_file.push({
							article: d.article,
							id: d.id,
							content_file: d.content_file,
						});
					});
				}
				
				this.setState(
					{
						articleId: res.data.id,

						category: res.data?.categories ? res.data?.categories : [],
						name: res.data.name,

						selectedWidget: selectedWidget,
						author: author,
						status: res.data.status,
						date: res.data.date ? new Date(res.data.date) : new Date(),
						dateToApi: res.data.date,
						article_assets: res.data.article_assets,
						widgetDisable: widgetDisable,
						is_internal: res.data.widgets[0]?.is_internal,
						urlType: urlType,
						selectedLanguage: 1,
						selectedArticleType: res.data.article_type ? res.data.article_type.id : 1,
						loaded_content_assets_file: loaded_content_assets_file,
						// title:res.data.title,
						// content:res.data.content,
						articleLanguages: res.data.article_languages,
						loadedKeywords: res.data.article_keywords,
						selectedPageId: res.data.article_languages[0]?.page,
						selectedSectionId: res.data.article_languages[0]?.section,
						is_nofollow: res.data.article_languages[0]?.is_nofollow,

						pairLocation: res.data.location ? res.data.location.id : false,
						location: res.data.location,
					},
					() => {
						this.getLanguages();
						// this.handleAuthorChange()
						if (this.state.author.length === 0) {
							this.getCurrentUser();
						}
					}
				);
			})
			.catch((err) => {
				const resErr = err?.response?.data ? err.response.data : err.message;

				const objAlert = new AlertHelper();
				objAlert.errorHandlerAlert(resErr);
			});
	}

	clearInternal() {
		this.setState({
			selectedPageId: null,
			selectedSectionId: null,
		});
	}

	clearExternal() {
		let urlLanguages = [...this.state.articleLanguages];
		urlLanguages.map((item, id) => {
			// let urlLanguage = { ...urlLanguages[ id ]}
			item.path = null;
			urlLanguages[id] = item;

			return item;
		});

		this.setState({
			articleLanguages: urlLanguages,
		});
	}

	setLoading() {
		this.setState(
			{
				isLoading: !this.state.isLoading,
			},
			() => {
				this.setOverlay();
			}
		);
	}

	setOverlay() {
		var overlay = "";
		if (this.state.isLoading) {
			overlay = (
				<div id="loader-article">
					<div className="overlay d-flex justify-content-center align-items-center">
						<i className="fa fa-2x fa-sync fa-spin"></i>
					</div>
				</div>
			);
		}

		this.setState({
			overlay: overlay,
		});
	}

	addArticle() {
		this.setLoading();
		const { t } = this.props;
		const objArticle = new ArticleApi();

		let article_languages, dt;
		let articleType = parseInt(this.state.selectedArticleType);

		const author = this.state.author.length > 0 ? this.state.author[0].val : null;

		const categories = this.state.category.map((item) => {
			return parseInt(item.id);
		});

		if (articleType === 1) {
			article_languages = this.state.articleLanguages.map((item, idx) => {
				return {
					language: item.language,
					name: item.title,
					title: item.title,
					summary: item.summary,
					content: item.content,
					description: item.description,
					page: this.state.selectedPageId ? this.state.selectedPageId : null,
					section: this.state.selectedSectionId ? this.state.selectedSectionId : null,
					path: item.path,
				};
			});
			dt = {
				title: this.state.title,
				content: this.state.content,
				name: this.state.name,
				author: author,
				categories: categories,
				location: this.state.pairLocation ? this.state.pairLocation : this.state.removeLocation ? null : null,
				status: this.state.status,
				article_type: this.state.selectedArticleType,
				article_languages: article_languages,
			};
		} else if (articleType === 2) {
			article_languages = this.state.articleLanguages.map((item, idx) => {
				return {
					language: item.language,
					title: item.title,
					summary: item.summary,
					content_file: this.state.content_assets_file[idx],
					description: item.description,
					page: this.state.selectedPageId ? this.state.selectedPageId : null,
					section: this.state.selectedSectionId ? this.state.selectedSectionId : null,
					path: item.path,
				};
			});
			dt = {
				name: this.state.name,
				author: author,
				categories: categories,
				location: this.state.pairLocation ? this.state.pairLocation : this.state.removeLocation ? null : null,
				status: this.state.status,
				article_type: this.state.selectedArticleType,
				article_languages: article_languages,
			};
		} else if (articleType === 3) {
			article_languages = this.state.articleLanguages.map((item, idx) => {
				return {
					language: item.language,
					name: item.title,
					title: item.title,
					summary: item.summary,
					content: item.content,
					content_section: item.content_section,
					description: item.description,
					page: this.state.selectedPageId ? this.state.selectedPageId : null,
					section: this.state.selectedSectionId ? this.state.selectedSectionId : null,
					path: item.path,
				};
			});
			dt = {
				title: this.state.title,
				content: this.state.content,
				name: this.state.name,
				author: author,
				categories: categories,
				location: this.state.pairLocation ? this.state.pairLocation : this.state.removeLocation ? null : null,
				status: this.state.status,
				article_type: this.state.selectedArticleType,
				article_languages: article_languages,
			};
		}

		if (this.state.date) {
			dt["date"] = this.state.dateToApi;
		}

		if (this.state.selectedAssetAdd.length > 0) {
			const ids = this.state.selectedAssetAdd.map((item) => {
				return {
					asset: item.id,
				};
			});

			dt["article_assets"] = ids;
		}

		if (this.state.selectedWidget) {
			dt["widgets"] = this.state.selectedWidget.map((item) => item.id);
		}

		objArticle
			.addArticle(dt)
			.then((res) => {
				const keywords_languages = this.state.articleKeywords.map((item) => {
					return {
						article: res.data.id,
						language: parseInt(item.language),
						keyword: item.keyword,
					};
				});
				this.setLoading();

				objArticle.addKeywords(keywords_languages).then((res) => {
					swal("Success !", t(this.state.transAlert + "article_add_success"), "success");

					if (this.props.match.path === "/article/widgets/detail/:widgetId") {
						const widgetId = this.props.match.params.widgetId;
						setTimeout(() => {
							this.props.history.push(`/widget/detail/${widgetId}`);
						}, 3000);
					} else {
						setTimeout(() => {
							this.props.history.push(`/article`);
						}, 3000);
					}
				});
			})
			.catch((err) => {
				console.log("err", err);
				const objAlert = new AlertHelper();
				objAlert.errorHandlerAlert(err.response.data);
			});
	}

	updateArticle() {
		this.setLoading();

		const { t } = this.props;
		const objArticle = new ArticleApi();
		const articleId = this.state.articleId;

		const article_languages = this.state.articleLanguages.map((item, idx) => {
			return {
				id: item.id,
				language: item.language,
				title: item.title,
				summary: item.summary,
				content: item.content,
				content_section: item.content_section,
				content_file: this.state.content_assets_file[idx] ? this.state.content_assets_file[idx] : false,
				description: item.description,
				page: this.state.selectedPageId ? this.state.selectedPageId : null,
				section: this.state.selectedSectionId ? this.state.selectedSectionId : null,
				path: item.path,
			};
		});

		const author = this.state.author.length > 0 ? this.state.author[0].val : null;

		const categories = this.state.category.map((item) => {
			return parseInt(item.id);
		});

		var dt = {
			// title:this.state.title,
			// content:this.state.content,
			name: this.state.name,
			// language:this.state.language,
			categories: categories,
			author: author,
			location: this.state.pairLocation ? this.state.pairLocation : this.state.removeLocation ? null : null,
			//widget:this.state.widget,
			status: this.state.status,
			article_type: parseInt(this.state.selectedArticleType),
			article_languages: article_languages,
			//date:this.state.date,
			// assets: this.state.assets,
		};

		if (this.state.date) {
			dt["date"] = this.state.dateToApi;
		}

		// if (this.state.assets.length > 0) {
		// 	dt["assets"] = this.state.assets;
		// }

		if (this.state.selectedWidget) {
			dt["widgets"] = this.state.selectedWidget.map((item) => item.id);
		}

		this.state.loadedKeywords.forEach((k, i) => {
			const keywords_languages = {
				language: k.language,
				keyword: k.keyword,
			};

			objArticle.updateKeywords(k.id, keywords_languages).then((res) => {});
		});

		objArticle
			.updateArticle(articleId, dt)
			.then((res) => {
				const keywords_languages = this.state.articleKeywords.map((item) => {
					return {
						article: articleId,
						language: parseInt(item.language),
						keyword: item.keyword,
					};
				});

				this.setLoading();
				objArticle.addKeywords(keywords_languages).then((res) => {});

				swal("Success !", t(this.state.transAlert + "article_update_success"), "success");

				if (this.props.match.path === "/article/detail/:articleId/:widgetId") {
					const widgetId = this.props.match.params.widgetId;
					setTimeout(() => {
						this.props.history.push(`/widget/detail/${widgetId}`);
					}, 3000);
				} else if (this.props.match.path === "/article/detail/:articleId") {
					setTimeout(() => {
						this.props.history.push(`/article`);
					}, 3000);
				}
			})
			.catch((err) => {
				const objAlert = new AlertHelper();
				objAlert.errorHandlerAlert(err.response.data);
			});
	}

	getArticleCategories() {
		const objArticle = new ArticleApi();

		objArticle.getArticleCategories().then((res) => {
			this.setState({
				categoryList: res.data.results,
			});
		});
	}

	getAllWidget() {
		const objWidget = new WidgetApi();

		const urlParams = new URLSearchParams(window.location.search);
		const templateId = urlParams.get("template");

		const params = {
			template: templateId ? templateId : Number(localStorage.getItem("templateId")),
			max_size: true,
			category: 3,
			internal: this.state.is_internal,
		};

		objWidget.getAllWidgets(params).then((res) => {
			// namePage setting
			const widgetList = res.data.results.map((item) => {
				var page = "";
				if (item.page) {
					page = " - " + item.page;
				}

				return {
					...item,
					namePage: item.name + page,
				};
			});

			this.setState({
				widgetList: widgetList,
			});
		});
	}

	getDetailWidget() {
		const objWidget = new WidgetApi();
		const widgetId = this.props.match.params.widgetId;

		return objWidget.getDetailWidget(widgetId);
	}

	getLanguages() {
		const { languagesList } = this.context;

		this.setState(
			{
				languageList: languagesList,
			},
			() => {
				// ===================================================================================================================================
				var index = this.state.languageList.findIndex((item) => item.abbreviation === localStorage.getItem("lang"));

				this.setState(
					{
						tabKey: index,
					},
					() => {
						this.renderTabLanguage();
						// this.handleChangeTab(this.state.tabKey)
					}
				);
			}
		);
	}

	getPages() {
		const objPage = new PageApi();

		const params = {
			language: localStorage.getItem("lang"),
			template: localStorage.getItem("templateId"),
			max_size: true,
		};

		objPage.pageLanguageList(params).then((res) => {
			this.setState(
				{
					pages: res.data.results,
				},
				() => {
					if (this.state.selectedPageId) {
						this.getSectionByPage();
					}
				}
			);
		});
	}

	getSectionByPage() {
		const objSection = new SectionApi();

		const params = {
			language: localStorage.getItem("lang"),
			page_id: this.state.selectedPageId,
			max_size: true,
		};

		objSection.sectionLanguage(params).then((res) => {
			this.setState({
				sections: res.data.results,
			});
		});
	}

	getCurrentUser() {
		const objUser = new UserApi();

		objUser
			.getCurrentUser()
			.then((res) => {
				const author = [
					{
						val: res.data.id,
						label: res.data.user.first_name + " " + res.data.user.last_name,
					},
				];

				// alert(JSON.stringify(author))

				this.setState({
					author: author,
				});
			})
			.catch((err) => {
				console.log("err getCurrent user => ", err.message);
			});
	}

	renderTabLanguage() {
		const articleLanguages = this.state.languageList.map((item) => {
			var result = this.state.articleLanguages.find((articleLang) => item.id === articleLang.language);
			if (!result) {
				result = {
					article: this.state.articleId || 0,
					language: item.id,
					title: "",
					summary: "",
					content: "",
					content_section: "",
					page: null,
					section: null,
					path: "",
					description: "",
					keyword: "",
				};
			}

			result.languageName = item.name;
			result.page = !this.state.selectedPageId ? null : this.state.selectedPageId;
			result.section = !this.state.selectedSectionId ? null : this.state.selectedSectionId;
			return result;
		});

		this.setState(
			{
				articleLanguages: articleLanguages,
			},
			() => {
				this.handleChangeTab(this.state.tabKey); // english default
			}
		);
	}

	handleKeyword(value) {
		this.setState({
			activeKeyword: value,
		});
	}

	addKeyword(index) {
		// let keywords = document.getElementById('fk-content-keywords').value
		if (this.state.activeKeyword !== "") {
			const articleKeyword = {
				id: `${this.state.articleKeywords.length + 1}`,
				language: index + 1,
				keyword: this.state.activeKeyword,
			};
			this.setState(
				{
					articleKeywords: this.state.articleKeywords.concat(articleKeyword),
					activeKeyword: "",
				},
				() => {
					document.getElementById("fk-content-keywords0").value = "";
					document.getElementById("fk-content-keywords1").value = "";
				}
			);
		}
	}

	updateKeyword(id, value) {
		let articleKeywords = [...this.state.articleKeywords];
		let articleKeyword = { ...articleKeywords[id - 1] };

		articleKeyword.keyword = value;
		articleKeywords[id - 1] = articleKeyword;

		this.setState({
			articleKeywords: articleKeywords,
		});
	}

	updateLoadedKeyword(index, value) {
		let loadedKeywords = [...this.state.loadedKeywords];
		let loadedKeyword = { ...loadedKeywords[index] };

		loadedKeyword.keyword = value;
		loadedKeywords[index] = loadedKeyword;

		this.setState({
			loadedKeywords: loadedKeywords,
		});
	}

	deleteAddedKeyword(index) {
		this.setState({
			articleKeywords: this.state.articleKeywords.filter(function (ele, i) {
				return i !== index;
			}),
		});
	}

	deleteLoadedKeyword(id, index) {
		const objArticle = new ArticleApi();

		this.setState({
			loadedKeywords: this.state.loadedKeywords.filter(function (ele, i) {
				return i !== index;
			}),
		});

		objArticle.deleteKeywords(id).then((res) => {
			// this.getDetailArticle()
		});
	}

	setShowLocationModal() {
		this.setState({
			setShowLocationModal: !this.state.setShowLocationModal,
		});
	}

	setShowArticleAddLocationModal() {
		this.setState({
			setShowArticleAddLocationModal: !this.state.setShowArticleAddLocationModal,
		});
	}

	addNewLocation(newLocation) {
		const locationObj = new LocationApi();

		locationObj
			.addLocation(newLocation)
			.then((res) => {
				if (res.data) {
					this.setState({
						pairLocation: res.data.id,
						location: res.data,
					});
					// swal("Success !", t(this.state.transAlert + "newlocation_add_success"), "success")
					this.setShowLocationModal();
				}
			})
			.catch((err) => {
				console.log("err => ", err);
			});
	}

	pairLocationWithArticle(location) {
		this.setState({
			pairLocation: location.id,
			location: location,
		});
		this.setShowArticleAddLocationModal();
	}

	removeLocation(locationId) {
		if (locationId) {
			this.setState({
				pairLocation: false,
				location: false,
				removeLocation: true,
			});
		}
	}

	handleChangeContentFile = (e, index) => {
		let content_file = [...this.state.content_assets_file];
		content_file[index] = e.target.files[0];

		this.setState({
			content_assets_file: content_file,
		});
	};

	showPreviewContentFile = (url) => {
		window.open(url, "_blank");
	};

	showUpdateContentFile = (index) => {
		let update_assets_file = [...this.state.update_assets_file];
		update_assets_file[index] = !update_assets_file[index];

		this.setState({
			update_assets_file: update_assets_file,
		});
	};

	render() {
		const { t } = this.props;

		// const Categories = this.state.categoryList.map(item => {
		//     return (<option value={item.id} key={item.id}> {item.category}</option>)
		// })

		const Categories = this.state.categoryList.map((item) => {
			return {
				id: item.id,
				name: item.category,
			};
		});

		var categorySelected = [];
		// SELECTED CATEGORY
		if (this.state.categoryList.length > 0) {
			categorySelected = this.state.category.map((item, index) => {
				for (var ii = 0; ii < this.state.categoryList.length; ii++) {
					if (this.state.categoryList[ii]?.id === item || this.state.categoryList[ii]?.id === item?.id) {
						return {
							id: this.state.categoryList[ii]?.id,
							name: this.state.categoryList[ii]?.category,
						};
					}
				}

				return { id: 0, name: "" };
			});
		}

		const PageSelect = this.state.pages.map((item) => {
			return (
				<option key={item.id} value={item.id}>
					{item.name}
				</option>
			);
		});

		const SectionSelect = this.state.sections.map((item) => {
			return (
				<option key={item.id} value={item.section}>
					{item.name}
				</option>
			);
		});

		const TabLanguage = this.state.articleLanguages.map((item, index) => {
			let ckeditorConfig = {
				toolbar: {
					items: ["heading", "|", "bold", "italic", "|", "superscript", "subscript", "|", "link", "|", "bulletedList", "numberedList", "|", "insertTable", "|", "undo", "redo"],
				},
				table: {
					contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
				},
				language: "",
			};

			if (item.language === 2) {
				ckeditorConfig.language = "vi";
			} else {
				ckeditorConfig.language = "en";
			}

			const { selectedArticleType } = this.state;
			let contentEditor;
			switch (selectedArticleType) {
				case 1:
					contentEditor = (
						<CKEditor
							id="editor"
							config={ckeditorConfig}
							editor={ClassicEditor}
							data={this.state.articleLanguages[index].content}
							onInit={(editor) => {
								//console.log( 'Editor is ready to use! '+index, editor );
							}}
							onChange={(event, editor) => {
								const data = editor.getData();

								this.handleChangeContent(index, data);
							}}
						/>
					);
					break;
				case 2:
					const { loaded_content_assets_file } = this.state;
					let editor;
					if (loaded_content_assets_file && loaded_content_assets_file.length > 0) {
						if (loaded_content_assets_file[index].content_file) {
							editor = (
								<>
									<div className="d-flex">
										<button className="btn btn-primary mb-3 mr-3" onClick={() => this.showPreviewContentFile(loaded_content_assets_file[index].content_file)}>
											<i className="fa fa-eye mr-1"></i>Preview File
										</button>
										<button className="btn btn-primary mb-3" onClick={() => this.showUpdateContentFile(index)}>
											<i className="fa fa-edit mr-1"></i>Update
										</button>
									</div>
									{this.state.update_assets_file[index] && (
										<div className="d-flex flex-column">
											<input type="file" name="asset_file" className="form-control w-25" onChange={(event) => this.handleChangeContentFile(event, index)}></input>
											<button className="btn btn-primary mb-3 mt-3 w-25" onClick={() => this.showUpdateContentFile(index)}>
												<i className="fa fa-times mr-1"></i>Cancel
											</button>
										</div>
									)}
								</>
							);
						} else {
							editor = <input type="file" name="asset_file" className="form-control" onChange={(event) => this.handleChangeContentFile(event, index)}></input>;
						}
					} else {
						editor = <input type="file" name="asset_file" className="form-control" onChange={(event) => this.handleChangeContentFile(event, index)}></input>;
					}
					contentEditor = (
						<div className="d-flex flex-column">
							{/* <label>{t("content_file")}</label> */}
							<input type="text" name="content-pdf" className="form-control mb-3" placeholder="Article Source" defaultValue={this.state.articleLanguages[index].content} onChange={(e) => this.handleChangeContent(index, e.target.value)}></input>
							{editor}
						</div>
					);
					break;
				case 3:
					contentEditor = (
						<AdvancedArticle pageActive={this.state.pageActive} langIdx={index} articleAssets={[...this.state.article_assets, ...this.state.selectedAssetAdd]} articleLanguages={this.state.articleLanguages} handleChangeContent={this.handleChangeContent} handleChangeContentSection={this.handleChangeContentSection} selectedArticleType={selectedArticleType} />
					);
					break;

				default:
					break;
			}

			return (
				<Tab key={index} eventKey={index} title={item.languageName}>
					<br></br>

					<div className="form-group">
						<label> {t("title")} </label>
						<input className="form-control" placeholder={t(this.state.transPage + "title_placeholder")} value={this.state.articleLanguages[index].title} onChange={(event) => this.handleChangeTitle(index, event.target.value)}></input>
					</div>

					<div className="form-group">
						<label> {t("summary")} </label>
						<textarea className="form-control" value={this.state.articleLanguages[index].summary} onChange={(event) => this.handleChangeSummary(index, event.target.value)}></textarea>
					</div>

					<div className="form-group">
						<label> {t("content")}</label>
						{contentEditor}
					</div>

					<br></br>

					<div className="seo-section">
						<label> SEO </label>
						<div className="form-group">
							<label> {t("description")} </label>
							<textarea className="form-control" value={this.state.articleLanguages[index].description} onChange={(event) => this.handleChangeDescription(index, event.target.value)}></textarea>
						</div>

						<div id="form-keywords" className="form-group">
							<label> {t("keyword")} </label>
							<div className="fk-content">
								{/* <input className="form-control"
                                defaultValue={this.state.articleKeywords[index] ? this.state.articleKeywords[index].keyword : this.state.articleLanguages[index].keyword}
                                onChange={(event) => this.handleChangeKeyword(index, event.target.value)}
                            ></input> */}
								{this.state?.loadedKeywords.map((a, i) => {
									return parseInt(a.language - 1) === index ? (
										<div className="fk-c-in">
											<i className="fa fa-times fa-sm" onClick={(event) => this.deleteLoadedKeyword(a.id, i)}></i>
											<input type="text" className="form-control" value={a.keyword} onChange={(event) => this.updateLoadedKeyword(i, event.target.value)} />
										</div>
									) : (
										<></>
									);
								})}
								{this.state?.articleKeywords.map((a, i) => {
									return parseInt(a.language - 1) === index ? (
										<div className="fk-c-in">
											<i className="fa fa-times fa-sm" onClick={(event) => this.deleteAddedKeyword(i)}></i>
											<input type="text" className="form-control" defaultValue={a.keyword} onChange={(event) => this.updateKeyword(a.id, event.target.value)} />
										</div>
									) : (
										<></>
									);
								})}
								<input id={`fk-content-keywords${index}`} type="text" className="form-control" defaultValue={""} onChange={(event) => this.handleKeyword(event.target.value)} />

								<div className="fk-icn-add" onClick={() => this.addKeyword(index)}>
									<i className="fa fa-plus"></i>
								</div>
							</div>
						</div>
					</div>
				</Tab>
			);
		});

		var AssetSection = "";

		if (this.props.match.path === "/article/add") {
			AssetSection = (
				<ArticleAssetListAdd
					selectedAssetAdd={this.state.selectedAssetAdd}
					setSelectedAssetAdd={this.setSelectedAssetAdd}
					//assets={this.state.assets}
					deleteSelectedAssetAdd={this.deleteSelectedAssetAdd}
					getDetailArticle={this.getDetailArticle}
				></ArticleAssetListAdd>
			);
		} else if (this.props.match.path === "/article/widgets/detail/:widgetId") {
			AssetSection = (
				<ArticleAssetListAdd
					selectedAssetAdd={this.state.selectedAssetAdd}
					setSelectedAssetAdd={this.setSelectedAssetAdd}
					//assets={this.state.assets}
					deleteSelectedAssetAdd={this.deleteSelectedAssetAdd}
					getDetailArticle={this.getDetailArticle}
				></ArticleAssetListAdd>
			);
		} else {
			AssetSection = <ArticleAssetListUpdate articleId={this.state.articleId} article_assets={this.state.article_assets} getDetailArticle={this.getDetailArticle} />;
		}

		const years = myRange(1990, myGetYear(new Date()) + 1, 1);
		const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

		var URLInput = this.state.articleLanguages.map((item, index) => {
			return (
				<div className="col-md-6">
					<div className="form-group" key={index}>
						<label> Url {item.languageName}</label>
						<input value={item.path} type="text" className="form-control" onChange={(event) => this.handleUrlExternalChange(index, event.target.value)}></input>
					</div>
				</div>
			);
		});

		if (this.state.urlType === "internal") {
			URLInput = (
				<>
					<div className="col-md-6">
						<div className="form-group">
							<label> {t("page")} </label>
							<select className="form-control" value={this.state.selectedPageId} onChange={(event) => this.handlePageChange(event.target.value)}>
								<option value={null}> -- {t(this.state.transPage + "select_page")} -- </option>
								{PageSelect}
							</select>
						</div>
					</div>

					<div className="col-md-6">
						<div className="form-group">
							<label> {t("section")} </label>
							<select className="form-control" value={this.state.selectedSectionId} onChange={(event) => this.handleSectionChange(event.target.value)}>
								<option value="0"> -- {t(this.state.transPage + "select_section")} -- </option>
								{SectionSelect}
							</select>
						</div>
					</div>
				</>
			);
		}

		// const selectedAsset = this.state.selectedAssetAdd.map((item,index) => {
		//     return (<li key={index}>{item.id} - {item.name}</li>)
		// })

		let renderLocation;

		if (this.state.location) {
			renderLocation = (
				<div className="d-flex">
					<button className="btn btn-primary mb-3" onClick={this.setShowArticleAddLocationModal}>
						<i className="fa fa-edit mr-1"></i>Change Location
					</button>
					<div className="d-flex file-input-custom ml-3" onClick={this.setShowLocationModal}>
						<label for="">
							<i className="fa fa-plus mr-1"></i>Add new Location
						</label>
					</div>
				</div>
			);
		} else {
			renderLocation = (
				<div className="d-flex">
					<button className="btn btn-primary mb-3" onClick={this.setShowArticleAddLocationModal}>
						<i className="fa fa-plus mr-1"></i>Add Location
					</button>
					<div className="d-flex file-input-custom ml-3" onClick={this.setShowLocationModal}>
						<label for="">
							<i className="fa fa-plus mr-1"></i>Add new Location
						</label>
					</div>
				</div>
			);
		}

		let defaulLocation;

		if (this.state.pairLocation && this.state.location) {
			defaulLocation = {
				lat: parseFloat(this.state.location.latitude),
				lng: parseFloat(this.state.location.longitude),
			};
		} else {
			defaulLocation = {
				lat: 0,
				lng: 0,
			};
		}

		return (
			<LoadScript googleMapsApiKey={this.state.gMapApiKey} libraries={this.state.mapsLib}>
				<Content>
					{this.state.overlay}

					<ContentHeader>
						<div className="row" style={{ padding: "0 5px" }}>
							<TitlePages title={this.state.name} />

							<Button onClick={() => this.props.history.goBack()} className="btn btn-default col-md-1 mt-3">
								<i className="fa fa-arrow-left">&nbsp;</i>
								{t("back")}
							</Button>
						</div>

						<div className="clear"></div>
					</ContentHeader>
					<ContentBody>
						<Card>
							<CardHeader>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<label>{t("date")}</label>
											<div>
												<DatePicker
													className="form-control"
													wrapperClassName="form-control"
													onChange={(date) => this.handleChangeDate(date)}
													selected={this.state.date}
													renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
														<div
															style={{
																margin: 10,
																display: "flex",
																justifyContent: "center",
															}}
														>
															<button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
																{"<"}
															</button>
															<select value={myGetYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
																{years.map((option) => (
																	<option key={option} value={option}>
																		{option}
																	</option>
																))}
															</select>

															<select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
																{months.map((option) => (
																	<option key={option} value={option}>
																		{option}
																	</option>
																))}
															</select>

															<button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
																{">"}
															</button>
														</div>
													)}
												></DatePicker>
												{/* {JSON.stringify(this.state.date)} */}
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<label>{t("status")}</label>
											<select className="form-control" value={this.state.status} onChange={(event) => this.handleChangeStatus(event.target.value)}>
												<option value="Drafted">{t("drafted")}</option>
												<option value="Published">{t("published")}</option>
											</select>
											{/* {this.state.status} */}
										</div>
									</div>
								</div>
							</CardHeader>
							<CardHeader>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<label>{t("collections")}</label>
											{/* { JSON.stringify(this.state.selectedWidget)} */}
											<Select
												multi
												disabled={this.state.widgetDisable}
												searchable={true}
												searchBy="name"
												options={this.state.widgetList}
												values={[...this.state.selectedWidget]}
												valueField="id"
												labelField="name"
												onDropdownClose={(value) => {
													// console.log("dropdownClose" , value)
												}}
												onChange={(selectedWidget) => {
													this.setState({
														selectedWidget,
													});
													// const newValue = this.state.selectedWidget.map((val) => ({ value: val.id, label: val.name }));

													// return this.setState({
													//   selectedWidget: [...this.state.selectedWidget, ...newValue]
													// });
												}}
											/>
											{/* { JSON.stringify(this.state.widgetList)} */}
										</div>
										<div className="form-group">
											<label>{t("name")}</label>
											<input type="text" name="" className="form-control" value={this.state.name} onChange={(event) => this.handleChangeName(event.target.value)}></input>
											{/* {this.state.name} */}
										</div>
									</div>
									<div className="col-md-6">
										{/* <div className="form-group">
                                        <label>Category</label>
                                        <select className="form-control" value={this.state.category} onChange={(event) => this.handleChangeCategory(event.target.value)}>
                                            <option value=""> -- Select Category --</option>
                                            {Categories}
                                        </select>
                                        
                                    </div> */}
										<div className="form-group">
											<label>{t("category")}</label>
											{/* { JSON.stringify(this.state.selectedWidget)} */}
											<Select
												multi
												//disabled={this.state.widgetDisable}
												searchable={true}
												searchBy="name"
												options={Categories}
												values={categorySelected}
												valueField="id"
												labelField="name"
												onDropdownClose={(value) => {
													// console.log("dropdownClose" , value)
												}}
												onChange={(selectedcategory) => {
													this.handleChangeCategory(selectedcategory);
													// const newValue = this.state.selectedWidget.map((val) => ({ value: val.id, label: val.name }));

													// return this.setState({
													//   selectedWidget: [...this.state.selectedWidget, ...newValue]
													// });
												}}
											/>
											{/* { JSON.stringify(Categories)} */}
										</div>
										<div className="form-group">
											<label>{t("author")}</label>
											{/* { this.state.employeeList.map(item => item.label)} */}
											{/* <input type="text" name="author" value={this.state.author} className="form-control" onChange={(event) => this.handleAuthorChange(event.target.value)} ></input> */}
											<Select
												searchable={true}
												searchBy="label"
												name="form-field-name"
												values={this.state.author}
												options={this.state.employeeList}
												valueField="val"
												labelField="label"
												onChange={(author) => {
													this.setState({
														author: author,
													});
													// const newValue = this.state.selectedWidget.map((val) => ({ value: val.id, label: val.name }));

													// return this.setState({
													//   selectedWidget: [...this.state.selectedWidget, ...newValue]
													// });
												}}
											/>
										</div>
									</div>
								</div>
							</CardHeader>
							<CardHeader>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<label> {t("url_type")}</label>
											<br></br>
											<label htmlFor="url-internal">
												<input type="radio" className="" id="url-internal" name="url_type" value={"internal"} checked={this.state.urlType === "internal"} onChange={() => this.handleUrlTypeChange("internal")}></input>
												&nbsp; {t("internal")}
											</label>
											&nbsp; &nbsp;
											<label htmlFor="url-external">
												<input type="radio" className="" id="url-external" name="url_type" value="external" checked={this.state.urlType === "external"} onChange={() => this.handleUrlTypeChange("external")}></input>
												&nbsp; {t("external")}
											</label>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<input type="checkbox" id="is_nofollow" onClick={() => this.handleChangeNoFollow} checked={this.state.is_nofollow}></input>
											&nbsp;
											<label htmlFor="is_nofollow"> {t("no_follow")} </label>
										</div>
									</div>
								</div>
								<div className="row">{URLInput}</div>
							</CardHeader>
							<CardBody>
								<div className="col-md-6">
									<div className="form-group">
										<label> {t("article_type")}</label>
										<br></br>
										<label htmlFor="article-type">
											<input type="radio" className="" id="article-type1" name="article-type1" value={1} checked={this.state.selectedArticleType === 1} onChange={() => this.handleArticleTypeChange(1)}></input>
											&nbsp; 1
										</label>
										&nbsp; &nbsp;
										<label htmlFor="article-type">
											<input type="radio" className="" id="article-type3" name="article-type2" value={2} checked={this.state.selectedArticleType === 2} onChange={() => this.handleArticleTypeChange(2)}></input>
											&nbsp; 2
										</label>
										&nbsp; &nbsp;
										<label htmlFor="article-type">
											<input type="radio" className="" id="article-type3" name="article-type3" value={3} checked={this.state.selectedArticleType === 3} onChange={() => this.handleArticleTypeChange(3)}></input>
											&nbsp; 3
										</label>
									</div>
								</div>
								<Tabs id="controlled-tab-example" activeKey={this.state.tabKey} onSelect={(k) => this.handleChangeTab(k)}>
									{TabLanguage}
								</Tabs>
							</CardBody>

							<CardBody>
								<div className="form-group">
									<label>Location</label>
									{this.state.pairLocation && this.state.location ? (
										<div className="form-group position-relative">
											<div style={{ position: "absolute", right: "0", zIndex: "2" }}>
												<div className="fk-icn-remove" onClick={() => this.removeLocation(this.state.location.id)}>
													<i className="fa fa-times"></i>
												</div>
											</div>
											<p>{this.state.location.company_name || this.state.location.location_languages[0].location_type || this.state.location.location_languages[1].location_type}</p>
											<GoogleMap mapContainerStyle={{ width: "100%", height: "30vh" }} center={defaulLocation} zoom={10}>
												<Marker position={defaulLocation} />
											</GoogleMap>
											<p>{this.state.location.address || this.state.location.location_languages[0].address || this.state.location.location_languages[1].address}</p>
										</div>
									) : (
										<></>
									)}
									{renderLocation}
								</div>
							</CardBody>

							<hr></hr>
							<CardBody>{[1,2,3].includes(this.state.selectedArticleType) && AssetSection}</CardBody>
							<CardFooter>
								{this.state.ButtonForm}
								<Button onClick={() => this.props.history.goBack()} className="btn btn-danger float-right" style={{ marginRight: 10 }}>
									<i className="fa fa-times">&nbsp;</i>
									{t("cancel")}
								</Button>
							</CardFooter>
						</Card>
					</ContentBody>

					<ModalLocation show={this.state.setShowLocationModal} onHide={this.setShowLocationModal} addNewLocation={this.addNewLocation}></ModalLocation>
					<WidgetArticleAddLocationModal
						show={this.state.setShowArticleAddLocationModal}
						onHide={this.setShowArticleAddLocationModal}
						locationId={this.state.pairLocation}
						// widgetId={this.props.data.widget}
						// getWidgetDetail={this.getDetailWidget}
						// selectedAssets={this.state.selectedAssets}
						// type={""}
						// selectedAsset={this.handleSelectedAssetThumbnail}
						pairLocationWithArticle={this.pairLocationWithArticle}
					></WidgetArticleAddLocationModal>
				</Content>
			</LoadScript>
		);
	}
}

export default withRouter(withTranslation()(ArticleDetailPage));
