import React, { Component } from 'react';
import { Modal, Card, Button } from "react-bootstrap"
import swal from 'sweetalert'
import AlertHelper from "./../../lib/AlertHelper"
import Pagination from "react-js-pagination";

import AssetApi from "../../api/AssetApi"

import { shortSentence } from "../../lib/StringHelper"
import ArticleApi from '../../api/ArticleApi';

class ArticleAssetChangeModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            articleId: 0,
            articleAssetId: 0,
            assets: [],
            selectedOneAsset: {},
            previousOneAsset: {},
            pairDisable: true,
            loadingModal: "",

            activePage: 1,
            limitPageData: 30,
            totalCount: 0,

            search: "",
        }
        this.getAllAssets = this.getAllAssets.bind(this)
        this.handleSelectedAsset = this.handleSelectedAsset.bind(this)
        this.onShowModal = this.onShowModal.bind(this)
        this.addAssetArticle = this.addAssetArticle.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
    }

    onShowModal() {
        this.getAllAssets()
        this.setState({
            articleId: this.props.articleId,
            articleAssetId: this.props.articleAssetId,
            selectedOneAsset: this.props.assetId,
            previousOneAsset: this.props.assetId,
            pairDisable: false
        })

        //alert("props => "+this.props.articleId)
    }

    getAllAssets() {
        const objAsset = new AssetApi()

        return objAsset.getAllAssets({
            page_size: this.state.limitPageData,
            page: this.state.activePage,
            search: this.state.search,
            order: '-id'
        })
            .then(res => {

                //console.log(res.data.results)

                this.setState({
                    assets: res.data.results,
                    totalCount: res.data.count,
                })
            })
    }

    handleSelectedAsset(value) {

        this.setState({
            selectedOneAsset: value
        }, () => {
            if (Object.keys(this.state.selectedOneAsset).length > 0) {
                this.setState({
                    pairDisable: false,

                })
            } else {
                this.setState({
                    pairDisable: true,

                })
            }
        })
    }

    addAssetArticle() {
        const objArticle = new ArticleApi()

        this.setState({
            loadingModal: <div className="overlay d-flex justify-content-center align-items-center">
                <i className="fas fa-2x fa-sync fa-spin"></i>
            </div>
        })

        const asset = this.state.selectedOneAsset.id
        const articleAssetPair = this.state.articleAssetId

        //console.log(" dt => ",asset, articleAssetPair)

        objArticle.articleAssetChange(articleAssetPair, asset)
            .then(res => {
                this.props.getDetailArticle()
                this.setState({
                    loadingModal: ""
                })
                this.props.onHide()
                swal("Success !", "You sucessfully change asset into this article !", "success");
            })
            .catch(err => {
                const objAlert = new AlertHelper()
                objAlert.errorHandlerAlert(err.response.data)
            })
    }

    handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,

        }, () => {
            this.getAllAssets()
        });
    }

    handleSearchChange(value) {
        this.setState({
            search: value,
            activePage: 1
        }, () => {
            this.getAllAssets()
        })
    }

    render() {
        const AssetList = this.state.assets.length > 0 ? this.state.assets.map((item, index) => {

            // console.log("asset item => ", item )
            var media = <img width={"100%"} alt="" src={item.asset_file}></img>
            if (item.file_type === "URL") {
                media = <iframe title={`asset-media-embedded-${index}`} width={"100%"} src={item.asset_link} allowFullScreen></iframe>
            } else if (item.file_type === "Video") {
                media = <video width={"100%"} height={"50%"} title="Video" controls>
                    <source src={item.asset_file} />
                </video>
            }


            return (
                <div key={item.id} className="col-md-2">
                    <Card>
                        <Card.Header className={item.id === this.state.selectedOneAsset.id ? "bg-success" : "bg-primary"}>
                            <div className="form-check form-check-inline">
                                <input type="radio" className="form-check-input"
                                    checked={item.id === this.state.selectedOneAsset.id ? true : false}
                                    id={"label-modal-" + item.id}
                                    onClick={(event) => this.handleSelectedAsset(item, event.target.checked)}></input>
                                <label className="form-check-label" htmlFor={"label-modal-" + item.id}>{item.name}</label>
                            </div>

                        </Card.Header>
                        <Card.Body>
                            <div className="container-fluid" align="center">
                                {media}
                            </div>
                        </Card.Body>


                        <Card.Footer>
                            <div align="center" style={{ fontSize: 12 }}>{shortSentence(item.description, 6)} ...</div>
                        </Card.Footer>
                    </Card>
                </div>
            )
        }) : <div className="col-md-12 center-block"><h4 className="text-center" >There is no Assets</h4></div>

        return (
            <Modal show={this.props.show} onHide={() => this.props.onHide()} size="xl" onShow={() => this.onShowModal()}>
                {this.state.loadingModal}
                <Modal.Header closeButton className="bg-primary">
                    Select Asset
                </Modal.Header>

                <Modal.Body>
                    <div style={{ float: "left" }}>
                        <div className="form-group">

                            <input type="text" placeholder="search..." className="form-control" name="search" value={this.state.search} onChange={(event) => this.handleSearchChange(event.target.value)} />
                        </div>
                    </div>
                    <div style={{ float: "right" }}>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.limitPageData}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={4}
                            onChange={this.handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"

                        />
                    </div>
                    <div className="clearfix"></div>
                    <div className="row">
                        {AssetList}
                    </div>
                    <div style={{ float: "right" }}>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.limitPageData}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={4}
                            onChange={this.handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"

                        />
                    </div>
                    <div className="clearfix"></div>

                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={this.state.pairDisable} onClick={() => this.addAssetArticle()}> <i className="fa fa-paperclip">&nbsp;</i> Pair These Assets </Button>
                </Modal.Footer>


            </Modal>
        );
    }
}

export default ArticleAssetChangeModal;