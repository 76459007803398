import React, { Component } from 'react';
import Card,{ CardHeader, CardBody  } from "./../../components/Card";
import { Dropdown } from "react-bootstrap"
import { Link } from "react-router-dom"

import { shortSentence } from "./../../lib/StringHelper"

class TextElementItem extends Component {

    render() {


        const item = this.props.item

        var index = this.props.languages.findIndex( item => item.abbreviation === localStorage.getItem("lang") )

        var shortContent = shortSentence(item.text_languages[index]?.content, 5)
        
        return(<div className="col-md-2" key={item.id}>
            <Card>
                <CardHeader className="bg-primary">
                    <div className="form-check form-check-inline">
                        <input type="checkbox" value="" onClick={(event) => { this.props.setSelectedGeneral("general",item, event.currentTarget.checked) }} className="form-check-input" id={"label"+item.id}></input>&nbsp;
    <label className="form-check-label" htmlFor={"label"+item.id}><b >{item.index}.{item.name}</b> </label>
                    </div>
                    <div className="card-tools">
                            <Dropdown drop="down">
                                <Dropdown.Toggle  id="dropdown-basic" as={"a"} >  
                                    <label href="#" style={{"color":"black"}}><i className="fa fa-ellipsis-v"></i></label>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => {
                                        this.props.setTextId(item.id)
                                        
                                    }}>Update</Dropdown.Item>
                                
                                </Dropdown.Menu>
                            </Dropdown>
                        
                        </div>
                </CardHeader>
                <CardBody>
                    <div style={{"textDecoration":"none",color:"black"}}>
                        <div className="container-fluid" align="center" style={{height:149}}>
                            {shortContent} ...
                        </div>
                    </div>
                </CardBody>
            </Card> 
        </div>)
    }
}

export default TextElementItem;