import React, { Component } from 'react';
import { Link } from "react-router-dom"

import ValueContext from './../section/Context'
import classes from './SidebarNew.module.css'

class MenuItem extends Component {
    static contextType = ValueContext

    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const contextValue = this.context
        const icon = this.props.icon ? this.props.icon : "fa fa-circle"
        const url = this.props.url ? this.props.url : ""
        const sidebarOpen = this.props.showSidebarValue ? "sidebar-max" : ""
        const sidebar = localStorage.getItem("sidebar")

        let activeStyle = !this.props.showSidebarValue ? classes.padActive : classes.padInActive
        let activeItem = this.props.isActive ? 'sd1-active-items' : ''

        let content
        if (this.props.onlyPages) {
            content =
                // <li className={`nav-item`} data-widget="pushmenu" onClick={() => { contextValue.showingSidebar(); this.props.onClick()}}>
                <li className={`nav-item`} data-widget="pushmenu" onClick={() => contextValue.showingSidebar()} >
                    <div className={["nav-link", activeStyle].join(' ')} onClick={this.props.onClick} data-toggle={this.props.showSidebarValue ? "tooltip" : ""} data-placement="right" title={this.props.showSidebarValue ? this.props.children : ''}>
                        <i className={`${icon} nav-icon `}></i>
                        <p className={activeItem}>{this.props.children}</p>
                    </div>
                </li>
        } else {
            content = url !== "" ?
                <li className={`nav-item`} onClick={this.props.onClick} >
                    <Link to={url} className={["nav-link", activeStyle].join(' ')} data-toggle={this.props.showSidebarValue ? "tooltip" : ""} data-placement="right" title={this.props.showSidebarValue ? this.props.children : ''}>
                        <i className={`${icon} nav-icon `}></i>
                        <p className={activeItem}>{this.props.children}</p>
                    </Link>
                </li>
                :
                <li className={`nav-item`} onClick={this.props.onClick} >
                    <div to={url} className={["nav-link", activeStyle].join(' ')} data-toggle={this.props.showSidebarValue ? "tooltip" : ""} data-placement="right" title={this.props.showSidebarValue ? this.props.children : ''}>
                        <i className={`${icon} nav-icon `}></i>
                        <p className={activeItem}>{this.props.children}</p>
                    </div>
                </li>
        }

        return (
            <>
                {content}
            </>
            // <li className={`nav-item ${sidebarOpen}`}>
            // <li className={`nav-item`} onClick={this.props.onClick}>
            //     <Link to={url} className={["nav-link", activeStyle].join(' ')}>
            //         <i className={`${icon} nav-icon `}></i>
            //         <p className={activeItem}>{this.props.children}</p>
            //     </Link>
            // </li>
        );
    }
}

export default MenuItem;