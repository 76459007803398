import { Component } from "react";
import { Modal, Button, Tab, Tabs } from "react-bootstrap";

import UserApi from "./../../api/UserApi";

import swal from "sweetalert";
import { withTranslation } from "react-i18next";

import Context from "../../pages/Context";

class AuthorAddModal extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);

        this.state = {
            authorId: 0,
            tabKey: 1,
            languageList: [],
            avatar: "",
            avatarFile: null,
            selectedLanguage: 1,
            name: "",
            transPage: "modals.author_add.",
            transAlert: "alert.",
            renderTabLanguage: "",
            author_languages: [],
        };

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeAvatar = this.handleChangeAvatar.bind(this);
        this.renderTabLanguage = this.renderTabLanguage.bind(this);
        this.handleChangeTab = this.handleChangeTab.bind(this);

        this.addAuthor = this.addAuthor.bind(this);
        this.updateAuthor = this.updateAuthor.bind(this);

        this.onShowModal = this.onShowModal.bind(this);
        this.onHideModal = this.onHideModal.bind(this);

        this.getDetailAuthor = this.getDetailAuthor.bind(this);
    }

    onShowModal() {
        const { languagesList } = this.context;

        this.setState(
            {
                authorId: this.props.authorId,
                tabKey: 1,
                languageList: languagesList,
                selectedLanguage: 1,
                name: "",
                avatarFile: null,
            },
            () => {
                if (this.state.authorId) {
                    this.getDetailAuthor();
                } else {
                    this.renderTabLanguage();
                }
            }
        );
    }

    onHideModal() {
        this.setState(
            {
                authorId: 0,
                tabKey: 1,
                name: "",
                avatarFile: null,
                renderTabLanguage: "",
                languageList: [],
                selectedLanguage: 1,
            },
            () => {
                this.props.onHide();
            }
        );
    }

    handleChangeName(id, value) {
        let author = [...this.state.author_languages];
        let lang = { ...author[id] };
        lang.name = value;
        author[id] = lang;

        this.setState(
            {
                name: author[0].name ? author[0].name : "",
                author_languages: author,
            },
            () => {
                // console.log("res s", this.state.author_languages);
            }
        );
    }

    handleChangeAvatar(event) {
        //console.log(event.target.files[0])
        this.setState({
            avatarFile: event.target.files[0],
        });
    }

    getDetailAuthor() {
        const objAuthor = new UserApi();

        objAuthor.getDetailAuthor(this.state.authorId).then((res) => {
            this.setState(
                {
                    name: res.data.name,
                    avatar: res.data.avatar,
                    author_languages: res.data.author_languages,
                },
                () => {
                    this.renderTabLanguage();
                }
            );
        });
    }

    renderTabLanguage() {
        const renderTabLanguage = this.state.languageList.map((item) => {
            var result = this.state.author_languages.find(
                (authors) => item.id === authors.language
            );

            if (!result) {
                result = {
                    language: item.id,
                    name: "",
                };
            }

            result.languageName = item.name;
            return result;
        });

        this.setState(
            {
                author_languages: renderTabLanguage,
            },
            () => {
                // console.log(renderTabLanguage);
            }
        );
    }

    handleChangeTab(value) {
        this.setState(
            {
                selectedLanguage: value,
                tabKey: value,
            },
            () => {
                this.renderTabLanguage();
            }
        );
    }

    addAuthor() {
        const { t } = this.props;
        const userApi = new UserApi();

        const a = [];

        this.state.author_languages.forEach((el) => {
            a.push({
                language: el.language,
                name: el.name,
            });
        });

        if (this.state.avatarFile === null) {
            const datas = {
                name: this.state.name,
                author_languages: a,
            };
            userApi
                .addAuthorNew(datas)
                .then((res) => {
                    swal(
                        "Success",
                        t(this.state.transAlert + "add_author_success"),
                        "success"
                    );
                    this.props.getAuthor();
                    this.onHideModal();
                })
                .catch((err) => {
                    swal("Error!", err.message, "error");
                });
        } else {
            const data = {
                name: this.state.name,
                avatar: this.state.avatarFile,
                author_languages: a,
            };
            userApi
                .addAuthor(data)
                .then((res) => {
                    swal(
                        "Success",
                        t(this.state.transAlert + "add_author_success"),
                        "success"
                    );
                    this.props.getAuthor();
                    this.onHideModal();
                })
                .catch((err) => {
                    swal("Error!", err.message, "error");
                });
        }
    }

    updateAuthor() {
        const { t } = this.props;
        const userApi = new UserApi();

        const a = [];

        this.state.author_languages.forEach((el) => {
            a.push({
                language: el.language,
                name: el.name,
            });
        });

        if (this.state.avatarFile === null) {
            const datas = {
                name: this.state.name ? this.state.name : "",
                author_languages: a,
            };

            userApi
                .updateAuthorNew(this.state.authorId, datas)
                .then((res) => {
                    swal(
                        "Success",
                        t(this.state.transAlert + "update_author_success"),
                        "success"
                    );
                    this.props.getAuthor();
                    this.onHideModal();
                })
                .catch((err) => {
                    swal("Error!", err.message, "error");
                });
        } else {
            const formData = new FormData();
            formData.append(`avatar`, this.state.avatarFile);
            formData.append(`name`, this.state.name);
            formData.append(`author_languages`, a);

            userApi
                .updateAuthor(this.state.authorId, formData)
                .then((res) => {
                    swal(
                        "Success",
                        t(this.state.transAlert + "update_author_success"),
                        "success"
                    );
                    this.props.getAuthor();
                    this.onHideModal();
                })
                .catch((err) => {
                    swal("Error!", err.message, "error");
                });
        }
    }

    // addAuthor() {
    //     const { t } = this.props;
    //     const userApi = new UserApi();

    //     const a = [];

    //     this.state.author_languages.forEach((el) => {
    //         a.push({
    //             language: el.language,
    //             name: el.name,
    //         });
    //     });

    //     const data = {
    //         name: this.state.name,
    //         avatar: this.state.avatarFile,
    //         author_languages: a,
    //     };

    //     userApi
    //         .addAuthor(data)
    //         .then((res) => {
    //             swal(
    //                 "Success",
    //                 t(this.state.transAlert + "add_author_success"),
    //                 "success"
    //             );
    //             this.props.getAuthor();
    //             this.onHideModal();
    //         })
    //         .catch((err) => {
    //             swal("Error!", err.message, "error");
    //         });
    // }

    // updateAuthor() {
    //     const { t } = this.props;
    //     const userApi = new UserApi();

    //     const a = [];

    //     this.state.author_languages.forEach((el) => {
    //         a.push({
    //             language: el.language,
    //             name: el.name,
    //         });
    //     });

    //     const data = {
    //         name: this.state.name,
    //         avatar: this.state.avatarFile,
    //         author_languages: a,
    //     };

    //     userApi
    //         .updateAuthor(this.state.authorId, data)
    //         .then((res) => {
    //             console.log("Res", res.data);
    //             swal(
    //                 "Success",
    //                 t(this.state.transAlert + "update_author_success"),
    //                 "success"
    //             );
    //             this.props.getAuthor();
    //             this.onHideModal();
    //         })
    //         .catch((err) => {
    //             swal("Error!", err.message, "error");
    //         });
    // }

    render() {
        const { t } = this.props;
        var HeaderText = t("add_author");
        var BtnSubmit = (
            <Button variant="primary" onClick={() => this.addAuthor()}>
                <i className="fa fa-plus"> &nbsp; </i>
                {t("add")}
            </Button>
        );

        if (this.state.authorId) {
            HeaderText = t("update_author");
            BtnSubmit = (
                <Button variant="primary" onClick={() => this.updateAuthor()}>
                    <i className="fa fa-edit"> &nbsp; </i>
                    {t("update")}
                </Button>
            );
        }

        const TabLanguage = this.state.author_languages.map((item, index) => {
            return (
                <Tab
                    key={index}
                    eventKey={item.language}
                    title={item.languageName}
                >
                    <br></br>

                    <div className="form-group">
                        <label> {t("name")} </label>
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.author_languages[index].name}
                            onChange={(event) =>
                                this.handleChangeName(index, event.target.value)
                            }
                        ></input>
                    </div>
                </Tab>
            );
        });

        return (
            <Modal
                size="lg"
                show={this.props.show}
                onHide={() => this.onHideModal()}
                onShow={() => this.onShowModal()}
            >
                <Modal.Header closeButton className="bg-primary">
                    <Modal.Title> {HeaderText} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-3">
                            <div style={{ border: "1px solid grey" }}>
                                <img
                                    src={this.state.avatar}
                                    height={150}
                                    width={"100%"}
                                    alt=""
                                ></img>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <Tabs
                                        id="controlled-tab-example-3"
                                        activeKey={this.state.tabKey}
                                        onSelect={(k) =>
                                            this.handleChangeTab(k)
                                        }
                                    >
                                        {TabLanguage}
                                    </Tabs>
                                </div>
                            </div>
                            {/* <div className="form-group">
                                <label> {t("name")} </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    value={this.state.name}
                                    onChange={(event) =>
                                        this.handleChangeName(
                                            event.target.value
                                        )
                                    }
                                ></input>
                            </div> */}
                            <div className="form-group">
                                <label> {t("avatar")} </label>
                                <br></br>
                                <input
                                    type="file"
                                    onChange={(event) =>
                                        this.handleChangeAvatar(event)
                                    }
                                ></input>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>{BtnSubmit}</Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(AuthorAddModal);
