import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import swal from "sweetalert";

// import { Collapse, Nav } from "react-bootstrap";

import ValueContext from "../../components/section/Context";
import TitlePages from "../Elements/TitlePages";
import Card, { CardHeader, CardTitle, CardBody } from "../../components/Card";
import { Content, ContentHeader, ContentBody } from "../../components/Content";
import PageSettings from "../../components/Page/PageSettings";
import PageSectionAdd from "../../components/Modal/PageSectionAdd";
import SectionPair from "../../components/Modal/SectionPair";
import PageSectionDeleteConfirm from "../../components/Modal/PageSectionDeleteConfirm";
import PageApi from "../../api/PageApi";
import SectionApi from "../../api/SectionApi";
import { withTranslation } from "react-i18next";
import config from "../../config/config";
import ReactGA from "react-ga";

import classes from "./NewPageDetail.module.css";

class PageDetail extends Component {
    static contextType = ValueContext;
    constructor(props) {
        super(props);
        this.state = {
            sectionId: 0,

            page: {
                sections: [],
            },
            selectedSections: [],
            deleteDisable: true,
            showPairModal: false,
            showModal: false,
            showModalDelete: false,
            pageHasParams: false,
            isDraft: false,
            idPages: null,
            styles: [],
            columns: {
                "droppable-1": {
                    id: "droppable-1",
                    title: "sections",
                    sectionIds: [],
                },
            },
            columnOrder: ["droppable-1"],
            isCollapse: true,
            use_page_desc: false,
            transAlert: "alert.",
        };

        // this.getPageSections = this.getPageSections.bind(this)
        this.getPageDetail = this.getPageDetail.bind(this);
        this.useQuery = this.useQuery.bind(this);
        this.setSelectedItem = this.setSelectedItem.bind(this);
        this.setShowModal = this.setShowModal.bind(this);
        this.setShowPairModal = this.setShowPairModal.bind(this);
        this.setShowModalDelete = this.setShowModalDelete.bind(this);
        this.deleteSelectedSection = this.deleteSelectedSection.bind(this);
        this.setItemId = this.setItemId.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    useQuery() {
        // return new URLSearchParams(useLocation().search);
    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        const pageId = this.props.match.params.pageId;

        this.setState({
            idPages: pageId,
        });
        this.getPageDetail(pageId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            const pageId = this.props.match.params.pageId;
            this.getPageDetail(pageId);
        }
    }

    setShowModal() {
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    setShowPairModal() {
        this.setState({
            showPairModal: !this.state.showPairModal,
        });
    }

    setShowModalDelete() {
        this.setState({
            showModalDelete: !this.state.showModalDelete,
        });
    }

    getPageDetail(pageId) {
        const sectionObj = new SectionApi();

        sectionObj.getPageSections(pageId).then((res) => {
            const page = res.data;
            const sections = [];
            var widgets = [];
            var sectionIds = [];

            let dataStyle = res.data.styles;
            // console.log(dataStyle);
            res.data.sections.forEach((item, index) => {
                widgets = [];

                item.section_widgets.forEach((itemJ) => {
                    widgets.push({
                        name: itemJ.name,
                        category: itemJ.category,
                        categoryName: itemJ.category_name,
                    });
                });

                sections.push({
                    id: item.id,
                    index: item.index,
                    page: item.page,
                    name: item.name,
                    widgets: widgets,
                });

                sectionIds.push(item);
            });

            page.sections = sections;

            this.setState({
                page: page,
                pageHasParams: res.data.has_params,
                paramsQuantity: res.data.params_qty,
                use_page_desc: res.data.use_page_description,
                pageType: res.data.page_type,
                isDraft: res.data.is_draft,
                styles: dataStyle,
                columns: {
                    ...this.state.columns,
                    ["droppable-1"]: {
                        ...this.state.columns["droppable-1"],
                        sectionIds: sectionIds,
                    },
                },
            });
        });
    }

    setSelectedItem(section, value) {
        var lastSelected = [...this.state.selectedSections];
        var indexDelete = -1;
        // var stateSelected = ""

        if (value === true) {
            lastSelected.push(section);
        } else {
            // console.log("jalan donk harusnya ", section , this.state.selectedSections)
            this.state.selectedSections.forEach((item, index) => {
                if (item.id === section.id) {
                    indexDelete = index;
                }
            });

            lastSelected.splice(indexDelete, 1);
            //console.log("lastArticles => ",lastArticles)
        }

        this.setState(
            {
                selectedSections: lastSelected,
            },
            () => {
                if (this.state.selectedSections.length > 0) {
                    this.setState({
                        deleteDisable: false,
                    });
                } else {
                    this.setState({
                        deleteDisable: true,
                    });
                }
            }
        );
    }

    deleteSelectedSection() {
        const { t } = this.props;
        const objPage = new PageApi();
        const pageId = this.props.match.params.pageId;

        const sections = this.state.selectedSections.map((item) => {
            return item.id;
        });

        objPage
            .pageSectionDelete(sections)
            .then((res) => {
                this.setState({
                    showModalDelete: false,
                    disableDelete: true,
                    selectedSections: [],
                });
                this.getPageDetail(pageId);

                swal(
                    "Success !",
                    t(this.state.transAlert + "delete_widget_in_section"),
                    "success"
                );

                setTimeout(() => {
                    window.location.reload();
                }, 0);
            })
            .catch((err) => {
                swal("Error!", err.message, "error");
            });
    }

    setItemId(value) {
        this.setState(
            {
                sectionId: value,
            },
            () => {
                this.setShowModal();
            }
        );
    }

    onDragEnd(result) {
        //console.log("result ==> ",result)
        const { destination, source } = result;
        // const pageId = this.props.match.params.pageId;

        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const column = this.state.columns[source.droppableId];
        // console.log(" column ==>",column)

        // // process.exit()
        const newSectionIds = Array.from(column.sectionIds);
        //console.log( "newSectionIds 1 ==> ",newSectionIds )
        newSectionIds.splice(source.index, 1);
        newSectionIds.splice(
            destination.index,
            0,
            column.sectionIds[source.index]
        );
        //console.log( "newSectionIds 2 ==> ",newSectionIds )

        const objSection = new SectionApi();

        // const ids = this.state.columns[source.droppableId].sectionIds.map(item => {
        //     return item.id
        // })
        const ids = newSectionIds.map((item) => {
            return item.id;
        });

        objSection
            .sectionIndexUpdate(ids)
            .then((res) => {
                const result = res.data.map((item) => {
                    const findSec = newSectionIds.find(
                        (row) => row.id === item.id
                    );

                    return {
                        ...findSec,
                        index: item.index,
                    };
                });

                // ===== pengubah state ====

                const newColumn = {
                    ...column,
                    sectionIds: result,
                };

                const newColumnState = {
                    ...this.state.columns,
                    [source.droppableId]: newColumn,
                };

                this.setState({
                    columns: newColumnState,
                });

                // ======= pengubah state =============

                // console.log("newColumn => ", newColumn.sectionIds);

                //this.getPageDetail(pageId)
            })
            .catch((err) => {});
    }

    render() {
        const { t } = this.props;

        let value_behavior,
            value_block,
            is_checked_scroll,
            id_opt_behavior,
            id_opt_block;

        this.state.styles.forEach((j, i) => {
            switch (i) {
                case 0:
                    if (j.options) {
                        j.options.forEach((m, i) => {
                            switch (i) {
                                case 0:
                                    id_opt_behavior = m.option;
                                    value_behavior = m.value;
                                    break;
                                case 1:
                                    value_block = m.value;
                                    id_opt_block = m.option;
                                    break;
                                default:
                            }
                        });
                    } else {
                        is_checked_scroll = j.is_checked;
                    }
                    break;
                case 1:
                    if (j.options) {
                        j.options.forEach((m, i) => {
                            switch (i) {
                                case 0:
                                    id_opt_behavior = m.option;
                                    value_behavior = m.value;
                                    break;
                                case 1:
                                    value_block = m.value;
                                    id_opt_block = m.option;
                                    break;
                                default:
                            }
                        });
                    } else {
                        is_checked_scroll = j.is_checked;
                    }
                    break;
                default:
            }
        });
        // let idx = this.state.idPages;

        let hasParams = this.state.pageHasParams;
        let paramQty = this.state.paramsQuantity;

        const pageId = this.props.match.params.pageId;

        const SectionList = this.state.columns["droppable-1"].sectionIds
            // .sort((a, b) => parseFloat(a.index) - parseFloat(b.index))
            .map((item, index) => {
                // console.log(" screenshot => ",item)

                var menuWidgetButton = "";
                if (item.menu !== null) {
                    //const menuWidget = item.menu
                    menuWidgetButton = (
                        <Link
                            to={`/menu-list/${item.menu}`}
                            title="Menu Section"
                        >
                            <Button className="btn-sm" variant="default">
                                <i className="fas fa-bars">&nbsp;</i>
                            </Button>
                        </Link>
                    );
                    //   console.log("menuWidget===>", menuWidget)
                    //item.widgets.push({ menu: menuWidget })
                }

                item.section_widgets.map((itemW, index) => {
                    var icon = "fa fa-puzzle-piece";
                    var title = itemW.category_name;
                    var link = `/widget/detail/${itemW.id}`;

                    // console.log("icon ==> ",itemW)

                    if (itemW.category_name === "Article") {
                        icon = "fa fa-newspaper";
                    } else if (itemW.category_name === "Text") {
                        icon = "fa fa-font";
                    } else if (itemW.category_name === "Media") {
                        icon = "fa fa-photo-video";
                    }
                    // } else if (itemW.menu) {
                    //   icon = "fas fa-bars"
                    //   itemW.name = "Menu: "
                    //   link = `/menu-list/${itemW.menu}`
                    // }

                    return (
                        <Link
                            to={link ? link : "/#"}
                            className="col-md-2"
                            key={index}
                        >
                            <div>
                                <div className="callout callout-info">
                                    <i
                                        title={title}
                                        className={`${" " + icon}`}
                                    >
                                        &nbsp;
                                    </i>{" "}
                                    {itemW.name}
                                    {itemW.menu}
                                </div>
                            </div>
                        </Link>
                    );
                });

                return (
                    <Draggable
                        draggableId={"draggable-" + index}
                        index={index}
                        key={index}
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                // disableinteractiveelementblocking
                            >
                                <div className="col-md-12">
                                    <Card className="card card-outline card-primary">
                                        <CardHeader className="bg bg-default">
                                            <CardTitle>
                                                <input
                                                    id={
                                                        item.name +
                                                        "-" +
                                                        item.id
                                                    }
                                                    className=""
                                                    type="checkbox"
                                                    onClick={(event) =>
                                                        this.setSelectedItem(
                                                            item,
                                                            event.currentTarget
                                                                .checked
                                                        )
                                                    }
                                                ></input>
                                                &nbsp;
                                                <label
                                                    htmlFor={
                                                        item.name +
                                                        "-" +
                                                        item.id
                                                    }
                                                >
                                                    {" "}
                                                    {item.index}. {item.name}
                                                </label>
                                            </CardTitle>
                                            <div className="card-tools">
                                                <Button
                                                    className="btn-sm"
                                                    variant="default"
                                                    onClick={() =>
                                                        this.setItemId(item.id)
                                                    }
                                                >
                                                    <i className="fa fa-edit"></i>
                                                </Button>
                                                <span>&nbsp;</span>
                                                {menuWidgetButton}
                                                <span>&nbsp;</span>
                                                <Link
                                                    to={`/section/detail/${item.id}`}
                                                >
                                                    <Button
                                                        className="btn-sm"
                                                        variant="default"
                                                    >
                                                        <i className="fa fa-arrow-right"></i>
                                                    </Button>
                                                </Link>
                                                <span>&nbsp;</span>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <div
                                                className="row"
                                                style={{
                                                    backgroundColor: "#E9E9E9",
                                                    padding: "-40% -60%",
                                                }}
                                            >
                                                <img
                                                    height="300"
                                                    src={
                                                        item.screenshot
                                                            ? `${item.screenshot}`
                                                            : "/img/phenikaa-default-image.svg"
                                                    }
                                                    alt={item.name}
                                                    style={{
                                                        marginRight: "auto",
                                                        marginLeft: "auto",
                                                        width: "100%",
                                                        height: "100%",
                                                    }}
                                                    className
                                                ></img>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        )}
                    </Draggable>
                );
            });

        const contextValue = this.context;
        let toogleSidebar = contextValue.showSidebar ? (
            <div
                data-widget="pushmenu"
                onClick={() => contextValue.showingSidebar()}
                className={classes.toogleSidebarExt}
            ></div>
        ) : (
            ""
        );

        return (
            <Content>
                <ContentHeader>
                    {/* <h1> Page : {this.state.page.name} </h1> */}
                    <TitlePages
                        title={`${t("pages.new_page_detail.title")} : ${
                            this.state.page.name
                        }`}
                    />
                </ContentHeader>
                {toogleSidebar}

                <ContentBody>
                    <Card>
                        <CardHeader>
                            <CardTitle>
                                {" "}
                                {t("pages.new_page_detail.section_list")} :{" "}
                            </CardTitle>
                            <div className="card-tools">
                                <Button
                                    variant="danger"
                                    onClick={() => this.setShowModalDelete()}
                                    disabled={this.state.deleteDisable}
                                    style={{ marginRight: 5 }}
                                >
                                    <i className="fa fa-trash">&nbsp;</i>
                                    {t("delete_selected")}
                                </Button>
                                <Button
                                    onClick={() =>
                                        this.setState({ sectionId: 0 }, () =>
                                            this.setShowPairModal()
                                        )
                                    }
                                >
                                    <i className="fa fa-plus">&nbsp;</i>
                                    {t("add_section")}
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className="row">
                                <div className="col-md-9">
                                    <DragDropContext
                                        className="row"
                                        // onBeforeCapture={this.onBeforeCapture}
                                        // onBeforeDragStart={this.onBeforeDragStart}
                                        // onDragStart={this.onDragStart}
                                        // onDragUpdate={() => console.log("drag update ")}
                                        onDragEnd={(result) =>
                                            this.onDragEnd(result)
                                        }
                                    >
                                        <Droppable
                                            droppableId="droppable-1"
                                            type="SECTION"
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    style={{
                                                        backgroundColor: snapshot.isDraggingOver
                                                            ? "white"
                                                            : "white",
                                                        height: "100%",
                                                    }}
                                                    {...provided.droppableProps}
                                                >
                                                    {SectionList}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                                <div className="col-md-3">
                                    <PageSettings
                                        id={this.props.match.params.pageId}
                                        hasParams={hasParams}
                                        isPublished={this.state.isDraft}
                                        paramQty={paramQty}
                                        pageType={this.state.pageType}
                                        getPageDetail={this.getPageDetail}
                                        valueBehavior={value_behavior}
                                        valueBlock={value_block}
                                        isChecked={is_checked_scroll}
                                        idBehavior={id_opt_behavior}
                                        idBlock={id_opt_block}
                                        use_page={this.state.use_page_desc}
                                    ></PageSettings>
                                </div>
                            </div>

                            <div className="clearfix"></div>
                        </CardBody>
                    </Card>
                </ContentBody>
                <PageSectionAdd
                    id={this.state.sectionId}
                    pageId={pageId}
                    show={this.state.showModal}
                    onHide={this.setShowModal}
                    getPageDetail={this.getPageDetail}
                ></PageSectionAdd>
                <SectionPair
                    id={this.state.sectionId}
                    pageId={pageId}
                    show={this.state.showPairModal}
                    onHide={this.setShowPairModal}
                    getPageDetail={this.getPageDetail}
                ></SectionPair>
                <PageSectionDeleteConfirm
                    show={this.state.showModalDelete}
                    onHide={this.setShowModalDelete}
                    selectedSections={this.state.selectedSections}
                    deleteSelectedSection={this.deleteSelectedSection}
                ></PageSectionDeleteConfirm>
            </Content>
        );
    }
}

export default withTranslation()(PageDetail);
