import React, { Component, createRef } from 'react'

import swal from "sweetalert"

import TitlePages from './Elements/TitlePages'
import EmailEditor from 'react-email-editor'

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'

import EmailApi from "../api/EmailApi"

import { Content, ContentHeader, ContentBody } from "./../components/Content";
import { withTranslation } from 'react-i18next';
import config from '../config/config'
import ReactGA from 'react-ga';
import Context from './Context'


class EmailTemplate extends Component {
    static contextType = Context
    constructor(props) {
        super(props)
        this.state = {
            templateDesign: {},
            activeTab: "",
            activeTemplate: {},
            templates: [],
            langId: 1,
            languagesList: [],
            transAlert: "alert."
        }

        this.editor = createRef()

        this.changeLanguage = this.changeLanguage.bind(this)
        this.exportHtml = this.exportHtml.bind(this)
        this.onLoad = this.onLoad.bind(this)
        this.updateTemplateEmail = this.updateTemplateEmail.bind(this)
        this.getAllTemplateEmail = this.getAllTemplateEmail.bind(this)
        this.handleTab = this.handleTab.bind(this)
        this.getLanguages = this.getLanguages.bind(this)
    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        this.getAllTemplateEmail()
        this.getLanguages()
    }

    changeLanguage(value) {
        const oldLangId = this.state.langId;

        let newActiveTemplate = { ...this.state.activeTemplate }
        this.editor.current.editor.exportHtml((data) => {
            const { design, html } = data;
            const strDesign = JSON.stringify(design)

            newActiveTemplate.content_languages[oldLangId - 1] = {
                ...newActiveTemplate.content_languages[oldLangId - 1],
                body: html,
                content_design: strDesign
            }
        })

        this.setState({
            langId: value,
            activeTemplate: newActiveTemplate
        }, () => {
            this.handleTab(this.state.activeTab)
        })
    }

    getAllTemplateEmail() {
        const Em = new EmailApi()

        Em.getAllTemplateEmail()
            .then(res => {
                const langId = this.state.langId

                const results = res.data.results
                let templateDesign = results.length ? results[0].content_languages[langId - 1].content_design : ""
                templateDesign = templateDesign ? JSON.parse(templateDesign) : templateDesign

                this.setState({
                    activeTab: 0,
                    activeTemplate: results[0],
                    templateDesign: templateDesign,
                    templates: results
                }, this.onLoad)
            })
    }

    getLanguages() {
        const { languagesList } = this.context

        this.setState({
            languagesList: languagesList,
            langId: languagesList[0].id
        })
    }

    getTemplateEmailById(id) {
        const Em = new EmailApi()
        const langId = this.state.langId

        Em.getTemplateEmailById(id)
            .then(res => {
                const templateDesign = JSON.parse(res.data.content_languages[langId - 1].content_design)

                this.setState({
                    templateDesign: templateDesign
                }, this.onLoad)
            })
    }

    handleTab(e) {
        let langId, activeTemplate;
        const results = this.state.templates
        const { languagesList } = this.state;

        if (this.state.activeTab === e) {
            langId = this.state.langId
            activeTemplate = this.state.activeTemplate
        } else {
            langId = languagesList.length ? languagesList[0].id : "1"
            activeTemplate = results[e]
        }

        let templateDesign = results.length ? results[e].content_languages[langId - 1].content_design : ""
        templateDesign = templateDesign ? JSON.parse(templateDesign) : templateDesign

        this.setState({
            langId: langId,
            activeTab: e,
            activeTemplate: activeTemplate,
            templateDesign: templateDesign
        }, this.onLoad)
    }

    updateTemplateEmail(param) {
        const { t } = this.props;
        const Em = new EmailApi()

        this.editor.current.editor.exportHtml((data) => {
            const { design, html } = data;
            const strDesign = JSON.stringify(design)

            let emailContents = { ...this.state.activeTemplate }
            emailContents.content_languages[this.state.langId - 1] = {
                ...emailContents.content_languages[this.state.langId - 1],
                body: html,
                content_design: strDesign
            }

            Em.updateTemplateEmail(param, emailContents)
                .then((res) => {
                    if (res) {
                        swal("Success !", t(this.state.transAlert + "email_design_template_update_success"), "success")
                    }
                })
                .catch((err) => {
                    swal("Error!", err.message, "error")
                });
        })

    }

    exportHtml(e) {
        this.editor.current.editor.exportHtml((data) => {
            const { design, html } = data;

        });
    }

    onLoad() {
        const templateJson = this.state.templateDesign;
        if (Object.keys(templateJson).length) {
            if (this.editor.current.editor) {
                this.editor.current.editor.loadDesign(templateJson);
            } else {
                setTimeout(() => {
                    this.onLoad();
                }, 1000)
            }
        }
    }

    render() {
        const { t } = this.props;

        return (
            <Content>
                <ContentHeader>
                    <TitlePages title={t('pages.email_template.title')} />
                </ContentHeader>
                <ContentBody>

                    <ToggleButtonGroup
                        className="mb-2"
                        type="radio"
                        name="languageTemplate"
                        value={this.state.activeTab}
                        onChange={this.handleTab}
                    >
                        {
                            this.state.templates.map((template, i) => {
                                return <ToggleButton key={template.id} value={i}>{template.name}</ToggleButton>
                            })
                        }
                    </ToggleButtonGroup>

                    <Tabs
                        onSelect={this.changeLanguage}
                        activeKey={this.state.langId}
                        id="uncontrolled-tab-example"
                    >
                        {
                            this.state.languagesList.map(lang => {
                                return <Tab key={lang.id} eventKey={lang.id} title={lang.name} />
                            })
                        }
                    </Tabs>

                    <EmailEditor
                        ref={this.editor}
                        style={{ height: "75vh" }}
                    />

                    <div className="pb-3 d-flex justify-content-end">
                        <Button
                            onClick={() => { this.updateTemplateEmail(this.state.activeTemplate?.id) }}
                            className="mt-3"
                            variant="primary"
                        >
                            {t('pages.email_template.save_design')}
                        </Button>
                    </div>

                </ContentBody>
            </Content>
        )
    }
}

export default withTranslation()(EmailTemplate);