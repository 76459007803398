import React, { Component } from 'react';

class ContentHeader extends Component {
    
    render() {
        return (
            <section className="content-header contentHeader">
                {this.props.children}
            </section>
        );
    }
}

export default ContentHeader;