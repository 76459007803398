import React, { Component } from 'react';

import ValueContext from '../components/section/Context'
// import SideBar from "./../components/section/Sidebar"
import TitlePages from './Elements/TitlePages'

import { Content, ContentHeader, ContentBody } from "./../components/Content"
import { Card, CardBody } from "../components/Card";
// import Dashboard from "../components/section/Dashboard"

import { withTranslation } from 'react-i18next';
import config from '../config/config'
import ReactGA from 'react-ga';

import classes from './Pages.module.css'

class AboutPage extends Component {
    static contextType = ValueContext

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        const contextValue = this.context
        const { t } = this.props
        return (
            <Content>
                <ContentHeader>
                    {/* <h1> About Us </h1> */}
                    <TitlePages title={t('pages.about_page.title')} />
                </ContentHeader>
                <ContentBody>
                    <Card>
                        <CardBody>
                            <p>{t('pages.about_page.content')}</p>
                        </CardBody>
                    </Card>
                </ContentBody>
            </Content>
        );
    }
}

export default withTranslation()(AboutPage);