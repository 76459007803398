import React, { Component } from "react";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import JobApi from "./../../api/JobApi";
import EducationListModal from "./EducationListModal";
import ExperienceListModal from "./ExperienceListModal";
import PortfolioListModal from "./PortfolioListModal";
import SalaryOfferListModal from "./SalaryOfferListModal";
import LogsListModal from "./LogsListModal";
import { withTranslation } from "react-i18next";

class ApplicationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applicationId: this.props.applicationId,
      application: {},
      selectedPriority: 0,
      selectedConfirmation: 0,
      priorityList: [],
      confirmationList: [],
      showExperience: false,
      showEducation: false,
      showPortfolio: false,
      showSalaryOffer: false,
      showLogs: false,
      transPage: "modals.application.",
      transAlert: "alert.",
      app_logs: [],
      id: 0,
    };

    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.getDetailApplication = this.getDetailApplication.bind(this);
    this.updateApplication = this.updateApplication.bind(this);
    this.handleChangeConfirm = this.handleChangeConfirm.bind(this);
    this.handleChangePriority = this.handleChangePriority.bind(this);
    this.getPriorityList = this.getPriorityList.bind(this);
    this.getConfirmationList = this.getConfirmationList.bind(this);
    this.setShowEducation = this.setShowEducation.bind(this);
    this.setShowExperience = this.setShowExperience.bind(this);
    this.setShowPortfolio = this.setShowPortfolio.bind(this);
    this.setShowSalaryOffer = this.setShowSalaryOffer.bind(this);
    this.setShowLogs = this.setShowLogs.bind(this);
  }

  onOpenModal() {
    this.getPriorityList();
    this.getConfirmationList();

    this.setState(
      {
        applicationId: this.props.applicationId,
      },
      () => this.getDetailApplication()
    );
  }

  onCloseModal() {
    this.setState(
      {
        applicationId: 0,
        application: {},
      },
      () => {
        this.props.callback();
        this.props.onHide();
      }
    );
  }

  setShowEducation() {
    this.setState({
      showEducation: !this.state.showEducation,
    });
  }

  setShowLogs() {
    this.setState({
      showLogs: !this.state.showLogs,
    });
  }

  setShowExperience() {
    this.setState({
      showExperience: !this.state.showExperience,
    });
  }

  setShowPortfolio() {
    this.setState({
      showPortfolio: !this.state.showPortfolio,
    });
  }

  setShowSalaryOffer() {
    this.setState({
      showSalaryOffer: !this.state.showSalaryOffer,
    });
  }

  handleChangePriority(value) {
    this.setState({
      selectedPriority: value,
    });
  }

  handleChangeConfirm(value) {
    this.setState({
      selectedConfirmation: value,
    });
  }

  getDetailApplication() {
    const obJob = new JobApi();

    obJob
      .getApplicationDetail(this.state.applicationId)
      .then((res) => {
        this.setState({
          selectedConfirmation: res.data.confirmation,
          selectedPriority: res.data.priority,
          application: res.data,
          id: res.data.id,
          app_logs: res.data.application_logs,
        });
      })
      .catch((err) => {
        // alert(err.message)
      });
  }

  getEducationList() {}

  getPriorityList() {
    const obJob = new JobApi();
    const params = {};

    obJob
      .getPriorityList(params)
      .then((res) => {
        this.setState({
          priorityList: res.data.results,
        });
      })
      .catch((err) => {
        console.log(" err => ", err.message);
      });
  }

  getConfirmationList() {
    const obJob = new JobApi();
    const params = {};

    obJob
      .getConfirmationList(params)
      .then((res) => {
        this.setState({
          confirmationList: res.data.results,
        });
      })
      .catch((err) => {
        console.log(" err => ", err.message);
      });
  }

  updateApplication() {
    const { t } = this.props;
    const obJob = new JobApi();

    const request = {
      priority: this.state.selectedPriority,
      confirmation: this.state.selectedConfirmation,
    };

    obJob
      .updateApplication(this.state.applicationId, request)
      .then((res) => {
        swal(
          "Success!",
          t(this.state.transAlert + "application_update_success"),
          "success"
        );
        this.onCloseModal();
      })
      .catch((err) => {
        swal("Error!", err.message, "error");
      });
  }

  render() {
    const { t } = this.props;

    const application = this.state.application;
    const applicant = application?.applicant;
    const app_logs = this.state.app_logs;

    var image = this.state.application?.applicant?.avatar
      ? this.state.application?.applicant?.avatar
      : `${process.env.PUBLIC_URL}/img/phenikaa-default-image.svg`;

    var educationDetail = "";
    var experienceDetail = "";
    var portfolioDetail = "";
    var coverLetterDetail = "";

    if (this.state.application?.applicant?.educations.length > 1) {
      educationDetail = (
        <button
          className="badge badge-info"
          onClick={() => {
            this.setShowEducation();
          }}
        >
          {" "}
          More info{" "}
        </button>
      );
    }

    if (this.state.application?.applicant?.experiences.length > 1) {
      experienceDetail = (
        <button
          className="badge badge-info"
          onClick={() => {
            this.setShowExperience();
          }}
        >
          {" "}
          More info{" "}
        </button>
      );
    }

    if (
      this.state.application?.attachments?.filter(
        (element) => element.attachment_type === "Portfolio"
      ).length > 0
    ) {
      // portfolioDetail = JSON.stringify(this.state.application?.applicant)
      portfolioDetail = (
        <button
          className="badge badge-info"
          onClick={() => {
            this.setShowPortfolio();
          }}
        >
          {" "}
          More info{" "}
        </button>
      );
    }

    if (
      this.state.application?.attachments?.filter(
        (element) => element.attachment_type === "Cover Letter"
      ).length > 0
    ) {
      //coverLetterDetail = JSON.stringify(this.state.application?.attachments)
      coverLetterDetail = (
        <a
          href={
            this.state.application?.attachments?.find(
              (element) => element.attachment_type === "Cover Letter"
            ).attachment_file
          }
        >
          {" "}
          Download{" "}
        </a>
      );
    }

    return (
      <Modal
        size="xl"
        show={this.props.show}
        onHide={this.props.onHide}
        onShow={this.onOpenModal}
      >
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title>
            {" "}
            {t(this.state.transPage + "application_detail")}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-3">
              <img
                style={{ margin: "0 12.5%" }}
                src={image}
                width={"75%"}
                height={150}
                alt=""
              ></img>
            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label> {t("name")} </label>
                    <div>
                      {" "}
                      {applicant?.user?.first_name} {applicant?.user?.last_name}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label> {t("gender")} </label>
                    <div> {applicant?.gender}</div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label> {t("birth_date")} </label>
                    <div> {applicant?.birth_date}</div>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      {" "}
                      {t(this.state.transPage + "last_working_experience")}{" "}
                    </label>
                    <div>
                      {[
                        this.state.application?.applicant?.experiences?.[
                          this.state.application?.applicant?.experiences
                            .length - 1
                        ],
                      ].map((item) => {
                        if (item) {
                          return (
                            <div>
                              in <b>{item?.company}</b> as <b>{item?.title}</b>{" "}
                              from {item?.date_start} to {item?.date_finish}{" "}
                            </div>
                          );
                        }
                        return <div></div>;
                      })}

                      {experienceDetail}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      {" "}
                      {t(this.state.transPage + "last_education")}{" "}
                    </label>
                    <div>
                      {[
                        this.state.application?.applicant?.educations?.[
                          this.state.application?.applicant?.educations.length -
                            1
                        ],
                      ].map((item) => {
                        if (item) {
                          return (
                            <div>
                              in <b>{item?.school}</b> got <b>{item?.level}</b>{" "}
                              from {item?.date_start} to {item?.date_finish}{" "}
                            </div>
                          );
                        }
                        return <div></div>;
                      })}
                      {educationDetail}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label> {t("resume")} </label>
                    <div>
                      {this.state.application?.attachments?.forEach((item) => {
                        //return JSON.stringify(item)
                        if (item?.attachment_type === "Resume") {
                          return <a href={item?.attachment_file}> Download </a>;
                        }
                      })}
                      {/* { applicant?.resume ? <a href={applicant?.resume}> Download </a> : "-" }  */}
                    </div>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label> {t("phone_number")} </label>
                    <div> {applicant?.phone}</div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label> {t("email")} </label>
                    <div> {applicant?.user?.email}</div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label> {t(this.state.transPage + "apply_date")} </label>
                    <div> {application?.apply_date}</div>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label> {t("cover_letter")} </label>
                    <div>{coverLetterDetail}</div>
                    {/* <div dangerouslySetInnerHTML={{__html:this.state.application?.cover_letter}}></div> */}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label> {t("portfolio")} </label>
                    <div>{portfolioDetail}</div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      {" "}
                      {t(this.state.transPage + "salary_offering")}{" "}
                    </label>
                    <div>
                      <button
                        className="badge badge-info"
                        href="#"
                        onClick={() => {
                          this.setShowSalaryOffer();
                        }}
                      >
                        {" "}
                        View{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label> {t("priority")} </label>
                    <select
                      className="form-control"
                      value={this.state.selectedPriority}
                      onChange={(event) => {
                        this.handleChangePriority(event.target.value);
                      }}
                    >
                      <option value="">
                        {" "}
                        -- {t(this.state.transPage + "select_priority")} --
                      </option>
                      {this.state.priorityList.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {" "}
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label> {t("confirmation")} </label>
                    <select
                      className="form-control"
                      value={this.state.selectedConfirmation}
                      onChange={(event) =>
                        this.handleChangeConfirm(event.target.value)
                      }
                    >
                      <option>
                        {" "}
                        -- {t(
                          this.state.transPage + "select_confirmation"
                        )} --{" "}
                      </option>
                      {this.state.confirmationList.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {" "}
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label> Email Status </label>
                    <div>
                      <button
                        className="badge badge-info"
                        onClick={this.setShowLogs}
                      >
                        {t("logs")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <EducationListModal
            show={this.state.showEducation}
            onHide={this.setShowEducation}
            callback={() => {
              this.getDetailApplication();
            }}
            educations={this.state.application?.applicant?.educations || []}
          ></EducationListModal>

          <LogsListModal
            show={this.state.showLogs}
            onHide={this.setShowLogs}
            get={() => this.getDetailApplication()}
            app_logs={app_logs}
            id={this.state.id}
          ></LogsListModal>
          <ExperienceListModal
            show={this.state.showExperience}
            onHide={this.setShowExperience}
            callback={() => {
              this.getDetailApplication();
            }}
            experiences={this.state.application?.applicant?.experiences || []}
          ></ExperienceListModal>
          <PortfolioListModal
            show={this.state.showPortfolio}
            onHide={this.setShowPortfolio}
            callback={() => {
              this.getDetailApplication();
            }}
            portfolios={
              this.state.application?.attachments?.filter(
                (item) => item.attachment_type === "Portfolio"
              ) || []
            }
          ></PortfolioListModal>
          <SalaryOfferListModal
            applicationId={this.state.applicationId}
            show={this.state.showSalaryOffer}
            onHide={this.setShowSalaryOffer}
            callback={() => {
              this.getDetailApplication();
            }}
            offers={this.state.application?.offers}
          ></SalaryOfferListModal>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={() => {
              this.updateApplication();
            }}
          >
            <i className="fa fa-edit"></i> Update
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(ApplicationModal);
