import * as THREE from "three";
import React, { useState, useEffect, useRef, Suspense } from "react";
import { useThree, useLoader, Canvas } from "react-three-fiber";
import { OrbitControls } from "@react-three/drei";
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';

const MainPano = (src) => {
    // const mainTexture = useLoader(THREE.TextureLoader, "./assets/test_pano.jpg");
    // const texture = useLoader(TextureLoader, './assets/8hw0clw-360-panorama-miami.jpeg');
    // const texture = useLoader(TextureLoader, 'https://image.shutterstock.com/image-photo/full-seamless-spherical-hdri-panorama-600w-1465422062.jpg');
    // const texture = useLoader(TextureLoader, src.src);

    // const texture = new THREE.TextureLoader().load(src.src);
    var loader = new THREE.TextureLoader();
    loader.crossOrigin = 'true';

    var texture = loader.load(src.src);

    return (
        <mesh scale={[-1, 1, 1]}>
            <sphereBufferGeometry args={[500, 60, 40]} />
            <meshBasicMaterial map={texture} side={THREE.BackSide} crossOrigin="true" />
        </mesh>
    );
};

export default function Panorama(props) {
    return (
        <div id="sphereicalImg" style={{width: '100%', height: '100%'}}>
            <Canvas camera={{ fov: 55, position: [0, 0, 0.1] }} style={{width: '100%', height: props.pStyle}}>
                <OrbitControls
                    enableZoom={false}
                    enablePan={false}
                    enableDamping
                    dampingFactor={0.1}
                    autoRotate={false}
                    rotateSpeed={-0.15}
                />
                <Suspense fallback={<span>Loading image...</span>}>
                    <MainPano src={props.src} />
                </Suspense>
            </Canvas>
        </div>
    );
};
