import React, { Component } from 'react';
import { Modal , InputGroup , Dropdown} from "react-bootstrap"
import NumberFormat from "react-number-format"
import swal from 'sweetalert';
import JobApi from '../../api/JobApi';

import GeneralApi from "./../../api/GeneralApi"
import UserApi from './../../api/UserApi';
import userApi from "./../../api/UserApi"
import { withTranslation } from 'react-i18next';

class SalaryOfferListModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            offers:[],

            applicationId:0,
            salaryOffer:0,
            selectedCurrency:1,
            selectedCurrencyName:"đồng",
            currencies:[],
            transPage: "modals.salary_offer.",
            transAlert: "alert."
        }

        this.getOffers   = this.getOffers.bind(this)
        this.onOpenModal    = this.onOpenModal.bind(this)
        this.getCurrencies  = this.getCurrencies.bind(this)
        this.addSalaryOffer = this.addSalaryOffer.bind(this)

        this.handleChangeSalaryOffer = this.handleChangeSalaryOffer.bind(this)
        this.handleChangeCurrency    = this.handleChangeCurrency.bind(this)
    }

    onOpenModal() {
        this.getOffers()
        this.getCurrencies()

        this.setState({
            applicationId:this.props.applicationId
        })
    }

    getOffers() {
        this.setState({
            offers:this.props.offers
        })
    }

    getCurrencies() {

        const obj = new GeneralApi()

        obj.getCurrencies()
        .then(res => {
            this.setState({
                currencies:res.data.results
            })
        })
    }

    handleChangeSalaryOffer(value) {
        this.setState({
            salaryOffer:value
        })
    }

    handleChangeCurrency(value) {
        this.setState({
            selectedCurrency:value.id,
            selectedCurrencyName:value.name
        })
    }

    addSalaryOffer() {
        const {t} = this.props;
        const obj     = new JobApi()
        const objUser = new UserApi()

        if(this.state.salaryOffer < 1) {
            
            swal("Error",t(this.state.transAlert + "salary_offer_input_error"),"error")
            return false
        } 

        const request = { 
            "application":this.state.applicationId,
            "currency":this.state.selectedCurrency,
            "employee":0,
            "salary_offer":isNaN(this.state.salaryOffer) ? parseFloat(this.state.salaryOffer.replace(/,/g, "")) : this.state.salaryOffer,
            "offered_by":"Phenikaa"
        }

        objUser.getCurrentUser()
        .then(res => {

            request.employee = res.data.id

            return obj.addSalaryOffer(request)
        })
        .then(res => {

            swal("Success",t(this.state.transAlert + "salary_offer_add_success"),"success")
            this.props.callback()

        })
        .catch(err => {

            swal("Error",err.message,"error")

            
        })

        
    }

    approveOfferSalary(salaryOfferId) {
        const {t} = this.props;
        const obj     = new JobApi()
        const objUser = new UserApi()


        var request = {
            "employee":0,
            "final_offer":true
        }

        objUser.getCurrentUser()
        .then(res => {

            request.employee = res.data.id

            return obj.approveSalaryOffer(request, salaryOfferId)
        })
        .then(res => {
            swal("Success",t(this.state.transAlert + "salary_offer_approved_success"),"success")
            this.props.callback()

        })
        .catch(err => {

            swal("Error",err.message,"error")

            
        })
    }

    render() {
        const {t} = this.props;
        var disableAddOffer = false
        var lastOffer = this.state.offers?.[this.state.offers.length - 1]

        if( lastOffer?.offered_by === "Phenikaa" ) {
            disableAddOffer = true
        }

        const Offer = this.state.offers.map((item,index) => {

            index = index + 1

            var approvedButton = ""

            if(item.offered_by === "Applicant" && item.offered_by === "Applicant" && this.state.offers.length === index && item.final_offer === false) {
                approvedButton = <button onClick={() => { this.approveOfferSalary(item.id)  }} className="btn btn-success" style={{alignContent:"right"}}> {t('approve')} </button>
            }

            var labelFinalOffer = <label className="badge badge-warning"> {t('open')} </label>
            if( index !== this.state.offers.length && item.final_offer === false ) {
                labelFinalOffer = <label className="badge badge-secondary"> {t('closed')} </label>
            }
            if( index === this.state.offers.length && item.final_offer === true) {
                labelFinalOffer = <label className="badge badge-success"> {t('approved')} </label>
            }

            return (<tr>
                <td>{index}</td>
                <td>{item.salary_offer} {item.currency_name}</td>
                <td>{item.offered_by}</td>
                <td>{item.employee_name}</td>
                <td>{labelFinalOffer}</td>
                <td>
                    { approvedButton}
                </td>
            </tr>)
        })

        return (
            <Modal size="lg" show={this.props.show} onHide={this.props.onHide} onShow={this.onOpenModal}>
                <Modal.Header closeButton className="bg-primary">
                   {t('salary_offer')}
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <div className="float-left">
                                    <label> {t('salary_offer')} </label>
                                    <InputGroup.Prepend>
                                                            
                                        <NumberFormat thousandSeparator={true} value={this.state.salaryOffer} onChange={(event) => {this.handleChangeSalaryOffer(event.target.value)}} 
                                                className="form-control" aria-label="" aria-describedby="basic-addon1" />
                                        <InputGroup.Text id="basic-addon1">
                                            <Dropdown>
                                                <Dropdown.Toggle as="a"  id="dropdown-custom-components">
                                                    { this.state.selectedCurrencyName}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu >
                                                    {
                                                        this.state.currencies.map(item => {
                                                            return( <Dropdown.Item key={item.id} onClick={() => { this.handleChangeCurrency({
                                                                id:item.id,
                                                                name:item.name
                                                            })}}>{ item.name }</Dropdown.Item> )
                                                        })
                                                    }
                                                    
                                
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </InputGroup.Text>
                                        <InputGroup.Text id="basic-addon2">
                                            <button disabled={disableAddOffer} className="btn btn-success btn-xs float-left" onClick={() => this.addSalaryOffer()}> 
                                                <i className="fa fa-plus">&nbsp;</i> Add
                                            </button>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                </div>

                                <span className="clearfix"></span>
                            </div>
                            
                        </div>
                        <div className="col-md-6">
                            
                        </div>
                        
                    </div>
                    <div className="row">
                       <table className="table table-bordered">
                           <thead>
                               <th> {t('no')} </th>
                               <th> {t('salary_offer')} </th>
                               <th> {t('offered_by')} </th>
                               <th> {t('employee')} </th>
                               <th> {t('status')} </th>
                               <th> {t('action')} </th>
                           </thead>
                           <tbody>
                               { Offer }
                           </tbody>
                       </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { this.props.onHide() }}> {t('ok')} </button>
                   
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(SalaryOfferListModal);