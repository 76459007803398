import React, { Component } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { withTranslation } from "react-i18next";
import Card from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content";
import TitlePages from "./Elements/TitlePages";
import LocationApi from "../api/LocationApi";
// import ModalLocation from "../components/Modal/LocationModal";
import LocationDelete from "../components/Modal/LocationDelete";
import "./LocationPage.css";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";

class LocationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transPage: "pages.location.",
      transAlert: "alert.",
      allLocation: [],
      isEnglish: false,
      langLoc: [],
      mapsLib: ["drawing", "places"],
      gMapApiKey: "AIzaSyCigRL-AQS62AdkaUj42rp9OX_5Mbze8PM",
      modalAddOpen: false,
      modalDeleteOpen: false,
      ID: 0,
      company_name: "",
      search: "",
      totalCount: null,
      activePage: 1,
      limitPageData: 10,
    };
    this.getAllLocation = this.getAllLocation.bind(this);
    this.deleteLocation = this.deleteLocation.bind(this);
    // this.getLocationByFilter = this.getLocationByFilter.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.autocomplete = null;
  }

  componentDidMount() {
    this.getAllLocation();
  }

  onLoad(autocomplete) {
    this.autocomplete = autocomplete;
  }

  handlePageChange(pageNumber) {
    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.getAllLocation();
      }
    );
  }

  componentDidUpdate() {
    const lang = localStorage.getItem("lang");
    var isEnglish = false;

    if (lang === "en") {
      isEnglish = true;
    }
    if (isEnglish !== this.state.isEnglish) {
      this.setState({
        isEnglish: isEnglish,
      });
      this.getAllLocation();
    }
  }

  getAllLocation() {
    const api = new LocationApi();
    api
      .getAllLocations({
        page_size: this.state.limitPageData,
        page: this.state.activePage,
        search: this.state.search,
      })
      .then((res) => {
        this.setState({
          totalCount: res.data.count,
          allLocation: res.data.results,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //   async getLocationByFilter() {
  //     const objLocation = new LocationApi();
  //     const res = await objLocation
  //           .getAllLocations({
  //               page_size: this.state.limitPageData,
  //               page: this.state.activePage,
  //               search: this.state.search,
  //           });
  //       this.setState({
  //           totalCount: res.data.count,
  //           allLocation: res.data.results,
  //       });
  //   }

  deleteLocation() {
    const { t } = this.props;
    const api = new LocationApi();
    api
      .deleteLocation(this.state.ID)
      .then((res) => {
        console.log("res delete =>", res.data);
        swal("Success", t("alert.location_delete_success"), "success");
        this.getAllLocation();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSearch(val) {
    this.setState({ search: val }, () => {
      this.getAllLocation();
    });
  }

  render() {
    const { t } = this.props;
    const containerStyle = {
      width: "100%",
      height: "100%",
    };
    console.log(this.state.allLocation);
    let cards = this.state.allLocation.map((el) => {
      let comp_name;
      let locs, address;
      let data;

      const center = {
        lat: parseFloat(el.latitude),
        lng: parseFloat(el.longitude),
      };

      if (el.company_name !== null) {
        comp_name = el.company_name;
      } else {
        comp_name = "-";
      }

      if (this.state.isEnglish) {
        data = el.location_languages[0];
        if (data) {
          locs = data.location_type !== null ? data.location_type : "-";
          address = data.address !== null ? data.address : "-";
          //   web = data.website;
        } else {
          locs = "-";
          address = "-";
        }
      } else {
        data = el.location_languages[1];
        if (data) {
          locs = data.location_type !== null ? data.location_type : "-";
          address = data.address !== null ? data.address : "-";
          //   web = data.website;
        } else {
          locs = "-";
          address = "-";
        }
      }

      return (
        <div className="col-md-6" key={el.id}>
          <Card>
            <div className="form-group" style={{ margin: "0" }}>
              <div className="d-flex flex-row">
                <div className="col-md-4" style={{ padding: "unset" }}>
                  <LoadScript
                    googleMapsApiKey={this.state.gMapApiKey}
                    libraries={this.state.mapsLib}
                  >
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={15}
                      mapTypeId="roadmap"
                      onLoad={this.onLoad}
                    >
                      <Marker position={center} />
                    </GoogleMap>
                  </LoadScript>
                </div>
                <div
                  className="col-md-8"
                  style={{ margin: "1rem 0", padding: "0px 1rem 0px 2rem" }}
                >
                  <div className="b">
                    {" "}
                    <p className="c">{comp_name}</p>
                    <Link to={`location/detail/${el.id}`}>
                      {" "}
                      <i
                        class="fa fa-ellipsis-v"
                        aria-hidden="true"
                        style={{
                          width: "5%",
                          justifyContent: "flex-end",
                          display: "flex",
                          cursor: "pointer",
                          color: "#00599d",
                        }}
                      ></i>
                    </Link>
                    <i
                      onClick={() => {
                        this.setState({
                          modalDeleteOpen: !this.state.modalDeleteOpen,
                          ID: el.id,
                          company_name: comp_name,
                        });
                      }}
                      class="fa fa-trash"
                      aria-hidden="true"
                      style={{
                        width: "5%",
                        justifyContent: "flex-end",
                        display: "flex",
                        cursor: "pointer",
                        color: "#00599d",
                      }}
                    ></i>
                  </div>

                  <p className="link">{locs}</p>
                  <p className="add">{address}</p>

                  <div className="column">
                    <div className="row-web">
                      <p className="p">Phone : {el.phone}</p>
                    </div>
                    <div className="row-web">
                      <p className="link">
                        <p className="p2">Email : &nbsp;</p>
                        {el.email}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    });

    return (
      <Content>
        <LocationDelete
          show={this.state.modalDeleteOpen}
          onHide={() => {
            this.setState({ modalDeleteOpen: false });
          }}
          locationID={this.state.ID}
          positionId={this.state.ID}
          names={this.state.company_name}
          delete={this.deleteLocation}
        />
        <ContentHeader>
          <TitlePages title={t(this.state.transPage + "title")} />
        </ContentHeader>

        <ContentBody>
          <Card>
            <div className="loc-header">
              <div className="row">
                <label>{t(this.state.transPage + "location_list")}</label>
                <div className="d-flex flex-row btn-loc-add ml-auto">
                  <Link to={"location/add"}>
                    {" "}
                    <div style={{ cursor: "pointer" }}>
                      {t(this.state.transPage + "add_location")}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </Card>
          <div
            className="d-flex flex-row"
            width="100%"
            style={{ padding: "0 2rem" }}
          >
            {/* <div
              className="form-group"
              style={{ width: "30%", marginRight: "1rem" }}
            >
              <label>{t("filter")}</label>
              <select
                className="form-control"
                defaultValue={this.state.inputType}
                onChange={(event) => {
                  this.handleChangeFileType(event.target.value);
                }}
              >
                <option value="Check">
                  {" "}
                  -- {t(this.state.transPage + "select_location")} --{" "}
                </option>
              </select>
            </div> */}
            <div className="form-group" style={{ width: "30%" }}>
              <label>{t("search")}</label>
              <input
                type="text"
                placeholder={t(this.state.transPage + "search")}
                className="form-control"
                name="search"
                value={this.state.search}
                onChange={(event) => this.handleSearch(event.target.value)}
              />
            </div>
          </div>
          <div style={{ padding: "0 2rem" }}>
            {" "}
            <hr />
          </div>
          <div className="clearfix" style={{ marginBottom: "3rem" }}></div>
          <div className="row" style={{ padding: "0 2rem" }}>
            {this.state.totalCount !== 0 ? (
              cards
            ) : (
              <div className="col-md-12 center-block">
                <h4 className="text-center">There is no Location</h4>
              </div>
            )}
          </div>
        </ContentBody>
        <div className="d-flex flex-row">
          <div
            style={{ float: "left", padding: "0 2rem", margin: "2rem 0" }}
            id="pagination_custom"
          >
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.limitPageData}
              totalItemsCount={this.state.totalCount}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange.bind(this)}
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled={true}
              prevPageText={t("prev")}
              nextPageText={t("next")}
            />
          </div>
        </div>
      </Content>
    );
  }
}

export default withTranslation()(LocationPage);
