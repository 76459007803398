import React, { Component } from 'react';
import { Button, Tabs, Tab, TabContent } from "react-bootstrap"
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import swal from "sweetalert"
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Panorama from '../components/Panorama/Panorama'


import Card, { CardHeader, CardBody, CardFooter } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content"
import TitlePages from './Elements/TitlePages'
import WidgetAssetAddThumbnailModal from "./../components/Modal/WidgetAssetAddThumbnailModal"

import AssetApi from "./../api/AssetApi"
import GeneralApi from "./../api/GeneralApi"
import imgDefault from './Assets/default-image.svg'

import defaultImg from './Assets/default-image.svg'
import "./AssetDetailPage.css";
import { withTranslation } from 'react-i18next';
import config from '../config/config'
import ReactGA from 'react-ga';
import Context from './Context';
import PdfPreviewModal from '../components/Modal/PdfPreviewModal';

class AssetDetailPage extends Component {
    static contextType = Context;
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            description: "",
            media: "",
            asset_link: "",
            asset_type: "",
            inputType: "FILE",
            file_type: "",
            hasLanguage: false,
            hasLanguageStatic: false,
            hasSlogan: false,
            hasDarkLogo: false,
            darkLight: false,

            file: [],
            asset_types: [],
            assets_file: [],
            assets_link: [],
            descriptions: [],
            languageList: [],
            asset_used_in_article: false,
            asset_used_in_widget: false,
            tabKey: 1,
            selectedLanguage: 1,
            renderTabLanguage: "",
            idElement: 0,

            fileNewThumbnail: false,
            setShowAssetThumbnailModal: false,
            thumbnailUrl: false,
            thumbnailUrl1: false,
            thumbnailUrl2: false,
            thumbnailID: false,
            transPage: "pages.asset_detail.",
            transAlert: "alert.",
            activeLang: 'en',

            src: null,
            srcEn: null,
            srcVn: null,
            crop: {
                unit: '%',
                x: (100 - 40) / 2,
                y: 0,
                width: 40,
                height: 100,
                aspect: 9 / 16,
            },
            cropLoaded: false,
            cropsLoaded: [false, false],
            assets_crop: [],
            mobile_variable: [],
            default_variable: {
                mobile_x: 0,
                mobile_y: 0,
                mobile_width: 0,
                mobile_height: 0,
                mobile_unit: 0,
                mobile_aspect: 0,
            },

            isShowPdfPreview: false,
            pdfFile: ""
        }

        this.getAssetTypeLanguage = this.getAssetTypeLanguage.bind(this)

        this.handleChangeName = this.handleChangeName.bind(this)
        this.handleChangeDescription = this.handleChangeDescription.bind(this)
        this.handleChangeTab = this.handleChangeTab.bind(this)
        this.handleChangeLink = this.handleChangeLink.bind(this)
        this.setShowAssetThumbnailModal = this.setShowAssetThumbnailModal.bind(this)
        this.handleSelectedAssetThumbnail = this.handleSelectedAssetThumbnail.bind(this)

        this.handleChangeFile = this.handleChangeFile.bind(this)
        this.handleChangeTab = this.handleChangeTab.bind(this)
        this.handleChangeLink = this.handleChangeLink.bind(this)
        this.handleChangeFileType = this.handleChangeFileType.bind(this)
        this.handleHasLanguage = this.handleHasLanguage.bind(this)
        this.handleDarkLogo = this.handleDarkLogo.bind(this)
        this.handleDarkLight = this.handleDarkLight.bind(this)
        this.handlePreviewPdf = this.handlePreviewPdf.bind(this);

        this.getDetailAsset = this.getDetailAsset.bind(this)
        this.updateAsset = this.updateAsset.bind(this)
        this.deleteAsset = this.deleteAsset(this)
        // this.handleChnageFile = this.handleChangeFile(this)

        this.renderTabLanguage = this.renderTabLanguage.bind(this)

        this.hiddenFileInput = React.createRef(null);

        this.onCropChange = this.onCropChange.bind(this)
        this.makeClientCrop = this.makeClientCrop.bind(this)
    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        const { languagesList } = this.context;

        this.getAssetTypeLanguage()
        this.setState({
            languageList: languagesList,
            activeLang: localStorage.getItem("lang")
        }, () => {
            this.getDetailAsset()
        })
    }

    setShowAssetThumbnailModal(value) {
        this.setState({
            thumbnailType: value,
            setShowAssetThumbnailModal: !this.state.setShowAssetThumbnailModal
        })
    }

    handleChangeName(value) {
        this.setState({
            name: value
        })
    }

    handleChangeDescription(id, value) {

        let descriptions = [...this.state.descriptions]
        let description = { ...descriptions[id] }
        description.description = value
        descriptions[id] = description

        this.setState({
            description: descriptions[0].description,
            descriptions: descriptions
        })

    }

    handleChangeLink(value) {
        //console.log("Sub: " + value.substring(8,23))
        var newValue = "";
        if (value.substring(8, 23) === "www.youtube.com") {
            newValue = value.replace('/watch?v=', '/embed/')
        } else {
            newValue = value;
        }

        this.setState({
            asset_link: newValue
        })
    }

    handleChangeLinkLanguage(value, index) {
        //console.log("Sub: " + value.substring(8,23))
        const { assets_link } = this.state;

        if (value.substring(8, 23) === "www.youtube.com") {
            assets_link[index] = value.replace('/watch?v=', '/embed/')
        } else {
            assets_link[index] = value;
        }

        this.setState({
            assets_link: assets_link
        })
    }

    onImageLoaded = (image, param, index) => {
        this.imageRef = image;
    };

    onImageLoaded1 = (image2, param, index) => {
        this.imageRef2 = image2;
    };

    onCropComplete = (crop, param, index) => {
        if (param === 'nolang') {
            this.makeClientCrop(crop, param, index);
        } else {
            if (this.state.cropsLoaded[index]) {
                this.makeClientCrop(crop, param, index);
            }
        }
    };

    onCropChange = (crop, param, index) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });

        if (param === 'nolang') {
            this.setState({ crop });
        } else {
            let assets_crop = [...this.state.assets_crop]
            assets_crop[index] = crop
            this.setState({ assets_crop });
        }
    };

    onDragStart(index) {
        let cropsLoaded = [...this.state.cropsLoaded]
        cropsLoaded[index] = true

        this.setState({
            cropsLoaded: cropsLoaded
        })
    }

    async makeClientCrop(crop, param, index) {
        let ref
        if (param !== 'nolang' && index === 1) {
            ref = this.imageRef2
        } else {
            ref = this.imageRef
        }
        if (ref && crop.width && crop.height) {
            if (param === 'nolang') {
                let fn = this.getFileName(this.state.src)
                const croppedImage = await this.getCroppedImg(this.imageRef, crop, this.getFileName(this.state.src), param, index)
                const croppedImageUrl = this.blobToFile(croppedImage, `m-${Math.floor(100000 + Math.random() * 900000)}-${fn}`)

                // let cropLoaded = this.checkArraysMatch(crop, this.state.mobile_variable) === true ? false : true
                // let cropLoaded = (JSON.stringify(crop) === JSON.stringify(this.state.mobile_variable)) ? false : true
                this.setState((prevState) => {
                    return {
                        croppedImageUrl,
                        cropLoaded: this.checkArraysMatch(crop, this.state.mobile_variable) ? true : false
                    }
                }, () => {

                })
            } else {
                let fn = this.getFileName(this.state.descriptions[index].asset_file)
                if (index === 0) {
                    const croppedImage = await this.getCroppedImg(this.imageRef, crop, fn, param, index);
                    const croppedImageUrlEnPrev = await this.getCroppedImg1(this.imageRef, crop, fn, param, index);
                    const croppedImageUrlEn = this.blobToFile(croppedImage, `m-${Math.floor(100000 + Math.random() * 900000)}-${fn}`)

                    this.setState({ croppedImageUrlEn, croppedImageUrlEnPrev });
                } else {
                    const croppedImage = await this.getCroppedImg(this.imageRef2, crop, fn, param, index);
                    const croppedImageUrlVnPrev = await this.getCroppedImg1(this.imageRef2, crop, fn, param, index);
                    const croppedImageUrlVi = this.blobToFile(croppedImage, `m-${Math.floor(100000 + Math.random() * 900000)}-${fn}`)

                    this.setState({ croppedImageUrlVi, croppedImageUrlVnPrev });
                }
            }
        }
    }

    getCroppedImg(image, crop, fileName, param, index) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                // blob.type = type;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                // resolve(this.fileUrl); use this if you want to display result of cropped
                resolve(blob); // this return only blob file which will be saved to db
            }, 'image/jpeg');
        });
    }

    getCroppedImg1(image, crop, fileName, param, index) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl); //use this if you want to display result of cropped
                // resolve(blob); // this return only blob file which will be saved to db
            }, 'image/jpeg');
        });
    }

    getFileName(file) {
        let fname = file.split('/').pop()
        return fname.substring(0, fname.indexOf("."));
    }

    blobToFile(theBlob, fileName) {
        let type
        if (theBlob.type === "image/png") {
            type = 'png'
        } else {
            type = 'jpg'
        }
        theBlob.lastModifiedDate = new Date();
        theBlob.name = `${fileName}.${theBlob.type}`;

        const file = new File([theBlob], `${fileName}.${type}`);
        // return theBlob;
        return file;
    }

    checkArraysMatch(arr1, arr2) {
        if (arr1.length !== arr2.length) {
            return false;
        } else {
            let newArr1 = this.convertToArr(arr1)
            let newArr2 = this.convertToArr(arr2)

            for (let i = 0; i < newArr1.length; i++) {
                if (newArr1[i] !== newArr2[i]) {
                    return false;
                }
            }
        }

        return true;
    };

    convertToArr(arr) {
        let arr1 = []
        arr1.push({ x: arr.x })
        arr1.push({ y: arr.y })
        arr1.push({ width: arr.width })
        arr1.push({ height: arr.height })
        arr1.push({ unit: arr.unit })
        arr1.push({ aspect: arr.aspect })
        return arr1
    }

    handleChangeFile(event) {
        //console.log(event.target.files[0])
        this.setState({
            file: event.target.files[0]
        })

        const reader = new FileReader();
        reader.addEventListener('load', () =>
            this.setState({ src: reader.result })
        );
        reader.readAsDataURL(event.target.files[0]);
    }

    handleChangeNewThumbnail(event) {
        this.setState({
            fileNewThumbnail: event.target.files[0],
            thumbnailUrl: URL.createObjectURL(event.target.files[0]),
            thumbnailID: false
        })
    }

    handleChangeFileLang(event, index) {
        //console.log(event.target.files[0])

        let assets_file = [...this.state.assets_file]
        assets_file[index] = event.target.files[0];

        this.setState({
            assets_file: assets_file
        })

        const reader = new FileReader();
        reader.addEventListener('load', () =>
            parseInt(index) === 0 ?
                this.setState({ srcEn: reader.result }) :
                this.setState({ srcVn: reader.result })

        );
        reader.readAsDataURL(event.target.files[0]);
    }

    handleChangeFileType(value) {
        var inputType = "FILE"
        if (parseInt(value) === 3) {
            inputType = "URL"
        }

        this.setState({
            // asset_type:value,
            asset_type: parseInt(value),
            inputType: inputType
        })
    }

    handleHasLanguage() {
        this.setState({
            hasLanguage: !this.state.hasLanguage
        })
    }

    handleHasSlogan() {
        this.setState({
            hasSlogan: !this.state.hasSlogan
        })
    }

    handleDarkLogo() {
        this.setState({
            hasDarkLogo: !this.state.hasDarkLogo
        })
    }

    handleDarkLight(value) {
        this.setState({
            darkLight: value
        })
    }

    getDetailAsset() {
        const objAsset = new AssetApi()

        const assetId = this.props.match.params.assetId

        objAsset.getDetailAsset(assetId)
            .then(res => {

                // swal("error !",JSON.stringify(res.data),"error")
                // console.log(res.data)
                let assets_crop = []
                res.data.asset_languages?.forEach((ass, i) => {
                    assets_crop.push({
                        x: ass.mobile_x,
                        y: ass.mobile_y,
                        width: ass.mobile_width,
                        height: ass.mobile_height,
                        unit: ass.mobile_unit,
                        aspect: ass.mobile_aspect,
                    });
                });

                let assLink = []
                if (parseInt(res.data.asset_type) === 3) {
                    res.data.asset_languages?.forEach(ass => {
                        assLink.push(
                            ass.asset_link
                        )
                    });
                }

                this.setState({
                    name: res.data.name,
                    description: res.data.description,
                    media: res.data.asset_file,
                    src: res.data.asset_file,
                    thumbnail: res.data.thumbnail_file,
                    thumbnailUrl: res.data.thumbnail_file,
                    hasLanguage: res.data.has_languages,
                    hasSlogan: res.data.has_slogan,
                    hasLanguageStatic: res.data.has_languages,
                    assets_link: assLink,
                    asset_link: res.data.asset_link,
                    file_type: res.data.asset_type_name,
                    asset_type: parseInt(res.data.asset_type),
                    hasDarkLogo: res.data.set_logo_opacity,
                    darkLight: res.data.logo_opacity,
                    descriptions: res.data.asset_languages,
                    thumbnailID: res.data.thumbnail,
                    asset_used_in_article: res.data.asset_used_in_article,
                    asset_used_in_widget: res.data.asset_used_in_widget,
                    src1: res.data.asset_languages[0]?.asset_mobile,
                    src2: res.data.asset_languages[1]?.asset_mobile,

                    srcEn: res.data.asset_languages[0]?.file_big,
                    srcVn: res.data.asset_languages[1]?.file_big,

                    mobile_variable: res.data.mobile_width > 0 ? {
                        x: res.data.mobile_x,
                        y: res.data.mobile_y,
                        width: res.data.mobile_width,
                        height: res.data.mobile_height,
                        unit: res.data.mobile_unit,
                        aspect: res.data.mobile_aspect,
                    } : this.state.crop,
                    cropLoaded: true,
                    assets_crop: assets_crop,
                    isCropped: true

                }, () => {
                    this.renderTabLanguage()
                })
                //swal("success !",JSON.stringify(res.data),"success")
            })
            .catch(err => {
                swal("error !", JSON.stringify(err.message), "error")
            })
    }

    getAssetTypeLanguage() {
        const obj = new AssetApi()
        const lang = localStorage.getItem('lang')

        obj.assetTypeLanguage({
            language: lang ? lang : 'en'
        })
            .then(res => {
                this.setState({
                    asset_types: res.data.results
                })
            })
    }

    handleSelectedAssetThumbnail(thumbnailType, asset, id) {
        switch (thumbnailType) {
            case 'noLang':
                this.setState({
                    thumbnailUrl: asset
                })
                break;
            case 'enLang':
                this.setState({
                    thumbnailUrl1: asset
                })
                break;
            case 'vnLang':
                this.setState({
                    thumbnailUrl2: asset
                })
                break;
            default:
                break;
        }
        this.setState({
            thumbnailID: id,
            fileNewThumbnail: false
        })
    }

    updateAsset() {
        const objAsset = new AssetApi()

        const asset_mobiles = [this.state.croppedImageUrlEn, this.state.croppedImageUrlVi]

        const asset_languages = this.state.descriptions.map((item, index) => {
            return {
                language: item.language,
                description: item.description,
                asset_file: this.state.assets_file[index],
                asset_link: this.state.assets_link[index],
                asset_mobile: asset_mobiles[index] ? asset_mobiles[index] : false,

                mobile_variable: this.state.assets_crop[index].width ? {
                    mobile_aspect: this.state.assets_crop[index]?.aspect,
                    mobile_unit: this.state.assets_crop[index]?.unit,
                    mobile_height: Math.ceil(this.state.assets_crop[index]?.height),
                    mobile_width: Math.ceil(this.state.assets_crop[index]?.width),
                    mobile_x: Math.ceil(this.state.assets_crop[index]?.x),
                    mobile_y: Math.ceil(this.state.assets_crop[index]?.y)
                } : this.state.default_variable
            }
        })

        const assetId = this.props.match.params.assetId
        const newData = {
            name: this.state.name,
            has_languages: this.state.hasLanguage,
            thumbnail: parseInt(this.state.asset_type) !== 1 ? (this.state.thumbnailID ? this.state.thumbnailID : null) : null,
            description: this.state.description,
            asset_type: this.state.asset_type,
            asset_link: this.state.asset_link,
            asset_file: this.state.file,
            asset_mobile: this.state.croppedImageUrl,
            has_slogan: this.state.hasSlogan,
            set_logo_opacity: this.state.hasDarkLogo,
            logo_opacity: this.state.darkLight,
            asset_languages: asset_languages,

            mobile_variable: this.state.crop ? {
                mobile_aspect: this.state.crop.aspect,
                mobile_unit: this.state.crop.unit,
                mobile_height: Math.ceil(this.state.crop.height),
                mobile_width: Math.ceil(this.state.crop.width),
                mobile_x: Math.ceil(this.state.crop.x),
                mobile_y: Math.ceil(this.state.crop.y)
            } : this.state.default_variable
        }

        //  console.log(assetId, newData)
        // console.log("NewAsset ==> ", newData)

        if (this.state.fileNewThumbnail) {
            const newThumbnail = {
                name: this.state.name,
                description: "",
                has_languages: "",
                has_slogan: this.state.false,
                thumbnail: null,
                file_type: "FILE",
                asset_type: 1,
                set_logo_opacity: false,
                logo_opacity: false,
                asset_mobile: null,
                asset_file: this.state.fileNewThumbnail,
                asset_languages: asset_languages
            }

            objAsset.assetAdd(newThumbnail)
                .then(res => {
                    let newArray = { ...newData, thumbnail: res.data.id }
                    this.updateExistingAsset(assetId, newArray)
                })
                .catch(err => {
                    console.log("err => ", err)
                })
        } else {
            this.updateExistingAsset(assetId, newData)
        }
    }

    updateExistingAsset(assetId, newAsset) {
        const { t } = this.props;
        const objAsset = new AssetApi()

        objAsset.assetUpdate(assetId, newAsset)
            .then(res => {
                // console.log(" res data asset ==> ", res.data)

                swal("Success !", t(this.state.transAlert + "asset_update_success"), "success")
                setTimeout(() => {
                    window.location.reload()
                }, 500)
            })
            .catch(err => {
                swal("Error !", err.message, "error")
            })
    }

    deleteAsset() {

    }

    renderTabLanguage() {
        const renderTabLanguage = this.state.languageList.map((item, index) => {

            var findName = this.state.descriptions.find((textLang, idx) => item.id === textLang.language)
            return {

                language: item.id,
                //   name: findName ? findName.name : "",
                languageName: item.name,
                //   page: this.state.selectedPage,
                // thumbnail: this.state.thumbnail,
                thumbnail: findName?.thumbnail_file,
                asset_file: findName?.asset_file,
                asset_link: findName?.asset_link,
                description: findName?.description,
            }

        })

        this.setState({
            descriptions: renderTabLanguage,
        })
    }

    handleChangeTab(value) {
        this.setState(
            {
                selectedLanguage: value,
                tabKey: value,
            },
            () => {
                this.renderTabLanguage()
            }
        )
    }

    // handleChangeFileClick() {
    //     this.hiddenFileInput.current.click();
    // }

    checkingFileExtension(file) {
        let fileType
        let typeVid = ["webm", "mkv", "flv", "gif", "avi", "mov", "wmv", "mp4", "3gp"];
        let typeImg = ["jpg", "jpeg", "png", "svg", "webp", "bmp", "tiff", "exif"];
        let typeDoc = ["doc", "docx", "odt", "pdf", "xls", "xlsx", "ods", "ppt", "pptx", "txt"];
        let extension = (file && file !== "null") ? file.substring(file.lastIndexOf(".") + 1) : 'default'
        if (typeVid.includes(extension)) {
            fileType = "vid"
        } else if (typeImg.includes(extension)) {
            fileType = "img"
        } else if (typeDoc.includes(extension)) {
            fileType = "doc"
        } else {
            fileType = "img"
        }
        return fileType
    }

    checkingPreviewFile(file, index, lang) {
        let media, asset_image_big

        switch (!lang ? this.state.asset_type : '') {
            case 1:
                if (lang) {
                    asset_image_big = file ? file : defaultImg
                } else {
                    asset_image_big = this.state.media
                }
                media = <img src={asset_image_big} alt={this.state.description} width={"100%"} style={{ lineHeight: "100%" }} ></img>

                break;
            case 2:
                let extFile
                if (lang) {
                    extFile = file ? this.checkingFileExtension(file) : "null"
                } else {
                    extFile = this.state.media ? this.checkingFileExtension(this.state.media) : "null"
                }

                if (extFile === "img") {
                    media = <img src={lang ? file?.asset_languages[index].asset_file : this.state.media} width={"100%"} style={{ lineHeight: "100%" }} alt={""}></img>
                } else if (extFile === "doc") {
                    let files = lang ? file.split("/").pop() : (this.state.file.length !== 0 ? this.state.file.name.split(".").pop() : this.state.media.split("/").pop())
                    let ext = files.split(".").pop()
                    media = <div style={{ minHeight: "200px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <span style={{ fontSize: "32px" }}>{ext}</span>
                    </div>
                    // }
                } else if (extFile === "vid") {
                    media = <video style={{ height: "100%", width: "100%" }} controls loop={false}>
                        <source src={lang ? file : this.state.media} type="video/mp4" />
                    </video>
                } else if (extFile === "null") {
                    media = <img src={defaultImg} alt="big-mage" height="100%" style={{ display: "block", height: "100%", width: "100%", margin: "auto", objectFit: "contain" }}></img>
                }

                break;
            case 3:
                media = <iframe src={lang ? file : this.state.asset_link} style={{ height: "100%", width: "100%" }} title={"media"} controls loop={false}>
                </iframe>
                break;
            default:
                break;
        }
        return media
    }

    checkingDefaultMedia(customMedia) {
        let fname, updateFile, media
        if (this.state.file.length > 0) {
            fname = this.state.file.name
        } else {
            fname = this.state.file.length !== 0 ? this.state.file.name.split('/').pop() : (this.state.media ? (this.state.media !== "" ? this.state.media.split('/').pop() : "") : "")
        }
        updateFile = <div className="d-flex file-input-custom-u fc-ux">
            <input type="file" name="asset_file" className="form-control custom-file-input" onChange={(event) => this.handleChangeFile(event)}></input>
            <label for="asset_file" >
                Update file
                <p class="file-name-custom"></p>
            </label>
        </div>
        media =
            <div className="d-flex flex-column align-items-center">
                {customMedia}
                {updateFile}
                <span style={{ textAlign: "center", wordBreak: "break-word" }}>{fname}</span>
            </div>

        return media
    }

    assetsUsedIn(data, param) {
        const { t } = this.props;
        let assets, columns, customPagination, customNoData

        columns = [
            {
                name: <div className={'tableColumn'}>
                    <div className={'contentColumn'}>
                        <span>{t(this.state.transPage + "page")}</span>
                    </div>
                </div>,
                selector: 'page_name',
                sortable: true,
                cell: (row, i) => {
                    return (
                        <span>{row.page_name}</span>
                    )
                }
            },
            {
                name: <div className={'tableColumn'}>
                    <div className={'contentColumn'}>
                        <span>{t(this.state.transPage + "section")}</span>
                    </div>
                </div>,
                selector: 'section_name',
                sortable: true,
                cell: (row, i) => {
                    return (
                        <span>{row.section_name}</span>
                    )
                }
            },
            {
                name: <div className={'tableColumn'}>
                    <div className={'contentColumn'}>
                        <span>{t(this.state.transPage + "id")}</span>
                    </div>
                </div>,
                selector: param === "Article" ? 'article_id' : 'widget_id',
                sortable: true,
                cell: (row, i) => {
                    return (
                        <span>{param === "Article" ? row.article_id : row.widget_id}</span>
                    )
                }
            },
            {
                name: <div className={'tableColumn'}>
                    <div className={'contentColumn'}>
                        <span>{param === "Article" ? (t(this.state.transPage + "article_title")) : (t(this.state.transPage + "widget_name"))}</span>
                    </div>
                </div>,
                selector: param === "Article" ? 'article_title' : 'widget_name',
                sortable: true,
                cell: (row, i) => {
                    return (
                        <span>{param === "Article" ? row.article_title : row.widget_name}</span>
                    )
                }
            }
        ];

        customPagination = {
            rowsPerPageText: 'Rows per page',
            rangeSeparatorText: 'of',
            noRowsPerPage: true,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All'
        }

        customNoData = <div className={'contentNoData'}>
            <span>{'no record found'}</span>
        </div>

        assets = <DataTableExtensions
            columns={columns}
            data={data}
            export={false}
            print={false}
            exportHeaders={true}
            filterPlaceholder={'search'}
        >
            <DataTable
                className={["table table-bordered dataTable", 'dataTable'].join(' ')}
                title={param}
                noHeader
                highlightOnHover={true}
                striped={true}
                defaultSortField="post_date"
                defaultSortAsc={false}
                pagination={true}
                paginationComponentOptions={customPagination}
                responsive={true}
                noDataComponent={customNoData}
            />
        </DataTableExtensions>

        return assets
    }

    removeThumbnail = () => {
        this.setState({
            thumbnailID: false,
            thumbnailUrl: false
        })
    }

    handleKeyDownLang = (e, index) => {
        const { assets_link } = this.state;
        if (e.key === "Enter" || e.keyCode === 13) {
            if (e.target.value.substring(8, 23) === "www.youtube.com") {
                assets_link[index] = e.target.value.replace('/watch?v=', '/embed/')
            } else {
                assets_link[index] = e.target.value
            }

            this.setState({
                assets_link: assets_link
            })
        }
    }

    handlePreviewPdf(index) {
        const src = index ? this.state.descriptions[index]?.asset_file : this.state.src

        if (this.isPdf()) {
            this.setState({ isShowPdfPreview: true, pdfFile: src })
        }
    }

    isPdf(index) {
        const src = index ? this.state.descriptions[index]?.asset_file : this.state.src
        const arrSrc = src ? src.split('.') : []
        return arrSrc.length && arrSrc[arrSrc.length - 1].toLowerCase() === "pdf"
    }

    render() {
        const { t } = this.props;

        const { crop, croppedImageUrl, croppedImageUrlEn, croppedImageUrlVn, src, srcEn, srcVn, mobile_variable, cropLoaded, assets_crop, default_variable, croppedImageUrlEnPrev, croppedImageUrlVnPrev } = this.state;

        const TabLanguage = this.state.descriptions.map((item, index) => {
            // console.log(item)
            if (this.state.hasLanguage) {
                // FileInputLanguage = <div className="form-group">
                //     <label>Asset File</label>
                //     <input type="file" name="asset_file" className="form-control" onChange={(event) => this.handleChangeFileLang(event, index)}></input>
                // </div>
                if (parseInt(this.state.asset_type) !== 3) {
                    FileInputLanguage =
                        <div className="form-group">
                            <label>{t(this.state.transPage + "asset_file")}</label>
                            <div className="ass-sel-update">
                                <p>
                                    {this.state.assets_file && this.state.assets_file[index] ? this.state.assets_file[index].name.split('/').pop() : (item.asset_file ? item.asset_file.split('/').pop() : item.asset_file)}
                                    <button
                                        className="ml-2 btn btn-sm btn-link"
                                        onClick={() => { this.handlePreviewPdf(index) }}
                                        style={{ display: this.isPdf(index) ? "inline" : "none" }}
                                    >
                                        preview
                                    </button>
                                </p>

                                <div className="d-flex file-input-custom-t">
                                    <input type="file" name="asset_file" className="form-control custom-file-input" onChange={(event) => this.handleChangeFileLang(event, index)}></input>
                                    <label for="asset_file" >
                                        Update File
                                        <p class="file-name-custom"></p>
                                    </label>
                                </div>
                            </div>
                            {index === 0 ? parseInt(this.state.asset_type) === 1 && srcEn &&
                                (
                                    <div className="mt-3">
                                        <label style={{ position: "relative", display: 'block' }}>Assets for mobile</label>
                                        <div className="c_crop--fixed">
                                            <ReactCrop
                                                src={srcEn}
                                                crop={(assets_crop[0] && assets_crop[0].width) ? assets_crop[0] : crop}
                                                ruleOfThirds
                                                crossorigin={'true'}
                                                onImageLoaded={this.onImageLoaded}
                                                onDragStart={() => this.onDragStart(index)}
                                                onComplete={(crop) => this.onCropComplete(crop, 'lang', index)}
                                                onChange={(crop) => this.onCropChange(crop, 'lang', index)}

                                            />
                                        </div>
                                        {/* this for preview */}
                                        {/* <div className={'d-flex'}>
                                            <div className="form-group d-flex flex-column">
                                                <label>loaded</label>
                                                <img alt="Crop" style={{ maxWidth: '100%' }} src={this.state.src1} />
                                            </div>
                                            <div className="form-group d-flex flex-column">
                                                <label>croppped</label>
                                                {croppedImageUrlEnPrev && (
                                                    <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrlEnPrev} />
                                                )}
                                            </div>
                                        </div> */}

                                    </div>
                                ) :
                                parseInt(this.state.asset_type) === 1 && srcVn &&
                                (
                                    <div className="mt-3">
                                        <label style={{ position: "relative", display: 'block' }}>Assets for mobile</label>
                                        <div className="c_crop--fixed">
                                            <ReactCrop
                                                src={srcVn}
                                                crop={(assets_crop[1] && assets_crop[1].width) ? assets_crop[1] : crop}
                                                ruleOfThirds
                                                crossorigin={'true'}
                                                onImageLoaded={this.onImageLoaded1}
                                                onDragStart={() => this.onDragStart(index)}
                                                onComplete={(crop) => this.onCropComplete(crop, 'lang', index)}
                                                onChange={(crop) => this.onCropChange(crop, 'lang', index)}
                                            />
                                        </div>
                                        {/* this for preview */}
                                        {/* <div className={'d-flex'}>
                                            <div className="form-group d-flex flex-column">
                                                <label>loaded</label>
                                                <img alt="Crop" style={{ maxWidth: '100%' }} src={this.state.src2} />
                                            </div>
                                            <div className="form-group d-flex flex-column">
                                                <label>croppped</label>
                                                {croppedImageUrlVnPrev && (
                                                    <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrlVnPrev} />
                                                )}
                                            </div>
                                        </div> */}
                                    </div>
                                )
                            }
                        </div>
                } else {
                    // LINK IS NOT EXITS IN DATABASE
                    // if (this.state.inputType === "URL") {
                    FileInputLanguage = <div className="form-group">
                        <label>Asset Url</label>
                        <input type="url" name="asset_url" className="form-control" value={this.state.assets_link[index] ? this.state.assets_link[index] : ''} onChange={(event) => this.handleChangeLinkLanguage(event.target.value, index)}></input>
                    </div>
                    // }
                }
            } else {
            }

            return (
                <Tab key={index} eventKey={item.language} title={item.languageName}>
                    <br></br>
                    {FileInputLanguage}
                    <div className='form-group'>
                        <label> {t('description')} </label>
                        <textarea className='form-control'
                            onChange={(event) => this.handleChangeDescription(index, event.target.value)}
                            value={this.state.descriptions[index].description}></textarea>
                        {/* { JSON.stringify( this.state.text_languages )  } */}
                    </div>
                </Tab>
            )
        })

        var Media

        if (this.state.hasLanguageStatic) {
            const TabMedia = this.state.descriptions.map((item, index) => {
                let ass, fname, media, updateFile
                if (this.state.asset_type === 1) {
                    ass = item.asset_file ? (item.asset_file !== "" ? item.asset_file : imgDefault) : imgDefault
                    media = <img src={ass} alt={item.description ? item.description : ""} width={"100%"} style={{ lineHeight: "100%", margin: "auto" }} ></img>
                } else if (this.state.asset_type === 2) {
                    let extFile = this.checkingFileExtension(item.asset_file)
                    if (extFile === "img") {
                        media = <img src={item.asset_file} alt={item.description ? item.description : ""} width={"100%"} style={{ minHeight: "200px", lineHeight: "100%", margin: "auto", objectFit: "contain" }} ></img>
                    } else if (extFile === "doc") {
                        let files = this.state.assets_file.length > 0 && this.state.assets_file[index] ? this.state.assets_file[index].name.split(".").pop() : (item.asset_file ? item.asset_file.split("/").pop() : '.')
                        let ext = files.split(".").pop()
                        media = <div style={{ minHeight: "200px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <span style={{ fontSize: "32px" }}>{ext}</span>
                        </div>
                    } else if (extFile === "vid") {
                        media = <video style={{ height: "100%", width: "100%" }} controls loop={false}>
                            <source src={this.state.media} type="video/mp4" />
                        </video>
                    } else {
                        media = <img src={defaultImg} alt={""} width={"100%"} style={{ lineHeight: "100%", margin: "auto" }} ></img>
                    }

                    if (this.state.assets_file.length > 0 && this.state.assets_file[index]) {
                        fname = this.state.assets_file[index].name
                    } else {
                        fname = item.asset_file ? (item.asset_file !== "" ? item.asset_file.split('/').pop() : "") : ""
                    }
                    updateFile = <div className="d-flex file-input-custom-u">
                        <input type="file" name="asset_file" className="form-control custom-file-input" onChange={(event) => this.handleChangeFileLang(event, index)}></input>
                        <label for="asset_file" >
                            Update file
                            <p class="file-name-custom"></p>
                        </label>
                    </div>
                } else if (this.state.asset_type === 3) {
                    if (item.asset_link) {
                        media = <iframe title={item.description} width={"100%"} src={item.asset_link} allowFullScreen></iframe>
                    } else {
                        ass = item.thumbnail ? item.thumbnail : defaultImg
                        media = <img src={ass} alt={item.description} width={"100%"} style={{ lineHeight: "100%", margin: "auto" }} ></img>
                    }

                } else if (this.state.asset_type === 6) {
                    ass = item.asset_file ? (item.asset_file !== "" ? item.asset_file : imgDefault) : imgDefault
                    media = <Panorama src={ass} pStyle='40vh' />
                } else if (this.state.asset_type === 7) {
                    ass = item.asset_file ? (item.asset_file !== "" ? item.asset_file : imgDefault) : imgDefault
                    media = <video style={{ height: "100%", width: "100%" }} controls loop={false}>
                        <source src={ass} type="" />
                    </video>
                } else {
                    ass = item.thumbnail ? item.thumbnail : imgDefault
                    media = <img src={ass} alt={item.description ? item.description : ""} width={"100%"} style={{ lineHeight: "100%", margin: "auto" }} ></img>
                }

                // if(this.state.file_type === 3) {
                //     media = <iframe title={"asset-media-embedded"} width={"100%"} src={item.asset_link} allowFullScreen></iframe>
                // } else if(this.state.file_type === "Video") {
                //     media = <video width={"100%"} height={"50%"} controls>
                //         <source src={item.asset_file} />
                //     </video>
                // }
                return (
                    <Tab key={index} eventKey={item.language} title={item.languageName} style={{ height: "100%", width: "100%" }}>
                        <div className="d-flex flex-column align-items-center">
                            {media}
                            {updateFile}
                            <span style={{ textAlign: "center", wordBreak: "break-word" }}>{fname}</span>
                        </div>
                    </Tab>
                )

            })

            Media = <Tabs id="ass-d-p">{TabMedia}</Tabs>
        } else {
            let media
            switch (this.state.asset_type) {
                case 1:
                    media = <img src={this.state.media} alt={this.state.description} width={"100%"} style={{ lineHeight: "100%" }} ></img>
                    break;
                case 2:
                    let customMedia = this.checkingPreviewFile("", "", false)
                    media = this.checkingDefaultMedia(customMedia)
                    break;
                case 3:
                    media = <iframe title={"asset-media-embedded"} width={"100%"} src={this.state.asset_link} allowFullScreen></iframe>
                    // media =
                    //     <video style={{ height: "100%", width: "100%" }} controls loop={false}>
                    //         <source src={this.state.asset_link} type="video" />
                    //     </video>
                    break;
                case 6:
                    // media = <img src={this.state.media} alt={this.state.description} width={"100%"} style={{ lineHeight: "100%" }} ></img>
                    media = <Panorama src={this.state.media} pStyle='40vh' />
                    break;
                case 7:
                    media =
                        <video style={{ height: "100%", width: "100%" }} controls loop={false}>
                            <source src={this.state.media} type="video" />
                        </video>
                    break;
                default:
                    media = <img src={this.state.media} alt={this.state.description} width={"100%"} style={{ lineHeight: "100%" }} ></img>

                    break;
            }
            Media = media
        }

        var FileInputLanguage = ""
        var FileInput = ""
        var input = <div className="form-group">
            {/* <input type="file" name="asset_file" className="form-control" onChange={(event) => this.handleChangeFile(event)}></input> */}
            {
                (this.state.asset_link || this.state.file.length) && parseInt(this.state.asset_type) === 3 ?
                    <>
                        <label>Asset Url</label>
                        <div className="ass-sel-update">
                            <input type='text' className="form-control"
                                value={this.state.asset_link}
                                onChange={(event) => this.handleChangeLink(event.target.value)} />
                            {/* <div className="d-flex file-input-custom-t">
                            <input type="file" name="asset_file" className="form-control custom-file-input" onChange={(event) => this.handleChangeFile(event)}></input>
                            <label for="asset_file" >
                                Update Link
                            <p class="file-name-custom"></p>
                            </label>
                        </div> */}
                        </div>
                    </>
                    :
                    <>
                        <label>Asset File</label>
                        <div className="ass-sel-update">
                            <p>
                                {this.state.file.length !== 0 ? this.state.file.name.split('/').pop() : (this.state.media ? this.state.media.split('/').pop() : this.state.asset_link)}
                                <button
                                    className="ml-2 btn btn-sm btn-link"
                                    onClick={() => { this.handlePreviewPdf() }}
                                    style={{ display: this.isPdf() ? "inline" : "none" }}
                                >
                                    preview
                                </button>
                            </p>
                            <div className="d-flex file-input-custom-t">
                                <input type="file" name="asset_file" className="form-control custom-file-input" onChange={(event) => this.handleChangeFile(event)}></input>
                                <label for="asset_file" >
                                    Update File
                                    <p class="file-name-custom"></p>
                                </label>
                            </div>
                        </div>
                    </>

            }


            {(parseInt(this.state.asset_type) === 1) && src &&
                (
                    <div className="mt-3">
                        <label style={{ position: "relative", display: 'block' }}>Assets for mobile</label>
                        <div className="c_crop--fixed">
                            <ReactCrop
                                src={src}
                                crop={cropLoaded ? (mobile_variable.width !== 0 ? mobile_variable : crop) : crop}
                                ruleOfThirds
                                crossorigin={'true'}
                                onImageLoaded={this.onImageLoaded}
                                onComplete={(crop) => this.onCropComplete(crop, 'nolang', '')}
                                onChange={(crop) => this.onCropChange(crop, 'nolang', '')}
                            />
                        </div>
                    </div>
                )}
        </div>

        if (this.state.inputType === "URL") {
            input = <div className="form-group">
                <label>Asset Url</label>
                <input type="url" name="asset_url" className="form-control" onChange={(event) => this.handleChangeLink(event.target.value)}></input>
            </div>
        }


        const { descriptions } = this.state
        let SetThumbnail, FileThumbnail
        if (this.state.asset_type && parseInt(this.state.asset_type) !== 1) {
            let thumb = this.state.thumbnailUrl ? this.state.thumbnailUrl : defaultImg
            let thumbFile = thumb
            if (this.state.activeLang === "vi") {
                thumbFile = descriptions[1] ? (descriptions[1].thumbnail ? descriptions[1].thumbnail : thumb) : thumb
            } else {
                thumbFile = descriptions[0] ? (descriptions[0].thumbnail ? descriptions[0].thumbnail : thumb) : thumb
            }
            SetThumbnail =
                <div className="d-flex flex-column">
                    <label style={{ padding: ".5rem 0 0 .5rem" }}>Thumbnail</label>
                    {/* enable if you want to show thumbnail */}
                    {
                        this.state.thumbnailID && this.state.thumbnailUrl ?
                            <div className='position-relative' style={{ width: '100%', cursor: 'pointer' }} onClick={this.removeThumbnail}>
                                <i className='fa fa-times fa-1x position-absolute' style={{ top: '-2rem', right: '0rem', zIndex: '2', border: '1px solid black', padding: '.5rem', color: 'black' }}></i>
                                {/* <img src={this.state.thumbnailUrl} style={{ height: '300px', width: '100%', marginBottom: '1.5rem' }} alt="" /> */}
                                <img src={thumbFile} style={{ height: '200px', width: '100%', objectFit: "contain", marginBottom: '1rem' }} alt="" />
                            </div>
                            : <></>
                    }
                    <div className="d-flex justify-content-center">
                        <div className="m-auto" style={{ cursor: "pointer" }} onClick={() => { this.setShowAssetThumbnailModal('noLang') }}>
                            <i className={this.state.thumbnailUrl ? "fa fa-edit" : "fa fa-plus"}></i> {this.state.thumbnailUrl ? 'Update' : 'Add'}
                        </div>
                        <div className="d-flex file-input-custom-t">
                            <input type="file" name="asset_file" className="form-control custom-file-input" onChange={(event) => this.handleChangeNewThumbnail(event)}></input>
                            <label for="asset_file" >
                                <i className="fa fa-plus mr-1"></i>Add new
                                <p class="file-name-custom"></p>
                            </label>
                        </div>
                    </div>

                </div>
        } else {
            <></>
        }

        if (!this.state.hasLanguage) {
            FileInputLanguage = ""
            FileInput = input
            FileThumbnail = SetThumbnail
        } else {
            FileInput = ""
            FileInputLanguage = input
            FileThumbnail = SetThumbnail ? <div id="c-main-thumb-d-p">{SetThumbnail}</div> : <></>
        }

        var DarkLogoInput = ""

        if (this.state.hasDarkLogo) {
            DarkLogoInput = <div>
                <input type="radio" value="dark" id="dark-radio" name="has-dark-logo" checked={this.state.darkLight === "Dark"} onClick={() => this.handleDarkLight("Dark")}></input>
                &nbsp;
                <label for="dark-radio"> Dark </label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <input type="radio" value="light" id="light-radio" name="has-dark-logo" checked={this.state.darkLight === "Light"} onClick={() => this.handleDarkLight("Light")} ></input>
                &nbsp;
                <label for="light-radio"> Light </label>
                {/* { JSON.stringify(this.state.darkLight) } */}
            </div>
        }

        const { asset_used_in_article, asset_used_in_widget } = this.state
        let assetUsed
        if ((asset_used_in_article && asset_used_in_article.length > 0) || (asset_used_in_widget && asset_used_in_widget.length > 0)) {
            let assetsArticle, assetsWidget

            if (asset_used_in_article && asset_used_in_article.length > 0) {
                assetsArticle =
                    <>
                        <label>{t(this.state.transPage + "article")}</label>
                        {this.assetsUsedIn(asset_used_in_article, "Article")}
                    </>
            }

            if (asset_used_in_widget && asset_used_in_widget.length > 0) {
                assetsWidget =
                    <>
                        <label>{t(this.state.transPage + "widget")}</label>
                        {this.assetsUsedIn(asset_used_in_widget, "Widget")}
                    </>
            }

            assetUsed =
                <div id='ass_d_page_tab' className="form-group" >
                    <label>{t(this.state.transPage + "asset_used_in")}</label>
                    <div className="d-flex flex-column">
                        {assetsArticle}
                        {assetsWidget}
                    </div>
                </div>

        }

        return (
            <Content>
                <ContentHeader>
                    <TitlePages title={t(this.state.transPage + "title")} />
                    <div className="row" style={{ padding: "0 5px" }}>
                        {/* <h1 className="col-md-11"> Asset Detail </h1> */}
                        <Button onClick={() => this.props.history.goBack()} className="btn btn-default col-md-1 mt-3 ml-auto" >
                            <i className="fa fa-arrow-left">&nbsp;</i>
                            {t('back')}
                        </Button>
                    </div>

                    <div className="clear"></div>
                </ContentHeader>
                <ContentBody>
                    <Card>

                        {/* <CardHeader>
                            <Button variant="danger" className="float-right"> <i className="fa fa-trash">&nbsp;</i>Delete Asset </Button>
                        </CardHeader> */}
                        <CardBody>
                            <div className="row">
                                <div className={parseInt(this.state.asset_type) === 6 ? "col-md-12" : "col-md-3"} style={{ backgroundColor: "#F5F5F5" }}>
                                    <div className="d-flex flex-column">
                                        <div id="c-main-ass-d-p">
                                            <div id="360Preview">

                                            </div>
                                            {Media}
                                            {/* <button style={{marginLeft:"22%"}} onClick={() => this.handleChangeFile()} className="btn btn-primary btn-sm"> Change File </button> */}
                                            <input type="file" style={{ display: 'none' }} ref={this.hiddenFileInput} onChange={(event) => {

                                                var reader = new FileReader();
                                                var file = event.target.files[0]
                                                //console.log("file => ",file)
                                                //  var url = reader.readAsDataURL(file);

                                                reader.addEventListener("change", function () {
                                                    this.setState({
                                                        file: file,
                                                        media: reader.result
                                                    })
                                                })


                                            }} />
                                        </div>
                                        {FileThumbnail}
                                    </div>
                                </div>

                                <div className={parseInt(this.state.asset_type) === 6 ? "col-md-12" : "col-md-9"}>
                                    <div className="form-group ">
                                        <label>{t(this.state.transPage + "asset_name")}</label>
                                        <input type="text" name="asset_name" className="form-control" value={this.state.name} onChange={(event) => this.handleChangeName(event.target.value)}></input>

                                    </div>
                                    <div className="form-group">
                                        <input type="checkbox" value="dark-logo" id="check-dark-logo" checked={this.state.hasDarkLogo} onClick={() => this.handleDarkLogo()} ></input> &nbsp;
                                        <label for="check-dark-logo"> {t(this.state.transPage + "logo_override_color")} </label>
                                        {DarkLogoInput}
                                    </div>
                                    <div className="form-group">
                                        <input type="checkbox" id="check-has-language" checked={this.state.hasLanguage} onClick={() => this.handleHasLanguage()}></input> &nbsp;
                                        <label for="check-has-language"> {t(this.state.transPage + "has_language")} </label>
                                    </div>
                                    <div className="form-group">
                                        <input type="checkbox" id="check-has-slogan" checked={this.state.hasSlogan} onClick={() => this.handleHasSlogan()}></input> &nbsp;
                                        <label for="check-has-slogan"> {t(this.state.transPage + "has_slogan")} </label>
                                    </div>
                                    <div className="form-group">
                                        <label>{t('file_type')}</label>
                                        <select className="form-control" value={this.state.asset_type} onChange={(event) => { this.handleChangeFileType(event.target.value) }}>
                                            <option value=""> -- {t(this.state.transPage + "select_file_type")} -- </option>
                                            {
                                                this.state.asset_types.map(item => {
                                                    return (<option value={item.id}> {item.name} </option>)
                                                })
                                            }
                                        </select>

                                    </div>
                                    {FileInput}
                                    <Tabs>
                                        {TabLanguage}
                                    </Tabs>
                                    {assetUsed}
                                </div>
                            </div>

                        </CardBody>
                        <CardFooter>
                            <div className="row">
                                <div className="col-md-9">

                                </div>
                                <div className="col-md-3">
                                    <Button className="float-right" onClick={() => this.updateAsset()} > <i className="fa fa-edit">&nbsp;</i> {t('update')} </Button>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </ContentBody>
                <WidgetAssetAddThumbnailModal
                    show={this.state.setShowAssetThumbnailModal}
                    onHide={this.setShowAssetThumbnailModal}
                    // widgetId={this.props.data.widget}
                    getWidgetDetail={this.getDetailWidget}
                    selectedAssets={this.state.selectedAssets}
                    thumbnailType={this.state.thumbnailType}
                    type={""}
                    selectedAsset={this.handleSelectedAssetThumbnail}
                ></WidgetAssetAddThumbnailModal>
                <PdfPreviewModal
                    show={this.state.isShowPdfPreview}
                    onHide={() => {
                        this.setState({ isShowPdfPreview: false })
                    }}
                    file={this.state.pdfFile}
                    title={this.state.name}
                />
            </Content>
        );
    }
}

export default withTranslation()(AssetDetailPage);
