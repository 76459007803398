import React, { Component } from 'react';
import { Button, Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import swal from "sweetalert"


import MyCard, { CardHeader, CardTitle, CardBody, CardFooter } from "../components/Card";

import { Content, ContentHeader, ContentBody } from "./../components/Content"
import MenuDeleteConfirm from "./../components/Modal/MenuDeleteConfirm"
import TitlePages from './Elements/TitlePages'

import MenuApi from "./../api/MenuApi"
import MenuAddModal from '../components/Modal/MenuAddModal';

import { shortChar } from "./../lib/StringHelper"
import { withTranslation } from 'react-i18next';
import UserContext from './Context';
import config from '../config/config'
import ReactGA from 'react-ga';

class MenuListPage extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props)

        this.state = {

            id: 0,

            menus: [],
            selectedMenu: [],

            deleteDisable: true,
            showModal: false,
            confirmModal: false,
            loadingModal: "",

            successModalshow: false,

            errorModalshow: false,
            errorMessage: "",
            transAlert: "alert.",
            activeTemplate: 0

        }

        this.getAllMenu = this.getAllMenu.bind(this)

        this.handleSelectedMenu = this.handleSelectedMenu.bind(this)
        this.deleteSelectedMenu = this.deleteSelectedMenu.bind(this)

        this.showConfirmModal = this.showConfirmModal.bind(this)
        this.showModal = this.showModal.bind(this)
    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        this.setState({ activeTemplate: localStorage.getItem("templateId") }, this.getAllMenu)
    }

    componentDidUpdate() {
        const { activeTemplate } = this.context;
        if (activeTemplate !== this.state.activeTemplate) {
            this.setState({ activeTemplate: activeTemplate }, this.getAllMenu)
        }
    }

    getAllMenu() {
        const objMenu = new MenuApi()

        objMenu.getAllMenu({
            content: 3,
            language: localStorage.getItem("lang"),
            show_all: true,
            template: localStorage.getItem("templateId")
        })
            .then(res => {
                this.setState({
                    menus: res.data.results
                })
            })

    }

    handleSelectedMenu(myMenu, value) {

        const menu = {
            id: myMenu.id,
            name: myMenu.name
        }

        var lastSelected = [...this.state.selectedMenu]
        var indexDelete = -1
        // var stateSelected = ""

        if (value === true) {

            lastSelected.push(menu)
        } else {
            //console.log("jalan donk harusnya ", article , this.state.selectedArticles)
            this.state.selectedMenu.forEach((item, index) => {
                if (item.id === menu.id) {
                    indexDelete = index
                }
            })

            lastSelected.splice(indexDelete, 1)
            //console.log("lastArticles => ",lastArticles)
        }

        this.setState({
            selectedMenu: lastSelected,

        }, () => {
            if (this.state.selectedMenu.length > 0) {
                this.setState({
                    deleteDisable: false,

                })
            } else {
                this.setState({
                    deleteDisable: true,

                })
            }
        })
    }

    showConfirmModal() {
        this.setState({
            confirmModal: !this.state.confirmModal
        })
    }

    showModal() {
        this.setState({
            showModal: !this.state.showModal
        }, () => {
            if (!this.state.showModal) {
                this.setState({
                    id: 0
                })
            }
        })
    }

    deleteSelectedMenu() {
        const { t } = this.props;
        const objMenu = new MenuApi()
        const _this = this
        this.setState({
            loadingModal: <div className="overlay d-flex justify-content-center align-items-center">
                <i className="fas fa-2x fa-sync fa-spin"></i>
            </div>
        })

        const selectedMenuId = this.state.selectedMenu.map(item => {
            return (item.id)
        })

        objMenu.menuMultiDelete(selectedMenuId)
            .then(res => {

                objMenu.getAllMenu({
                    content: 3,
                    language: localStorage.getItem("lang"),
                    show_all: true
                })
                    .then(res => {

                        _this.setState({
                            menus: res.data.results,
                            loadingModal: "",
                            confirmModal: !this.state.confirmModal
                        }, () => {
                            swal("Success !", t(this.state.transAlert + "menu_delete_success"), "success")
                        })

                    })
                    .catch(err => {
                        swal("Error !", err.message, "error")
                    })

            })
            .catch(err => {
                this.setState({
                    errorModalshow: true,
                    message: err.message
                })
            })

    }

    render() {
        const { t } = this.props;

        const MenuList = this.state.menus.map((menu, index) => {
            return (
                <div className="col-md-3" key={index}>
                    <Card>
                        <CardHeader className="bg-primary">
                            <CardTitle>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        id={"menu-" + menu.id}
                                        type="checkbox"
                                        onClick={(event) => this.handleSelectedMenu(menu, event.target.checked)}
                                    ></input>
                                    <label
                                        className="form-check-label"
                                        htmlFor={"menu-" + menu.id}
                                        style={{ marginLeft: 10 }}

                                    >{menu.name}  </label>
                                </div>

                            </CardTitle>
                            <div className="card-tools">
                                <i className="fa fa-edit"
                                    onClick={() => {
                                        this.setState({
                                            id: menu.id
                                        }, () => this.showModal())
                                    }}
                                ></i>
                            </div>
                        </CardHeader>
                        <Link to={`/menu-list/${menu.id}/`}>
                            <CardBody>
                                <ul>
                                    {/* {
                                        menu.items.map((item, index) => {
                                            return (
                                                <li key={index}>{shortChar(item.title, 20)} ... </li>
                                            )
                                        })
                                    } */}
                                </ul>
                                <div className="clearfix"></div>
                            </CardBody>
                        </Link>
                    </Card>
                </div>
            )
        })

        const contextValue = this.context
        return (
            <Content>
                <ContentHeader>
                    <div className="row">
                        {/* <h1 className="col-md-11"> Menu List Page </h1> */}
                        <TitlePages title={t('pages.menu_list_page.title')} />
                        <div className="card-tools">

                        </div>
                    </div>

                </ContentHeader>
                <ContentBody>
                    <MyCard>
                        <CardHeader>
                            <div className="card-tools">
                                <div className="row">
                                    <div className=" float-right" style={{ marginRight: 10, }}>
                                        <Button variant="danger" disabled={this.state.deleteDisable} onClick={this.showConfirmModal} className="float-right">
                                            <i className="fa fa-trash">&nbsp;</i> {t('pages.menu_list_page.delete_selected')} </Button>
                                    </div>

                                    <Button variant="primary" onClick={() => {
                                        this.setState({
                                            id: 0
                                        }, () => {
                                            this.showModal()
                                        })
                                    }
                                    } className="float-right" style={{ marginRight: 10, }} >
                                        <i className="fa fa-plus">&nbsp;</i>
                                        {t('pages.menu_list_page.add_menu')}
                                    </Button>
                                </div>

                            </div>
                        </CardHeader>
                    </MyCard>
                    <div className="row">
                        {MenuList}
                    </div>
                    <MenuDeleteConfirm
                        show={this.state.confirmModal}
                        selectedMenu={this.state.selectedMenu}
                        loadingModal={this.state.loadingModal}
                        onHide={this.showConfirmModal}
                        getAllMenu={this.getAllMenu}
                        deleteSelectedMenu={this.deleteSelectedMenu}
                    >
                    </MenuDeleteConfirm>
                    <MenuAddModal
                        id={this.state.id}
                        show={this.state.showModal}
                        loadingModal={this.state.loadingModal}
                        onHide={this.showModal}
                        getAllMenu={this.getAllMenu}
                    >

                    </MenuAddModal>
                </ContentBody>
            </Content>
        );
    }
}

export default withTranslation()(MenuListPage);