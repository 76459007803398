import React, { Component } from "react"
import swal from "sweetalert"
import { Button } from "react-bootstrap"

import WidgetApi from "./../../api/WidgetApi"
import SectionApi from "./../../api/SectionApi"
import AlertHelper from "./../../lib/AlertHelper"

class ArticleFormExternal extends Component {
  constructor(props) {
    super(props)

    this.updateSectionWidgetPair = this.updateSectionWidgetPair.bind(this)
    this.setSelectedCollectionId = this.setSelectedCollectionId.bind(this)
  }

  componentDidMount() {
    this.setState({
      selectedCollectionId: this.props.data.widget
    })
  }

  setSelectedCollectionId(e) {
    this.setState({
      selectedCollectionId: parseInt(e.target.value),
    })
  }

  updateSectionWidgetPair() {
    const objSection = new SectionApi()

    const sectionDetailId = this.props.data.id
    const widget = this.state.selectedCollectionId
    const name = this.props.data.name
    const sectionId = this.props.data.section

    objSection
      .updateSectionDetailWidgetPair(sectionDetailId, sectionId, name, widget)
      .then((res) => {
        swal("Success !", "You successfully updated Section Widget pair ", "success")
      })
      .catch((err) => {
        const objAlert = new AlertHelper()
        const resErr = err?.response?.data ? err?.response?.data : err.message
        //console.log(" Error => ",err?.response?.data)
        objAlert.errorHandlerAlert(resErr)
      })
  }

  render() {
    let ElementPad, AssetAdd
    let widgetId = this.props.data.widget
    let options = this.props.collectionsList.map((el) => {
      if (el.id === parseInt(widgetId)) {
        return (
          <option value={el.id} selected>
            {el.name}
          </option>
        )
      } else {
        return <option value={el.id}>{el.name}</option>
      }
    })
    ElementPad = (
      <div>
        <label htmlFor='pairedCollections'>Selected collection:</label>
        <select id='pairedCollections' className='form-control' style={{}} onChange={this.setSelectedCollectionId}>
          {options}
        </select>
      </div>
    )
    AssetAdd = (
      <Button className='float-right mt-3' onClick={this.updateSectionWidgetPair}>
        <i className='fa fa-edit'>&nbsp;</i>
        Update Collection
      </Button>
    )
    return (
      <div className='row'>
        <div className='col-md-6'>
          {ElementPad}
          {AssetAdd}
        </div>
      </div>
    )
  }
}

export default ArticleFormExternal
