import React, { Component } from "react";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import { GoogleMap, Autocomplete, Marker } from "@react-google-maps/api";
// import Geocode from "react-geocode";

import swal from "sweetalert";
import AlertHelper from "./../../lib/AlertHelper";
import GeneralApi from "./../../api/GeneralApi";
import JobApi from "./../../api/JobApi";
import LocationApi from "./../../api/LocationApi";
import { withTranslation } from "react-i18next";

import "./LocationModal.css";

class LocationModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // gMapApiKey: "AIzaSyA6aQOcCYM5yXimMPDdTCopJD9wT0324L8",
            gMapApiKey: "AIzaSyCigRL-AQS62AdkaUj42rp9OX_5Mbze8PM",
            // gMapApiKey: 'AIzaSyCo2mDUObZFhtBXJg_QTEGbcMAyvn4zKkY',
            queryLocation: "",
            mapsLib: ["drawing", "places"],
            currentLatLng: {
                lat: 0,
                lng: 0,
            },
            defaultCenter: {
                lat: -6.1741,
                lng: 106.8296,
            },
            bounds: null,
            isMarkerShown: false,
            languageList: [],
            locationLanguage: [],
            companies: [],
            names: [],
            nameNoLang: "",
            address: [],
            addressNoLang: "",
            website: [],
            websiteNoLang: "",

            hasLanguage: false,
            tabKey: 1,
            selectedLanguage: 1,

            // update Loc
            locationLoaded: false,

            transPage: "modals.location_add_new.",
            transAlert: "alert.",
        };
        this.myMap = React.createRef();

        this.onOpenModal = this.onOpenModal.bind(this);

        this.autocomplete = null;

        this.onLoad = this.onLoad.bind(this);
        this.onPlaceChanged = this.onPlaceChanged.bind(this);

        this.handleChangeAddress = this.handleChangeAddress.bind(this);
        this.handleChangeWebsite = this.handleChangeWebsite.bind(this);
        this.handleHasLanguage = this.handleHasLanguage.bind(this);
        // this.handleLocationChange = this.handleLocationChange.bind(this)
        this.renderTabLanguage = this.renderTabLanguage.bind(this);
        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.handleChangeCompany = this.handleChangeCompany.bind(this);

        this.addNewLocation = this.addNewLocation.bind(this);
        this.getDetailLocation = this.getDetailLocation.bind(this);
        this.getCompanies = this.getCompanies.bind(this);
    }

    componentDidMount() {
        this.showCurrentLocation();
    }

    getLanguages() {
        const objLang = new GeneralApi();

        return objLang.getLanguages();
    }

    getCompanies() {
        const obJob = new JobApi();

        obJob
            .getCompanies({
                max_size: true,
            })
            .then((res) => {
                this.setState({
                    companies: res.data.results,
                });
            })
            .catch((err) => { });
    }

    onOpenModal() {
        this.getCompanies();
        this.getLanguages().then((res) => {
            this.setState(
                {
                    id: this.props.positionId | 0,
                    tabKey: 1,
                    selectedLanguage: 1,
                    languageList: res.data.results,
                },
                () => {
                    // this.setState({
                    //     address:""
                    // })
                    // if(this.props.locationId) {
                    //     this.getDetailLocation()
                    // } else {
                    //     this.renderTabLanguage()
                    // }
                }
            );
        });
    }

    showCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState((prevState) => ({
                    currentLatLng: {
                        ...prevState.currentLatLng,
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    },
                    isMarkerShown: true,
                }));
            });
        } else {
            console.log("error");
        }
    };

    onLoad(autocomplete) {
        console.log("autocomplete: ", autocomplete);

        this.autocomplete = autocomplete;
    }

    onPlaceChanged() {
        if (this.autocomplete !== null) {
            let location = this.autocomplete.getPlace();
            this.setState((prevState) => ({
                currentLatLng: {
                    ...prevState.currentLatLng,
                    lat: location.geometry.location.lat(),
                    lng: location.geometry.location.lng(),
                },
                addressNoLang: location.formatted_address,
                isMarkerShown: true,
                address: [
                    { address: location.formatted_address },
                    { address: location.formatted_address },
                ],
            }));
            console.log(location);
        } else {
            console.log("Autocomplete is not loaded yet!");
        }
    }

    showCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState((prevState) => ({
                    currentLatLng: {
                        ...prevState.currentLatLng,
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    },
                    isMarkerShown: true,
                }));
            });
        } else {
            console.log("error");
        }
    };

    addNewLocation() {
        const location_languages = this.state.languageList.map((item, index) => {
            return {
                language: item.id,
                location_type: this.state.names[index]
                    ? this.state.names[index].name
                    : "-",
                address: this.state.address[index]
                    ? this.state.address[index].address
                    : "",
                website: this.state.website[index]
                    ? this.state.website[index].website
                    : "",
            };
        });

        const newLocation = {
            company: this.state.selectedCompany,
            location_type: this.state.nameNoLang ? this.state.nameNoLang : "-", //location_type is NAME        
            latitude: this.state.currentLatLng?.lat,
            longitude: this.state.currentLatLng?.lng,
            description: document.getElementById("m-loc-description").value || "-",
            email: document.getElementById("m-loc-email").value || "-",
            phone: document.getElementById("m-loc-phone").value || "-",
            address: this.state.addressNoLang || "-",
            website: this.state.websiteNoLang || "-",
            location_languages: location_languages || "-",
        };
        this.props.addNewLocation(newLocation);
    }

    getDetailLocation(locationId) {
        const obj = new LocationApi();

        obj
            .getDetailLocation(locationId)
            .then((res) => {
                // console.log(" getDetailLocation ===> ", res.data)
                this.setState(
                    {
                        address: res.data.address,
                        currentLatLng: {
                            lat: parseFloat(res.data.latitude),
                            lng: parseFloat(res.data.longitude),
                        },
                        // locationType: res.data.location_type,
                        // longitude: res.data.longitude,
                        // latitude: res.data.latitude,
                        // selectedCompany: res.data.company,
                        // selectedCountry: res.data.country,
                        // locationLanguages: res.data.location_languages
                        locationLoaded: true,
                    },
                    () => {
                        this.renderTabLanguage();
                    }
                );
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    handleChangeNames(index, value) {
        let names = [...this.state.names];
        let new_names = { ...names[index] };
        new_names.name = value;
        names[index] = new_names;

        this.setState(
            {
                names: names,
            },
            () => { }
        );
    }

    handleChangeNameNoLang(value) {
        this.setState({
            nameNoLang: value,
        });
    }

    handleChangeAddress(index, value) {
        let location_address = [...this.state.address];
        let newlocation_address = { ...location_address[index] };
        newlocation_address.address = value;
        location_address[index] = newlocation_address;

        this.setState(
            {
                // oldaddress: newlocation_address[0].address,
                address: location_address,
            },
            () => { }
        );
    }

    handleChangeAddressNoLang(value) {
        this.setState({
            addressNoLang: value,
        });
    }

    handleChangeWebsite(index, value) {
        let location_website = [...this.state.website];
        let newlocation_website = { ...location_website[index] };
        newlocation_website.website = value;
        location_website[index] = newlocation_website;

        this.setState(
            {
                website: location_website,
            },
            () => { }
        );
    }

    handleChangeWebsiteNoLang(value) {
        this.setState({
            websiteNoLang: value,
        });
    }

    handleChangeTab(value) {
        this.setState(
            {
                selectedLanguage: value,
                tabKey: value,
            },
            () => {
                this.renderTabLanguage();
            }
        );
    }

    handleChangeCompany(value) {
        this.setState({
            selectedCompany: value,
        });
    }

    handleHasLanguage() {
        this.setState({
            hasLanguage: !this.state.hasLanguage,
        });
    }

    renderTabLanguage() {
        const renderTabLanguage = this.state.languageList.map((item) => {
            return {
                language: item.id,
                languageName: item.name,
                description: "",
            };
        });

        this.setState({
            locationLanguage: renderTabLanguage,
        });
    }

    render() {
        const { t } = this.props;

        const containerStyle = {
            width: "100%",
            height: "40vh",
        };

        let renderTabLanguage;
        if (this.state.hasLanguage) {
            if (this.state.languageList) {
                let tabLanguage;
                tabLanguage = this.state.languageList.map((item, index) => {
                    return (
                        <Tab key={index} eventKey={index + 1} title={item.name}>
                            <br></br>

                            <div className="form-group">
                                <label>
                                    {t(this.state.transPage + "name")}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={this.state.names[index]?.name}
                                    onChange={(event) =>
                                        this.handleChangeNames(index, event.target.value)
                                    }
                                ></input>
                            </div>
                            <div className="form-group">
                                <label> {t("address")} </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.address[index]?.address}
                                    onChange={(event) =>
                                        this.handleChangeAddress(index, event.target.value)
                                    }
                                ></input>
                            </div>
                            <div className="form-group mt-2">
                                <label>{t(this.state.transPage + "website")}</label>
                                <input
                                    type="text"
                                    name="asset_name"
                                    className="form-control"
                                    value={this.state.website[index]?.website}
                                    onChange={(event) =>
                                        this.handleChangeWebsite(index, event.target.value)
                                    }
                                ></input>
                            </div>
                        </Tab>
                    );
                });

                renderTabLanguage = (
                    <div className="form-group">
                        <Tabs
                            id="controlled-tab-example-3"
                            activeKey={this.state.tabKey}
                            onSelect={(k) => this.handleChangeTab(k)}
                        >
                            {tabLanguage}
                        </Tabs>
                    </div>
                );
            }
        } else {
            renderTabLanguage = (
                <>
                    <div className="form-group">
                        <label> {t(this.state.transPage + "name")} </label>
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.nameNoLang}
                            onChange={(event) => this.handleChangeNameNoLang(event.target.value)}
                        ></input>
                    </div>
                    <div className="form-group">
                        <label> {t("address")} </label>
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.addressNoLang}
                            onChange={(event) =>
                                this.handleChangeAddressNoLang(event.target.value)
                            }
                        ></input>
                    </div>
                    <div className="form-group mt-2">
                        <label for="m-loc-name">
                            {t(this.state.transPage + "website")}
                        </label>
                        <input
                            id="m-loc-name"
                            type="text"
                            name="asset_name"
                            className="form-control"
                            value={this.state.websiteNoLang}
                            onChange={(event) =>
                                this.handleChangeWebsiteNoLang(event.target.value)
                            }
                        ></input>
                    </div>
                </>
            );
        }

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                onShow={this.onOpenModal}
            >
                <Modal.Header closeButton className="bg-primary">
                    <Modal.Title> Location </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <GoogleMap
                        ref={this.myMap}
                        id="g-maps"
                        mapContainerStyle={containerStyle}
                        center={
                            this.state.locationLoaded
                                ? this.state.currentLatLng
                                : this.state.isMarkerShown
                                    ? this.state.currentLatLng
                                    : this.state.defaultCenter
                        }
                        zoom={17}
                        mapTypeId="roadmap"
                    >
                        <Autocomplete
                            onLoad={this.onLoad}
                            onPlaceChanged={this.onPlaceChanged}
                        // types={['(regions)']} only region showing
                        >
                            {/* <input type="text" id="m-tx-autocomplete" defaultValue={''} className="form-control" placeholder="Location" onChange={(event) => this.handleLocationChange(event.target.value)}></input> */}
                            <input
                                type="text"
                                placeholder="search here"
                                style={{
                                    boxSizing: `border-box`,
                                    border: `1px solid transparent`,
                                    width: `50%`,
                                    // height: `32px`,
                                    height: `2.5rem`,
                                    padding: `0 12px`,
                                    borderRadius: `3px`,
                                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                    fontSize: `14px`,
                                    outline: `none`,
                                    textOverflow: `ellipses`,
                                    position: "absolute",
                                    left: "25%",
                                    // marginLeft: "-120px"
                                    top: ".6rem",
                                }}
                            />
                        </Autocomplete>
                        <Marker
                            // onLoad={onLoad}
                            position={
                                this.state.isMarkerShown
                                    ? this.state.currentLatLng
                                    : this.state.defaultCenter
                            }
                        />
                    </GoogleMap>

                    <div className="form-group mt-3">
                        <input
                            type="checkbox"
                            id="check-has-language"
                            checked={this.state.hasLanguage}
                            onClick={() => this.handleHasLanguage()}
                        ></input>{" "}
                            &nbsp;
                            <label for="check-has-language">
                            {" "}
                            {t(this.state.transPage + "has_language")}{" "}
                        </label>
                    </div>

                    <div className="form-group">
                        <label for="m-loc-company">
                            {t(this.state.transPage + "company_name")}
                        </label>
                        <select
                            id="m-loc-company"
                            value={this.state.selectedCompany}
                            className="form-control"
                            onChange={(event) => this.handleChangeCompany(event.target.value)}
                        >
                            <option> -- Please Select Company -- </option>
                            {this.state.companies.map((item) => {
                                return (
                                    <option value={item.id} key={item.id}>
                                        {" "}
                                        {item.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                    <div className="form-group ">
                        <label for="m-loc-description">
                            {t(this.state.transPage + "description")}
                        </label>
                        <input
                            id="m-loc-description"
                            type="text"
                            name="asset_name"
                            className="form-control"
                            defaultValue={""}
                        ></input>
                    </div>
                    <div className="form-group ">
                        <label for="m-loc-email">{t(this.state.transPage + "email")}</label>
                        <input
                            id="m-loc-email"
                            type="email"
                            name="asset_name"
                            className="form-control"
                            defaultValue={""}
                        ></input>
                    </div>
                    <div className="form-group ">
                        <label for="m-loc-phone">{t(this.state.transPage + "phone")}</label>
                        <input
                            id="m-loc-phone"
                            type="text"
                            name="asset_name"
                            className="form-control"
                            defaultValue={""}
                        ></input>
                    </div>

                    {renderTabLanguage}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={this.props.onHide}>
                        <i className="fa fa-times">&nbsp;</i>
                        {t(this.state.transPage + "cancel")}
                    </Button>
                    <Button variant="primary" onClick={() => this.addNewLocation()}>
                        <i className="fa fa-plus">&nbsp;</i>
                        {t(this.state.transPage + "add_new_location")}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(LocationModal);
