import React, { Component } from 'react';
import css from "./AdvancedArticle.module.css"
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";

import defaultImage from "../Assets/default-image.svg";

class AdvancedArticle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            contents: [
                {
                    type: "text",
                    selectedAssets: [],
                    textContent: "",
                    options: {
                        imageSize: "regular",
                    },
                }
            ],

            articleAssets: []
        }

        this.handleChangeOptions = this.handleChangeOptions.bind(this)
        this.handleChangeType = this.handleChangeType.bind(this)
        this.handleSelectedAsset = this.handleSelectedAsset.bind(this)
        this.addContentSection = this.addContentSection.bind(this)
        this.generateHtml = this.generateHtml.bind(this)
    }

    defaultContent = {
        type: "text",
        selectedAssets: [],
        textContent: "",
        options: {
            imageSize: "regular",
        },
    }

    componentDidMount() {
        const { langIdx } = this.props
        const parsedContentSection = JSON.parse(this.props.articleLanguages[langIdx].content_section || null)

        if (Array.isArray(parsedContentSection)) {
            this.setState({ contents: parsedContentSection, isLoaded: true })
        } else {
            this.setState({ isLoaded: true })
        }

        const {articleAssets} = this.state
        if (Array.isArray(articleAssets)){
            this.setState({
                articleAssets: articleAssets
            })
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevState.contents) !== JSON.stringify(this.state.contents)) {
            this.props.handleChangeContent(this.props.langIdx, this.generateHtml(), () => {
                this.props.handleChangeContentSection(this.props.langIdx, JSON.stringify(this.state.contents))
            })
        }
        if (this.state.articleAssets !== this.props.articleAssets){
            this.setState({
                articleAssets: this.props.articleAssets
            })
        }
    }

    handleChangeOptions(value, option, idx) {
        let newContents = JSON.parse(JSON.stringify(this.state.contents))
        newContents[idx].options[option] = value

        this.setState({ contents: newContents })
    }

    handleChangeType(value, idx) {
        let newContents = JSON.parse(JSON.stringify(this.state.contents))
        newContents[idx].type = value
        newContents[idx].selectedAssets = []

        this.setState({ contents: newContents })
    }

    handleTextContent(value, idx) {
        let newContents = JSON.parse(JSON.stringify(this.state.contents))
        newContents[idx].textContent = value

        this.setState({ contents: newContents })
    }

    handleSelectedAsset(value, assetId, contentIdx) {
        let newContents = JSON.parse(JSON.stringify(this.state.contents))
        let setSelectedAssets = new Set(newContents[contentIdx].selectedAssets)

        if (value && setSelectedAssets.size >= 4) {
            return
        }

        if (value) {
            setSelectedAssets.add(assetId)
        } else {
            setSelectedAssets.delete(assetId)
        }

        newContents[contentIdx].selectedAssets = [...setSelectedAssets]
        this.setState({ contents: newContents })
    }

    addContentSection() {
        let newContents = JSON.parse(JSON.stringify(this.state.contents))
        newContents.push(this.defaultContent)

        this.setState({ contents: newContents })
    }

    deleteContentSection(idx) {
        let newContents = JSON.parse(JSON.stringify(this.state.contents))
        newContents.splice(idx, 1)

        this.setState({ contents: newContents })
    }

    generateHtml() {
        const { contents } = this.state;
        const { articleAssets } = this.props;
        const page = this.props.pageActive

        const objArticleAssets = {}
        articleAssets.forEach(asset => {
            if (page === "add"){
                objArticleAssets[asset.id] = asset
            } else if (page === "update") {
                objArticleAssets[asset.asset] = asset
            }
        })

        let strHtml = ""
        contents.forEach((content, idx) => {
            if (content.type === "text") {
                const paragraphText = content.textContent
                strHtml += paragraphText
            } else if (content.type === "image") {
                let strImages = ""

                const imageSize = content.options.imageSize
                let styleSize = ""
                let classes = ""
                let parentStyle = ""
                if (imageSize === "regular") {
                    styleSize = "width: calc(50% - 10px); margin: 8px;"
                    classes = "regular-width"
                    parentStyle = "display:flex; flex-wrap:wrap; justify-content: center;"
                } else if (imageSize === "full-width") {
                    styleSize = "width: 100%;"
                    classes = "full-width"
                    parentStyle = "position:relative; width:100%; height:100vh; display:flex; flex-wrap:wrap; justify-content: center; margin-bottom: 16px;"
                }

                content.selectedAssets.forEach(assetId => {
                    const asset = objArticleAssets[assetId]
                    strImages += `<img class="${classes}" style="object-fit: cover; ${styleSize}" src="${asset?.file_medium}" alt="${asset?.description || ''}">`
                })

                const captionText = content.textContent

                strHtml += `<div style="${parentStyle}">
                    ${strImages}
                </div>${captionText ? `<div style="text-align: center; margin-bottom: 16px">${captionText}</div>` : ""}`
            } else if (content.type === "video") {
                let strVideos = ""
                content.selectedAssets.forEach(assetId => {
                    // console.log('objArticleAssets', objArticleAssets)
                    // console.log('objArticleAssets', objArticleAssets[1])
                    const asset = objArticleAssets[assetId]
                    // const ass = Array(objArticleAssets).filter(([key, value]) => asset === assetId)
                    
                    console.log('assetId', assetId)
                    console.log('asset', asset)
                   
                    strVideos += `<video style="margin: 8px; width: calc(50% - 10px);" controls><source src="${asset?.file || asset?.asset_languages[0]?.asset_file || asset?.asset_languages[1]?.asset_file || asset?.asset_thumbnail}">Your browser does not support the video tag.</video>`
                })

                strHtml += `<div style="display:flex; flex-wrap:wrap; justify-content: center;">${strVideos}</div>`
            } else if (content.type === "link") {
                if (content.textContent.indexOf("https://www.youtube.com/watch") !== -1) {
                    const linkYoutube = new URL(content.textContent)
                    const videoId = linkYoutube.searchParams.get("v")

                    strHtml += `<div style="display:flex; flex-wrap:wrap; justify-content: center;">
                        <iframe style="margin: 5px; width: calc(50% - 10px); height: 400px" src="https://www.youtube.com/embed/${videoId}"></iframe>
                    </div>`
                }
            }
        })

        return strHtml
    }

    render() {
        const { langIdx } = this.props
        let ckeditorConfig = {
            toolbar: {
                items: ["heading", "|", "bold", "italic", "|", "superscript", "subscript", "|", "link", "|", "bulletedList", "numberedList", "|", "insertTable", "|", "undo", "redo"],
            },
            table: {
                contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
            },
            language: "",
        };

        if (langIdx === 1) {
            ckeditorConfig.language = "vi";
        } else {
            ckeditorConfig.language = "en";
        }

        const page = this.props.pageActive

        return (
            <div>
                {
                    this.state.contents.map((item, idx) => (
                        <>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                    {
                                        ["Text", "Image", "Video", "Link"].map(contentType => {
                                            const lcContentType = contentType.toLowerCase()
                                            return (
                                                <div className="mr-3">
                                                    <input
                                                        className="mr-1"
                                                        id={`content-${idx}-${lcContentType}-${langIdx}`}
                                                        type="radio"
                                                        name={`content-${idx}-${langIdx}`}
                                                        checked={item.type === lcContentType}
                                                        value={lcContentType}
                                                        onChange={(e) => { this.handleChangeType(e.target.value, idx) }}
                                                    />
                                                    <label htmlFor={`content-${idx}-${lcContentType}-${langIdx}`}>{contentType}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div>
                                    <button
                                        className="btn btn-sm btn-danger"
                                        disabled={this.state.contents.length <= 1}
                                        onClick={() => { this.deleteContentSection(idx) }}
                                    >
                                        <i class="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>

                            <div className="mb-3">
                                {
                                    item.type === "text" && 
                                    this.state.isLoaded &&
                                    <>
                                        <CKEditor
                                            config={ckeditorConfig}
                                            editor={ClassicEditor}
                                            data={this.state.contents[idx].textContent}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();

                                                this.handleTextContent(data, idx)
                                            }}
                                        />
                                    </>
                                }
                                {
                                    item.type === "image" &&
                                    <>
                                        <div className="form-inline mb-1">
                                            <label className="mr-2">Size:</label>
                                            <select
                                                className="form-control"
                                                onChange={(e) => { this.handleChangeOptions(e.target.value, "imageSize", idx) }}
                                                value={this.state.contents[idx].options.imageSize}
                                            >
                                                <option value="regular">Regular</option>
                                                <option value="full-width">Full width</option>
                                            </select>
                                        </div>
                                        <ul className={css.ul}>
                                            {
                                                this.state.articleAssets?.map((asset, assetIdx) =>
                                                        asset.asset_type_name === "Image" || asset.asset_type === "Image" ?
                                                            <li className={css.li}>
                                                                <input
                                                                    className={css.inputCb}
                                                                    type="checkbox"
                                                                    id={`asset-image-${langIdx}-${idx}-${assetIdx}`}
                                                                    // onChange={(e) => { this.handleSelectedAsset(e.target.checked, asset.id, idx) }}
                                                                    onChange={(e) => { page === 'add' ? this.handleSelectedAsset(e.target.checked, asset.id, idx) : this.handleSelectedAsset(e.target.checked, asset.asset, idx) }}
                                                                    // checked={item.selectedAssets.includes(asset.id) || item.selectedAssets.includes(asset.asset)}
                                                                    checked={page === 'add' ? item.selectedAssets.includes(asset.id) : item.selectedAssets.includes(asset.asset)}
                                                                />
                                                                {/* <div className="w-25">
                                                                <pre>
                                                                    {JSON.stringify(asset)}
                                                                    {JSON.stringify(item)}
                                                                </pre>
                                                                </div> */}

                                                                <label
                                                                    className={css.label}
                                                                    htmlFor={`asset-image-${langIdx}-${idx}-${assetIdx}`}
                                                                >
                                                                    <img src={asset.file_small} alt={asset.description || ""} />
                                                                </label>
                                                            </li> : <></>)
                                            }
                                        </ul>
                                        <input
                                            className="form-control"
                                            placeholder="Caption"
                                            type="text"
                                            value={item.textContent}
                                            onChange={(e) => { this.handleTextContent(e.target.value, idx) }}
                                        />
                                    </>
                                }
                                {
                                    item.type === "video" &&
                                    <ul className={css.ul}>
                                        {
                                            this.props.articleAssets
                                                .map((asset, assetIdx) =>
                                                    asset.asset_type_name === "Video" || asset.asset_type === "Video" ?
                                                        <li className={css.li}>
                                                            <input
                                                                className={css.inputCb}
                                                                type="checkbox"
                                                                id={`asset-video-${langIdx}-${idx}-${assetIdx}`}
                                                                // onChange={(e) => { this.handleSelectedAsset(e.target.checked, asset.asset, idx) }}
                                                                onChange={(e) => { page === 'add' ? this.handleSelectedAsset(e.target.checked, asset.id, idx) : this.handleSelectedAsset(e.target.checked, asset.asset, idx) }}

                                                                // checked={item.selectedAssets.includes(asset.asset)}
                                                                checked={page === 'add' ? item.selectedAssets.includes(asset.id) : item.selectedAssets.includes(asset.asset)}

                                                            />
                                                            <label
                                                                className={css.label}
                                                                htmlFor={`asset-video-${langIdx}-${idx}-${assetIdx}`}
                                                            >
                                                                {
                                                                    (asset?.file || asset?.asset_languages[0]?.asset_file || asset?.asset_languages[1]?.asset_file) ? 
                                                                    <>
                                                                    <video style={{margin: '8px', width: '200px'}} controls><source src={asset?.file || asset?.asset_languages[0]?.asset_file || asset?.asset_languages[1]?.asset_file || asset?.asset_thumbnail} />Your browser does not support the video tag.</video>
                                                                    </> :
                                                                    <img src={asset.thumbnail_file || defaultImage} alt={asset.description || ""} />
                                                                }
                                                            </label>
                                                        </li> : <></>)
                                        }
                                    </ul>
                                }
                                {
                                    item.type === "link" &&
                                    <input
                                        className="form-control"
                                        placeholder="Link Youtube"
                                        type="text"
                                        value={item.textContent}
                                        onChange={(e) => { this.handleTextContent(e.target.value, idx) }}
                                    />
                                }
                            </div>
                        </>
                    ))
                }

                <div>
                    <button
                        className="btn btn-primary mt-2"
                        onClick={this.addContentSection}
                    >
                        <i className="fa fa-plus mr-1"></i>Add content section
                    </button>
                </div>
            </div>
        );
    }
}

export default AdvancedArticle;