import React, { Component } from "react";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Card, { CardHeader, CardTitle } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content";

import "./SectionDetail.css";

// import SectionFormHeader from './SectionForm/SectionFormHeader';
import AssetForm from "./SectionForm/AssetForm";
import TextCard from "./SectionForm/TextCard";
import ArticleCard from "./SectionForm/ArticleCard";
import TitlePages from "./Elements/TitlePages";

import SectionApi from "../api/SectionApi";
import { withTranslation } from "react-i18next";
import config from '../config/config'
import ReactGA from 'react-ga';
import Context from './Context'

class SectionDetail extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      // gMapApiKey: 'AIzaSyA6aQOcCYM5yXimMPDdTCopJD9wT0324L8',
      gMapApiKey: "AIzaSyCigRL-AQS62AdkaUj42rp9OX_5Mbze8PM",
      mapsLib: ["drawing", "places"],

      section: {
        section_widgets: [],
      },
      sectionId: false,
      transPage: "pages.section_detail.",
      innerwidth: "",
      innerwidthBig: "",
      isLoaded: {}
    };

    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.getGeneralSectionDetail = this.getGeneralSectionDetail.bind(this)
  }

  componentDidMount() {
    ReactGA.initialize(config.GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    let w = window.screen.width;

    if (this.props.match.params.sectionId) {
      if (w <= 1440) {
        this.setState({
          innerwidth: "15%",
          innerwidthBig: "50%",
        });
      } else if (w > 1440) {
        this.setState({
          innerwidth: "10%",
          innerwidthBig: "55%",
        });
      }

      this.setState({
        sectionId: this.props.match.params.sectionId
      }, () => {
        this.getSectionDetail();
      })

    }
  }

  getGeneralSectionDetail() {
    const { sectionDetail } = this.context
    const hasArticle = this.state.section?.section_widgets.filter(widget => widget.category_name === "Article")
    const hasText = this.state.section?.section_widgets.filter(widget => widget.category_name === "Text")

    if (hasArticle.length) {
      sectionDetail.methods.getAllWidget()
    }

    if ((hasArticle.length || hasText.length)) {
      sectionDetail.methods.getPages()
    }

    if (hasArticle.length) {
      sectionDetail.methods.getArticleCategories()
    }

    if (hasText.length) {
      sectionDetail.methods.getAllSections()
    }

    if (hasArticle.length) {
      sectionDetail.methods.getAllEmployees()
    }
  }

  getSectionDetail() {
    const objSection = new SectionApi();

    objSection
      .getDetailSection(this.state.sectionId)
      .then((res) => {
        this.setState({ section: res.data }, this.getGeneralSectionDetail);
      })
      .catch((err) => {
        // alert(err.message)
      });
  }

  render() {
    const { t } = this.props;

    return (
      <LoadScript googleMapsApiKey={this.state.gMapApiKey} libraries={this.state.mapsLib}>

        <Content>
          <ContentHeader className="content-header">
            {/* <h1 className="left-content"> Section Detail </h1> */}
            <TitlePages title={t(this.state.transPage + "title")} />
            <div className="right-content"></div>
            <div className="clearfix"></div>
          </ContentHeader>
          <ContentBody>
            <div className="row">
              <div
                style={{ width: this.state.innerwidthBig, marginRight: "1rem" }}
              >
                <Card>
                  <CardHeader>
                    <CardTitle>
                      <div style={{ width: 200, display: "inline-block" }}>
                        {t(this.state.transPage + "section_name")}
                      </div>
                      <div style={{ display: "inline-block" }}>:</div>
                      <div style={{ display: "inline-block" }}>
                        <b>
                          &nbsp;{this.state.section.id}. {this.state.section.name}
                        </b>
                      </div>
                    </CardTitle>
                  </CardHeader>
                </Card>
              </div>
              <div style={{ width: "30%", marginRight: "1rem" }}>
                <Card>
                  <CardHeader>
                    <CardTitle>
                      <div style={{ width: 100, display: "inline-block" }}>
                        {t("menu")}
                      </div>
                      <div style={{ display: "inline-block" }}>:</div>
                      <div style={{ display: "inline-block" }}>
                        <b>
                          &nbsp;
                        {this.state.section.menu
                            ? `${this.state.section.menu}. ${this.state.section.menu_name}`
                            : null}
                        </b>
                      </div>
                    </CardTitle>
                  </CardHeader>
                </Card>
              </div>
              <div style={{ width: this.state.innerwidth }}>
                <Card>
                  <CardHeader>
                    <CardTitle>
                      <div
                        onClick={() => this.props.history.goBack()}
                        style={{ cursor: "pointer" }}
                      // className="btn btn-default"
                      // style={{ width: "100%", height: "100%" }}
                      >
                        <i
                          className="fa fa-arrow-left"
                          style={{ marginRight: ".5rem" }}
                        >
                          {" "}
                        &nbsp;
                      </i>

                        {t("back")}
                      </div>
                    </CardTitle>
                  </CardHeader>
                </Card>

                {/* <Card>
                <CardHeader>
                  <CardTitle>
                    <Button
                      onClick={() => this.props.history.goBack()}
                      className="btn btn-default col-md-12 "
                      style={{ width: "100%" }}
                    >
                      <i className="fa fa-arrow-left">&nbsp;</i>
                      {t("back")}
                    </Button>
                  </CardTitle>
                </CardHeader>
              </Card> */}
              </div>
            </div>

            {this.state.section.section_widgets.map((item, index) => {
              //console.log(" item => ", item)
              if (item.category_name === "Text") {
                return (
                  <div key={index}>
                    <TextCard
                      section={this.state.section}
                      widget={item.widget}
                      widgetName={item.name}
                      widgetElementType={item.category_name}
                      widgetElementStatic="Static"
                      data={item}
                    ></TextCard>
                    <hr></hr>
                  </div>
                );
              } else if (item.category_name === "Article") {
                return (
                  <div key={index}>
                    <ArticleCard
                      section={this.state.section}
                      widget={item.widget}
                      widgetName={item.name}
                      widgetElementType={item.category_name}
                      widgetElementStatic="Static"
                      data={item}
                      getSectionDetail={this.getSectionDetail}
                    ></ArticleCard>

                    <hr></hr>
                  </div>
                );
              } else if (item.category_name === "Media") {
                return (
                  <div key={index}>
                    <AssetForm
                      section={this.state.section}
                      widget={item.widget}
                      widgetName={item.name}
                      widgetElementType={item.category_name}
                      widgetElementStatic="Static"
                      data={item}
                      getSectionDetail={this.getSectionDetail}
                    ></AssetForm>
                    <hr />
                  </div>
                );
              } else {
                return <div></div>;
              }
            })}
          </ContentBody>
        </Content>
      </LoadScript>

    );
  }
}

export default withTranslation()(SectionDetail);
