import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import classes from './NewMenuItem.module.css'
import iB from './assets/arrB.svg'
import iW from './assets/arrW.svg'

export default class NewMenuItem extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        let active = this.props.active ? classes.titleActive : classes.titleItem
        return (
            <div className={active}>
                <Link to={`/page/detail/${this.props.data.id}`} onClick={this.props.activePage}>
                    <div className={classes.accHead}>
                        <p className={classes.titleAccordion}>{this.props.data.name}</p>
                        <img className={classes.titleIcon} src={this.props.active ? iW : iB} alt="icon" />
                    </div>
                </Link>
            </div>
        )
    }
}
