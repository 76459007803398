import React, { Component } from 'react';
import { Button } from "react-bootstrap"
import swal from "sweetalert"
import Pagination from "react-js-pagination";

import Card, { CardHeader, CardTitle, CardBody, CardFooter } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content"
import ListCard from "./../components/ListCard"

import TitlePages from './Elements/TitlePages'
import WidgetAddNew from "./../components/Modal/WidgetAddNew"
import SectionWidgetDeleteConfirm from "./../components/Modal/SectionWidgetDeleteConfirm"

import WidgetApi from "./../api/WidgetApi"
import TemplateApi from "./../api/TemplateApi"
import { withTranslation } from 'react-i18next';
import UserContext from './Context'
import config from '../config/config'
import ReactGA from 'react-ga';


class WidgetsPage extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props)

        this.state = {
            section: {
                name: ""
            },
            widgets: [],
            templates: [],
            showModal: false,
            showModalDelete: false,
            disableDelete: true,
            selectedWidget: [],
            widgetId: 0,
            activeTemplate: 0,

            activePage: 1,
            limitPageData: 30,
            totalCount: 0,
            search: "",
            templateId: "",
            isCheckedGlobal: false,

            transPage: "pages.widgets.",
            transAlert: "alert."
        }

        this.getAllWidgets = this.getAllWidgets.bind(this)
        this.setWidgetId = this.setWidgetId.bind(this)
        this.setSelectedWidget = this.setSelectedWidget.bind(this)
        this.setShowModal = this.setShowModal.bind(this)
        this.setShowModalDelete = this.setShowModalDelete.bind(this)

        this.deleteSelectedWidget = this.deleteSelectedWidget.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.handleStateChange = this.handleStateChange.bind(this)
        // this.handlePageChange = this.handlePageChange.bind(this)
    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        const { activeTemplate } = this.context;

        this.getAllTemplates()

        this.setState({ activeTemplate: activeTemplate, templateId: activeTemplate, }, this.getAllWidgets)
    }

    componentDidUpdate() {
        const { activeTemplate } = this.context;

        if (activeTemplate !== this.state.activeTemplate) {
            this.setState({
                search: "",
                templateId: activeTemplate,
                activePage: 1,
                activeTemplate: activeTemplate
            }, () => {
                this.getAllWidgets()
                this.getAllTemplates()
            })
        }
    }

    setShowModal() {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    setShowModalDelete() {
        this.setState({
            showModalDelete: !this.state.showModalDelete
        })
    }

    setWidgetId(widgetId) {
        this.setState({
            widgetId: widgetId
        }, () => {
            this.setShowModal()
        })
    }

    getAllTemplates() {
        const templateApi = new TemplateApi();
        const activeSite = localStorage.getItem('siteId')

        templateApi
            .getSites(activeSite)
            .then(res => {
                const templates = res.data.templates;
                this.setState({
                    templates: templates
                })
            })
    }

    getAllWidgets() {
        const objWidget = new WidgetApi()

        objWidget.getAllWidgets({
            page_size: this.state.limitPageData,
            page: this.state.activePage,
            search: this.state.search,
            template: this.state.templateId,
            internal: this.state.isCheckedGlobal ? null : "True"
        })
            .then(res => {
                this.setState({
                    totalCount: res.data.count,
                    widgets: res.data.results
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    setSelectedWidget(itemData, value) {
        var lastSelected = [...this.state.selectedWidget]
        var indexDelete = -1

        if (value === true) {

            lastSelected.push(itemData)
        } else {
            //console.log("jalan donk harusnya ", article , this.state.selectedArticles)
            this.state["selectedWidget"].forEach((item, index) => {
                if (item.id === itemData.id) {
                    indexDelete = index
                }
            })

            lastSelected.splice(indexDelete, 1)
            //console.log("lastArticles => ",lastArticles)
        }

        this.setState({
            "selectedWidget": lastSelected,

        }, () => {
            if (this.state["selectedWidget"].length > 0) {
                this.setState({
                    disableDelete: false,

                })
            } else {
                this.setState({
                    disableDelete: true,

                })
            }
        })
    }

    deleteSelectedWidget() {
        const { t } = this.props;
        const objWidget = new WidgetApi()

        const widgets = this.state.selectedWidget.map(item => {
            return (item.id)
        })

        objWidget.widgetDelete(widgets)
            .then(res => {
                this.setState({
                    showModalDelete: false,
                    disableDelete: true,
                    selectedWidget: [],
                })

                swal("Success !", t(this.state.transAlert + "widget_delete_success"), "success")

                setTimeout(() => {
                    window.location.reload()
                }, 0)
            })
            .catch(err => {
                swal("Error!", err.message, "error")
            })
    }

    handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
        }, () => {
            this.getAllWidgets()
        });
    }

    handleSearchChange(value) {
        this.setState({
            activePage: 1,
            search: value
        }, () => {
            this.getAllWidgets()
        })
    }

    handleStateChange(event, stateName) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            activePage: 1,
            [stateName]: value
        }, () => {
            this.getAllWidgets()
        });
    }

    render() {
        const { t } = this.props;
        const WidgetList = this.state.widgets.map(item => {

            var widgetFormatData = []
            if (item.category_name === "Article") {
                widgetFormatData.push(item.category_name)
            }
            if (item.category_name === "Media") {
                widgetFormatData.push(item.category_name)
            }
            if (item.category_name === "Text") {
                widgetFormatData.push(item.category_name)
            }

            return (
                <div className="col-md-2" key={item.id}>
                    <ListCard
                        data={{
                            name: item.name,
                            id: item.id,
                            category_name: item.category_name,
                            from: "widget-page",
                            internal: item.is_internal
                        }}
                        tools={true}
                        type={"element"}
                        items={widgetFormatData}
                        linkTo={`/widget/detail/${item.id}`}
                        setSelectedItem={this.setSelectedWidget}
                        setItemId={this.setWidgetId}
                    ></ListCard>
                </div>

            )
        })

        return (
            <Content>
                <ContentHeader>
                    {/* <h1> Widgets </h1> */}
                    <TitlePages title={t(this.state.transPage + 'title')} />
                </ContentHeader>
                <ContentBody>
                    <Card>
                        <CardHeader>
                            <CardTitle></CardTitle>
                            <div className="card-tools">
                                <Button
                                    onClick={() => this.setShowModalDelete()}
                                    disabled={this.state.disableDelete}
                                    variant="danger" style={{ marginRight: 5 }}>
                                    <i className="fa fa-trash"></i> {t('delete_selected')}
                                </Button>
                                <Button
                                    onClick={() => {
                                        this.setWidgetId(0)
                                    }}
                                >
                                    <i className="fa fa-plus"></i> {t(this.state.transPage + 'add_widget')}
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div style={{ float: "left" }}>
                                <div className="form-inline">
                                    <input
                                        type="text"
                                        placeholder={t(this.state.transPage + 'search_widget')}
                                        className="form-control"
                                        name="search"
                                        value={this.state.search}
                                        onChange={(event) => this.handleSearchChange(event.target.value)}
                                    />

                                    <div className="ml-4 form-inline">
                                        <label htmlFor="filter-template">{t('template')}: &nbsp;</label>
                                        <select
                                            className="form-control d-inline-block"
                                            id="filter-template"
                                            onChange={(e) => this.handleStateChange(e, "templateId")}
                                            value={this.state.templateId}
                                        >
                                            {
                                                this.state.templates.map(template => {
                                                    return (
                                                        <option
                                                            key={template.id}
                                                            value={template.id}
                                                        >
                                                            {template.name}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div className="ml-4 form-inline">
                                        <input
                                            id="show-global-widget"
                                            type="checkbox"
                                            checked={this.state.isCheckedGlobal}
                                            onChange={(e) => this.handleStateChange(e, "isCheckedGlobal")}
                                        />
                                        <label
                                            className="ml-1"
                                            htmlFor="show-global-widget"
                                        >
                                            {t('show_global_widget')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div style={{ float: "right" }}>
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.limitPageData}
                                    totalItemsCount={this.state.totalCount}
                                    pageRangeDisplayed={4}
                                    onChange={this.handlePageChange.bind(this)}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </div>
                            <div className="clearfix"></div>

                            <div className="row">
                                {WidgetList}
                            </div>

                            <div style={{ float: "right" }}>
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.limitPageData}
                                    totalItemsCount={this.state.totalCount}
                                    pageRangeDisplayed={4}
                                    onChange={this.handlePageChange.bind(this)}
                                    itemClass="page-item"
                                    linkClass="page-link"

                                />
                            </div>
                            <div className="clearfix"></div>
                        </CardBody>
                        <CardFooter>

                        </CardFooter>
                    </Card>
                </ContentBody>
                <WidgetAddNew
                    widgetId={this.state.widgetId}
                    section={this.state.section}
                    show={this.state.showModal}
                    onHide={this.setShowModal}

                    selectedWidget={this.state.selectedWidget}
                    getAllWidgets={this.getAllWidgets}

                ></WidgetAddNew>
                <SectionWidgetDeleteConfirm

                    show={this.state.showModalDelete}
                    onHide={this.setShowModalDelete}
                    //getSectionDetail={this.getSectionDetail}
                    selectedWidget={this.state.selectedWidget}
                    deleteSelectedWidget={this.deleteSelectedWidget}
                ></SectionWidgetDeleteConfirm>
            </Content>
        );
    }
}

export default withTranslation()(WidgetsPage);