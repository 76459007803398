import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Tab, Nav, Col, Row, Collapse } from "react-bootstrap"
import Card, { CardHeader, CardTitle, CardBody, CardFooter } from "../components/Card";
import DashboardNew from '../components/section/DashboardNew';
import Box from '../components/Box/Box'
import { Content, ContentHeader, ContentBody } from "./../components/ContentNew"

import classes from './TestSidebarPage.module.css'

class TestSidebarPage extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    toggleActive = () => {
        this.setState((prevState) => {
            return {
                isCollapse: !prevState.isCollapse,
            };
        })
    }

    render() {
        return (
            <>
                <DashboardNew>
                    {/* <Content>
                        <ContentHeader>
                            <h1 onClick={this.showSidebar}>Test </h1>
                        </ContentHeader>
                        <div className={classes.contentPage}>
                            <div className={classes.boxToogle} onClick={this.toggleActive} aria-controls="page-collapse" aria-expanded={this.state.isCollapse}>
                                <span>Home Slider</span>
                                <FontAwesomeIcon color="#F1F1F1" className={classes.iconUp} icon={this.state.isCollapse ? faChevronUp : faChevronDown} size="lg" />
                                <FontAwesomeIcon color="#00599D" className={[classes.iconList, classes.iconListW].join(' ')} icon={faEllipsisV} size="lg" />
                            </div>
                            <Collapse in={this.state.isCollapse} className={classes.pageCollapse}>
                                <div id="page-collapse">
                                    <Box />
                                </div>
                            </Collapse>
                        </div>
                        <Card>
                            <CardHeader>
                                <CardTitle> Test Page </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Row>
                                        <Col>
                                            <Nav variant="pills" className="">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first">Tab 1</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second">Tab 2</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    Tab First
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    Tab Second
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </CardBody>
                            <CardFooter>
                                <p> This is Footer </p>
                            </CardFooter>
                        </Card>

                    </Content> */}
                </DashboardNew>
            </>

        );
    }
}

export default TestSidebarPage;