import React, { Component } from "react";
import swal from "sweetalert";
import TitlePages from "./Elements/TitlePages";
import { Content, ContentHeader, ContentBody } from "./../components/Content";
import MultipleDropdownButton from "../components/Dropdown/MultipleDropdownButton"
import Card, { CardHeader, CardBody } from "../components/Card";

import { withTranslation } from 'react-i18next';
import UserContext from './Context';
import config from '../config/config'
import ReactGA from 'react-ga';
import SiteApi from '../api/SiteApi'

import './LanguagePage.css'

class LanguagePage extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            sites: [],
            activeSite: false,
            transAlert: "alert.",
            transPage: "pages.language_settings.",
        };
        this.getSites = this.getSites.bind(this)
        this.handleChangeSites = this.handleChangeSites.bind(this)
        this.getLanguageUsed = this.getLanguageUsed.bind(this);
        this.updateLanguageUsed = this.updateLanguageUsed.bind(this);
    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        this.getSites()
    }

    
    getSites() {
        const objSite = new SiteApi()

        objSite.getAllSites().then((res) => {
            let siteId = localStorage.getItem('siteId')
            this.setState({
                sites: res.data.results,
                activeSite: siteId
            })
        })
    }

    handleChangeSites(value) {
        
    }

    getLanguageUsed() {

    }

    updateLanguageUsed() {

    }

    render() {
        const { t } = this.props;

        return (
            <Content>
                <ContentHeader>
                    <TitlePages title={t(this.state.transPage + "title")} />
                </ContentHeader>
                <ContentBody>
                    <Card style={{ padding: '1rem' }}>
                        <label className="mb-3">Set language</label>
                        <div className="w-50 p-0 mb-3">
                            <span>Website</span>
                            <select className="form-control nav-L-sel-c mt-2" value={this.state.activeSite} onChange={(event) => { this.handleChangeSites(event.target.value) }}>
                                {/* <option disabled value=""> -- {t(this.state.transPage + "select_site")} -- </option> */}
                                {
                                    this.state.sites.map(item => {
                                        return (<option value={item.id}> {item.name} </option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="w-100 row">
                            <div className="w-50">
                                <div className="row">
                                    <div className="w-60 mb-2">
                                        <span>Website Languages</span>
                                    </div>
                                    <div className="w-40 mb-2">
                                        <span>Set Active Language</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="w-60 title-lg">
                                        <input type="text" className="form-control input-title-lg" defaultValue="English" />
                                    </div>
                                    <div className="w-40 d-flex content-lg">
                                        <div className="d-flex m-auto">
                                            <span>Active</span>
                                            <label className="switchCk" for="checkbox">
                                                <input type="checkbox" id="checkbox" />
                                                <div className="sliderCk round"></div>
                                            </label>
                                            <span>Inactive</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="w-60 title-lg">
                                        <input type="text" className="form-control input-title-lg" defaultValue="Vietnamese" />
                                    </div>
                                    <div className="w-40 d-flex content-lg">
                                        <div className="d-flex m-auto">
                                            <span>Active</span>
                                            <label className="switchCk" for="checkbox">
                                                <input type="checkbox" id="checkbox" />
                                                <div className="sliderCk round"></div>
                                            </label>
                                            <span>Inactive</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-50 ml-auto">
                                <div className="row">
                                    <div className="w-60 mb-2">
                                        <span>Website Languages</span>
                                    </div>
                                    <div className="w-40 mb-2">
                                        <span>Set Active Language</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="w-60 title-lg">
                                        <input type="text" className="form-control input-title-lg" defaultValue="English" />
                                    </div>
                                    <div className="w-40 d-flex content-lg">
                                        <div className="d-flex m-auto">
                                            <span>Active</span>
                                            <label className="switchCk" for="checkbox">
                                                <input type="checkbox" id="checkbox" />
                                                <div className="sliderCk round"></div>
                                            </label>
                                            <span>Inactive</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="w-60 title-lg">
                                        <input type="text" className="form-control input-title-lg" defaultValue="Vietnamese" />
                                    </div>
                                    <div className="w-40 d-flex content-lg">
                                        <div className="d-flex m-auto">
                                            <span>Active</span>
                                            <label className="switchCk" for="checkbox">
                                                <input type="checkbox" id="checkbox" />
                                                <div className="sliderCk round"></div>
                                            </label>
                                            <span>Inactive</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </ContentBody>
            </Content>
        )
    }
}
export default withTranslation()(LanguagePage);