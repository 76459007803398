import React, { useState, useCallback } from 'react';
import { Card } from './Card';
import update from 'immutability-helper';
const style = {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '10px'
};
const ArticleOrder = (props) => {
    const [cards, setCards] = useState(props.data)

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        const dragCard = cards[dragIndex];
        setCards(update(cards, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
            ],
        }));
    }, [cards], props.orderChanges(cards)
    );

    const renderCard = (card, index) => {
        return (<Card key={props.isAsset? card.id : card.id} index={index} id={props.isAsset? card.id : card.id} text={props.isAsset? card.asset_name : card.name} moveCard={moveCard} />);
    };
    return (<>
        <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
    </>);

};

export default ArticleOrder
