import React, { Component } from 'react';
import { Button } from "react-bootstrap"
import Select from "react-dropdown-select"
import Card, { CardHeader, CardTitle, CardBody, CardFooter } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content"
import TitlePages from './Elements/TitlePages'

import UserApi from '../api/UserApi';
import swal from "sweetalert"
import AlertHelper from "./../lib/AlertHelper"
import toTitle from "to-title"
import { withTranslation } from 'react-i18next';
import config from '../config/config'
import ReactGA from 'react-ga';

class UserDetailPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userId: 0,
            username: "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            passwordConfirm: "",
            active: false,
            avatar: "",
            previewAvatar: "",
            // isStaff:false,
            iSuperuser:false,
            groups: [],
            selectedGroup: [],
            privilegeList: [],
            privilege: false,
            transPage: "pages.user_detail.",
            transAlert: "alert."

        }

        this.handleUsernameChange = this.handleUsernameChange.bind(this)
        this.handleFirstnameChange = this.handleFirstnameChange.bind(this)
        this.handleLastnameChange = this.handleLastnameChange.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.handleActiveChange = this.handleActiveChange.bind(this)
        this.handleActiveSuperuser = this.handleActiveSuperuser.bind(this)
        
        // this.handleStaffChange = this.handleStaffChange.bind(this)
        // this.handleSuperuser = this.handleSuperuser.bind(this)
        this.errMessage = this.errMessage.bind(this)

        this.getDetailEmployee = this.getDetailEmployee.bind(this)

        this.fileInput = React.createRef()

    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        this.setState({
            applicantId: this.props.match.params.applicantId,
            employeeId: this.props.match.params.employeeId,
            username: "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            passwordConfirm: "",
            active: false,
            isStaff: false,
            iSuperuser:false,
            groups: [],
            selectedGroup: []
        }, () => {
            this.getAllPrivileges()
            if (this.state.employeeId) {
                this.getDetailEmployee(this.state.employeeId)
            }
        })
    }

    // onCloseModal() {
    //     this.setState({
    //         userId:0
    //     },() => {
    //         this.props.onHide()
    //         this.props.setUserId(0)
    //     })

    // }

    getAllPrivileges = () => {
        const userObj = new UserApi()

        userObj.getAllPrivileges()
            .then(res => {
                console.log(" ===> ", res)

                this.setState({
                    privilegeList: res.data.results
                })
            })
            .catch(err => {
                const objAlert = new AlertHelper()
                objAlert.errorHandlerAlert(err.response.data)
            })
    }

    handleFirstnameChange(value) {
        this.setState({
            firstName: value
        })
    }

    handleLastnameChange(value) {
        this.setState({
            lastName: value
        })
    }

    handleActiveChange(value) {
        var val = false
        if (value === "active") {
            val = true
        }
        this.setState({
            active: val
        })
    }

    handleActiveSuperuser = (value) => {
        var val = false
        if (value === "active") {
            val = true
        }
        this.setState({
            iSuperuser: val
        })
    }

    handleUsernameChange(value) {
        this.setState({
            username: value
        })
    }

    handleEmailChange(value) {
        this.setState({
            email: value
        })
    }

    handlePasswordChange(value) {
        this.setState({
            password: value
        })
    }

    handlePasswordConfirmChange(value) {
        this.setState({
            passwordConfirm: value
        })
    }

    handleAvatarChange(value) {
        this.setState({
            avatar: value
        })
    }

    getDetailEmployee() {

        const userObj = new UserApi()

        userObj.getDetailEmployee(this.state.employeeId)
            .then(res => {

                console.log(" ===> ", res.data)
                this.setState({
                    "username": res.data.user.username,
                    "firstName": res.data.user.first_name,
                    "lastName": res.data.user.last_name,
                    "email": res.data.user.email,

                    // "groups": res.data.groups,
                    "active": res.data.user.is_active,
                    // "isStaff": res.data.is_staff,
                    privilege: res.data.privileges,
                    iSuperuser: res.data.user.is_superuser ? true : false,
                    previewAvatar: res.data.avatar
                })
            })
            .catch(err => {
                const objAlert = new AlertHelper()
                objAlert.errorHandlerAlert(err.response.data)
            })


    }

    addEmployee() {
        const { t } = this.props;
        const userObj = new UserApi()
        const formData = new FormData()

        const user = {
            "username": this.state.username,
            "first_name": this.state.firstName,
            "last_name": this.state.lastName,
            "email": this.state.email,
            "password": this.state.password,
            "confirm_password": this.state.passwordConfirm,
            "is_active": this.state.active,
            // "is_staff": this.state.isStaff,
            "is_superuser": this.state.iSuperuser
        }

        formData.append("user.username", user.username)
        formData.append("user.first_name", user.first_name)
        formData.append("user.last_name", user.last_name)
        formData.append("user.email", user.email)
        formData.append("user.password", user.password)
        formData.append("user.confirm_password", user.confirm_password)
        formData.append("user.is_active", user.is_active)
        formData.append("user.is_superuser", user.is_superuser)
        formData.append("division", "")
        if (this.state.avatar) {
            formData.append("avatar", this.state.avatar)
        }

        if (!this.state.iSuperuser && this.state.privilege) {
            this.state.privilege.forEach(item => {
                formData.append("privileges", item.id)
            })
        }

        userObj.addEmployee(formData)
            .then(res => {
                swal("Success !", t(this.state.transAlert + "user_add_success"), "success")
                const _this = this
                setTimeout(function () {
                    _this.props.history.push(`/user`)
                })
            })
            .catch(err => {
                const errorResponse = err.response?.data;
                let errorMessage = "";

                if (errorResponse) {
                    if (Array.isArray(errorResponse)) {
                        errorMessage = errorResponse.join("\n")
                    } else {
                        errorMessage = this.errMessage(errorResponse, errorMessage)
                    }
                }
                swal("Error !", errorMessage, "error")
            })
    }

    updateEmployee() {
        const { t } = this.props;
        const userObj = new UserApi()
        const formData = new FormData()

        const user = {
            "username": this.state.username,
            "first_name": this.state.firstName,
            "last_name": this.state.lastName,
            "email": this.state.email,
            "password": this.state.password,
            "confirm_password": this.state.passwordConfirm,
            "is_active": this.state.active,
            // "is_staff": this.state.isStaff,
            "is_superuser": this.state.iSuperuser
        }

        formData.append("user.username", user.username)
        formData.append("user.first_name", user.first_name)
        formData.append("user.last_name", user.last_name)
        formData.append("user.email", user.email)
        formData.append("user.password", user.password)
        formData.append("user.confirm_password", user.confirm_password)
        formData.append("user.is_active", user.is_active)
        formData.append("user.is_superuser", user.is_superuser)
        formData.append("division", "")

        if (this.state.avatar) {
            formData.append("avatar", this.state.avatar)
        }

        if (!this.state.iSuperuser && this.state.privilege) {
            this.state.privilege.forEach(item => {
                formData.append("privileges", item.id)
            })
        }

        userObj.updateEmployee(this.state.employeeId, formData)
            .then(res => {
                console.log("", res.data)
                swal("Success !", t(this.state.transAlert + "user_update_success"), "success")
                // const _this = this
                // setTimeout(function(){
                //     _this.props.history.push(`/user`)
                // })

            })
            .catch(err => {
                swal("Error !", err.message, "error")
            })
    }

    applicantAdd() {

    }

    applicantUpdate() {

    }

    errMessage(obj, message) {
        const keys = Object.keys(obj);
        keys.forEach(key => {
            if (Array.isArray(obj[key])) {
                message += toTitle(key) + ":\n" + obj[key].join('\n') + "\n\n"
            } else if (typeof obj[key] === 'object' && Object.keys(obj[key]).length) {
                message = this.errMessage(obj[key], message)
            }
        })
        return message.trim()
    }

    handleChangePrivilege = (value) => {
        this.setState({
            privilege: value
        })
    }

    render() {
        const { t } = this.props;
        var HeaderText = ""
        var BtnSubmit = ""

        if (this.props.match.path === "/employee/add") {
            HeaderText = t(this.state.transPage + "employee_add")
            BtnSubmit = <Button variant="primary" style={{ float: "right" }} onClick={() => { this.addEmployee() }}>
                <i className="fa fa-plus">&nbsp;</i>
                {t(this.state.transPage + "employee_add")}
            </Button>
        }
        if (this.props.match.path === "/applicant/update") {
            HeaderText = t(this.state.transPage + "applicant_add")
            BtnSubmit = <Button variant="primary" style={{ float: "right" }} onClick={() => { this.applicantAdd() }}>
                <i className="fa fa-plus">&nbsp;</i>
                {t(this.state.transPage + "applicant_add")}
            </Button>
        }
        if (this.state.employeeId) {
            HeaderText = t(this.state.transPage + "employee_update")
            BtnSubmit = <Button variant="primary" style={{ float: "right" }} onClick={() => { this.updateEmployee() }}>
                <i className="fa fa-plus">&nbsp;</i>
                {t(this.state.transPage + "employee_update")}
            </Button>
        }
        if (this.state.applicant) {
            HeaderText = t(this.state.transPage + "applicant_update")
            BtnSubmit = <Button variant="primary" style={{ float: "right" }} onClick={() => { this.applicantUpdate() }}>
                <i className="fa fa-plus">&nbsp;</i>
                {t(this.state.transPage + "applicant_update")}
            </Button>

        }

        const Privileges = this.state.privilegeList.map(item => {
            return {
                id: item.id,
                name: item.name,
            }
        })

        let privilegeSelected = []
        // SELECTED ROLE
        if (this.state.privilege && this.state.privilegeList.length > 0) {
            privilegeSelected = this.state.privilege.map((item, index) => {
                for (var ii = 0; ii < this.state.privilegeList.length; ii++) {

                    if (this.state.privilegeList[ii]?.id === item || this.state.privilegeList[ii]?.id === item?.id) {
                        return {
                            id: this.state.privilegeList[ii]?.id,
                            name: this.state.privilegeList[ii]?.name,
                        }
                    }
                }

                return { id: 0, name: "" }
            })
        }
        return (

            <Content>
                <ContentHeader>
                    <TitlePages title={HeaderText} />
                </ContentHeader>
                <ContentBody>
                    <div className="col-md-8">
                        <Card >
                            {/* <CardHeader>
                                <CardTitle> <b>{ HeaderText }</b> </CardTitle>
                                <TitlePages title={HeaderText} />
                            </CardHeader> */}
                            <CardBody>
                                <div className="row">

                                    <div className="col-md-4">
                                        <img
                                            title="Click to change the avatar"
                                            width={"70%"}
                                            src={this.state.previewAvatar || "/img/phenikaa-default-image.svg"}
                                            alt="Click to change the avatar"
                                            style={{ cursor: "pointer", border: "1px solid grey" }}
                                            onClick={() => {
                                                this.fileInput.current.click()
                                            }}
                                        ></img>
                                        <div style={{ textAlign: "center", width: "70%" }}> {t('avatar')} </div>

                                        <input type="file" name="image" id="image" style={{ display: "none" }} onChange={(event) => {
                                            //alert(JSON.stringify(event.target.value))
                                            this.setState({
                                                avatar: event.target.files[0],
                                                previewAvatar: URL.createObjectURL(event.target.files[0])
                                            })
                                        }} ref={this.fileInput}></input>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="form-group">
                                            <label> {t('first_name')} </label>
                                            <input className="form-control" type="text" value={this.state.firstName} onChange={(event) => this.handleFirstnameChange(event.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <label> {t('last_name')} </label>
                                            <input className="form-control" type="text" value={this.state.lastName} onChange={(event) => this.handleLastnameChange(event.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label> {t('email')} </label>
                                            <input type="email" className="form-control" value={this.state.email} onChange={(event) => this.handleEmailChange(event.target.value)}></input>
                                        </div>
                                        {/* <div className="form-group"> 
                                        <label> Group </label>
                                        
                                    </div> */}
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            {/* { JSON.stringify(this.state.active) } */}
                                            <label> {t('active')} </label><br></br>
                                            <label id="active"> <input type="radio" onChange={() => this.handleActiveChange("active")} id="active" name="active-user" checked={this.state.active}></input> {t('active')} </label>
                                            <span>&emsp;</span>
                                            <label id="active"> <input type="radio" onChange={() => this.handleActiveChange("unactive")} id="unactive" name="active-user" checked={!this.state.active}></input> {t('inactive')} </label>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Superuser</label><br></br>
                                            <label> <input type="radio" onChange={() => this.handleActiveSuperuser('active')} checked={this.state.iSuperuser}></input> True </label>
                                            <span>&emsp;</span>
                                            <label> <input type="radio" onChange={() => this.handleActiveSuperuser('inactive')} checked={!this.state.iSuperuser}></input> False </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        {
                                            !this.state.iSuperuser ?
                                                <div className='form-group'>
                                                    <label>Role</label><br></br>
                                                    <Select
                                                        multi
                                                        searchable={true}
                                                        searchBy="name"
                                                        options={Privileges}
                                                        values={privilegeSelected}
                                                        valueField="id"
                                                        labelField="name"
                                                        onDropdownClose={(value) => {
                                                            // console.log("dropdownClose" , value)   
                                                        }}
                                                        onChange={(selectedprivilege) => {
                                                            this.handleChangePrivilege(selectedprivilege)
                                                        }}
                                                    />
                                                </div> : <></>
                                        }

                                        {/* <div className="form-group">
                                       
                                        <label><input type="checkbox" value="staff" name="staff" defaultChecked={this.state.isStaff} onClick={() =>  this.handleStaffChange()} /> Staff</label>

                                    </div>
                                    <div className="form-group">
                                       
                                        <label><input type="checkbox" value="superuser" name="superuser" defaultChecked={this.state.iSuperuser} onClick={() =>  this.handleSuperuser()} /> Superuser </label>

                                    </div> */}
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label> {t('username')} </label>
                                            <input type="text" className="form-control" value={this.state.username} onChange={(event) => this.handleUsernameChange(event.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <label> {t('password')} </label>
                                            <input type="password" className="form-control" value={this.state.password} onChange={(event) => this.handlePasswordChange(event.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <label> {t('confirm_password')} </label>
                                            <input type="password" className="form-control" value={this.state.passwordConfirm} onChange={(event) => this.handlePasswordConfirmChange(event.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                            <CardFooter>
                                {BtnSubmit}
                            </CardFooter>
                        </Card>
                    </div>
                </ContentBody>
            </Content>

        );
    }
}

export default withTranslation()(UserDetailPage);