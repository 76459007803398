import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap"
import { withTranslation } from 'react-i18next';

class ApplicantUnarchiveConfirm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            transPage: "modals.applicant_unarchive."
        }

        this.archiveSelectedApplicant = this.unarchiveSelectedApplicant.bind(this)
        this.onOpenModal = this.onOpenModal.bind(this)
        // this.onHideModal = this.onHideModal.bind(this)
    }

    unarchiveSelectedApplicant() {
        // dari props method nya
        // this.props.applicantArchive(this.props.applicantId, false) 
        this.props.unarchiveSelectedApplicant(this.props.selectedApplicant, false)
        this.props.onHide()
        this.props.getApplicantList()
    }

    onOpenModal() {
        //console.log("modal open")
    }

    onCloseModal() {
        this.props.onHide()
    }
    
    render() {
        const {t} = this.props;
        return (
            <Modal show={this.props.show} onHide={() => this.onCloseModal()} onShow={() => this.onOpenModal()}>
                {this.props.loadingModal}
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>{t(this.state.transPage + "title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t(this.state.transPage + "confirmation_text")}</p>
                    {
                        this.props.selectedApplicant.map(item => {
                            return(<li>{item.first_name} {item.last_name}</li>)
                        })
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => this.props.onHide()}>
                        {t('cancel')}
                    </Button>
                    <Button variant="light" onClick={() => this.archiveSelectedApplicant()}>
                        <i className="fa fa-archive">&nbsp;</i>
                        {t(this.state.transPage + "unarchive_applicant")}
                    </Button>   
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(ApplicantUnarchiveConfirm);