import React, { Component } from 'react';
import { Modal, Card, Button } from "react-bootstrap"
import swal from 'sweetalert'
import AlertHelper from "./../../lib/AlertHelper"
import Pagination from "react-js-pagination";

import AssetApi from "../../api/AssetApi"

import { shortSentence } from "../../lib/StringHelper"
import ArticleApi from '../../api/ArticleApi';
import { withTranslation } from 'react-i18next';

class ArticleAssetAddModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            articleId: 0,
            assets: [],
            selectedAssets: [],
            pairDisable: true,
            loadingModal: "",

            activePage: 1,
            limitPageData: 30,
            totalCount: 0,

            search: "",
            fileType: false,
            asset_types: [],

            transPage: "modals.article_asset_add.",
            transAlert: "alert."
        }
        this.getAllAssets = this.getAllAssets.bind(this)
        this.handleSelectedAsset = this.handleSelectedAsset.bind(this)
        this.onShowModal = this.onShowModal.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)
        this.addAssetArticle = this.addAssetArticle.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
    }

    onShowModal() {
        this.getAssetTypeLanguage()
        this.getAllAssets()
        this.setState({
            articleId: this.props.articleId
        })
    }

    onCloseModal() {
        this.setState({
            assets: [],
            selectedAssets: []
        }, () => {

            this.props.onHide()
        })
    }

    getAllAssets() {
        const objAsset = new AssetApi()
        const { fileType } = this.state

        let param
        if (fileType) {
            param = {
                page_size: this.state.limitPageData,
                page: this.state.activePage,
                search: this.state.search,
                order: '-id',
                type: fileType,
            }
        } else {
            param = {
                page_size: this.state.limitPageData,
                page: this.state.activePage,
                search: this.state.search,
                order: '-id'
            }
        }

        return objAsset.getAllAssets(param)
            .then(res => {

                this.setState({
                    assets: res.data.results,
                    totalCount: res.data.count,
                })
            })
    }

    getAssetTypeLanguage = () => {
        const obj = new AssetApi();

        obj.assetTypeLanguage({}).then((res) => {
            this.setState({
                asset_types: res.data.results,
            });
        });
    }

    handleSelectedAsset(asset, value) {

        var lastSelected = [...this.state.selectedAssets]
        var indexDelete = -1
        // var stateSelected = ""

        if (value === true) {

            lastSelected.push(asset)
        } else {

            this.state.selectedAssets.forEach((item, index) => {
                if (item.id === asset.id) {
                    indexDelete = index
                }
            })

            lastSelected.splice(indexDelete, 1)
            //console.log("lastArticles => ",lastArticles)
        }

        this.setState({
            selectedAssets: lastSelected,

        }, () => {

            // alert(JSON.stringify(this.state.selectedAssets))
            if (this.state.selectedAssets.length > 0) {
                this.setState({
                    pairDisable: false,

                })
            } else {
                this.setState({
                    pairDisable: true,

                })
            }
        })
    }

    addAssetArticle() {
        const { t } = this.props;
        const objArticle = new ArticleApi()

        this.setState({
            loadingModal: <div className="overlay d-flex justify-content-center align-items-center">
                <i className="fas fa-2x fa-sync fa-spin"></i>
            </div>
        })

        if (this.props.type === "add") {


            this.props.setSelectedAssetAdd(this.state.selectedAssets)
            // this.props.getDetailArticle()
            this.setState({
                loadingModal: ""
            })
            this.onCloseModal()
            swal("Success !", t(this.state.transAlert + "add_asset_into_article_success"), "success");

        } else if (this.props.type === "update") {

            const ids = this.state.selectedAssets.map(item => {
                return (item.id)
            })

            const dt = {
                articleId: this.state.articleId,
                ids: ids
            }

            // console.log(" dt => ",dt)

            objArticle.articleAssetPair(dt.articleId, dt.ids)
                .then(res => {
                    this.props.getDetailArticle()
                    this.setState({
                        loadingModal: ""
                    })
                    this.onCloseModal()
                    swal("Success !", t(this.state.transAlert + "add_asset_into_article_success"), "success");
                })
                .catch(err => {
                    const objAlert = new AlertHelper()
                    objAlert.errorHandlerAlert(err.message)
                })
        }


    }

    handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,

        }, () => {
            this.getAllAssets()
        });
    }

    handleSearchChange(value) {
        this.setState({
            search: value,
            activePage: 1
        }, () => {
            this.getAllAssets()
        })
    }

    handleChangeFileType(value) {
        if (value !== "Check") {
            this.setState(
                {
                    fileType: value,
                    activePage: 1,
                },
                () => {
                    this.getAllAssets();
                }
            );
        } else {
            if (value === "Check") {
                this.setState(
                    {
                        isFilterByType: false,
                    },
                    () => {
                        this.getAllAssets();
                    }
                );
            }
        }
    }

    render() {
        const { t } = this.props;

        const AssetList = this.state.assets.length > 0 ? this.state.assets.map((item, index) => {

            // console.log("asset item => ", item )
            var media = <img width={"100%"} alt="" src={item.asset_file}></img>
            if (item.file_type === "URL") {
                media = <iframe title={`asset-media-embedded-${index}`} width={"100%"} src={item.asset_link} allowFullScreen></iframe>
            } else if (item.file_type === "Video") {
                media = <video width={"100%"} height={"50%"} title="Video" controls>
                    <source src={item.asset_file} />
                </video>
            }

            return (
                <div key={item.id} className="col-md-2">
                    <Card>
                        <Card.Header className="bg-primary">
                            <div className="form-check form-check-inline">
                                <input type="checkbox" className="form-check-input" id={"label-modal-" + item.id} onClick={(event) => this.handleSelectedAsset(item, event.target.checked)}></input>
                                <label className="form-check-label" htmlFor={"label-modal-" + item.id}>{item.name}</label>
                            </div>

                        </Card.Header>
                        <Card.Body>
                            <div className="container-fluid" align="center">
                                {media}
                            </div>
                        </Card.Body>


                        <Card.Footer>
                            <div align="center" style={{ fontSize: 12 }}>{shortSentence(item.description, 6)} ...</div>
                        </Card.Footer>
                    </Card>
                </div>
            )
        }) : <div className="col-md-12 center-block"><h4 className="text-center" >There is no Assets</h4></div>

        return (
            <Modal show={this.props.show} onHide={() => this.onCloseModal()} size="xl" onShow={() => this.onShowModal()}>
                {this.state.loadingModal}
                <Modal.Header closeButton className="bg-primary">
                    {t(this.state.transPage + "title")}
                </Modal.Header>

                <Modal.Body>
                    <div>
                        {/* { JSON.stringify(this.state.selectedAssets)} */}
                    </div>
                    <div className="d-flex">
                        <div className="form-group" style={{ marginRight: "1rem" }}>
                            <label>{t("file_type")}</label>
                            <select
                                className="form-control w-auto"
                                defaultValue={this.state.inputType}
                                onChange={(event) => {
                                    this.handleChangeFileType(event.target.value);
                                }}
                            >
                                <option value="Check">
                                    {" "}-- Select File Type --{" "}
                                </option>
                                {this.state.asset_types?.map((item) => {
                                    return <option value={item.name}> {item.name} </option>;
                                })}
                            </select>
                        </div>
                        <div style={{ float: "left" }}>
                        <label>{t("search")}</label>
                            <div className="form-group">
                                <input type="text" placeholder={t(this.state.transPage + "search_asset")} className="form-control" name="search" value={this.state.search} onChange={(event) => this.handleSearchChange(event.target.value)} />
                            </div>
                        </div>
                    </div>

                    <div style={{ float: "right" }}>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.limitPageData}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={4}
                            onChange={this.handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"

                        />
                    </div>
                    <div className="clearfix"></div>
                    <div className="row">
                        {AssetList}
                    </div>
                    <div style={{ float: "right" }}>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.limitPageData}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={4}
                            onChange={this.handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"

                        />
                    </div>
                    <div className="clearfix"></div>

                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={this.state.pairDisable} onClick={() => this.addAssetArticle()}> <i className="fa fa-paperclip">&nbsp;</i> {t(this.state.transPage + "pair_these_assets")} </Button>
                </Modal.Footer>


            </Modal>
        );
    }
}

export default withTranslation()(ArticleAssetAddModal);