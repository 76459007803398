import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Card, { CardHeader, CardTitle, CardBody } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content";
import TemplateAddModal from "./../components/Modal/TemplateAddModal";
import TitlePages from "./Elements/TitlePages";
import Context from "./Context";
import TemplateApi from "./../api/TemplateApi";
import { withTranslation } from "react-i18next";
// import TemplatePairModal from "../components/Modal/TemplatePairModal";
import TemplateDeleteModal from "../components/Modal/TemplateDeleteConfirm";
import TemplateUpdateModal from "../components/Modal/TemplateUpdateModal";
import config from '../config/config'
import ReactGA from 'react-ga';

class TemplateList extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      showModalAdd: false,
      showModalPair: false,
      disableDelete: true,
      val: false,
      selectedTemplates: [],
      templateId: 0,
      templatesActive: [],
      templatesActive2: [],
      transAlert: "alert.",
      showModalDelete: false,
      showModalUpdate: false,
      id_selected_template: 0,
      selectedTemp: [],
      id: 0,
      isChange: false,
      is_active: null,
      ids: 0,
      data: [],
      changeActiveTemplate: () => {
        this.changeActiveTemplate(parseInt(this.state.activeSite));
      },
    };

    // this.getAllTemplates = this.getAllTemplates.bind(this);
    this.setSelectedItem = this.setSelectedItem.bind(this);
    this.getSites = this.getSites.bind(this);
    // this.getAllTemplate = this.getAllTemplate.bind(this);
    this.setShowModalAdd = this.setShowModalAdd.bind(this);
    this.setShowModalPair = this.setShowModalPair.bind(this);
    this.setShowModalDelete = this.setShowModalDelete.bind(this);
    this.setShowModalUpdate = this.setShowModalUpdate.bind(this);
  }

  componentDidMount() {
    ReactGA.initialize(config.GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    const ids = parseInt(localStorage.getItem("siteId"));
    this.getSites(ids);
  }

  componentDidUpdate(prevState) {
    // console.log(prevState.activeSite);
    // const site_id = parseInt(localStorage.getItem("siteId"));
    // const contextValue = this.context;
    // const idf = parseInt(contextValue.activeSite);
    var isChange = true;
    const nextID = parseInt(localStorage.getItem("siteId"));
    const prevID = parseInt(prevState.activeSite);

    if (prevID !== nextID) {
      isChange = true;
    } else {
      isChange = false;
    }

    if (this.state.isChange !== isChange) {
      this.setState({
        isChange: isChange,
      });
      this.getSites(nextID);
    }
  }

  setShowModalAdd() {
    this.setState({
      showModalAdd: !this.state.showModalAdd,
    });
  }

  setShowModalPair() {
    this.setState({
      showModalPair: !this.state.showModalPair,
    });
  }
  setShowModalDelete() {
    this.setState({
      showModalDelete: !this.state.showModalDelete,
    });
  }
  setShowModalUpdate() {
    this.setState({
      showModalUpdate: !this.state.showModalUpdate,
    });
  }

  getSites(param) {
    if (this.state.selectedTemplates.length > 0) {
      this.setState({
        disableDelete: false,
      });
    } else {
      this.setState({
        disableDelete: true,
      });
    }

    const objTemplate = new TemplateApi();
    objTemplate
      .getSites(param)
      .then((res) => {
        this.setState({
          templatesActive: res.data.templates,
        });
      })
      .catch((err) => {
        console.log("err on sites =>", err.response);
      });
  }

  //   getAllTemplate() {
  //     if (this.state.selectedTemplates.length > 0) {
  //       this.setState({
  //         disableDelete: false,
  //       });
  //     } else {
  //       this.setState({
  //         disableDelete: true,
  //       });
  //     }

  //     const objTemplate = new TemplateApi();
  //     objTemplate
  //       .getAllTemplate()
  //       .then((res) => {
  //         console.log(res.data);
  //         this.setState({
  //           templatesActive2: res.data.results,
  //         });
  //       })
  //       .catch((err) => {
  //         console.log("err on sites =>", err.response);
  //       });
  //   }

  deleteselectedTemplates() {
    const { t } = this.props;
    const objTemplate = new TemplateApi();

    const templates = this.state.selectedTemplates.map((item) => {
      return item.id;
    });

    objTemplate
      .deleteTemplate(templates)
      .then((res) => {
        this.setState({
          showModalDelete: false,
          disableDelete: true,
          selectedWidget: [],
        });
        //this.getSectionDetail(sectionId)

        swal(
          "Success !",
          t(this.state.transAlert + "template_delete_success"),
          "success"
        );

        setTimeout(() => {
          window.location.reload();
        }, 0);
      })
      .catch((err) => {
        swal("Error!", err.message, "error");
      });
  }

  setSelectedItem(template, value) {
    var lastSelected = [...this.state.selectedTemplates];
    var indexDelete = -1;

    if (value === true) {
      lastSelected.push(template);
    } else {
      this.state["selectedTemplates"].forEach((item, index) => {
        if (item.id === template.id) {
          indexDelete = index;
        }
      });

      lastSelected.splice(indexDelete, 1);
    }

    this.setState(
      {
        selectedTemplates: lastSelected,
      },
      () => {
        if (this.state["selectedTemplates"].length > 0) {
          this.setState({
            disableDelete: false,
          });
        } else {
          this.setState({
            disableDelete: true,
          });
        }
      }
    );
  }

  render() {
    const { t } = this.props;
    const contextValue = this.context;
    const id_sites = contextValue.activeSite;
    let form_check;
    let isActived = false;

    const TemplateList = this.state.templatesActive.map((item) => {
      let checkBox;
      checkBox = (
        <input
          id={item.name + "-" + item.id}
          className=""
          type="checkbox"
          onClick={(event) =>
            this.setSelectedItem(item, event.currentTarget.checked)
          }
        ></input>
      );
      //   if (item.active) {
      //     checkBox = <></>;
      //     isActived = true;
      //     form_check = (
      //       <Form.Check type="checkbox" label="Active" checked={item.active} />
      //     );
      //   } else {
      //     checkBox = (
      //       <input
      //         id={item.name + "-" + item.id}
      //         className=""
      //         type="checkbox"
      //         onClick={(event) =>
      //           this.setSelectedItem(item, event.currentTarget.checked)
      //         }
      //       ></input>
      //     );

      //     form_check = <></>;
      //     isActived = false;
      //   }
      return (
        <div className="col-md-4" key={item.id}>
          <Card className="card card-outline card-primary">
            <CardHeader className="">
              <CardTitle>
                {checkBox}
                &nbsp;
                <Link to={`/template/detail/${item.id}`}>
                  <label htmlFor={item.name + "-" + item.id}>
                    <i className="fa fa-pager" title="template"></i> {item.name}
                  </label>
                </Link>
              </CardTitle>
              <div className="card-tools">
                <button
                  className="btn btn-sm"
                  onClick={() => {
                    this.setState(
                      {
                        templateId: item.id,
                        is_active: item.active,
                      },
                      () => this.setShowModalUpdate()
                    );
                  }}
                >
                  <i className="fa fa-edit"></i>
                </button>
              </div>
            </CardHeader>

            <CardBody>
              <Form.Group controlId="checkTemplateByActiveSites">
                {form_check}
              </Form.Group>
            </CardBody>
          </Card>
        </div>
      );
    });

    return (
      <Context.Provider value={this.state}>
        <Content>
          <ContentHeader>
            {/* <h1> Templates </h1> */}
            <TitlePages title={t("pages.template.title")} />
            <div className="float-right">
              <Button
                className="btn btn-danger"
                disabled={this.state.disableDelete}
                onClick={() => this.setShowModalDelete()}
                style={{ marginRight: 5 }}
              >
                <i className="fa fa-trash">&nbsp;</i>
                {t("pages.template.delete_selected_template")}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  this.setState(
                    {
                      templateId: 0,
                    },
                    () => this.setShowModalAdd()
                  );
                }}
              >
                <i className="fa fa-plus">&nbsp;</i>
                {t("pages.template.add_template")}
              </Button>
            </div>
            <div className="clearfix"></div>
          </ContentHeader>
          <ContentBody>
            <div className="row">{TemplateList}</div>
          </ContentBody>
          {/* <TemplatePairModal
          id_site={id_sites}
          id={this.state.templateId}
          show={this.state.showModalPair}
          getSites={this.getSites}
          onHide={this.setShowModalPair}
        ></TemplatePairModal> */}
          <TemplateAddModal
            id={this.state.templateId}
            show={this.state.showModalAdd}
            onHide={this.setShowModalAdd}
            id_site={id_sites}
            getSites={this.getSites}
          ></TemplateAddModal>
          <TemplateUpdateModal
            id={this.state.templateId}
            show={this.state.showModalUpdate}
            onHide={this.setShowModalUpdate}
            id_site={id_sites}
            getSites={this.getSites}
            status_active={this.state.is_active}
            data={this.state.templatesActive}
          ></TemplateUpdateModal>
          <TemplateDeleteModal
            id_site={id_sites}
            show={this.state.showModalDelete}
            onHide={this.setShowModalDelete}
            getSites={this.getSites}
            selectedTemplate={this.state.selectedTemplates}
          ></TemplateDeleteModal>
        </Content>
      </Context.Provider>
    );
  }
}

export default withTranslation()(TemplateList);
