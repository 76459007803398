import React, { Component } from 'react'
import TitlePages from "./Elements/TitlePages";
import { Content, ContentHeader, ContentBody } from "./../components/Content";

export default class LoadingPage extends Component {
    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '60%',
            transform: 'translate(50%, 50%)'
        }
        return (
            <Content>
                <ContentBody>
                    <div style={style}>
                        <div className='w-100 h-100 d-flex'>
                            <div className="overlay d-flex m-auto justify-content-center align-items-center">
                                <i className="fa fa-2x fa-sync fa-spin"></i>
                            </div>
                        </div>
                    </div>
                </ContentBody>
            </Content>
        )
    }
}
