import React, { Component } from 'react';
import { Tabs, Tab, Button } from "react-bootstrap"
import { SketchPicker } from 'react-color';

import swal from 'sweetalert'
import AlertHelper from "./../../lib/AlertHelper"

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor"

import GeneralApi from "../../api/GeneralApi"
import WidgetApi from "../../api/WidgetApi"
import PageApi from "../../api/PageApi"
import SectionApi from "./../../api/SectionApi"
import GeneralDeleteConfirm from "../../components/Modal/GeneralDeleteConfirm"

import "./TextForm.css"
import Context from "../Context";

class TextForm extends Component {
    static contextType = Context;
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            name: "",
            content: "",
            input_type: "Small",
            urlType: "internal",
            selectedPage: "",
            selectedSection: "",
            selectedText: [],
            urlEnglish: "",
            urlVietname: "",

            pages: [],
            sections: [],
            text_languages: [],
            languageList: [],

            tabKey: 1,
            selectedLanguage: 1,
            idElement: 0,
            renderTabLanguage: "",
            defaultPicker: '#000000',

            hasColor: false,

            widget: {},

            showConfirmDelete: false,
        }

        this.minElements = {
            739: 3,
            2386: 6
        }

        // this.onOpenModal = this.onOpenModal.bind(this)
        // this.onCloseModal = this.onCloseModal.bind(this)

        this.handleNameChange = this.handleNameChange.bind(this)
        this.handleContentChange = this.handleContentChange.bind(this)
        this.handleChangeTab = this.handleChangeTab.bind(this)
        this.handleUrlTypeChange = this.handleUrlTypeChange.bind(this)
        this.handleUrlExternalChange = this.handleUrlExternalChange.bind(this)
        // this.handleInputTypeChange = this.handleInputTypeChange.bind(this)

        this.getDetailTextElement = this.getDetailTextElement.bind(this)

        this.renderTabLanguage = this.renderTabLanguage.bind(this)
        this.setShowConfirmDelete = this.setShowConfirmDelete.bind(this)
        this.deleteSelectedText = this.deleteSelectedText.bind(this)

    }

    componentDidMount() {
        const { languagesList } = this.context;

        this.setState({
            id: this.props.text_element?.id | 0,
            tabKey: 1,
            selectedLanguage: 1,
            languageList: languagesList,
            widget: this.props.widget

        }, () => {

            this.getAllSections()
            this.getPages()

            if (this.state.id) {
                //alert(this.state.id)
                this.getDetailTextElement()
            } else {
                this.renderTabLanguage()
            }

        })

    }

    componentWillUnmount() {
        this.setState({
            id: 0,
            name: "",
            // languageList:[],
            tabKey: 1,
            selectedLanguage: 1,
            text_languages: [],
            renderTabLanguage: ""
        }, () => {
            this.props.onHide()
        })
    }

    getPages() {
        const { sectionDetail } = this.context;

        this.setState({
            pages: sectionDetail.pagesList
        })
    }

    getSectionByPage() {
        const objSection = new SectionApi()

        const params = {
            language: localStorage.getItem("lang"),
            page_id: this.state.selectedPageId,
            max_size: true
        }

        objSection.sectionLanguage(params)
            .then(res => {

                // console.log(" heheh ",res.data)

                this.setState({
                    sections: res.data.results
                })
            })
    }

    getAllSections() {
        const { sectionDetail } = this.context;

        this.setState({
            sectionList: sectionDetail.sectionList
        })
    }


    getDetailTextElement() {
        const obj = new WidgetApi()
        const textId = this.props.text_element.id

        const _this = this

        obj.widgetTextDetail(textId)
            .then(res => {
                // console.log(" res ==> ",res.data )
                this.setState({
                    id: res.data.id,
                    name: res.data.name,
                    text_languages: res.data.text_languages,
                    input_type: res.data.input_type,
                    widget: this.props.widget,
                    hasColor: res.data.font_color !== '#000000' ? true : false,
                    defaultPicker: res.data.font_color !== '#000000' ? res.data.font_color : '#000000'
                }, () => {

                    this.renderTabLanguage()
                })
            })
    }

    // handleInputTypeChange(value) {
    //     this.setState({
    //         input_type:value
    //     })
    // }

    handleNameChange(value) {
        this.setState({
            name: value
        })
    }

    handleUrlTypeChange(value) {
        this.setState({
            urlType: value
        })
    }

    handleUrlExternalChange(id, value) {
        let urlLanguages = [...this.state.urlLanguages]
        let urlLanguage = { ...urlLanguages[id] }
        urlLanguage.path = value
        urlLanguages[id] = urlLanguage

        this.setState({
            urlLanguages: urlLanguages
        })
    }

    handleContentChange(id, value) {
        let text_languages = [...this.state.text_languages]
        let text_language = { ...text_languages[id] }
        text_language.content = value
        text_languages[id] = text_language

        this.setState({
            text_languages: text_languages
        })
    }

    handleChangeTab(value) {

        this.setState({

            selectedLanguage: value,
            tabKey: value,
        }, () => {
            this.renderTabLanguage()
        })
    }

    handlePageChange(value) {
        if (!value) {
            this.setState({
                selectedPageId: null,
                selectedSectionId: null,
                sections: []
            }, () => {
                //this.getSectionByPage()
            })
        } else {
            this.setState({
                selectedPageId: value
            }, () => {
                this.getSectionByPage()
            })

        }
    }

    handleSectionChange(value) {
        this.setState({
            selectedSectionId: value
        })
    }

    addWidgetText() {

        const objWidget = new WidgetApi()

        this.setState({
            loadingModal: <div className="overlay d-flex justify-content-center align-items-center">
                <i className="fas fa-2x fa-sync fa-spin"></i>
            </div>
        })

        const inputTextLanguage = this.state.text_languages.map(item => {
            return ({
                "language": item.language,
                content: item.content
            })
        })

        const font_color = this.state.hasColor ? this.state.defaultPicker : '#000000'

        objWidget.widgetTextAdd(this.props.widget.id, this.state.name, inputTextLanguage, font_color)
            .then(res => {
                swal("Success !", "You sucessfully added text into this widget !", "success");
                this.props.getWidgetDetail()
            })
            .catch(err => {
                const objAlert = new AlertHelper()
                const resErr = err?.response?.data ? err?.response?.data : err.message
                //console.log(" Error => ",err?.response?.data)
                objAlert.errorHandlerAlert(resErr)
            })

    }

    updateWidgetText() {

        const objWidget = new WidgetApi()

        this.setState({
            loadingModal: <div className="overlay d-flex justify-content-center align-items-center">
                <i className="fas fa-2x fa-sync fa-spin"></i>
            </div>
        })

        const inputTextLanguage = this.state.text_languages.map(item => {
            return ({
                id: item.id,
                "language": item.language,
                content: item.content
            })
        })

        const data = {
            textId: this.state.id,
            name: this.state.name,
            input_type: this.state.input_type,
            text_languages: inputTextLanguage,
            font_color: this.state.hasColor ? this.state.defaultPicker : '#000000'
        }

        objWidget.widgetTextUpdate(data)
            .then(res => {

                swal("Success !", "You sucessfully added text into this widget !", "success");
                this.props.getWidgetDetail()
            })
            .catch(err => {
                // const objAlert = new AlertHelper()
                // const resErr = err?.response?.data ? err?.response?.data : err.message
                console.log(" Error => ", err?.response?.data)
                // objAlert.errorHandlerAlert(resErr)
            })

    }

    deleteSelectedText() {
        const widgetObj = new WidgetApi()

        // const widgetId = this.props.match.params.widgetId
        const texts = this.state.selectedText.map((item) => {
            return item.id
        })

        widgetObj
            .widgetTextDelete(texts)
            .then((res) => {
                this.setState(
                    {
                        selectedText: [],
                    },
                    () => {
                        this.props.getWidgetDetail()
                        this.setShowConfirmDelete()
                        swal("Success !", "You successfully delete text on this widget ", "success")
                    }
                )
            })
            .catch((err) => {
                this.setShowConfirmDelete()
                swal("Error !", err.message, "error")
            })
    }

    renderTabLanguage() {

        const renderTabLanguage = this.state.languageList.map(item => {
            var result = this.state.text_languages.find(textLang => item.id === textLang.language)

            if (!result) {
                result = {
                    language: item.id,
                    text_element: 4,
                    content: "",
                    languageName: item.name
                }
            }

            result.languageName = item.name
            return result
        })

        this.setState({
            text_languages: renderTabLanguage
        }, () => {
            // console.log("text_languages : ",this.state.text_languages)
        })


    }

    changePicker = (color) => {
        this.setState({
            defaultPicker: color.hex
        })
    }

    handleHasColor() {
        this.setState({
            hasColor: !this.state.hasColor
        })
    }

    setShowConfirmDelete() {
        this.setState({
            showConfirmDelete: !this.state.showConfirmDelete,
            selectedText: [{
                id: this.state.id,
                name: this.state.name,
                type: "Text"
            }]
        })
    }

    render() {

        var HeaderExecute = "Add"
        var ButtonExecute = <Button className="float-right" variant="primary" onClick={() => this.addWidgetText()}>
            <i className="fa fa-plus">&nbsp;</i>
            Add Text Element
        </Button>
        var DeleteButton;

        if (this.state.id) {
            HeaderExecute = "Update"
            ButtonExecute = (
                <Button className="float-right" variant="primary" onClick={() => this.updateWidgetText()}>
                    <i className="fa fa-edit">&nbsp;</i> Update Text Element
                </Button>
            )

            if (Object.keys(this.minElements).includes(this.props.widget?.id.toString()) && this.props.widget.text_elements.length > this.minElements[this.props.widget?.id]) {
                DeleteButton = (
                    <Button className="float-right mr-2" variant="danger" onClick={this.setShowConfirmDelete}>
                        <i className="fa fa-trash">&nbsp;</i> Delete Text
                    </Button>
                )
            }
        }

        const PageSelect = this.state.pages.map(item => {
            return (<option key={item.id} value={item.id}>{item.name}</option>)
        })

        const SectionSelect = this.state.sections.map(item => {
            // console.log(item)
            return (<option key={item.id} value={item.section}>{item.name}</option>)
        })

        // GANTI JIKA SUDAH ADA4
        var URLInput = this.state.languageList.map((item, index) => {

            return (
                <div className="form-group" key={index}>

                    <label> Url {item.name}</label>
                    <input value={this.state.languageList[index].path} type="text" className="form-control"
                        onChange={(event) => this.handleUrlExternalChange(index, event.target.value)}></input>
                </div>
            )
        })

        if (this.state.urlType === "internal") {
            URLInput = <div>
                <div className="form-group">
                    <label> Page </label>
                    <select className="form-control" value={this.state.selectedPageId} onChange={(event) => this.handlePageChange(event.target.value)}>
                        <option value=""> -- Please Select Your Page -- </option>
                        {PageSelect}
                    </select>
                </div>
                <div className="form-group">
                    <label> Section </label>
                    <select className="form-control" value={this.state.selectedSectionId} onChange={(event) => this.handleSectionChange(event.target.value)}>
                        <option value=""> -- Please Select Your Section -- </option>
                        {SectionSelect}
                    </select>
                </div>

            </div>
        }

        let ckeditorConfig = {

            toolbar: {
                items: [
                    "heading",
                    "|",
                    'bold',
                    'italic',
                    "|",
                    "link",
                    '|',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'insertTable',

                    "|",
                    'undo',
                    'redo'
                ]
            },
            table: {
                contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
            },
            language: "",

        }

        const TabLanguage = this.state.text_languages.map((item, index) => {

            var TextEditor = <textarea
                className="form-control"
                onChange={(event) => this.handleContentChange(index, event.target.value)}
                value={this.state.text_languages[index].content}
            >
            </textarea>

            if (this.state.input_type === "Big") {
                TextEditor = <CKEditor

                    id="editor"
                    config={ckeditorConfig}
                    editor={ClassicEditor}
                    data={this.state.text_languages[index].content}
                    onInit={editor => {
                        // You can store the "editor" and use when it is needed.

                        //console.log( 'Editor is ready to use! '+index, editor );
                    }}
                    onChange={(event, editor) => {

                        const data = editor.getData()

                        this.handleContentChange(index, data)
                    }}
                />

            }

            // console.log(this.state.languageList)

            return (

                <Tab key={index} eventKey={item.language} title={item.languageName}>
                    <br></br>

                    <div className="form-group">
                        <label> Content </label>
                        {TextEditor}
                        {/* { JSON.stringify( this.state.text_languages )  } */}
                    </div>

                </Tab>
            )
        })

        return (
            <div>
                <div id="text-form" className="row">

                    <div className="col-md-6">
                        <div className="form-group">
                            <label> Name </label>
                            <input type="text" className="form-control" onChange={(event) => this.handleNameChange(event.target.value)} value={this.state.name}></input>
                        </div>
                        <Tabs
                            id="controlled-tab-example-3"
                            activeKey={this.state.tabKey}
                            onSelect={(k) => this.handleChangeTab(k)}
                        >
                            {TabLanguage}
                        </Tabs>
                        <div className="form-group">
                            <input type="checkbox" id="check-has-color" checked={this.state.hasColor}
                                onChange={() => this.handleHasColor()}
                            >
                            </input> &nbsp;
                            <label for="check-has-color"> Custom Text Color </label>
                            {
                                this.state.hasColor ?
                                    <SketchPicker
                                        color={this.state.defaultPicker}
                                        onChangeComplete={this.changePicker}
                                    /> : <></>
                            }
                        </div>
                    </div>
                    {/* <div className=" text-form-right">
                    
                        <div className="form-group">
                            <label> Url Type</label><br></br>
                            <input 
                                type="radio" 
                                name="url-type" 
                                id="url-type-internal"
                                defaultChecked={this.state.urlType === "internal"}
                                onClick={() => this.handleUrlTypeChange("internal")}
                            ></input>&nbsp;
                            <label htmlFor="url-type-internal"> Internal </label>
                            &nbsp; &nbsp;
                            <input 
                                type="radio" 
                                name="url-type" 
                                id="url-type-external"
                                defaultChecked={this.state.urlType === "external"}
                                onClick={() => this.handleUrlTypeChange("external")}
                            ></input>&nbsp;
                            <label htmlFor="url-type-external"> External </label>
                        </div>
                        { URLInput }
                    </div> */}
                </div>
                <div className="row">
                    <div className="pull-left col-md-6">

                    </div>
                    <div className="pull-right col-md-6">
                        {ButtonExecute}
                        {DeleteButton}
                    </div>
                </div>
                <GeneralDeleteConfirm
                    show={this.state.showConfirmDelete}
                    onHide={this.setShowConfirmDelete}
                    selectedGeneral={this.state.selectedText}
                    deleteSelectedGeneral={this.deleteSelectedText}
                />
            </div>

        );
    }
}

export default TextForm;