import React, { Component } from 'react';
import { ListGroup, Badge, Collapse } from "react-bootstrap"
import Card, { CardHeader, CardTitle, CardBody } from "./../../components/Card";

import ApplicationModal from "./ApplicationModal"

import JobApi from "./../../api/JobApi"
import { withTranslation } from 'react-i18next';
import UserContext from '../Context'


const labelConfirmation = [
    {
        id: 1,
        label: "secondary"
    },
    {
        id: 2,
        label: "primary"
    },
    {
        id: 3,
        label: "info"
    },
    {
        id: 4,
        label: "success"
    }, {
        id: 5,
        label: "danger"
    }
]

const labelPriority = [
    {
        id: 1,
        label: "success"
    },
    {
        id: 2,
        label: "info"
    },
    {
        id: 3,
        label: "warning"
    },
    {
        id: 4,
        label: "danger"
    }
]

class JobApplicationList extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props)

        this.state = {

            jobId: this.props.jobId,
            applicationId: 0,
            applicationList: [],
            showModal: false,
            isTable: false,

            priorityList: [],
            confirmationList: [],

            selectedPriority: undefined,
            selectedConfirmation: undefined,

            transPage: 'pages.job_application.'
        }

        this.getApllicationList = this.getApllicationList.bind(this)
        this.setShowApplicationModal = this.setShowApplicationModal.bind(this)

        this.getPriorityList = this.getPriorityList.bind(this)
        this.getConfirmationList = this.getConfirmationList.bind(this)

        this.handleChangeConfirm = this.handleChangeConfirm.bind(this)
        this.handleChangePriority = this.handleChangePriority.bind(this)
    }

    componentDidMount() {

        this.getApllicationList()
        this.getPriorityList()
        this.getConfirmationList()
    }

    getPriorityList() {
        const obJob = new JobApi()
        const params = {}

        obJob.getPriorityList(params)
            .then(res => {
                this.setState({
                    priorityList: res.data.results
                })
            })
            .catch(err => {
                console.log(" err => ", err.message)
            })
    }

    getConfirmationList() {
        const obJob = new JobApi()
        const params = {}

        obJob.getConfirmationList(params)
            .then(res => {
                this.setState({
                    confirmationList: res.data.results
                })
            })
            .catch(err => {
                console.log(" err => ", err.message)
            })
    }

    setShowApplicationModal() {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    getApllicationList() {
        const obJob = new JobApi()

        const params = {
            job_poster: this.state.jobId,
            priority: this.state.selectedPriority,
            confirmation: this.state.selectedConfirmation
        }

        obJob.getApplicationList(params)
            .then(res => {

                // console.log("PRISM PHANTASY",res.data.results)

                this.setState({
                    applicationList: res.data.results
                }, () => {

                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    handleChangePriority(value) {


        if (!value) {
            value = undefined
        }

        this.setState({
            selectedPriority: value
        }, () => {
            this.getApllicationList()
        })
    }

    handleChangeConfirm(value) {

        if (!value) {
            value = undefined
        }


        this.setState({
            selectedConfirmation: value
        }, () => {
            this.getApllicationList()
        })
    }


    render() {
        const { t } = this.props;
        const { employee_privileges } = this.context;
        const isOnlyMonitor = employee_privileges.filter(e => e.id === 5).length > 0;

        var Content = ""
        var CardList = this.state.applicationList.map((item, index) => {

            var variantLabel = labelConfirmation.find(item2 => {
                return item2.id === item.confirmation
            })

            var priorityLabel = labelPriority.find(item3 => {
                return item3.id === item.priority
            })

            var image = item.applicant_avatar ? item.applicant_avatar : `${process.env.PUBLIC_URL}/img/phenikaa-default-image.svg`

            CardList = <ListGroup key={index} as="div" className="col-md-2" style={{ marginBottom: 20 }}>
                <ListGroup.Item className={"bg bg-secondary"} >
                    <b>{item.index}. {item.applicant_name} </b>
                    <span style={{ float: "right" }}>
                        <button
                            className="btn btn-link text-white p-0"
                        >
                            <i
                                className="fa fa-edit"
                                onClick={() => {
                                    this.setState({
                                        applicationId: item.id
                                    }, () => {
                                        this.setShowApplicationModal()
                                    })
                                }}
                            />
                        </button>
                    </span>
                </ListGroup.Item>
                <ListGroup.Item>
                    <a href={`/job-poster/applicant/detail/${item.applicant}`}>
                        <img alt="applicants" style={{ margin: "0 0" }} src={image} width={"100%"} height={"100%"}></img>
                    </a>
                </ListGroup.Item>
                <ListGroup.Item>
                    <div> {t(this.state.transPage + 'work_experience')} : {item.applicant_working_experience} </div>
                    <div> {t(this.state.transPage + 'last_ed')} : {item.applicant_last_education}</div>
                </ListGroup.Item>
                <ListGroup.Item>
                    <div> {t('priority')} :  <Badge variant={priorityLabel.label}>{item.priority_name}</Badge></div>
                    <div> {t('confirm')} : <Badge variant={variantLabel.label}>{item.confirmation_name}</Badge></div>
                </ListGroup.Item>
                <ListGroup.Item>
                    <a href={`/job-poster/applicant/detail/${item.applicant}`}> {t(this.state.transPage + 'preview_applicant')} </a>
                </ListGroup.Item>

            </ListGroup>

            return CardList
        })

        const TableFormat = <table className="table responsive-table table-border">
            <thead>
                <th> {t('no')} </th>
                <th> {t('photo')} </th>
                <th> {t('name')} </th>
                <th> {t(this.state.transPage + 'last_working_experience')} </th>
                <th> {t(this.state.transPage + 'last_education')} </th>
                <th> {t('priority')} </th>
                <th> {t('confirm')} </th>
                <th> {t('action')} </th>

            </thead>
            <tbody>
                {this.state.applicationList.map((item, index) => {

                    var variantLabel = labelConfirmation.find(item2 => {
                        return item2.id === item.confirmation
                    })

                    var priorityLabel = labelPriority.find(item3 => {
                        return item3.id === item.priority
                    })

                    var image = item.applicant_avatar ? item.applicant_avatar : `${process.env.PUBLIC_URL}/img/phenikaa-default-image.svg`

                    return (<tr>
                        <td> {item.index} </td>
                        <td>
                            <a href={`/job-poster/applicant/detail/${item.applicant}`}>
                                <img alt="applicants" style={{ margin: "0 0" }} src={image} width={50} height={50}></img>
                            </a>
                        </td>
                        <td> <b> {item.applicant_name} </b> </td>
                        <td> {item.applicant_experience?.title} </td>
                        <td> {item.applicant_education?.title} </td>
                        <td> <Badge variant={priorityLabel.label}>{item.priority_name}</Badge> </td>
                        <td> <Badge variant={variantLabel.label}>{item.confirmation_name}</Badge> </td>
                        <td>
                            <span style={{ float: "right" }}>
                                <button
                                    className="btn btn-link p-0"
                                >
                                    <i
                                        className="fa fa-edit"
                                        onClick={() => {
                                            this.setState({
                                                applicationId: item.id
                                            }, () => {
                                                this.setShowApplicationModal()
                                            })
                                        }}
                                    />
                                </button>
                            </span>
                        </td>
                    </tr>)
                })}
            </tbody>
        </table>

        Content = CardList
        if (this.state.isTable) {
            Content = TableFormat
        }


        return (
            <Card>
                <CardHeader className="bg-primary">
                    <CardTitle><b> <i className="fa fa-envelope-open-text">&nbsp;</i> {t('applicants')} </b></CardTitle>
                    <div className="card-tools">
                        {this.props.applicationToggle}
                    </div>
                </CardHeader>
                <Collapse in={this.props.appToggle} style={{ padding: "1.25rem" }}>
                    <CardBody>
                        <div className="row">
                            <div className="col-md-11">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label> {t('priority')} </label>
                                            <select className="form-control" value={this.state.selectedPriority}
                                                onChange={(event) => { this.handleChangePriority(event.target.value) }}
                                            >
                                                <option value={""}> -- {t(this.state.transPage + 'select_priority')} --</option>
                                                {
                                                    this.state.priorityList.map(item => {

                                                        return (<option key={item.id} value={item.id}> {item.name}</option>)
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label> {t('confirmation')} </label>
                                            <select className="form-control" value={this.state.selectedConfirmation}
                                                onChange={(event) => this.handleChangeConfirm(event.target.value)}
                                            >
                                                <option value=""> -- {t(this.state.transPage + 'select_confirmation')} -- </option>
                                                {
                                                    this.state.confirmationList.map(item => {


                                                        return (<option key={item.id} value={item.id}> {item.name}</option>)
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-1">
                                <button className="btn btn-primary btn-sm float-right" onClick={() => this.setState({ isTable: true })}>
                                    <i className="fa fa-list"></i>
                                </button>
                                &nbsp;
                                <button className="btn btn-primary btn-sm float-right" onClick={() => this.setState({ isTable: false })}>
                                    <i className="fa fa-square"></i>
                                </button>
                            </div>

                        </div>
                        <br></br>
                        <div className="row">
                            {Content}
                        </div>

                    </CardBody>
                </Collapse>
                <ApplicationModal
                    applicationId={this.state.applicationId}
                    show={this.state.showModal}
                    onHide={this.setShowApplicationModal}
                    callback={this.getApllicationList}
                ></ApplicationModal>
            </Card>
        );
    }
}

export default withTranslation()(JobApplicationList);