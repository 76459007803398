import axios from "axios";
import config from "./../config/config";
class MenuApi {
    // base_api = "http://192.168.13.177:7000/"
    base_api = config.base_api;
    token = config.token;

    getAllMenu(params) {
        return axios.get(this.base_api + "menus/", {
            params: params,
            headers: {
                Authorization: "Token " + this.token,
                "Content-Type": "application/json",
            },
        });
    }

    getDetailMenu(menuId) {
        return axios.get(this.base_api + `menus/${menuId}/?content=0`, {
            headers: {
                Authorization: "Token " + this.token,
                "Content-Type": "application/json",
            },
        });
    }

    getDetailMenuItem(menuItemId) {
        return axios.get(this.base_api + `menu_items/${menuItemId}/`, {
            headers: {
                Authorization: "Token " + this.token,
                "Content-Type": "application/json",
            },
        });
    }

    menuAdd(menu) {
        return axios.post(
            this.base_api + `menus/`,
            {
                name: menu.name,
                template: menu.template,
            },
            {
                headers: {
                    Authorization: "Token " + this.token,
                    "Content-Type": "application/json",
                },
            }
        );
    }

    menuUpdate(id, menu) {
        return axios.patch(
            this.base_api + `menus/${id}/`,
            {
                name: menu.name,
                item_update: menu.items,
            },
            {
                headers: {
                    Authorization: "Token " + this.token,
                    "Content-Type": "application/json",
                },
            }
        );
    }

    menuItemAdd(data) {
        /*
        var newData = {
            menu:data.menu,
            item_languages:data.item_languages,
            widget:data.widget,
            url:data.url,
            parent:data.parent
        }
        */
        // if(data.parent) {
        //     newData.parent = data.parent
        // }

        return axios.post(this.base_api + `menu_items/`, data, {
            headers: {
                Authorization: "Token " + this.token,
                "Content-Type": "application/json",
            },
        });
    }

    menuItemUpdate(menuId, data) {
        /*
        var newData = {
            menu:data.menu,
            item_languages:data.item_languages,
            widget:data.widget,
            url:data.url,
            parent:data.parent
        }
        */

        // if(data.parent) {
        //     newData.parent = data.parent
        // }

        // console.log("In the api");
        // console.log(menuId);
        // console.log(data);

        return axios.patch(this.base_api + `menu_items/${menuId}/`, data, {
            headers: {
                Authorization: "Token " + this.token,
                "Content-Type": "application/json",
            },
        });
    }

    menuItemDelete(menuItemId) {
        return axios.delete(this.base_api + `menu_items/${menuItemId}/`, {
            headers: {
                Authorization: "Token " + this.token,
                "Content-Type": "application/json",
            },
        });
    }

    menuMultiDelete(ids) {
        return axios.delete(this.base_api + `menu_delete/`, {
            data: {
                ids: ids,
            },
            headers: {
                Authorization: "Token " + this.token,
                "Content-Type": "application/json",
            },
        });
    }
}

export default MenuApi;
