import React, { Component } from 'react';
import { Tab, Nav, Col, Row } from "react-bootstrap"
import Card,{ CardHeader, CardTitle,CardBody, CardFooter  } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content"

// import { Editor } from '@tinymce/tinymce-react'; 
import axios from "axios"

// import Dashboard from "./../components/section/Dashboard"
class TestPage extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            content:"",

            show:false,
            images:[
                {
                    id:96, 
                    url:"satu"
                }, 
                {
                    id:2,
                    url:"dua"
                }
            ],
            result:"", 
            response: {
                message:"something wrong",
                data:{  
                    foo:"bar",
                    test:'tesla'
                }
            }

        }

        this.getAllWidget = this.getAllWidget.bind(this)
    }

    componentDidMount() {
        this.getAllWidget()
        .then(res => {
            console.log("WIDGET LIST => ", res.data)
        })
    }

    getAllWidget() {

        const params = {}

        return axios.get("https://api.phenikaa.com/widgets/",{
            params:params,
            headers:{
                "Authorization":"Token 82e2e0f4b2d790b30cf2916d1bf664bdb3620149",
                "Content-Type":"application/json"
            }
        })
    }
 
    render() {


        return (
          
            <Content>
                <ContentHeader>
                    <h1> Test </h1>
                </ContentHeader>
                <ContentBody>
                    <Card>
                        <CardHeader>
                            <CardTitle> Test Page </CardTitle>
                        </CardHeader>
                        <CardBody>

                        </CardBody>
                        <CardFooter>
                        <p> This is Footer </p>
                        </CardFooter>
                    </Card>
                </ContentBody>
            </Content>
        );
    }
}

export default TestPage;