import axios from "axios"
import config from "./../config/config"

class ArticleApi {

    // base_api = "http://192.168.13.177:7000/"
    base_api = config.base_api
    token = config.token
    //token = "7e142a22850ebf92be390c473ce16cb4593651f7"

    getAllArticles(params){
        return axios.get(this.base_api+"articles/",{
            params:params,
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    getDetailArticles(articleId){
        return axios.get(this.base_api+`articles/${articleId}/`,{
            params:{
                
            },
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    getArticleCategories(params) { 
        return axios.get(this.base_api+`article_categories/`,{
            params:params,
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    getDetailArticleCategory(categoryId) {
        return axios.get(this.base_api+`article_categories/${categoryId}/`,{
         
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    addArticle(data) {
        var bodyFormData = new FormData();
        if (data.content) {
            bodyFormData.append("content", data.content);
        }
        if (data.title) {
            bodyFormData.append("title", data.title);
        }

        bodyFormData.append("name", data.name);
        bodyFormData.append("article_type", data.article_type);
        if (data.categories) {
            data.categories.forEach(categories => {
                if (categories) {
                    bodyFormData.append("categories", categories);
                }
            });
        }
        if (data.widgets) {
            data.widgets.forEach(widgets => {
                if (widgets) {   
                    bodyFormData.append("widgets", widgets);
                }
            });
        }
        if (data.article_assets) {
            data.article_assets.forEach((ass, index) => {
                if (ass) {   
                    bodyFormData.append(`article_assets[${index}]asset`, ass.asset);
                }
            });
        }
        if (data.author) {
            bodyFormData.append("author", data.author);
        }
        bodyFormData.append("date", data.date);
        if (data.location) {
            bodyFormData.append("location", data.location);
        }
        bodyFormData.append("status", data.status);

        data.article_languages.forEach((item, index) => {
            bodyFormData.append(`article_languages[${index}]language`, item.language);
            if (item.name) {
                bodyFormData.append(`article_languages[${index}]name`, item.name);
            }
            bodyFormData.append(`article_languages[${index}]title`, item.title);
            bodyFormData.append(`article_languages[${index}]summary`, item.summary);
            if (item.content) {
                bodyFormData.append(`article_languages[${index}]content`, item.content);
            }
            if (item.content_file) {
                bodyFormData.append(`article_languages[${index}]content_file`, item.content_file);
            }
            if (item.content_section) {
                bodyFormData.append(`article_languages[${index}]content_section`, item.content_section);
            }
            bodyFormData.append(`article_languages[${index}]description`, item.description);
            if (item.page) {
                bodyFormData.append(`article_languages[${index}]page`, item.page);
            }
            if (item.section) {
                bodyFormData.append(`article_languages[${index}]section`, item.section);
            }
            bodyFormData.append(`article_languages[${index}]path`, item.path);
        })

        return axios.post(this.base_api + `articles/`, bodyFormData, {
            headers: {
                Authorization: `Token ${this.token}`,
                "Content-Type": "multipart/form-data",
            },
        });
    }

    updateArticle(articleId, data) {
        var bodyFormData = new FormData();
        if (data.content) {
            bodyFormData.append("content", data.content);
        }
        if (data.title) {
            bodyFormData.append("title", data.title);
        }

        bodyFormData.append("name", data.name);
        bodyFormData.append("article_type", data.article_type);
        if (data.categories) {
            data.categories.forEach(categories => {
                if (categories) {
                    bodyFormData.append("categories", categories);
                }
            });
        }
        if (data.widgets) {
            data.widgets.forEach(widgets => {
                if (widgets) {   
                    bodyFormData.append("widgets", widgets);
                }
            });
        }
        if (data.author) {
            bodyFormData.append("author", data.author);
        }
        bodyFormData.append("date", data.date);
        if (data.location) {
            bodyFormData.append("location", data.location);
        }
        bodyFormData.append("status", data.status);

        data.article_languages.forEach((item, index) => {
            bodyFormData.append(`article_languages[${index}]language`, item.language);
            if (item.name) {
                bodyFormData.append(`article_languages[${index}]name`, item.name);
            }
            bodyFormData.append(`article_languages[${index}]title`, item.title);
            bodyFormData.append(`article_languages[${index}]summary`, item.summary);
            if (item.content) {
                bodyFormData.append(`article_languages[${index}]content`, item.content);
            }
            if (item.content_file) {
                bodyFormData.append(`article_languages[${index}]content_file`, item.content_file);
            }
            if (item.content_section) {
                bodyFormData.append(`article_languages[${index}]content_section`, item.content_section);
            }
            bodyFormData.append(`article_languages[${index}]description`, item.description);
            if (item.page) {
                bodyFormData.append(`article_languages[${index}]page`, item.page);
            }
            if (item.section) {
                bodyFormData.append(`article_languages[${index}]section`, item.section);
            }
            bodyFormData.append(`article_languages[${index}]path`, item.path);
        })

        return axios.patch(this.base_api+`articles/${articleId}/`,bodyFormData,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"multipart/form-data"
            }
        })
    }

    addKeywords(data){
        return axios.post(this.base_api+`article_keywords/`,data,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    updateKeywords(keywordId, data){
        return axios.patch(this.base_api+`article_keywords/${keywordId}/`,data,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    deleteKeywords(keywordId) {
        return axios.delete(this.base_api+`article_keywords/${keywordId}/`,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    changeMultipleArticleStatus(articles ){
        return axios.patch(this.base_api+`article_status/`,{
            ids:articles
        },{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    } 

    articleAssetChange(articleAssetId, assetId) {
        return axios.patch(this.base_api+`article_assets/${articleAssetId}/`,{
            asset:assetId
        },{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    // the assets form is [1,2,3]
    // if assets empty / [] , which mean the article didnt had assets
    articleAssetPair(articleId, assets) {
        return axios.patch(this.base_api+`article_asset_pair/`,{
            article_id:articleId,
            asset_ids:assets
        },{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    articleAssetUnpair(articleId, assets) {
        return axios.patch(this.base_api+`article_asset_unpair/`,{
            article_id:articleId,
            asset_ids:assets
        },{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    deleteArticle(articleId) {
        return axios.delete(this.base_api+`articles/${articleId}/`,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    deleteMultiArticle(articles) {
        return axios.delete(this.base_api+`article_delete/`,{
            data:{
                ids:articles
            },
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }
    
    // {"widget_id":1, "article_ids":[41, 39]}
    widgetArticleIndexUpdate(widget_id, article_ids) {
        return axios.patch(this.base_api+`widget_article_index_update/`,{
            
            widget_id:widget_id,
            article_ids:article_ids
            
        },{
        
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

}

export default ArticleApi