import React, { Component } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import swal from "sweetalert";
import AlertHelper from "./../../lib/AlertHelper";
import TemplateApi from "./../../api/TemplateApi";
import { withTranslation } from "react-i18next";

class TemplateAddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      name: "",
      screenshot: "",
      transPage: "modals.template_add.",
      transAlert: "alert.",
      isActive: false,
      site: "",
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleActiveTemplate = this.handleActiveTemplate.bind(this);
    this.handleScreenshotFile = this.handleScreenshotFile.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.getTemplateDetail = this.getTemplateDetail.bind(this);
    this.createTemplateNew = this.createTemplateNew.bind(this);
  }

  componentDidMount() {}

  onOpenModal() {
    this.setState(
      {
        id: this.props.id,
      },
      () => {
        if (this.state.id) {
          this.getTemplateDetail();
        }
      }
    );
  }

  handleNameChange(value) {
    this.setState({
      name: value,
    });
  }

  handleActiveTemplate(value) {
    this.setState((prevState) => {
      return {
        isActive: !prevState.isActive,
      };
    });
  }

  handleScreenshotFile(e) {
    this.setState({
      screenshot: e.target.files[0],
    });
  }

  getTemplateDetail() {
    const objTemplate = new TemplateApi();

    objTemplate.getTemplateDetail(this.state.id).then((res) => {
      this.setState({
        name: res.data.name,
      });
    });
  }

  createTemplateNew() {
    const { t } = this.props;
    const objTemplate = new TemplateApi();
    const formData = new FormData();
    const ids = parseInt(localStorage.getItem("siteId"));

    formData.append("name", this.state.name);
    formData.append("screenshot", this.state.screenshot);
    formData.append("active_template", this.state.isActive);
    formData.append("site", ids);

    objTemplate
      .createTemplateNew(formData)
      .then((res) => {
        swal(
          "Success !",
          t(this.state.transAlert + "page_add_sucess"),
          "success"
        );
        setTimeout(() => {
          window.location.reload();
        }, 0);
      })
      .catch((err) => {
        const objAlert = new AlertHelper();
        const resErr = err?.response?.data ? err?.response?.data : err.message;
        //console.log(" Error => ",err?.response?.data)
        objAlert.errorHandlerAlert(resErr);
      });
  }

  render() {
    const { t } = this.props;
    var HeaderTitle = t(this.state.transPage + "title_add");
    var BtnSubmit = (
      <Button variant="primary" onClick={() => this.createTemplateNew()}>
        <i className="fa fa-plus">&nbsp;</i>
        {t(this.state.transPage + "add_template")}
      </Button>
    );

    if (this.state.id) {
      HeaderTitle = t(this.state.transPage + "title_update");
      BtnSubmit = (
        <Button variant="primary" onClick={() => this.templateUpdate()}>
          <i className="fa fa-edit">&nbsp;</i>
          {t(this.state.transPage + "update_template")}
        </Button>
      );
    }

    return (
      <Modal
        show={this.props.show}
        onHide={() => this.props.onHide()}
        onShow={() => this.onOpenModal()}
      >
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title> {HeaderTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label> {t("name")} </label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={this.state.name}
              onChange={(event) => this.handleNameChange(event.target.value)}
            ></input>
          </div>

          <div className="form-group">
            <label> {t("screenshot")} </label>
            <input
              type="file"
              className="form-control"
              name=""
              value=""
              onChange={(e) => this.handleScreenshotFile(e)}
            />
          </div>
          {/* <Form.Group controlId="checkTemplateOnModal">
            <Form.Check
              type="checkbox"
              label="Active"
              onChange={(e) => this.handleActiveTemplate(e.target.value)}
              checked={this.state.isActive ? true : false}
              value={this.state.isActive}
            />
          </Form.Group> */}
        </Modal.Body>
        <Modal.Footer>{BtnSubmit}</Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(TemplateAddModal);
