import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import classes from './NewMenuItem.module.css'
import iB from './assets/arrB.svg'
import iW from './assets/arrW.svg'

export default class ExtendMenuItem extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        let active = this.props.active ? classes.titleActiveExt : classes.titleItemExt
        let idx = {
            // marginLeft: `calc((1rem * ${this.props.idx}))`
        }

        let draftIco
        if (this.props.data?.is_draft) {
            draftIco = <i className='fa fa-eye-slash fa-xs' title='draft' style={{ opacity: '.5' }}></i>
        }

        return (
            <div className={active} style={idx}>
                <Link to={`/page/detail/${this.props.data.id}`} onClick={this.props.activePage}>
                    <div className={classes.accHead}>
                        <p className={classes.titleAccordion}>{this.props.data.name}&nbsp;&nbsp;&nbsp;{draftIco}</p>
                        <img className={classes.titleIcon} src={this.props.active ? iW : iB} alt="icon" />
                    </div>
                </Link>
            </div>
        )
    }
}
