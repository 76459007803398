import React, { Component } from 'react';
import { Modal } from "react-bootstrap"
import { withTranslation } from 'react-i18next';

class PortfolioListModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            portfolios:[],
            transPage: "modals.portfolio_list."
        }

        this.getPortfolio   = this.getPortfolio.bind(this)
        this.onOpenModal    = this.onOpenModal.bind(this)
    }

    onOpenModal() {
        this.getPortfolio()
    }

    getPortfolio() {
        this.setState({
            portfolios:this.props.portfolios
        })
    }

    render() {
        const {t} = this.props;
        const Portfolio = this.state.portfolios.map((item,index) => {

            index = index + 1

            return (<tr>
                <td>{ index }</td>
                <td><a href={item.attachment_file}> {t('download')} </a></td>
                <td><a href={item.attachment_link}> {t('view')} </a></td>
               
            </tr>)
        })

        return (
            <Modal size="lg" show={this.props.show} onHide={this.props.onHide} onShow={this.onOpenModal}>
                <Modal.Header closeButton className="bg-primary">
                   {t('portfolio')}
                </Modal.Header>
                <Modal.Body>
                    <div className="row">

                        

                       <table className="table table-bordered">
                           <thead>
                               <th> {t('no')} </th>
                               <th> {t('file')} </th>
                               <th> {t('link')} </th>
                             
                           </thead>
                           <tbody>
                               { Portfolio }
                           </tbody>
                       </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { this.props.onHide() }}> {t('ok')} </button>
                   
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(PortfolioListModal);