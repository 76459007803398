import React, { Component } from 'react';

import classes from './SidebarNew.module.css'
class NavItem extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        // const menuOpen = this.props.menuOpen ? "menu-open" : ""
        // let menuOpen = ''
        // if (this.props.menuOpen) {
        //     const element = document.querySelector(`.${this.props.navClass}`);
        //     const isElement = element.classList.contains("menu-open");
        //     menuOpen = isElement ? "" : "menu-open"
        // }
        const menuOpen = this.props.menuOpen ? classes.menuOpen : classes.menuClose
        const menuOpenTr = this.props.menuOpen ? classes.nvTrOpen : classes.nvTrClose
        // let menuOpenTr
        // if (this.props.menuOpen) {
        //     const element = document.querySelector(`.${this.props.navClass}`);
        //     const isElement = element.classList.contains("menu-open");
        //     menuOpenTr = isElement ? classes.nvTrClose : classes.nvTrOpen
        // }
        const icon = this.props.icon ? this.props.icon : "fa fa-circle"
        const sidebarOpen = this.props.showSidebarValue ? classes.sidebarOpen : classes.sidebarClose
        
        return (
            <div className={[menuOpen, this.props.navClass, sidebarOpen, classes.navItemCustom].join(' ')}>
                <div href={this.props.href} className={classes.sidebarItem} onClick={() => this.props.onClick()} data-toggle={this.props.showSidebarValue ? "tooltip" : ""} data-placement={this.props.showSidebarValue ? "right" : ""} title={this.props.showSidebarValue ? this.props.title : ''}>
                    <i className={`${icon} nav-icon `}></i>
                    <p>
                        {this.props.title}
                        <i className="right fas fa-angle-left"></i>
                    </p>
                </div>
                <ul className={[classes.nvTr, menuOpenTr].join(' ')}>
                    {this.props.children}
                </ul>
            </div>
            // <div className={[`nav-item`, this.props.navClass, classes.navItemCustom].join(' ')}>
            //     <a href={this.props.href} className="nav-link" onClick={() => this.props.onClick()}>
            //         <i class={`${icon} nav-icon `}></i>
            //         <p>
            //             {this.props.title}
            //             <i class="right fas fa-angle-left"></i>
            //         </p>
            //     </a>
            //     <ul className={["nav nav-treeview"].join(' ')}>
            //         {this.props.children}
            //     </ul>
            // </div>

        );
    }
}

export default NavItem;