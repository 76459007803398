/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import swal from "sweetalert";
import AlertHelper from "./../../lib/AlertHelper";

import MenuApi from "./../../api/MenuApi";
// import GeneralApi from "./../../api/GeneralApi"
import PageApi from "./../../api/PageApi";
import SectionApi from "./../../api/SectionApi";
import WidgetApi from "./../../api/WidgetApi";
import { withTranslation } from "react-i18next";

import Context from "../../pages/Context";

class MenuItemAdd extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);

    this.state = {
      menuItemId: 0,

      menuId: 0,
      name: "",
      parent: null,
      names: [],
      languageList: [],
      url: null,
      pages: [],
      sections: [],
      selectedPage: 0,
      selectedSection: 0,

      update: true,

      tabKey: 1,
      selectedLanguage: 1,
      renderTabLanguage: "",
      idElement: 0,

      widgets: [],
      widget: null,

      open: false,

      urlType: "internal",
      isDraft: true,
      transPage: "modals.menu_item_add.",
      transAlert: "alert.",
    };

    // this.clearState = this.clearState.bind(this)

    this.onShowModal = this.onShowModal.bind(this);
    this.onHideModal = this.onHideModal.bind(this);
    this.getDetailMenuItem = this.getDetailMenuItem.bind(this);
    this.getLanguages = this.getLanguages.bind(this);
    this.getWidgetList = this.getWidgetList.bind(this);
    this.getPageLanguage = this.getPageLanguage.bind(this);
    this.getSectionLanguage = this.getSectionLanguage.bind(this);

    this.handleWidgetChange = this.handleWidgetChange.bind(this);
    this.handlePageLanguageChange = this.handlePageLanguageChange.bind(this);
    this.handleSectionLanguageChange = this.handleSectionLanguageChange.bind(
      this
    );
    this.handleUrlTypeChange = this.handleUrlTypeChange.bind(this);
    this.handleChangeIsPublish = this.handleChangeIsPublish.bind(this);

    this.clearInternal = this.clearInternal.bind(this);
    this.clearExternal = this.clearExternal.bind(this);
  }

  componentDidMount() {}

  onShowModal() {
    const _this = this;

    // console.log(" onShowModal ==> ",this.props.parent )
    this.getWidgetList().then((res) => {
      this.setState(
        {
          widgets: res.data.results,
        },
        () => {
          _this.getPageLanguage();
        }
      );
    });
  }

  onHideModal() {
    this.setState(
      {
        menuItemId: 0,
        menuId: 0,
        name: "",
        parent: null,
        url: "",
        widget: null,
        // languageList:[],
        tabKey: 1,
        selectedLanguage: 1,
        names: [],
        renderTabLanguage: "",
        isDraft: false,
      },
      () => this.props.onHide()
    );
  }

  getWidgetList() {
    const objWidget = new WidgetApi();

    return objWidget.getAllWidgets({
      internal: false,
      max_size: true,
    });
  }

  getDetailMenuItem() {
    const menuObj = new MenuApi();

    const menuItemId = this.state.menuItemId;
    // console.log("getDetailmenuItem ==> ")
    menuObj.getDetailMenuItem(menuItemId).then((res) => {
      console.log(" detail MENU MENU  ===> ", res.data);

      this.setState(
        {
          menuId: res.data.menu,
          parent: res.data.parent,
          names: res.data.item_languages,
          widget: res.data.widget,
          urlType: "internal",
          selectedPage: res.data.item_languages[0].page,
          selectedSection: res.data.item_languages[0].section,
          isDraft: res.data.is_draft,
        },
        () => {
          // alert(this.state.selectedSection)

          console.log(this.state.widget + " - " + res.data.widget);
          this.handleWidgetChange(res.data.widget);
          if (this.state.selectedPage === null) {
            //this.state.urlType = "external"
            this.setState({
              urlType: "external",
            });
          } else {
            //this.state.urlType = "internal"
            this.setState(
              {
                urlType: "internal",
              },
              () => {
                // this.getWidgetList()
                // this.getPageLanguage()
                this.handlePageLanguageChange(this.state.selectedPage);
              }
            );
          }
          this.renderTabLanguage();
        }
      );
    });
  }

  getLanguages() {
    const { languagesList } = this.context;

    this.setState(
      {
        menuItemId: this.props.id ? this.props.id : 0,
        menuId: this.props.menuId ? this.props.menuId : 0,
        parent: this.props.parent,
        update: this.props.update,
        tabKey: 1,
        selectedLanguage: 1,
        languageList: languagesList,
        open: this.props.open,
      },
      () => {
        // alert(this.state.menuItemId)
        if (this.state.menuItemId) {
          this.getDetailMenuItem();
          //this.handleSectionLanguageChange(this.state.selectedSection);
        } else {
          this.renderTabLanguage();
        }
      }
    );
  }

  getPageLanguage() {
    const objPage = new PageApi();
    //console.log(this.state.names)
    return objPage
      .pageLanguageList({
        template: localStorage.getItem("templateId"),
        language: localStorage.getItem("lang"),
        max_size: true,
      })
      .then((res) => {
        // console.log("  HELLO ====> ",res )

        this.setState(
          {
            pages: res.data?.results,
          },
          () => {
            //
            this.getLanguages();
          }
        );
      });
  }

  getSectionLanguage(pageId) {
    // console.log("GetSectionLog: " + pageId)

    const objSection = new SectionApi();

    objSection
      .sectionLanguage({
        template: localStorage.getItem("templateId"),
        language: localStorage.getItem("lang"),
        page_id: pageId,
        max_size: true,
      })
      .then((res) => {
        //console.log("getting sections");
        //console.log(res);
        this.setState({
          sections: res.data.results,
        });
      });
  }

  handleContentChange(id, value) {
    //console.log("handleContentChange ... ")

    let names = [...this.state.names];
    let rowName = { ...names[id] };
    rowName.name = value;
    names[id] = rowName;

    this.setState({
      names: names,
    });
  }

  handleUrlChange(value, index) {
    //console.log("URL change: " + value + " - " + index);
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.names[index].path = value;
    this.state.names[index].page = null;
    this.state.names[index].section = null;
    this.setState(
      {
        url: value,
        selectedPage: 0,
        selectedSection: 0,
      },
      () => {}
    );
  }

  handleUrlTypeChange(value) {
    const _this = this;
    this.setState(
      {
        urlType: value,
      },
      () => {
        // _this.getDetailWidget()
        if (this.state.urlType === "internal") {
          _this.clearExternal();
        } else {
          _this.clearInternal();
        }
      }
    );
  }

  handleChangeTab(value) {
    this.setState(
      {
        selectedLanguage: value,
        tabKey: value,
      },
      () => {
        this.renderTabLanguage();
      }
    );
  }

  handleWidgetChange(value) {
    //console.log("value ==> ",value)
    this.setState({
      widget: value,
    });
  }

  handlePageLanguageChange(value) {
    //this.state.selectedPage = value
    //this.state.selectedSection = null;
    if (value === "") {
      value = null;
    }
    this.setState(
      {
        selectedPage: value,
        // selectedSection: null, // THERE IS A PROBLEM IN HERE
      },
      () => {
        this.getSectionLanguage(value);
        //console.log("GetAllSections - " + value);
        //console.log(this.state.selectedPage);
        this.renderTabLanguage();
      }
    );

    //console.log("GetAllSections - " + value + " - " + this.state.selectedPage);
  }

  handleSectionLanguageChange(value) {
    //console.log("Update Section");
    if (value === "") {
      value = null;
    }
    this.setState(
      {
        selectedSection: value,
      },
      () => {
        this.renderTabLanguage();
      }
    );
  }

  handleChangeIsPublish(value) {
    this.setState({
      isDraft: value,
    });
  }

  renderTabLanguage() {
    const renderTabLanguage = this.state.languageList.map((item, index) => {
      var findName = this.state.names.find(
        (textLang, idx) => item.id === textLang.language
      );

      //console.log("item " + index)

      if (this.state.update) {
        //console.log("first", this.state.names[index])
        return {
          id: findName?.id,
          language: item.id,
          name: findName ? findName.name : "",
          languageName: item.name,
          page: this.state.selectedPage,
          section: this.state.selectedSection,
          path: this.state.names[index].path,
        };
      } else {
        //console.log("after", this.state.names[index])
        return {
          //   id: null,
          language: item.id,
          name: findName ? findName.name : "",
          languageName: item.name,
          page: this.state.selectedPage,
          section: this.state.selectedSection,
          path: null,
        };
      }
    });

    this.setState(
      {
        names: renderTabLanguage,
      },
      () => {
        //console.log("names render : ",this.state.names)
      }
    );
  }

  addmenuItem() {
    const { t } = this.props;
    // console.log("state.name", this.state.names)
    const objMenuItem = new MenuApi();
    const data = {
      menu: this.state.menuId,
      item_languages: this.state.names,
      parent: this.state.parent,
      //url:this.state.url,
      //selectedPage: null,
      widget: this.state.widget,
      urlType: this.state.urlType,
      is_draft: this.state.isDraft,
    };

    //console.log( " data => ",data )

    objMenuItem
      .menuItemAdd(data)
      .then((res) => {
        //console.log("res.data", res.data)
        swal(
          "Success !",
          t(this.state.transAlert + "menu_item_add_success"),
          "success"
        );
        this.props.getMenuDetail();
        this.props.onHide();
      })
      .catch((err) => {
        const objAlert = new AlertHelper();
        objAlert.errorHandlerAlert(err.response.data);
      });
  }

  updateMenuItem() {
    const { t } = this.props;
    const objMenuItem = new MenuApi();

    const menuItemId = this.state.menuItemId;

    const data = {
      menu: this.state.menuId,
      item_languages: this.state.names,
      parent: this.state.parent,
      urlType: this.state.urlType,
      widget: this.state.widget,
      is_draft: this.state.isDraft,
    };

    objMenuItem
      .menuItemUpdate(menuItemId, data)
      .then((res) => {
        //console.log("res.data", res.data)
        swal(
          "Success !",
          t(this.state.transAlert + "menu_item_update_success"),
          "success"
        );
        this.props.getMenuDetail();
        this.props.onHide();
      })
      .catch((err) => {
        const objAlert = new AlertHelper();
        const resErr = err?.response?.data ? err?.response?.data : err.message;
        objAlert.errorHandlerAlert(resErr);
      });
  }

  clearInternal() {
    this.setState(
      {
        selectedPage: null,
        selectedSection: null,
      },
      () => {
        // alert(" clear cuy")
        console.log("hello world");
      }
    );
  }

  clearExternal() {
    let urlLanguages = [...this.state.names];
    urlLanguages.map((item, id) => {
      // let urlLanguage = { ...urlLanguages[ id ]}
      item.path = null;
      urlLanguages[id] = item;

      return item;
    });

    this.setState({
      names: urlLanguages,
    });
  }

  render() {
    const { t } = this.props;
    var HeaderText;
    var BtnSubmit;

    if (!this.state.update) {
      // this.clearState()

      HeaderText = t(this.state.transPage + "title_add");
      BtnSubmit = (
        <Button variant="primary" onClick={() => this.addmenuItem()}>
          <i className="fa fa-plus">&nbsp;</i>
          {t("add")}
        </Button>
      );
    } else {
      HeaderText = t(this.state.transPage + "title_update");
      BtnSubmit = (
        <Button variant="primary" onClick={() => this.updateMenuItem()}>
          <i className="fa fa-edit">&nbsp;</i>
          {t("update")}
        </Button>
      );
    }

    const WidgetList = this.state.widgets.map((item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      );
    });

    const PageLangList = this.state.pages.map((item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      );
    });

    const SectionLangList = this.state.sections.map((item) => {
      return (
        <option value={item.section} key={item.id}>
          {item.name}
        </option>
      );
    });

    const TabLanguage = this.state.names.map((item, index) => {
      // console.log("item" ,item )

      return (
        <Tab key={index} eventKey={item.language} title={item.languageName}>
          <br></br>

          <div className="form-group">
            <label> {t("name")} </label>
            <textarea
              className="form-control"
              onChange={(event) =>
                this.handleContentChange(index, event.target.value)
              }
              value={this.state.names[index].name}
            ></textarea>
            {/* { JSON.stringify( this.state.text_languages )  } */}
          </div>
        </Tab>
      );
    });

    var URLInput = null;

    if (this.state.urlType === "internal") {
      URLInput = (
        <div>
          <div className="form-group">
            <label style={{ cssTransform: "capitalized" }}> {t("page")} </label>
            <select
              value={this.state.selectedPage}
              className="form-control"
              onChange={(event) => {
                this.handlePageLanguageChange(event.target.value);
              }}
            >
              <option value={0} selected={this.state.selectedPage === 0}>
                {" "}
                -- {t(this.state.transPage + "select_page")} --{" "}
              </option>
              {PageLangList}
            </select>
          </div>

          <div className="form-group">
            <label style={{ cssTransform: "capitalized" }}>
              {" "}
              {t("section")}{" "}
            </label>
            <select
              value={this.state.selectedSection}
              className="form-control"
              id="selectionForm"
              onChange={(event) => {
                this.handleSectionLanguageChange(event.target.value);
              }}
            >
              <option
                value={0}
                selected={this.state.selectedSection === 0}
              >
                {" "}
                -- {t(this.state.transPage + "select_section")} --{" "}
              </option>
              {SectionLangList}
            </select>
          </div>
        </div>
      );
    } else {
      //console.log(this.state.names)
      URLInput = this.state.names.map((item, index) => {
        return (
          <div className="form-group">
            <label> Url {item.languageName}</label>
            <input
              value={this.state.names[index].path}
              type="text"
              className="form-control"
              onChange={(event) =>
                this.handleUrlChange(event.target.value, index)
              }
            ></input>
          </div>
        );
      });
    }

    return (
      <Modal
        show={this.props.show}
        onHide={() => this.onHideModal()}
        onShow={() => this.onShowModal()}
      >
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title> {HeaderText} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            id="controlled-tab-example-3"
            activeKey={this.state.tabKey}
            onSelect={(k) => this.handleChangeTab(k)}
          >
            {TabLanguage}
          </Tabs>

          {/* <form>
                <div className="form-group">
                    <label> Name </label>
                    <input value={this.state.name} onChange={(event) => this.handleNameChange(event.target.value)} type="text" className="form-control"></input>
                </div>
            </form> */}

          <div className="form-group">
            <label> {t("url_type")} </label>
            <br></br>
            <label htmlFor="internal">
              <input
                id="internal"
                type="radio"
                name="urlType"
                value={this.state.urlType}
                defaultChecked={this.state.urlType === "internal"}
                onChange={() => this.handleUrlTypeChange("internal")}
              ></input>{" "}
              {t("internal")}
            </label>
            &nbsp;&nbsp;&nbsp;
            <label htmlFor="external">
              <input
                id="external"
                type="radio"
                name="urlType"
                value={"external"}
                defaultChecked={this.state.urlType === "external"}
                onChange={() =>
                  this.setState({
                    urlType: "external",
                  })
                }
              ></input>{" "}
              {t("external")}
            </label>
          </div>

          {URLInput}

          <div className="form-group">
            <label> {t("widget")} </label>
            <select
              className="form-control"
              value={this.state.widget}
              onChange={(event) => {
                this.handleWidgetChange(event.target.value);
              }}
            >
              <option value="" key="">
                -- {t(this.state.transPage + "select_widget")} --
              </option>
              {WidgetList}
            </select>
          </div>
          <div className="form-group">
            <label> {t("publicity")} </label>
            <br></br>
            <input
              id="publish"
              type="radio"
              name="publicity"
              value={"publish"}
              defaultChecked={this.state.isDraft === false}
              onChange={() => this.handleChangeIsPublish(false)}
            ></input>{" "}
            <label htmlFor="publish">{t("publish")}</label>
            &nbsp;&nbsp;&nbsp;
            <input
              id="draft"
              type="radio"
              name="publicity"
              value={"draft"}
              defaultChecked={this.state.isDraft === true}
              onChange={() => this.handleChangeIsPublish(true)}
            ></input>{" "}
            <label htmlFor="draft">{t("draft")}</label>
          </div>
        </Modal.Body>
        <Modal.Footer>{BtnSubmit}</Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(MenuItemAdd);
