import React, { Component } from 'react';

class CardHeader extends Component {

    render() {

        const className = this.props.className || ""

        return (
            <div className={"card-header "+className } title={this.props.title}>
                { this.props.children }
            </div>
        );
    }
}

export default CardHeader;