import axios from "axios"
import config from "./../config/config"
export default class WidgetApi {

    // base_api = "http://192.168.13.177:7000/"
    base_api = config.base_api
    token = config.token

    getDetailWidget(widgetId, params) {
        return axios.get(this.base_api+`widgets/${widgetId}/`, {
            params:params,
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    getAllWidgets(params){
        return axios.get(this.base_api+`widgets/`,{
            params:params,
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    getWidgetCategories(){
        return axios.get(this.base_api+`widget_categories/`,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    widgetDelete(widget_ids){
        return axios.delete(this.base_api+"widget_delete/",{
            data:{
                ids:widget_ids
            },
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    widgetIndexUpdate(sectionId, widget_ids){
        return axios.patch(this.base_api+"section_widget_index_update/",{
            section_id:sectionId,
            ids:widget_ids
        },{
           
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    widgetArticleUnpair(widget_id, articles) {

        // console.log("key value => ",{
        //     article_ids:articles
        // })

        return axios.patch(this.base_api+`widget_article_unpair/`,{
            "widget_id":widget_id,
            "article_ids":articles // [19,22]
        },{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    
    widgetAssetPair(widgetId, asset) {
        return axios.patch(this.base_api+`widget_asset_pair/`,{
            "widget_id":widgetId,
            "asset_ids":asset // [19,22]
        },{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    widgetAssetUnpair(widgetId, asset) {
        return axios.patch(this.base_api+`widget_asset_unpair/`,{
            "widget_id":widgetId,
            "asset_ids":asset // [19,22]
        },{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    widgetTextDetail(textId){
        return axios.get(this.base_api+`text_elements/${textId}/`, {
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    widgetTextAdd(widgetId, name, text_languages, font_color) {
        return axios.post(this.base_api+`text_elements/`,{
            "widget":widgetId,
            name:name,
            text_languages:text_languages,
            font_color: font_color
        },{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    widgetTextUpdate(data) {
        return axios.patch(this.base_api+`text_elements/${data.textId}/`,{
            
            name:data.name,
            input_type:data.input_type,
            text_languages:data.text_languages,
            font_color : data.font_color
        },{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    widgetTextDelete(ids){
        return axios.delete(this.base_api+`text_element_delete/`,{
            data:{
                ids:ids
            },
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    widgetElementIndexUpdate(data) {
        return axios.patch(this.base_api+`widget_element_index_update/`,data,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    widgetElementUnpair(data) {
        return axios.patch(this.base_api+`widget_element_unpair/`,data,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    // http://beta-api.bzpublish.com/widgets/35/widget_asset_index_update/ update index asset
    // http://beta-api.bzpublish.com/widgets/2185/widget_article_index_update/ update index widget
    widgetArticleOrder(id, data) {
        return axios.patch(this.base_api+`widgets/${id}/widget_article_index_update/`,data,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    widgetAssetOrder(id, data) {
        return axios.patch(this.base_api+`widgets/${id}/widget_asset_index_update/`,data,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
    }

    /*
        {
            "widget_id":51,
            "elements":[
                {
                    "id":795,
                    "category":"Text"
                },
                {
                    "id":26,
                    "category":"Media"
                },
                {
                    "id":726,
                    "category":"Article"
                }
            ]
        }
    */

    // http://54.255.70.35/widget_element_unpair/

}