import React, { Component } from "react";
import swal from "sweetalert";
import TitlePages from "./Elements/TitlePages";
import { Content, ContentHeader, ContentBody } from "./../components/Content";
import BucketApi from "../api/BucketApi";
import { Collapse, Card } from "react-bootstrap";
import { CardHeader } from "../components/Card";
import { CardBody } from "./../components/Card";
import { withTranslation } from 'react-i18next';
import UserContext from './Context';
import config from '../config/config'
import ReactGA from 'react-ga';


class BucketSettingPage extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            isConnected: false,
            openCollapse: false,
            idTemplate: 0,
            template_info: [],
            names: [],
            defaultArr: [{
                id: '1',
                name: 'Preview'
            },
            {
                id: '2',
                name: 'Publish'
            }]
        };
        this.getBucketSettings = this.getBucketSettings.bind(this);
        this.updateBucketSettings = this.updateBucketSettings.bind(this);
        this.addBucketSettings = this.addBucketSettings.bind(this);
    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
        
        const contextProps = this.context
        this.setState({ idTemplate: contextProps.activeTemplate }, this.getBucketSettings)
    }

    componentDidUpdate() {
        const contextProps = this.context
        if (contextProps.activeTemplate !== this.state.idTemplate) {
            this.setState({ idTemplate: contextProps.activeTemplate }, this.getBucketSettings)
        }
    }

    getBucketSettings() {
        const BS = new BucketApi();

        BS.getBucketSettings(this.state.idTemplate)
            .then((res) => {
                res.data.template_information.forEach((data) => {
                    this.setState({
                        [`names${data.id}`]: false,
                    });
                });
                this.setState({
                    template_info: res.data.template_information,
                    isConnected: true,
                });
            })
            .catch((err) => {
                console.log("error =>", err);
            });
    }

    updateBucketSettings(index, id) {
        const BS = new BucketApi();
        let bucket_name_preview = document.getElementById("name0").value;
        let bucket_name_publish = document.getElementById("name1").value;
        let bucket_key_preview = document.getElementById("key0").value;
        let bucket_key_publish = document.getElementById("key1").value;
        let domain_preview = document.getElementById("domain0").value;
        let domain_publish = document.getElementById("domain1").value;
        let secret_key_preview = document.getElementById("secret0").value;
        let secret_key_publish = document.getElementById("secret1").value;

        let data;
        let name_updated;

        if (index === 0) {
            name_updated = "Preview S3 Bucket";
            data = {
                bucket_key: bucket_key_preview,
                bucket_name: bucket_name_preview,
                domain: domain_preview,
                secret_key: secret_key_preview
            };
        } else if (index === 1) {
            name_updated = "Publish S3 Bucket";
            data = {
                bucket_key: bucket_key_publish,
                bucket_name: bucket_name_publish,
                domain: domain_publish,
                secret_key: secret_key_publish
            };
        }

        BS.updateBucketSettings(id, data)
            .then((res) => {
                if (res.data) {
                    if (data) {
                        swal("Success !", `${name_updated} has been updated !`, "success");
                    }
                }
            })
            .catch((err) => {
                if (err) {
                    swal("Error!", err.message, "error");
                }
            });
    }

    addBucketSettings(index, id) {
        const BS = new BucketApi();
        let bucket_name_preview = document.getElementById("name0").value;
        let bucket_name_publish = document.getElementById("name1").value;
        let bucket_key_preview = document.getElementById("key0").value;
        let bucket_key_publish = document.getElementById("key1").value;
        let domain_preview = document.getElementById("domain0").value;
        let domain_publish = document.getElementById("domain1").value;
        let secret_key_preview = document.getElementById("secret0").value;
        let secret_key_publish = document.getElementById("secret1").value;

        let data;
        let name_updated;

        if (index === 0) {
            name_updated = "Preview S3 Bucket";
            data = {
                template: this.state.idTemplate,
                name: 'Preview',
                bucket_key: bucket_key_preview,
                bucket_name: bucket_name_preview,
                domain: domain_preview,
                secret_key: secret_key_preview
            };
        } else if (index === 1) {
            name_updated = "Publish S3 Bucket";
            data = {
                template: this.state.idTemplate,
                name: 'Publish',
                bucket_name: bucket_name_publish,
                bucket_key: bucket_key_publish,
                secret_key: secret_key_publish,
                domain: domain_publish,
            };
        }

        BS.addBucketSettings(data)
            .then((res) => {
                if (res.data) {
                    if (data) {
                        swal("Success !", `${name_updated} has been added !`, "success");
                    }
                }
            })
            .catch((err) => {
                if (err) {
                    swal("Error!", err.message, "error");
                }
            });
    }

    templateHTML(ele, index, param) {
        const { openCollapse } = this.state;
        const { t } = this.props;

        let idx = ele.name === "Preview" ? 0 : 1
        let html
        html = <Card>
            <CardHeader className={!openCollapse ? "bg-primary" : ""}>
                <div style={{ float: "left" }}>
                    <div style={{ width: "100%", float: "left", marginLeft: 0, fontWeight: "bold" }}>
                        {ele.name} <i className="fas fa-pencil"> &nbsp;</i>
                    </div>
                </div>

                <div style={{ float: "right", marginLeft: "2%" }}
                    className="arrow-toggle"
                    onClick={() => {
                        this.setState((prevState) => ({
                            [`names${idx}`]: !prevState[`names${idx}`],
                        }));
                    }}
                >
                    <button className="btn btn-xs">
                        <i className={openCollapse ? "fas fa-chevron-up" : "fas fa-chevron-down text-white"}></i>
                    </button>
                </div>
            </CardHeader>
            <Collapse in={this.state[`names${idx}`]} style={{ padding: "1.25rem" }}>
                <CardBody>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>{t('pages.s3_bucket_settings.bucket_name')}</label>
                            <input id={`name${idx}`} type="text" defaultValue={ele.bucket_name} className="form-control"></input>
                        </div>
                        <div className="form-group">
                            <label>{t('pages.s3_bucket_settings.bucket_key')}</label>
                            <input id={`key${idx}`} type="text" defaultValue={ele.bucket_key} className="form-control"></input>
                        </div>
                        <div className="form-group">
                            <label>{t('pages.s3_bucket_settings.domain_name')}</label>
                            <input id={`domain${idx}`} type="text" defaultValue={ele.domain} className="form-control"></input>
                        </div>
                        <div className="form-group">
                            <label>{t('pages.s3_bucket_settings.secret_key')}</label>
                            <input id={`secret${idx}`} type="text" defaultValue={ele.secret_key} className="form-control"></input>
                        </div>

                        <div className="float-right">
                            <div className="btn btn-primary" onClick={param === "add" ? () => this.addBucketSettings(index) : () => this.updateBucketSettings(index, ele.id)}>
                                <i className={param === "add" ? "fa fa-plus" : "fa fa-edit"}>&nbsp;</i>
                                {param === "add" ? t('add') : t('update')}
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Collapse>
        </Card>
        return html
    }

    render() {
        const { t } = this.props;
        const { openCollapse, template_info, isConnected, names, defaultArr } = this.state;
        let form_settings;

        const arr = ['Preview', 'Publish']
        if (isConnected) {
            if (template_info.length > 0) {
                if (template_info.length === 1) {
                    let existBucket = template_info[0].name
                    let currentIdx = arr.indexOf(existBucket)
                    let active, idx
                    if (currentIdx === 0) {
                        active = 'Publish'
                        idx = 1
                    } else if (currentIdx === 1) {
                        active = 'Preview'
                        idx = 0
                    }
                    let data = {
                        id: idx,
                        name: active,
                        active_key: active
                    }
                    let newArr = template_info.concat(data)
                    form_settings = newArr.map((ele, i) => {
                        let html
                        if (ele.active_key === "Preview" || ele.active_key === "Publish") {
                            html = this.templateHTML(ele, i, 'add')
                        } else {
                            html = this.templateHTML(ele, i, 'edit')
                        }
                        return html
                    });
                } else {
                    form_settings = template_info.map((ele, i) => {
                        return this.templateHTML(ele, i, 'edit')
                    });
                }
            } else {
                form_settings = defaultArr.map((ele, i) => {
                    return this.templateHTML(ele, i, 'add')
                });
            }
        } else {
            form_settings = defaultArr.map((ele, i) => {
                return this.templateHTML(ele, i, 'add')
            });
        }

        return (
            <Content>
                <ContentHeader>
                    <TitlePages title={t('pages.s3_bucket_settings.title')} />
                </ContentHeader>
                <ContentBody>
                    <div className="pl-4 pr-4 pb-4">
                        <div className="clearfix"></div>
                        {form_settings}
                    </div>
                </ContentBody>
            </Content>
        );
    }
}

export default withTranslation()(BucketSettingPage)