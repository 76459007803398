import React from 'react';
import Cookies from 'js-cookie'

// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem("user")
  if (userStr) return JSON.parse(userStr)
  else return null
}

// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem("token") || ""
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem("token")
  localStorage.removeItem("user")
}

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  localStorage.setItem("token", token)
  localStorage.setItem("user", JSON.stringify(user))
}

export const setMobileCookie = (key, dataUser, privilege) => {
  Cookies.set('key', key)
  Cookies.set('user', dataUser)
  Cookies.set('employee_privileges', privilege)
}

export const removeMobileCookie = () => {
  Cookies.remove('key')
  Cookies.remove('user')
  Cookies.remove('employee_privileges')
}


const Context = React.createContext()
export default Context
