import React, { Component } from "react";

import { Link } from "react-router-dom";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import swal from "sweetalert2";
import Pagination from "react-js-pagination";

import Card, { CardHeader, CardTitle, CardBody } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "../components/Content";
import TitlePages from './Elements/TitlePages'

// import ListCard from "./../components/ListCard"
import PageSectionCatalogAdd from "./../components/Modal/PageSectionCatalogAdd";

import PageApi from "./../api/PageApi";
import SectionApi from "../api/SectionApi";

// import axios from "axios"
import { Button } from "react-bootstrap";
import PageSectionDeleteConfirm from "../components/Modal/PageSectionDeleteConfirm";
import SectionCatalogMultipleUpdate from "../components/Modal/SectionCatalogMultipleUpdate";
import { withTranslation } from 'react-i18next';
import config from '../config/config'
import ReactGA from 'react-ga';

class SectionCatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionId: 0,
      sections: [],
      selectedSections: [],
      deleteDisable: true,

      showModal: false,
      showMultipleUpdate: false,
      showModalDelete: false,

      activePage: 1,
      limitPageData: 9,
      totalCount: 0,
      heightCardCatalog: null,
      search: "",
      transAlert: "alert."
    };

    this.timeouts = {}

    this.getSectionCatalog = this.getSectionCatalog.bind(this);
    this.deleteSelectedSection = this.deleteSelectedSection.bind(this);
    this.setSelectedItem = this.setSelectedItem.bind(this);
    this.setShowModal = this.setShowModal.bind(this);
    this.setShowMultipleUpdate = this.setShowMultipleUpdate.bind(this);
    this.setItemId = this.setItemId.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.distributeItemId = this.distributeItemId.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount() {
    ReactGA.initialize(config.GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.getSectionCatalog();
    if (window.screen.width <= 1440) {
      this.setState({
        heightCardCatalog: 150,
      });
    } else if (window.screen.width <= 1920) {
      this.setState({
        heightCardCatalog: 300,
      });
    } else if (window.screen.width === 2560) {
      this.setState({
        heightCardCatalog: 400,
      });
    }
  }

  setShowModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  setShowModalDelete() {
    this.setState({
      showModalDelete: !this.state.showModalDelete,
    });
  }

  setShowMultipleUpdate() {
    this.setState({
      showMultipleUpdate: !this.state.showMultipleUpdate,
    });
  }

  getSectionCatalog() {
    const objSection = new SectionApi();

    objSection
      .sectionCatalogList({
        page_size: this.state.limitPageData,
        page: this.state.activePage,
        name: this.state.search,
      })
      .then((res) => {
        this.setState({
          sections: res.data.results,
          totalCount: res.data.count,
        });
      });
  }

  setItemId(value) {
    this.setState(
      {
        sectionId: value,
      },
      () => {
        this.setShowModal();
      }
    );
  }

  distributeItemId(value) {
    const objSection = new SectionApi();

    objSection
      .sectionCatalogDistribute(value)
      .then((result) => {
        // alert("to")
        /*
                {"data":
                    {"messages":
                        ["INFO: AD_Section1 does not have any child"]
                    },
                    "status":200,"statusText":"OK",
                    "headers":{"content-length":"58","content-type":"application/json"},
                    "config":{"url":"http://54.255.70.35/sections/349/distribute/",
                    "method":"post","data":"{}",
                    "headers":{"Accept":"application/json, text/plain, ","Content-Type":"application/json",
                    "Authorization":"Token 60edaccc8f547344c09fe84bea12029a7aab6b36"},"transformRequest":[null],
                    "transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}}
            */

        var contentMessage = "";

        result.data.messages.forEach((item) => {
          var splitItem = item.split(": ");

          if (splitItem[0] === "INFO") {
            contentMessage += `<li> <div class="label label-info"></div> ${splitItem[1]}</li>`;
          } else if (splitItem[0] === "WARNING") {
            contentMessage += `<li> <div class="label label-warning"></div> ${splitItem[1]}</li>`;
          } else if (splitItem[0] === "DANGER") {
            contentMessage += `<li> <div class="label label-danger"></div> ${splitItem[1]}</li>`;
          } else if (splitItem[0] === "SUCCESS") {
            contentMessage += `<li> <div class="label label-success"></div> ${splitItem[1]}</li>`;
          }
        });

        var htmlResult = `<ul>${contentMessage}</ul> `;

        swal.fire({
          html: `${htmlResult}`,
        });
      })
      .catch((err) => {
        swal.fire("Error!", err.message, "error");
      });
  }

  setSelectedItem(section, value) {
    var lastSelected = [...this.state.selectedSections];
    var indexDelete = -1;
    // var stateSelected = ""

    if (value === true) {
      lastSelected.push(section);
    } else {
      // console.log("jalan donk harusnya ", section , this.state.selectedSections)
      this.state.selectedSections.forEach((item, index) => {
        if (item.id === section.id) {
          indexDelete = index;
        }
      });

      lastSelected.splice(indexDelete, 1);
      //console.log("lastArticles => ",lastArticles)
    }

    this.setState(
      {
        selectedSections: lastSelected,
      },
      () => {
        if (this.state.selectedSections.length > 0) {
          this.setState({
            deleteDisable: false,
          });
        } else {
          this.setState({
            deleteDisable: true,
          });
        }
      }
    );
  }

  deleteSelectedSection() {
    const { t } = this.props;
    const objPage = new PageApi();
    //const pageId = 68 // hardcode , khusus

    const sections = this.state.selectedSections.map((item) => {
      return item.id;
    });

    objPage
      .pageSectionDelete(sections)
      .then((res) => {
        this.setState({
          showModalDelete: false,
          disableDelete: true,
          selectedSections: [],
        });
        this.getSectionCatalog();

        swal.fire(
          "Success !",
          t(this.state.transAlert + "delete_widget_in_section"),
          "success"
        );

        setTimeout(() => {
          window.location.reload();
        }, 0);
      })
      .catch((err) => {
        swal.fire("Error!", err.message, "error");
      });
  }

  handlePageChange(pageNumber) {
    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.getSectionCatalog();
      }
    );
  }

  handleSearchChange(value) {
    clearTimeout(this.timeouts.search)
    this.setState(
      {
        activePage: 1,
        search: value,
      },
      () => {
        this.timeouts.search = setTimeout(() => {
          this.getSectionCatalog();
        }, 333)
      }
    );
  }

  render() {
    const { t } = this.props
    // const pageId = this.props.match.params.pageId

    const SectionList = this.state.sections.map((item) => {
      return (
        <div className="col-md-4">
          <Card className="card card-outline card-primary">
            <CardHeader className="">
              <CardTitle>
                <input
                  id={item.name + "-" + item.id}
                  className=""
                  type="checkbox"
                  onClick={(event) =>
                    this.setSelectedItem(item, event.currentTarget.checked)
                  }
                ></input>
                &nbsp;
                <i className="fa fa-align-left" title="section">
                  &nbsp;
                </i>
                <label htmlFor={item.name + "-" + item.id}> {item.name}</label>
              </CardTitle>
              <div className="card-tools">
                <Button
                  className="btn-sm"
                  variant="default"
                  onClick={() => this.setItemId(item.id)}
                >
                  <i className="fa fa-edit"></i>
                </Button>

                <span>&nbsp;</span>
                <Button
                  className="btn-sm"
                  variant="default"
                  onClick={() => this.distributeItemId(item.id)}
                >
                  <i className="fa fa-sync"></i>
                </Button>

                <span>&nbsp;</span>
                <Link to={`/section/detail/${item.id}`}>
                  <Button className="btn-sm" variant="default">
                    <i className="fa fa-arrow-right"></i>
                  </Button>
                </Link>
                <span>&nbsp;</span>
              </div>
            </CardHeader>
            <CardBody style={{ height: this.state.heightCardCatalog }}>
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src={
                  item.screenshot ? `${item.screenshot}` : "/img/phenikaa-default-image.svg"
                }
                alt={item.name}
              ></img>
            </CardBody>
          </Card>
        </div>
      );
    });

    return (
      <Content>
        <ContentHeader>
          {/* <h1> Section Catalogs </h1> */}
          <TitlePages title={t('pages.section_catalog.title')} />
        </ContentHeader>
        <ContentBody>
          <Card>
            <CardHeader>
              <CardTitle> {t('pages.section_catalog.section_list')} : </CardTitle>
              <div className="card-tools">
                <Button
                  variant="primary"
                  onClick={() => this.setShowMultipleUpdate()}
                  style={{ marginRight: 5 }}
                >
                  <i className="fa fa-plus">&nbsp;</i>
                  {t('pages.section_catalog.add_multiple_section')}
                </Button>
                <Button
                  variant="danger"
                  onClick={() => this.setShowModalDelete()}
                  disabled={this.state.deleteDisable}
                  style={{ marginRight: 5 }}
                >
                  <i className="fa fa-trash">&nbsp;</i>
                  {t('delete_selected')}
                </Button>
                <Button
                  onClick={() =>
                    this.setState({ sectionId: 0 }, () => this.setShowModal())
                  }
                >
                  <i className="fa fa-plus">&nbsp;</i>
                  {t('add_section')}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <div style={{ float: "left" }}>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder={t('pages.section_catalog.search_section')}
                    className="form-control"
                    name="search"
                    value={this.state.search}
                    onChange={(event) =>
                      this.handleSearchChange(event.target.value)
                    }
                  />
                </div>
              </div>
              <div style={{ float: "right" }}>
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.limitPageData}
                  totalItemsCount={this.state.totalCount}
                  pageRangeDisplayed={4}
                  onChange={this.handlePageChange.bind(this)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
              <div className="clearfix"></div>
              <div className="row">{SectionList}</div>
              <div style={{ float: "right" }}>
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.limitPageData}
                  totalItemsCount={this.state.totalCount}
                  pageRangeDisplayed={4}
                  onChange={this.handlePageChange.bind(this)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
              <div className="clearfix"></div>
            </CardBody>
          </Card>
        </ContentBody>
        <SectionCatalogMultipleUpdate
          id={this.state.sectionId}
          show={this.state.showMultipleUpdate}
          onHide={this.setShowMultipleUpdate}
          getSectionCatalog={this.getSectionCatalog}
        ></SectionCatalogMultipleUpdate>
        <PageSectionCatalogAdd
          id={this.state.sectionId}
          show={this.state.showModal}
          onHide={this.setShowModal}
          getSectionCatalog={this.getSectionCatalog}
        ></PageSectionCatalogAdd>
        <PageSectionDeleteConfirm
          show={this.state.showModalDelete}
          onHide={this.setShowModalDelete}
          selectedSections={this.state.selectedSections}
          deleteSelectedSection={this.deleteSelectedSection}
        ></PageSectionDeleteConfirm>
      </Content>
    );
  }
}

export default withTranslation()(SectionCatalog);
