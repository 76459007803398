import React, { Component } from 'react';
import { Button } from "react-bootstrap"
// import { Link } from "react-router-dom"

import Card, { CardHeader, CardTitle, CardBody } from "../components/Card";
import { Content, ContentHeader, ContentBody } from "./../components/Content"
import TitlePages from './Elements/TitlePages'

import TemplateApi from "./../api/TemplateApi"
import { withTranslation } from 'react-i18next';
import config from '../config/config'
import ReactGA from 'react-ga';

class TemplateDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            template: {},
            transPage: "pages.template_detail."
        }

        this.getTemplateDetail = this.getTemplateDetail.bind(this)
    }

    componentDidMount() {
        ReactGA.initialize(config.GA_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        this.getTemplateDetail()
    }

    getTemplateDetail() {
        const objTemplate = new TemplateApi()

        const templateId = this.props.match.params.templateId

        objTemplate.getTemplateDetail(templateId)
            .then(res => {
                this.setState({
                    template: res.data
                })
            })
    }

    render() {
        const { t } = this.props;
        return (
            <Content>
                <ContentHeader>
                    {/* <h1> Template : {this.state.template.name} </h1> */}
                    <TitlePages title={`${t('template')} : ${this.state.template.name}`} />

                    <div className="clearfix"></div>
                </ContentHeader>
                <ContentBody>
                    <div className="row">

                    </div>

                </ContentBody>
            </Content>
        );
    }
}

export default withTranslation()(TemplateDetail);