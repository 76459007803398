import React, { Component } from "react";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import PageApi from "./../../api/PageApi";
import swal from "sweetalert";
import Context from "../../pages/Context"

class PageUpdateInformation extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      name: "",
      textId: 0,
      content: "",
      input_type: "Small",
      text_languages: [],
      languageList: [],
      tabKey: 1,
      selectedLanguage: 1,
      idElement: 0,
      renderTabLanguage: "",
      selectedPageId: 0,
      selectedSectionId: 0,
      showTextModal: false,
      currentDescription: [],
      errorText: null,
      values: "",
      currentD: [],
      isEn: "",
      idE: 0,
      idVn: 0,
      isVn: "",
      dataText: [],
      languageName: "",
    };
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleContentChange = this.handleContentChange.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleInputTypeChange = this.handleInputTypeChange.bind(this);
    this.renderTabLanguage = this.renderTabLanguage.bind(this);
    this.setTextId = this.setTextId.bind(this);
    this.setShowTextModal = this.setShowTextModal.bind(this);
    this.getDetailTextElement = this.getDetailTextElement.bind(this);
  }

  // componentDidMount() {
  //   this.setState({
  //     text_languages: this.props.currentDesc,
  //   });
  // }

  componentWillReceiveProps(nextProps) {
    this.setState({
      text_languages: nextProps.currentDesc,
    });
  }

  onOpenModal() {
    const { languagesList } = this.context;
    const param = [];

    param.push({
      template: localStorage.getItem("templateId"),
      language: localStorage.getItem("lang"),
      max_size: true,
    });

    this.setState(
      {
        id: this.props.textId | 0,
        tabKey: 1,
        selectedLanguage: 1,
        languageList: languagesList,
      },
      () => {

        if (this.state.id) {
          this.renderTabLanguage();
          // this.getDetailTextElement();
        } else {
          this.renderTabLanguage();
        }
      }
    );
  }

  getDetailTextElement() {
    this.setState({
      text_languages: this.props.currentDesc,
    });
    this.renderTabLanguage();
  }

  setTextId(value) {
    this.setState(
      {
        textId: value,
      },
      () => {
        this.props.onHide();
      }
    );
  }

  setShowTextModal() {
    this.setState({
      showTextModal: !this.state.showTextModal,
    });
  }

  onCloseModal() {
    this.setState(
      {
        id: 0,
        name: "",
        // languageList:[],
        tabKey: 1,
        selectedLanguage: 1,
        text_languages: [],
        renderTabLanguage: "",
      },
      () => {
        this.props.onHide();
      }
    );
  }

  handleInputTypeChange(value) {
    this.setState({
      input_type: value,
    });
  }

  handleNameChange(value) {
    this.setState({
      name: value,
    });
  }

  handleContentChange(i, e) {
    let tmp = [...this.state.text_languages];
    let text_language = { ...tmp[i] };
    text_language.description = e;
    tmp[i] = text_language;

    this.setState({
      text_languages: tmp,
    });
  }

  handleChangeTab(value) {
    this.setState(
      {
        selectedLanguage: value,
        tabKey: value,
      },
      () => {
        this.renderTabLanguage();
      }
    );
  }

  updateDescriptionPage() {
    // alert("updating.....");
    const objPage = new PageApi();
    const inputTextLanguage = this.state.text_languages.map((item) => {
      return {
        language: item.language,
        language_name: item.languageName,
        description: item.description,
      };
    });

    let id = this.props.id;

    objPage
      .updateDescriptionPage(id, inputTextLanguage)
      // this.showModal = this.showModal.bind(this);
      .then((res) => {
        console.log(res.data);
        this.props.onHide();
        this.props.getDataAll(id);
        swal(
          "Success !",
          "You sucessfully updated text into Description!",
          "success"
        );
      })
      .catch((err) => {
        console.log(err.response.data);

        if (err.response.data) {
          if (err.response.data[0]) {
            this.setState({
              errorText: "* Error updating....",
            });
          } else if (err.response.data.page_languages[0]) {
            this.setState({
              errorText: "* Ensure this field has no more than 160 characters.",
            });
          }
        }
      });
  }

  renderTabLanguage() {
    const renderTabLanguage = this.state.languageList.map((item) => {
      var result = this.state.text_languages.find(
        (textLang) => item.id === textLang.language
      );

      if (!result) {
        result = {
          language: item.id,
          text_element: 4,
          content: "",
          languageName: item.name,
        };
      }

      result.languageName = item.name;
      return result;
    });

    this.setState(
      {
        text_languages: renderTabLanguage,
      },
      () => {
        // console.log("text_languages : ", this.state.text_languages);
      }
    );
  }

  render() {
    var HeaderExecute = "Update";
    let ButtonExecute = null;
    ButtonExecute = (
      <Button variant="primary" onClick={() => this.updateDescriptionPage()}>
        <i className="fa fa-edit">&nbsp;</i>
        Update
      </Button>
    );

    let ckeditorConfig = {
      toolbar: {
        items: [
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "superscript",
          "subscript",
          "|",
          "link",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",

          "|",
          "undo",
          "redo",
        ],
      },
      table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
      },
      language: "",
    };

    let TabLanguage;
    var TextEditor;
    if (this.state.text_languages) {
      TabLanguage = this.state.text_languages.map((s, i) => {
        if (this.state.input_type === "Big") {
          TextEditor = (
            <CKEditor
              id="editor"
              config={ckeditorConfig}
              editor={ClassicEditor}
              data={this.state.text_languages[i].content}
              onInit={(editor) => { }}
              onChange={(event, editor) => {
                const data = editor.getData();

                this.handleContentChange(i, data);
              }}
            />
          );
        }
        TextEditor = (
          <div className="d-flex flex-column">
            <textarea
              className="form-control"
              onChange={(event) =>
                this.handleContentChange(i, event.target.value)
              }
              value={this.state.text_languages[i].description}
            ></textarea>
            <p
              style={{
                fontSize: "12px ",
                color: "red",
                marginTop: ".5rem",
                marginBottom: "0",
              }}
            >
              {this.state.errorText}
            </p>
          </div>
        );
        return (
          <Tab key={i} eventKey={s.language} title={s.language_name}>
            <br></br>
            <div className="form-group">
              <label> Content </label>
              {TextEditor}
            </div>
          </Tab>
        );
      });
    }

    // const TabLanguage = this.state.text_languages.map((item, index) => {
    //   var TextEditor = (
    //     <div className="d-flex flex-column">
    //       <textarea
    //         className="form-control"
    //         onChange={(event) =>
    //           this.handleContentChange(index, event.target.value)
    //         }
    //         value={this.state.text_languages[index].content}
    //       ></textarea>

    //       <p
    //         style={{
    //           fontSize: "12px ",
    //           color: "red",
    //           marginTop: ".5rem",
    //           marginBottom: "0",
    //         }}
    //       >
    //         {this.state.errorText}
    //       </p>
    //     </div>
    //   );

    //   if (this.state.input_type === "Big") {
    //     TextEditor = TextEditor = (
    //       <CKEditor
    //         id="editor"
    //         config={ckeditorConfig}
    //         editor={ClassicEditor}
    //         data={this.state.text_languages[index].content}
    //         onInit={(editor) => {}}
    //         onChange={(event, editor) => {
    //           const data = editor.getData();

    //           this.handleContentChange(index, data);
    //         }}
    //       />
    //     );
    //   }

    //   return (
    //     <Tab key={index} eventKey={item.language} title={item.languageName}>
    //       <br></br>

    //       <div className="form-group">
    //         <label> Content </label>
    //         {TextEditor}
    //       </div>
    //     </Tab>
    //   );
    // });

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onShow={this.onOpenModal}
        size="md"
      >
        <Modal.Header closeButton className="bg-primary">
          <Modal.Title>
            {HeaderExecute} {this.props.widgetName} Description{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <Tabs
              id="controlled-tab-example-3"
              activeKey={this.state.tabKey}
              onSelect={(k) => this.handleChangeTab(k)}
            >
              {TabLanguage}
            </Tabs>
          </form>
        </Modal.Body>
        <Modal.Footer>{ButtonExecute}</Modal.Footer>
      </Modal>
    );
  }
}

export default PageUpdateInformation;
